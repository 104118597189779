import { useEffect, useState } from 'react';
import produce from 'immer';
import {
	CorrelationsAnalysis,
	CrosstabAnalysis,
	DensityPlotAnalysis,
	FrequenciesAnalysis,
	KaplanMeierAnalysis,
	PlotNumericAnalysis,
	TimeCourseAnalysisV1,
	TimeCourseAnalysisV2,
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	CorrelationsV1Analysis,
	DensityPlotAnalysisV2,
	CrosstabAnalysisV2,
	PlotNumericAnalysisV2,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { Switch } from 'components/UI/Interactables/Switch';
import { Typography } from 'components/UI/Typography';
import { useAnalysisConfigPanel, useTranslation, useUpdateAnalysis } from 'hooks/store';
import { SelectItem } from 'types/index';
import { useDebounce } from 'hooks/utils';
import { isEqual } from 'lodash';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';

interface Props {
	analysis:
		| FrequenciesAnalysis
		| FrequenciesAnalysisV2
		| CorrelationsAnalysis
		| CorrelationsV1Analysis
		| CrosstabAnalysis
		| CrosstabAnalysisV2
		| DensityPlotAnalysis
		| KaplanMeierAnalysis
		| KaplanMeierAnalysisV2
		| PlotNumericAnalysis
		| PlotNumericAnalysisV2
		| CompareNumericAnalysisV2
		| CompareNumericAnalysisV1
		| TimeCourseAnalysisV1
		| TimeCourseAnalysisV2
		| DensityPlotAnalysisV2
		| TimeCourseAnalysisV1
		| TimeCourseAnalysisV2;
	hasChartOptions?: boolean;
	isEnabled?: boolean | '' | SelectItem[] | undefined | string;
	hasHistogram?: boolean;
	hasChartTicks?: boolean;
}
export const AnalysisFormatting = ({
	analysis,
	isEnabled,
	hasHistogram,
	hasChartOptions = true,
	hasChartTicks = false
}: Props) => {
	const { translate } = useTranslation();

	const {
		options: { chartLegend: legend, showCensorTicks, showCategoryLabels, histogram }
	} = analysis;

	const [chartLegend, setChartLegend] = useState(legend);

	const [censorTicks, setCensorTicks] = useState(showCensorTicks);

	const [chartHistogram, setChartHistogram] = useState(histogram);

	const updateAnalysis = useUpdateAnalysis();

	const [{ isFormattingOpen }, { openFormatting }] = useAnalysisConfigPanel(analysis.id);

	useEffect(() => {
		if (showCensorTicks !== undefined) {
			if (!isEqual(showCensorTicks, censorTicks)) {
				setCensorTicks(showCensorTicks);
			}
		}
	}, [showCensorTicks]);

	useEffect(() => {
		if (legend !== undefined) {
			if (!isEqual(legend, chartLegend)) {
				setChartLegend(legend);
			}
		}
	}, [legend]);

	useEffect(() => {
		if (histogram !== undefined) {
			if (!isEqual(histogram, chartHistogram)) {
				setChartHistogram(histogram);
			}
		}
	}, [histogram]);

	useDebounce(
		() => {
			if (
				!isEqual(legend, chartLegend) ||
				!isEqual(histogram, chartHistogram) ||
				!isEqual(showCensorTicks, censorTicks)
			) {
				const updatedAnalysis = {
					...analysis,

					options: {
						...analysis.options,
						chartLegend,
						histogram: chartHistogram,
						showCensorTicks: censorTicks
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[chartLegend, chartHistogram, censorTicks],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<CollapsibleCard
			marginOffset={{ bottom: 1.6 }}
			title={translate(({ analysis }) => analysis.analyses.groupedOptions.title.Formatting)}
			open={!isFormattingOpen}
			onToggle={() =>
				openFormatting({ analysisId: analysis.id, formatting: !isFormattingOpen })
			}
		>
			<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
				{hasChartOptions ? (
					<>
						<Typography.Caption fontweight={weight => weight.bold}>
							{translate(
								({ analysis }) =>
									analysis.analyses.groupedOptions.title.chartOptions
							)}
						</Typography.Caption>
						<Switch
							label={translate(({ analysis }) => analysis.generic.chartLegend)}
							on={chartLegend}
							disabled={!isEnabled}
							onChange={() => setChartLegend(state => !state)}
						/>
						{hasChartTicks ? (
							<Switch
								label={translate(({ analysis }) => analysis.generic.showChartTicks)}
								on={censorTicks}
								disabled={!isEnabled}
								onChange={() => setCensorTicks(state => !state)}
							/>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}

				{hasHistogram && (
					<Switch
						label={translate(({ analysis }) => analysis.generic.histogram)}
						on={chartHistogram}
						disabled={!hasHistogram}
						onChange={() => setChartHistogram(state => !state)}
					/>
				)}
				<Typography.Caption fontweight={weight => weight.bold}>
					{translate(
						({ analysis }) => analysis.analyses.groupedOptions.title.categoryValues
					)}
				</Typography.Caption>

				<RadioButton
					label={translate(({ analysis }) => analysis.generic.showValues)}
					disabled={!isEnabled}
					selected={!showCategoryLabels}
					onSelect={() => {
						const updatedAnalysis = produce(analysis, draft => {
							draft.options.showCategoryLabels = false;
						});

						updateAnalysis({ analysis: updatedAnalysis });
					}}
				/>

				<RadioButton
					label={translate(({ analysis }) => analysis.generic.showLabels)}
					disabled={!isEnabled}
					selected={!!showCategoryLabels}
					onSelect={() => {
						const updatedAnalysis = produce(analysis, draft => {
							draft.options.showCategoryLabels = true;
						});

						updateAnalysis({ analysis: updatedAnalysis });
					}}
				/>
			</Gap>
		</CollapsibleCard>
	);
};
