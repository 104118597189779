import { useState } from 'react';
import { isEqual } from 'lodash';
import {
	PlotNumericAnalysis,
	AnalysisErrorBarType,
	Columns,
	LineOptions,
	AlignOptions
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { SelectItem, GenericMap, SelectItemOrGroup } from 'types/index';
import { Svgs } from 'environment';
import { ScatterOptions } from './PlotNumeric';
import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { TabsWithIcon } from 'components/UI/Tabs';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { RadioButton } from 'components/UI/Interactables/RadioButton';

import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useAnalysisActiveTab,
	useFilters
} from 'hooks/store';
import { useDebounce, useMemoOnce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

const ERROR_BAR_VALUES = [
	AnalysisErrorBarType.Mean,
	AnalysisErrorBarType.MeanSD,
	AnalysisErrorBarType.MeanCI,
	AnalysisErrorBarType.MeanRange,
	AnalysisErrorBarType.Median,
	AnalysisErrorBarType.MedianCI,
	AnalysisErrorBarType.MedianRange,
	AnalysisErrorBarType.MedianIQR
];

interface Props extends ScatterOptions {
	analysis: PlotNumericAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
	onLineSelect: (option: LineOptions) => void;
	onAlignSelect: (value: AlignOptions) => void;
}

export function PlotNumericConfig({
	analysis,
	variablesDataSelectItems,
	loading,
	line,
	align,
	onLineSelect,
	onAlignSelect
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		id,
		input: { variables }
	} = analysis;

	const [values, setValues] = useState(variables);
	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const [activeColumn] = useAnalysesActiveColum();

	const { selectItemsMap, categorySelectItems, numericSelectItems } = variablesDataSelectItems;
	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: PlotNumericAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	const errorBars = useMemoOnce(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		ERROR_BAR_VALUES.forEach(errorBar => {
			const item = {
				label: translateErrorBars(errorBar),
				value: errorBar
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	});

	function translateErrorBars(type: AnalysisErrorBarType) {
		if (type === AnalysisErrorBarType.Mean) {
			return translate(({ analysis }) => analysis.generic.errorBars.mean);
		}
		if (type === AnalysisErrorBarType.MeanSD) {
			return translate(({ analysis }) => analysis.generic.errorBars.meanSD);
		}
		if (type === AnalysisErrorBarType.MeanCI) {
			return translate(({ analysis }) => analysis.generic.errorBars.meanCI);
		}
		if (type === AnalysisErrorBarType.MeanRange) {
			return translate(({ analysis }) => analysis.generic.errorBars.meanRange);
		}
		if (type === AnalysisErrorBarType.Median) {
			return translate(({ analysis }) => analysis.generic.errorBars.median);
		}
		if (type === AnalysisErrorBarType.MedianCI) {
			return translate(({ analysis }) => analysis.generic.errorBars.medianCI);
		}
		if (type === AnalysisErrorBarType.MedianRange) {
			return translate(({ analysis }) => analysis.generic.errorBars.medianRange);
		}
		if (type === AnalysisErrorBarType.MedianIQR) {
			return translate(({ analysis }) => analysis.generic.errorBars.medianIQR);
		}
	}

	const getVariableItems: () => SelectItemOrGroup[] = () => {
		if (selectItemsMap[values.groupingVariable ?? '']) {
			return filterCategorySelectItems(selectItemsMap[values.groupingVariable ?? ''].value);
		}

		return categorySelectItems;
	};

	const getGroupingItems: () => SelectItemOrGroup[] = () => {
		return filterCategorySelectItems(selectItemsMap[values.categoryVariable].value);
	};

	// Filters out a value from categorySelectItems or from options inside categorySelectItems (for groups and series)
	const filterCategorySelectItems = (value: string) => {
		const filteredCategorySelectItems: SelectItemOrGroup[] = categorySelectItems.flatMap(
			(item: SelectItemOrGroup) => {
				if ('value' in item) {
					return (item as SelectItem).value !== value
						? [item]
						: ([] as SelectItemOrGroup[]);
				}
				if ('options' in item) {
					const filteredOptions = item.options.filter(option => option.value !== value);
					return filteredOptions.length > 0
						? [{ ...item, options: filteredOptions }]
						: ([] as SelectItemOrGroup[]);
				}
				return [item];
			}
		);

		return filteredCategorySelectItems;
	};

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as PlotNumericAnalysis} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<TabsWithIcon
						labels={[
							translate(({ analysis }) => analysis.analyses.plotNumeric.view.columns),
							translate(({ analysis }) => analysis.analyses.plotNumeric.view.box),
							translate(({ analysis }) => analysis.analyses.plotNumeric.view.scatter)
						]}
						icons={[Svgs.GroupedView, Svgs.BoxPlotView, Svgs.ScatterView]}
						startIndex={activeTab}
						onChangeTabs={active => setActiveTab({ analysisId: id, activeTab: active })}
						maxWidth={36}
					/>
				</Gap>
			</CollapsibleCard>

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.category
						)}
						items={getVariableItems()}
						value={selectItemsMap[values.categoryVariable]}
						onValueSelected={categoryVariable =>
							categoryVariable && setValues(state => ({ ...state, categoryVariable }))
						}
						canClear={false}
					/>
					{/* COMPARE */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.numeric
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.numericVariable]}
						onValueSelected={numericVariable =>
							numericVariable && setValues(state => ({ ...state, numericVariable }))
						}
						canClear={false}
					/>
					{/* PLOT */}
					{activeTab === 0 && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.plotNumeric.config.plot
							)}
							items={errorBars.items}
							disabled={!values.errorBar}
							value={errorBars.itemsMap[values.errorBar ?? ''] ?? errorBars.items[0]}
							onValueSelected={errorBar =>
								errorBar &&
								setValues(state => ({
									...state,
									errorBar: errorBar as AnalysisErrorBarType
								}))
							}
							canClear={false}
						/>
					)}
					{/* GROUPING */}
					{activeTab !== 2 && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.plotNumeric.config.grouping
							)}
							placeholder={translate(
								({ analysis }) => analysis.analyses.plotNumeric.config.noGrouping
							)}
							items={getGroupingItems()}
							value={selectItemsMap[values.groupingVariable ?? ''] ?? null}
							onValueSelected={groupingVariable =>
								setValues(state => ({
									...state,
									groupingVariable: groupingVariable ?? undefined
								}))
							}
						/>
					)}
					{activeTab === 2 && (
						<>
							<RadioGroup
								items={[AlignOptions.Aligned, AlignOptions.Scattered]}
								selected={align}
								onSelect={item => onAlignSelect(item as AlignOptions)}
							/>
							<Gap marginGap={{ bottom: 0.4 }}>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumeric.config.line.noLine
									)}
									selected={line === 0}
									onSelect={() => onLineSelect(LineOptions.None)}
								/>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumeric.config.line.lineAtMean
									)}
									selected={line === 1}
									onSelect={() => onLineSelect(LineOptions.Mean)}
								/>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumeric.config.line.lineAtMedian
									)}
									selected={line === 2}
									onSelect={() => onLineSelect(LineOptions.Median)}
								/>
							</Gap>
						</>
					)}
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} isEnabled={analysis.output.grouping} />
		</ConfigContainer>
	);
}
