import { CompareNumericAnalysisV1 } from 'api/data/analyses';
import { VariablesData } from 'store/data/variables';

import { CompareNumericTukeyV1 } from './CompareNumericTukeyV1/CompareNumericTukeyV1';
import { CompareNumericIndependentV1 } from './CompareNumericIndependentV1';
import { CompareNumericKruskalV1 } from './CompareNumericKruskalV1';
import { CompareNumericMannWhitneyV1 } from './CompareNumericMannWhitneyV1';
import { CompareNumericOneWayAnovaV1 } from './CompareNumericOneWayAnovaV1';
import { CompareNumericTwoWayAnovaV1 } from './CompareNumericTwoWayAnovaV1';
import { CompareNumericOneWayManovaV1 } from './CompareNumericOneWayManovaV1';
import { CompareNumericTwoWayManovaV1 } from './CompareNumericTwoWayManovaV1';
import { Gap } from 'components/UI/Gap';

interface Props {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
}

export function CompareNumericStatisticsV1({ analysis, variablesData }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		independent: {
			visible: inputStatistics.independentV1,
			results: outputStatistics.independent
		},
		mannWhitney: {
			visible: inputStatistics.mannWhitneyV1,
			results: outputStatistics.mannWhitney
		},
		oneWayAnova: {
			visible: inputStatistics.oneWayAnovaV1,
			results: outputStatistics.oneWayAnova
		},
		tukey: {
			visible: inputStatistics.tukeyV1,
			results: outputStatistics.tukey
		},
		kruskal: {
			visible: inputStatistics.kruskalV1,
			results: outputStatistics.kruskal
		},
		twoWayAnova: {
			visible: inputStatistics.twoWayAnovaV1,
			results: outputStatistics.twoWayAnova
		},
		oneWayManova: {
			visible: inputStatistics.oneWayManovaV1,
			results: outputStatistics.oneWayManova
		},
		twoWayManova: {
			visible: inputStatistics.twoWayManovaV1,
			results: outputStatistics.twoWayManova
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.independent.visible && (
				<CompareNumericIndependentV1 results={statistics.independent.results} />
			)}

			{statistics.mannWhitney.visible && (
				<CompareNumericMannWhitneyV1 results={statistics.mannWhitney.results} />
			)}

			{statistics.oneWayAnova.visible && (
				<CompareNumericOneWayAnovaV1 results={statistics.oneWayAnova.results} />
			)}

			{statistics.tukey.visible && (
				<CompareNumericTukeyV1 results={statistics.tukey.results} />
			)}

			{statistics.kruskal.visible && (
				<CompareNumericKruskalV1 results={statistics.kruskal.results} />
			)}

			{statistics.twoWayAnova.visible && (
				<CompareNumericTwoWayAnovaV1
					variablesData={variablesData}
					results={statistics.twoWayAnova.results}
				/>
			)}

			{statistics.oneWayManova.visible && (
				<CompareNumericOneWayManovaV1
					analysis={analysis}
					variablesData={variablesData}
					results={statistics.oneWayManova.results}
				/>
			)}

			{statistics.twoWayManova.visible && (
				<CompareNumericTwoWayManovaV1
					analysis={analysis}
					variablesData={variablesData}
					results={statistics.twoWayManova.results}
				/>
			)}
		</Gap>
	);
}
