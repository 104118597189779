import { PreviewDPAInput } from 'api/data/projects';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	ActionTypes,
	previewDPA,
	selectPreviewDPAFile,
	PreviewDPAFile,
	clearPreviewDPAFile
} from 'store/data/projects';

interface Handlers {
	handler: (input: PreviewDPAInput) => void;
	reset: () => void;
}

export function usePreviewProjectDPA(): OperationResult<PreviewDPAFile | null, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectPreviewDPAFile(state.data.projects));

	const [{ loading, error }] = useActivity(ActionTypes.PREVIEW_DPA);

	function handler(input: PreviewDPAInput) {
		dispatch(previewDPA(input));
	}

	function reset() {
		dispatch(clearPreviewDPAFile());
	}

	const handlers: Handlers = {
		handler,
		reset
	};

	return [{ data, loading, error }, handlers];
}
