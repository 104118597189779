import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import {
	Analysis,
	CompareNumericAnalysisV2,
	ComparePairedAnalysis,
	ComparePairedDataModels,
	CorrelationsAnalysis,
	CrosstabAnalysis,
	CrosstabAnalysisV2,
	DensityPlotAnalysis,
	ExploreAnalysis,
	FrequenciesAnalysis,
	JADBioAnalysis,
	LogisticRegressionAnalysis,
	KaplanMeierAnalysis,
	NumberPlotXYAnalysis,
	PlotNumericAnalysis,
	TimeWindowSizeType,
	KaplanMeierDataModels,
	CompareNumericAnalysisV1,
	CorrelationsV1Analysis,
	TimeCourseAnalysisV2,
	TimeCourseAnalysisV1,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	FrequenciesAnalysisV2,
	ExploreAnalysisV2,
	DensityPlotAnalysisV2,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { Variable } from 'api/data/variables';
import { AnalysesById } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { GenericMap } from 'types/index';

import { resetAnalysisInputOutput } from './resetAnalysisInputOutput';

import { VariableCategory } from '../../api/data/variables/types';
import {
	buildVariablesRichData,
	getAggregatorVariableNameByAggregationRuleName,
	variablesDataArrayIterator
} from 'helpers/variables';
import { AnalysisType } from 'api/data/analyses/constants';
import { VariableType } from 'types/data/variables/constants';

type AnalysisInputVariables = {
	key: string;
	variableRef: string;
	variableRefType: VariableType | VariableType[];
	getSecondDefaultVariableRef?: boolean;
	secondDefaultVariableRefFallback?: boolean;
	omitDefaultVariableRef?: boolean;
	transform?: (string: string) => any;
}[];

interface Props {
	analyses: Analysis[];
	analysesById: AnalysesById;
	variablesData: VariablesData;
}

export function rebuildInvalidAnalyses({ analyses, analysesById, variablesData }: Props) {
	const newAnalysesById = cloneDeep(analysesById);

	const { variablesDataArray, variablesMap, variableSetsMap } =
		buildVariablesRichData(variablesData);

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const numericVariableNames: string[] = [];
	const categoryVariableNames: string[] = [];
	const categoryMultipleVariableNames: string[] = [];
	const dateVariableNames: string[] = [];
	const durationVariableNames: string[] = [];

	const variablesFromSet: GenericMap<{
		numericVariableNames: string[];
		categoryVariableNames: string[];
		categoryMultipleVariableNames: string[];
	}> = {};

	// BUILD VARIABLES BY TYPE ARRAYS
	variablesDataArrayIterator(
		variablesDataArray,
		// VARIABLE
		variable => addVariableToNamesArray(variable),
		// GROUP
		groupData => {
			const { groupVariables } = groupData;

			groupVariables.forEach(variable => addVariableToNamesArray(variable));
		},
		// VARIABLE SET
		variableSetData => {
			const { aggregationRules, setData, setName } = variableSetData;

			variablesDataArrayIterator(
				setData,
				variable => addVariableToNamesArray(variable, undefined, setName),
				groupData => {
					const { groupVariables } = groupData;

					groupVariables.forEach(variable =>
						addVariableToNamesArray(variable, undefined, setName)
					);
				},
				() => null
			);

			aggregationRules.forEach(aggregationRule => {
				const variable = variablesMap[aggregationRule.aggregator.variableName];

				addVariableToNamesArray(variable, aggregationRule.name);
			});
		}
	);

	function addVariableToNamesArray(variable: Variable, customName?: string, setName?: string) {
		const isNumericVariable = [VariableType.Integer, VariableType.Float].includes(
			variable.type
		);

		const isDurationVariable = VariableType.TimeDuration === variable.type;
		const isCategoryVariable = VariableType.Category === variable.type;
		const isCategoryMultipleVariable = VariableType.CategoryMultiple === variable.type;
		const isDateVariable = [VariableType.Date, VariableType.DateTime].includes(variable.type);

		if (setName) {
			if (variablesFromSet[setName]) {
				if (isNumericVariable)
					variablesFromSet[setName].numericVariableNames.push(variable.name);
				if (isCategoryVariable)
					variablesFromSet[setName].categoryVariableNames.push(variable.name);
				if (isCategoryMultipleVariable)
					variablesFromSet[setName].categoryMultipleVariableNames.push(variable.name);
			} else {
				if (isNumericVariable)
					variablesFromSet[setName] = {
						numericVariableNames: [variable.name],
						categoryMultipleVariableNames: [],
						categoryVariableNames: []
					};
				if (isDateVariable)
					variablesFromSet[setName] = {
						numericVariableNames: [],
						categoryMultipleVariableNames: [],
						categoryVariableNames: []
					};
				if (isCategoryVariable)
					variablesFromSet[setName] = {
						numericVariableNames: [],
						categoryMultipleVariableNames: [],
						categoryVariableNames: [variable.name]
					};
			}
		}

		if (isNumericVariable) numericVariableNames.push(customName ?? variable.name);
		if (isCategoryVariable) categoryVariableNames.push(customName ?? variable.name);
		if (isCategoryMultipleVariable)
			categoryMultipleVariableNames.push(customName ?? variable.name);
		if (isDateVariable) dateVariableNames.push(customName ?? variable.name);
		if (isDurationVariable) durationVariableNames.push(customName ?? variable.name);
	}

	analyses.forEach(analysis => {
		// FREQUENCIES
		if (isType(AnalysisType.Frequencies)) {
			analysis = analysis as FrequenciesAnalysis;

			const { categoryVariable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.Frequencies];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable,
					variableRefType: inputVariableTypes.categoryVariable
				}
			];

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		if (isType(AnalysisType.FrequenciesV2)) {
			analysis = analysis as FrequenciesAnalysisV2;

			const { categoryVariable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.FrequenciesV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable?.name ?? '',
					variableRefType: inputVariableTypes.categoryVariable,
					transform: name => {
						if (name) {
							return { name };
						}
						return null;
					}
				}
			];

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// EXPLORE
		if (isType(AnalysisType.Explore)) {
			analysis = analysis as ExploreAnalysis;

			const variableRefs = analysis.input.variables.map(item => item.variableName);

			const inputTypes = analysisInputVariableTypes[AnalysisType.Explore];

			const analysisInputVariables: AnalysisInputVariables = variableRefs.map(
				variableRef => ({
					key: '',
					variableRef,
					variableRefType: inputTypes
				})
			);

			const newVariableRefs: string[] = [];

			analysisInputVariables.forEach(({ variableRef, variableRefType }) => {
				if (
					hasVariableRef(variableRef) &&
					isVariableRefValid(variableRef, variableRefType)
				) {
					newVariableRefs.push(variableRef);
				}
			});

			// REBUILD IF NO VARIABLE REFS
			if (newVariableRefs.length === 0) {
				const defaultVariableRef = getDefaultVariableRef(inputTypes);

				newVariableRefs.push(defaultVariableRef);
			}

			// APPLY NEW VARIABLE REFS
			const validAnalysis = produce(analysis, draft => {
				draft.input.variables = newVariableRefs.map(variableRef => ({
					variableName: variableRef
				}));
			});

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		if (isType(AnalysisType.ExploreV2)) {
			analysis = analysis as ExploreAnalysisV2;

			const variableRefs = analysis.input.variables.map(item => item.name);

			const inputTypes = analysisInputVariableTypes[AnalysisType.ExploreV2];

			const analysisInputVariables: AnalysisInputVariables = variableRefs.map(
				variableRef => ({
					key: '',
					variableRef,
					variableRefType: inputTypes,
					transform: name => {
						if (!name) return null;
						return { name };
					}
				})
			);

			const newVariableRefs: string[] = [];

			analysisInputVariables.forEach(({ variableRef, variableRefType }) => {
				if (
					hasVariableRef(variableRef) &&
					isVariableRefValid(variableRef, variableRefType)
				) {
					newVariableRefs.push(variableRef);
				}
			});

			// REBUILD IF NO VARIABLE REFS
			if (newVariableRefs.length === 0) {
				const defaultVariableRef = getDefaultVariableRef(inputTypes);

				newVariableRefs.push(defaultVariableRef);
			}

			// APPLY NEW VARIABLE REFS
			const validAnalysis = produce(analysis, draft => {
				draft.input.variables = newVariableRefs.map(variableRef => ({
					name: variableRef
				}));
			});

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// COMPARE NUMERIC
		if (isType(AnalysisType.CompareNumericV1)) {
			analysis = analysis as CompareNumericAnalysisV1;

			const { categoryVariable, exploreVariable, categoryVariableTwo, exploreVariableTwo } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.CompareNumericV1];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable,
					variableRefType: inputVariableTypes.categoryVariable
				},
				{
					key: 'exploreVariable',
					variableRef: exploreVariable,
					variableRefType: inputVariableTypes.exploreVariable
				}
			];

			if (categoryVariableTwo) {
				analysisInputVariables.push({
					key: 'categoryVariableTwo',
					variableRef: categoryVariableTwo,
					variableRefType: inputVariableTypes.categoryVariableTwo,
					omitDefaultVariableRef: true
				});
			}
			if (exploreVariableTwo) {
				analysisInputVariables.push({
					key: 'exploreVariableTwo',
					variableRef: exploreVariableTwo,
					variableRefType: inputVariableTypes.exploreVariableTwo,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}
		if (isType(AnalysisType.CompareNumericV2)) {
			analysis = analysis as CompareNumericAnalysisV2;

			const { categoryVariable, exploreVariable, categoryVariableTwo, exploreVariableTwo } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.CompareNumericV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable?.name ?? '',
					variableRefType: inputVariableTypes.categoryVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'exploreVariable',
					variableRef: exploreVariable?.name ?? '',
					variableRefType: inputVariableTypes.exploreVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				}
			];

			analysisInputVariables.push({
				key: 'categoryVariableTwo',
				variableRef: categoryVariableTwo?.name ?? '',
				variableRefType: inputVariableTypes.categoryVariableTwo,
				omitDefaultVariableRef: true,
				transform: varName => {
					if (!varName) return null;
					return { name: varName };
				}
			});
			analysisInputVariables.push({
				key: 'exploreVariableTwo',
				variableRef: exploreVariableTwo?.name ?? '',
				variableRefType: inputVariableTypes.exploreVariableTwo,
				omitDefaultVariableRef: true,
				transform: varName => {
					if (!varName) return null;
					return { name: varName };
				}
			});

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// CROSSTAB
		if (isType(AnalysisType.Crosstab)) {
			analysis = analysis as CrosstabAnalysis;

			const { yVariable, groupingVariable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.Crosstab];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'yVariable',
					variableRef: yVariable,
					variableRefType: inputVariableTypes.yVariable
				},
				{
					key: 'groupingVariable',
					variableRef: groupingVariable,
					variableRefType: inputVariableTypes.groupingVariable,
					getSecondDefaultVariableRef: true
				}
			];

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// CROSSTAB
		if (isType(AnalysisType.CrosstabV2)) {
			analysis = analysis as CrosstabAnalysisV2;

			const { rowVariable, columnVariable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.CrosstabV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'rowVariable',
					variableRef: rowVariable?.name ?? '',
					variableRefType: inputVariableTypes.rowVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'columnVariable',
					variableRef: columnVariable?.name ?? '',
					variableRefType: inputVariableTypes.columnVariable,
					getSecondDefaultVariableRef: true,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				}
			];

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// KAPLAN MEIER
		if (isType(AnalysisType.Kaplan)) {
			analysis = analysis as KaplanMeierAnalysis;

			const { durationVariable, eventVariable, startDate, endDate, groupVariable } =
				analysis.input.variables;

			const { selectedDataModel } = analysis.input;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.Kaplan];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'durationVariable',
					variableRef: durationVariable,
					variableRefType: inputVariableTypes.durationVariable
				},
				{
					key: 'eventVariable',
					variableRef: eventVariable,
					variableRefType: inputVariableTypes.eventVariable
				},
				{
					key: 'startDate',
					variableRef: startDate,
					variableRefType: inputVariableTypes.startDate
				},
				{
					key: 'endDate',
					variableRef: endDate,
					variableRefType: inputVariableTypes.endDate,
					getSecondDefaultVariableRef: true,
					secondDefaultVariableRefFallback: false
				}
			];

			if (groupVariable) {
				analysisInputVariables.push({
					key: 'groupVariable',
					variableRef: groupVariable,
					variableRefType: inputVariableTypes.groupVariable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = cloneDeep(
				validateAnalysisInputVariables(analysisInputVariables, analysis)
			) as KaplanMeierAnalysis;

			const wasEventVariableValid = eventVariable !== '';
			const isEventVariableValid = validAnalysis.input.variables.eventVariable !== '';

			/**
			 * RESET VARIABLE REF `eventVariable` CATEGORY VALUE `positiveEvent`
			 * IF VARIABLE REF GOT DELETED
			 */
			if (wasEventVariableValid && !isEventVariableValid) {
				validAnalysis.input.variables.positiveEvent[0] = '';
			}

			// UPDATE `positiveEvent` FROM THE NEW VARIABLE REF ASSIGNED
			if (isEventVariableValid) {
				const variable = variablesMap[validAnalysis.input.variables.eventVariable];

				const defaultCategoryValue = variable.categories[0]?.value ?? '';

				validAnalysis.input.variables.positiveEvent[0] = defaultCategoryValue;
			}

			const startDateEndDateSameVariableRef =
				validAnalysis.input.variables.startDate !== '' &&
				validAnalysis.input.variables.endDate !== '' &&
				validAnalysis.input.variables.startDate === validAnalysis.input.variables.endDate;

			// INVALIDATE `endDate` IF `endDate` and `startDate` VARIABLE REFS ARE THE SAME
			if (startDateEndDateSameVariableRef) {
				validAnalysis.input.variables.endDate = '';
			}

			const isStartDateValid = validAnalysis.input.variables.startDate !== '';
			const isEndDateValid = validAnalysis.input.variables.endDate !== '';
			const isFromAndEndDateValid = isStartDateValid && isEndDateValid;

			const isTimeVariableOption = selectedDataModel === KaplanMeierDataModels.duration;
			const isTimeRangeOption =
				selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent;

			// INVALIDATE `selectedTimeOption.RANGE` IF `startDate` AND `endDate` ARE NOT BOTH VALID
			if (isTimeRangeOption && !isFromAndEndDateValid) {
				validAnalysis.input.selectedDataModel = KaplanMeierDataModels.duration;
			}

			const wasDurationVariableValid = durationVariable !== '';
			const isDurationVariableValid = validAnalysis.input.variables.durationVariable !== '';
			const durationVariableBecameInvalid =
				wasDurationVariableValid && !isDurationVariableValid;

			/**
			 * SET `selectedTimeOption.VARIABLE` TO `selectedTimeOption.RANGE` IF
			 * `durationVariable` BECAME INVALID AND
			 * `startDate` AND `endDate` ARE VALID
			 */
			if (isTimeVariableOption && durationVariableBecameInvalid && isFromAndEndDateValid) {
				validAnalysis.input.selectedDataModel = KaplanMeierDataModels.timeRangeWithEvent;
			}

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		if (isType(AnalysisType.KaplanV2)) {
			analysis = analysis as KaplanMeierAnalysisV2;

			const { durationVariable, eventVariable, startDate, endDate, groupVariable } =
				analysis.input.variables;

			const { selectedDataModel } = analysis.input;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.Kaplan];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'durationVariable',
					variableRef: durationVariable?.name ?? '',
					variableRefType: inputVariableTypes.durationVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'eventVariable',
					variableRef: eventVariable?.name ?? '',
					variableRefType: inputVariableTypes.eventVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'startDate',
					variableRef: startDate?.name ?? '',
					variableRefType: inputVariableTypes.startDate,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'endDate',
					variableRef: endDate?.name ?? '',
					variableRefType: inputVariableTypes.endDate,
					getSecondDefaultVariableRef: true,
					secondDefaultVariableRefFallback: false,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				}
			];

			if (groupVariable) {
				analysisInputVariables.push({
					key: 'groupVariable',
					variableRef: groupVariable.name,
					variableRefType: inputVariableTypes.groupVariable,
					omitDefaultVariableRef: true,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				});
			}

			const validAnalysis = cloneDeep(
				validateAnalysisInputVariables(analysisInputVariables, analysis)
			) as KaplanMeierAnalysisV2;

			const wasEventVariableValid = eventVariable;
			const isEventVariableValid = validAnalysis.input.variables.eventVariable;

			/**
			 * RESET VARIABLE REF `eventVariable` CATEGORY VALUE `positiveEvent`
			 * IF VARIABLE REF GOT DELETED
			 */
			if (wasEventVariableValid && !isEventVariableValid) {
				validAnalysis.input.variables.positiveEvent[0] = '';
			}

			// UPDATE `positiveEvent` FROM THE NEW VARIABLE REF ASSIGNED
			if (isEventVariableValid) {
				const variable =
					variablesMap[validAnalysis.input.variables.eventVariable?.name ?? ''];

				const defaultCategoryValue = variable.categories[0]?.value ?? '';

				validAnalysis.input.variables.positiveEvent[0] = defaultCategoryValue;
			}

			const startDateEndDateSameVariableRef =
				validAnalysis.input.variables.startDate &&
				validAnalysis.input.variables.endDate &&
				validAnalysis.input.variables.startDate === validAnalysis.input.variables.endDate;

			// INVALIDATE `endDate` IF `endDate` and `startDate` VARIABLE REFS ARE THE SAME
			if (startDateEndDateSameVariableRef) {
				validAnalysis.input.variables.endDate;
			}

			const isStartDateValid = validAnalysis.input.variables.startDate;
			const isEndDateValid = validAnalysis.input.variables.endDate;
			const isFromAndEndDateValid = isStartDateValid && isEndDateValid;

			const isTimeVariableOption = selectedDataModel === KaplanMeierDataModels.duration;
			const isTimeRangeOption =
				selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent;

			// INVALIDATE `selectedTimeOption.RANGE` IF `startDate` AND `endDate` ARE NOT BOTH VALID
			if (isTimeRangeOption && !isFromAndEndDateValid) {
				validAnalysis.input.selectedDataModel = KaplanMeierDataModels.duration;
			}

			const wasDurationVariableValid = durationVariable;
			const isDurationVariableValid = validAnalysis.input.variables.durationVariable;
			const durationVariableBecameInvalid =
				wasDurationVariableValid && !isDurationVariableValid;

			/**
			 * SET `selectedTimeOption.VARIABLE` TO `selectedTimeOption.RANGE` IF
			 * `durationVariable` BECAME INVALID AND
			 * `startDate` AND `endDate` ARE VALID
			 */
			if (isTimeVariableOption && durationVariableBecameInvalid && isFromAndEndDateValid) {
				validAnalysis.input.selectedDataModel = KaplanMeierDataModels.timeRangeWithEvent;
			}

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		/// CORRELATIONS (old)
		if (isType(AnalysisType.CorrelationsV1)) {
			analysis = analysis as CorrelationsV1Analysis;

			const { xNumericVariable, yNumericVariable, groupingVariable } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.CorrelationsV1];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'xNumericVariable',
					variableRef: xNumericVariable,
					variableRefType: inputVariableTypes.xNumericVariable
				},
				{
					key: 'yNumericVariable',
					variableRef: yNumericVariable,
					variableRefType: inputVariableTypes.yNumericVariable,
					getSecondDefaultVariableRef: true
				}
			];

			if (groupingVariable) {
				analysisInputVariables.push({
					key: 'groupingVariable',
					variableRef: groupingVariable,
					variableRefType: inputVariableTypes.groupingVariable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}
		// CORRELATIONS
		if (isType(AnalysisType.CorrelationsV2)) {
			analysis = analysis as CorrelationsAnalysis;

			const { xVariable, yVariable, groupVariables } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.CorrelationsV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'xVariable',
					variableRef: xVariable?.name ?? '',
					variableRefType: inputVariableTypes.xVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'yVariable',
					variableRef: yVariable?.name ?? '',
					variableRefType: inputVariableTypes.yVariable,
					getSecondDefaultVariableRef: true,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				}
			];

			if (groupVariables.length > 0) {
				analysisInputVariables.push({
					key: 'groupVariables',
					variableRef: groupVariables[0].name,
					variableRefType: inputVariableTypes.groupVariables,
					omitDefaultVariableRef: true,
					transform: varName => {
						if (!varName) return [];
						return [{ name: varName }];
					}
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// PLOT NUMERIC
		if (isType(AnalysisType.PlotNumeric)) {
			analysis = analysis as PlotNumericAnalysis;

			const { categoryVariable, numericVariable, groupingVariable } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.PlotNumeric];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable,
					variableRefType: inputVariableTypes.categoryVariable
				},
				{
					key: 'numericVariable',
					variableRef: numericVariable,
					variableRefType: inputVariableTypes.numericVariable
				}
			];

			if (groupingVariable) {
				analysisInputVariables.push({
					key: 'groupingVariable',
					variableRef: groupingVariable,
					variableRefType: inputVariableTypes.groupingVariable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}
		if (isType(AnalysisType.PlotNumericV2)) {
			analysis = analysis as PlotNumericAnalysisV2;

			const { categoryVariable, numericVariable, groupingVariable } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.PlotNumericV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'categoryVariable',
					variableRef: categoryVariable?.name ?? '',
					variableRefType: inputVariableTypes.categoryVariable,
					transform: name => {
						if (!name) return null;
						return { name: name };
					}
				},
				{
					key: 'numericVariable',
					variableRef: numericVariable?.name ?? '',
					variableRefType: inputVariableTypes.numericVariable,
					transform: name => {
						if (!name) return null;
						return { name: name };
					}
				}
			];

			if (groupingVariable) {
				analysisInputVariables.push({
					key: 'groupingVariable',
					variableRef: groupingVariable?.name ?? '',
					variableRefType: inputVariableTypes.groupingVariable,
					omitDefaultVariableRef: true,
					transform: name => {
						if (!name) return null;
						return { name };
					}
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// DENSITY PLOT
		if (isType(AnalysisType.DensityPlot)) {
			analysis = analysis as DensityPlotAnalysis;

			const { numericVariable, groupingVariable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.DensityPlot];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'numericVariable',
					variableRef: numericVariable,
					variableRefType: inputVariableTypes.numericVariable
				}
			];

			if (groupingVariable) {
				analysisInputVariables.push({
					key: 'groupingVariable',
					variableRef: groupingVariable,
					variableRefType: inputVariableTypes.groupingVariable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}
		if (isType(AnalysisType.DensityPlotV2)) {
			analysis = analysis as DensityPlotAnalysisV2;

			const { numericVariable, groupVariables } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.DensityPlotV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'numericVariable',
					variableRef: numericVariable?.name ?? '',
					variableRefType: inputVariableTypes.numericVariable,
					transform: name => {
						if (!name) return null;
						return name;
					}
				}
			];

			if (groupVariables) {
				analysisInputVariables.push({
					key: 'groupVariables',
					variableRef: groupVariables?.[0].name ?? '',
					variableRefType: inputVariableTypes.groupVariables,
					omitDefaultVariableRef: true,
					transform: name => {
						if (!name) return [];
						return [{ name }];
					}
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// TIME COURSE
		if (isType(AnalysisType.TimeCourseV2)) {
			analysis = analysis as TimeCourseAnalysisV2;

			const { numericVariable, timeVariable, timeUnit, groupVariables } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.TimeCourseV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'numericVariable',
					variableRef: numericVariable?.name ?? '',
					variableRefType: inputVariableTypes.numericVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				},
				{
					key: 'timeVariable',
					variableRef: timeVariable?.name ?? '',
					variableRefType: inputVariableTypes.timeVariable,
					transform: varName => {
						if (!varName) return null;
						return { name: varName };
					}
				}
			];

			analysisInputVariables.push({
				key: 'groupVariables',
				variableRef: groupVariables?.[0]?.name ?? '',
				variableRefType: inputVariableTypes.groupVariables,
				omitDefaultVariableRef: true,
				transform: varName => {
					if (!varName) return [];
					return [{ name: varName }];
				}
			});

			const validAnalysis = validateAnalysisInputVariables(
				analysisInputVariables,
				analysis
			) as TimeCourseAnalysisV2;

			const validAnalysisInputVariables = validAnalysis.input.variables;
			const hasDateRef =
				validAnalysisInputVariables.timeVariable &&
				hasVariableRef(validAnalysisInputVariables.timeVariable?.name);

			if (hasDateRef) {
				const variable = validAnalysisInputVariables.timeVariable
					? variablesMap[validAnalysisInputVariables.timeVariable?.name]
					: null;
				const isDate = variable?.type === VariableType.Date;

				const DATE_TIME_WINDOW_VALUES = [
					TimeWindowSizeType.Hours,
					TimeWindowSizeType.Minutes,
					TimeWindowSizeType.Seconds
				];

				const shouldResetTimeWindow =
					isDate && timeUnit && DATE_TIME_WINDOW_VALUES.includes(timeUnit);

				if (shouldResetTimeWindow) {
					validAnalysisInputVariables.timeUnit = TimeWindowSizeType.Days;
				}
			}

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		if (isType(AnalysisType.TimeCourseV1)) {
			analysis = analysis as TimeCourseAnalysisV1;

			const { numericVariable, dateVariable, timeWindowSize, groupingVariable } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.TimeCourseV1];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'numericVariable',
					variableRef: numericVariable,
					variableRefType: inputVariableTypes.numericVariable
				},
				{
					key: 'dateVariable',
					variableRef: dateVariable,
					variableRefType: inputVariableTypes.dateVariable
				}
			];

			if (groupingVariable) {
				analysisInputVariables.push({
					key: 'groupingVariable',
					variableRef: groupingVariable,
					variableRefType: inputVariableTypes.groupingVariable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(
				analysisInputVariables,
				analysis
			) as TimeCourseAnalysisV1;

			const validAnalysisInputVariables = validAnalysis.input.variables;
			const hasDateRef = hasVariableRef(validAnalysisInputVariables.dateVariable);

			if (hasDateRef) {
				const variable = variablesMap[validAnalysisInputVariables.dateVariable];
				const isDate = variable.type === VariableType.Date;

				const DATE_TIME_WINDOW_VALUES = [
					TimeWindowSizeType.Hours,
					TimeWindowSizeType.Minutes,
					TimeWindowSizeType.Seconds
				];

				const shouldResetTimeWindow =
					isDate && DATE_TIME_WINDOW_VALUES.includes(timeWindowSize);

				if (shouldResetTimeWindow) {
					validAnalysisInputVariables.timeWindowSize = TimeWindowSizeType.Years;
				}
			}

			const hasChanges = !isEqual(analysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// COMPARE PAIRED
		if (isType(AnalysisType.ComparePaired)) {
			analysis = analysis as ComparePairedAnalysis;

			const {
				numericVariableOne,
				numericVariableTwo,
				catVarnameDiffSamples,
				patientIdentifierVarname,
				groupVariable,
				testVariable,
				setName
			} = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.ComparePaired];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'numericVariableOne',
					variableRef: numericVariableOne,
					variableRefType: inputVariableTypes.numericVariableOne
				},
				{
					key: 'numericVariableTwo',
					variableRef: numericVariableTwo,
					variableRefType: inputVariableTypes.numericVariableTwo,
					getSecondDefaultVariableRef: true
				},
				{
					key: 'catVarnameDiffSamples',
					variableRef: catVarnameDiffSamples,
					variableRefType: inputVariableTypes.catVarnameDiffSamples
				},
				{
					key: 'patientIdentifierVarname',
					variableRef: patientIdentifierVarname,
					variableRefType: inputVariableTypes.patientIdentifierVarname,
					getSecondDefaultVariableRef: true
				}
			];

			const initialAnalysis = newAnalysesById[analysis.id];
			const validAnalysis = cloneDeep(
				validateAnalysisInputVariables(analysisInputVariables, analysis)
			) as ComparePairedAnalysis;

			if (validAnalysis.input.variables.setName) {
				const variableSet = variableSetsMap[validAnalysis.input.variables.setName];
				if (variableSet) {
					analysisInputVariables.push({
						key: 'setName',
						variableRef: setName,
						variableRefType: inputVariableTypes.setName
					});
				} else {
					if (Object.keys(variableSetsMap).length > 0) {
						validAnalysis.input.variables.setName =
							Object.values(variableSetsMap)[0].setName;
						analysisInputVariables.push({
							key: 'setName',
							variableRef: Object.values(variableSetsMap)[0].setName,
							variableRefType: inputVariableTypes.setName
						});
					} else {
						validAnalysis.input.variables.setName = '';
						validAnalysis.input.dataModel =
							'SINGLE_ENTRY_PER_SUBJECT' as ComparePairedDataModels;
					}
				}
			}

			const testVariableData = variablesMap[testVariable];
			if (
				testVariableData &&
				variablesFromSet[validAnalysis.input.variables.setName].numericVariableNames.some(
					variable => variable === testVariable
				)
			) {
				analysisInputVariables.push({
					key: 'testVariable',
					variableRef: testVariable,
					variableRefType: inputVariableTypes.testVariable
				});
			} else {
				if (
					variablesFromSet[validAnalysis.input.variables.setName] &&
					variablesFromSet[validAnalysis.input.variables.setName].numericVariableNames
						.length > 0
				) {
					validAnalysis.input.variables.testVariable =
						variablesFromSet[
							validAnalysis.input.variables.setName
						].numericVariableNames[0];

					if (validAnalysis.input.variables.testVariable) {
						analysisInputVariables.push({
							key: 'testVariable',
							variableRef: validAnalysis.input.variables.testVariable,
							variableRefType: inputVariableTypes.testVariable
						});
					}
				} else {
					validAnalysis.input.variables.testVariable = '';
				}
			}

			const groupVariableData = variablesMap[validAnalysis.input.variables.groupVariable];
			if (
				groupVariableData &&
				variablesFromSet[validAnalysis.input.variables.setName].categoryVariableNames.some(
					variable => variable === groupVariable
				)
			) {
				analysisInputVariables.push({
					key: 'groupVariable',
					variableRef: groupVariable,
					variableRefType: inputVariableTypes.groupVariable
				});
			} else if (
				variablesFromSet[validAnalysis.input.variables.setName] &&
				variablesFromSet[validAnalysis.input.variables.setName].categoryVariableNames
					.length > 0
			) {
				validAnalysis.input.variables.groupVariable =
					variablesFromSet[
						validAnalysis.input.variables.setName
					].categoryVariableNames[0];

				if (validAnalysis.input.variables.groupVariable) {
					analysisInputVariables.push({
						key: 'groupVariable',
						variableRef: validAnalysis.input.variables.groupVariable,
						variableRefType: inputVariableTypes.groupVariable
					});
				}
			} else {
				validAnalysis.input.variables.groupVariable = '';
			}

			let categories: VariableCategory[] = [];
			const name =
				analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
					? groupVariable
					: catVarnameDiffSamples;

			let variable = variablesMap[groupVariable];
			if (name in aggregatorVariableNameByAggregationRuleName) {
				const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

				variable = variablesMap[aggregatorVariableName];
			}

			if (variable) {
				categories = variable.categories;
			}
			if (categories.length) {
				if (categories.length === 1) {
					if (analysis.input.dataModel === ComparePairedDataModels.USING_SERIES) {
						validAnalysis.input.variables.groupOne = categories[0].label;
					} else {
						validAnalysis.input.variables.firstCategoryValue = categories[0].label;
					}

					analysisInputVariables.push({
						key:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? 'groupOne'
								: 'firstCategoryValue',
						variableRef: categories[0].label,
						variableRefType:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? inputVariableTypes.groupOne
								: inputVariableTypes.firstCategoryValue
					});
				} else if (categories.length > 1) {
					if (analysis.input.dataModel === ComparePairedDataModels.USING_SERIES) {
						validAnalysis.input.variables.groupOne = categories[0].label;
						validAnalysis.input.variables.groupTwo = categories[1].label;
					} else {
						validAnalysis.input.variables.firstCategoryValue = categories[0].label;
						validAnalysis.input.variables.secondCategoryValue = categories[1].label;
					}
					analysisInputVariables.push({
						key:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? 'groupOne'
								: 'firstCategoryValue',
						variableRef: categories[0].label,
						variableRefType:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? inputVariableTypes.groupOne
								: inputVariableTypes.firstCategoryValue
					});
					analysisInputVariables.push({
						key:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? 'groupTwo'
								: 'secondCategoryValue',
						variableRef: categories[1].label,
						variableRefType:
							analysis.input.dataModel === ComparePairedDataModels.USING_SERIES
								? inputVariableTypes.groupTwo
								: inputVariableTypes.secondCategoryValue
					});
				}
			}

			const hasChanges = !isEqual(initialAnalysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// LOGISTIC REGRESSION
		if (isType(AnalysisType.LogisticRegression)) {
			analysis = analysis as LogisticRegressionAnalysis;

			const { dependentVariable, independentVariable, positiveEvent } =
				analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.LogisticRegression];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'independentVariable',
					variableRef: independentVariable,
					variableRefType: inputVariableTypes.independentVariable
				},
				{
					key: 'dependentVariable',
					variableRef: dependentVariable,
					variableRefType: inputVariableTypes.dependentVariable
				}
			];

			const validAnalysis = cloneDeep(
				validateAnalysisInputVariables(analysisInputVariables, analysis)
			) as LogisticRegressionAnalysis;

			let categories: string[] = [];

			let variable = variablesMap[dependentVariable];
			if (dependentVariable in aggregatorVariableNameByAggregationRuleName) {
				const aggregatorVariableName =
					aggregatorVariableNameByAggregationRuleName[dependentVariable];

				variable = variablesMap[aggregatorVariableName];
			}

			if (variable) {
				categories = variable.categories.map(value => value.value);
			}

			const finalCategories: string[] = [];

			if (positiveEvent.length) {
				positiveEvent.forEach(value => {
					if (categories.includes(value)) {
						finalCategories.push(value);
					}
				});
			} else {
				if (categories.length) {
					finalCategories.push(categories[0]);
				}
			}

			validAnalysis.input.variables.positiveEvent = finalCategories;

			const initialAnalysis = newAnalysesById[analysis.id];
			const hasChanges = !isEqual(initialAnalysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		if (isType(AnalysisType.LogisticRegressionV2)) {
			analysis = analysis as LogisticRegressionAnalysisV2;

			const { xVariable, yVariable, outcomes } = analysis.input.variables;

			const inputVariableTypes =
				analysisInputVariableTypes[AnalysisType.LogisticRegressionV2];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'xVariable',
					variableRef: xVariable?.name ?? '',
					variableRefType: inputVariableTypes.xVariable,
					transform: name => {
						if (!name) return null;
						return { name };
					}
				},
				{
					key: 'yVariable',
					variableRef: yVariable?.name ?? '',
					variableRefType: inputVariableTypes.yVariable,
					transform: name => {
						if (!name) return null;
						return { name };
					}
				}
			];

			const validAnalysis = cloneDeep(
				validateAnalysisInputVariables(analysisInputVariables, analysis)
			) as LogisticRegressionAnalysis;

			let categories: string[] = [];

			let variable = variablesMap[yVariable?.name ?? ''];
			if (yVariable && yVariable.name in aggregatorVariableNameByAggregationRuleName) {
				const aggregatorVariableName =
					aggregatorVariableNameByAggregationRuleName[yVariable.name];

				variable = variablesMap[aggregatorVariableName];
			}

			if (variable) {
				categories = variable.categories.map(value => value.value);
			}

			const finalCategories: string[] = [];

			if (outcomes.length) {
				outcomes.forEach(value => {
					if (categories.includes(value)) {
						finalCategories.push(value);
					}
				});
			} else {
				if (categories.length) {
					finalCategories.push(categories[0]);
				}
			}

			validAnalysis.input.variables.positiveEvent = finalCategories;

			const initialAnalysis = newAnalysesById[analysis.id];
			const hasChanges = !isEqual(initialAnalysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		// NUMBER PLOT XY
		if (isType(AnalysisType.NumberPlotXY)) {
			analysis = analysis as NumberPlotXYAnalysis;

			const { x_value, y_value, grouping_variable } = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.NumberPlotXY];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'x_value',
					variableRef: x_value,
					variableRefType: inputVariableTypes.x_value
				},
				{
					key: 'y_value',
					variableRef: y_value[0],
					variableRefType: inputVariableTypes.y_value,
					getSecondDefaultVariableRef: true,
					transform: name => [name]
				}
			];

			if (grouping_variable) {
				analysisInputVariables.push({
					key: 'grouping_variable',
					variableRef: grouping_variable,
					variableRefType: inputVariableTypes.grouping_variable,
					omitDefaultVariableRef: true
				});
			}

			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(analysis, validAnalysis);

			// validateAnalysisInputVariables function does not support type transforms, we need to cast y_value to array here;
			if (hasChanges) {
				newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
			}
		}

		// JADBIO
		if (isType(AnalysisType.JADBio)) {
			analysis = analysis as JADBioAnalysis;

			const {
				classificationVariable,
				eventVariableName,
				timeToEventVariableName,
				regressionVariable
			} = analysis.input.variables;

			const inputVariableTypes = analysisInputVariableTypes[AnalysisType.JADBio];

			const analysisInputVariables: AnalysisInputVariables = [
				{
					key: 'classificationVariable',
					variableRef: classificationVariable,
					variableRefType: inputVariableTypes.classificationVariable
				},
				{
					key: 'eventVariableName',
					variableRef: eventVariableName,
					variableRefType: inputVariableTypes.eventVariableName
				},
				{
					key: 'timeToEventVariableName',
					variableRef: timeToEventVariableName,
					variableRefType: inputVariableTypes.timeToEventVariableName
				},
				{
					key: 'regressionVariable',
					variableRef: regressionVariable,
					variableRefType: inputVariableTypes.regressionVariable
				}
			];

			const initialAnalysis = newAnalysesById[analysis.id];
			const validAnalysis = validateAnalysisInputVariables(analysisInputVariables, analysis);

			const hasChanges = !isEqual(initialAnalysis, validAnalysis);

			if (hasChanges) newAnalysesById[analysis.id] = resetAnalysisInputOutput(validAnalysis);
		}

		function isType(analysisType: AnalysisType): boolean {
			return analysis.type === analysisType;
		}
	});

	/**
	 * Checks if `variableRef` exists and if `variable.type` corresponds to analysis `variableRefType` type.
	 *
	 * @param variableRef variable name
	 * @param variableRefType
	 *
	 * @returns boolean
	 */
	function isVariableRefValid(
		variableRef: string,
		variableRefType: VariableType | VariableType[]
	): boolean {
		let variable = variablesMap[variableRef];

		if (variableRef in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[variableRef];

			variable = variablesMap[aggregatorVariableName];
		}

		// VARIABLE GOT DELETED - INVALID
		if (!variable) return false;

		if (Array.isArray(variableRefType)) {
			const variableRefTypes = variableRefType;

			return variableRefTypes.includes(variable.type);
		}

		return variable.type === variableRefType;
	}

	/**
	 *
	 * @param variableRefType variable name
	 * @param second if `true` returns the 2nd variable name; default: returns 1st
	 *
	 * @returns variable name or empty string if not found
	 */
	function getDefaultVariableRef(
		variableRefType: VariableType | VariableType[],
		options: {
			second?: boolean;
			fallbackToFirst?: boolean;
		} = {
			second: false,
			fallbackToFirst: true
		}
	): string {
		let defaultVariableRef = '';

		const isDurationVariable = VariableType.TimeDuration === variableRefType;

		const isNumericVariable = Array.isArray(variableRefType)
			? variableRefType.some(type =>
					[VariableType.Integer, VariableType.Float].includes(type)
			  )
			: [VariableType.Integer, VariableType.Float].includes(variableRefType);
		const isCategoryVariable = Array.isArray(variableRefType)
			? variableRefType.includes(VariableType.Category)
			: VariableType.Category === variableRefType;
		const isCategoryMultipleVariable = Array.isArray(variableRefType)
			? variableRefType.includes(VariableType.CategoryMultiple)
			: VariableType.CategoryMultiple === variableRefType;
		const isDateVariable = Array.isArray(variableRefType)
			? variableRefType.some(type =>
					[VariableType.Date, VariableType.DateTime].includes(type)
			  )
			: [VariableType.Date, VariableType.DateTime].includes(variableRefType);

		const { second, fallbackToFirst } = options;

		if (isNumericVariable) {
			if (second) {
				if (fallbackToFirst) {
					defaultVariableRef = numericVariableNames[1] ?? numericVariableNames[0] ?? '';
				} else {
					defaultVariableRef = numericVariableNames[1] ?? '';
				}
			} else {
				defaultVariableRef = numericVariableNames[0] ?? '';
			}
		} else if (isCategoryVariable) {
			if (second) {
				if (fallbackToFirst) {
					defaultVariableRef = categoryVariableNames[1] ?? categoryVariableNames[0] ?? '';
				} else {
					defaultVariableRef = categoryVariableNames[1] ?? '';
				}
			} else {
				defaultVariableRef = categoryVariableNames[0] ?? '';
			}
		} else if (isCategoryMultipleVariable) {
			if (second) {
				if (fallbackToFirst) {
					defaultVariableRef = categoryVariableNames[1] ?? categoryVariableNames[0] ?? '';
				} else {
					defaultVariableRef = categoryVariableNames[1] ?? '';
				}
			} else {
				defaultVariableRef = categoryVariableNames[0] ?? '';
			}
		} else if (isDateVariable) {
			if (second) {
				if (fallbackToFirst) {
					defaultVariableRef = dateVariableNames[1] ?? dateVariableNames[0] ?? '';
				} else {
					defaultVariableRef = dateVariableNames[1] ?? '';
				}
			} else {
				defaultVariableRef = dateVariableNames[0] ?? '';
			}
		} else if (isDurationVariable) {
			if (second) {
				if (fallbackToFirst) {
					defaultVariableRef = durationVariableNames[1] ?? durationVariableNames[0] ?? '';
				} else {
					defaultVariableRef = durationVariableNames[1] ?? '';
				}
			} else {
				defaultVariableRef = durationVariableNames[0] ?? '';
			}
		}

		return defaultVariableRef;
	}

	/**
	 * - invalidates input variable refs if variable was deleted or if `variable.type` changed
	 * and doesn't correspond to analysis input variable ref type
	 * - validates
	 *
	 * @param analysisInputVariables
	 * @param analysis analysis to validate
	 */
	function validateAnalysisInputVariables(
		analysisInputVariables: AnalysisInputVariables,
		analysis: Analysis
	): Analysis {
		return produce(analysis, draft => {
			analysisInputVariables.forEach(
				({
					key,
					variableRef,
					variableRefType,
					getSecondDefaultVariableRef,
					secondDefaultVariableRefFallback,
					omitDefaultVariableRef,
					transform
				}) => {
					let newVariableRef = variableRef;
					/**
					 * INVALIDATE VARIABLE REF IF:
					 * - VARIABLE DOES NOT EXIST
					 * - VARIABLE TYPE HAS CHANGED
					 */
					if (
						hasVariableRef(variableRef) &&
						!isVariableRefValid(variableRef, variableRefType)
					) {
						newVariableRef = '';
					}

					// REBUILD VARIABLE REF IF EMPTY AFTER VALIDATION
					if (!hasVariableRef(newVariableRef)) {
						// KEEP THIS HERE FOR EASY DEBUGGING IN PREPROD/PROD
						if (!(key in draft.input.variables)) {
							console.error(
								`validateAnalysisInput() -> key '${key}' does not exist in analysis '${analysis.type}'`
							);
						}

						// DO NOT REPLACE WITH ANOTHER REFERENCE (?)
						if (omitDefaultVariableRef) {
							if (newVariableRef) {
								// @ts-ignore
								draft.input.variables[key] = newVariableRef;
							} else {
								// @ts-ignore - TODO: find another way to type the `key` here
								delete draft.input.variables[key];
							}
						} else {
							const defaultVariableRef = getDefaultVariableRef(variableRefType, {
								second: getSecondDefaultVariableRef,
								fallbackToFirst: secondDefaultVariableRefFallback
							});

							if (transform) {
								// @ts-ignore - TODO: find another way to type the `key` here
								draft.input.variables[key] = transform(defaultVariableRef);
							} else {
								// @ts-ignore - TODO: find another way to type the `key` here
								draft.input.variables[key] = defaultVariableRef;
							}
						}
					}
				}
			);
		});
	}

	const shouldRebuildAnalyses = !isEqual(analysesById, newAnalysesById);

	const output = {
		shouldRebuildAnalyses,
		newAnalysesById
	};

	return output;
}

function hasVariableRef(variableRef: string): boolean {
	return variableRef !== '';
}

/**
 * RETURNS THE VARIABLE TYPE OF EACH ANALYSIS VARIABLE INPUT BY ANALYSIS TYPE
 */
export const analysisInputVariableTypes = {
	[AnalysisType.Frequencies]: {
		categoryVariable: VariableType.Category
	},
	[AnalysisType.FrequenciesV2]: {
		categoryVariable: [VariableType.Category, VariableType.CategoryMultiple]
	},
	[AnalysisType.Explore]: [VariableType.Integer, VariableType.Float, VariableType.TimeDuration],
	[AnalysisType.ExploreV2]: [VariableType.Integer, VariableType.Float, VariableType.TimeDuration],
	[AnalysisType.CompareNumericV1]: {
		categoryVariable: VariableType.Category,
		exploreVariable: [VariableType.Integer, VariableType.Float],
		categoryVariableTwo: VariableType.Category,
		exploreVariableTwo: [VariableType.Integer, VariableType.Float]
	},
	[AnalysisType.CompareNumericV2]: {
		categoryVariable: VariableType.Category,
		exploreVariable: [VariableType.Integer, VariableType.Float],
		categoryVariableTwo: VariableType.Category,
		exploreVariableTwo: [VariableType.Integer, VariableType.Float]
	},
	[AnalysisType.Crosstab]: {
		yVariable: VariableType.Category,
		groupingVariable: VariableType.Category
	},
	[AnalysisType.CrosstabV2]: {
		rowVariable: VariableType.Category,
		columnVariable: VariableType.Category
	},
	[AnalysisType.Kaplan]: {
		durationVariable: [VariableType.Integer, VariableType.Float],
		eventVariable: VariableType.Category,
		startDate: [VariableType.Date, VariableType.DateTime],
		endDate: [VariableType.Date, VariableType.DateTime],
		groupVariable: VariableType.Category
	},
	[AnalysisType.CorrelationsV1]: {
		xNumericVariable: [VariableType.Integer, VariableType.Float],
		yNumericVariable: [VariableType.Integer, VariableType.Float],
		groupingVariable: VariableType.Category
	},
	[AnalysisType.CorrelationsV2]: {
		xVariable: [VariableType.Integer, VariableType.Float],
		yVariable: [VariableType.Integer, VariableType.Float],
		groupVariables: [VariableType.Category, VariableType.Integer, VariableType.Unique]
	},
	[AnalysisType.PlotNumeric]: {
		categoryVariable: VariableType.Category,
		numericVariable: [VariableType.Integer, VariableType.Float],
		groupingVariable: VariableType.Category
	},
	[AnalysisType.PlotNumericV2]: {
		categoryVariable: VariableType.Category,
		numericVariable: [VariableType.Integer, VariableType.Float],
		groupingVariable: VariableType.Category
	},
	[AnalysisType.DensityPlot]: {
		numericVariable: [VariableType.Integer, VariableType.Float],
		groupingVariable: VariableType.Category
	},
	[AnalysisType.DensityPlotV2]: {
		numericVariable: [VariableType.Integer, VariableType.Float],
		groupVariables: VariableType.Category
	},
	[AnalysisType.TimeCourseV2]: {
		numericVariable: [VariableType.Integer, VariableType.Float],
		timeVariable: [VariableType.Date, VariableType.DateTime],
		groupVariables: [VariableType.Category]
	},
	[AnalysisType.TimeCourseV1]: {
		numericVariable: [VariableType.Integer, VariableType.Float],
		dateVariable: [VariableType.Date, VariableType.DateTime],
		groupingVariable: VariableType.Category
	},
	[AnalysisType.ComparePaired]: {
		numericVariableOne: [VariableType.Integer, VariableType.Float],
		numericVariableTwo: [VariableType.Integer, VariableType.Float],
		catVarnameDiffSamples: VariableType.Category,
		firstCategoryValue: VariableType.String,
		secondCategoryValue: VariableType.String,
		groupOne: VariableType.String,
		groupTwo: VariableType.String,
		patientIdentifierVarname: [VariableType.String, VariableType.Integer],
		setName: [VariableType.String],
		testVariable: [VariableType.Integer, VariableType.Float],
		groupVariable: VariableType.Category
	},
	[AnalysisType.NumberPlotXY]: {
		x_value: [
			VariableType.Integer,
			VariableType.Float,
			VariableType.Date,
			VariableType.DateTime
		],
		y_value: [VariableType.Integer, VariableType.Float],
		grouping_variable: VariableType.Category
	},
	[AnalysisType.LogisticRegression]: {
		independentVariable: [VariableType.Integer, VariableType.Float],
		groupVariable: VariableType.Category,
		dependentVariable: VariableType.Category,
		positiveEvent: [VariableType.String]
	},
	[AnalysisType.LogisticRegressionV2]: {
		xVariable: [VariableType.Integer, VariableType.Float],
		groupVariables: [VariableType.Category],
		yVariable: VariableType.Category,
		outcomes: [VariableType.String]
	},
	[AnalysisType.JADBio]: {
		classificationVariable: VariableType.Category,
		eventVariableName: VariableType.Category,
		timeToEventVariableName: [VariableType.Integer, VariableType.Float],
		regressionVariable: [VariableType.Integer, VariableType.Float]
	}
};
