import { BrowserRouter } from 'react-router-dom';

import { Alerts } from './Alerts';
import ErrorBoundary from './ErrorBoundary';
import { Init } from './Init';
import { Navigation } from './Navigation';
import { PageLifecycle } from './PageLifecycle';
import { Store } from './Store';

export function App() {
	return (
		<BrowserRouter>
			<Store>
				<PageLifecycle>
					<Init>
						<ErrorBoundary>
							<Alerts>
								<Navigation />
							</Alerts>
						</ErrorBoundary>
					</Init>
				</PageLifecycle>
			</Store>
		</BrowserRouter>
	);
}
