import { OperationResult } from 'hooks/store/types';
import { ActionTypes, getDPA, selectDPAFiles, DPAFiles } from 'store/data/projects';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity, useIsProjectValid } from 'hooks/store';

export function useGetProjectDPA(): OperationResult<DPAFiles> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const data = useSelector(state => selectDPAFiles(state.data.projects));
	const fetched = useSelector(state => state.data.projects.DPA.fetched);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DPA);

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid) handler();
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getDPA());
	}

	return [{ data, loading, error, fetched }, handler];
}
