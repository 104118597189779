import { Controller, useFormContext } from 'react-hook-form';

import { ProjectDPASignData } from 'api/data/projects';
import { useTranslation } from 'hooks/store';
import { InputType } from 'types/index';

import { handleFieldAutoFocus } from '../helpers';

import { Steps } from '../../ProjectDPAForm';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';

export function ProjectDPAProcessingPurpose() {
	// const { translate } = useTranslation();

	const { translate } = useTranslation();

	const { getValues } = useFormContext<ProjectDPASignData>();

	const { shouldAutoFocus } = handleFieldAutoFocus({
		formValues: getValues(),
		step: Steps.ProcessingPurpose
	});

	return (
		<>
			<Controller
				name="purpose"
				render={({ field: { ref, name, value, onChange, onBlur } }) => (
					<Input
						ref={ref}
						type={InputType.Text}
						label={translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.whatIsThePurpose
						)}
						value={value}
						hint={translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.specificPurposeExamples
						)}
						onChange={onChange}
						onBlur={onBlur}
						autoFocus={shouldAutoFocus(name)}
						required
					/>
				)}
			/>

			<Spacer size={s => s.s} />

			<Controller
				name="personalData"
				render={({ field: { ref, name, value, onChange, onBlur } }) => (
					<Input
						ref={ref}
						type={InputType.Text}
						label={translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.whatIsPersonalData
						)}
						hint={translate(
							dict =>
								dict.projects.createAndImport.DPA.hasDPA
									.specificPerosnalDataExamples
						)}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						autoFocus={shouldAutoFocus(name)}
						required
					/>
				)}
			/>

			<Spacer size={s => s.s} />

			<Controller
				name="dataSubjects"
				render={({ field: { ref, name, value, onChange, onBlur } }) => (
					<Input
						ref={ref}
						type={InputType.Text}
						label={translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.whatAreTheCategories
						)}
						hint={translate(
							dict =>
								dict.projects.createAndImport.DPA.hasDPA.specificCategoriesExamples
						)}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						autoFocus={shouldAutoFocus(name)}
						required
					/>
				)}
			/>
		</>
	);
}
