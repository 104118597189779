import { CognitoUser } from '@aws-amplify/auth';

export enum ChallengeType {
	Mfa = 'SOFTWARE_TOKEN_MFA',
	NewPassword = 'NEW_PASSWORD_REQUIRED'
}

export enum MfaType {
	OneTimePassword = 'TOTP',
	None = 'NOMFA'
}

export interface Credentials {
	username: string;
	password: string;
}

export interface User extends CognitoUser {
	signInUserSession: any;
	username: string;
	challengeName: ChallengeType;
	challengeParam: {
		requiredAttributes: string[];
	};
	preferredMFA: MfaType;
}

export interface CompleteFirstLoginInput {
	user: User;
	password: string;
	phone: string;
}

export interface ResetPasswordInput {
	password: string;
	code: string;
}

export interface CompleteResetPasswordInput extends ResetPasswordInput {
	username: string;
}
