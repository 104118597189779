interface Props {
	value: string[];
}

export function YStatistic({ value }: Props) {
	const slope = Number(value[0]);
	const intercept = Number(value[1]);

	let computedSlope: string | null = null;
	let computedIntercept: string | null = null;
	let slopePow: string | null = null;
	let interceptPow: string | null = null;

	if (slope < 0 && slope > -0.001) {
		computedSlope = slope.toExponential();

		const [lead, decimal, pow] = computedSlope.split(/e|\./);

		slopePow = pow;
		computedSlope = `${lead}.${decimal}`;
		computedSlope = Number(computedSlope).toFixed(2);
	} else if (slope > 0 && slope < 0.001) {
		computedSlope = slope.toExponential();

		const [lead, decimal, pow] = computedSlope.split(/e|\./);

		slopePow = pow;
		computedSlope = `${lead}.${decimal}`;
		computedSlope = Number(computedSlope).toFixed(2);
	} else {
		computedSlope = slope.toFixed(2);
	}

	if (intercept < 0 && intercept > -0.001) {
		computedIntercept = intercept.toExponential();

		const [lead, decimal, pow] = computedIntercept.split(/e|\./);

		interceptPow = pow;
		computedIntercept = `${lead}.${decimal}`;
		computedIntercept = Number(computedIntercept).toFixed(2);
	} else if (intercept > 0 && intercept < 0.001) {
		computedIntercept = intercept.toExponential();

		const [lead, decimal, pow] = computedIntercept.split(/e|\./);

		interceptPow = pow;
		computedIntercept = `${lead}.${decimal}`;
		computedIntercept = Number(computedIntercept).toFixed(2);
	} else {
		computedIntercept = intercept.toFixed(2);
	}

	const finalSlope = slopePow ? (
		<>
			{computedSlope}*10<sup>{slopePow}</sup>
		</>
	) : (
		computedSlope
	);

	const finalIntercept = interceptPow ? (
		<>
			{computedIntercept}*10<sup>{interceptPow}</sup>
		</>
	) : (
		computedIntercept
	);

	if (typeof finalIntercept === 'string' && finalIntercept.substring(0, 1) !== '-') {
		return (
			<>
				y = {finalSlope}x + {finalIntercept}
			</>
		);
	}
	return (
		<>
			y = {finalSlope}x {finalIntercept}
		</>
	);
}
