import { useMemo } from 'react';
import { isEmpty, isNumber } from 'lodash';
import { nanoid as generate } from 'nanoid';
import {
	AnalysisStatisticAggregationType,
	TimeWindowSizeType,
	TimeCourseResultsDataV2
} from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { ZingChartClickEvent } from 'zingchart-react';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import { useComputeTimeCourseChartData as useComputeData } from 'helpers/analysis';
import {
	useTranslation,
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';

interface Props {
	id?: string;
	data: TimeCourseResultsDataV2;
	grouping: boolean;
	isLegendEnabled: boolean;
	timeWindowSize: TimeWindowSizeType;
	legendHeader?: string;
	errorBar?: AnalysisStatisticAggregationType;
	isForExport?: boolean;
	scalesLabels: ScalesLabels;
	loading: boolean;
	isConfigPanelOpen: boolean;
}

export function TimeCourseChart({
	id,
	data,
	grouping,
	isLegendEnabled,
	legendHeader,
	timeWindowSize,
	errorBar,
	isForExport,
	scalesLabels,
	loading,
	isConfigPanelOpen
}: Props) {
	const { translate } = useTranslation();
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumns] = useAnalysesActiveColum();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const { series, options } = useComputeData(
		data,
		grouping,
		activeColumns,
		!!fullscreen,
		isLegendEnabled,
		scalesLabels,
		plots,
		timeWindowSize,
		errorBar,
		legendHeader
	);

	const renderId = useMemo(generate, [
		fullscreen,
		activeColumns,
		windowSize,
		analysisIds,
		isConfigPanelOpen,
		filters
	]);

	function onTogglePlot(e: ZingChartClickEvent) {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}

	/**
	 * Returns the total number of nodes the chart would have to go through in order to generate
	 * Is required in order to not freeze the page, when a user would attempt to generate a chart with
	 * millions of seconds / minutes, that would take too much to render, freezing the page
	 *
	 * @returns the number of seconds or minutes, based on analysis config
	 */
	const canCalculateChart = () => {
		let min = Infinity;
		let max = -Infinity;
		for (const entry of series) {
			if (entry.values) {
				for (let i = 0; i < entry.values.length; i++) {
					if (isNumber(entry.values[i] as number)) {
						// number
						min = Math.min(min, entry.values[i] as number);
						max = Math.max(max, entry.values[i] as number);
					} else {
						// is array
						const xCoordOfValue = (entry.values[i] as (number | null)[])[0];
						// check if value is Null
						if (isNumber(xCoordOfValue)) {
							min = Math.min(min, xCoordOfValue);
							max = Math.max(max, xCoordOfValue);
						}
					}
				}
			}
		}

		switch (options.scaleX.step) {
			case 'minute':
				return differenceInMinutes(max, min);
			case 'second':
				return differenceInSeconds(max, min);
			default:
				// there is no need to check for anything bigger than minutes
				return 0;
		}
	};

	if (isEmpty(data) && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	// check set at 500k values. 5.78 days, if time window is seconds, 347 days if time window is minutes
	if (canCalculateChart() > 500000) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.tooMuchData)} />
			</AnalysisContainer>
		);
	}

	return (
		<Chart
			key={renderId}
			onLegendItemClick={onTogglePlot}
			id={id}
			type={t => t.Line}
			series={series}
			options={options}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
