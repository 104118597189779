import type { SuggestedVariableTypes } from 'api/data/projects';
import { VariableType } from 'types/data/variables/constants';
import { PreviewVariable } from 'types/data/projects/import/types';
import { nanoid as generate } from 'nanoid';
import {
	ACCEPTED_DATA_TYPES,
	DATE_FORMATS,
	DATE_TIME_FORMATS,
	DATE_TIME_TYPE_REGEX,
	DATE_TYPE_REGEX
} from 'types/data/projects/import/constants';
import { getUserTimeZoneSelectItem } from './importTimezone';
import type { Variable } from 'api/data/variables';
import { SPECIAL_CHARACTERS } from 'consts';

export function getPreviewVariablesAndTypes(
	variables: SuggestedVariableTypes[],
	timezoneDuringImport?: boolean
): {
	variables: PreviewVariable[];
	hasTypesIncluded: boolean;
} {
	let hasTypesIncluded = false;

	const formattedVariables = variables.map(variable => {
		hasTypesIncluded = true;

		const name = variable.name;

		const typeValue = variable.suggestedVariableType;
		const typeValueLowercase = variable.suggestedVariableType.toLowerCase() as VariableType;

		const labelValue = variable.label ?? variable.name;

		const dateTypeMatchResult = typeValue.match(DATE_TYPE_REGEX);
		const isDateWithFormat = dateTypeMatchResult && dateTypeMatchResult.length > 1;

		const dateTimeTypeMatchResult = typeValue.match(DATE_TIME_TYPE_REGEX);
		const isDateTimeWithFormat = dateTimeTypeMatchResult && dateTimeTypeMatchResult.length > 1;

		let dateFormat = variable.dateFormat;

		if (!dateFormat) {
			if (variable.suggestedVariableType === VariableType.Date) {
				dateFormat = DATE_FORMATS.Line;
			}
			if (variable.suggestedVariableType === VariableType.DateTime) {
				dateFormat = DATE_TIME_FORMATS.Line;
			}
		}

		const obj = {
			name,
			type: getFormatedVariableType(
				typeValueLowercase,
				!!isDateWithFormat,
				!!isDateTimeWithFormat
			),
			typeError: '',
			label: labelValue,
			labelError: '',
			isNew: true,
			omit: false,
			previewVariableName: '',
			previewVariableLabel: labelValue,
			previewVariableLabelError: '',
			dateFormat,
			customDateFormat: '',
			dateFormatError: '',
			isExcelDateFormat: false,
			id: generate(),
			...(timezoneDuringImport &&
				variable.suggestedVariableType === VariableType.DateTime &&
				variable.dateFormat &&
				!/(%z)/i.test(variable.dateFormat) && {
					timeZone: getUserTimeZoneSelectItem()
				})
		} as PreviewVariable;

		return obj;
	});

	return {
		variables: formattedVariables as PreviewVariable[],
		hasTypesIncluded
	};
}

function getFormatedVariableType(
	type: VariableType,
	isDateWithFormat: boolean,
	isDateTimeWithFormat: boolean
): VariableType | string {
	if (isDateWithFormat) {
		return VariableType.Date;
	}

	if (isDateTimeWithFormat) {
		return VariableType.DateTime;
	}

	const isAcceptedType = Object.values(ACCEPTED_DATA_TYPES)
		.toString()
		.toLocaleLowerCase()
		.includes(type);

	if (isAcceptedType) {
		if (type === VariableType.CategoryMultiple.toLowerCase()) {
			return VariableType.CategoryMultiple;
		}
		if (type === VariableType.Unique.toLowerCase()) {
			return VariableType.Unique;
		}
		return type;
	}

	return '';
}

export function hasDuplicateLabels(variable: PreviewVariable, oldVariables: Variable[]) {
	let isDuplicate = false;
	oldVariables.forEach(oldVariable => {
		if (oldVariable.label.trim() === variable.label.replaceAll(SPECIAL_CHARACTERS, '').trim()) {
			isDuplicate = true;
		}
	});

	return isDuplicate;
}
