import { useEffect, useState, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';

import { Columns, ExploreAnalysis } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { Svgs, Colors } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { ConfigContainer } from '../../UI';

import { Button, SelectContainer } from './ExploreConfig.style';
import { Icon } from 'components/UI/Icons';
import { CollapsibleCard } from 'components/Projects/Filters/Filter.style';
import { Gap } from 'components/UI/Gap';
import { Typography } from 'components/UI/Typography';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	useTranslation,
	useUpdateAnalysis,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';

interface Props {
	analysis: ExploreAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function ExploreConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const usedVariableNames = useMemo(
		() => analysis.input.variables.map(({ variableName }) => variableName),
		[analysis.input.variables]
	);

	const [newVariable, setNewVariable] = useState(false);
	const [variablesList, setVariablesList] = useState(usedVariableNames);

	const [activeColumn] = useAnalysesActiveColum();
	const { selectItemsMap, numericSelectItems, durationSelectItems } = variablesDataSelectItems;
	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	/**
	 * Omit `variablesList`
	 */
	const filteredSelectItems = useMemo(() => {
		function match(item: (typeof numericSelectItems)[0]): boolean {
			if ('options' in item) {
				item.options = item.options.filter(match);

				return item.options.length > 0;
			}

			return !variablesList.includes(item.value);
		}

		return cloneDeep([...numericSelectItems, ...durationSelectItems]).filter(match);
	}, [numericSelectItems, durationSelectItems, variablesList]);

	useEffect(() => {
		if (!isEqual(usedVariableNames, variablesList)) setVariablesList(usedVariableNames);
	}, [usedVariableNames]);

	useDebounce(
		() => {
			if (isEqual(usedVariableNames, variablesList)) return;

			const values = variablesList.map(variableName => ({
				variableName
			}));

			const updatedAnalysis: ExploreAnalysis = {
				...analysis,
				input: { variables: values }
			};

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[variablesList],
		ANALYSIS_DEBOUNCE_TIME
	);

	function changeVariable(previousVariableName: string, variableName: string) {
		setVariablesList(state => {
			const previousVariableIndex = state.indexOf(previousVariableName);
			const newState = [...state];
			newState[previousVariableIndex] = variableName;

			return newState;
		});
	}

	function addVariable(variableName: string) {
		setVariablesList(state => [...state, variableName]);
	}

	function removeVariable(variableName: string) {
		setVariablesList(state => [...state.filter(name => name !== variableName)]);
	}

	const noVariablesAvailable = !filteredSelectItems.length;

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as ExploreAnalysis} />
			)}
			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Typography.Caption
						color={Colors.text.main}
						fontweight={w => w.medium}
						marginOffset={{ bottom: 0.4 }}
					>
						{translate(({ analysis }) => analysis.analyses.explore.config.variables)}
					</Typography.Caption>

					{variablesList.map((variableName, index) => (
						<SelectContainer key={index}>
							<CreatableSelect
								items={filteredSelectItems}
								value={selectItemsMap[variableName] ?? null}
								onValueSelected={value =>
									value && changeVariable(variableName, value)
								}
								canClear={false}
							/>
							<Icon
								svg={Svgs.Delete}
								marginOffset={{ left: 0.8 }}
								colors={{
									color: Colors.text.disabled,
									hover: Colors.primary.normal
								}}
								onClick={() => removeVariable(variableName)}
							/>
						</SelectContainer>
					))}

					{newVariable && (
						<SelectContainer>
							<CreatableSelect
								placeholder={translate(
									({ analysis }) =>
										analysis.analyses.explore.config.selectVariables
								)}
								items={filteredSelectItems}
								onValueSelected={value => {
									if (value) {
										addVariable(value);
										setNewVariable(false);
									}
								}}
								canClear={false}
								initiallyOpen
								autoFocus
							/>
							<Icon
								svg={Svgs.Delete}
								marginOffset={{ left: 0.8 }}
								colors={{
									color: Colors.text.disabled,
									hover: Colors.primary.normal
								}}
								onClick={() => setNewVariable(false)}
							/>
						</SelectContainer>
					)}

					{!newVariable && !noVariablesAvailable && (
						<Button
							variant={v => v.link}
							title={translate(
								({ analysis }) => analysis.analyses.explore.config.addVariables
							)}
							onClick={() => setNewVariable(true)}
						/>
					)}
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}
