import { useEffect, useMemo } from 'react';
import { Table } from 'components/UI/Table';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { GetLogisticRegressionOutput } from 'api/data/analyses';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation } from 'hooks/store';
import { VariablesData } from 'store/data/variables';
import { useMutableState, usePrevious } from 'hooks/utils';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { PStatistic } from '../../../AnalysisStatistics';
import { computeCellValue } from 'helpers/analysis';

interface Props {
	results: GetLogisticRegressionOutput | null;
	variablesData: VariablesData;
}

export function LogisticRegressionTable({ results, variablesData }: Props) {
	return (
		<AnalysisStatistic>
			{results && <Result results={results} variablesData={variablesData} />}
		</AnalysisStatistic>
	);
}

function Result({ results, variablesData }: Props) {
	const { translate } = useTranslation();
	const { variablesMap } = variablesData;

	const [draftExpanded, setDraftExpanded] = useMutableState<{ [key: string]: boolean }>({});

	const prevResults = usePrevious(results);
	useEffect(() => {
		if (results && prevResults !== results && Array.isArray(results)) {
			let newValues = {};
			results.forEach(value => {
				newValues = { ...newValues, [value.group.groupValue]: true };
			});
			setDraftExpanded(newValues);
		}
	}, [results, prevResults]);

	const data = useMemo(() => {
		if (!Array.isArray(results)) {
			return [{ logisticRegression: results }];
		} else return results;
	}, [results]);

	return (
		<Flex column>
			{data.map((value, index) => {
				const isGrouped = 'group' in value;
				const isVisible =
					isGrouped &&
					draftExpanded[value.group.groupValue as keyof typeof draftExpanded];

				return (
					<Flex column key={`logistic-reg-${index}`}>
						{value.logisticRegression && (
							<>
								<Spacer size={s => s.m} />
								{/* EXPANDABLE TOGGLE */}
								{isGrouped && (
									<Flex marginOffset={{ bottom: isVisible ? 0.8 : undefined }}>
										<Icon
											svg={isVisible ? Svgs.ArrowDown : Svgs.ArrowRight}
											size={s => s.l}
											marginOffset={{ right: 0.8 }}
											onClick={() =>
												setDraftExpanded(state => {
													state[
														value.group
															.groupValue as keyof typeof draftExpanded
													] =
														!state[
															value.group
																.groupValue as keyof typeof draftExpanded
														];
												})
											}
										/>
										<Typography.Paragraph
											fontweight={w => w.bold}
											onClick={() =>
												setDraftExpanded(state => {
													state[
														value.group
															.groupValue as keyof typeof draftExpanded
													] =
														!state[
															value.group
																.groupValue as keyof typeof draftExpanded
														];
												})
											}
											clickable
										>
											{value.group.groupValue}
										</Typography.Paragraph>
									</Flex>
								)}
								<Spacer size={s => s.s} />
								{(isVisible || !isGrouped) &&
									(value.logisticRegression.error ? (
										<AnalysisStatistic.Error>
											{value.logisticRegression.error?.message}
										</AnalysisStatistic.Error>
									) : (
										<Table.Responsive horizontalScroll>
											<Table fullWidth>
												<Table.Head>
													<Table.Row>
														<Table.Column></Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.estimate
															)}
														</Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.standardError
															)}
														</Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.zValue
															)}
														</Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.pValue
															)}
														</Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.lowerCI
															)}
														</Table.Column>
														<Table.Column>
															{translate(
																dict =>
																	dict.analysis.analyses
																		.logisticRegression
																		.tableColumns.upperCI
															)}
														</Table.Column>
													</Table.Row>
												</Table.Head>
												<Table.Body>
													{value.logisticRegression.coefficients &&
														value.logisticRegression.coefficients.map(
															(coef, idx) => (
																<Table.Row
																	key={`table-coef-${idx}`}
																>
																	<Table.Cell width={14} noWrap>
																		{coef.variableName &&
																		variablesMap[
																			coef.variableName
																		]
																			? variablesMap[
																					coef
																						.variableName
																			  ].label
																			: coef.type}
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		{computeCellValue(
																			coef.estimate
																		)}
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		{computeCellValue(
																			coef.standardError
																		)}
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		{computeCellValue(
																			coef.statistic
																		)}
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		<PStatistic
																			value={coef.pValue}
																		/>
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		{computeCellValue(
																			coef.lowerCI
																		)}
																	</Table.Cell>
																	<Table.Cell width={14} noWrap>
																		{computeCellValue(
																			coef.upperCI
																		)}
																	</Table.Cell>
																</Table.Row>
															)
														)}
												</Table.Body>
											</Table>
										</Table.Responsive>
									))}
							</>
						)}
					</Flex>
				);
			})}
		</Flex>
	);
}
