import { ComparePairedResults, ComparePairedStatisticsData } from 'api/data/analyses';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { VariablesMap } from 'store/data/variables';

import { computeCellValueForExport } from './computeData';

interface TableRows {
	nrOfRows: string[];
	average: string[];
	std: string[];
	confidentLower: string[];
	confidentUpper: string[];
	sem: string[];
	variance: string[];
	skewness: string[];
	kurtosis: string[];
	median: string[];
	min: string[];
	max: string[];
	range: string[];
	IQRLower: string[];
	IQRUpper: string[];
	missing: string[];
}

export function exportComparePaired(
	dataset: ComparePairedResults,
	{ translate, variablesMap }: { translate: TranslateFunction; variablesMap: VariablesMap }
) {
	const leftRows: string[] = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		nrOfRows: [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	if (dataset.data) {
		const { delta_stats, sample_values } = dataset.data;

		sample_values.forEach(rowData => {
			const { variableName, categoryValue, stats } = rowData;

			addRow(stats, getColumnName(variableName, categoryValue));
		});

		addRow(delta_stats, `Delta`);
	}

	function addRow(row: ComparePairedStatisticsData, columnName: string) {
		columns.push(columnName);

		rows.nrOfRows.push(computeCellValueForExport(row['nr of rows'], { noDecimals: true }));
		rows.average.push(computeCellValueForExport(row.average));
		rows.std.push(computeCellValueForExport(row.std));
		rows.confidentLower.push(computeCellValueForExport(row.confidentLower));
		rows.confidentUpper.push(computeCellValueForExport(row.confidentUpper));
		rows.sem.push(computeCellValueForExport(row.sem));
		rows.variance.push(computeCellValueForExport(row.variance));
		rows.skewness.push(computeCellValueForExport(row.skewness));
		rows.kurtosis.push(computeCellValueForExport(row.kurtosis));
		rows.median.push(computeCellValueForExport(row.median));
		rows.min.push(computeCellValueForExport(row.min));
		rows.max.push(computeCellValueForExport(row.max));
		rows.range.push(computeCellValueForExport((Number(row.max) - Number(row.min)).toString()));
		rows.IQRLower.push(computeCellValueForExport(row.IQRLower));
		rows.IQRUpper.push(computeCellValueForExport(row.IQRUpper));
		rows.missing.push(computeCellValueForExport(row.missing, { noDecimals: true }));
	}

	function getColumnName(variableName: string, categoryValue?: string) {
		let name = variableName;

		if (variableName in variablesMap) {
			const variable = variablesMap[variableName];

			name = variable.label;

			if (categoryValue) {
				// TODO: logic for value/label toggle
				name = categoryValue;
			}
		}

		return name;
	}

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	// LEFT-TOP CORNER SPACER
	csv += comma;
	// ADD COLUMNS
	csv += columns.join(comma) + newLine;

	Object.keys(rows).forEach((key, index) => {
		const row = rows[key as keyof TableRows];

		const leftRowColumn = leftRows[index];

		csv += leftRowColumn + comma + row + newLine;
	});

	return csv;
}
