import {
	CompareNumericResultsV1,
	VariableStatistics,
	CompareNumericResultsTwoCategoriesValue,
	ShapiroResultsV1
} from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { VariablesData } from 'store/data/variables';

import { AnalysisGroupedTables } from '../../UI';
import { PStatistic } from '../../AnalysisStatistics';
import { decodeURIComponentSafe } from 'helpers/generic';
import { computeCellValue } from 'helpers/analysis';
import { getAggregationLabel } from 'helpers/variables/variableHelpers';

interface TableRows {
	'nr of rows': React.ReactElement[];
	average: React.ReactElement[];
	std: React.ReactElement[];
	confidentLower: React.ReactElement[];
	confidentUpper: React.ReactElement[];
	sem: React.ReactElement[];
	variance: React.ReactElement[];
	skewness: React.ReactElement[];
	kurtosis: React.ReactElement[];
	median: React.ReactElement[];
	min: React.ReactElement[];
	max: React.ReactElement[];
	range: React.ReactElement[];
	IQRLower: React.ReactElement[];
	IQRUpper: React.ReactElement[];
	missing: React.ReactElement[];
}

interface Props {
	variablesData: VariablesData;
	dataset: CompareNumericResultsV1;
	shapiro: {
		visible: boolean;
		data: ShapiroResultsV1;
	};
}

export function CompareNumericTableV1({ variablesData, dataset, shapiro }: Props) {
	const { translate } = useTranslation();

	const { variablesMap, variableSetsMap } = variablesData;

	const leftRows = {
		n: translate(({ analysis }) => analysis.generic.n),
		mean: translate(({ analysis }) => analysis.generic.mean),
		sd: translate(({ analysis }) => analysis.generic.sd),
		CI_lower: translate(({ analysis }) => analysis.generic.confidenceLower),
		CI_upper: translate(({ analysis }) => analysis.generic.confidenceUpper),
		sem: translate(({ analysis }) => analysis.generic.sem),
		variance: translate(({ analysis }) => analysis.generic.variance),
		skewness: translate(({ analysis }) => analysis.generic.skewness),
		kurtosis: translate(({ analysis }) => analysis.generic.kurtosis),
		median: translate(({ analysis }) => analysis.generic.median),
		mininum: translate(({ analysis }) => analysis.generic.min),
		maximum: translate(({ analysis }) => analysis.generic.max),
		range: translate(({ analysis }) => analysis.generic.range),
		IQRLower: translate(({ analysis }) => analysis.generic.IQRLower),
		IQRUpper: translate(({ analysis }) => analysis.generic.IQRUpper),
		missing: translate(({ analysis }) => analysis.generic.missing)
	};

	const columns: string[] = [];
	const rows: TableRows = {
		'nr of rows': [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	const exploreVariableColumns: string[] = [];
	const categoryVariableColumns: string[] = [];

	Object.entries(dataset).forEach(([key, results]) => {
		const aggregationLabel = getAggregationLabel(key, variablesMap, variableSetsMap);

		const exploreVariableColumnLabel = variablesMap[key]
			? variablesMap[key].label
			: aggregationLabel
			? aggregationLabel
			: '';

		exploreVariableColumns.push(exploreVariableColumnLabel);

		results.forEach((data: VariableStatistics | CompareNumericResultsTwoCategoriesValue) => {
			const hasTwoCategoryVariables = ['categorizationValue', 'groupedCalculations'].every(
				key => key in data
			);

			if (hasTwoCategoryVariables) {
				data = data as CompareNumericResultsTwoCategoriesValue;

				const { categorizationValue, groupedCalculations } = data;

				categoryVariableColumns.push(decodeURIComponentSafe(categorizationValue));

				groupedCalculations.forEach(row => addRow(row, row.groupValue));
			} else {
				data = data as VariableStatistics;

				addRow(data, data.categorizationValue);
			}
		});
	});

	const columnsConfig = {
		colSpan: {
			exploreVariable: columns.length / exploreVariableColumns.length,
			categoryVariable: columns.length / categoryVariableColumns.length
		}
	};

	function addRow(row: VariableStatistics, columnName: string) {
		columns.push(decodeURIComponentSafe(columnName));

		rows['nr of rows'].push(computeCellValue(row['nr of rows'], { noDecimals: true }));
		rows.average.push(computeCellValue(row.average));
		rows.std.push(computeCellValue(row.std));
		rows.confidentLower.push(computeCellValue(row.confidentLower));
		rows.confidentUpper.push(computeCellValue(row.confidentUpper));
		rows.sem.push(computeCellValue(row.sem));
		rows.variance.push(computeCellValue(row.variance));
		rows.skewness.push(computeCellValue(row.skewness));
		rows.kurtosis.push(computeCellValue(row.kurtosis));
		rows.median.push(computeCellValue(row.median));
		rows.min.push(computeCellValue(row.min));
		rows.max.push(computeCellValue(row.max));
		rows.range.push(computeCellValue((Number(row.max) - Number(row.min)).toString()));
		rows.IQRLower.push(computeCellValue(row.IQRLower));
		rows.IQRUpper.push(computeCellValue(row.IQRUpper));
		rows.missing.push(computeCellValue(row.missing, { noDecimals: true }));
	}

	const hasExploreVariableColumns = exploreVariableColumns.length > 0;
	const hasCategoryVariableColumns = categoryVariableColumns.length > 0;

	const leftRowHeight =
		((hasExploreVariableColumns ? 1.1 : 0) + (hasCategoryVariableColumns ? 1.05 : 0) + 1) * 4;

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={15} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column height={leftRowHeight}>&nbsp;</Table.Column>
					</Table.Row>
				</Table.Head>

				<Table.Body>
					{Object.values(leftRows).map((value, index) => (
						<Table.Row key={`left-table-row-${index}`}>
							<Table.Cell key={`left-table-row-cell-${index}`} bold>
								{value}
							</Table.Cell>
						</Table.Row>
					))}

					{shapiro.visible && shapiro.data.data && (
						<Table.Row>
							<Table.Cell bold>
								{translate(dict => dict.analysis.generic.shapiro)}
							</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>
			</Table>

			<Table.Responsive>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							{hasExploreVariableColumns &&
								exploreVariableColumns.map((column, index) => (
									<Table.Column
										key={`right-table-column-explore-variable-${index}`}
										title={column}
										minWidth={12}
										maxWidth={14}
										colSpan={columnsConfig.colSpan.exploreVariable}
										noWrap
									>
										{column}
									</Table.Column>
								))}
						</Table.Row>

						<Table.Row>
							{categoryVariableColumns.map((column, index) => (
								<Table.Column
									key={`right-table-column-category-variable-${index}`}
									title={column}
									minWidth={12}
									maxWidth={14}
									colSpan={columnsConfig.colSpan.categoryVariable}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>

						<Table.Row>
							{columns.map((column, index) => (
								<Table.Column
									key={`right-table-column-${index}`}
									title={column}
									minWidth={12}
									maxWidth={14}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{Object.keys(rows).map((key, index) => (
							<Table.Row key={`right-table-row-${index}`}>
								{rows[key as keyof TableRows].map((item, i) => (
									<Table.Cell key={`right-table-row-cell-${i}`}>
										{item}
									</Table.Cell>
								))}
							</Table.Row>
						))}

						{shapiro.visible && shapiro.data.data && (
							<Table.Row>
								{shapiro.data.data.map((item, index) => (
									<Table.Cell key={`right-table-row-saphiro-${index}`}>
										<PStatistic formatNaN={false} value={item.pValue} />
									</Table.Cell>
								))}
							</Table.Row>
						)}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}
