import { useMemo } from 'react';

import { LogRankResults, LogRankResultsData } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';

interface Props {
	results: LogRankResults;
}

export function KaplanLogRank({ results }: Props) {
	const { translate } = useTranslation();

	const { data, error, errorMessage } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.logRank.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{errorMessage ??
						translate(({ errors }) => errors.api.analyses.statistics.logRank)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}

function Result({ data }: { data: LogRankResultsData }) {
	const { xStatistic, pStatistic } = useMemo(() => {
		const xStatistic = getXStatistic({
			statistic: data.statistic
		});

		const pStatistic = getPStatistic({
			pValue: data.pValue
		});

		return { xStatistic, pStatistic };
	}, [data]);

	return (
		<Table.Responsive horizontalScroll fullWidth={false}>
			<Table>
				<Table.Body>
					<Table.Row>
						<Table.Cell title={xStatistic.text} width={14} noWrap alignBottom>
							{xStatistic.JSX}
						</Table.Cell>
						<Table.Cell title={pStatistic.text} width={14} noWrap alignBottom>
							{pStatistic.JSX}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

function getXStatistic(input: { statistic: number }) {
	const { statistic } = input;

	const { value: parsedStatistic } = parseAnalysisNumber(statistic);

	const output = {
		JSX: (
			<>
				<i>{`X`}</i>
				<sup>{`2`}</sup>
				{`(1) = ${parsedStatistic}`}
			</>
		),
		text: `X^2(1) = ${parsedStatistic}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}
