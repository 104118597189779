import { KaplanMeierAnalysis } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { KaplanLogRank } from './KaplanLogRank';
import { KaplanPatientsTable } from './KaplanPatientsTable';

interface Props {
	analysis: KaplanMeierAnalysis;
}

export function KaplanStatistics({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { dataset, statistics: outputStatistics }
	} = analysis;

	const statistics = {
		patients: {
			visible: inputStatistics.patients,
			results: dataset
		},
		logRank: {
			visible: inputStatistics.logRank,
			results: outputStatistics.logRank
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.patients.visible && (
				<KaplanPatientsTable dataset={statistics.patients.results} />
			)}

			{statistics.logRank.visible && <KaplanLogRank results={statistics.logRank.results} />}
		</Gap>
	);
}
