import { useEffect, useMemo, useState } from 'react';

import {
	useDeactivateUserAccount,
	useSubscription,
	useTranslation,
	useAPIUserOwnedProjects,
	useUsername,
	useAPITransferEnterpriseProjectsOwnership,
	useSubscriptionRules
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

import { BoldTextHighlight } from 'components/UI/BoldTextHighlight';
import { Flex } from 'components/UI/Flex';
import { HSpacer } from 'components/UI/HSpacer';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Colors, Dictionary, Svgs } from 'environment';

import { DropdownUsers } from 'components/UI/DropdownUsers';
import { SubscriptionStatus, UserStatus } from 'store/account/subscription';

import {
	CustomisableTableRow,
	CustomisableTableColumn,
	TableFilterType,
	TableName
} from 'types/index';

import { CustomisableTable, SearchIconToInputToggle } from 'components/UI/CustomisableTable';

import { ProjectWrapper } from './DeactivateEnterpriseUser.style';
import { Icon } from 'components/UI/Icons';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { OwnedProject } from 'store/account/enterprise';

interface Props {
	userId: string | null;
	onClose: (deactivated?: boolean) => void;
}

enum UserDeactivationSteps {
	DeactivateUser = 'DeactivateUser',
	TransferProjectOwnership = 'TransferProjectOwnership',
	SelectedProjectsTransferPreview = 'SelectedProjectsTransferPreview',
	SelectedProjectsTransferSuccessful = 'SelectedProjectsTransferSuccessful',
	UserDeactivated = 'UserDeactivated',
	DontHavePermissionsToTransfer = 'DontHavePermissionsToTransfer'
}

export enum ModalTitles {
	DeactivateUser = 'deactivateUser',
	TransferProjectOwnership = 'transferProjectOwnership',
	SelectedProjectsTransferPreview = 'projectOwnershipPreview',
	SelectedProjectsTransferSuccessful = 'transferComplete',
	UserDeactivated = 'userDeactivated',
	DontHavePermissionsToTransfer = 'deactivateUser'
}

export function DeactivateEnterpriseUser({ userId, onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [userDeactivationStep, setUserDeactivationStep] = useState(
		UserDeactivationSteps.DeactivateUser
	);
	const [newOwner, setNewOwner] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [filteredOwnedProjects, setFilteredOwnedProjects] = useState<OwnedProject[]>([]);
	const [fullScreen, setFullScreen] = useState(false);
	const [projectIdsToTransfer, setProjectIdsToTransfer] = useState<string[]>([]);

	const loggedUser = useUsername();
	const [
		{
			data: { users }
		}
	] = useSubscription();
	const { canTransferProjectOwnership } = useSubscriptionRules();
	const [
		{ loading: deactivatingUserAccount, error: errorDeactivatingUserAccount },
		deactivateUserAccount
	] = useDeactivateUserAccount();
	const userToDeactivate = users.find(user => user.userid === userId);
	const [
		{ loading: gettingOwnedProjects, error: errorGettingOwnedProjects, data: ownedProjects },
		getOwnedProjects
	] = useAPIUserOwnedProjects();

	const [{ loading: transferring, error: errorTransferring }, transferProjects] =
		useAPITransferEnterpriseProjectsOwnership();

	// Filtering project is done locally since there is no redux involvement
	useEffect(() => {
		let resultData: OwnedProject[] = [];
		// if (!searchTerm) return;

		if (!searchTerm?.length || !ownedProjects) {
			resultData = ownedProjects;
		} else {
			resultData = ownedProjects.filter(ownedProject => {
				let matches = true;
				const keywords = [
					ownedProject.projectName.toLowerCase().trim(),
					ownedProject.organizations?.toString().trim()
				];
				searchTerm.split(' ').forEach(term => {
					let termFound = false;
					keywords.forEach(keyword => {
						if (
							(keyword &&
								keyword.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !==
									-1) ||
							Number(keyword) === Number(term)
						)
							termFound = true;
					});
					if (!termFound) matches = false;
				});

				return matches;
			});
		}
		setFilteredOwnedProjects(resultData);
	}, [searchTerm, gettingOwnedProjects]);

	//Check if user ownes project and transfer if needed
	//else, deactivate dirrectly
	useCompletedAction(gettingOwnedProjects, errorGettingOwnedProjects, () => {
		const hasOwnedProjects = !!ownedProjects.length;
		if (hasOwnedProjects) {
			if (canTransferProjectOwnership) onGoToSelectProjectsAndNewOwner();
			else setUserDeactivationStep(UserDeactivationSteps.DontHavePermissionsToTransfer);
		} else {
			deactivateUserAccount(userId ?? '');
		}
	});

	useCompletedAction(transferring, errorTransferring, () => {
		setNotification({
			message: translate(
				({ enterpriseAdmin }) => enterpriseAdmin.deactivateUser.title.transferComplete
			)
		});
		if (ownedProjects.length - projectIdsToTransfer.length > 0) {
			onGoToSelectedProjectsSuccessfulTransfer();
		} else {
			userToDeactivate && deactivateUserAccount(userToDeactivate?.userid);
		}
	});

	//On user successfully deactivated, go to last screen
	useCompletedAction(deactivatingUserAccount, errorDeactivatingUserAccount, () => {
		setNotification({
			message: translate(
				({ enterpriseAdmin }) =>
					enterpriseAdmin.deactivateUser.api.userDeactivatedSuccessfully
			)
		});
		onGoToDeactivatedUser();
	});

	function getUserOwnedProjects() {
		getOwnedProjects(userToDeactivate?.userid ?? '');
	}
	function onGoToSelectProjectsAndNewOwner(keepSelection?: true) {
		// Reset previous selections (if any)
		if (!keepSelection) {
			setProjectIdsToTransfer([]);
			setNewOwner(null);
		}
		setUserDeactivationStep(UserDeactivationSteps.TransferProjectOwnership);
	}
	function onGoToSelectedProjectsPreview() {
		setUserDeactivationStep(UserDeactivationSteps.SelectedProjectsTransferPreview);
	}
	function onGoToSelectedProjectsSuccessfulTransfer() {
		setUserDeactivationStep(UserDeactivationSteps.SelectedProjectsTransferSuccessful);
	}
	function onGoToDeactivatedUser() {
		setUserDeactivationStep(UserDeactivationSteps.UserDeactivated);
	}
	function handlePrimaryClick() {
		if (userDeactivationStep === UserDeactivationSteps.DeactivateUser) getUserOwnedProjects();
		if (userDeactivationStep === UserDeactivationSteps.TransferProjectOwnership) {
			onGoToSelectedProjectsPreview();
		}
		if (userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferPreview)
			newOwner && transferProjects(newOwner, projectIdsToTransfer);
		if (userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferSuccessful) {
			getUserOwnedProjects();
		}
		if (
			userDeactivationStep === UserDeactivationSteps.UserDeactivated ||
			userDeactivationStep === UserDeactivationSteps.DontHavePermissionsToTransfer
		)
			//When userToDeactivate has no more owned projects, close modal otherwise set SelectedProjectsTransferPreview step
			onClose();
	}

	//Modal data computations
	const selectedProjectsToTransferNames: string[] = [];
	ownedProjects.forEach(project =>
		projectIdsToTransfer.includes(project.projectId)
			? selectedProjectsToTransferNames.push(project.projectName)
			: undefined
	);

	const usersElligibleForTransfer = useMemo(
		() =>
			users.filter(
				user =>
					user.status === UserStatus.Active &&
					user.subscriptionStatus === SubscriptionStatus.Active &&
					![loggedUser, userToDeactivate?.userid].includes(user.userid)
			),
		[users]
	);
	const usersMap = useMemo(
		() =>
			usersElligibleForTransfer.map(user => {
				const { userid, userFirstName, userSirName, emailAddress } = user;

				return {
					userId: userid,
					userFirstName: userFirstName,
					userSirName: userSirName,
					emailAddress: emailAddress
				};
			}),
		[usersElligibleForTransfer]
	);

	const projectsColumns: CustomisableTableColumn[] = useMemo(() => {
		const columns = [
			{
				name: 'projectName',
				label: translate(({ projects }) => projects.projects),
				filterType: TableFilterType.Text
			},
			{
				name: 'collaborators',
				label: translate(({ projectOptions }) => projectOptions.collaborators),
				filterType: TableFilterType.Numeric,
				isOptional: true
			}
		];

		return columns;
	}, []);

	const tableData = useMemo(() => {
		const data: CustomisableTableRow[] = [];
		filteredOwnedProjects.forEach(project => {
			const row = {
				id: project.projectId,
				projectName: project.projectName,
				collaborators: project.collaborators ?? 0
			};
			data.push(row);
		});
		return data;
	}, [filteredOwnedProjects]);

	const rowSelectableIds: string[] = [];
	tableData.filter(row => rowSelectableIds.push(row.id));

	if (!userToDeactivate) return <></>;

	const { userFirstName, userSirName } = userToDeactivate;

	const userToDeactivateFullName = `${userFirstName} ${userSirName}`;

	// UI Dynamic translations determined by projects number
	// Labels
	const numberOfProjects = ownedProjects.length;
	const ownedProjectSingularOrPlural =
		numberOfProjects < 2
			? translate(({ terms }) => terms.project)
			: translate(({ projects }) => projects.projects);
	const numberOfSelectedProjects = selectedProjectsToTransferNames.length;
	const selectedProjectSingularOrPlural =
		numberOfSelectedProjects < 2
			? translate(({ terms }) => terms.project)
			: translate(({ projects }) => projects.projects);
	const licenseDeactivateQuestion = translate(
		dict => dict.enterpriseAdmin.deactivateUser.modalQuestion,
		false,
		{
			user: userToDeactivateFullName
		}
	);
	const licenseDeactivateCaption = translate(
		dict => dict.enterpriseAdmin.deactivateUser.modalCaption,
		false,
		{
			user: userToDeactivateFullName
		}
	);
	const projectsTransferProgress = translate(
		dict => dict.enterpriseAdmin.deactivateUser.userDeactivated.projectsTransferredNumber,
		false,
		{
			transferredProjects: selectedProjectsToTransferNames.length,
			totalProjects: ownedProjects.length,
			projectTermSingularOrPlural: ownedProjectSingularOrPlural
		}
	);
	const stillOwningProjectSingularOrPlural =
		ownedProjects.length - selectedProjectsToTransferNames.length < 2
			? translate(({ terms }) => terms.project)
			: translate(({ projects }) => projects.projects);
	const userStillOwnsProjectsCaption = translate(
		dict => dict.enterpriseAdmin.deactivateUser.userDeactivated.userStillOwner,
		false,
		{
			user: userToDeactivateFullName,
			ownedProjects: ownedProjects.length - selectedProjectsToTransferNames.length,
			projectTermSingularOrPlural: (
				stillOwningProjectSingularOrPlural as string
			).toLocaleLowerCase()
		}
	);
	const userDeactivationTranslationKey = projectIdsToTransfer.length
		? 'userWithProjectsSuccessfullyDeactivated'
		: 'userSuccessfullyDeactivated';
	const userSuccessfullyDeactivatedCaption = translate(
		dict => dict.enterpriseAdmin.deactivateUser.userDeactivated[userDeactivationTranslationKey],
		false,
		{
			user: userToDeactivateFullName
		}
	);
	const userStillOwnsBoldCaption = userStillOwnsProjectsCaption.split('.')[0];

	const userDeactivationOrTransferSuccessKey = projectIdsToTransfer.length
		? 'projectTransferSuccesful'
		: 'userLicenceDeactivated';
	const ownershipTransferOrUserDeactivatedSuccessfulCaption = translate(
		({ enterpriseAdmin }) =>
			enterpriseAdmin.deactivateUser.userDeactivated[userDeactivationOrTransferSuccessKey],
		false,
		{
			projectTermSingularOrPlural: selectedProjectSingularOrPlural
		}
	);
	const transferInstructions = translate(
		({ enterpriseAdmin }) =>
			enterpriseAdmin.deactivateUser.transferProjectOwnership.transferInstructions,
		false,
		{
			userToDeactivate: userToDeactivateFullName ?? ''
		}
	);
	const licenseLoosingCaptions = Object.keys(
		Dictionary.enterpriseAdmin.deactivateUser.licenceDeactivationCaptions
	);
	const primaryLabel =
		userDeactivationStep === UserDeactivationSteps.DeactivateUser ||
		userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferSuccessful
			? translate(({ buttons }) => buttons.continue)
			: userDeactivationStep === UserDeactivationSteps.TransferProjectOwnership
			? translate(({ buttons }) => buttons.transfer)
			: userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferPreview
			? translate(({ buttons }) => buttons.confirm)
			: translate(({ buttons }) => buttons.done);

	const modalTitle = translate(
		({ enterpriseAdmin }) =>
			enterpriseAdmin.deactivateUser.title[ModalTitles[userDeactivationStep]],
		false,
		{
			projectTermSingularOrPlural:
				userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferPreview
					? selectedProjectSingularOrPlural
					: ownedProjectSingularOrPlural
		}
	);

	const fullScreenEnabled =
		userDeactivationStep === UserDeactivationSteps.TransferProjectOwnership &&
		fullScreen &&
		newOwner;

	const headerControls = (
		<Flex style={{ maxHeight: '2rem' }}>
			<SearchIconToInputToggle searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			{newOwner && (
				<Svgs.Maximize
					onClick={() => setFullScreen(true)}
					style={{
						cursor: 'pointer',
						width: '2rem',
						height: '2rem',
						marginRight: '0.6rem'
					}}
				/>
			)}
		</Flex>
	);

	return (
		<Modal
			visible={!!userId}
			fullSizeConfig={{
				narrow: false,
				headerMainConfig: fullScreenEnabled
					? {
							leftComponent: modalTitle,
							rightComponent: (
								<Flex justify={a => a.between} style={{ width: '8rem' }}>
									<Icon
										svg={Svgs.Minimize}
										onClick={() => setFullScreen(false)}
									/>
									<Icon svg={Svgs.Close} onClick={() => onClose()} />
								</Flex>
							)
					  }
					: undefined,
				headerNavigationConfig: fullScreenEnabled
					? {
							rightComponent: (
								<SearchInput
									placeholder={'Search'}
									onChangeTerm={setSearchTerm}
									term={searchTerm ?? ''}
								/>
							)
					  }
					: undefined
			}}
			title={modalTitle}
			size={s => (fullScreenEnabled ? s.full : s.m)}
			primary={{
				label: primaryLabel,
				loading: deactivatingUserAccount || gettingOwnedProjects || transferring,
				disabled:
					userDeactivationStep === UserDeactivationSteps.TransferProjectOwnership
						? !projectIdsToTransfer.length || !newOwner
						: false,
				onClick: handlePrimaryClick
			}}
			neutral={
				userDeactivationStep !== UserDeactivationSteps.UserDeactivated &&
				userDeactivationStep !== UserDeactivationSteps.DontHavePermissionsToTransfer
					? {
							label: translate(dict =>
								userDeactivationStep ===
								UserDeactivationSteps.SelectedProjectsTransferPreview
									? dict.buttons.back
									: dict.buttons.cancel
							),
							onClick:
								userDeactivationStep ===
								UserDeactivationSteps.SelectedProjectsTransferPreview
									? () => onGoToSelectProjectsAndNewOwner(true)
									: onClose
					  }
					: undefined
			}
			onClose={onClose}
			enterAsPrimaryOnClick
			close
		>
			{/* User deactivation captions Step */}
			{userDeactivationStep === UserDeactivationSteps.DeactivateUser && (
				<>
					<Typography.Paragraph marginOffset={{ bottom: 2.4 }}>
						<BoldTextHighlight
							searchTerm={userToDeactivateFullName}
							sentence={licenseDeactivateQuestion}
						/>
					</Typography.Paragraph>
					<Typography.Paragraph marginOffset={{ bottom: 1.6 }}>
						<BoldTextHighlight
							searchTerm={userToDeactivateFullName}
							sentence={licenseDeactivateCaption}
						/>
					</Typography.Paragraph>

					{licenseLoosingCaptions.map(key => (
						<Flex key={key}>
							<HSpacer size={s => s.m} />
							<Typography.Paragraph>&#x2022;</Typography.Paragraph>
							<HSpacer size={s => s.s} />
							<Typography.Paragraph marginOffset={{ bottom: 0.8 }}>
								{translate(
									dict =>
										dict.enterpriseAdmin.deactivateUser
											.licenceDeactivationCaptions[
											key as keyof typeof dict.enterpriseAdmin.deactivateUser.licenceDeactivationCaptions
										]
								)}
							</Typography.Paragraph>
						</Flex>
					))}
				</>
			)}
			{userDeactivationStep === UserDeactivationSteps.TransferProjectOwnership && (
				<>
					{!fullScreenEnabled && (
						<>
							<Typography.Paragraph marginOffset={{ bottom: 2.4 }}>
								<BoldTextHighlight
									searchTerm={userToDeactivateFullName}
									sentence={transferInstructions}
								/>
							</Typography.Paragraph>
							<DropdownUsers
								label={translate(
									({ enterpriseAdmin }) =>
										enterpriseAdmin.projects.transferModal.newOwner
								)}
								users={usersMap}
								selectedUserId={newOwner}
								onSelect={setNewOwner}
							/>
							<Spacer size={s => s.m} />
							<Typography.Caption fontweight={f => f.medium} color={Colors.text.main}>
								{translate(
									({ enterpriseAdmin }) =>
										enterpriseAdmin.projects.transferModal.title
								)}
							</Typography.Caption>
						</>
					)}
					<CustomisableTable
						tableName={TableName.OwnedProjects}
						selectableRows={rowSelectableIds}
						selectedIds={projectIdsToTransfer}
						items={tableData}
						hasPagination={!fullScreenEnabled}
						cellProps={{ minWidth: 22 }}
						columns={projectsColumns}
						onRowsSelect={selected => setProjectIdsToTransfer(selected)}
						renderControlsHeader={
							!fullScreenEnabled
								? {
										rightNode: headerControls
								  }
								: undefined
						}
					/>
				</>
			)}
			{userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferPreview && (
				<>
					<DropdownUsers
						disabled
						inactive
						label={translate(
							({ enterpriseAdmin }) => enterpriseAdmin.projects.transferModal.newOwner
						)}
						users={usersMap}
						selectedUserId={newOwner}
					/>
					<Spacer size={s => s.l} />
					<Typography.Caption>
						{translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.deactivateUser.transferProjectOwnership
									.projectsSelected
						)}
					</Typography.Caption>
					<Spacer size={s => s.xs} />
					{selectedProjectsToTransferNames.map((projectName, index) => (
						<ProjectWrapper key={projectName} backgroundColoredRow={index % 2 == 1}>
							<Typography.Paragraph>{projectName}</Typography.Paragraph>
						</ProjectWrapper>
					))}
				</>
			)}
			{/* Project(s) ownership transferred successfully Step */}
			{userDeactivationStep === UserDeactivationSteps.SelectedProjectsTransferSuccessful && (
				<Flex align={a => a.center} justify={j => j.center} column>
					<Spacer size={s => s.xl} />
					<Svgs.UserChecked />
					<Spacer size={s => s.m} />
					<Typography.H3>{projectsTransferProgress}</Typography.H3>
					<Spacer size={s => s.xs} />
					<Typography.Paragraph marginOffset={{ x: 1 }} alignCenter>
						<BoldTextHighlight
							searchTerm={userStillOwnsBoldCaption}
							sentence={userStillOwnsProjectsCaption}
						/>
					</Typography.Paragraph>
				</Flex>
			)}
			{/* User Deactivation Successfully Step */}
			{userDeactivationStep === UserDeactivationSteps.UserDeactivated && (
				<Flex align={a => a.center} justify={j => j.center} column>
					<Spacer size={s => s.xl} />
					<Svgs.UserDeactivated />
					<Spacer size={s => s.m} />
					<Typography.H3>
						{ownershipTransferOrUserDeactivatedSuccessfulCaption}
					</Typography.H3>
					<Spacer size={s => s.xs} />
					<Typography.Paragraph marginOffset={{ x: 1 }} alignCenter>
						<BoldTextHighlight
							searchTerm={userToDeactivateFullName}
							sentence={userSuccessfullyDeactivatedCaption}
						/>
					</Typography.Paragraph>
				</Flex>
			)}
			{/* User Can't Be Deactivated */}
			{userDeactivationStep === UserDeactivationSteps.DontHavePermissionsToTransfer && (
				<Flex align={a => a.center} justify={j => j.center} column>
					<Spacer size={s => s.xl} />
					<Svgs.UserDeactivated />
					<Spacer size={s => s.m} />
					<Typography.H3>
						{translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.deactivateUser.userDeactivated.cannotDeactivateUser
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter>
						{translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.deactivateUser.userDeactivated
									.dontHaveTransferProjectPermission
						)}
					</Typography.Paragraph>
				</Flex>
			)}
		</Modal>
	);
}
