import { ComparePairedResults, ComparePairedStatisticsData } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { computeCellValue } from 'helpers/analysis';
import { useTranslation } from 'hooks/store';
import { VariablesData } from 'store/data/variables';

import { AnalysisGroupedTables } from '../../UI';

interface TableRows {
	'nr of rows': React.ReactElement[];
	average: React.ReactElement[];
	std: React.ReactElement[];
	confidentLower: React.ReactElement[];
	confidentUpper: React.ReactElement[];
	sem: React.ReactElement[];
	variance: React.ReactElement[];
	skewness: React.ReactElement[];
	kurtosis: React.ReactElement[];
	median: React.ReactElement[];
	min: React.ReactElement[];
	max: React.ReactElement[];
	range: React.ReactElement[];
	IQRLower: React.ReactElement[];
	IQRUpper: React.ReactElement[];
	missing: React.ReactElement[];
}

interface Props {
	variablesData: VariablesData;
	dataset: ComparePairedResults;
}

export function ComparePairedTable({ variablesData, dataset }: Props) {
	const { translate } = useTranslation();

	const { variablesMap } = variablesData;

	const leftRows = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		'nr of rows': [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	if (dataset.data) {
		const { delta_stats, sample_values } = dataset.data;

		sample_values.forEach(rowData => {
			const { variableName, categoryValue, stats } = rowData;

			addRow(stats, getColumnName(variableName, categoryValue));
		});

		addRow(delta_stats, `Delta`);
	}

	function addRow(row: ComparePairedStatisticsData, columnName: string) {
		columns.push(columnName);

		rows['nr of rows'].push(computeCellValue(row['nr of rows'], { noDecimals: true }));
		rows.average.push(computeCellValue(row.average));
		rows.std.push(computeCellValue(row.std));
		rows.confidentLower.push(computeCellValue(row.confidentLower));
		rows.confidentUpper.push(computeCellValue(row.confidentUpper));
		rows.sem.push(computeCellValue(row.sem));
		rows.variance.push(computeCellValue(row.variance));
		rows.skewness.push(computeCellValue(row.skewness));
		rows.kurtosis.push(computeCellValue(row.kurtosis));
		rows.median.push(computeCellValue(row.median));
		rows.min.push(computeCellValue(row.min));
		rows.max.push(computeCellValue(row.max));
		rows.range.push(computeCellValue((Number(row.max) - Number(row.min)).toString()));
		rows.IQRLower.push(computeCellValue(row.IQRLower));
		rows.IQRUpper.push(computeCellValue(row.IQRUpper));
		rows.missing.push(computeCellValue(row.missing, { noDecimals: true }));
	}

	function getColumnName(variableName: string, categoryValue?: string) {
		let name = variableName;

		if (variableName in variablesMap) {
			const variable = variablesMap[variableName];

			name = variable.label;

			if (categoryValue) {
				// TODO: logic for value/label toggle
				name = categoryValue;
			}
		}

		return name;
	}

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={15} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column>&nbsp;</Table.Column>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{leftRows.map((value, index) => (
						<Table.Row key={`left-table-row-${index}`}>
							<Table.Cell key={`left-table-row-cell-${index}`} bold>
								{value}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							{columns.map((column, index) => (
								<Table.Column
									key={`right-table-column-${index}`}
									title={column}
									minWidth={12}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{Object.keys(rows).map((key, index) => (
							<Table.Row key={`right-table-row-${index}`}>
								{rows[key as keyof TableRows].map((item, i) => (
									<Table.Cell key={`right-table-row-cell-${i}`}>
										{item}
									</Table.Cell>
								))}
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}
