import { sendRequest, USER_URL } from 'api/utils';
import { Dictionary } from 'environment';

import {
	GetUsersByEmailsResponse,
	GetUsersByEmailsAxiosResponse,
	CheckIfEmailIsUniqueRequest,
	CheckIfEmailIsUniqueResponse,
	InviteUserResponse,
	InviteUserRequest,
	GetUsersByIdsResponse,
	GetUsersByIdsAxiosResponse,
	SearchUsersByEmailsOutput,
	SearchUsersByEmailsInput,
	SearchUsersByEmailsRequest,
	SearchUsersByEmailsResponse
} from './types';

export const methods = {
	checkIfEmailIsUnique: 'checkIfEmailIsUnique',
	getUsersByEmails: 'getUsersByEmails',
	searchUsersByEmail: 'searchUsersByEmails',
	getUsersByIds: 'getUsersByIds',
	inviteUser: 'inviteUser'
};

export default () => ({
	getUsersByEmails: async (
		projectId: string,
		emails: string[]
	): Promise<GetUsersByEmailsResponse> => {
		const { data }: GetUsersByEmailsAxiosResponse = await sendRequest(USER_URL, {
			method: methods.getUsersByEmails,
			userAccesses: emails.map(email => ({
				projectId: Number(projectId),
				emailAddress: email
			}))
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accounts.couldNoGetUsersByEmail);
		}

		return data;
	},

	async searchUsersByEmails({
		input,
		callbacks
	}: {
		input: SearchUsersByEmailsInput;
		callbacks?: {
			onIsNotValidEmailList?: (invalidEmails: string[]) => void;
		};
	}): Promise<SearchUsersByEmailsOutput> {
		const { data } = await sendRequest<SearchUsersByEmailsRequest, SearchUsersByEmailsResponse>(
			USER_URL,
			{
				method: methods.searchUsersByEmail,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();

		const { users, notSharedWith } = data;

		if (users.length === 0) {
			callbacks?.onIsNotValidEmailList?.(notSharedWith);
			throw new Error();
		}

		return { users, notSharedWith };
	},

	getUsersByIds: async (ids: string[]): Promise<GetUsersByIdsResponse> => {
		const { data }: GetUsersByIdsAxiosResponse = await sendRequest(USER_URL, {
			method: methods.getUsersByIds,
			userIds: ids
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accounts.couldNoGetUsersByIds);
		}

		return data;
	},

	checkIfEmailIsUnique: async (email: string) => {
		const { data }: CheckIfEmailIsUniqueResponse =
			await sendRequest<CheckIfEmailIsUniqueRequest>(USER_URL, {
				method: methods.checkIfEmailIsUnique,
				user: {
					emailAddress: email
				}
			});

		if (!data.emailUnique) throw new Error();
	},
	inviteUser: async (emailAddress: string) => {
		const { data }: InviteUserResponse = await sendRequest<InviteUserRequest>(USER_URL, {
			method: methods.inviteUser,
			emailAddress
		});

		if (!data) throw new Error();
	}
});
