import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { CrosstabAnalysis } from 'api/data/analyses';
import {
	AnalysisStatisticsWrapper,
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames, FileType } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { CrosstabTable } from './CrosstabTable';
import { CrosstabStatistics } from './CrosstabStatistics';
import { CrosstabBarChart } from './CrosstabBarChart';
import { CrosstabSunburstChart } from './CrosstabSunburstChart';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportCrosstab, exportSvg } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	mapVariableCategoryValueToLabel,
	mapVariableCategoryValuesToLabels
} from 'helpers/variables';
import {
	useTranslation,
	useAreFiltersValid,
	useProject,
	useAnalysisActiveTab,
	useAnalysesActiveColum,
	useFullscreenAnalysis
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: CrosstabAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function CrosstabView({
	analysis,
	variablesDataSelectItems,
	exporting,
	loading,
	variablesData
}: Props) {
	const { translate } = useTranslation();
	const areFiltersValid = useAreFiltersValid();
	const [{ data: project }] = useProject();

	const { selectItemsLabelMap } = variablesDataSelectItems;

	const {
		id,
		input,
		options: { configPanel, chartLegend = true },
		output: { dataset }
	} = analysis;

	const {
		rows: { rows, name: rowsName },
		columns: {
			categoryLabels: categories,
			categoryTotals: totals,
			label: columnsLabel,
			name: columnsName
		}
	} = dataset;

	const [activeTab] = useAnalysisActiveTab(id);
	const [activeColumn] = useAnalysesActiveColum();

	const [fullscreen] = useFullscreenAnalysis();

	const crosstabGroupedId = ZingChartExportTypes.CrosstabGrouped + id;
	const crosstabStackedId = ZingChartExportTypes.CrosstabStacked + id;
	const crosstabSunburstId = ZingChartExportTypes.CrosstabSunburst + id;

	useEffect(() => {
		if (exporting && project) {
			if (activeTab === 0) {
				const data = exportCrosstab(parsedDataset, { translate });

				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.CrosstabTable,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			} else if (activeTab === 1) {
				exportSvg(
					crosstabGroupedId,
					getExportFileName(
						ExportFileNames.CrosstabGroupedChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					crosstabStackedId,
					getExportFileName(
						ExportFileNames.CrosstabStackedChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 3) {
				exportSvg(
					crosstabSunburstId,
					getExportFileName(
						ExportFileNames.CrosstabSunburstChart,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const areStatisticsEnabled = Object.values(input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	const scalesLabels = {
		labelX: columnsLabel,
		labelY: translate(d => d.analysis.analyses.frequencies.name)
	};

	const legendHeader = selectItemsLabelMap[rowsName] ?? undefined;

	const { variablesMap, variableSetsMap } = variablesData;

	const { parsedDataset, parsedCategories, parsedRows } = useMemo(() => {
		let parsedRows = rows;
		let parsedCategories = categories;
		const parsedDataset = cloneDeep(dataset);

		if (analysis.options.showCategoryLabels) {
			parsedRows = cloneDeep(rows).map(row => {
				row.label =
					mapVariableCategoryValueToLabel(row.label, variablesMap[rowsName]) ?? row.label;

				row.valueDetails = row.valueDetails.map(valueDetails => ({
					...valueDetails,
					groupVariableValue:
						mapVariableCategoryValueToLabel(
							valueDetails.groupVariableValue,
							variablesMap[valueDetails.groupVariable]
						) ?? valueDetails.groupVariableValue,
					variableValue:
						mapVariableCategoryValueToLabel(
							valueDetails.variableValue,
							variablesMap[valueDetails.variable]
						) ?? valueDetails.variableValue
				}));

				return row;
			});

			parsedCategories = mapVariableCategoryValuesToLabels(
				categories,
				variablesMap[columnsName]
			);
			parsedDataset.rows.rows = cloneDeep(parsedRows);
			parsedDataset.columns.categoryLabels = cloneDeep(parsedCategories);
		}

		return { parsedDataset, parsedCategories, parsedRows };
	}, [
		rows,
		categories,
		rowsName,
		columnsName,
		variablesMap,
		analysis.options.showCategoryLabels
	]);

	const disableInteractions = activeTab === 3 && activeColumn !== 1 && !fullscreen;

	const columnTitle = useMemo(() => {
		if (variablesMap[columnsLabel]) {
			return variablesMap[columnsLabel].label;
		}
		// aggregated variable;
		const aggregationRuleNameToAggregatorVariableMap =
			buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

		const rule = aggregationRuleNameToAggregatorVariableMap[columnsLabel];

		// fallback
		if (!rule) return columnsLabel;
		const variableLabel = variablesMap[rule.aggregator.variableName].label;
		const aggregationType = rule.rule.type;

		return `${variableLabel} (${aggregationType.toUpperCase()})`;
	}, [variablesMap, variableSetsMap, columnsLabel]);

	return (
		<AnalysisViewContainer
			disableInteractions={disableInteractions}
			isConfigPanelOpen={configPanel.open}
			loading={loading}
		>
			{activeTab === 0 && (
				<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Crosstab}>
					<CrosstabTable
						rowTitle={legendHeader}
						columnTitle={columnTitle}
						rows={parsedRows}
						columns={parsedCategories}
						totals={totals}
					/>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 1 && (
				<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Crosstab}>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<CrosstabBarChart
							rows={parsedRows}
							categories={parsedCategories}
							isLegendEnabled={chartLegend}
							legendHeader={legendHeader}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<CrosstabBarChart
							id={crosstabGroupedId}
							rows={parsedRows}
							categories={parsedCategories}
							isForExport
							isLegendEnabled={chartLegend}
							legendHeader={legendHeader}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 2 && (
				<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Crosstab}>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<CrosstabBarChart
							rows={parsedRows}
							categories={parsedCategories}
							isLegendEnabled={chartLegend}
							stacked
							legendHeader={legendHeader}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<CrosstabBarChart
							id={crosstabStackedId}
							rows={parsedRows}
							categories={parsedCategories}
							isLegendEnabled={chartLegend}
							stacked
							isForExport
							legendHeader={legendHeader}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 3 && (
				<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Crosstab}>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<CrosstabSunburstChart
							rows={parsedRows}
							categories={parsedCategories}
							fullscreen={fullscreen}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<CrosstabSunburstChart
							id={crosstabSunburstId}
							rows={parsedRows}
							categories={parsedCategories}
							isForExport
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{renderStatistics && (
				<AnalysisStatisticsWrapper>
					<CrosstabStatistics analysis={analysis} />
				</AnalysisStatisticsWrapper>
			)}
		</AnalysisViewContainer>
	);
}
