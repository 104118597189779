import { ArithmeticOperator, ComparisonOperator } from 'types/data/variables/constants';
import type { SerializedContentWithPositions, Variable } from 'api/data/variables/types';
import { SubType, TokenType } from 'types/UI/calculatedEditor/constants';
import type {
	CategoryToken,
	KeywordToken,
	NumberToken,
	OperatorToken,
	ParenthesisToken,
	PlaceholderToken,
	SeparatorToken,
	VariableToken
} from 'types/UI/calculatedEditor/types';
import { nanoid as generate } from 'nanoid';
import { Colors } from 'environment';

export const tokenGenerator = {
	generateVariableToken: (
		variable: Variable,
		startPosition: number,
		id: string
	): { token: VariableToken; nextPos: number } => {
		return {
			token: {
				id: `${variable.name}_${id}`,
				value: variable.label,
				pos: {
					start: startPosition,
					end: startPosition + variable.label.length
				},
				type: TokenType.Identifier,
				subType: SubType.Variable
			},
			nextPos: startPosition + variable.label.length
		};
	},
	generateOperatorToken: (
		operator: ArithmeticOperator | ComparisonOperator,
		startPosition: number,
		depth: number
	): { token: OperatorToken; nextPos: number } => {
		let subType = SubType.Plus;

		if (operator === ArithmeticOperator.Subtraction) {
			subType = SubType.Minus;
		}
		if (operator === ArithmeticOperator.Multiplication) {
			subType = SubType.Multiply;
		}
		if (operator === ArithmeticOperator.Division) {
			subType = SubType.Divide;
		}
		if (operator === ComparisonOperator.Equals) {
			subType = SubType.Equals;
		}
		if (operator === ComparisonOperator.GreaterThan) {
			subType = SubType.GreaterThan;
		}
		if (operator === ComparisonOperator.GreaterThanOrEqual) {
			subType = SubType.GreaterThanOrEqual;
		}
		if (operator === ComparisonOperator.LessThan) {
			subType = SubType.LessThan;
		}
		if (operator === ComparisonOperator.LessThanOrEqual) {
			subType = SubType.LessThanOrEqual;
		}

		return {
			token: {
				id: generate(),
				value: operator,
				pos: {
					start: startPosition,
					end: startPosition + operator.length
				},
				type: TokenType.Operator,
				subType: subType,
				depth: depth,
				color: Colors.lqleditor.parenthesisAndOperatorColors[
					depth % Colors.lqleditor.parenthesisAndOperatorColors.length
				]
			},
			nextPos: startPosition + operator.length
		};
	},
	generateNumberToken: (
		value: number,
		startPosition: number
	): { token: NumberToken; nextPos: number } => {
		const isFloat = !Number.isInteger(value);
		return {
			token: {
				id: generate(),
				value: value.toString(),
				pos: {
					start: startPosition,
					end: startPosition + value.toString().length
				},
				type: TokenType.Number,
				subType: isFloat ? SubType.Float : SubType.Integer
			},
			nextPos: startPosition + value.toString().length
		};
	},
	generateParenthesisToken: (
		value: '(' | ')',
		startPosition: number,
		depth: number,
		matchingPairParenthesis: ParenthesisToken | null = null
	): { token: ParenthesisToken; nextPos: number } => {
		return {
			token: {
				id: generate(),
				value: value,
				pos: {
					start: startPosition,
					end: startPosition + value.length
				},
				type: TokenType.Parenthesis,
				subType: value === '(' ? SubType.OpenParenthesis : SubType.CloseParenthesis,
				depth: depth,
				color: Colors.lqleditor.parenthesisAndOperatorColors[
					depth % Colors.lqleditor.parenthesisAndOperatorColors.length
				],
				matchingPairId: matchingPairParenthesis ? matchingPairParenthesis.id : null
			},
			nextPos: startPosition + value.length
		};
	},
	generateKeywordToken: (
		value: string,
		startPosition: number
	): { token: KeywordToken; nextPos: number } => {
		return {
			token: {
				id: generate(),
				value: value,
				pos: {
					start: startPosition,
					end: startPosition + value.length
				},
				type: TokenType.Keyword,
				subType:
					value.toLowerCase() === 'and'
						? SubType.And
						: value.toLowerCase() === 'if'
						? SubType.If
						: SubType.ThenValueIs,
				color: Colors.lqleditor.keywordColor
			},
			nextPos: startPosition + value.length
		};
	},
	generateCategoryToken: (
		value: string,
		startPosition: number
	): { token: CategoryToken; nextPos: number } => {
		return {
			token: {
				id: generate(),
				value: value,
				pos: {
					start: startPosition,
					end: startPosition + value.length
				},
				type: TokenType.Identifier,
				subType: SubType.Category
			},
			nextPos: startPosition + value.length
		};
	},
	generateSeparatorToken: (
		value: string,
		startPosition: number
	): { token: SeparatorToken; nextPos: number } => {
		return {
			token: {
				id: generate(),
				value: value,
				pos: {
					start: startPosition,
					end: startPosition + value.length
				},
				type: TokenType.Separator,
				subType: SubType.Comma
			},
			nextPos: startPosition + value.length
		};
	},
	generatePlaceholderToken: (
		id: string,
		startPosition: number
	): { token: PlaceholderToken; nextPos: number } => {
		return {
			token: {
				id: id,
				value: ' ',
				pos: {
					start: startPosition,
					end: startPosition + 1
				},
				type: TokenType.Placeholder,
				subType: SubType.None
			},

			nextPos: startPosition + 1
		};
	}
};

export function generateMergeContent(content: SerializedContentWithPositions[]) {
	const mergedContent: SerializedContentWithPositions[] = [];

	let currentTextNode = content[0];

	for (let i = 1; i < content.length; i++) {
		const nextNode = content[i];

		if (
			currentTextNode.type === 'text' &&
			nextNode.type === 'text' &&
			currentTextNode.text &&
			!nextNode.marks &&
			!currentTextNode.marks
		) {
			currentTextNode.text += nextNode.text;
			currentTextNode.pos.end = nextNode.pos.end;
		} else {
			mergedContent.push(currentTextNode);
			currentTextNode = nextNode;
		}
	}

	mergedContent.push(currentTextNode);

	return mergedContent;
}
