import { CrosstabRow } from 'api/data/analyses';

/**
 *
 * @param rows CrossTabRow[]
 * @param totals number[] => column totals (row1[0] + row2[0] + ..., row0[1] + row1[1] + ..., ...)
 * @returns number[] => row totals (row0_total[last], row1_total[last], ...)
 */
export function computeCrosstabTotalValues(rows: CrosstabRow[], totals: number[]) {
	const values = rows.reduce(
		(acc, row, index) => {
			let sum = 0;
			row.valueDetails.map(value => (sum += Number(value.N)));

			acc[index] = sum;
			return acc;
		},
		[0]
	);
	const totalValues = totals.reduce((acc, total) => (acc += Number(total)), 0);

	values[values.length] = totalValues;
	return values;
}
