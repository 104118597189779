import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { KaplanMeierAnalysis, KaplanMeierResults, KaplanMeierDataModels } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper,
	AnalysisStatisticsWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { KaplanChart } from './KaplanChart';
import { KaplanStatistics } from './KaplanStatistics';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import {
	useProject,
	useAreFiltersValid,
	useTranslation,
	useAnalysesActiveColum,
	useFullscreenAnalysis
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: KaplanMeierAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function KaplanView({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const [{ data: project }] = useProject();
	const areFiltersValid = useAreFiltersValid();
	const { translate } = useTranslation();
	const {
		id,
		options: { configPanel, chartLegend = false },
		input: {
			selectedDataModel,
			variables: { durationVariable, startDate, endDate, groupVariable },
			statistics: { confidenceIntervals }
		},
		output: { dataset: groups }
	} = analysis;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const { selectItemsLabelMap } = variablesDataSelectItems;
	const { variablesMap } = variablesData;

	const kaplanMeierId = ZingChartExportTypes.KaplanMeier + id;

	const { parsedGroups } = useMemo(() => {
		let parsedGroups = groups;

		if (analysis.options.showCategoryLabels) {
			parsedGroups = cloneDeep(groups);
			const newGroup: KaplanMeierResults = {};

			for (const group in parsedGroups) {
				const parsedKey =
					(groupVariable &&
						mapVariableCategoryValueToLabel(group, variablesMap[groupVariable])) ??
					group;

				newGroup[parsedKey] = parsedGroups[group];
			}
			parsedGroups = newGroup;
		}

		return { parsedGroups };
	}, [groups, groupVariable, variablesMap, analysis.options.showCategoryLabels]);

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				kaplanMeierId,
				getExportFileName(
					ExportFileNames.KaplanMeierChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX:
			selectedDataModel !== KaplanMeierDataModels.duration
				? selectItemsLabelMap[endDate] + ' - ' + selectItemsLabelMap[startDate]
				: selectItemsLabelMap[durationVariable],
		labelY: translate(dict => dict.analysis.analyses.kaplan.yAxis)
	};

	const legendHeader = groupVariable ? selectItemsLabelMap[groupVariable] : undefined;

	const areStatisticsEnabled = Object.values(analysis.input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Kaplan}>
				<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
					<KaplanChart
						name={durationVariable}
						dataset={parsedGroups}
						confidenceIntervals={confidenceIntervals}
						isLegendEnabled={chartLegend}
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						loading={loading}
					/>
				</AnalysisChartContainer>
				<AnalysisExportWrapper>
					<KaplanChart
						id={kaplanMeierId}
						name={durationVariable}
						dataset={parsedGroups}
						confidenceIntervals={confidenceIntervals}
						isLegendEnabled
						legendHeader={legendHeader}
						isForExport
						scalesLabels={scalesLabels}
						loading={loading}
					/>
				</AnalysisExportWrapper>

				{renderStatistics && (
					<AnalysisStatisticsWrapper>
						<KaplanStatistics analysis={analysis} />
					</AnalysisStatisticsWrapper>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
