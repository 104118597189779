import produce from 'immer';
import { cloneDeep } from 'lodash';

import { SYSTEM_GENERATED_STATUS_NAME } from 'consts';

import initialState from './initialState';
import { ActionTypes, Actions, State, ProjectSort } from './types';

import { Actions as AuthActions, ActionTypes as AuthActionTypes } from '../../auth/types';
import { Actions as EntriesActions, ActionTypes as EntriesActionTypes } from '../entries/types';
import { Actions as StatusesActions, ActionTypes as StatusesActionTypes } from '../statuses/types';

import { StatusTypeAccess } from '../collaborators';
import { generateTimeZoneSelectItems } from 'helpers/projects/imports/importTimezone';
import { ProjectOwnership, ProjectStatus, ProjectType } from 'types/data/projects/constants';

export default (
	state: State = initialState,
	action: Actions | AuthActions | EntriesActions | StatusesActions
): State => {
	switch (action.type) {
		case EntriesActionTypes.GET_ENTRIES: {
			const { projectId, entries, totalCount } = action.payload;

			return produce(state, draft => {
				const { entriesCount } = draft;

				entriesCount.byProjectId[projectId] = {
					count: totalCount !== undefined ? totalCount : entries.length
				};
			});
		}

		case EntriesActionTypes.GET_MORE_ENTRIES: {
			const { projectId, entries } = action.payload;

			return produce(state, draft => {
				const { entriesCount } = draft;

				if (projectId in entriesCount.byProjectId) {
					entriesCount.byProjectId[projectId].count += entries.length;
				}
			});
		}

		case EntriesActionTypes.CREATE_ENTRY: {
			return produce(state, draft => {
				const { projectId, entriesCount } = draft;

				if (projectId && projectId in entriesCount.byProjectId) {
					entriesCount.byProjectId[projectId].count++;
				}
			});
		}

		case EntriesActionTypes.DELETE_ENTRY: {
			return produce(state, draft => {
				const { projectId, entriesCount } = draft;

				if (projectId && projectId in entriesCount.byProjectId) {
					entriesCount.byProjectId[projectId].count--;
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case AuthActionTypes.PATIENT_LOGIN: {
			return produce(state, draft => {
				draft.isValid = true;
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				if (projectId && draft.byId[projectId]) draft.isValid = true;

				// reset DPA state
				draft.DPA.files = [];
				draft.DPA.fetched = false;
			});
		}

		case ActionTypes.GET_PROJECTS:
		case ActionTypes.GET_PROMS: {
			const { projects, username } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				const isProm = action.type === ActionTypes.GET_PROMS;
				const projectKey = isProm ? 'proms' : 'projects';

				draft[projectKey] = cloneDeep(initialState[projectKey]);
				draft[projectKey].fetched = true;
				if (projectKey === 'proms') {
					draft.tableFilters.proms.pageIndex = 0;
				} else {
					draft.tableFilters.projects.pageIndex = 0;
				}

				const { all, byStatus, byOwnership } = draft[projectKey];

				if (!isProm) {
					draft.initialProjectIds = projects.map(project => project.projectId);
				}

				projects.forEach(project => {
					project.projectType = isProm ? ProjectType.PROM : ProjectType.CORE;

					byId[project.projectId] = {
						...byId[project.projectId],
						...project
					};

					all.push(project.projectId);

					byStatus[project.status].push(project.projectId);

					const ownProject = project.createdByUser === username;
					const orphanProject = !project.createdByUser;

					byOwnership[ProjectOwnership.All].push(project.projectId);

					if (ownProject) {
						byOwnership[ProjectOwnership.Own].push(project.projectId);
					} else if (!orphanProject) {
						byOwnership[ProjectOwnership.SharedWithMe].push(project.projectId);
					}
				});
			});
		}

		case ActionTypes.GET_PROJECT: {
			const { project, username, projectType } = action.payload;

			return produce(state, draft => {
				const projectKey = projectType === ProjectType.PROM ? 'proms' : 'projects';

				const {
					byId,
					[projectKey]: { all, byStatus, byOwnership }
				} = draft;

				draft.isValid = true;

				byId[project.projectId] = {
					...byId[project.projectId],
					...project
				};

				if (!all.includes(project.projectId)) {
					all.push(project.projectId);
				}

				if (!byStatus[project.status].includes(project.projectId)) {
					byStatus[project.status].push(project.projectId);
				}

				if (!byOwnership[ProjectOwnership.All].includes(project.projectId)) {
					byOwnership[ProjectOwnership.All].push(project.projectId);

					const ownProject = project.createdByUser === username;
					const orphanProject = !project.createdByUser;

					if (ownProject) {
						byOwnership[ProjectOwnership.Own].push(project.projectId);
					} else if (!orphanProject) {
						byOwnership[ProjectOwnership.SharedWithMe].push(project.projectId);
					}
				}
			});
		}

		case ActionTypes.GET_COUNTS: {
			const { counts } = action.payload;

			return produce(state, draft => {
				const { entriesCount } = draft;

				counts.forEach(count => {
					entriesCount.byProjectId[count.projectId] = {
						count: count.countForProject
					};
				});
			});
		}

		case ActionTypes.SET_TABLE_FILTERS: {
			const { options, matchProms } = action.payload;

			return produce(state, draft => {
				if (matchProms) {
					draft.tableFilters.proms = options;
				} else {
					draft.tableFilters.projects = options;
				}
			});
		}

		case ActionTypes.GET_IMPORT_DATA_TEXT: {
			const { importDataText } = action.payload;

			return produce(state, draft => {
				draft.import.helpText = importDataText;
			});
		}

		case ActionTypes.CREATE_PROJECT: {
			const { project } = action.payload;

			return produce(state, draft => {
				const { projectId, projectType, status } = project;

				const projectKey = projectType === ProjectType.PROM ? 'proms' : 'projects';

				const {
					byId,
					[projectKey]: { all, byStatus, byOwnership },
					initialProjectIds
				} = draft;

				byId[projectId] = project;

				if (!byStatus[status]) byStatus[status] = [];

				all.push(projectId);
				byStatus[project.status].push(projectId);
				byOwnership[ProjectOwnership.Own].push(projectId);
				byOwnership[ProjectOwnership.All].push(projectId);
				initialProjectIds.push(projectId);
			});
		}

		case ActionTypes.UPDATE_PROJECT: {
			const { project } = action.payload;

			return produce(state, draft => {
				const { byId, projects, proms } = draft;

				const previousStatus = byId[project.projectId]
					? byId[project.projectId].status
					: undefined;

				if (previousStatus && project.status !== previousStatus) {
					const isProm = project.projectType === ProjectType.PROM;

					const data = isProm ? proms : projects;

					data.byStatus[previousStatus] = data.byStatus[previousStatus].filter(
						id => id !== project.projectId
					);
					data.byStatus[project.status].push(project.projectId);
				}

				// APPLY CHANGES - SPREAD OPERATOR DUE TO WRONG TYPE INTERSECTION
				byId[project.projectId] = {
					...byId[project.projectId],
					...project
				};
			});
		}

		case ActionTypes.COPY_PROJECT: {
			return produce(state, draft => {
				draft.metadata.refetch = false;
			});
		}

		case ActionTypes.SET_COPY_PROJECT_ID: {
			return produce(state, draft => {
				const { copiedProjectId } = action.payload;
				draft.copiedProjectId = copiedProjectId;
			});
		}

		case ActionTypes.SET_REFETCH_PROJECT: {
			return produce(state, draft => {
				draft.metadata.refetch = true;
			});
		}

		case ActionTypes.DELETE_PROJECT: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byId, tableFilters, projects } = draft;

				if (!(projectId in byId)) return;

				const project = byId[projectId];

				const isProm = project.projectType === ProjectType.PROM;

				const stateProjectsOrProms = isProm ? draft.proms : draft.projects;

				stateProjectsOrProms.all = stateProjectsOrProms.all.filter(
					id => id !== project.projectId
				);

				stateProjectsOrProms.byStatus[project.status] = stateProjectsOrProms.byStatus[
					project.status
				].filter(id => id !== project.projectId);

				stateProjectsOrProms.byOwnership = {
					own: stateProjectsOrProms.byOwnership.own.filter(
						id => id !== project.projectId
					),
					sharedWithMe: stateProjectsOrProms.byOwnership.sharedWithMe.filter(
						id => id !== project.projectId
					),
					all: stateProjectsOrProms.byOwnership.all.filter(id => id !== project.projectId)
				};

				/**
				 * CLEAR `projectId` if the deleted project was accesed before
				 * This is done due to no-cleanup after leaving a project - `projectId` does NOT switch to `null`
				 */
				if (currentProjectId === projectId) draft.projectId = null;

				delete byId[projectId];

				/**
				 * REPAIR PROJECTS TABLE PAGINATION
				 */
				const { pageIndex, pageSize } = isProm ? tableFilters.proms : tableFilters.projects;

				const pagesCount = Math.ceil(projects.all.length / pageSize);
				const newPageIndex = pagesCount - 1;
				const lastPageIndex = newPageIndex < 0 ? 0 : newPageIndex;

				if (pageIndex > lastPageIndex) {
					if (isProm) {
						tableFilters.proms.pageIndex = lastPageIndex;
					} else {
						tableFilters.projects.pageIndex = lastPageIndex;
					}
				}
			});
		}

		case ActionTypes.SET_PROJECTS_SEARCH_TERM: {
			const { term, isProject } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const identifier = isProject ? 'projects' : 'proms';
				filters[identifier].term = term;
			});
		}

		case ActionTypes.SET_ACTIVE_STATUS: {
			const { status, isProject } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const identifier = isProject ? 'projects' : 'proms';
				filters[identifier].status = status;
			});
		}

		case ActionTypes.SET_ACTIVE_OWNERSHIP: {
			const { ownership, isProject } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const identifier = isProject ? 'projects' : 'proms';
				filters[identifier].ownership = ownership;
			});
		}

		case ActionTypes.SET_PROJECT_SORT: {
			const { sort, isProject } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const identifier = isProject ? 'projects' : 'proms';

				filters[identifier].sort = sort;
			});
		}

		case ActionTypes.UPLOAD_URL: {
			const { fileId, isBinaryFile } = action.payload;

			return produce(state, draft => {
				draft.import.file.fileId = fileId;
				draft.import.isBinary = isBinaryFile;
			});
		}

		case ActionTypes.UPLOAD_FILE: {
			return produce(state, draft => {
				draft.import.file.uploaded = true;
			});
		}

		case ActionTypes.STORE_CSV_CONTENT: {
			const { content, originalContent, headerDelimiter } = action.payload;

			return produce(state, draft => {
				draft.import.headerDelimiter = headerDelimiter;
				draft.import.csv = content;
				draft.import.originalContent = originalContent;
				draft.import.isBinary = false;
			});
		}

		case ActionTypes.RESET_CSV_DATA: {
			return produce(state, draft => {
				draft.import.importPercentage = 0;
				draft.import.csv = null;
				draft.import.convertedCsvData = null;
				draft.import.suggestedVariableTypes = null;
				draft.import.dataToEntries = {
					oldFormattedVariables: [],
					columnToMatch: null,
					variableToMatch: null
				};
				draft.import.formattedVariables = [];
				draft.import.importVariableSet.columnToMatchWithMainLevel = null;
				draft.import.importVariableSet.variableToMatchOnMainLevel = null;
			});
		}

		case ActionTypes.RESET_CSV: {
			return produce(state, draft => {
				draft.import.importPercentage = 0;
				draft.import.csv = null;
				draft.import.convertedCsvData = null;
			});
		}

		case ActionTypes.RESET_PROJECTS_FILTERS: {
			const { isProject } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const identifier = isProject ? 'projects' : 'proms';

				filters[identifier].ownership = ProjectOwnership.All;
				filters[identifier].sort = ProjectSort.byNumber;
				filters[identifier].status = ProjectStatus.Ongoing;
				filters[identifier].term = '';
			});
		}

		case ActionTypes.LEAVE_PROJECT: {
			const { projectId, matchProms } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byId, proms, projects } = draft;

				const promsOrProjects = matchProms ? proms : projects;
				const filters = matchProms ? draft.filters.proms : draft.filters.projects;

				if (promsOrProjects.fetched && byId[projectId]) {
					const { status } = byId[projectId];

					promsOrProjects.all = promsOrProjects.all.filter(
						project => project !== projectId
					);
					promsOrProjects.byOwnership.sharedWithMe =
						promsOrProjects.byOwnership.sharedWithMe.filter(
							project => project !== projectId
						);
					promsOrProjects.byOwnership.all = promsOrProjects.byOwnership.all.filter(
						project => project !== projectId
					);
					promsOrProjects.byStatus[status] = promsOrProjects.byStatus[status].filter(
						project => project !== projectId
					);

					// HANDLE LEAVING LAST SHARED PROJECT -> SWITCH TO ALL PROJECTS VIEW
					if (!promsOrProjects.byOwnership.sharedWithMe.length) {
						filters.ownership = ProjectOwnership.All;
					}

					/**
					 * CLEAR `projectId` if the deleted project was accesed before
					 * This is done due to no-cleanup after leaving a project - `projectId` does NOT switch to `null`
					 */
					if (currentProjectId === projectId) draft.projectId = null;

					delete byId[projectId];
				}
			});
		}

		case ActionTypes.UPDATE_IMPORT_PERCENTAGE: {
			const { importPercentage } = action.payload;

			return produce(state, draft => {
				draft.import.importPercentage = importPercentage;
			});
		}

		case ActionTypes.UPDATE_PROJECT_OWNER_PERMISSIONS: {
			const { permissions } = action.payload;

			return produce(state, draft => {
				const { projectId, byId } = draft;

				if (projectId && byId[projectId]) {
					byId[projectId].userAccess = permissions;
				}
			});
		}

		case ActionTypes.SUGGEST_VARIABLE_TYPES: {
			const {
				suggestedVariableTypes,
				dateFormats,
				dateTimeFormats,
				initialSuggestions,
				isExcelOrigin,
				timeZones,
				formattedVariables
			} = action.payload;

			return produce(state, draft => {
				draft.import.suggestedVariableTypes = suggestedVariableTypes;
				draft.import.initialSuggestions = initialSuggestions;
				draft.import.dateFormats = dateFormats;
				draft.import.dateTimeFormats = dateTimeFormats;
				draft.import.isExcelOrigin = isExcelOrigin;
				draft.import.timeZones = generateTimeZoneSelectItems(timeZones);
				if (formattedVariables.length > 0) {
					draft.import.formattedVariables = formattedVariables;
				}
			});
		}

		/**
		 * DPA
		 */

		case ActionTypes.GET_DPA: {
			const { DPAFiles } = action.payload;

			return produce(state, draft => {
				draft.DPA.files = DPAFiles;
				draft.DPA.fetched = true;
			});
		}

		case ActionTypes.PREVIEW_DPA: {
			const { previewDPAFile } = action.payload;

			return produce(state, draft => {
				draft.DPA.preview = previewDPAFile;
			});
		}

		case ActionTypes.SIGN_DPA: {
			return produce(state, draft => {
				// reset DPA state
				draft.DPA = {
					preview: null,
					files: [],
					fetched: false
				};
			});
		}

		case ActionTypes.CLEAR_PREVIEW_DPA_FILE: {
			return produce(state, draft => {
				draft.DPA.preview = null;
			});
		}

		/**
		 * IMPORTS
		 */

		case ActionTypes.SET_FORMATTED_VARIABLES: {
			const { formattedVariables } = action.payload;

			return produce(state, draft => {
				draft.import.formattedVariables = formattedVariables;
			});
		}

		case ActionTypes.SET_FORMATTED_VARIABLE: {
			const { formattedVariable } = action.payload;

			return produce(state, draft => {
				const index = draft.import.formattedVariables.findIndex(
					formattedVar => formattedVar.id === formattedVariable.id
				);

				draft.import.formattedVariables[index] = formattedVariable;
			});
		}

		case ActionTypes.SET_PREVIOUS_MAPPING: {
			const { importType } = action.payload;
			return produce(state, draft => {
				const previousMapping = draft.import.previousMapping;
				const dataToEntries = draft.import.dataToEntries;
				const importVariableSet = draft.import.importVariableSet;
				previousMapping.formattedVariables = draft.import.formattedVariables;
				previousMapping.importType = importType;

				previousMapping.columnToMatch = dataToEntries.columnToMatch;
				previousMapping.variableToMatch = dataToEntries.variableToMatch;
				previousMapping.columnToMatchWithMainLevel =
					importVariableSet.columnToMatchWithMainLevel;
				previousMapping.variableToMatchOnMainLevel =
					importVariableSet.variableToMatchOnMainLevel;
			});
		}

		case ActionTypes.SET_DATA_TO_ENTRIES: {
			const {
				dataToEntries: { oldFormattedVariables, columnToMatch, variableToMatch }
			} = action.payload;

			return produce(state, draft => {
				oldFormattedVariables &&
					(draft.import.dataToEntries.oldFormattedVariables = oldFormattedVariables);
				columnToMatch && (draft.import.dataToEntries.columnToMatch = columnToMatch);
				variableToMatch && (draft.import.dataToEntries.variableToMatch = variableToMatch);
			});
		}

		case ActionTypes.GET_PROJECTS_COLLABORATORS_AVATAR_DATA: {
			const {
				data: { byProjectId: fetchedAvatars }
			} = action.payload;

			return produce(state, draft => {
				const {
					avatars: { byProjectId }
				} = draft;

				draft.avatars.byProjectId = { ...byProjectId, ...fetchedAvatars };
			});
		}

		case ActionTypes.SET_PROJECTS_VIEW_OPTION: {
			const { viewOption } = action.payload;

			return produce(state, draft => {
				draft.metadata.viewOption = viewOption;
			});
		}

		case ActionTypes.SET_PROJECTS_TABLE_VISIBLE_COLUMNS: {
			const { columnNames } = action.payload;

			return produce(state, draft => {
				const {
					metadata: { columnSettings }
				} = draft;

				columnSettings.visible = columnNames;
			});
		}

		case ActionTypes.SET_ASSIGNED_ORGANIZATION_ID: {
			const { organizationId } = action.payload;

			return produce(state, draft => {
				draft.import.assignedOrganizationId = organizationId;
			});
		}

		/**
		 * IMPORT OF SERIES
		 */

		case ActionTypes.SET_IMPORT_VARIABLE_SET: {
			const { importVariableSet } = action.payload;

			return produce(state, draft => {
				draft.import.importVariableSet = {
					...draft.import.importVariableSet,
					...importVariableSet
				};
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case StatusesActionTypes.CREATE_STATUS: {
			const { status } = action.payload;

			return produce(state, draft => {
				const { projectId, byId } = draft;

				if (projectId && projectId in byId) {
					const project = byId[projectId];

					const { userAccess } = project;

					if (userAccess) {
						// INIT STRUCTURE
						if (!userAccess.statusTypeAccesses) {
							userAccess.statusTypeAccesses = [];

							const noStatus: StatusTypeAccess = {
								statusTypeVariableName: SYSTEM_GENERATED_STATUS_NAME,
								viewData: true,
								editData: true,
								setStatus: true
							};

							userAccess.statusTypeAccesses.push(noStatus);
						}

						const statusAccess: StatusTypeAccess = {
							statusTypeVariableName: status.name,
							viewData: true,
							editData: true,
							setStatus: true
						};

						userAccess.statusTypeAccesses.push(statusAccess);
					}
				}
			});
		}

		case StatusesActionTypes.CREATE_STATUSES: {
			const { statuses } = action.payload;

			return produce(state, draft => {
				const { projectId, byId } = draft;

				if (projectId && projectId in byId) {
					const project = byId[projectId];

					const { userAccess } = project;

					if (userAccess) {
						// INIT STRUCTURE
						if (!userAccess.statusTypeAccesses) {
							userAccess.statusTypeAccesses = [];

							const noStatus: StatusTypeAccess = {
								statusTypeVariableName: SYSTEM_GENERATED_STATUS_NAME,
								viewData: true,
								editData: true,
								setStatus: true
							};

							userAccess.statusTypeAccesses.push(noStatus);
						}

						statuses.forEach(status => {
							const statusAccess: StatusTypeAccess = {
								statusTypeVariableName: status.name,
								viewData: true,
								editData: true,
								setStatus: true
							};

							userAccess.statusTypeAccesses?.push(statusAccess);
						});
					}
				}
			});
		}

		case StatusesActionTypes.DELETE_STATUS: {
			const { statusName } = action.payload;

			return produce(state, draft => {
				const { projectId, byId } = draft;

				if (projectId && projectId in byId) {
					const project = byId[projectId];

					const { userAccess } = project;

					if (!(userAccess && userAccess.statusTypeAccesses)) return;

					userAccess.statusTypeAccesses = userAccess.statusTypeAccesses.filter(
						statusAccess => statusAccess.statusTypeVariableName !== statusName
					);

					const hasOneStatusAccess = userAccess.statusTypeAccesses.length === 1;

					if (hasOneStatusAccess) {
						const statusAccess = userAccess.statusTypeAccesses[0];

						const isSystemGenerated =
							statusAccess.statusTypeVariableName === SYSTEM_GENERATED_STATUS_NAME;

						if (isSystemGenerated) userAccess.statusTypeAccesses = [];
					}
				}
			});
		}

		case ActionTypes.GET_PROJECT_METADATA_DEFINITION: {
			const { metadata } = action.payload;

			return produce(state, draft => {
				draft.projectMetadataDefinition = metadata;
			});
		}

		default: {
			return state;
		}
	}
};
