import { VariableType } from 'types/data/variables/constants';
import {
	ColumnTitle,
	MatchColumnToMainVariableContainer,
	NarrowContainer,
	Subtitle,
	Title
} from 'components/Projects/CreateAndImport';
import type { ImportPageProps } from 'types/data/projects/import/types';

import { ImportType, InputType } from 'types/index';

import { Typography } from 'components/UI/Typography';
import { Pagination } from 'components/UI/Pagination';
import { Input } from 'components/UI/Inputs/Input';
import { FlexCellLabel, FlexCellType, FlexRow, List } from './ReplaceAll.style';
import { StickyFooter } from 'components/UI/StickyFooter';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { useCreateVariableSet, useTranslation } from 'hooks/store';
import { AssignProjectOrganizationModal } from 'components/Dataset/Modals';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeZoneOptions } from '../TimeZoneOptions/TimeZoneOptions';
import { Spacer } from 'components/UI/Spacer';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';

import {
	handleCustomDateFormatChange,
	handleVariableDateTypeChange,
	handleVariableTimeZoneChange,
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import {
	applyTimezoneToAllDatetimeVariables,
	importRequiresGlobalTimezoneSettings,
	variableHasSuggestedDateFormat
} from 'helpers/projects/imports/importTimezone';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { useImportManager } from 'hooks/store/data/projects/import/useImportManager';

export function ReplaceAll({
	selectedOption,
	handleBack,
	handleFinishImport,
	handleApiImportErrors
}: ImportPageProps) {
	const [{ loading: loadingCreateVariableSet }] = useCreateVariableSet();

	const { translate } = useTranslation();

	const { fullImportError, timezoneDuringImport } = useFlags();
	const {
		data: {
			main: {
				dataTypeSelectItems,
				dateTimeFormatsSelectItems,
				dateFormatsSelectItems,
				mappedMainLevelVariables,
				mappedInitialVariables,
				variablesWithDateFormat,
				initialSuggestions,
				timeZones,
				globalTimezone,
				formattedVariables,
				isExcelOrigin,
				isBinary
			},
			series: {
				showAssignOrganizationModal,
				isImportVariableSet,
				variableToMatchOnMainLevel,
				columnToMatchWithMainLevel,
				assignedOrganization,
				userOrganizations
			}
		},
		handlers: {
			main: {
				canUpload,
				onVariableTypeChanged,
				computeTitleAndSubtitle,
				handleChangeGlobalTimezone,
				onVariableLabelChange,
				requiresTimezoneSettings,
				setFormattedVariables,
				upload
			},
			series: {
				handleSelectColumnToMatchWithMainLevel,
				setAssignedOrganizationId,
				setStoreAssignedOrganizationId,
				setVariableToMatchOnMainLevel,
				setShowAssignOrganizationModal
			}
		},
		pagination: {
			pageIndex,
			pageSize,
			pagesCount,
			shouldPaginate,
			page,
			changePage,
			changePageSize
		}
	} = useImportManager({ selectedOption, handleFinishImport, handleApiImportErrors });

	const { title, subtitle } = computeTitleAndSubtitle();

	return (
		<>
			<NarrowContainer>
				{!fullImportError && <Title>{title}</Title>}
				<Subtitle>{subtitle}</Subtitle>

				{importRequiresGlobalTimezoneSettings(formattedVariables, timezoneDuringImport) && (
					<TimeZoneOptions
						value={globalTimezone}
						onChange={handleChangeGlobalTimezone}
						applyToAll={() =>
							applyTimezoneToAllDatetimeVariables(
								formattedVariables,
								globalTimezone,
								setFormattedVariables
							)
						}
					/>
				)}

				{isImportVariableSet && (
					<MatchColumnToMainVariableContainer>
						<FlexRow noMargin>
							<FlexCellLabel>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainFileColumn
									)}
								</Typography.Caption>
							</FlexCellLabel>
							<FlexCellType>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainVariableToMatch
									)}
								</Typography.Caption>
							</FlexCellType>
						</FlexRow>

						<FlexRow noMargin>
							<FlexCellLabel>
								<CreatableSelect
									canClear={false}
									className="select"
									items={mappedInitialVariables}
									scrollIntoView
									value={
										columnToMatchWithMainLevel
											? {
													label: columnToMatchWithMainLevel?.previewVariableLabel,
													value: columnToMatchWithMainLevel?.previewVariableLabel
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													)
											  }
									}
									onValueSelected={(_, index) =>
										handleSelectColumnToMatchWithMainLevel(index)
									}
								/>
							</FlexCellLabel>
							<FlexCellType>
								<CreatableSelect
									canClear={false}
									className="select"
									scrollIntoView
									value={
										variableToMatchOnMainLevel
											? {
													label: variableToMatchOnMainLevel.label,
													value: variableToMatchOnMainLevel.name
											  }
											: null
									}
									placeholder={translate(
										dict => dict.projectsPage.dataToEntries.variableName
									)}
									items={mappedMainLevelVariables}
									onValueSelected={(_, __, item) =>
										item &&
										setVariableToMatchOnMainLevel({
											name: item.value,
											label: item.label
										})
									}
								/>
							</FlexCellType>
						</FlexRow>
					</MatchColumnToMainVariableContainer>
				)}

				{shouldPaginate && (
					<Flex marginOffset={{ bottom: 1.6 }}>
						<Pagination
							totalCountLabel={translate(dict => dict.terms.variables)}
							pageIndex={pageIndex}
							pageSize={pageSize}
							pagesCount={pagesCount}
							changePage={changePage}
							changePageSize={changePageSize}
							totalCount={formattedVariables.length}
						/>
					</Flex>
				)}

				<FlexRow>
					<FlexCellLabel>
						<ColumnTitle>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.previewVariables
										.newVariableLabel
							)}
						</ColumnTitle>
					</FlexCellLabel>
					<FlexCellType>
						<ColumnTitle>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.previewVariables.dataType
							)}
						</ColumnTitle>
					</FlexCellType>
				</FlexRow>
				<List>
					{page.map(variable => (
						<div key={variable.id}>
							<FlexRow
								key={variable.id}
								style={{ alignItems: 'flex-start' }}
								id={
									variable.type === VariableType.Date
										? `preview-variable-cell-date-format-${variable.id}`
										: `preview-variable-cell-date-time-format-${variable.id}`
								}
							>
								<FlexCellLabel>
									{/* VARIABLE LABEL */}
									<Input
										type={InputType.Text}
										id={`preview-variable-label-${variable.id}`}
										value={variable.label}
										error={variable.labelError}
										placeholder={translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.variableNameHere
										)}
										onChange={e =>
											onVariableLabelChange({
												varId: variable.id,
												value: e.target.value,
												importType: ImportType.ReplaceAll,
												clearLabelErrors: true
											})
										}
									/>
									{[VariableType.Date, VariableType.DateTime].includes(
										variable.type as VariableType
									) && (
										<>
											{/* VARIABLE DATE FORMAT WHEN SUGGESTED BY BE */}
											{variableHasSuggestedDateFormat(
												variable,
												formattedVariables,
												isExcelOrigin
											) ? (
												<>
													<Spacer size={s => s.s} />
													<CreatableSelect
														scrollIntoView
														canClear={false}
														id={`preview-variable-date-time-format-${variable.id}`}
														disabled={
															(variablesWithDateFormat.includes(
																variable.previewVariableLabel
															) ||
																variablesWithDateFormat.includes(
																	variable.label
																)) &&
															initialSuggestions?.find(
																v =>
																	v.name ===
																		variable.previewVariableLabel ||
																	v.name === variable.label
															)?.suggestedVariableType ===
																variable.type
														}
														value={{
															label: matchDateTimeFormat(
																variable.dateFormat
															),
															value: matchDateTimeFormat(
																variable.dateFormat
															)
														}}
														items={
															variable.type === VariableType.Date
																? [
																		...dateFormatsSelectItems,
																		{
																			label: 'Custom',
																			value: 'Custom'
																		}
																  ]
																: [
																		...dateTimeFormatsSelectItems,
																		{
																			label: 'Custom',
																			value: 'Custom'
																		}
																  ]
														}
														onValueSelected={value =>
															value &&
															handleVariableDateTypeChange(
																variable.id,
																value,
																formattedVariables,
																setFormattedVariables
															)
														}
													/>
												</>
											) : (
												<>
													{/* DATE FORMAT PICKER WHEN NOT SUGGESTED BY BE */}
													{!isExcelOrigin &&
														showDateFormatPicker(
															variable.type as VariableType,
															VariableType.Date,
															variable.isExcelDateFormat,
															isBinary
														) && (
															<>
																<Spacer size={s => s.s} />
																<CreatableSelect
																	canClear={true}
																	id={`preview-variable-date-format-${variable.id}`}
																	scrollIntoView
																	disabled={
																		(variablesWithDateFormat.includes(
																			variable.previewVariableLabel
																		) ||
																			variablesWithDateFormat.includes(
																				variable.label
																			)) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																					variable.previewVariableLabel ||
																				v.name ===
																					variable.label
																		)?.suggestedVariableType ===
																			variable.type
																	}
																	value={
																		variable.dateFormat
																			? {
																					label: matchDateTimeFormat(
																						variable.dateFormat
																					),
																					value: matchDateTimeFormat(
																						variable.dateFormat
																					)
																			  }
																			: {
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					),
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateFormatsSelectItems
																	]}
																	onValueSelected={value =>
																		value &&
																		handleVariableDateTypeChange(
																			variable.id,
																			value,
																			formattedVariables,
																			setFormattedVariables
																		)
																	}
																/>
															</>
														)}
													{/* DATETIME FORMAT PICKER WHEN NOT SUGGESTED BY BE */}
													{showDateFormatPicker(
														variable.type as VariableType,
														VariableType.DateTime,
														variable.isExcelDateFormat,
														isBinary
													) && (
														<>
															<Spacer size={s => s.s} />
															<CreatableSelect
																scrollIntoView
																canClear={true}
																id={`preview-variable-date-time-format-${variable.id}`}
																disabled={
																	(variablesWithDateFormat.includes(
																		variable.previewVariableLabel
																	) ||
																		variablesWithDateFormat.includes(
																			variable.label
																		)) &&
																	initialSuggestions?.find(
																		v =>
																			v.name ===
																				variable.previewVariableLabel ||
																			v.name ===
																				variable.label
																	)?.suggestedVariableType ===
																		variable.type
																}
																value={
																	variable.dateFormat
																		? {
																				label: matchDateTimeFormat(
																					variable.dateFormat
																				),
																				value: matchDateTimeFormat(
																					variable.dateFormat
																				)
																		  }
																		: {
																				label: translate(
																					dict =>
																						dict
																							.projectsPage
																							.dataToEntries
																							.selectDateAndTime
																				),
																				value: translate(
																					dict =>
																						dict
																							.projectsPage
																							.dataToEntries
																							.selectDateAndTime
																				)
																		  }
																}
																items={[
																	{
																		label: 'Custom',
																		value: 'Custom'
																	},
																	...dateTimeFormatsSelectItems
																]}
																onValueSelected={value =>
																	value &&
																	handleVariableDateTypeChange(
																		variable.id,
																		value,
																		formattedVariables,
																		setFormattedVariables
																	)
																}
																onClear={() => {
																	handleVariableDateTypeChange(
																		variable.id,
																		'',
																		formattedVariables,
																		setFormattedVariables
																	);
																}}
															/>
														</>
													)}
												</>
											)}
											{/* WHEN THE USER HAS SELECTED TO ENTER CUSTOM DATETIME FORMAT */}
											{variable.dateFormat === DATE_FORMATS.Custom && (
												<>
													<Spacer size={s => s.s} />
													<Input
														type={InputType.Text}
														id={
															variable.type === VariableType.Date
																? `preview-variable-custom-date-format-${variable.id}`
																: `preview-variable-custom-date-time-format-${variable.id}`
														}
														value={variable.customDateFormat}
														error={variable.dateFormatError}
														placeholder={translate(
															dict =>
																dict.projectsPage.dataToEntries
																	.customDateFormat
														)}
														onChange={e =>
															handleCustomDateFormatChange(
																variable.id,
																e.target.value.toUpperCase(),
																formattedVariables,
																setFormattedVariables,
																variable.type ===
																	VariableType.DateTime
															)
														}
													/>
												</>
											)}
										</>
									)}
								</FlexCellLabel>
								<Icon svg={Svgs.ArrowLongRight} style={{ marginTop: '0.7rem' }} />
								<FlexCellType>
									<CreatableSelect
										canClear={false}
										id={`preview-variable-type-${variable.id}`}
										value={
											variable.type
												? {
														label: translate(
															dict =>
																dict.variableLabels[
																	variable.type as VariableType
																]
														),
														value: variable.type
												  }
												: {
														label: translate(
															({ inputPlaceholder }) =>
																inputPlaceholder.pleaseSelect
														),
														value: translate(
															({ inputPlaceholder }) =>
																inputPlaceholder.pleaseSelect
														)
												  }
										}
										scrollIntoView
										items={dataTypeSelectItems}
										onValueSelected={value =>
											value &&
											onVariableTypeChanged(
												variable.id,
												value as VariableType
											)
										}
									/>
									{/* TIMEZONE SETTINGS IF FORMAT DOES NOT INCLUDE Z / +HH:MM / -HH:MM */}
									{requiresTimezoneSettings(variable) && (
										<>
											<Spacer size={s => s.s} />
											<CreatableSelect
												canClear={true}
												id={`global_timezone_select`}
												items={timeZones}
												onClear={() =>
													handleVariableTimeZoneChange(
														variable.id,
														'',
														formattedVariables,
														setFormattedVariables
													)
												}
												onValueSelected={value =>
													value &&
													handleVariableTimeZoneChange(
														variable.id,
														value,
														formattedVariables,
														setFormattedVariables
													)
												}
												value={variable.timeZone}
											/>
										</>
									)}
								</FlexCellType>
							</FlexRow>
						</div>
					))}
				</List>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: loadingCreateVariableSet,
					disabled: canUpload(),
					onClick: upload
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: handleBack
				}}
				maxWidth={65.2}
				zIndex={1006}
			/>
			{/* ASSIGN GROUP MODAL */}
			<AssignProjectOrganizationModal
				visible={showAssignOrganizationModal}
				onContinue={() => {
					setShowAssignOrganizationModal(false);
				}}
				onClose={handleBack}
				organization={assignedOrganization}
				onSelect={id =>
					fullImportError
						? setStoreAssignedOrganizationId(id)
						: setAssignedOrganizationId(id)
				}
				userOrganizations={userOrganizations}
			/>
		</>
	);
}
