import { Typography } from 'components/UI/Typography';
import { OptionsHeaderContainer } from '../UI';
import { useAnalysisConfigPanel, useFullscreenAnalysis, useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { MediaQueries, Svgs } from 'environment';
import {
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	ComparePairedAnalysis,
	CorrelationsAnalysis,
	CorrelationsV1Analysis,
	CrosstabAnalysis,
	DensityPlotAnalysis,
	DensityPlotAnalysisV2,
	ExploreAnalysis,
	ExploreAnalysisV2,
	FrequenciesAnalysis,
	KaplanMeierAnalysis,
	LogisticRegressionAnalysis,
	NumberPlotXYAnalysis,
	PlotNumericAnalysis,
	TimeCourseAnalysisV1,
	TimeCourseAnalysisV2,
	LogisticRegressionAnalysisV2,
	CrosstabAnalysisV2,
	PlotNumericAnalysisV2,
	FrequenciesAnalysisV2,
	ComparePairedAnalysisV2,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { Icon } from 'components/UI/Icons';

interface Props {
	analysis:
		| ExploreAnalysis
		| ExploreAnalysisV2
		| FrequenciesAnalysis
		| FrequenciesAnalysisV2
		| CompareNumericAnalysisV2
		| CompareNumericAnalysisV1
		| ComparePairedAnalysis
		| ComparePairedAnalysisV2
		| CorrelationsAnalysis
		| CorrelationsV1Analysis
		| CrosstabAnalysis
		| CrosstabAnalysisV2
		| DensityPlotAnalysis
		| DensityPlotAnalysisV2
		| KaplanMeierAnalysis
		| KaplanMeierAnalysisV2
		| LogisticRegressionAnalysis
		| LogisticRegressionAnalysisV2
		| NumberPlotXYAnalysis
		| PlotNumericAnalysis
		| PlotNumericAnalysisV2
		| TimeCourseAnalysisV1
		| TimeCourseAnalysisV2;
}

export const AnalysisOptionsHeader = ({ analysis }: Props) => {
	const { translate } = useTranslation();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	const [fullscreen] = useFullscreenAnalysis();

	const [{ isConfigPanelOpen }, { openConfigPanel }] = useAnalysisConfigPanel(analysis.id);

	function handleCloseConfig() {
		openConfigPanel({ analysisId: analysis.id, openConfig: !isConfigPanelOpen });
	}

	return (
		<OptionsHeaderContainer fullscreen={fullscreen}>
			<Typography.H6>{translate(({ analysis }) => analysis.analyses.name)}</Typography.H6>
			{isMobileDevice ? (
				<Icon
					svg={Svgs.ArrowLeft}
					marginOffset={{ right: 4 }}
					variant={v => v.buttonActive}
					onClick={handleCloseConfig}
				/>
			) : (
				<Icon
					variant={v => v.button}
					svg={Svgs.Close}
					size={s => s.m}
					marginOffset={{ top: 0.6 }}
					onClick={handleCloseConfig}
				/>
			)}
		</OptionsHeaderContainer>
	);
};
