import { Node as ProsemirrorNode } from 'prosemirror-model';
import { ExtensionType, TokenType } from 'types/UI/calculatedEditor/constants';
import type {
	Variable,
	SerializedContent,
	SerializedContentWithPositions,
	SerializedDoc,
	SerializedDocWithPositions
} from 'api/data/variables/types';

function serializeNodeWithPosition(
	node: ProsemirrorNode,
	pos: number
): SerializedContentWithPositions {
	const serialized: SerializedContentWithPositions = {
		type: node.type.name,
		pos: {
			start: pos,
			end: pos + node.nodeSize
		},
		attrs: node.attrs
			? {
					...node.attrs
			  }
			: undefined
	};

	if (node.isText) {
		serialized.text = node.text;
	}

	if (node.marks && node.marks.length) {
		serialized.marks = node.marks.reduce((acc, mark) => {
			acc.push({
				type: mark.type.name,
				attrs: mark.attrs
			});
			return acc;
		}, [] as any[]);
	}

	if (node.content && node.childCount) {
		serialized.content = [];
		node.forEach((child, offset) => {
			const childPos = pos + 1 + offset; // +1 accounts for the beginning of the node boundary
			serialized.content!.push(serializeNodeWithPosition(child, childPos));
		});

		// set the end position of the parent node to the end position of the last child node + 1
		serialized.pos.end = serialized.content[serialized.content.length - 1].pos.end + 1;
	}

	return serialized;
}

export function serializeDocWithPositions(doc: ProsemirrorNode): SerializedDocWithPositions {
	const serializedContent: SerializedContentWithPositions[] = [];

	doc.descendants((node, pos) => {
		// Exclude the top-level doc node
		if (node.type.name !== 'doc') {
			serializedContent.push(serializeNodeWithPosition(node, pos));
		}
		return false; // We're handling child nodes in the serializeNodeWithPosition function, so return false here
	});

	const endPos =
		serializedContent.length > 0 ? serializedContent[serializedContent.length - 1].pos.end : 0;

	const serializedDoc: SerializedDocWithPositions = {
		type: 'doc',
		pos: {
			start: 0,
			end: endPos
		},
		content: serializedContent
	};

	return serializedDoc;
}

function removePosFromContent(contentWithPos: SerializedContentWithPositions): SerializedContent {
	const { pos, content, ...rest } = contentWithPos;

	return {
		...rest,
		...(content ? { content: content.map(removePosFromContent) } : {})
	} as SerializedContent;
}

export function dropPositionsFromSerializedDoc(
	docWithPos?: SerializedDocWithPositions | null
): SerializedDoc {
	if (!docWithPos) {
		return {
			type: 'doc',
			content: [
				{
					type: 'paragraph',
					attrs: { nodeIndent: null, style: '', spellcheck: 'false' }
				}
			]
		};
	}

	const { pos, content, ...rest } = docWithPos;

	return {
		...rest,
		content: content
			? content.map(removePosFromContent)
			: {
					type: 'paragraph',
					attrs: { nodeIndent: null, style: '', spellcheck: 'false' }
			  }
	} as SerializedDoc;
}

export function isSerializedDoc(
	content: SerializedDoc | SerializedContent
): content is SerializedDoc {
	return content.type === 'doc';
}

export function isSerializedContent(
	content: SerializedDoc | SerializedContent
): content is SerializedContent {
	return content.type !== 'doc';
}

export function generateTextNode(text: string, startPos: number): SerializedContentWithPositions {
	return { type: 'text', text, pos: { start: startPos, end: startPos + text.length } };
}

export function generateVariableTextNode(
	variable: Variable,
	id: string,
	startPostion: number
): SerializedContentWithPositions {
	const _id = `${variable.name}__${id}`;

	return {
		type: 'text',
		marks: [
			{
				type: 'Variable-Tokenizer',
				attrs: {
					id: _id,
					type: 'variable'
				}
			}
		],
		text: variable.label,
		pos: {
			start: startPostion,
			end: startPostion + variable.label.length
		}
	};
}
export function generatePlaceholderTextNode(
	id: string,
	startPosition: number
): SerializedContentWithPositions {
	return {
		type: 'text',
		marks: [
			{
				type: ExtensionType.PlaceholderTokenizer,
				attrs: {
					id: id,
					type: TokenType.Placeholder
				}
			}
		],
		text: ' ',
		pos: {
			start: startPosition,
			end: startPosition + 1
		}
	};
}
