import { useMemo } from 'react';

import { CrosstabRow } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { GenericMap } from 'types/index';

import { AnalysisGroupedTables } from '../../UI';
import { computeCrosstabTotalValues } from 'helpers/analysis';
import { decodeURIComponentSafe } from 'helpers/generic';

interface Props {
	rowTitle: string;
	columnTitle: string;
	rows: CrosstabRow[];
	columns: string[];
	totals: number[];
}

export function CrosstabTable({ rowTitle, columnTitle, rows, columns, totals }: Props) {
	const { translate } = useTranslation();

	const totalValues = useMemo(() => computeCrosstabTotalValues(rows, totals), [rows, totals]);

	interface ValueDetails {
		N: string;
		percent: string;
	}

	const rowValueDetails: GenericMap<ValueDetails[]> = {};

	rows.forEach(row => {
		rowValueDetails[row.label] = row.valueDetails;
	});

	const leftTableColumnLabel = decodeURIComponentSafe(rowTitle);
	const rightTableColumnLabel = decodeURIComponentSafe(columnTitle);

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={16} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column
							title={leftTableColumnLabel}
							height={8.4}
							minWidth={12}
							maxWidth={20}
							noWrap
						>
							{leftTableColumnLabel}
						</Table.Column>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{rows.map((row, index) => (
						<Table.Row key={`sticky-table-row-${index}`}>
							<Table.Cell title={row.label} maxWidth={20} height={6} noWrap>
								{row.label}
							</Table.Cell>
						</Table.Row>
					))}
					<Table.Row>
						<Table.Cell height={6} bold>
							{translate(({ analysis }) => analysis.analyses.crosstab.table.total)}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							<Table.Column
								title={rightTableColumnLabel}
								colSpan={columns.length + 1}
								noWrap
							>
								{rightTableColumnLabel}
							</Table.Column>
						</Table.Row>
						<Table.Row>
							{columns.map((column, index) => (
								<Table.Column
									title={column}
									key={`table-column-${index}`}
									minWidth={12}
									maxWidth={16}
									noWrap
								>
									{column}
								</Table.Column>
							))}
							<Table.Column minWidth={12}>
								{translate(
									({ analysis }) => analysis.analyses.crosstab.table.total
								)}
							</Table.Column>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{Object.keys(rowValueDetails).map((row, rowIndex) => (
							<Table.Row key={`table-row-${rowIndex}`}>
								{rowValueDetails[row].map((cell, cellIndex) => (
									<Table.Cell
										key={`table-row-${rowIndex}-cell-${cellIndex}`}
										height={6}
									>
										{cell.N}
										<br></br>
										{cell.percent}
									</Table.Cell>
								))}
								<Table.Cell height={6}>
									{totalValues[rowIndex]}
									<br></br>
									100%
								</Table.Cell>
							</Table.Row>
						))}
						<Table.Row>
							{totals.map((total, index) => (
								<Table.Cell key={`table-total-cell-${index}`} height={6} bold>
									{total}
									<br></br>
									{((total / totalValues[totalValues.length - 1]) * 100).toFixed(
										1
									)}
									%
								</Table.Cell>
							))}
							<Table.Cell height={6} bold>
								{totalValues[totalValues.length - 1]}
								<br></br>
								100%
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}
