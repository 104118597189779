import { Auth } from 'aws-amplify';

import { sendRequest, USER_URL } from 'api/utils';
import { handleIsAdminFlag, handleIsDataSetSuperAdminFlag } from 'api/utils/helpers';
import { stringAsBoolean } from 'helpers/generic';
import { LoginInput, LoginSteps } from 'store/auth';
import { StorageKeys } from 'types/index';

import {
	ChallengeType,
	CompleteFirstLoginInput,
	CompleteResetPasswordInput,
	Credentials,
	MfaType,
	User
} from './types';

const IS_MANDATORY_MFA_ENABLED = stringAsBoolean(process.env.REACT_APP_MANDATORY_MFA!);

const methods = {
	signOut: 'signOut'
};

export default () => ({
	login: async ({ username, password }: Credentials): Promise<LoginInput> => {
		try {
			const user: User = await Auth.signIn(username, password);

			localStorage.setItem(StorageKeys.Username, user.username);

			if (IS_MANDATORY_MFA_ENABLED && user.preferredMFA === MfaType.None) {
				// mandatory MFA but MFA not set
				localStorage.setItem(StorageKeys.LoginStep, LoginSteps.setupOneTimePassword);
				return { loginStep: LoginSteps.setupOneTimePassword, user };
			}

			if (user.challengeName === ChallengeType.Mfa) {
				// MFA not set
				return { loginStep: LoginSteps.oneTimePassword, user };
			}

			if (user.challengeName === ChallengeType.NewPassword) {
				// mandatory new password
				return { loginStep: LoginSteps.newPassword, user };
			}

			const { idToken } = user.signInUserSession;

			handleIsAdminFlag(idToken);
			handleIsDataSetSuperAdminFlag(idToken);
			localStorage.setItem(StorageKeys.IdToken, idToken.jwtToken);
			localStorage.setItem(StorageKeys.LoginCompleted, 'true');
			localStorage.removeItem(StorageKeys.LoginStep);
			localStorage.removeItem(StorageKeys.PatientLoginCompleted);

			return {
				user,
				loggedIn: true
			};
		} catch (e: any) {
			if (e.code === 'UserNotFoundException') {
				return {
					errors: {
						credentials: e.message
					}
				};
			} else if (e.code === 'PasswordResetRequiredException') {
				return {
					loginStep: LoginSteps.resetPassword,
					errors: {
						newPasswordRequired: true
					}
				};
			} else if (e.code === 'NotAuthorizedException') {
				return {
					errors: {
						credentials: e.message
					}
				};
			} else {
				throw new Error(e.message);
			}
		}
	},

	logout: async () => {
		try {
			await Auth.signOut();
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	ledidiLogout: async () => {
		await sendRequest(USER_URL, {
			method: methods.signOut
		});
	},

	completeFirstLogin: async ({ user, password, phone }: CompleteFirstLoginInput) => {
		try {
			const attributes = phone ? { phone_number: phone } : null;

			await Auth.completeNewPassword(user, password, attributes);
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	resetPassword: async (username: string) => {
		try {
			await Auth.forgotPassword(username);
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	completeResetPassword: async ({ username, password, code }: CompleteResetPasswordInput) => {
		try {
			await Auth.forgotPasswordSubmit(username, code, password);
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	setOneTimePassword: async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			return await Auth.setupTOTP(user);
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	verifyOneTimePassword: async (code: string) => {
		try {
			const user = await Auth.currentAuthenticatedUser();

			await Auth.verifyTotpToken(user, code);
			await Auth.setPreferredMFA(user, MfaType.OneTimePassword);

			const { idToken } = user.signInUserSession;

			handleIsAdminFlag(idToken);
			handleIsDataSetSuperAdminFlag(idToken);

			localStorage.setItem(StorageKeys.OneTimePassword, 'true');
		} catch (e) {
			throw new Error();
		}
	},

	submitOneTimePassword: async (user: User, code: string) => {
		try {
			const loggedUser = await Auth.confirmSignIn(user, code, ChallengeType.Mfa);

			const { idToken } = loggedUser.signInUserSession;

			handleIsAdminFlag(idToken);
			handleIsDataSetSuperAdminFlag(idToken);

			localStorage.removeItem(StorageKeys.LoginStep);
			localStorage.setItem(StorageKeys.LoginCompleted, 'true');
			localStorage.setItem(StorageKeys.IdToken, idToken.jwtToken);
			localStorage.setItem(StorageKeys.OneTimePassword, 'true');

			return { logged: true, otp: true };
		} catch (e) {
			throw new Error();
		}
	},

	resetMultiFactorAuth: async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			await Auth.setPreferredMFA(user, MfaType.None);
			localStorage.removeItem(StorageKeys.OneTimePassword);
		} catch (e: any) {
			throw new Error(e.message);
		}
	},

	patientLogin: async (username: string, password: string) => {
		try {
			await Auth.signIn(username, password);

			localStorage.removeItem(StorageKeys.IdToken);
			localStorage.removeItem(StorageKeys.LoginCompleted);
			localStorage.setItem(StorageKeys.PatientLoginCompleted, 'true');

			return true;
		} catch (e: any) {
			throw new Error(e.message);
		}
	}
});
