import { Chart } from 'components/UI/Chart';
import { NumberMap, ScalesLabels } from 'types/index';
import { useMemo } from 'react';
import { nanoid as generate } from 'nanoid';
import { ZingChartClickEvent } from 'zingchart-react';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import { useComputeFrequenciesBarChartData as useComputeData } from 'helpers/analysis';
import {
	useTranslation,
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	isForExport?: boolean;
	data: NumberMap;
	scalesLabels: ScalesLabels;
	activeColumn: number;
	fullscreen: string;
	loading: boolean;
	isConfigPanelOpen: boolean;
}

// TO DISCARD
export function FrequenciesBarChart({
	id,
	data,
	isForExport,
	scalesLabels,
	fullscreen,
	activeColumn,
	loading,
	isConfigPanelOpen
}: Props) {
	const { translate } = useTranslation();
	const { series, options } = useComputeData(data, scalesLabels, activeColumn, !!fullscreen);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();

	const renderId = useMemo(generate, [
		activeColumn,
		fullscreen,
		windowSize,
		analysisIds,
		isConfigPanelOpen,
		filters
	]);

	function onTogglePlot(e: ZingChartClickEvent) {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}

	const isEmpty = () => Object.values(data).filter(value => value !== 0).length === 0;

	if (isEmpty() && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	return (
		<Chart
			onLegendItemClick={onTogglePlot}
			key={renderId}
			id={id}
			type={t => t.Bar}
			series={series}
			options={options}
			effect={e => e.ExpandBottom}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
