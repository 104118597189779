import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { SignDPAInput, ProjectDPASignData } from 'api/data/projects';
import { initButtonProps } from 'helpers/buttons';
import { ActionTypes, CopyProject, copyProject } from 'store/data/projects';
import { createProject, CreateProject } from 'store/data/projects';
import { PromDistributionTypes } from 'types/index';
import { NarrowContainer, Title } from '..';
import {
	ProjectDPAIntroduction,
	ProjectDPAProcessingPurpose,
	ProjectDPAPreview,
	ProjectDPASigned
} from './Steps';
import { PreviewDPAFileResetter } from './PreviewDPAFileResetter';
import { StickyFooter } from 'components/UI/StickyFooter';
import { Flex } from 'components/UI/Flex';
import {
	useTranslation,
	useEventTracker,
	useProjectId,
	useProject,
	useSignProjectDPA,
	usePreviewProjectDPA,
	useCopyProject,
	useActivity
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, useEffectOnce, usePrevious } from 'hooks/utils';

export enum Steps {
	Introduction = 'introduction',
	ProcessingPurpose = 'processingPurpose',
	PreviewDPA = 'previewDPA',
	SignedDPA = 'signedDPA'
}

const STEPS_ORDER = [
	Steps.Introduction,
	Steps.ProcessingPurpose,
	Steps.PreviewDPA,
	Steps.SignedDPA
];

export interface DraftProjectDPA {
	state: {
		useDPA: boolean;
		terms: boolean;
		readDPA: boolean;
	};
	formData: ProjectDPASignData;
}

interface Props {
	existingProject?: boolean;
	draftProject?: CreateProject | null;
	createProjectController?: {
		loading: boolean;
		error: boolean;
		create: (...input: Parameters<typeof createProject>) => void;
	};
	copyProjectController?: {
		loading: boolean;
		error: boolean;
		copy: (...input: Parameters<typeof copyProject>) => void;
	};
	copyProjectData?: CopyProject | null;
	distributionType?: PromDistributionTypes; // USED FOR MANUAL DISTRIBUTION PROM
	onBack: () => void;
	onFinish: () => void;
}

export function ProjectDPAForm({
	existingProject,
	draftProject,
	createProjectController,
	copyProjectController,
	copyProjectData,
	distributionType,
	onBack,
	onFinish
}: Props) {
	const { translate } = useTranslation();

	const {
		trackEvent,
		trackingEventTypes: { AppEventTrackingTypes }
	} = useEventTracker();

	const [projectId, setProjectId] = useProjectId();
	const [{ data: project }] = useProject();
	const [draftProjectDPA, setDraftProjectDPA] = useState<DraftProjectDPA | null | null>(null);

	const [{ loading: signingDPA, error: errorSigningDPA }, signDPA] = useSignProjectDPA();
	const [, { reset: resetDPAFile }] = usePreviewProjectDPA();

	const [step, setStep] = useState(Steps.Introduction);

	const [useDPA, setUseDPA] = useState(draftProjectDPA?.state.useDPA ?? false);
	const [terms, setTerms] = useState(draftProjectDPA?.state.terms ?? false);
	const [readDPA, setReadDPA] = useState(draftProjectDPA?.state.readDPA ?? false);
	const [{ data: copiedProjectId }] = useCopyProject();

	const [{ loading: updatingProject, error: errorUpdatingProject }] = useActivity(
		ActionTypes.UPDATE_PROJECT
	);

	// AFTER DPA SIGN
	useCompletedAction(signingDPA, errorSigningDPA, navigateStep().last);

	// AFTER PROJECT CREATION
	useCompletedAction(
		createProjectController ? createProjectController.loading : false,
		createProjectController ? createProjectController.error : false,
		useDPA ? () => handleSignDPA(projectId) : onFinish
	);

	// AFTER COPY PROJECT
	useCompletedAction(
		copyProjectController ? copyProjectController.loading : false,
		copyProjectController ? copyProjectController.error : false,
		useDPA ? () => handleSignDPA(copiedProjectId) : () => null
	);

	// AFTER UPDATE PROJECT
	useCompletedAction(updatingProject, errorUpdatingProject, () => {
		if (!useDPA) {
			onFinish();
		}
	});

	// APPLY DRAFT DATA (IF EXISTS)
	useEffectOnce(() => {
		if (draftProjectDPA) {
			const { formData } = draftProjectDPA;

			Object.keys(formData).forEach(key => {
				const fieldName = key as keyof ProjectDPASignData;

				setValue(fieldName, formData[fieldName], {
					shouldDirty: true,
					shouldValidate: true
				});
			});
		}
	});

	/**
	 * Reset form values and accepted terms when the user clicks "No" in the question of "include personal data subject?"
	 */
	const prevUseDPA = usePrevious(useDPA);
	useEffect(() => {
		if (prevUseDPA !== undefined && prevUseDPA && !useDPA) {
			resetForm();
			resetDPAFile();
			resetPreviewStepStates();
		}
	}, [useDPA]);

	const validationSchema = yup.object({
		legalEntityName: yup.string().trim().required(),
		enterpriseNumber: yup.string().trim().required(),
		country: yup.string().trim().required(),
		purpose: yup.string().trim().required(),
		personalData: yup.string().trim().required(),
		dataSubjects: yup.string().trim().required()
	});

	const initialValues: ProjectDPASignData = {
		legalEntityName: '',
		enterpriseNumber: '',
		country: '',
		purpose: translate(dict => dict.projects.createAndImport.DPA.hasDPA.purposeExamples),
		personalData: translate(
			dict => dict.projects.createAndImport.DPA.hasDPA.personalDataExamples
		),
		dataSubjects: translate(dict => dict.projects.createAndImport.DPA.hasDPA.categoriesExamples)
	};

	const {
		Form,
		getValues,
		handleSubmit,
		FormProvider,
		setValue,
		reset: resetForm,
		formProviderProps,
		isDirty
	} = useReactForm({
		initialValues,
		validationSchema: useDPA ? validationSchema : undefined
	});

	const handleFormSubmit = handleSubmit(() => {
		if (
			isDirty &&
			((createProjectController &&
				!createProjectController.loading &&
				!createProjectController.error) ||
				(copyProjectController &&
					!copyProjectController.loading &&
					!copyProjectController.error))
		) {
			if (isAtStep(Steps.Introduction)) {
				if (areFieldsValidByStep(Steps.Introduction)) navigateStep().next();
			}

			if (isAtStep(Steps.ProcessingPurpose)) {
				if (areFieldsValidByStep(Steps.ProcessingPurpose)) navigateStep().next();
			}
		}
	});

	function handleSignDPA(id: string | null) {
		if (!projectId && !copiedProjectId) return;
		if (!draftProject && !project) return;

		const formValues = { ...getValues() };

		let signInput: SignDPAInput = {
			dpa: formValues,
			project: { projectId: '', projectName: '' }
		};
		if (draftProject && id !== null) {
			signInput = {
				...signInput,
				project: {
					projectId: id,
					projectName: draftProject.projectName
				}
			};
		}
		if (project && id !== null) {
			signInput = {
				...signInput,
				project: {
					projectId: id,
					projectName: project.projectName
				}
			};
		}
		signInput.project.projectId && signDPA(signInput);
		if (!draftProject || copiedProjectId) {
			setProjectId(null);
		}
	}

	function handleCreateProject() {
		trackEvent({
			eventName: AppEventTrackingTypes.createProject,
			eventSpecifics: {
				projectTracking: {
					projectWithDPA: useDPA
				}
			}
		});
		if (copyProjectData) {
			copyProjectController && copyProjectController.copy(copyProjectData);
			handleSignDPA(copiedProjectId);
		} else if (draftProject) {
			createProjectController &&
				createProjectController.create(draftProject, distributionType);
			handleSignDPA(projectId);
		}
	}

	function areFieldsValidByStep(targetStep: string) {
		let valid = false;

		const formValues = { ...getValues() };

		if (targetStep === Steps.Introduction) {
			const { legalEntityName, enterpriseNumber, country } = formValues;

			valid = areFieldsValid([legalEntityName, enterpriseNumber, country]);
		}
		if (targetStep === Steps.ProcessingPurpose) {
			const { purpose, personalData, dataSubjects } = formValues;

			valid = areFieldsValid([purpose, personalData, dataSubjects]);
		}

		function areFieldsValid(fields: string[]) {
			return fields.every(value => value.trim() !== '');
		}

		return valid;
	}

	const buttonProps = initButtonProps(buttons => {
		if (isAtStep(Steps.Introduction)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.continue),
				disabled: useDPA
					? !areFieldsValidByStep(Steps.Introduction)
					: existingProject ?? false,
				onClick: useDPA ? navigateStep().next : handleCreateProject
			};

			buttons.neutral = {
				label: translate(({ buttons }) => buttons.back),
				onClick: () => {
					setDraftProjectDPA({
						state: { useDPA, terms, readDPA },
						formData: { ...getValues() }
					});
					onBack();
				}
			};
		}

		if (isAtStep(Steps.ProcessingPurpose)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.continue),
				disabled: !areFieldsValidByStep(Steps.ProcessingPurpose),
				onClick: navigateStep().next
			};

			buttons.neutral = {
				label: translate(({ buttons }) => buttons.back),
				onClick: navigateStep().prev
			};
		}

		if (isAtStep(Steps.PreviewDPA)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.signAndContinue),
				disabled: !terms,
				onClick: handleCreateProject
			};

			buttons.neutral = {
				label: translate(({ buttons }) => buttons.back),
				onClick: navigateStep().prev
			};
		}

		if (isAtStep(Steps.SignedDPA)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.ok),
				onClick: onFinish
			};
		}

		if (signingDPA || (createProjectController && createProjectController.loading)) {
			buttons.primary.loading = true;

			// DISABLE SECONDARY BUTTON `onClick`
			delete buttons.neutral?.onClick;
		}
	});

	function isAtStep(targetStep: string) {
		return step === targetStep;
	}

	function navigateStep() {
		const currentStepIndex = STEPS_ORDER.indexOf(step);

		function prev() {
			const prevStepIndex = currentStepIndex - 1;

			if (isIndexInRange(prevStepIndex)) {
				const prevStep = STEPS_ORDER[prevStepIndex];

				setStep(prevStep);
			}
		}

		function next() {
			const nextStepIndex = currentStepIndex + 1;

			if (isIndexInRange(nextStepIndex)) {
				const nextStep = STEPS_ORDER[nextStepIndex];

				setStep(nextStep);
			}
		}

		function first() {
			setStep(STEPS_ORDER[0]);
		}

		function last() {
			const lastStep = STEPS_ORDER[STEPS_ORDER.length - 1];

			setStep(lastStep);
		}

		function isIndexInRange(index: number) {
			return STEPS_ORDER[index] !== undefined;
		}

		return { prev, next, first, last };
	}

	function resetPreviewStepStates() {
		setTerms(false);
		setReadDPA(false);
	}

	return (
		<Flex justify={j => j.between} column style={{ height: '100%' }}>
			<NarrowContainer>
				<FormProvider {...formProviderProps}>
					<PreviewDPAFileResetter onReset={resetPreviewStepStates} />

					<Form onSubmit={handleFormSubmit}>
						<Title>
							{translate(
								dict =>
									dict.projects.createAndImport.DPA.hasDPA.dataProcessingAgreement
							)}
						</Title>

						{isAtStep(Steps.Introduction) && (
							<ProjectDPAIntroduction useDPAState={{ useDPA, setUseDPA }} />
						)}
						{isAtStep(Steps.ProcessingPurpose) && <ProjectDPAProcessingPurpose />}
						{isAtStep(Steps.PreviewDPA) && (
							<ProjectDPAPreview
								draftProject={draftProject ?? project}
								termsState={{ terms, setTerms }}
								readDPAState={{ readDPA, setReadDPA }}
							/>
						)}
						{isAtStep(Steps.SignedDPA) && <ProjectDPASigned />}
					</Form>
				</FormProvider>
			</NarrowContainer>

			<StickyFooter
				primary={buttonProps.primary}
				neutral={!isAtStep(Steps.SignedDPA) && buttonProps.neutral}
				maxWidth={65.2}
				modalFooter={true}
			/>
		</Flex>
	);
}
