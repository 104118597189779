import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import {
	useTranslation,
	useDocumentsSearchTerm,
	useDocumentsListFilters,
	useAdminUsersTableParams
} from 'hooks/store';

interface Props {
	fetching: boolean;
	showAddFileButton: boolean;
	showDPAButton: boolean;
	hasDocuments: boolean;
	onAddFileClick: () => void;
	onDPAButtonClick: () => void;
}

export function DocumentsHeader({
	fetching,
	showAddFileButton,
	showDPAButton,
	hasDocuments,
	onAddFileClick,
	onDPAButtonClick
}: Props) {
	const { translate } = useTranslation();

	const [searchTerm, setSearchTerm] = useDocumentsSearchTerm();

	const [filters, { resetAllFilters }] = useDocumentsListFilters();

	const [{ pageIndex, pageSize }, setPageParams] = useAdminUsersTableParams();

	function resetPageIndex() {
		if (pageIndex !== 0) setPageParams({ pageIndex: 0, pageSize });
	}

	function resetSearchTerm() {
		if (searchTerm.trim().length > 0) setSearchTerm({ term: '' });
	}

	function clearFilters() {
		resetPageIndex();
		resetAllFilters();
		resetSearchTerm();
	}

	const areFiltersActive = !!(filters.type.active.length || filters.uploadDate.valid);
	const areAnyFiltersActive = searchTerm.trim().length > 0 || areFiltersActive;

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					hasDocuments && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(({ documents }) => documents.search)}
								term={searchTerm}
								disabled={fetching}
								onChangeTerm={term => setSearchTerm({ term })}
							/>
							{showAddFileButton &&
								addButton({
									label: translate(dict => dict.documents.buttons.add),
									action: onAddFileClick
								})}
						</Flex>
					)
				}
			/>
			<Header.Title
				title={translate(({ documents }) => documents.list.title)}
				component={
					<Flex>
						{hasDocuments && (
							<>
								{/* CLEAR FILTERS */}
								{areAnyFiltersActive && (
									<Button
										title={translate(dict => dict.entriesOptions.clearFilters)}
										variant={v => v.link}
										onClick={clearFilters}
									/>
								)}
							</>
						)}
						{showDPAButton && (
							<Button
								variant={v => v.link}
								title={translate(({ documents }) => documents.buttons.DPA)}
								marginOffset={{ left: 2.4 }}
								onClick={onDPAButtonClick}
							/>
						)}
					</Flex>
				}
			/>
		</>
	);
}
