import { useMemo, ReactElement } from 'react';
import { ExploreResults } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { computeCellValue } from 'helpers/analysis';
import { decodeURIComponentSafe } from 'helpers/generic';
import { useTimeDurationEntries, useTranslation, useVariablesData } from 'hooks/store';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariableType } from 'types/data/variables/constants';
import {
	getMicrosecondsFromTimeDurationString,
	getTimeDurationFormatForAnalysis,
	getTimeDurationStringFromMicroseconds
} from 'helpers/entries';
import { buildAggregationRuleNameToAggregatorVariableMap } from 'helpers/variables';
import { GenericMap } from 'types';

interface Props {
	variablesDataSelectItems: VariablesDataSelectItems;
	dataset: ExploreResults;
}
interface TableRows {
	'nr of rows'?: ReactElement | null;
	average?: ReactElement | null;
	std?: ReactElement | null;
	confidentLower?: ReactElement | null;
	confidentUpper?: ReactElement | null;
	sem?: ReactElement | null;
	variance?: ReactElement | null;
	skewness?: ReactElement | null;
	kurtosis?: ReactElement | null;
	median?: ReactElement | null;
	min?: ReactElement | null;
	max?: ReactElement | null;
	range?: ReactElement | null;
	IQRLower?: ReactElement | null;
	IQRUpper?: ReactElement | null;
	missing?: ReactElement | null;
}

export function ExploreTable({ variablesDataSelectItems, dataset }: Props) {
	const { translate } = useTranslation();

	const { variablesMap, variableSetsMap } = useVariablesData();
	// NOTE: aggregation rules as variable are sent and mapped by rule.name, which is not indexed in `variablesMap`
	const aggregationRuleToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);
	const { parseTimeDurationEntryCell } = useTimeDurationEntries({ withTranslation: true });

	const { selectItemsLabelMap } = variablesDataSelectItems;

	const leftTableRows = {
		'nr of rows': translate(({ analysis }) => analysis.generic.n),
		average: translate(({ analysis }) => analysis.generic.mean),
		std: translate(({ analysis }) => analysis.generic.sd),
		confidentLower: translate(({ analysis }) => analysis.generic.confidenceLower),
		confidentUpper: translate(({ analysis }) => analysis.generic.confidenceUpper),
		sem: translate(({ analysis }) => analysis.generic.sem),
		variance: translate(({ analysis }) => analysis.generic.variance),
		skewness: translate(({ analysis }) => analysis.generic.skewness),
		kurtosis: translate(({ analysis }) => analysis.generic.kurtosis),
		median: translate(({ analysis }) => analysis.generic.median),
		min: translate(({ analysis }) => analysis.generic.min),
		max: translate(({ analysis }) => analysis.generic.max),
		range: translate(({ analysis }) => analysis.generic.range),
		IQRLower: translate(({ analysis }) => analysis.generic.IQRLower),
		IQRUpper: translate(({ analysis }) => analysis.generic.IQRUpper),
		missing: translate(({ analysis }) => analysis.generic.missing)
	};

	function initRow(): TableRows {
		return {
			'nr of rows': null,
			average: null,
			std: null,
			confidentLower: null,
			confidentUpper: null,
			sem: null,
			variance: null,
			skewness: null,
			kurtosis: null,
			median: null,
			min: null,
			max: null,
			range: null,
			IQRLower: null,
			IQRUpper: null,
			missing: null
		};
	}

	const { tableRows, tableColumns } = useMemo(() => {
		const tableColumns: string[] = [];
		const tableRows: GenericMap<TableRows> = {};
		dataset.forEach(value => {
			const variable =
				variablesMap[value.variableName] ??
				variablesMap[
					aggregationRuleToVariableNameMap[value.variableName].aggregator.variableName
				];
			const isTimeDuration = variable?.type === VariableType.TimeDuration;
			const format = variable?.durationFormat;

			tableColumns.push(value.variableName);
			tableRows[value.variableName] = initRow();

			tableRows[value.variableName]['nr of rows'] = computeCellValue(value['nr of rows'], {
				noDecimals: true
			});
			tableRows[value.variableName].average = computeCellValue(value.average, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].std = computeCellValue(value.std, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].confidentLower = computeCellValue(value.confidentLower, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].confidentUpper = computeCellValue(value.confidentUpper, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].sem = computeCellValue(value.sem, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].variance = computeCellValue(value.variance, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].skewness = computeCellValue(value.skewness);
			tableRows[value.variableName].kurtosis = computeCellValue(value.kurtosis);
			tableRows[value.variableName].median = computeCellValue(value.median, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].min = computeCellValue(value.min, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].max = computeCellValue(value.max, {
				noDecimals: false,
				duration: isTimeDuration
			});

			if (isTimeDuration && format) {
				const hasValue = value.min
					.replaceAll(/[a-zA-Z]/g, '')
					.split(':')
					.some(number => !isNaN(Number(number)));
				if (!hasValue) {
					tableRows[value.variableName].range = computeCellValue(value.min);
				} else {
					const analysisFormat = getTimeDurationFormatForAnalysis(format);
					const max = getMicrosecondsFromTimeDurationString(value.max, format);
					const min = getMicrosecondsFromTimeDurationString(value.min, format);
					const computedValue =
						getTimeDurationStringFromMicroseconds(max - min, format) ?? '';
					tableRows[value.variableName].range = computeCellValue(
						parseTimeDurationEntryCell(computedValue, analysisFormat),
						{
							noDecimals: false,
							duration: isTimeDuration
						}
					);
				}
			} else {
				tableRows[value.variableName].range = computeCellValue(
					(Number(value.max) - Number(value.min)).toString()
				);
			}

			tableRows[value.variableName].IQRLower = computeCellValue(value.IQRLower, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].IQRUpper = computeCellValue(value.IQRUpper, {
				noDecimals: false,
				duration: isTimeDuration
			});

			tableRows[value.variableName].missing = computeCellValue(value.missing, {
				noDecimals: true
			});
		});
		return { tableRows, tableColumns };
	}, [dataset]);

	return (
		<Table.Responsive>
			<Table fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column height={4} empty />
						{tableColumns.map((column, index) => {
							const label = decodeURIComponentSafe(selectItemsLabelMap[column]);

							return (
								<Table.Column
									height={4}
									title={label}
									minWidth={12}
									key={`right-table-row-column-${index}`}
									noWrap
								>
									{label}
								</Table.Column>
							);
						})}
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{Object.entries(leftTableRows).map(([key, value], index) => {
						return (
							<Table.Row key={`left-table-row-${index}`}>
								<Table.Cell key={`left-table-row-cell-${index}`} bold>
									{value}
								</Table.Cell>

								{tableColumns.map((_, varIndex) => {
									const variableKey = tableColumns[varIndex];
									return (
										<Table.Cell key={`right-table-row-${variableKey}`}>
											{tableRows[variableKey][key as keyof TableRows]}
										</Table.Cell>
									);
								})}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}
