import { Columns, DensityPlotAnalysis } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { ActionTypes, VariablesDataSelectItems } from 'store/data/analyses';

import { DensityPlotConfig } from './DensityPlotConfig';
import { DensityPlotView } from './DensityPlotView';
import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import { MediaQueries } from 'environment';
import { useFetchAnalysis, useAnalysesActiveColum, useFullscreenAnalysis } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

interface Props {
	analysis: DensityPlotAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const { loading } = useFetchAnalysis({
		id: analysis.id,
		actionType: ActionTypes.GET_DENSITY_PLOT
	});
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const analysisValidInput = isAnalysisInputValid(analysis);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
				{analysis.fetched && !loading ? (
					<DensityPlotView
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						variablesData={variablesData}
						exporting={exporting}
						loading={loading}
						key={analysis.id}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}
				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<DensityPlotConfig
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis numeric />
		</AnalysisContainer>
	);
}

export const DensityPlot = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
