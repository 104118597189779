import { ProjectDPASignData } from 'api/data/projects';

import { Steps } from '../ProjectDPAForm';

export function handleFieldAutoFocus(input: { formValues: ProjectDPASignData; step: Steps }) {
	const firstEmptyFieldName = getFirstEmptyFieldName(input);

	function shouldAutoFocus(fieldName: string) {
		return firstEmptyFieldName === fieldName;
	}

	return { shouldAutoFocus };
}

function getFirstEmptyFieldName(input: { formValues: ProjectDPASignData; step: Steps }) {
	const { formValues, step } = input;

	if (step === Steps.Introduction) {
		if (!isValueValid(formValues.legalEntityName)) return 'legalEntityName';
		if (!isValueValid(formValues.enterpriseNumber)) return 'enterpriseNumber';
		if (!isValueValid(formValues.country)) return 'country';
	}

	if (step === Steps.ProcessingPurpose) {
		if (!isValueValid(formValues.purpose)) return 'purpose';
		if (!isValueValid(formValues.personalData)) return 'personalData';
		if (!isValueValid(formValues.dataSubjects)) return 'dataSubjects';
	}

	return '';
}

function isValueValid(value: string) {
	return value.trim().length !== 0;
}
