import { isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import {
	Columns,
	CorrelationsStatisticsV1,
	CorrelationsV1Analysis,
	CorrelationsV1Variables
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ConfigContainer } from '../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';

import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

interface Props {
	analysis: CorrelationsV1Analysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CorrelationsV1Config({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();
	const {
		options: { configPanel },
		input: { variables, statistics }
	} = analysis;

	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);

	const [{ isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(analysis.id);

	const [activeColumn] = useAnalysesActiveColum();

	const { selectItemsMap, numericSelectItems, categorySelectItems } = variablesDataSelectItems;

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) {
			setStats(statistics);
		}
	}, [statistics]);

	useDebounce(
		() => {
			if (haveVariablesChanged(variables, values)) {
				const updatedAnalysis: CorrelationsV1Analysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							...values
						},
						statistics: {
							pearsonV1: false,
							spearmanV1: false,
							linearRegressionV1: false
						}
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	useDebounce(
		() => {
			if (haveStatisticsChanged(statistics, stats)) {
				const updatedAnalysis: CorrelationsV1Analysis = {
					...analysis,
					input: { ...analysis.input, statistics: stats }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[stats],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			prevShouldRefetchAnalyses !== undefined &&
			prevShouldRefetchAnalyses !== shouldRefetchAnalyses
		) {
			setStats({
				pearsonV1: false,
				spearmanV1: false,
				linearRegressionV1: false
			});
		}
	}, [shouldRefetchAnalyses]);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && configPanel.open && (
				<AnalysisOptionsHeader analysis={analysis as CorrelationsV1Analysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.x
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.xNumericVariable]}
						onValueSelected={xNumericVariable =>
							xNumericVariable && setValues(state => ({ ...state, xNumericVariable }))
						}
						canClear={false}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.y
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.yNumericVariable]}
						onValueSelected={yNumericVariable =>
							yNumericVariable && setValues(state => ({ ...state, yNumericVariable }))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.grouped
						)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.ungrouped
						)}
						items={categorySelectItems}
						disabled={isEmpty(categorySelectItems)}
						value={selectItemsMap[values.groupingVariable ?? ''] ?? null}
						onValueSelected={groupingVariable =>
							setValues(state => ({
								...state,
								groupingVariable: groupingVariable ?? undefined
							}))
						}
					/>

					{/* STATISTICS */}
					<Switch
						label={translate(dict => dict.analysis.statistics.pearson.name)}
						on={stats.pearsonV1}
						onChange={() =>
							setStats(state => ({ ...state, pearsonV1: !state.pearsonV1 }))
						}
					/>
					<Switch
						label={translate(dict => dict.analysis.statistics.spearman.name)}
						on={stats.spearmanV1}
						onChange={() =>
							setStats(state => ({ ...state, spearmanV1: !state.spearmanV1 }))
						}
					/>
					<Switch
						label={translate(dict => dict.analysis.statistics.linearRegression.name)}
						on={stats.linearRegressionV1}
						onChange={() =>
							setStats(state => ({
								...state,
								linearRegressionV1: !state.linearRegressionV1
							}))
						}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} isEnabled={variables.groupingVariable} />
		</ConfigContainer>
	);
}

const haveVariablesChanged = (own: CorrelationsV1Variables, values: CorrelationsV1Variables) =>
	own.xNumericVariable !== values.xNumericVariable ||
	own.yNumericVariable !== values.yNumericVariable ||
	own.groupingVariable !== values.groupingVariable;

const haveStatisticsChanged = (own: CorrelationsStatisticsV1, values: CorrelationsStatisticsV1) =>
	own.pearsonV1 !== values.pearsonV1 ||
	own.spearmanV1 !== values.spearmanV1 ||
	own.linearRegressionV1 !== values.linearRegressionV1;
