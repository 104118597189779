import { useEffect } from 'react';

import { ComparePairedAnalysis } from 'api/data/analyses';
import {
	AnalysisErrorWrapper,
	AnalysisStatisticsWrapper,
	AnalysisViewContainer
} from 'components/Analysis/Analyses';
import { VariablesData } from 'store/data/variables';
import { ExportFileNames, FileType } from 'types/index';

import { ComparePairedStatistics } from './ComparePairedStatistics';
import { ComparePairedTable } from './ComparePairedTable';
import { exportComparePaired } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import { useAreFiltersValid, useProject, useTranslation } from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: ComparePairedAnalysis;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function ComparePairedView({ analysis, variablesData, exporting, loading }: Props) {
	const { translate } = useTranslation();

	const areFiltersValid = useAreFiltersValid();

	const [{ data: project }] = useProject();

	const { variablesMap } = variablesData;

	const {
		input,
		options: { configPanel },
		output: { dataset }
	} = analysis;

	useEffect(() => {
		if (exporting && dataset) {
			const data = exportComparePaired(dataset, { translate, variablesMap });

			if (data && project) {
				const fileName = getExportFileName(
					ExportFileNames.ComparePairedTable,
					project.projectId,
					project.projectName
				);
				downloadFile(data, fileName, FileType.Csv);
			}
		}
	}, [exporting]);

	const areStatisticsEnabled = Object.values(input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.ComparePaired}>
				{dataset.data && (
					<ComparePairedTable variablesData={variablesData} dataset={dataset} />
				)}

				{renderStatistics && (
					<AnalysisStatisticsWrapper>
						<ComparePairedStatistics analysis={analysis} />
					</AnalysisStatisticsWrapper>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
