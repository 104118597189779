import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { FrequenciesAnalysis } from 'api/data/analyses';

import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames, FileType, NumberMap } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { FrequenciesBarChart } from './FrequenciesBarChart';
import { FrequenciesPieChart } from './FrequenciesPieChart';
import { FrequenciesTable } from './FrequenciesTable';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportFrequencies, exportSvg } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import {
	getAggregatorVariableNameByAggregationRuleName,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import {
	useTranslation,
	useProject,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalysisActiveTab
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: FrequenciesAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	loading: boolean;
	exporting: boolean;
}

export function FrequenciesView({
	analysis,
	variablesDataSelectItems,
	loading,
	exporting,
	variablesData
}: Props) {
	const { translate } = useTranslation();

	const [{ data: project }] = useProject();

	const {
		id,
		options: { configPanel, chartLegend = true },
		input: {
			variables: { categoryVariable }
		},
		output: {
			dataset: { results }
		}
	} = analysis;

	const [activeColumn] = useAnalysesActiveColum();

	const [fullscreen] = useFullscreenAnalysis();

	const { selectItemsLabelMap } = variablesDataSelectItems;

	const categoryLabel = selectItemsLabelMap[categoryVariable];

	const [activeTab] = useAnalysisActiveTab(id);

	const frequenciesColumnsId = ZingChartExportTypes.FrequenciesColumns + id;
	const frequenciesPieId = ZingChartExportTypes.FrequenciesPie + id;

	const { variablesMap, variableSetsMap } = variablesData;

	useEffect(() => {
		if (exporting && project) {
			if (activeTab === 0) {
				const data = exportFrequencies(parsedResults, { translate });

				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.FrequenciesTable,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			} else if (activeTab === 1) {
				exportSvg(
					frequenciesColumnsId,
					getExportFileName(
						ExportFileNames.FrequenciesColumns,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					frequenciesPieId,
					getExportFileName(
						ExportFileNames.FrequenciesPie,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: categoryLabel,
		labelY: translate(d => d.analysis.analyses.frequencies.name)
	};

	const aggregatorVariableNameByAggregationRuleNameMap = useMemo(() => {
		if (categoryVariable && !variablesMap[categoryVariable]) {
			return getAggregatorVariableNameByAggregationRuleName(variableSetsMap);
		}
	}, [variableSetsMap, categoryVariable]);
	const { parsedResults } = useMemo(() => {
		let parsedResults = cloneDeep(results);

		if (analysis.options.showCategoryLabels) {
			const newGroup: NumberMap = {};

			for (const set in parsedResults) {
				const parsedKey =
					(categoryVariable &&
						mapVariableCategoryValueToLabel(
							set,
							variablesMap[
								aggregatorVariableNameByAggregationRuleNameMap
									? aggregatorVariableNameByAggregationRuleNameMap[
											categoryVariable
									  ]
									: categoryVariable
							]
						)) ??
					set;
				newGroup[parsedKey] = parsedResults[set];
			}
			parsedResults = newGroup;
		}

		return { parsedResults };
	}, [results, categoryVariable, variablesMap, analysis.options.showCategoryLabels]);

	const disableInteractions = activeColumn !== 1 && !fullscreen;

	return (
		<AnalysisViewContainer
			disableInteractions={disableInteractions}
			isConfigPanelOpen={configPanel.open}
			loading={loading}
		>
			{activeTab === 0 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.Frequencies}
					ignoreAnalysisData
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesTable data={parsedResults} loading={loading} />
					</AnalysisChartContainer>
				</AnalysisErrorWrapper>
			)}
			{activeTab === 1 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.Frequencies}
					ignoreAnalysisData
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesBarChart
							data={parsedResults}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<FrequenciesBarChart
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							id={frequenciesColumnsId}
							data={parsedResults}
							scalesLabels={scalesLabels}
							isForExport
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 2 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.Frequencies}
					ignoreAnalysisData
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesPieChart
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							data={parsedResults}
							isLegendEnabled={chartLegend}
							legendHeader={categoryLabel}
							showLabels={true}
							loading={loading}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<FrequenciesPieChart
							id={frequenciesPieId}
							data={parsedResults}
							isForExport
							isLegendEnabled
							legendHeader={categoryLabel}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							loading={loading}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}
		</AnalysisViewContainer>
	);
}
