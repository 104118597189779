import { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { PlotNumericAnalysis } from 'api/data/analyses';
import { canFetchAnalysis, isAnalysisInputValid } from 'helpers/analysis';
import { AnalysisResult } from 'hooks/store/types';
import {
	ActionTypes,
	getPlotNumericColumns,
	getPlotNumericBoxplot,
	getPlotNumericScatter
} from 'store/data/analyses';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useActivities } from 'hooks/store/utils/useActivities';
import { useAreFiltersValid } from '../filters/useAreFiltersValid';
import { useAnalysisActiveTab } from './useAnalysisActiveTab';
import { useRefetchAnalyses } from './useRefetchAnalyses';

export function usePlotNumericAnalysis(analysis: PlotNumericAnalysis): AnalysisResult {
	const canDispatch = isAnalysisInputValid(analysis);

	const dispatch = useDispatch(canDispatch);

	const {
		id,
		options: { open },
		input: { variables },
		output: { dataset }
	} = analysis;

	const [activeTab] = useAnalysisActiveTab(id);

	const [activeTabType, setActiveTabType] = useState(activeTab ?? 0);
	const [areColumnsFetched, setAreColumnsFetched] = useState(!isEmpty(dataset.columns));
	const [areBoxesFetched, setAreBoxesFetched] = useState(!isEmpty(dataset.boxplot));
	const [areScatterFetched, setAreScatterFetched] = useState(!isEmpty(dataset.scatter));

	if (activeTab !== undefined && activeTabType !== activeTab) {
		setActiveTabType(activeTab);
	}

	const areFiltersValid = useAreFiltersValid();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();

	const [{ loading }] = useActivities(
		[
			ActionTypes.GET_PLOT_NUMERIC_COLUMNS,
			ActionTypes.GET_PLOT_NUMERIC_BOXPLOT,
			ActionTypes.GET_PLOT_NUMERIC_SCATTER
		],
		id
	);

	const prevVariables = usePrevious(variables);
	useEffect(() => {
		if (!prevVariables) return;

		const numericAndCategoryChanged =
			prevVariables.numericVariable !== variables.numericVariable ||
			prevVariables.categoryVariable !== variables.categoryVariable;
		const groupingChanged = prevVariables.groupingVariable !== variables.groupingVariable;

		if (activeTabType === 0) {
			if (numericAndCategoryChanged) {
				setAreBoxesFetched(false);
				setAreScatterFetched(false);
			}
			if (groupingChanged) setAreBoxesFetched(false);
		}
		if (activeTabType === 1) {
			if (numericAndCategoryChanged) {
				setAreColumnsFetched(false);
				setAreScatterFetched(false);
			}
			if (groupingChanged) setAreColumnsFetched(false);
		}
		if (activeTabType === 2) {
			setAreColumnsFetched(false);
			setAreBoxesFetched(false);
		}
	}, [variables]);

	const prevAnalysis = usePrevious(analysis);
	useEffect(() => {
		if (canFetchAnalysis(open, loading, areFiltersValid, canDispatch)) {
			if (shouldRefetchAnalysisTab(activeTabType)) dispatchAnalysis();
			else if (shouldRefetchAnalyses) dispatchAnalysis();
			else if (!isEqual(analysis.input.variables, prevAnalysis?.input.variables)) {
				dispatchAnalysis();
			}
		}
	}, [activeTabType, variables, areFiltersValid, shouldRefetchAnalyses]);

	function shouldRefetchAnalysisTab(activeTabType: number) {
		if (shouldRefetchAnalyses) {
			if (activeTabType === 0) {
				setAreBoxesFetched(false);
				setAreScatterFetched(false);
			} else if (activeTabType === 1) {
				setAreColumnsFetched(false);
				setAreScatterFetched(false);
			} else if (activeTabType === 2) {
				setAreColumnsFetched(false);
				setAreBoxesFetched(false);
			}
			return true;
		}
		if (activeTabType === 0) {
			if (!areColumnsFetched) {
				setAreColumnsFetched(true);
				return true;
			}
		}
		if (activeTabType === 1) {
			if (!areBoxesFetched) {
				setAreBoxesFetched(true);
				return true;
			}
		}
		if (activeTabType === 2) {
			if (!areScatterFetched) {
				setAreScatterFetched(true);
				return true;
			}
		}
	}

	function dispatchAnalysis() {
		if (activeTabType === 0) dispatch(getPlotNumericColumns(id));
		if (activeTabType === 1) dispatch(getPlotNumericBoxplot(id));
		if (activeTabType === 2) dispatch(getPlotNumericScatter(id));
	}

	return { loading, error: !areFiltersValid };
}
