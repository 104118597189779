import { useState } from 'react';
import { ViewProjectDPA } from 'components/Documents';
import { DPAFile } from 'store/data/projects';
import { NoDocuments } from './NoDocuments';
import { DocumentsList } from './List/DocumentsList';
import { DocumentsHeader } from './Header/DocumentsHeader';
import { UploadDocumentModal } from './Modals/UploadDocumentModal';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import {
	usePermissions,
	useIsProjectOwner,
	useGetProjectDPA,
	useProject,
	useProjectDocuments
} from 'hooks/store';

export function DocumentsPage() {
	const {
		hasDatasetWriteAllAccess,
		fetched: arePermissionsFetched,
		loading: loadingPermissions
	} = usePermissions();

	const isProjectOwner = useIsProjectOwner();

	const [{ data: projectDPAs, loading: loadingProjectDPA, fetched: isProjectDPAFetched }] =
		useGetProjectDPA();

	const projectDPA: DPAFile | undefined = projectDPAs[0];

	const showDPAButton = !!(isProjectOwner && projectDPA);

	const [{ loading: loadingProject }] = useProject();
	const [{ loading: loadingDocuments, data: documents, fetched: areDocumentsFetched }] =
		useProjectDocuments();

	const [uploadModalVisible, setUploadModalVisible] = useState(false);

	const [viewDPA, setViewDPA] = useState(false);

	const hasDocuments = documents.length > 0;

	const loadingDocumentsInitial = loadingDocuments && !areDocumentsFetched;

	const loading =
		loadingDocumentsInitial || loadingProject || loadingPermissions || loadingProjectDPA;

	const immediate = !arePermissionsFetched || !areDocumentsFetched || !isProjectDPAFetched;

	return (
		<>
			<DocumentsHeader
				fetching={loadingDocuments}
				showDPAButton={showDPAButton}
				showAddFileButton={hasDatasetWriteAllAccess}
				hasDocuments={hasDocuments}
				onAddFileClick={() => setUploadModalVisible(true)}
				onDPAButtonClick={() => setViewDPA(true)}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{documents.length ? (
						<DocumentsList documents={documents} />
					) : (
						<NoDocuments onAddFileClick={() => setUploadModalVisible(true)} />
					)}
				</Grid.Container>
			</Suspend>

			{/* DPA MODAL */}
			{viewDPA && projectDPA && (
				<ViewProjectDPA DPAFile={projectDPA} onClose={() => setViewDPA(false)} />
			)}
			{/* UPLOAD MODAL */}
			{uploadModalVisible && (
				<UploadDocumentModal onClose={() => setUploadModalVisible(false)} />
			)}
		</>
	);
}
