import { createSelector } from 'reselect';

import { CollaboratorAvatarData } from 'api/data/projects';
import { ProjectData, State } from './types';
import { selectParams } from 'store/utils';
import { filterAndSortProjects } from 'helpers/projects';
import { VariableType } from 'types/data/variables/constants';

import { ProjectMetadataName } from 'types';
import { transforMetadataParametersToFormRows } from 'helpers/projects/projectMetadata';
import { getTranslation } from 'hooks/store/ui/useTranslation';

/*
 * EXTRACTOR FUNCTIONS
 */

function getById({ byId }: State) {
	return byId;
}

function getProjectsAvatars({ avatars }: State) {
	return avatars;
}

function getInitialProjectIds({ initialProjectIds }: State) {
	return initialProjectIds;
}

function getProjectsEntriesCount({ entriesCount }: State) {
	return entriesCount;
}

function getMetadata({ metadata }: State) {
	return metadata;
}

function getProjectsTableFilters(state: State) {
	return state.tableFilters.projects;
}

function getProjectsTableFiltersProms(state: State) {
	return state.tableFilters.proms;
}

function getProjectsRows(
	{ byId, entriesCount, avatars, projectMetadataDefinition }: State,
	ids: string[]
): ProjectData[] {
	return ids.map(projectId => {
		// set initial values so we always return something
		let metadataProjectType = '';
		let metadataArchiveNumber = '';
		let collaboratorsCount = 0;

		// if project exists and has metadata
		if (byId[projectId] && byId[projectId].metadata && projectMetadataDefinition) {
			// get parsed metadata
			const metadataParameters =
				transforMetadataParametersToFormRows(projectMetadataDefinition);

			// get metadata project type number
			const metadataProjectTypeNumber =
				byId[projectId].metadata?.[ProjectMetadataName.projectType];

			// get metadata project type label
			metadataParameters.forEach(p => {
				const projectType = p.find(item => item.name === 'metadata_projectType');
				metadataProjectType = metadataProjectTypeNumber
					? getTranslation(
							() =>
								projectType?.categories[Number(metadataProjectTypeNumber) - 1] // because BE returns 1 for first value
									.name as string
					  )
					: '';
			});

			// get metadata archive number
			metadataArchiveNumber =
				byId[projectId].metadata?.[ProjectMetadataName.archiveNumber] || '';
		}
		if (avatars.byProjectId[projectId]?.byUserId) {
			collaboratorsCount = Object.keys(avatars.byProjectId[projectId].byUserId).length;
		}
		return {
			...byId[projectId],
			entries: entriesCount.byProjectId[projectId]?.count,
			collaborators: collaboratorsCount,
			...(projectMetadataDefinition && {
				metadataProjectType,
				metadataArchiveNumber
			})
		};
	});
}

function getProject({ byId, projectId }: State) {
	return projectId && byId[projectId] ? byId[projectId] : null;
}

function getProjects({ byId, filters, projects }: State) {
	return filterAndSortProjects(byId, filters.projects, projects);
}

function getProms({ byId, filters, proms }: State) {
	return filterAndSortProjects(byId, filters.proms, proms);
}

function getProjectsById(state: State) {
	return state.byId;
}

function getAllProjectsByIds(state: State) {
	return state.projects.all;
}

function getAllPromsIds(state: State) {
	return state.proms.all;
}

function getProjectName({ projectId, byId }: State) {
	return projectId && byId[projectId] ? byId[projectId].projectName : '';
}

function getFileData(state: State, allowTimeDurationType = true) {
	const { suggestedVariableTypes, file, isBinary } = state.import;

	let filteredSuggestedVariableTypes = suggestedVariableTypes;
	// filter out time duration types when uploading
	if (!allowTimeDurationType) {
		filteredSuggestedVariableTypes = filteredSuggestedVariableTypes
			? filteredSuggestedVariableTypes?.filter(
					variable => variable.suggestedVariableType !== VariableType.TimeDuration
			  )
			: null;
	}

	return {
		suggestedVariableTypes: filteredSuggestedVariableTypes,
		fileId: file.fileId,
		isBinary
	};
}

function getProjectPermissions({ projectId, byId }: State, id?: string) {
	if (id) {
		return byId[id]?.userAccess;
	} else if (projectId) {
		return byId[projectId]?.userAccess;
	}
}

function getProjectOwner({ projectId, byId }: State, id?: string) {
	if (id && byId[id]) return byId[id].createdByUser;

	if (projectId && byId[projectId]) return byId[projectId].createdByUser;
}

function getProjectOwnerDetails({ projectId, byId }: State) {
	if (!(projectId && byId[projectId])) return;

	const { userAccess: permissions, projectOwnerDetails } = byId[projectId];

	if (projectOwnerDetails && permissions) {
		const ownerDetails = {
			...projectOwnerDetails,
			accessToProject: permissions
		};

		return ownerDetails;
	}
}

function getImportDataText(state: State) {
	return state.import.helpText;
}

function getDPAFiles(state: State) {
	return state.DPA.files;
}

function getPreviewDPAFile(state: State) {
	return state.DPA.preview;
}

function getFormattedVariables(state: State) {
	return state.import.formattedVariables;
}

function getInitialFormattedVariables(state: State) {
	return state.import.initialSuggestions;
}

function getDataToEntries(state: State) {
	return state.import.dataToEntries;
}

function getPreviousMapping(state: State) {
	return state.import.previousMapping;
}

function getImportVariableSet(state: State) {
	return state.import.importVariableSet;
}

function getImportData(state: State) {
	return state.import;
}

function getTimeZones(state: State) {
	return state.import;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectProjectsViewOption = createSelector(
	[getMetadata],
	metadata => metadata.viewOption
);

export const selectProjectsTableVisibleColumns = createSelector(
	[getMetadata],
	metadata => metadata.columnSettings.visible
);

export const selectProjectsTableFilters = createSelector([getProjectsTableFilters], id => id);

export const selectPromsTableFilters = createSelector([getProjectsTableFiltersProms], id => id);

export const selectProjectsRows = createSelector([getProjectsRows], ids => ids);

export const selectInitialProjectIds = createSelector(
	[getInitialProjectIds],
	initialProjectIds => initialProjectIds
);

export const selectProject = createSelector([getProject], project => project);

export const selectProjects = createSelector([getProjects], ids => ids);

export const selectProms = createSelector([getProms], ids => ids);

export const selectProjectsById = createSelector([getProjectsById], projectsById => projectsById);

export const selectAllProjectsIds = createSelector(
	[getAllProjectsByIds],
	allProjectsIds => allProjectsIds
);

export const selectAllPromsIds = createSelector([getAllPromsIds], allPromsIds => allPromsIds);

type SelectProjectByIdParams = { id: string | null };

/**
 * Make it a factory in order to remove the cahce size of `1`
 *
 * This enforces the usage to be inside a `useMemo` with [] dependency array
 */
export const selectProjectById = () =>
	createSelector(
		[
			getById,
			// PARAMS
			(_: State, params: SelectProjectByIdParams) => selectParams.encode(params)
		],
		(byId, params) => {
			const { id } = selectParams.decode<SelectProjectByIdParams>(params);

			const _id = id ?? '';

			if (_id in byId) return byId[_id];
		}
	);

export const selectProjectName = createSelector([getProjectName], name => name);

export const selectFileData = createSelector([getFileData], csv => csv);

export const selectProjectPermissions = createSelector(
	[getProjectPermissions],
	permissions => permissions
);

export const selectProjectOwner = createSelector([getProjectOwner], owner => owner);

export const selectProjectOwnerDetails = createSelector(
	[getProjectOwnerDetails],
	ownerDetails => ownerDetails
);

export const selectImportDataText = createSelector(
	[getImportDataText],
	importDataText => importDataText
);

export const selectDPAFiles = createSelector([getDPAFiles], DPAFiles => DPAFiles);

export const selectPreviewDPAFile = createSelector(
	[getPreviewDPAFile],
	previewDPAFile => previewDPAFile
);

export const selectFormattedVariables = createSelector(
	[getFormattedVariables],
	formattedVariables => formattedVariables
);

export const selectInitialFormattedVariables = createSelector(
	[getInitialFormattedVariables],
	initialSuggestions => initialSuggestions
);

export const selectDataToEntries = createSelector(
	[getDataToEntries],
	dataToEntries => dataToEntries
);

export const selectPreviousMapping = createSelector(
	[getPreviousMapping],
	previousMapping => previousMapping
);

type selectProjectCollaboratorsAvatarsParams = { projectId: string };

export const selectProjectCollaboratorsAvatars = createSelector(
	[
		getProjectsAvatars,
		// PARAMS
		(_: State, params: selectProjectCollaboratorsAvatarsParams) => selectParams.encode(params)
	],
	(projectsAvatars, params) => {
		const { projectId } = selectParams.decode<selectProjectCollaboratorsAvatarsParams>(params);

		const collaboratorsAvatars: CollaboratorAvatarData[] = [];

		if (projectId in projectsAvatars.byProjectId) {
			const { byUserId } = projectsAvatars.byProjectId[projectId];

			Object.values(byUserId).forEach(avatarData => collaboratorsAvatars.push(avatarData));
		}

		return collaboratorsAvatars;
	}
);

export const selectAllProjectsAvatarsData = createSelector(
	[getProjectsAvatars],
	projectsAvatars => projectsAvatars
);

type selectProjectEntriesCountParams = { projectId: string };

export const selectProjectEntriesCount = createSelector(
	[
		getProjectsEntriesCount,
		// PARAMS
		(_: State, params: selectProjectEntriesCountParams) => selectParams.encode(params)
	],
	(projectsEntriesCount, params) => {
		const { projectId } = selectParams.decode<selectProjectEntriesCountParams>(params);

		if (projectId in projectsEntriesCount.byProjectId) {
			return projectsEntriesCount.byProjectId[projectId].count;
		}
	}
);

/*
	IMPORT OF SERIES
*/

export const selectImportVariableSet = createSelector(
	[getImportVariableSet],
	importVariableSet => importVariableSet
);

export const selectAssignedOrganizationId = createSelector(
	[getImportData],
	importData => importData.assignedOrganizationId
);

export const selectisExcelOrigin = createSelector(
	[getImportData],
	importData => importData.isExcelOrigin
);

export const selectTimeZones = createSelector([getTimeZones], importData => importData.timeZones);
// METADATA DEFINITION

export function selectProjectMetadataDefinition(state: State) {
	return state.projectMetadataDefinition;
}
