import { get, isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import {
	useDeepCompareCallback,
	useDispatch,
	useEffectOnce,
	usePrevious,
	useSelector
} from 'hooks/utils';
import {
	Analysis,
	CompareNumericAnalysisV1,
	CompareNumericAnalysisV2,
	CorrelationsAnalysis,
	CorrelationsV1Analysis,
	KaplanMeierAnalysis,
	KaplanMeierAnalysisV2,
	PlotNumericAnalysis,
	PlotNumericAnalysisV2
} from 'api/data/analyses';
import {
	ActionTypes,
	getChiSquareStatistics,
	getCompareNumericV1,
	getCompareNumericV2,
	getComparePaired,
	getCorrelationsV1,
	getCorrelationsV2,
	getCrosstab,
	getDensityPlot,
	getDensityPlotV2,
	getExplore,
	getExploreV2,
	getFisherStatistics,
	getFrequencies,
	getIndependentStatisticsV1,
	getIndependentStatisticsV2,
	getKaplanMeier,
	getKruskalStatisticsV1,
	getKruskalStatisticsV2,
	getLinearRegressionStatisticsV1,
	getLinearRegressionStatisticsV2,
	getLogRankStatistics,
	getLogisticRegression,
	getMannWhitneyStatisticsV1,
	getMannWhitneyStatisticsV2,
	getMcNemarStatistics,
	getNumberPlotXY,
	getOneWayAnovaStatisticsV1,
	getOneWayAnovaStatisticsV2,
	getOneWayManovaStatisticsV1,
	getOneWayManovaStatisticsV2,
	getPairedTTestStatistics,
	getPairedWilcoxonStatistics,
	getShapiroStatisticsV1,
	getShapiroStatisticsV2,
	getPearsonStatisticsV1,
	getPearsonStatisticsV2,
	getSpearmanStatisticsV1,
	getSpearmanStatisticsV2,
	getTukeyStatisticsV1,
	getTukeyStatisticsV2,
	getTwoWayAnovaStatisticsV1,
	getTwoWayAnovaStatisticsV2,
	getTwoWayManovaStatisticsV1,
	getTwoWayManovaStatisticsV2,
	getTimeCourseV1,
	getTimeCourseV2,
	selectIsAnalysisFetched,
	getLogisticRegressionV2,
	getCrosstabV2,
	getFisherStatisticsV2,
	getChiSquareStatisticsV2,
	getMcNemarStatisticsV2,
	getFrequenciesV2,
	getComparePairedV2,
	getKaplanMeierV2,
	getLogRankStatisticsV2,
	getPairedTTestStatisticsV2,
	getPairedWilcoxonStatisticsV2
} from 'store/data/analyses';
import { AnalysisResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { canFetchAnalysis, isAnalysisInputValid } from 'helpers/analysis';

import { useAnalysisById } from './useAnalysisById';
import { useAreFiltersValid } from '../filters/useAreFiltersValid';
import { useRefetchAnalyses } from './useRefetchAnalyses';
import { useAnalysisActiveTab } from './useAnalysisActiveTab';
import { useRefetchStatistics } from './useRefetchStatistics';
import { clearError } from 'store/ui/activities';
import { useGetErrorByType } from 'hooks/store/utils/useGetErrorByType';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	id: string;
	actionType: ActionTypes;
	refetchDependencies?: string[];
	initialFetchDependency?: string | null;
}

// exclude PlotNumeric - it would be too difficult and confusing to abstract the logic for that one;
// exclude correlations
type AnalysesType = Exclude<AnalysisType, AnalysisType.PlotNumeric | AnalysisType.PlotNumericV2>;
type Analyses = Exclude<Analysis, PlotNumericAnalysis & PlotNumericAnalysisV2>;

const handlersMap: Record<AnalysesType, (analysis?: Analyses) => void> = {
	[AnalysisType.TimeCourseV1]: analysis => analysis && getTimeCourseV1(analysis.id),
	[AnalysisType.TimeCourseV2]: analysis => analysis && getTimeCourseV2(analysis.id),
	[AnalysisType.Explore]: analysis => analysis && getExplore(analysis.id),
	[AnalysisType.ExploreV2]: analysis => analysis && getExploreV2(analysis.id),
	[AnalysisType.CompareNumericV2]: analysis => analysis && getCompareNumericV2(analysis.id),
	[AnalysisType.CompareNumericV1]: analysis => analysis && getCompareNumericV1(analysis.id),
	// here for backwards compatibility with snapshots that have old names!
	// [AnalysisType.CompareNumericInit]: () => undefined,
	[AnalysisType.Crosstab]: analysis => analysis && getCrosstab(analysis.id),
	[AnalysisType.CrosstabV2]: analysis => analysis && getCrosstabV2(analysis.id),
	[AnalysisType.Frequencies]: analysis => analysis && getFrequencies(analysis.id),
	[AnalysisType.FrequenciesV2]: analysis => analysis && getFrequenciesV2(analysis.id),
	[AnalysisType.Kaplan]: analysis => analysis && getKaplanMeier(analysis.id),
	[AnalysisType.KaplanV2]: analysis => analysis && getKaplanMeierV2(analysis.id),
	[AnalysisType.CorrelationsV2]: analysis => analysis && getCorrelationsV2(analysis.id),
	[AnalysisType.CorrelationsV1]: analysis => analysis && getCorrelationsV1(analysis.id),
	[AnalysisType.DensityPlot]: analysis => analysis && getDensityPlot(analysis.id),
	[AnalysisType.DensityPlotV2]: analysis => analysis && getDensityPlotV2(analysis.id),
	[AnalysisType.NumberPlotXY]: analysis => analysis && getNumberPlotXY(analysis.id),
	[AnalysisType.ComparePaired]: analysis => analysis && getComparePaired(analysis.id),
	[AnalysisType.ComparePairedV2]: analysis => analysis && getComparePairedV2(analysis.id),
	[AnalysisType.LogisticRegression]: analysis => analysis && getLogisticRegression(analysis.id),
	[AnalysisType.LogisticRegressionV2]: analysis =>
		analysis && getLogisticRegressionV2(analysis.id),
	[AnalysisType.JADBio]: () => undefined
};

const statisticsMap: Record<string, (id: string) => void> = {
	/**
	 * CORRELATIONS STATISTICS
	 */
	pearsonV1: (id: string) => getPearsonStatisticsV1(id),
	pearsonV2: (id: string) => getPearsonStatisticsV2(id),
	spearmanV1: (id: string) => getSpearmanStatisticsV1(id),
	spearmanV2: (id: string) => getSpearmanStatisticsV2(id),
	linearRegressionV1: (id: string) => getLinearRegressionStatisticsV1(id),
	linearRegressionV2: (id: string) => getLinearRegressionStatisticsV2(id),
	/**
	 * COMPARE NUMERIC
	 */
	shapiroV1: (id: string) => getShapiroStatisticsV1(id),
	shapiroV2: (id: string) => getShapiroStatisticsV2(id),
	mannWhitneyV1: (id: string) => getMannWhitneyStatisticsV1(id),
	mannWhitneyV2: (id: string) => getMannWhitneyStatisticsV2(id),
	independentV1: (id: string) => getIndependentStatisticsV1(id),
	independentV2: (id: string) => getIndependentStatisticsV2(id),
	oneWayAnovaV1: (id: string) => getOneWayAnovaStatisticsV1(id),
	oneWayAnovaV2: (id: string) => getOneWayAnovaStatisticsV2(id),
	twoWayAnovaV1: (id: string) => getTwoWayAnovaStatisticsV1(id),
	twoWayAnovaV2: (id: string) => getTwoWayAnovaStatisticsV2(id),
	tukeyV1: (id: string) => getTukeyStatisticsV1(id),
	tukeyV2: (id: string) => getTukeyStatisticsV2(id),
	kruskalV1: (id: string) => getKruskalStatisticsV1(id),
	kruskalV2: (id: string) => getKruskalStatisticsV2(id),
	twoWayManovaV1: (id: string) => getTwoWayManovaStatisticsV1(id),
	twoWayManovaV2: (id: string) => getTwoWayManovaStatisticsV2(id),
	oneWayManovaV1: (id: string) => getOneWayManovaStatisticsV1(id),
	oneWayManovaV2: (id: string) => getOneWayManovaStatisticsV2(id),
	/**
	 * KAPLAN MEIER STATISTICS
	 */
	logRank: (id: string) => getLogRankStatistics(id),
	confidenceIntervals: (id: string) => id && { type: '' },
	patients: (id: string) => id && { type: '' },
	logRankV2: (id: string) => getLogRankStatisticsV2(id),
	confidenceIntervalsV2: (id: string) => id && { type: '' },
	patientsV2: (id: string) => id && { type: '' },
	/**
	 * CROSSTAB STATISTICS
	 */
	fisher: (id: string) => getFisherStatistics(id),
	chiSquare: (id: string) => getChiSquareStatistics(id),
	mcNemar: (id: string) => getMcNemarStatistics(id),
	fisherV2: (id: string) => getFisherStatisticsV2(id),
	chiSquareV2: (id: string) => getChiSquareStatisticsV2(id),
	mcNemarV2: (id: string) => getMcNemarStatisticsV2(id),
	/**
	 * COMPARE PAIRED STATISTICS
	 */
	pairedTTest: (id: string) => getPairedTTestStatistics(id),
	pairedWilcoxon: (id: string) => getPairedWilcoxonStatistics(id),
	pairedTTestV2: (id: string) => getPairedTTestStatisticsV2(id),
	pairedWilcoxonV2: (id: string) => getPairedWilcoxonStatisticsV2(id)
};

/**
 *
 *  @refetchDependencies
 * 	path of value(s) in analysis object that will be compared to previous instance and trigger refetch if different;
 *  (default: [input.variables])
 *	@initialFetchDependency
 *  path of value in analysis object that will be checked for emptiness to trigger initial fetch;
 *  (default: output.dataset)
 */

export function useFetchAnalysis({
	id,
	actionType,
	refetchDependencies = ['input.variables'],
	initialFetchDependency = 'output.dataset'
}: Props): AnalysisResult {
	const analysis = useAnalysisById(id) as Exclude<Analysis, PlotNumericAnalysis>;
	const {
		options: { open }
	} = analysis;

	const [activeTab] = useAnalysisActiveTab(id);

	const [activeTabType, setActiveTabType] = useState(activeTab ?? 0);

	const canDispatch = isAnalysisInputValid(analysis);
	const dispatch = useDispatch(canDispatch);

	const areFiltersValid = useAreFiltersValid();
	const [shouldRefetchAllAnalyses] = useRefetchAnalyses();
	const [{ loading, error }] = useActivity(actionType, id);
	const errorId = useGetErrorByType(actionType, id)?.uuid;
	const canFetch = canFetchAnalysis(open, loading, areFiltersValid, canDispatch);
	const fetched = useSelector(state => selectIsAnalysisFetched(state.data.analyses, id));

	// AUTO SELECT ACTIVE TAB EFFECT
	useEffect(() => {
		if (activeTab !== undefined && activeTabType !== activeTab) {
			setActiveTabType(activeTab);
		}
	}, [activeTab, activeTabType]);

	// FETCH ANALYSIS ON FIRST MOUNT
	useEffectOnce(() => {
		if (!canFetch || fetched || !initialFetchDependency) return;

		if (isEmpty(get(analysis, initialFetchDependency))) {
			handler();
		}
	});

	// REFETCH ANALYSIS LOGIC
	const prevAnalysis = usePrevious(analysis);
	useEffect(() => {
		if (!canFetch) return;

		if (shouldRefetchAllAnalyses) return handler();

		// check for dependencies changes and refetch;
		let shouldRefetchAnalysis = false;
		if (!!prevAnalysis && refetchDependencies) {
			const triggerRefetch = refetchDependencies.some(dependency => {
				return !isEqual(get(analysis, dependency), get(prevAnalysis, dependency));
			});
			shouldRefetchAnalysis = triggerRefetch;
		}

		if (shouldRefetchAnalysis) handler();
	}, [canFetch, shouldRefetchAllAnalyses, analysis]);

	// REFETCH STATISTICS LOGIC
	const statisticsObject =
		(
			analysis as
				| CorrelationsAnalysis
				| KaplanMeierAnalysis
				| CompareNumericAnalysisV2
				| CompareNumericAnalysisV1
				| CorrelationsV1Analysis
				| CompareNumericAnalysisV1
				| KaplanMeierAnalysis
				| KaplanMeierAnalysisV2
		).input.statistics ?? [];

	const statisticsFlags = Object.values(statisticsObject) as boolean[];

	const statisticsCallbacks = Object.keys(statisticsObject).map(key => {
		return () => dispatch(statisticsMap[key](id));
	});
	useRefetchStatistics(statisticsFlags, statisticsCallbacks, {
		condition: canFetch
	});

	const handler = useDeepCompareCallback(() => {
		const handler = handlersMap[analysis.type as AnalysesType];
		dispatch(handler(analysis));
		if (errorId) dispatch(clearError({ uuid: errorId, type: actionType }));
	}, [analysis, handlersMap, errorId]);

	return { loading, error: error || !areFiltersValid };
}
