import { nanoid as generate } from 'nanoid';

import { Transaction } from 'api/data/projects';
import { createInitTransaction } from 'api/utils/helpers';
import { CanceledRequestError } from 'api/utils/network';
import { Dictionary } from 'environment';
import { timeout } from 'helpers/generic';
import { Thunk, ThunkDispatch } from 'store/types';

import { ActionTypes } from './types';

import { beginActivity, endActivity, setError } from './actions';

export function createActivity({ type, dispatch }: { type: string; dispatch: ThunkDispatch }) {
	const activityId = generate();

	function begin(input?: { payload: any }) {
		dispatch(
			beginActivity({
				type,
				uuid: activityId,
				...(input?.payload !== undefined && {
					payload: input.payload
				})
			})
		);
	}

	function end() {
		dispatch(endActivity({ type, uuid: activityId }));
	}

	function error({
		error,
		timeout,
		payload
	}: {
		error: string;
		timeout?: number;
		payload?: any;
	}) {
		// DO NOT ERROR ON CANCELED REQUESTS!
		if (error === CanceledRequestError.message) {
			return;
		}
		dispatch(
			setError({
				type,
				error,
				timeout,
				uuid: generate(),
				payload
			})
		);
	}

	return { begin, end, error, id: activityId };
}

interface DoAfterTransactionActionInput<T extends Record<string, any>> {
	transactionId: number;
	actionType: string;
	callback?: (response?: T) => void;
	errorCallback?: (error?: any) => void;
	error?: string;
	globalProjectId?: string;
	propagateError?: boolean;
}

/**
 * Waits for the transaction to finish and, if successful, executes callback
 *
 * @param transactionId number
 * @param actionType the action type for tracking the transaction activity with `useTransactionActivity` hook
 * @param callback function to call after transaction is done
 * @param errorCallback function to call if transaction failed
 * @returns {Promise} Promise
 */
export function doAfterTransaction<T extends Record<string, any> = Record<string, any>>({
	transactionId,
	actionType,
	callback = () => {
		return;
	},
	errorCallback,
	error,
	globalProjectId,
	propagateError
}: DoAfterTransactionActionInput<T>): Thunk {
	return async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DO_AFTER_TRANSACTION,
			dispatch
		});

		let projectId = null;
		const { projectId: id } = getState().data.projects;

		globalProjectId ? (projectId = globalProjectId) : (projectId = id);

		try {
			activity.begin({ payload: actionType });

			let transactionData = createInitTransaction();
			let timeoutCount = 0;
			let progressPercentage = 0;

			while (!transactionData.isFinished) {
				[transactionData] = (await Promise.all([
					context.api.data
						.projects()
						.checkTransactionProgress(
							Number(projectId),
							transactionId,
							propagateError && !!errorCallback
						),
					timeout(1000)
				])) as [Transaction<T>, any];

				if (transactionData.isTimedOut) {
					errorCallback?.();

					throw new Error(
						error || Dictionary.errors.api.entries.couldNotRecalculateEntries
					);
				}

				timeoutCount++;

				if (transactionData.progressPercentage !== progressPercentage) {
					timeoutCount = 0;
					progressPercentage = transactionData.progressPercentage;
				}

				if (timeoutCount >= 30) {
					errorCallback?.();

					throw new Error(
						error || Dictionary.errors.api.entries.couldNotRecalculateEntries
					);
				}
			}

			if (transactionData.isSuccessful) {
				callback(transactionData.response as T);
			} else {
				const { response } = transactionData;

				if (response) {
					if (propagateError && errorCallback) {
						return errorCallback(response);
					} else {
						if (response.errors && response.errors.length) {
							throw new Error(response.errors.join('\n'));
						}
						if (response.httpStatusCode !== 200) {
							//500 generic message;
							throw new Error(response.message ?? '');
						}
					}
				}
			}
		} catch (e: any) {
			if (!propagateError) {
				if (errorCallback) {
					errorCallback(e);
				}
			} else {
				activity.error({ error: e.message, payload: actionType });
			}
		} finally {
			activity.end();
		}
	};
}
