import { useEffect, useMemo } from 'react';

import { TimeCourseAnalysisV1, TimeCourseGroupedV1 } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { TimeCourseChartV1 } from './TimeCourseChartV1';
import { VariablesData } from 'store/data/variables';
import { cloneDeep } from 'lodash';
import { AnalysisChartContainer } from '../../UI';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { useProject, useAnalysesActiveColum, useFullscreenAnalysis } from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: TimeCourseAnalysisV1;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function TimeCourseViewV1({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const [{ data: project }] = useProject();

	const { selectItemsLabelMap } = variablesDataSelectItems;

	const {
		id,
		options: { configPanel, chartLegend = false },
		input: { variables },
		output: { dataset, grouping }
	} = analysis;

	const { variablesMap } = variablesData;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const { parsedDataset } = useMemo(() => {
		let parsedDataset = cloneDeep(dataset);

		if (analysis.options.showCategoryLabels && grouping) {
			parsedDataset = parsedDataset as TimeCourseGroupedV1;
			parsedDataset.groupedTimeCourse.forEach(g => {
				g.group.groupValue =
					(variables.groupingVariable &&
						mapVariableCategoryValueToLabel(
							g.group.groupValue,
							variablesMap[variables.groupingVariable]
						)) ??
					g.group.groupValue;
			});
		}

		return { parsedDataset };
	}, [dataset, variablesMap, analysis.options.showCategoryLabels]);

	const timeCourseChartId = ZingChartExportTypes.TimeCourse + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				timeCourseChartId,
				getExportFileName(
					ExportFileNames.TimeCourseChartV1,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[variables.dateVariable],
		labelY: selectItemsLabelMap[variables.numericVariable]
	};

	const legendHeader = variables.groupingVariable
		? selectItemsLabelMap[variables.groupingVariable]
		: undefined;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.TimeCourseV1}>
				{parsedDataset && (
					<>
						<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
							<TimeCourseChartV1
								data={parsedDataset}
								grouping={grouping}
								isLegendEnabled={chartLegend}
								errorBar={variables.errorBar ?? undefined}
								scalesLabels={scalesLabels}
								legendHeader={legendHeader}
								loading={loading}
								timeWindowSize={analysis.input.variables.timeWindowSize}
								isConfigPanelOpen={configPanel.open}
							/>
						</AnalysisChartContainer>
						<AnalysisExportWrapper>
							<TimeCourseChartV1
								id={timeCourseChartId}
								data={parsedDataset}
								grouping={grouping}
								errorBar={variables.errorBar ?? undefined}
								isForExport
								isLegendEnabled
								scalesLabels={scalesLabels}
								legendHeader={legendHeader}
								loading={loading}
								timeWindowSize={analysis.input.variables.timeWindowSize}
								isConfigPanelOpen={configPanel.open}
							/>
						</AnalysisExportWrapper>
					</>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
