import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	LicenceLimitationErrorTypes,
	toggleFreeLicenceLimitationModalEvent
} from 'helpers/licences';
import { LedidiStatusCode } from 'types/index';

import {
	ActionTypes,
	GetUsersByEmailsAction,
	ClearInvalidUsersAction,
	InviteUserAction,
	GetUsersByIdsAction,
	SetInvalidUsersAction,
	SearchUsersByEmailsAction
} from './types';

const getUsersByEmailsAction = (
	payload: ActionPayload<GetUsersByEmailsAction>
): GetUsersByEmailsAction => ({
	type: ActionTypes.GET_USERS_BY_EMAIL,
	payload
});

export const getUsersByEmails =
	(projectId: string, emails: string[]): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_USERS_BY_EMAIL,
			dispatch
		});

		try {
			activity.begin();

			const {
				notEligibleForSharing,
				notSharedWith,
				eligibleForInvite = [],
				users,
				ledidiStatusCode,
				notAllowedDueToDomainConstraintViolation
			} = await context.api.data.accounts().getUsersByEmails(projectId, emails);

			const licenceError = ledidiStatusCode
				? [LedidiStatusCode.ErrorLicence, LedidiStatusCode.ErrorLicenceOther].includes(
						ledidiStatusCode ?? ''
				  )
				: false;

			if (notEligibleForSharing && notEligibleForSharing.length) {
				if (licenceError) {
					toggleFreeLicenceLimitationModalEvent().dispatch(
						LicenceLimitationErrorTypes.collaboratorShareProject
					);
					activity.error({ error: '' });
				} else {
					activity.error({
						error: `User details are missing for the following users: ${notEligibleForSharing.join(
							', '
						)}`
					});
				}
			}

			const invalidUsers = notEligibleForSharing
				? [...notEligibleForSharing, ...notSharedWith]
				: notSharedWith;

			dispatch(
				getUsersByEmailsAction({
					users,
					eligibleForInvitation: eligibleForInvite,
					invalidUsers,
					notAllowedDueToDomainConstraintViolation:
						notAllowedDueToDomainConstraintViolation || []
				})
			);

			// THROW A CATCHABLE ERROR HERE AFTER THE STORE IS UPDATED
			if (licenceError) throw Error();
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const searchUsersByEmailsAction = (
	payload: ActionPayload<SearchUsersByEmailsAction>
): SearchUsersByEmailsAction => ({
	type: ActionTypes.SEARCH_USERS_BY_EMAIL,
	payload
});

export const searchUsersByEmails =
	(
		input: { emails: string[] },
		callbacks?: {
			onIsNotValidEmailList?: (invalidEmails: string[]) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.SEARCH_USERS_BY_EMAIL,
			dispatch
		});

		try {
			activity.begin();

			const { users, notSharedWith } = await context.api.data.accounts().searchUsersByEmails({
				input: { userAccesses: input.emails.map(email => ({ emailAddress: email })) },
				callbacks: {
					onIsNotValidEmailList: callbacks?.onIsNotValidEmailList
				}
			});

			dispatch(searchUsersByEmailsAction({ users, notSharedWith }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getUsersByIdsAction = (payload: ActionPayload<GetUsersByIdsAction>): GetUsersByIdsAction => ({
	type: ActionTypes.GET_USERS_BY_ID,
	payload
});

export const getUsersByIds =
	(ids: string[]): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_USERS_BY_ID,
			dispatch
		});

		try {
			activity.begin();

			const { users, userIdsNotFound: invalidUsers } = await context.api.data
				.accounts()
				.getUsersByIds(ids);

			if (invalidUsers && invalidUsers.length) {
				activity.error({
					error: `Following users could not be found: ${invalidUsers.join(', ')}`
				});
			}

			dispatch(getUsersByIdsAction({ users }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setInvalidUsers = (
	payload: ActionPayload<SetInvalidUsersAction>
): SetInvalidUsersAction => ({
	type: ActionTypes.SET_INVALID_USERS,
	payload
});

export const clearInvalidUsers = (): ClearInvalidUsersAction => ({
	type: ActionTypes.CLEAR_INVALID_USERS
});

export const isEmailUnique =
	(email: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.IS_EMAIL_UNIQUE,
			dispatch
		});

		try {
			activity.begin();

			await context.api.data.accounts().checkIfEmailIsUnique(email);
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const inviteUserAction = (payload: ActionPayload<InviteUserAction>): InviteUserAction => ({
	type: ActionTypes.INREACT_APP_USER,
	payload
});

export const inviteUser =
	(emailAddress: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INREACT_APP_USER,
			dispatch
		});

		try {
			activity.begin();

			const {
				data: {
					projects: { projectId }
				}
			} = getState();

			if (projectId) {
				await context.api.data.accounts().inviteUser(emailAddress);

				dispatch(inviteUserAction({ emailAddress }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};
