import { UserCard } from 'components/UI/UserCard';
import { Container } from './EligibleUser.style';
import { Button } from 'components/UI/Interactables/Button';
import { useTranslation, useUserEmail, useEventTracker } from 'hooks/store';

interface Props {
	emailAddress: string;
	disableInvite: boolean;
	onInvite: (emailAddress: string) => void;
}

export function EligibleUserForInvite({ emailAddress, disableInvite, onInvite }: Props) {
	const { translate } = useTranslation();
	const userEmail = useUserEmail();
	const {
		trackEvent,
		trackingEventTypes: { AppEventTrackingTypes }
	} = useEventTracker();

	function handleInvite() {
		if (!disableInvite) {
			const sameOrganizationInvitation =
				userEmail.split('@')[1] === emailAddress.split('@')[1];
			trackEvent({
				eventName: AppEventTrackingTypes.createUserByEmailInvite,
				eventSpecifics: {
					sameOrganizationInvitation
				}
			});
			onInvite(emailAddress);
		}
	}

	return (
		<Container>
			<UserCard.Basic userData={{ emailAddress }} userId={null} />
			<Button
				variant={v => v.secondary}
				title={translate(
					({ sharingConfirmNewMembers }) => sharingConfirmNewMembers.inviteUser
				)}
				onClick={handleInvite}
			/>
		</Container>
	);
}
