//@ts-nocheck
import { useMemo } from 'react';
import { Analysis, PlotNumericAnalysis } from 'api/data/analyses';
import { SubscriptionAddonCode } from 'types/index';
import { Dropdown } from 'components/UI/Dropdown';
import { buildAnalysis } from 'helpers/analysis';
import { stringAsBoolean } from 'helpers/generic';
import {
	useTranslation,
	useVariablesData,
	useCreateAnalysis,
	useIsUserAddonActive,
	useIsProjectAddonActive,
	useVariablesDataSelectItems
} from 'hooks/store';
import {
	getCompareNumericV1,
	getComparePaired,
	getCorrelationsV1,
	getTimeCourseV1,
	getCrosstab,
	getDensityPlot,
	getExplore,
	getFrequencies,
	getKaplanMeier,
	getLogisticRegression,
	getNumberPlotXY
} from 'store/data/analyses';
import { useDispatch } from 'hooks/utils';
import { AnalysisType } from 'api/data/analyses/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

// exclude PlotNumeric - it would be too difficult and confusing to abstract the logic for that one;
type AnalysesType = Exclude<AnalysisType, AnalysisType.PlotNumeric>;
type Analyses = Exclude<Analysis, PlotNumericAnalysis>;

const handlersMap: Partial<Record<AnalysesType, (analysis?: Analyses) => void>> = {
	[AnalysisType.TimeCourseV1]: analysis => analysis && getTimeCourseV1(analysis.id),
	[AnalysisType.Explore]: analysis => analysis && getExplore(analysis.id),
	[AnalysisType.Crosstab]: analysis => analysis && getCrosstab(analysis.id),
	[AnalysisType.Frequencies]: analysis => analysis && getFrequencies(analysis.id),
	[AnalysisType.Kaplan]: analysis => analysis && getKaplanMeier(analysis.id),
	// correlations do not auto fetch anymore!
	[AnalysisType.CompareNumericV1]: analysis => analysis && getCompareNumericV1(analysis.id),
	[AnalysisType.CorrelationsV1]: analysis => analysis && getCorrelationsV1(analysis.id),
	[AnalysisType.DensityPlot]: analysis => analysis && getDensityPlot(analysis.id),
	[AnalysisType.NumberPlotXY]: analysis => analysis && getNumberPlotXY(analysis.id),
	[AnalysisType.ComparePaired]: analysis => analysis && getComparePaired(analysis.id),
	[AnalysisType.LogisticRegression]: analysis => analysis && getLogisticRegression(analysis.id)
};

interface Props {
	addButton: (AddButtonParams: {
		label: string;
		disabled?: boolean;
		action: () => void;
	}) => React.ReactNode;
}

export function AddAnalysis({ addButton }: Props) {
	const { translate } = useTranslation();

	const {
		analysisVersion = 'v1',
		densityVersion = 'v1',
		timeCourseVersion = 'v1',
		compareVersion = 'v1',
		exploreVersion = 'v1',
		logisticRegressionVersion = 'v1',
		plotNumericVersion = 'v1',
		crosstabVersion = 'v1',
		frequenciesVersion = 'v1',
		comparePairedVersion = 'v1',
		kaplanVersion = 'v1'
	} = useFlags();

	const analyses = [
		...(frequenciesVersion === 'v1' ? [AnalysisType.Frequencies] : []),
		...(frequenciesVersion === 'v2' ? [AnalysisType.FrequenciesV2] : []),
		...(frequenciesVersion === 'all'
			? [AnalysisType.Frequencies, AnalysisType.FrequenciesV2]
			: []),
		...(exploreVersion === 'v1' ? [AnalysisType.Explore] : []),
		...(exploreVersion === 'v2' ? [AnalysisType.ExploreV2] : []),
		...(exploreVersion === 'all' ? [AnalysisType.Explore, AnalysisType.ExploreV2] : []),
		...(crosstabVersion === 'v1' ? [AnalysisType.Crosstab] : []),
		...(crosstabVersion === 'v2' ? [AnalysisType.CrosstabV2] : []),
		...(crosstabVersion === 'all' ? [AnalysisType.Crosstab, AnalysisType.CrosstabV2] : []),
		...(kaplanVersion === 'v1' ? [AnalysisType.Kaplan] : []),
		...(kaplanVersion === 'v2' ? [AnalysisType.KaplanV2] : []),
		...(kaplanVersion === 'all' ? [AnalysisType.Kaplan, AnalysisType.KaplanV2] : []),
		...(analysisVersion === 'v1' ? [AnalysisType.CorrelationsV1] : []),
		...(analysisVersion === 'v2' ? [AnalysisType.CorrelationsV2] : []),
		...(analysisVersion === 'all'
			? [AnalysisType.CorrelationsV1, AnalysisType.CorrelationsV2]
			: []),
		...(compareVersion === 'v1' ? [AnalysisType.CompareNumericV1] : []),
		...(compareVersion === 'v2' ? [AnalysisType.CompareNumericV2] : []),
		...(compareVersion === 'all'
			? [AnalysisType.CompareNumericV1, AnalysisType.CompareNumericV2]
			: []),
		...(densityVersion === 'v1' ? [AnalysisType.DensityPlot] : []),
		...(densityVersion === 'v2' ? [AnalysisType.DensityPlotV2] : []),
		...(densityVersion === 'all' ? [AnalysisType.DensityPlot, AnalysisType.DensityPlotV2] : []),
		...(plotNumericVersion === 'v1' ? [AnalysisType.PlotNumeric] : []),
		...(plotNumericVersion === 'v2' ? [AnalysisType.PlotNumericV2] : []),
		...(plotNumericVersion === 'all'
			? [AnalysisType.PlotNumeric, AnalysisType.PlotNumericV2]
			: []),
		...(timeCourseVersion === 'v1' ? [AnalysisType.TimeCourseV1] : []),
		...(timeCourseVersion === 'v2' ? [AnalysisType.TimeCourseV2] : []),
		...(timeCourseVersion === 'all'
			? [AnalysisType.TimeCourseV1, AnalysisType.TimeCourseV2]
			: []),
		...(comparePairedVersion === 'v1' ? [AnalysisType.ComparePaired] : []),
		...(comparePairedVersion === 'v2' ? [AnalysisType.ComparePairedV2] : []),
		...(comparePairedVersion === 'all'
			? [AnalysisType.ComparePaired, AnalysisType.ComparePairedV2]
			: []),
		...(logisticRegressionVersion === 'v1' ? [AnalysisType.LogisticRegression] : []),
		...(logisticRegressionVersion === 'v2' ? [AnalysisType.LogisticRegressionV2] : []),
		...(logisticRegressionVersion === 'all'
			? [AnalysisType.LogisticRegression, AnalysisType.LogisticRegressionV2]
			: []),
		AnalysisType.NumberPlotXY
	];

	const variablesData = useVariablesData({ initial: true });

	const dispatch = useDispatch();
	const createAnalysis = useCreateAnalysis();

	const IS_JADBIO_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_JADBIO!);

	const [{ data: isJADBioUserAddonActive }] = useIsUserAddonActive(SubscriptionAddonCode.JADBio);
	const [{ data: isJADBioProjectAddonActive }] = useIsProjectAddonActive(
		SubscriptionAddonCode.JADBio
	);

	const showJADBioOption = useMemo(() => {
		return IS_JADBIO_ENABLED && isJADBioUserAddonActive && isJADBioProjectAddonActive;
	}, [isJADBioProjectAddonActive, isJADBioUserAddonActive]);

	function handleSelect(type: AnalysisType) {
		const analysis = buildAnalysis({ type, variablesData });

		createAnalysis(analysis);

		if (analysis.type !== AnalysisType.PlotNumeric) {
			const handler = handlersMap[analysis.type as AnalysesType];
			if (!handler) return;
			dispatch(handler(analysis as Analyses));
		}
	}

	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);
	const hasCategoryOrNumberOrDurationVariable =
		variablesDataSelectItems.categorySelectItems.length > 0 ||
		variablesDataSelectItems.numericSelectItems.length > 0 ||
		variablesDataSelectItems.durationSelectItems.length > 0;

	function getSelectItemLabel(type: string) {
		if (densityVersion === 'all') {
			if (AnalysisType.DensityPlot === type) return translate(() => type) + ' V1';

			if (AnalysisType.DensityPlotV2 === type) return translate(() => type) + ' V2';
		}

		if (analysisVersion === 'all') {
			if (AnalysisType.CorrelationsV1 === type) return translate(() => type) + ' V1';

			if (AnalysisType.CorrelationsV2 === type) return translate(() => type) + ' V2';
		}

		if (compareVersion === 'all') {
			if (AnalysisType.CompareNumericV1 === type) return translate(() => type) + ' V1';

			if (AnalysisType.CompareNumericV2 === type) return translate(() => type) + ' V2';
		}
		if (timeCourseVersion === 'all') {
			if (AnalysisType.TimeCourseV1 === type) return translate(() => type) + ' V1';

			if (AnalysisType.TimeCourseV2 === type) return translate(() => type) + ' V2';
		}

		if (plotNumericVersion === 'all') {
			if (AnalysisType.PlotNumeric === type) return translate(() => type) + ' V1';

			if (AnalysisType.PlotNumericV2 === type) return translate(() => type) + ' V2';
		}

		if (logisticRegressionVersion === 'all') {
			if (AnalysisType.LogisticRegression === type) return translate(() => type) + ' V1';

			if (AnalysisType.LogisticRegressionV2 === type) return translate(() => type) + ' V2';
		}

		if (exploreVersion === 'all') {
			if (type === AnalysisType.Explore) return translate(() => type) + ' V1';
			if (type === AnalysisType.ExploreV2) return translate(() => type) + ' V2';
		}

		if (crosstabVersion === 'all') {
			if (type === AnalysisType.Crosstab) return translate(() => type) + ' V1';
			if (type === AnalysisType.CrosstabV2) return translate(() => type) + ' V2';
		}

		if (frequenciesVersion === 'all') {
			if (type === AnalysisType.Frequencies) return translate(() => type) + ' V1';
			if (type === AnalysisType.FrequenciesV2) return translate(() => type) + ' V2';
		}

		if (comparePairedVersion === 'all') {
			if (type === AnalysisType.ComparePaired) return translate(() => type) + ' V1';
			if (type === AnalysisType.ComparePairedV2) return translate(() => type) + ' V2';
		}

		if (kaplanVersion === 'all') {
			if (type === AnalysisType.Kaplan) return translate(() => type) + ' V1';
			if (type === AnalysisType.KaplanV2) return translate(() => type) + ' V2';
		}

		return translate(() => type);
	}

	const selectItems = [...analyses, ...(showJADBioOption ? [AnalysisType.JADBio] : [])].map(
		type => ({
			label: getSelectItemLabel(type),
			value: type
		})
	);

	return (
		<Dropdown
			toggleComponent={({ ref, toggle }) => {
				return (
					<div ref={ref}>
						{addButton({
							label: translate(dict => dict.analysis.actions.add),
							disabled: !hasCategoryOrNumberOrDurationVariable,
							action: toggle
						})}
					</div>
				);
			}}
			width={25}
			offset={{ top: 4, right: 0 }}
		>
			{selectItems.map((item, i) => (
				<Dropdown.Item
					close={() => undefined}
					onClick={() => {
						handleSelect(item.value as AnalysisType);
					}}
					title={item.label}
					key={`analisys_item_${i}`}
				/>
			))}
		</Dropdown>
	);
}
