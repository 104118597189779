import { Flex } from 'components/UI/Flex';
import { Link } from 'components/UI/Link';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useTranslation } from 'hooks/store';

export function ProjectDPASigned() {
	const { translate } = useTranslation();

	return (
		<>
			<Spacer size={s => s.m} />

			<Flex justify={j => j.center}>
				<Svgs.EmptyStatesNoDocuments style={{ minHeight: 240 }} />
			</Flex>

			<Spacer size={s => s.m} />

			<Typography.H3 multiline alignCenter>
				{translate(dict => dict.projectsPage.projectDPASigned.succesfully)}
			</Typography.H3>

			<Spacer size={s => s.s} />

			<Typography.Paragraph alignCenter>
				{translate(dict => dict.projectsPage.projectDPASigned.beforeVariable)}
				<Link href="mailto:support@ledidi.no">{`support@ledidi.no`}</Link>
				{translate(dict => dict.projectsPage.projectDPASigned.afterVariable)}
			</Typography.Paragraph>
		</>
	);
}
