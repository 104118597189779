import { useMemo } from 'react';
import { LinearRegressionResultsDataV1, LinearRegressionResultsV1 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { CHART_COLORS } from 'consts';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { YStatistic } from 'components/Analysis/Analyses/AnalysisStatistics';
import { Circle } from 'components/Analysis/Analyses/CorrelationsAnalysis/CorrelationsView/CorrelationsStatistics/styles';

interface Props {
	results: LinearRegressionResultsV1;
}
export function CorrelationsV1LinearRegression({ results }: Props) {
	const { translate } = useTranslation();
	const { data, error, errorMessage } = results;
	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.linearRegression.name)}
			</AnalysisStatistic.Title>
			{error && (
				<AnalysisStatistic.Error>
					{errorMessage ??
						translate(({ errors }) => errors.api.analyses.statistics.linearRegression)}
				</AnalysisStatistic.Error>
			)}
			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}
function Result({ data }: { data: LinearRegressionResultsDataV1 }) {
	const statistics = useMemo(() => {
		return data.map(statistic => {
			const { rValue = '', pValue = '', slope, intercept, error } = statistic;
			const rStatistic = getRStatistic({ rValue });
			const pStatistic = getPStatistic({ pValue });
			return {
				rStatistic,
				pStatistic,
				slope,
				intercept,
				...(error ? { error } : {})
			};
		});
	}, [data]);
	const isGrouped = statistics.length > 1;
	return (
		<Table.Responsive fullWidth={false} horizontalScroll>
			<Table>
				<Table.Body>
					{statistics.map(
						({ rStatistic, pStatistic, slope, intercept, error }, index) => (
							<Table.Row key={`linear-regression-statistic-${index}`}>
								{isGrouped && (
									<Table.Cell width={1.6}>
										<Circle color={CHART_COLORS[index]} />
									</Table.Cell>
								)}
								{error ? (
									<>
										<Table.Cell title={error} colSpan={2} width={28} noWrap>
											{error}
										</Table.Cell>
									</>
								) : slope && intercept ? (
									<Table.Cell width={28} noWrap>
										<YStatistic value={[slope, intercept]} />
									</Table.Cell>
								) : (
									<>
										<Table.Cell title={rStatistic.text} width={14} noWrap>
											{rStatistic.JSX}
										</Table.Cell>
										<Table.Cell title={pStatistic.text} width={14} noWrap>
											{pStatistic.JSX}
										</Table.Cell>
									</>
								)}
							</Table.Row>
						)
					)}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}
function getRStatistic(input: { rValue: string }) {
	const { rValue } = input;
	const { value: parsedRValue } = parseAnalysisNumber(rValue, {
		decimals: 2
	});
	const output = {
		JSX: (
			<>
				<i>{`r`}</i>
				{` = ${parsedRValue}`}
			</>
		),
		text: `r = ${parsedRValue}`
	};
	return output;
}
function getPStatistic(input: { pValue: string }) {
	const { pValue } = input;
	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});
	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};
	return output;
}
