export const PAREN_ERROR_PREFIX = 'prn_err_';

export const WHITESPACE_HELPER_ID = 'whitespacePress_help' as const;

export const ENTER_PRESS_HELPER_ID = 'enterPress_help' as const;

export const ADD_OPEN_PARENTHESIS_HELPER_ID = 'addOpenParen_help' as const;

export enum EditorActionsEnum {
	PressSpace = 'pressSpace',
	PressEnter = 'pressEnter',
	MissingOpenParenthesis = 'missingOpenParenthesis'
}

export enum ExtensionType {
	KeywordTokenizer = 'Keyword-Tokenizer',
	ParenthesisTokenizer = 'Parenthesis-Tokenizer',
	OperatorTokenizer = 'Operator-Tokenizer',
	NumberTokenizer = 'Number-Tokenizer',
	CategoryTokenizer = 'Category-Tokenizer',
	VariableTokenizer = 'Variable-Tokenizer',
	SeparatorTokenizer = 'Separator-Tokenizer',
	PlaceholderTokenizer = 'Placeholder-Extension',
	GeneralTokenizer = 'General-Tokenizer',
	AutoSuggestionExtension = 'AutoSuggestion-Extension',
	IndentationExtension = 'Indentation-Extension',
	FocusExtension = 'Focus-Extension',
	HelperManagerExtension = 'Helper-Manager-Extension'
}

export enum PresetType {
	ArithmeticOperation = 'Arithmetic-Operation',
	LogicalOperation = 'Logical-Operation',
	DateOperation = 'Date-Operation',
	QueryOperation = 'Query-Operation'
}

export enum TokenType {
	Keyword = 'Keyword',
	Operator = 'Operator',
	Identifier = 'Identifier',
	Parenthesis = 'Parenthesis',
	Number = 'Number',
	Delimiter = 'Delimiter',
	Illegal = 'Illegal',
	Separator = 'Separator',
	Placeholder = 'Placeholder',
	Unknown = 'Unknown'
}

export enum SubType {
	// Keywords
	If = 'if',
	And = 'and',
	Or = 'or',
	ThenValueIs = 'then-value-is',
	Max = 'max',
	Min = 'min',
	Mean = 'mean',
	Sum = 'sum',

	// Arithmetic Operators
	Plus = 'plus-operator',
	Minus = 'minus-operator',
	Divide = 'divide-operator',
	Multiply = 'multiply-operator',
	Assign = 'assign-operator',

	// Logical Operators
	GreaterThan = 'greater-than-operator',
	LessThan = 'less-than-operator',
	GreaterThanOrEqual = 'greater-than-equal-or-operator',
	LessThanOrEqual = 'less-than-equal-or-operator',
	Equals = 'equals-operator',
	Consequence = 'consequence-operator',

	// Parenthesis
	OpenParenthesis = 'open-parenthesis',
	CloseParenthesis = 'close_parenthesis',

	// Identifiers
	Variable = 'variable',
	Category = 'category',

	// Numbers
	Integer = 'integer',
	Float = 'float',

	// Separators
	Comma = 'comma',

	None = 'none'
}

export enum OperatorTypes {
	// Arithmetic Operators
	Plus = 'plus-operator',
	Minus = 'minus-operator',
	Divide = 'divide-operator',
	Multiply = 'multiply-operator',
	Assign = 'assign-operator',

	// Logical Operators
	GreaterThan = 'greater-than-operator',
	LessThan = 'less-than-operator',
	GreaterThanOrEqual = 'greater-than-equal-or-operator',
	LessThanOrEqual = 'less-than-equal-or-operator',
	Consequence = 'consequence-operator',
	Equals = 'equals-operator'
}

export enum KeywordTypes {
	If = 'if',
	And = 'and',
	Or = 'or',
	Then_Value_Is = 'then-value-is',
	Max = 'max',
	Min = 'min',
	Mean = 'mean',
	Sum = 'sum'
}

export enum ArithmeticOperationRuleBuilderStep {
	FirstOperand = 'arithmetic-first-operand',
	Operator = 'arithmetic-operator',
	SecondOperand = 'arithmetic-second-operand',
	ArithmeticDone = 'arithmetic-done'
}

/**
 *  Logical Operation Rule Builder Enums that describe the steps for building a rule
 */
export enum LogicalOperationRuleBuilderStep {
	/**
	 * 1. First step is to add the root keyword
	 * @example
	 * 'if'
	 * or
	 * 'IF'
	 * ( <root-keyword> )
	 */
	RootKeyword = 'root-keyword',
	/**
	 * 2. Second step is to add the root open parenthesis after the root keyword
	 * @example
	 * 'if('
	 * or
	 * 'IF('
	 * or
	 * 'if ('
	 * or
	 * 'IF ('
	 * ( <root-keyword> <root-open-parenthesis> )
	 */
	RootOParenthesis = 'root-open-parenthesis',
	/**
	 * 3. Third step is to add the root first field after the root open parenthesis
	 * @example
	 * 'if(field1'
	 * or
	 * 'IF(field1'
	 * or
	 * 'if ( field1'
	 * or
	 * 'IF ( field1'
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> )
	 * @remarks
	 * The root first field can be a variable or a number or a arithmetic operation determined by opening a parenthesis
	 */
	RootFirstField = 'root-first-field',
	/**
	 * 4. Fourth step is to add the root operator after the root first field
	 * @example
	 * 'if(field1 >'
	 * or
	 * 'IF(field1 >'
	 * or
	 * 'if ( field1 >'
	 * or
	 * 'IF ( field1 >'
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> )
	 * @remarks
	 * The root operator can only be a logical operator
	 * @see SubType for the list of logical operators types available
	 */
	RootOperator = 'root-operator',
	/**
	 * 3. Fifth step is to add the root second field after the root operator
	 * @example
	 * 'if(field1 > field2'
	 * or
	 * 'IF(field1 > field2'
	 * or
	 * 'if ( field1 > field2'
	 * or
	 * 'IF ( field1 > field2'
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> <root-second-field> )
	 * @remarks
	 * The root second field can be a variable or a number or a arithmetic operation determined by opening a parenthesis
	 */
	RootSecondField = 'root-second-field',
	/**
	 * 6. This is an optional step after closing the root parenthesis to chain multiple IF statements
	 * @example
	 * 'if(field1 > field2) and'
	 * or
	 * 'IF(field1 > field2) AND' and so on...
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> <root-second-field> <root-close-parenthesis> <root-and-operator> )
	 */
	RootAndOperator = 'root-and-operator',
	/**
	 * 7. This is step acts as an equal sign to the root keyword
	 * @example
	 * 'if(field1 > field2 then value is'
	 * or
	 * 'IF(field1 > field2 THEN value is' and so on...
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> <root-second-field>  <root-consequence-operator> )
	 * @remarks
	 * A root IF keyword or chained IF statements must have only one <root-consequence-operator>
	 */
	RootConsequenceOperator = 'root-consequence-operator',
	/**
	 * 8. This is before the last step to add the root consequence value
	 * @example
	 * 'if(field1 > field2 then value is 10'
	 * or
	 * 'IF(field1 > field2 ) THEN value is 10' and so on...
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> <root-second-field>   <root-close-parenthesis>  <root-consequence-operator> <root-consequence-value> )
	 * @remarks
	 * A root IF keyword or chained IF statements must have only one <root-consequence-value>
	 * For variables of type number the <root-consequence-value> can be a number or a arithmetic operation determined by opening a parenthesis
	 */
	RootConsequenceValue = 'root-consequence-value',
	/**
	 * 9. This is the last step to add the root close parenthesis. This step can also be added after the <root-second-field> if we want to chain multiple IF statements
	 * @example
	 * 'if(field1 > field2) then value is 10'
	 * or
	 * 'IF(field1 > field2 ) THEN value is 10' and so on...
	 * ( <root-keyword> <root-open-parenthesis> <root-first-field> <root-operator> <root-second-field>  <root-close-parenthesis>  <root-consequence-operator> <root-consequence-value> )
	 * or
	 * 'if( (field1 > field2) and (field3 > field4) ) then value is 10'
	 * or
	 * 'IF( (field1 > field2) AND (field3 > field4) ) THEN VALUE IS 10' and so on...
	 */
	RootCParenthesis = 'root-close-parenthesis',

	SubExpressionOParenthesis = 'subExpression-open-parenthesis',

	SubExpressionFirstOperand = 'subExpression-first-operand',

	SubExpressionArithmeticOperator = 'subExpression-arithmetic-operator',

	SubExpressionComparisonOperator = 'subExpression-Comparison-operator',

	/**
	 * This step is a generic step that is used to add an operator to a sub expression
	 */
	SubExpressionOperator = 'subExpression-operator',

	SubExpressionSecondOperand = 'subExpression-second-operand',

	SubExpressionDone = 'subExpression-done',

	/**
	 * 10. This step suggests that the rule is done and ready to be built
	 */
	RootDone = 'root-done'
}

export enum EditorChangeType {
	CharacterInsertion = 'character-insertion',
	CharacterDeletion = 'character-deletion',
	CharacterReplacement = 'character-replacement',
	NodeInsertion = 'node-insertion',
	NodeDeletion = 'node-deletion',
	SelectionDeletion = 'selection-deletion',
	MergedChange = 'merged-change',
	AutoSuggestionInsertion = 'auto-suggestion-insertion',
	RemoveAllContent = 'remove-all-content',
	ReplaceInitialContent = 'replace-initial-content',
	Indentation = 'indentation',
	IndentationWithText = 'indentation-with-text',
	MarkRemoved = 'mark-removed'
}

export const KEYWORDS = ['and', 'if', 'then value is'];

export const OPERATORS = ['<=', '>=', '==', '<', '>', '+', '-', '*', '/'];
