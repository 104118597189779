import React, { useMemo } from 'react';

import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { buildVariableSetVariablesData } from 'helpers/variables';
import { useVariablesDataSelectItems } from 'hooks/store';

interface Props {
	variablesData: VariablesData;
	setName: string;
	omitVariables?: string[];
	render: (variablesDataSelectItems: VariablesDataSelectItems) => React.ReactNode;
}

export function ComparePairedTestVariableConfig({
	variablesData,
	setName,
	omitVariables = [],
	render
}: Props) {
	const variableSetVariablesData = useMemo(
		() =>
			buildVariableSetVariablesData({
				setName,
				variablesData
			}),
		[setName, variablesData]
	);

	/*
	 * TODO:
	 * this component can be removed when the business logic of `useVariablesDataSelectItems`
	 * is turned into a function
	 */
	const variablesDataSelectItems = useVariablesDataSelectItems(variableSetVariablesData, {
		omitVariables
	});

	return <>{render(variablesDataSelectItems)}</>;
}
