import { SignDPAInput } from 'api/data/projects';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, signDPA } from 'store/data/projects';

export function useSignProjectDPA(): Operation<(input: SignDPAInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.SIGN_DPA);

	function handler(input: SignDPAInput) {
		dispatch(signDPA(input));
	}

	return [{ loading, error }, handler];
}
