import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	LogisticRegressionAnalysis,
	Columns,
	LogisticRegressionVariables
} from 'api/data/analyses';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Typography } from 'components/UI/Typography';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters
} from 'hooks/store';
import { useDebounce, useMutableState } from 'hooks/utils';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { buildVariableCategoriesMap } from 'helpers/variables';
import { VariablesData } from 'store/data/variables';
import { Switch } from 'components/UI/Interactables/Switch';

import { ConfigContainer } from '../UI';
import { SelectItem } from 'types/index';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';

interface Props {
	analysis: LogisticRegressionAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	loading: boolean;
}

export function LogisticRegressionConfig({
	analysis,
	variablesDataSelectItems,
	loading,
	variablesData
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const { variablesMap } = variablesData;

	const [{ areFiltersOpen }] = useFilters();
	const {
		input: analysisInput,
		options: { chartLegend: legend }
	} = analysis;

	const [activeColumn] = useAnalysesActiveColum();
	const [chartLegend, setChartLegend] = useState(legend);
	const [
		{ isConfigPanelOpen, isParamsOpen, isFormattingOpen },
		{ openFormatting, openParameters }
	] = useAnalysisConfigPanel(analysis.id);
	const [fullscreen] = useFullscreenAnalysis();

	const [draftAnalysisInput, setDraftAnalysisInput] = useMutableState(analysisInput);

	const { variables: inputVariables } = draftAnalysisInput;

	const {
		selectItemsMap,
		categorySelectItems,
		categoryWithoutAggregationsSelectItems,
		numericSelectItems
	} = variablesDataSelectItems;

	useDebounce(
		() => {
			if (
				haveVariablesChanged(analysisInput.variables, inputVariables) ||
				chartLegend !== legend
			) {
				const updatedAnalysis: LogisticRegressionAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							...inputVariables
						}
					},
					options: { ...analysis.options, chartLegend }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[draftAnalysisInput, chartLegend],
		ANALYSIS_DEBOUNCE_TIME
	);

	// SYNC `draftAnalysisInput` STATE
	useEffect(() => {
		if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	}, [analysisInput]);

	const categoriesWithLabelAndValue = useMemo(() => {
		const categories = variablesMap[inputVariables.dependentVariable].categories ?? [];
		const computedCategories = categories.map(c => c.value);

		const categoriesMap = buildVariableCategoriesMap(categories);

		const values = computedCategories.map(categoryValue => ({
			label: categoriesMap[categoryValue]?.label || categoryValue,
			value: categoriesMap[categoryValue]?.value || categoryValue,
			...(categoriesMap[categoryValue]?.description.length > 0 && {
				tooltip: categoriesMap[categoryValue].description
			})
		}));

		if (values.length) {
			setDraftAnalysisInput(state => {
				state.variables.positiveEvent = [values[0].label];
			});
		}

		return values;
	}, [inputVariables.dependentVariable]);

	useEffect(() => {
		if (legend !== undefined) {
			if (!isEqual(legend, chartLegend)) {
				setChartLegend(legend);
			}
		}
	}, [legend]);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as LogisticRegressionAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({
						analysisId: analysis.id,
						parameters: !isParamsOpen
					})
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegression.config.dependentVariable
						)}
						items={categoryWithoutAggregationsSelectItems.filter(
							item => (item as SelectItem).value !== inputVariables.groupVariable
						)}
						value={selectItemsMap[inputVariables.dependentVariable]}
						onValueSelected={dependentVariable =>
							dependentVariable &&
							setDraftAnalysisInput(state => {
								state.variables.dependentVariable = dependentVariable;
							})
						}
						canClear={false}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegression.config.positiveEvent
						)}
						placeholder={translate(({ radioGroups }) => radioGroups.noSelection)}
						values={inputVariables.positiveEvent.map(val => ({
							label: val,
							value: val
						}))}
						items={categoriesWithLabelAndValue}
						onValuesSelected={newValues =>
							newValues &&
							setDraftAnalysisInput(state => {
								state.variables.positiveEvent = newValues;
							})
						}
						onClear={() => {
							setDraftAnalysisInput(state => {
								state.variables.positiveEvent = [];
							});
						}}
						hasMultipleValues
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegression.config.independentVariable
						)}
						items={numericSelectItems}
						value={selectItemsMap[inputVariables.independentVariable]}
						onValueSelected={independentVariable =>
							independentVariable &&
							setDraftAnalysisInput(state => {
								state.variables.independentVariable = independentVariable;
							})
						}
						canClear={false}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegression.config.groupVariable
						)}
						items={categorySelectItems.filter(
							item => (item as SelectItem).value !== inputVariables.dependentVariable
						)}
						value={selectItemsMap[inputVariables.groupVariable ?? ''] ?? null}
						onValueSelected={groupVariable =>
							groupVariable &&
							setDraftAnalysisInput(state => {
								state.variables.groupVariable = groupVariable;
							})
						}
						onClear={() =>
							setDraftAnalysisInput(state => {
								state.variables.groupVariable = undefined;
							})
						}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Formatting
				)}
				open={!isFormattingOpen}
				onToggle={() =>
					openFormatting({ analysisId: analysis.id, formatting: !isFormattingOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Typography.Caption fontweight={weight => weight.bold}>
						{translate(
							({ analysis }) => analysis.analyses.groupedOptions.title.chartOptions
						)}
					</Typography.Caption>
					<Switch
						label={translate(({ analysis }) => analysis.generic.chartLegend)}
						on={chartLegend}
						disabled={!inputVariables.groupVariable}
						onChange={() => setChartLegend(state => !state)}
					/>
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}

const haveVariablesChanged = (
	own: LogisticRegressionVariables,
	values: LogisticRegressionVariables
) =>
	own.dependentVariable !== values.dependentVariable ||
	own.independentVariable !== values.independentVariable ||
	own.groupVariable !== values.groupVariable ||
	!isEqual(own.positiveEvent, values.positiveEvent);
