import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	> :nth-child(n) {
		width: calc(50% - 1.1rem);
		min-width: 40%;
	}
`;
