import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { nanoid as generate } from 'nanoid';

import { PlotNumericScatterValue } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { ScatterOptions } from '../PlotNumeric';
import { ZingChartClickEvent } from 'zingchart-react';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import {
	useTranslation,
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';
import { computePlotNumericScatterChartData as computeData } from 'helpers/analysis/';

interface Props extends ScatterOptions {
	id?: string;
	isForExport?: boolean;
	data: PlotNumericScatterValue[];
	shuffledData: number[][];
	scalesLabels: ScalesLabels;
	loading: boolean;
	isConfigPanelOpen: boolean;
}

export function PlotNumericScatterChart({
	id,
	isForExport,
	data,
	shuffledData,
	align,
	line,
	scalesLabels,
	loading,
	isConfigPanelOpen
}: Props) {
	const { translate } = useTranslation();
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const [fullscreen] = useFullscreenAnalysis();
	const windowSize = useWindowSize();
	const [activeColumns] = useAnalysesActiveColum();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const { series, options, disableAnimations } = computeData(
		data,
		shuffledData,
		align,
		line,
		scalesLabels,
		activeColumns,
		!!fullscreen,
		plots
	);

	const renderId = useMemo(generate, [
		fullscreen,
		activeColumns,
		windowSize,
		analysisIds,
		isConfigPanelOpen,
		filters
	]);

	if (isEmpty(data) && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	function onTogglePlot(e: ZingChartClickEvent) {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}

	return (
		<Chart
			onLegendItemClick={onTogglePlot}
			key={renderId}
			id={id}
			type={t => t.Mixed}
			series={series}
			options={options}
			disableAnimations={disableAnimations}
			effect={e => e.SlideBottom}
			method={m => m.RegularEaseOut}
			speed={s => s.Faster}
			isForExport={isForExport}
			height={'100%'}
			noLegendOnExport
		/>
	);
}
