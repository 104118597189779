import { useMemo } from 'react';
import { isEqual } from 'lodash';
import produce from 'immer';

import {
	OneWayManovaResultsV1,
	OneWayManovaResultsDataV1,
	CompareNumericAnalysisV1
} from 'api/data/analyses';
import { UPDATE_DEBOUNCE_TIME } from 'consts';
import { Svgs } from 'environment';
import { VariablesMap, VariablesData } from 'store/data/variables';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUpdateAnalysis } from 'hooks/store';
import { useMutableState, useDebounce } from 'hooks/utils';

type StatisticName = 'pillaisTrace' | 'hotellingTrace' | 'roysRoot' | 'wilksLamda';

interface Props {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
	results: OneWayManovaResultsV1;
}

export function CompareNumericOneWayManovaV1({ analysis, variablesData, results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title marginOffset={{ bottom: 1.6 }}>
				{translate(({ analysis }) => analysis.statistics.oneWayManova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translate(({ errors }) => errors.api.analyses.statistics.oneWayManova)}
				</AnalysisStatistic.Error>
			)}

			{data && (
				<Result
					analysis={analysis}
					variablesData={variablesData}
					data={data}
					error={error}
				/>
			)}
		</AnalysisStatistic>
	);
}

function Result({
	analysis,
	variablesData,
	data,
	error
}: {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
	data: OneWayManovaResultsDataV1;
	error?: boolean;
}) {
	const updateAnalysis = useUpdateAnalysis();

	const [draftExpanded, setDraftExpanded] = useMutableState(data.expanded);

	useDebounce(
		() => {
			const hasChanges = !isEqual(draftExpanded, data.expanded);

			if (!hasChanges) return;

			const updatedAnalysis = produce(analysis, draft => {
				if (draft.output.statistics.twoWayManova.data) {
					draft.output.statistics.twoWayManova.data.expanded = draftExpanded;
				}
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[draftExpanded],
		UPDATE_DEBOUNCE_TIME
	);

	const { variablesMap } = variablesData;

	const statistics = useMemo(() => {
		if (error) {
			return {
				byName: {
					pillaisTrace: null,
					hotellingTrace: null,
					roysRoot: null,
					wilksLamda: null
				},
				names: [] as StatisticName[]
			};
		}

		const { pillaisTrace, hotellingTrace, roysRoot, wilksLamda } = getStatistics(data, {
			variablesMap
		});

		return {
			byName: {
				pillaisTrace,
				hotellingTrace,
				roysRoot,
				wilksLamda
			},
			names: ['pillaisTrace', 'hotellingTrace', 'roysRoot', 'wilksLamda'] as StatisticName[]
		};
	}, [data, variablesMap, error]);

	const statisticLabelsMap = {
		pillaisTrace: `Pillai's trace`,
		hotellingTrace: `Hotelling's trace`,
		roysRoot: `Roy's largest root`,
		wilksLamda: `Wilks' Lambda`
	};

	return (
		<Gap marginGap={{ bottom: 1.6 }} notLastChild>
			{statistics.names.map(name => {
				const isVisible = draftExpanded[name];
				const label = statisticLabelsMap[name];
				const statistic = statistics.byName[name];

				return (
					<Flex key={name} column>
						{/* EXPANDABLE TOGGLE */}
						<Flex marginOffset={{ bottom: isVisible ? 0.8 : undefined }}>
							<Icon
								svg={isVisible ? Svgs.ArrowDown : Svgs.ArrowRight}
								size={s => s.l}
								marginOffset={{ right: 0.8 }}
								onClick={() =>
									setDraftExpanded(state => {
										state[name] = !state[name];
									})
								}
							/>
							<Typography.Paragraph
								fontweight={w => w.bold}
								onClick={() =>
									setDraftExpanded(state => {
										state[name] = !state[name];
									})
								}
								clickable
							>
								{label}
							</Typography.Paragraph>
						</Flex>

						{isVisible && (
							<Table.Responsive horizontalScroll fullWidth={false}>
								<Table>
									<Table.Body>
										{statistic && (
											<Table.Row key={`${name}_${statistic.label}`}>
												<Table.Cell
													title={statistic.label}
													width={28}
													noWrap
												>
													{statistic.label}
												</Table.Cell>
												<Table.Cell
													title={getFStatistic(statistic.fStatistic).text}
													width={14}
													noWrap
												>
													{getFStatistic(statistic.fStatistic).JSX}
												</Table.Cell>
												<Table.Cell
													title={getPStatistic(statistic.pStatistic).text}
													width={14}
													noWrap
												>
													{getPStatistic(statistic.pStatistic).JSX}
												</Table.Cell>
												<Table.Cell
													title={getLambdaStatistic(
														statistic.lambdaStatistic
													)}
													width={14}
													noWrap
												>
													{getLambdaStatistic(statistic.lambdaStatistic)}
												</Table.Cell>
											</Table.Row>
										)}
									</Table.Body>
								</Table>
							</Table.Responsive>
						)}
					</Flex>
				);
			})}
		</Gap>
	);
}

function getStatistics(
	data: OneWayManovaResultsDataV1,
	{ variablesMap }: { variablesMap: VariablesMap }
) {
	const keys = Object.keys(data.dynamic);

	const firstKey = keys[0];

	const firstStatisticLabel = variablesMap[firstKey].label;
	const firstStatisticValues = data.dynamic[firstKey];

	// TODO: FIND A CLEANER WAY TO BUILD THIS
	const statistics = {
		pillaisTrace: {
			label: firstStatisticLabel,
			fStatistic: {
				hypothesisDf: firstStatisticValues.pillaisTrace.hypothesisDf,
				erroDF: firstStatisticValues.pillaisTrace.erroDF,
				f: firstStatisticValues.pillaisTrace.f
			},
			pStatistic: {
				pValue: firstStatisticValues.pillaisTrace.pValue
			},
			lambdaStatistic: {
				value: firstStatisticValues.pillaisTrace.value
			}
		},
		hotellingTrace: {
			label: firstStatisticLabel,
			fStatistic: {
				hypothesisDf: firstStatisticValues.hotellingTrace.hypothesisDf,
				erroDF: firstStatisticValues.hotellingTrace.erroDF,
				f: firstStatisticValues.hotellingTrace.f
			},
			pStatistic: {
				pValue: firstStatisticValues.hotellingTrace.pValue
			},
			lambdaStatistic: {
				value: firstStatisticValues.hotellingTrace.value
			}
		},
		roysRoot: {
			label: firstStatisticLabel,
			fStatistic: {
				hypothesisDf: firstStatisticValues.roysRoot.hypothesisDf,
				erroDF: firstStatisticValues.roysRoot.erroDF,
				f: firstStatisticValues.roysRoot.f
			},
			pStatistic: {
				pValue: firstStatisticValues.roysRoot.pValue
			},
			lambdaStatistic: {
				value: firstStatisticValues.roysRoot.value
			}
		},
		wilksLamda: {
			label: firstStatisticLabel,
			fStatistic: {
				hypothesisDf: firstStatisticValues.wilksLamda.hypothesisDf,
				erroDF: firstStatisticValues.wilksLamda.erroDF,
				f: firstStatisticValues.wilksLamda.f
			},
			pStatistic: {
				pValue: firstStatisticValues.wilksLamda.pValue
			},
			lambdaStatistic: {
				value: firstStatisticValues.wilksLamda.value
			}
		}
	};

	return statistics;
}

function getFStatistic(input: { hypothesisDf: number; erroDF: number; f: number }) {
	const { hypothesisDf, erroDF, f } = input;

	const { value: parsedF } = parseAnalysisNumber(f);

	const output = {
		JSX: (
			<>
				<i>{`F`}</i>
				{`(${hypothesisDf}, ${erroDF}) = ${parsedF}`}
			</>
		),
		text: `F(${hypothesisDf}, ${erroDF}) = ${parsedF}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}

function getLambdaStatistic(input: { value: number }) {
	const { value } = input;

	const { operator, value: parsedValue } = parseAnalysisNumber(value);

	return `λ ${operator} ${parsedValue}`;
}
