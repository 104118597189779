import type {
	ProjectCount,
	ImportDataTextInterface,
	SuggestedVariableTypes,
	CollaboratorAvatarData
} from 'api/data/projects';
import type {
	ExtendedPreviewVariable,
	PreviewVariable,
	VariableToMatch
} from 'types/data/projects/import/types';
import type { Collaborator, CollaboratorPermissions, Organization } from 'store/data/collaborators';
import type { Action } from 'types/store/types';
import {
	type Nullable,
	type GenericMap,
	PromDistributionTypes,
	ImportType,
	SubscriptionAddonCode,
	type StringMap,
	SelectItem
} from 'types/index';
import type { ProjectOwnership, ProjectStatus, ProjectType } from 'types/data/projects/constants';

export enum ActionTypes {
	GET_PROJECTS = 'data/projects/GET_PROJECTS',
	GET_PROMS = 'data/projects/GET_PROMS',
	GET_PROJECT = 'data/projects/GET_PROJECT',
	GET_COUNTS = 'data/projects/GET_COUNTS',
	GET_IMPORT_DATA_TEXT = 'data/projects/GET_IMPORT_DATA_TEXT',
	CREATE_PROJECT = 'data/projects/CREATE_PROJECT',
	UPDATE_PROJECT = 'data/projects/UPDATE_PROJECT',
	DELETE_PROJECT = 'data/projects/DELETE_PROJECT',
	COPY_PROJECT = 'data/projects/COPY_PROJECT',
	SET_COPY_PROJECT_ID = 'data/projects/SET_COPY_PROJECT_ID',
	SET_REFETCH_PROJECT = 'data/projects/REFETCH_PROJECT',
	SET_PROJECTS_SEARCH_TERM = 'data/projects/SET_PROJECTS_SEARCH_TERM',
	SET_ACTIVE_STATUS = 'data/projects/SET_ACTIVE_STATUS',
	SET_ACTIVE_OWNERSHIP = 'data/projects/SET_ACTIVE_OWNERSHIP',
	SET_PROJECT_SORT = 'data/projects/SET_PROJECT_SORT',
	UPLOAD_PROJECT_DATASET = 'data/projects/UPLOAD_PROJECT_DATASET',
	UPDATE_EXISTING_DATASET = 'data/projects/UPDATE_EXISTING_DATASET',
	UPLOAD_TO_EXISTING_DATASET = 'data/projects/UPLOAD_TO_EXISTING_DATASET',
	UPLOAD_URL = 'data/projects/UPLOAD_URL',
	UPLOAD_FILE = 'data/projects/UPLOAD_FILE',
	STORE_CSV_CONTENT = 'data/projects/STORE_CSV_CONTENT',
	RESET_CSV_DATA = 'data/projects/RESET_CSV_DATA',
	RESET_CSV = 'data/projects/RESET_CSV',
	SET_PROJECT_ID = 'data/projects/SET_PROJECT_ID',
	RESET_PROJECTS_FILTERS = 'data/projects/RESET_PROJECTS_FILTERS',
	LEAVE_PROJECT = 'data/projects/LEAVE_PROJECT',
	UPDATE_IMPORT_PERCENTAGE = 'data/projects/UPDATE_IMPORT_PERCENTAGE',
	UPDATE_PROJECT_OWNER_PERMISSIONS = 'data/projects/UPDATE_PROJECT_OWNER_PERMISSIONS',
	SUGGEST_VARIABLE_TYPES = 'data/projects/SUGGEST_VARIABLE_TYPES',
	GENERATE_VARIABLE_NAMES = 'data/projects/GENERATE_VARIABLE_NAMES',
	GET_DPA = 'data/projects/GET_DPA',
	SIGN_DPA = 'data/projects/SIGN_DPA',
	PREVIEW_DPA = 'data/projects/PREVIEW_DPA',
	CLEAR_PREVIEW_DPA_FILE = 'data/projects/CLEAR_PREVIEW_DPA_FILE',
	SET_FORMATTED_VARIABLES = 'data/projects/SET_FORMATTED_VARIABLES',
	SET_FORMATTED_VARIABLE = 'data/projects/SET_FORMATTED_VARIABLE',
	SET_PREVIOUS_MAPPING = 'data/projects/SET_PREVIOUS_MAPPING',
	SET_DATA_TO_ENTRIES = 'data/projects/SET_DATA_TO_ENTRIES',
	GET_PROJECTS_COLLABORATORS_AVATAR_DATA = 'data/projects/GET_PROJECTS_COLLABORATORS_AVATAR_DATA',
	SET_IMPORT_VARIABLE_SET = 'data/projects/SET_IMPORT_VARIABLE_SET',
	CONVERT_PROJECT = 'data/projects/CONVERT_PROJECT',
	SET_ASSIGNED_ORGANIZATION_ID = 'data/projects/SET_ASSIGNED_ORGANIZATION_ID',
	// VIEW OPTION
	SET_PROJECTS_VIEW_OPTION = 'data/projects/SET_PROJECTS_VIEW_OPTION',
	SET_PROJECTS_TABLE_VISIBLE_COLUMNS = 'data/projects/SET_PROJECTS_TABLE_VISIBLE_COLUMNS',
	SET_TABLE_FILTERS = 'data/projects/SET_TABLE_FILTERS',
	// TRANSFER PROJECT
	// currently only available for Enterprise users
	ENTERPRISE_TRANSFER_PROJECTS_OWNERSHIP = 'data/accountUM/ENTERPRISE_TRANSFER_PROJECTS_OWNERSHIP',
	// metadata definition
	GET_PROJECT_METADATA_DEFINITION = 'data/accountUM/GET_PROJECT_METADATA_DEFINITION'
}

export interface Project {
	projectId: string;
	projectName: string;
	status: ProjectStatus;
	description: string;
	lastModifiedDate: string;
	slideFolderURL?: string;
	givenProjectNumber?: string;
	createdByUser?: string;
	sharedWithUsers?: Collaborator[];
	organization?: Organization;
	userAccess?: CollaboratorPermissions;
	projectOwnerDetails?: ProjectOwnerDetails;
	projectEndDate?: string;
	projectType?: ProjectType;
	promType?: PromDistributionTypes;
	activeAddonCodes?: SubscriptionAddonCode[];
	metadata?: ProjectMetadata;
}

export type ProjectMetadata = GenericMap<string>;

export type ProjectTableRow = Project & {
	entries: number;
	collaborators: number;
	metadataArchiveNumber?: string;
	metadataProjectType?: string;
};

export interface ProjectOwnerDetails {
	userId: string;
	status: string;
	emailAddress: string;
	userFirstName?: string;
	userSirName?: string;
	phoneNumber?: string;
	organization?: string;
	position?: string;
	department?: string;
	city?: string;
	country?: string;
}

export type ProjectsById = GenericMap<Project>;

export interface CreateProject {
	projectName: string;
	givenProjectNumber: string;
	status: ProjectStatus;
	description: string;
	slideFolderURL: string;
	projectType: ProjectType;
	projectEndDate?: string;
	activeAddonCodes?: SubscriptionAddonCode[];
	metadata?: ProjectMetadata;
}

export type ProjectFormValues = GenericMap<string | number | SubscriptionAddonCode[]>;

export interface UpdateProject extends CreateProject {
	projectId: string;
}

export interface CopyProjectData {
	variables: boolean;
	groups: boolean;
	forms: boolean;
	dependencies: boolean;
	statuses: boolean;
	statusPermissions: boolean;
	series: boolean;
	data: boolean;
	projectRoles: boolean;
	newOwnerId?: string;
}
export interface CopyProject extends CopyProjectData {
	projectId: string;
	projectDefinition: boolean;
}

export interface CopyProjectElements {
	[key: string]: {
		checked: boolean;
		disabled: boolean;
		visible: boolean;
	};
}

export interface Collection {
	all: string[];
	byStatus: {
		[status in ProjectStatus]: string[];
	};
	byOwnership: {
		[ownership in ProjectOwnership]: string[];
	};
	fetched: boolean;
}

export interface ProjectData {
	projectId: string;
	projectName: string;
	status: ProjectStatus;
	entries: number;
	collaborators: number;
	givenProjectNumber?: string;
	projectEndDate?: string;
	userAccess?: CollaboratorPermissions;
	createdByUser?: string;
	// metadata
	metadataProjectType?: string;
	metadataArchiveNumber?: string;
}

export interface PreviousMappingType {
	importType: Nullable<ImportType>;
	formattedVariables: PreviewVariable[];
	columnToMatch: Nullable<ExtendedPreviewVariable>;
	variableToMatch: Nullable<VariableToMatch>;
	columnToMatchWithMainLevel: Nullable<ExtendedPreviewVariable>;
	variableToMatchOnMainLevel: Nullable<VariableToMatch>;
}

export interface Import {
	headerDelimiter: string;
	helpText: ImportDataTextInterface | null;
	csv: Nullable<string[]>;
	originalContent: Nullable<string>;
	isBinary: boolean;
	convertedCsvData: Nullable<CsvContent>;
	suggestedVariableTypes: SuggestedVariableTypes[] | null;
	initialSuggestions: SuggestedVariableTypes[] | null;
	dateFormats: string[];
	dateTimeFormats: string[];
	isExcelOrigin?: boolean;
	timeZones: SelectItem[];
	importPercentage: number;
	assignedOrganizationId: Nullable<string>;
	file: {
		fileId: string;
		uploaded: boolean;
	};

	//
	formattedVariables: PreviewVariable[];
	previousMapping: PreviousMappingType;
	dataToEntries: {
		oldFormattedVariables: PreviewVariable[];
		columnToMatch: ExtendedPreviewVariable | null;
		variableToMatch: VariableToMatch | null;
	};
	importVariableSet: {
		isImportVariableSet: boolean;
		importVariableSetName: string;
		columnToMatchWithMainLevel: ExtendedPreviewVariable | null;
		variableToMatchOnMainLevel: VariableToMatch | null;
	};
}

export interface ProjectFilters {
	term: string;
	status: ProjectStatus | null;
	ownership: ProjectOwnership;
	sort: ProjectSort;
}

export enum ProjectSort {
	byName = 'byName',
	byDate = 'byDate',
	byNumber = 'byNumber'
}

export enum ProjectOptions {
	prom = 'promOptions',
	project = 'projectOptions'
}

export enum EditProject {
	prom = 'editProm',
	project = 'editProject'
}

export enum ProjectTableView {
	projectName = 'projectName',
	collaborators = 'collaborators',
	givenProjectNumber = 'givenProjectNumber',
	status = 'status',
	entries = 'entries',
	endDate = 'projectEndDate',
	// metadata
	metadataProjectType = 'metadataProjectType',
	metadataArchiveNumber = 'metadataArchiveNumber'
}

export enum ProjectTableViewData {
	title = 'projects.tableView.title',
	collaborators = 'projects.tableView.collaborators',
	number = 'projects.tableView.number',
	status = 'projects.tableView.status',
	entries = 'projects.tableView.entries',
	endDate = 'projects.tableView.endDate',
	// metadata
	metadataProjectType = 'projects.tableView.metadataProjectType',
	metadataArchiveNumber = 'projects.tableView.metadataArchiveNumber'
}

export enum ProjectStatusData {
	all = 'projects.allProjects',
	ongoing = 'projects.ongoing',
	onHold = 'projects.onHold',
	ended = 'projects.ended'
}

export enum ProjectsViewOptions {
	GRID = 'grid',
	TABLE = 'table'
}

export interface ProjectsTableParams {
	pageSize?: number;
	pageIndex?: number;
}

export interface CsvContent {
	csvHeaderContent: string;
	s3File: string;
	numberOfDataRows: number;
}

export interface PreviewDPAFile {
	signedS3URL: string;
}

export type DPAFiles = DPAFile[];

export interface DPAFile {
	fileId: string;
	fileMetadata: {
		fileId: string;
		signedS3URL: string;
		// dont know yet
		metadata: any;
	};
}

export interface ProjectDPA {
	preview: PreviewDPAFile | null;
	files: DPAFiles;
	fetched: boolean;
}

interface Filter {
	id: string;
	value: {
		value: string | string[];
		operator: string;
	};
}

export interface Sort {
	id: string;
	desc?: boolean;
}

export interface TableFilters {
	filters: Filter[];
	pageIndex: number;
	pageSize: number;
	sortBy: Sort[];
}

//-------- Project Metadata--------//

export interface ProjectMetadataParameter {
	name: string;
	label: string;
	description: string;
	placeholder: string;
	type: ProjectMetadataType;
	categories: ProjectMetadataCategory[];
	mandatory: boolean;
}

export enum ProjectMetadataType {
	Dropdown = 'dropdown',
	String = 'string'
}

export interface ProjectMetadataCategory {
	id: string;
	name: string;
	description: string;
}

export type ProjectMetadataDefinition = ProjectMetadataParameter[];

export interface State {
	projectId: string | null;
	isValid: boolean;
	byId: ProjectsById;

	// Because it is possible for projects that the user is NOT an owner or collaborator on to be added to redux, we store the initial project ids
	// to use as a proxy for this
	initialProjectIds: string[];

	projects: Collection;
	proms: Collection;
	import: Import;
	filters: {
		projects: ProjectFilters;
		proms: ProjectFilters;
	};
	tableFilters: { projects: TableFilters; proms: TableFilters };
	DPA: ProjectDPA;
	avatars: ProjectsAvatars;
	entriesCount: ProjectsEntriesCount;
	metadata: {
		viewOption: ProjectsViewOptions;
		columnSettings: {
			visible: string[];
		};

		refetch: boolean;
	};
	copiedProjectId: string | null;
	projectMetadataDefinition: ProjectMetadataDefinition | null;
}

export interface ProjectsAvatars {
	byProjectId: GenericMap<{
		byUserId: GenericMap<CollaboratorAvatarData>;
	}>;
}

export interface ProjectsEntriesCount {
	byProjectId: GenericMap<{ count: number }>;
}

export type GetProjectsAction = Action<
	ActionTypes.GET_PROJECTS,
	{
		projects: Project[];
		username: string;
	}
>;

export type GetPromsAction = Action<
	ActionTypes.GET_PROMS,
	{
		projects: Project[];
		username: string;
	}
>;

export type GetProjectAction = Action<
	ActionTypes.GET_PROJECT,
	{
		project: Project;
		username: string;
		projectType: ProjectType;
	}
>;

export type GetCountsAction = Action<
	ActionTypes.GET_COUNTS,
	{
		counts: ProjectCount[];
	}
>;

export type CreateProjectAction = Action<
	ActionTypes.CREATE_PROJECT,
	{
		project: Project;
		user?: Collaborator;
	}
>;

export type UpdateProjectAction = Action<
	ActionTypes.UPDATE_PROJECT,
	{
		project: UpdateProject;
	}
>;

export type DeleteProjectAction = Action<
	ActionTypes.DELETE_PROJECT,
	{
		projectId: string;
	}
>;

export type CopyProjectAction = Action<
	ActionTypes.COPY_PROJECT,
	{
		projectId: string;
		projectDefinition: boolean;
		variables: boolean;
		groups: boolean;
		dependencies: boolean;
		statuses: boolean;
		statusPermissions: boolean;
		series: boolean;
		forms: boolean;
		data: boolean;
		newOwnerId?: string;
		projectRoles: boolean;
	}
>;

export type SetCopyProjectAction = Action<
	ActionTypes.SET_COPY_PROJECT_ID,
	{
		copiedProjectId: string | null;
	}
>;
export type SetRefetchProjectsAction = Action<ActionTypes.SET_REFETCH_PROJECT>;

export type SetProjectsSearchTermAction = Action<
	ActionTypes.SET_PROJECTS_SEARCH_TERM,
	{
		term: string;
		isProject: boolean;
	}
>;

export type SetActiveStatusAction = Action<
	ActionTypes.SET_ACTIVE_STATUS,
	{
		status: ProjectStatus | null;
		isProject: boolean;
	}
>;

export type SetActiveOwnershipAction = Action<
	ActionTypes.SET_ACTIVE_OWNERSHIP,
	{
		ownership: ProjectOwnership;
		isProject: boolean;
	}
>;

export type SetProjectSortAction = Action<
	ActionTypes.SET_PROJECT_SORT,
	{
		sort: ProjectSort;
		isProject: boolean;
	}
>;

export type UploadProjectDatasetAction = Action<
	ActionTypes.UPLOAD_PROJECT_DATASET,
	{
		projectId: string;
	}
>;

export type UpdateExistingDatasetAction = Action<ActionTypes.UPDATE_EXISTING_DATASET>;

export type UploadToExistingDatasetAction = Action<ActionTypes.UPLOAD_TO_EXISTING_DATASET>;

export type UploadUrlAction = Action<
	ActionTypes.UPLOAD_URL,
	{
		fileId: string;
		url: string;
		isBinaryFile: boolean;
	}
>;

export type UploadFileAction = Action<ActionTypes.UPLOAD_FILE>;

export type SuggestVariableTypesAction = Action<
	ActionTypes.SUGGEST_VARIABLE_TYPES,
	{
		suggestedVariableTypes: SuggestedVariableTypes[];
		initialSuggestions: SuggestedVariableTypes[];
		dateFormats: string[];
		dateTimeFormats: string[];
		isExcelOrigin?: boolean;
		timeZones: string[];
		formattedVariables: PreviewVariable[];
	}
>;

export type GenerateVariableNamesAction = Action<
	ActionTypes.GENERATE_VARIABLE_NAMES,
	{
		generated: StringMap;
	}
>;

export type StoreCsvContentAction = Action<
	ActionTypes.STORE_CSV_CONTENT,
	{
		headerDelimiter: string;
		content: Nullable<string[]>;
		originalContent: string;
	}
>;

export type ResetCsvDataAction = Action<ActionTypes.RESET_CSV_DATA>;

export type ResetCsvAction = Action<ActionTypes.RESET_CSV>;

export type SetProjectIdAction = Action<
	ActionTypes.SET_PROJECT_ID,
	{
		projectId: string | null;
	}
>;

export type GetImportDataTextAction = Action<
	ActionTypes.GET_IMPORT_DATA_TEXT,
	{
		importDataText: ImportDataTextInterface;
	}
>;

export type ResetProjectsFiltersAction = Action<
	ActionTypes.RESET_PROJECTS_FILTERS,
	{
		isProject: boolean;
	}
>;

export type LeaveProjectAction = Action<
	ActionTypes.LEAVE_PROJECT,
	{
		projectId: string;
		matchProms: boolean;
	}
>;

export type UpdateImportPercentageAction = Action<
	ActionTypes.UPDATE_IMPORT_PERCENTAGE,
	{
		importPercentage: number;
	}
>;

export type UpdateProjectOwnerPermissionsAction = Action<
	ActionTypes.UPDATE_PROJECT_OWNER_PERMISSIONS,
	{
		permissions: CollaboratorPermissions;
	}
>;

export type GetDPAAction = Action<
	ActionTypes.GET_DPA,
	{
		DPAFiles: DPAFiles;
	}
>;

export type PreviewDPAAction = Action<
	ActionTypes.PREVIEW_DPA,
	{
		previewDPAFile: PreviewDPAFile;
	}
>;

export type SignDPAAction = Action<ActionTypes.SIGN_DPA>;

export type ClearPreviewDPAFileAction = Action<ActionTypes.CLEAR_PREVIEW_DPA_FILE>;

export type SetFormattedVariablesAction = Action<
	ActionTypes.SET_FORMATTED_VARIABLES,
	{
		formattedVariables: PreviewVariable[];
	}
>;

export type SetFormattedVariableAction = Action<
	ActionTypes.SET_FORMATTED_VARIABLE,
	{
		formattedVariable: PreviewVariable;
	}
>;

export type SetPreviousMappingAction = Action<
	ActionTypes.SET_PREVIOUS_MAPPING,
	{
		importType: ImportType | null;
	}
>;

export type SetDataToEntriesAction = Action<
	ActionTypes.SET_DATA_TO_ENTRIES,
	{
		dataToEntries: {
			oldFormattedVariables?: PreviewVariable[];
			columnToMatch?: ExtendedPreviewVariable;
			variableToMatch?: VariableToMatch;
		};
	}
>;

export type GetProjectsCollaboratorsAvatarDataAction = Action<
	ActionTypes.GET_PROJECTS_COLLABORATORS_AVATAR_DATA,
	{
		data: ProjectsAvatars;
	}
>;

export type SetProjectsViewOptionAction = Action<
	ActionTypes.SET_PROJECTS_VIEW_OPTION,
	{
		viewOption: ProjectsViewOptions;
	}
>;

export type SetProjectsTableVisibleColumnsAction = Action<
	ActionTypes.SET_PROJECTS_TABLE_VISIBLE_COLUMNS,
	{
		columnNames: string[];
	}
>;

export type SetTableFiltersAction = Action<
	ActionTypes.SET_TABLE_FILTERS,
	{ options: TableFilters; matchProms: boolean }
>;

/*
	IMPORT OF SERIES
*/
export type SetImportVariableSetAction = Action<
	ActionTypes.SET_IMPORT_VARIABLE_SET,
	{
		importVariableSet: {
			isImportVariableSet?: boolean;
			importVariableSetName?: string;
			columnToMatchWithMainLevel?: ExtendedPreviewVariable | null;
			variableToMatchOnMainLevel?: VariableToMatch | null;
		};
	}
>;

export type SetAssignedOrganizationIdAction = Action<
	ActionTypes.SET_ASSIGNED_ORGANIZATION_ID,
	{
		organizationId: string | null;
	}
>;

export type GetProjectMetadataDefinitionAction = Action<
	ActionTypes.GET_PROJECT_METADATA_DEFINITION,
	{
		metadata: ProjectMetadataDefinition;
	}
>;

export type Actions =
	| GetProjectsAction
	| GetPromsAction
	| GetProjectAction
	| GetCountsAction
	| GetImportDataTextAction
	| CreateProjectAction
	| UpdateProjectAction
	| CopyProjectAction
	| SetCopyProjectAction
	| SetRefetchProjectsAction
	| DeleteProjectAction
	| UploadProjectDatasetAction
	| UpdateExistingDatasetAction
	| UploadToExistingDatasetAction
	| SetProjectsSearchTermAction
	| SetActiveStatusAction
	| SetActiveOwnershipAction
	| SetProjectSortAction
	| SetProjectIdAction
	| ResetCsvDataAction
	| ResetCsvAction
	| StoreCsvContentAction
	| ResetProjectsFiltersAction
	| LeaveProjectAction
	| UpdateImportPercentageAction
	| UpdateProjectOwnerPermissionsAction
	| SuggestVariableTypesAction
	| GenerateVariableNamesAction
	| GetDPAAction
	| PreviewDPAAction
	| SignDPAAction
	| ClearPreviewDPAFileAction
	| SetFormattedVariableAction
	| SetFormattedVariablesAction
	| SetPreviousMappingAction
	| SetDataToEntriesAction
	| GetProjectsCollaboratorsAvatarDataAction
	| SetProjectsViewOptionAction
	| SetProjectsTableVisibleColumnsAction
	| SetTableFiltersAction
	| SetImportVariableSetAction
	| SetAssignedOrganizationIdAction
	| UploadUrlAction
	| UploadFileAction
	| GetProjectMetadataDefinitionAction;
