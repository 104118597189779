import { useRef, useMemo } from 'react';
import { isEmpty } from 'lodash';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import { nanoid as generate } from 'nanoid';

import { DensityPlotResults } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { computeDensityPlotChartData as computeData } from 'helpers/analysis';
import {
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	data: DensityPlotResults;
	isLegendEnabled: boolean;
	isHistogramEnabled: boolean;
	scalesLabels: ScalesLabels;
	isForExport?: boolean;
	legendHeader?: string;
	isConfigPanelOpen: boolean;
}

export function DensityPlotChart({
	id,
	data,
	scalesLabels,
	isLegendEnabled,
	isHistogramEnabled,
	isForExport,
	legendHeader,
	isConfigPanelOpen
}: Props) {
	const ref = useRef<ZingChart>(null);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumns] = useAnalysesActiveColum();
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();

	const { series, options } = computeData({
		data,
		isLegendEnabled,
		isHistogramEnabled,
		legendHeader,
		plots,
		scalesLabels
	});

	const renderId = useMemo(generate, [
		fullscreen,
		activeColumns,
		windowSize,
		analysisIds,
		isConfigPanelOpen,
		filters
	]);

	// legend item click toggle both bar and similar index of line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `line-${e.plotindex}` });
		}
	}

	if (isEmpty(data)) return null;

	return (
		<Chart
			key={renderId}
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
