import { useState, useMemo } from 'react';
import { isEqual } from 'lodash';

import { VariableType } from 'types/data/variables/constants';
import {
	TimeCourseAnalysisV1,
	AnalysisErrorBarType,
	TimeWindowSizeType,
	Columns
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { SelectItem, GenericMap, Nullable } from 'types/index';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';

import {
	useAnalysesActiveColum,
	useAnalysisConfigPanel,
	useFilters,
	useFullscreenAnalysis,
	useTranslation,
	useUpdateAnalysis
} from 'hooks/store';
import { useDebounce, useMemoOnce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { buildAggregationRuleNameToAggregatorVariableMap } from 'helpers/variables';

const ERROR_BAR_VALUES = [
	AnalysisErrorBarType.Mean,
	AnalysisErrorBarType.MeanSD,
	AnalysisErrorBarType.MeanCI,
	AnalysisErrorBarType.MeanRange,
	AnalysisErrorBarType.Median,
	AnalysisErrorBarType.MedianCI,
	AnalysisErrorBarType.MedianRange,
	AnalysisErrorBarType.MedianIQR
];

const TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Years,
	TimeWindowSizeType.Months,
	TimeWindowSizeType.Weeks,
	TimeWindowSizeType.Days
];

const DATE_TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Hours,
	TimeWindowSizeType.Minutes,
	TimeWindowSizeType.Seconds
];

const TIME_WINDOW_VALUES_EXTENDED = [...TIME_WINDOW_VALUES, ...DATE_TIME_WINDOW_VALUES];

interface Props {
	analysis: TimeCourseAnalysisV1;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function TimeCourseConfigV1({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		input: { variables }
	} = analysis;

	const [values, setValues] = useState(variables);

	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [activeColumn] = useAnalysesActiveColum();

	const { variablesMap, variableSetsMap } = variablesData;
	const aggRuleToVariableMap = buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const isDateTimeVariable =
		(
			variablesMap[values.dateVariable] ??
			variablesMap[aggRuleToVariableMap[values.dateVariable ?? ''].aggregator.variableName]
		).type === VariableType.DateTime;

	const { selectItemsMap, categorySelectItems, numericSelectItems, dateSelectItems } =
		variablesDataSelectItems;

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: TimeCourseAnalysisV1 = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	const errorBars = useMemoOnce(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		ERROR_BAR_VALUES.forEach(errorBar => {
			const item = {
				label: translateErrorBars(errorBar),
				value: errorBar
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	});

	const timeWindowValues = useMemo(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		const COMPUTED_VALUES = isDateTimeVariable
			? TIME_WINDOW_VALUES_EXTENDED
			: TIME_WINDOW_VALUES;

		COMPUTED_VALUES.forEach(timeWindow => {
			const item = {
				label: translateTimeWindowValues(timeWindow),
				value: timeWindow
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	}, [isDateTimeVariable]);

	function translateTimeWindowValues(type: TimeWindowSizeType) {
		switch (type) {
			case TimeWindowSizeType.Years:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.years
				);
			case TimeWindowSizeType.Months:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.months
				);
			case TimeWindowSizeType.Weeks:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.weeks
				);
			case TimeWindowSizeType.Days:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.days
				);
			case TimeWindowSizeType.Hours:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.hours
				);
			case TimeWindowSizeType.Minutes:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.minutes
				);
			case TimeWindowSizeType.Seconds:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.seconds
				);

			default:
				return translate(
					({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.years
				);
		}
	}

	function translateErrorBars(type: AnalysisErrorBarType) {
		switch (type) {
			case AnalysisErrorBarType.Mean:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
			case AnalysisErrorBarType.MeanSD:
				return translate(({ analysis }) => analysis.generic.errorBars.meanSD);
			case AnalysisErrorBarType.MeanCI:
				return translate(({ analysis }) => analysis.generic.errorBars.meanCI);
			case AnalysisErrorBarType.MeanRange:
				return translate(({ analysis }) => analysis.generic.errorBars.meanRange);
			case AnalysisErrorBarType.Median:
				return translate(({ analysis }) => analysis.generic.errorBars.median);
			case AnalysisErrorBarType.MedianCI:
				return translate(({ analysis }) => analysis.generic.errorBars.medianCI);
			case AnalysisErrorBarType.MedianRange:
				return translate(({ analysis }) => analysis.generic.errorBars.medianRange);
			case AnalysisErrorBarType.MedianIQR:
				return translate(({ analysis }) => analysis.generic.errorBars.medianIQR);
			default:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
		}
	}

	function onSelectDateVariable(dateVariable: Nullable<string>) {
		if (!dateVariable) return;

		const variable =
			variablesMap[values.dateVariable] ??
			variablesMap[aggRuleToVariableMap[values.dateVariable ?? ''].aggregator.variableName];

		setValues(state => {
			const isDate = variable.type === VariableType.Date;
			const shouldResetTimeWindow =
				isDate && DATE_TIME_WINDOW_VALUES.includes(state.timeWindowSize);

			return {
				...state,
				dateVariable,
				...(shouldResetTimeWindow && {
					timeWindowSize: TIME_WINDOW_VALUES[0]
				})
			};
		});
	}

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as TimeCourseAnalysisV1} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourseV1.config.variable
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.numericVariable]}
						onValueSelected={numericVariable =>
							numericVariable && setValues(state => ({ ...state, numericVariable }))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourseV1.config.dateVariable
						)}
						items={dateSelectItems}
						value={selectItemsMap[values.dateVariable]}
						onValueSelected={onSelectDateVariable}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourseV1.config.timeWindow.label
						)}
						items={timeWindowValues.items}
						disabled={!values.timeWindowSize}
						value={
							timeWindowValues.itemsMap[values.timeWindowSize ?? ''] ??
							timeWindowValues.items[0]
						}
						onValueSelected={timeWindowSize =>
							timeWindowSize &&
							setValues(state => ({
								...state,
								timeWindowSize: timeWindowSize as TimeWindowSizeType
							}))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourseV1.config.plot
						)}
						items={errorBars.items}
						disabled={!values.errorBar}
						value={errorBars.itemsMap[values.errorBar ?? ''] ?? errorBars.items[0]}
						onValueSelected={errorBar =>
							errorBar &&
							setValues(state => ({
								...state,
								errorBar: errorBar as AnalysisErrorBarType
							}))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.grouping
						)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.noGrouping
						)}
						items={categorySelectItems}
						value={selectItemsMap[values.groupingVariable ?? ''] ?? null}
						onValueSelected={groupingVariable =>
							setValues(state => ({
								...state,
								groupingVariable: groupingVariable ?? undefined
							}))
						}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} isEnabled={analysis.output.grouping} />
		</ConfigContainer>
	);
}
