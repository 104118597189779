import { useMemo } from 'react';

import { TwoWayAnovaResultsV1, TwoWayAnovaResultsDataV1 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';

import { VariablesMap, VariablesData } from 'store/data/variables';
import { AnalysisStatistic } from 'components/Analysis/Analyses';

interface Props {
	variablesData: VariablesData;
	results: TwoWayAnovaResultsV1;
}

export function CompareNumericTwoWayAnovaV1({ variablesData, results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.twoWayAnova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translate(({ errors }) => errors.api.analyses.statistics.twoWayAnova)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result variablesData={variablesData} data={data} error={error} />}
		</AnalysisStatistic>
	);
}

function Result({
	variablesData,
	data,
	error
}: {
	variablesData: VariablesData;
	data: TwoWayAnovaResultsDataV1;
	error?: boolean;
}) {
	const { variablesMap } = variablesData;

	const statistics = useMemo(() => {
		if (error) return [];

		const { first, second, mixed } = getStatistics(data, { variablesMap });

		return [first, second, mixed];
	}, [variablesMap, data, error]);

	return (
		<Table.Responsive fullWidth={false} horizontalScroll>
			<Table>
				<Table.Body>
					{statistics.map(({ label, fStatistic, pStatistic }) => (
						<Table.Row key={label}>
							<Table.Cell title={label} width={28} noWrap>
								{label}
							</Table.Cell>
							<Table.Cell title={getFStatistic(fStatistic).text} width={14} noWrap>
								{getFStatistic(fStatistic).JSX}
							</Table.Cell>
							<Table.Cell title={getPStatistic(pStatistic).text} width={14} noWrap>
								{getPStatistic(pStatistic).JSX}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

function getStatistics(
	values: TwoWayAnovaResultsDataV1,
	{ variablesMap }: { variablesMap: VariablesMap }
) {
	const keys = extractKeys(values);

	const firstStatisticValues = values.dynamic[keys.first];
	const secondStatisticValues = values.dynamic[keys.second];
	const mixedStatisticValues = values.dynamic[keys.mixed];

	const firstStatisticLabel = variablesMap[keys.first].label;
	const secondStatisticLabel = variablesMap[keys.second].label;
	const mixedStatisticLabel = `${firstStatisticLabel} * ${secondStatisticLabel}`;

	const statistics = {
		first: {
			label: firstStatisticLabel,
			fStatistic: {
				df: firstStatisticValues.df,
				erroDF: values.error.df,
				f: firstStatisticValues.f
			},
			pStatistic: {
				pValue: firstStatisticValues.pValue
			}
		},
		second: {
			label: secondStatisticLabel,
			fStatistic: {
				df: secondStatisticValues.df,
				erroDF: values.error.df,
				f: secondStatisticValues.f
			},
			pStatistic: {
				pValue: secondStatisticValues.pValue
			}
		},
		mixed: {
			label: mixedStatisticLabel,
			fStatistic: {
				df: mixedStatisticValues.df,
				erroDF: values.error.df,
				f: mixedStatisticValues.f
			},
			pStatistic: {
				pValue: mixedStatisticValues.pValue
			}
		}
	};

	return statistics;
}

function extractKeys(values: TwoWayAnovaResultsDataV1) {
	const rawKeys = Object.keys(values.dynamic);

	const parsedKeys = {
		first: '',
		second: '',
		mixed: ''
	};

	rawKeys.forEach(rawKey => {
		const isMixed = rawKey.includes(':');

		if (isMixed) {
			const [firstKey, secondKey] = rawKey.split(':');

			if (firstKey && secondKey) {
				parsedKeys.first = firstKey;
				parsedKeys.second = secondKey;
				parsedKeys.mixed = rawKey;
			}
		}
	});

	return parsedKeys;
}

function getFStatistic(input: { df: number; erroDF: number; f: number }) {
	const { df, erroDF, f } = input;

	const { value: parsedF } = parseAnalysisNumber(f);

	const output = {
		JSX: (
			<>
				<i>{`F`}</i>
				{`(${df}, ${erroDF}) = ${parsedF}`}
			</>
		),
		text: `F(${df}, ${erroDF}) = ${parsedF}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}
