import { useState } from 'react';
import { Svgs } from 'environment';
import { stringAsBoolean } from 'helpers/generic';
import { CreateProject } from 'store/data/projects';
import { PromDistributionTypes } from 'types/index';
import { ProjectDPAForm } from 'components/Projects/CreateAndImport';
import { CreatePromForm, AfterCreateOptions, PromDistributionType } from 'components/Proms/Create';
import {
	Container,
	Wrapper,
	Cancel
} from 'pages/Projects/CreateAndImport/CreateAndImportPage.style';
import { Icon } from 'components/UI/Icons';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useUserAddons, useCreateProject, useSignProjectDPA } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction, useKeyPress } from 'hooks/utils';

enum WizardSteps {
	PromForm = 'promForm',
	DistributionType = 'distributionType',
	DPA = 'DPA', // Data Processing Agreement,
	FinalStep = 'finalStep'
}

export function CreatePromPage() {
	const { goBack } = useNavigation();
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const isDPAEnabled = stringAsBoolean(process.env.REACT_APP_USE_PROJECT_DPA!);

	const [step, setStep] = useState(WizardSteps.PromForm);
	const [distributionType, setDistributionType] = useState(PromDistributionTypes.Automatic);

	const [draftProm, setDraftProm] = useState<CreateProject | null>(null);

	const [
		{
			data: { userAddons, activeUserAddons }
		}
	] = useUserAddons();

	const [{ loading: creatingProject, error: errorCreatingProject }, createProject] =
		useCreateProject();
	const [{ loading: signingDPA }] = useSignProjectDPA();

	/**
	 * Keeps a single source of truth of the instance (`loading`, `error`)
	 */
	const createProjectController = {
		loading: creatingProject,
		error: errorCreatingProject,
		create: createProject
	};

	useCompletedAction(
		creatingProject,
		errorCreatingProject,
		// SUCCESS CALLBACK
		() => {
			goToFinalStep();
			setNotification({
				message: translate(dict => dict.createProm.promCreated)
			});
		},
		// ERROR CALLBACK
		goToPromForm
	);

	useKeyPress({ onEscapeKeyPress: goBack }, { noModalsOpened: true, listen: getCanClose() });

	function handleCreateProm() {
		if (draftProm) createProject(draftProm, distributionType);
	}

	function goToPromForm() {
		setStep(WizardSteps.PromForm);
	}

	function goToDistributionType() {
		setStep(WizardSteps.DistributionType);
	}

	function goToProjectDPAForm() {
		setStep(WizardSteps.DPA);
	}

	function goToFinalStep() {
		setStep(WizardSteps.FinalStep);
	}

	function getCanClose() {
		return !(creatingProject || signingDPA);
	}

	const closeIconVisible = getCanClose();

	return (
		<Wrapper>
			<Cancel>
				{closeIconVisible && (
					<Icon svg={Svgs.Close} variant={v => v.button} onClick={goBack} />
				)}
			</Cancel>
			<Container>
				{step === WizardSteps.PromForm && (
					<CreatePromForm
						draftProm={draftProm}
						setDraftProm={setDraftProm}
						onContinue={goToDistributionType}
						userAddons={userAddons ?? []}
						activeUserAddons={activeUserAddons ?? []}
					/>
				)}
				{step === WizardSteps.DistributionType && (
					<PromDistributionType
						distributionType={distributionType}
						setDistributionType={setDistributionType}
						onBack={goToPromForm}
						onContinue={isDPAEnabled ? goToProjectDPAForm : handleCreateProm}
					/>
				)}
				{step === WizardSteps.DPA && (
					<ProjectDPAForm
						draftProject={draftProm}
						createProjectController={createProjectController}
						distributionType={distributionType}
						onBack={goToDistributionType}
						onFinish={goToFinalStep}
					/>
				)}
				{step === WizardSteps.FinalStep && <AfterCreateOptions />}
			</Container>
		</Wrapper>
	);
}
