import { ComparePairedAnalysis } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { ComparePairedTTest } from './ComparePairedTTest';
import { ComparePairedWilcoxon } from './ComparePairedWilcoxon';

interface Props {
	analysis: ComparePairedAnalysis;
}

export function ComparePairedStatistics({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		pairedTTest: {
			visible: inputStatistics.pairedTTest,
			results: outputStatistics.pairedTTest
		},
		pairedWilcoxon: {
			visible: inputStatistics.pairedWilcoxon,
			results: outputStatistics.pairedWilcoxon
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.pairedTTest.visible && (
				<ComparePairedTTest results={statistics.pairedTTest.results} />
			)}

			{statistics.pairedWilcoxon.visible && (
				<ComparePairedWilcoxon results={statistics.pairedWilcoxon.results} />
			)}
		</Gap>
	);
}
