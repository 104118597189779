import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { CorrelationsV1Analysis } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames } from 'types';
import { ZingChartExportTypes } from 'types/charts';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer, AnalysisStatisticsWrapper } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { useAnalysesActiveColum, useFullscreenAnalysis, useProject } from 'hooks/store';

import { VariablesDataSelectItems } from 'store/data/analyses';
import { CorrelationsScatterChartV1 } from './CorrelationsScatterChartV1';
import { CorrelationsV1Statistics } from './CorrelationsV1Statistics/CorrelationsV1Statistics';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: CorrelationsV1Analysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function CorrelationsV1View({
	analysis,
	exporting,
	loading,
	variablesData,
	variablesDataSelectItems
}: Props) {
	const [{ data: project }] = useProject();

	const {
		id,
		input,
		options: { configPanel, chartLegend = false },
		output: { dataset, statistics }
	} = analysis;

	const { selectItemsLabelMap } = variablesDataSelectItems;
	const { variablesMap } = variablesData;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const correlationsScatterId = ZingChartExportTypes.Correlations + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				correlationsScatterId,
				getExportFileName(
					ExportFileNames.CorrelationsScatterChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[input.variables.xNumericVariable],
		labelY: selectItemsLabelMap[input.variables.yNumericVariable]
	};

	const legendHeader = input.variables.groupingVariable
		? selectItemsLabelMap[input.variables.groupingVariable]
		: undefined;

	const { parsedDataset } = useMemo(() => {
		let parsedDataset = cloneDeep(dataset);
		if (analysis.options.showCategoryLabels) {
			parsedDataset = parsedDataset.map(result => {
				result.group =
					(input.variables.groupingVariable &&
						mapVariableCategoryValueToLabel(
							result.group,
							variablesMap[input.variables.groupingVariable]
						)) ??
					result.group;
				return result;
			});
		}
		return { parsedDataset };
	}, [
		dataset,
		variablesMap,
		input.variables.groupingVariable,
		analysis.options.showCategoryLabels
	]);

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.CorrelationsV1}>
				<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
					<CorrelationsScatterChartV1
						linearRegression={statistics.linearRegression.data ?? []}
						data={parsedDataset}
						isLegendEnabled={chartLegend}
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
				</AnalysisChartContainer>

				<AnalysisExportWrapper>
					<CorrelationsScatterChartV1
						linearRegression={statistics.linearRegression.data ?? []}
						id={correlationsScatterId}
						data={parsedDataset}
						isForExport
						isLegendEnabled
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
				</AnalysisExportWrapper>
				<AnalysisStatisticsWrapper>
					<CorrelationsV1Statistics analysis={analysis} />
				</AnalysisStatisticsWrapper>
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
