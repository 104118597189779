import produce from 'immer';

import {
	Analysis,
	/////////////////
	FrequenciesAnalysis,
	ExploreAnalysis,
	CrosstabAnalysis,
	KaplanMeierAnalysis,
	CorrelationsAnalysis,
	PlotNumericAnalysis,
	DensityPlotAnalysis,
	TimeCourseAnalysisV1,
	TimeCourseAnalysisV2,
	ComparePairedAnalysis,
	LogisticRegressionAnalysis,
	NumberPlotXYAnalysis,
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	CorrelationsV1Analysis,
	CrosstabAnalysisV2,
	FrequenciesAnalysisV2,
	ExploreAnalysisV2,
	LogisticRegressionAnalysisV2,
	DensityPlotAnalysisV2,
	KaplanMeierAnalysisV2,
	ComparePairedAnalysisV2
} from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

/**
 * Reset analysis:
 * - input
 * 	- statistics (if exists)
 * - output
 * 	- dataset
 *  - statistics (if exists)
 *
 * @param analysis the analysis to reset
 */
export function resetAnalysisInputOutput(analysis: Analysis): Analysis {
	const parsedAnalysis = produce(analysis, analysisDraft => {
		// FREQUENCIES
		if (analysis.type === AnalysisType.Frequencies) {
			const { output } = analysisDraft as FrequenciesAnalysis;

			output.dataset = {
				results: {}
			};
		}

		if (analysis.type === AnalysisType.FrequenciesV2) {
			const { output } = analysisDraft as FrequenciesAnalysisV2;

			output.dataset = {
				data: null
			};
		}

		// EXPLORE
		if (analysis.type === AnalysisType.Explore) {
			const { output } = analysisDraft as ExploreAnalysis;

			output.dataset = [];
		}

		// EXPLORE
		if (analysis.type === AnalysisType.ExploreV2) {
			const { output } = analysisDraft as ExploreAnalysisV2;

			output.dataset = { data: [] };
		}

		// COMPARE NUMERIC
		if (analysis.type === AnalysisType.CompareNumericV1) {
			const { output, input } = analysisDraft as CompareNumericAnalysisV1;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				shapiroV1: false,
				mannWhitneyV1: false,
				independentV1: false,
				oneWayAnovaV1: false,
				twoWayManovaV1: false,
				tukeyV1: false,
				kruskalV1: false,
				twoWayAnovaV1: false,
				oneWayManovaV1: false
			};
			// RESET OUTPUT
			output.dataset = {};
			output.statistics = {
				shapiro: {
					data: null
				},
				mannWhitney: {
					data: null
				},
				independent: {
					data: null
				},
				oneWayAnova: {
					data: null
				},
				tukey: {
					data: null
				},
				kruskal: {
					data: null
				},
				twoWayManova: {
					data: null
				},
				twoWayAnova: {
					data: null
				},
				oneWayManova: {
					data: null
				}
			};
		}
		if (analysis.type === AnalysisType.CompareNumericV2) {
			const { output, input } = analysisDraft as CompareNumericAnalysisV2;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				shapiroV2: false,
				mannWhitneyV2: false,
				independentV2: false,
				oneWayAnovaV2: false,
				twoWayManovaV2: false,
				tukeyV2: false,
				kruskalV2: false,
				twoWayAnovaV2: false,
				oneWayManovaV2: false
			};
			// RESET OUTPUT
			output.dataset = { data: null };
			output.statistics = {
				shapiro: {
					data: null
				},
				mannWhitney: {
					data: null
				},
				independent: {
					data: null
				},
				oneWayAnova: {
					data: null
				},
				tukey: {
					data: null
				},
				kruskal: {
					data: null
				},
				twoWayManova: {
					data: null
				},
				twoWayAnova: {
					data: null
				},
				oneWayManova: {
					data: null
				}
			};
		}

		// CROSSTAB
		if (analysis.type === AnalysisType.Crosstab) {
			const { input, output } = analysisDraft as CrosstabAnalysis;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				fisher: false,
				chiSquare: false,
				mcNemar: false
			};
			// RESET OUTPUT
			output.dataset = {
				rows: {
					label: '',
					name: '',
					rows: []
				},
				columns: {
					categoryLabels: [],
					categoryTotals: [],
					label: '',
					name: ''
				}
			};
			output.statistics = {
				fisher: {
					data: null
				},
				chiSquare: {
					data: null
				},
				mcNemar: {
					data: null
				}
			};
		}

		if (analysis.type === AnalysisType.CrosstabV2) {
			const { input, output } = analysisDraft as CrosstabAnalysisV2;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				fisherV2: false,
				chiSquareV2: false,
				mcNemarV2: false
			};
			// RESET OUTPUT
			output.dataset = {
				data: {
					columnLabels: [],
					rowLabels: [],
					columnVariable: {
						name: ''
					},
					rowVariable: {
						name: ''
					},
					counts: [],
					percentages: []
				}
			};
			output.statistics = {
				fisher: {
					data: null
				},
				chiSquare: {
					data: null
				},
				mcNemar: {
					data: null
				}
			};
		}

		// KAPLAN MEIER
		if (analysis.type === AnalysisType.Kaplan) {
			const { input, output } = analysisDraft as KaplanMeierAnalysis;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				patients: false,
				confidenceIntervals: false,
				logRank: false
			};
			// RESET OUTPUT
			output.dataset = {};
			output.statistics = {
				logRank: {
					data: null
				}
			};
		}

		if (analysis.type === AnalysisType.KaplanV2) {
			const { input, output } = analysisDraft as KaplanMeierAnalysisV2;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				patientsV2: false,
				confidenceIntervalsV2: false,
				logRankV2: false
			};
			// RESET OUTPUT
			output.dataset = { data: null };
			output.statistics = {
				logRank: {
					data: null
				}
			};
		}

		// CORRELATIONS (old)
		if (analysis.type === AnalysisType.CorrelationsV1) {
			const { input, output } = analysisDraft as CorrelationsV1Analysis;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				pearsonV1: false,
				spearmanV1: false,
				linearRegressionV1: false
			};
			// RESET OUTPUT
			output.dataset = [];
			output.statistics = {
				pearson: {
					data: null
				},
				spearman: {
					data: null
				},
				linearRegression: {
					data: null
				}
			};
		}
		// CORRELATIONS
		if (analysis.type === AnalysisType.CorrelationsV2) {
			const { input, output } = analysisDraft as CorrelationsAnalysis;

			input.variables = {
				groupVariables: [],
				xVariable: null,
				yVariable: null
			};
			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				pearsonV2: false,
				spearmanV2: false,
				linearRegressionV2: false
			};
			// RESET OUTPUT
			output.dataset = { data: [] };
			output.statistics = {
				pearsonV2: {
					data: null
				},
				spearmanV2: {
					data: null
				},
				linearRegressionV2: {
					data: null
				}
			};
		}

		// PLOT NUMERIC
		if (analysis.type === AnalysisType.PlotNumeric) {
			const { output } = analysisDraft as PlotNumericAnalysis;

			// RESET OUTPUT
			output.dataset = {
				columns: [],
				boxplot: [],
				scatter: []
			};
		}

		// DENSITY PLOT
		if (analysis.type === AnalysisType.DensityPlot) {
			const { output } = analysisDraft as DensityPlotAnalysis;

			output.dataset = [];
		}

		if (analysis.type === AnalysisType.DensityPlotV2) {
			const { output } = analysisDraft as DensityPlotAnalysisV2;

			output.dataset = { data: [] };
		}

		// TIME COURSE
		if (analysis.type === AnalysisType.TimeCourseV2) {
			const { output, input } = analysisDraft as TimeCourseAnalysisV2;

			input.variables = {
				...input.variables,
				numericVariable: null,
				timeVariable: null,
				groupVariables: []
			};
			output.dataset = { data: null };
		}

		// TIME COURSE
		if (analysis.type === AnalysisType.TimeCourseV1) {
			const { output } = analysisDraft as TimeCourseAnalysisV1;

			output.dataset = null;
		}

		// COMPARE PAIRED
		if (analysis.type === AnalysisType.ComparePaired) {
			const { input, output } = analysisDraft as ComparePairedAnalysis;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				pairedTTest: false,
				pairedWilcoxon: false
			};
			// RESET OUTPUT
			output.dataset = {
				data: null
			};
			output.statistics = {
				pairedTTest: {
					data: null
				},
				pairedWilcoxon: {
					data: null
				}
			};
		}

		if (analysis.type === AnalysisType.ComparePairedV2) {
			const { input, output } = analysisDraft as ComparePairedAnalysisV2;

			// RESET STATISTICS INPUT FLAGS
			input.statistics = {
				pairedTTestV2: false,
				pairedWilcoxonV2: false
			};
			// RESET OUTPUT
			output.dataset = {
				data: null
			};
			output.statistics = {
				pairedTTest: {
					data: null
				},
				pairedWilcoxon: {
					data: null
				}
			};
		}

		// LOGISTIC REGRESSION
		if (analysis.type === AnalysisType.LogisticRegression) {
			const { output } = analysisDraft as LogisticRegressionAnalysis;

			// RESET OUTPUT
			output.dataset = null;
		}

		if (analysis.type === AnalysisType.LogisticRegressionV2) {
			const { output } = analysisDraft as LogisticRegressionAnalysisV2;

			// RESET OUTPUT
			output.dataset = {
				data: null
			};
		}

		//PLOT XY
		if (analysis.type === AnalysisType.NumberPlotXY) {
			const { output } = analysisDraft as NumberPlotXYAnalysis;
			output.dataset.data = null;
		}
	});

	return parsedAnalysis;
}
