import produce from 'immer';

import type { OperationResult } from 'hooks/store/types';
import type {
	ExtendedPreviewVariable,
	PreviewVariable,
	VariableToMatch
} from 'types/data/projects/import/types';
import type { ColumnFile } from 'api/data/projects/types';
import type { NewVariableSet } from 'api/data/variables';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount
} from 'types/data/projects/types';
import type { DataType, Handlers, PreviewLabelChangeFunction } from './types';

import { ImportType } from 'types/index';
import {
	selectDataToEntries,
	selectFormattedVariables,
	setDataToEntriesAction,
	setFormattedVariablesAction,
	setPreviousMappingAction,
	selectPreviousMapping,
	setFormattedVariableAction,
	selectInitialFormattedVariables,
	selectisExcelOrigin,
	selectTimeZones
} from 'store/data/projects';
import { cloneDeep } from 'lodash';
import { VAR_LABEL_PATTERN } from 'consts';
import { getScopeVariables } from 'helpers/variables';
import { useDispatch, useSelector } from 'hooks/utils';
import { useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCreateVariableSet } from '../../variables/useCreateVariableSet';
import { useVariables } from '../../variables/useVariables';
import { useVariablesData } from '../../variables/useVariablesData';
import { useImportDataset } from '../useImportDataset';
import { useImportVariableSet } from '../useImportVariableSet';
import { useProjectId } from '../useProjectId';
import { useUploadUrl } from '../useUploadUrl';
import { VariableType } from 'types/data/variables/constants';
import { reverseMatchDateTimeFormat } from 'helpers/projects/imports/importDateAndDatetime';
import {
	defaultTimeZone,
	getTimeZoneOffset,
	userTimezome
} from 'helpers/projects/imports/importTimezone';

type ImportCallbacks = {
	handleApiImportErrors: (
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) => void;
};

interface Props {
	handleFinishImport?: () => void;
	projectOrganizationId?: string;
	callbacks?: ImportCallbacks;
}

export function useImport(
	{ handleFinishImport, projectOrganizationId, callbacks }: Props = {
		handleFinishImport: () => undefined,
		projectOrganizationId: undefined
	}
): OperationResult<DataType, Handlers> {
	const [id] = useProjectId();
	const projectId = parseInt(id || '', 10);

	const { setError } = useAlerts();
	const { translate } = useTranslation();
	const [, uploadProjectDataset] = useImportDataset();
	const [
		{
			data: { fileId }
		}
	] = useUploadUrl();

	const dispatch = useDispatch();

	const formattedVariables = useSelector(state => selectFormattedVariables(state.data.projects));

	const initialSuggestions = useSelector(state =>
		selectInitialFormattedVariables(state.data.projects)
	);
	const isExcelOrigin = useSelector(state => selectisExcelOrigin(state.data.projects));
	const timeZones = useSelector(state => selectTimeZones(state.data.projects));

	const { oldFormattedVariables, columnToMatch, variableToMatch } = useSelector(state =>
		selectDataToEntries(state.data.projects)
	);
	const previousMapping = useSelector(state => selectPreviousMapping(state.data.projects));

	const variablesData = useVariablesData({ initial: true });
	const [
		{
			data: { variables, variableSets, variableSetsMap }
		}
	] = useVariables({ initial: true, lazy: true });

	const [
		{
			isImportVariableSet,
			importVariableSetName,
			columnToMatchWithMainLevel,
			variableToMatchOnMainLevel
		},
		{ setImportVariableSetName }
	] = useImportVariableSet();

	// ----------------------------------- UPLOAD DATA TO ENTRIES ---------------------------------------------
	// ---------------------------------------- [x] [ ] [ ] ---------------------------------------------------

	async function uploadDataToEntries() {
		const draftFormattedVariables = cloneDeep(formattedVariables);

		if (columnToMatch) {
			if (columnToMatchWithMainLevel) {
				if (columnToMatch.index < columnToMatchWithMainLevel.index) {
					draftFormattedVariables.splice(columnToMatch.index, 0, {
						...columnToMatch,
						name: variableToMatch ? variableToMatch.name : ''
					});
					draftFormattedVariables.splice(
						columnToMatchWithMainLevel.index,
						0,
						columnToMatchWithMainLevel
					);
				} else {
					draftFormattedVariables.splice(
						columnToMatchWithMainLevel.index,
						0,
						columnToMatchWithMainLevel
					);
					draftFormattedVariables.splice(columnToMatch.index, 0, {
						...columnToMatch,
						name: variableToMatch ? variableToMatch.name : ''
					});
				}
			} else draftFormattedVariables.splice(columnToMatch.index, 0, columnToMatch);
		}
		if (projectId) {
			const elements: ColumnFile[] = [];
			draftFormattedVariables.forEach((element, index) =>
				elements.push({
					column: index,
					variable: {
						variableName:
							element.previewVariableLabel !== element.label
								? element.label
								: element.name,
						variableLabel: element.label,
						variableType: element.type,
						...(element.dateFormat && {
							dateFormat:
								element.dateFormat === 'Custom'
									? reverseMatchDateTimeFormat(element.customDateFormat)
									: element.dateFormat
						}),
						...(element.timeZone &&
							!/(%z)/i.test(element.dateFormat) && {
								timeZone: element.timeZone.value
							})
					}
				})
			);

			if (columnToMatch && variableToMatch && fileId) {
				uploadProjectDataset(
					{
						projectId,
						fileId,
						matchingVariable: { variableName: variableToMatch.name },
						matchingColumnIndex: columnToMatch.index,

						elements: elements,
						...(isImportVariableSet &&
							columnToMatchWithMainLevel &&
							variableToMatchOnMainLevel && {
								set: {
									setName: importVariableSetName
								},
								matchingSeriesVariable: {
									variableName: variableToMatchOnMainLevel.name
								},
								matchingSeriesColumnIndex: columnToMatchWithMainLevel.index
							})
					},
					ImportType.MoreDataToExistingEntries,
					callbacks && { onAPIError: callbacks.handleApiImportErrors }
				);

				if (handleFinishImport) handleFinishImport();
			} else {
				setError({
					message: translate(
						({ projects }) =>
							projects.createAndImport.errors.couldNotGenerateVariableLabels
					)
				});
			}
		}
	}

	// ---------------------------------UPLOAD ENTRIES TO DATASET -----------------------------------------
	// ------------------------------------- [ ] [x] [ ] --------------------------------------------------

	async function uploadEntriesToDataset() {
		const draftFormattedVariables = cloneDeep(formattedVariables);

		if (columnToMatchWithMainLevel && variableToMatchOnMainLevel) {
			draftFormattedVariables.splice(
				columnToMatchWithMainLevel.index,
				0,
				columnToMatchWithMainLevel
			);
		}

		if (projectId) {
			const newFormattedVariables = draftFormattedVariables.map(variable => ({
				...variable
			}));

			const elements: ColumnFile[] = [];
			newFormattedVariables.forEach((element, index) =>
				elements.push({
					column: index,
					variable: {
						variableLabel: element.previewVariableLabel || '',
						variableName: element.previewVariableName || '',
						variableType: element.type || '',
						...(element.dateFormat && {
							dateFormat:
								element.dateFormat === 'Custom'
									? reverseMatchDateTimeFormat(element.customDateFormat)
									: element.dateFormat
						}),
						...(element.timeZone &&
							!/(%z)/i.test(element.dateFormat) && {
								timeZone: element.timeZone.value
							})
					}
				})
			);

			if (fileId) {
				uploadProjectDataset(
					{
						projectId,
						fileId,
						elements,
						...(projectOrganizationId && {
							organizationId: Number(projectOrganizationId)
						}),
						...(isImportVariableSet &&
							columnToMatchWithMainLevel &&
							variableToMatchOnMainLevel && {
								set: {
									setName: importVariableSetName
								},
								matchingSeriesVariable: {
									variableName: variableToMatchOnMainLevel.name
								},
								matchingSeriesColumnIndex: columnToMatchWithMainLevel.index
							})
					},
					ImportType.MoreEntriesToDataset,
					callbacks && { onAPIError: callbacks.handleApiImportErrors }
				);

				if (handleFinishImport) handleFinishImport();
			} else {
				setError({
					message: translate(
						({ projects }) =>
							projects.createAndImport.errors.couldNotGenerateVariableLabels
					)
				});
			}
		}
	}

	// ----------------------------------- UPLOAD REPLACE ALL ---------------------------------------------
	// -------------------------------------- [ ] [ ] [x] -------------------------------------------------

	const [, createVariableSet] = useCreateVariableSet();

	async function uploadReplaceAll() {
		const formattedVariablesClone = cloneDeep(formattedVariables);

		let targetSetName = importVariableSetName;

		// Create new variable set if it doesn't exist
		if (isImportVariableSet && importVariableSetName) {
			const isNewVariableSet = !(importVariableSetName in variableSetsMap);

			if (isNewVariableSet) {
				const isSetLabelUnique = !variableSets.some(
					variableSet => variableSet.setLabel === importVariableSetName
				);

				if (isSetLabelUnique) {
					const variableSet: NewVariableSet = {
						setLabel: importVariableSetName
					};

					await createVariableSet({
						variableSet,
						callbacks: {
							successCallback: newVariableSet => {
								targetSetName = newVariableSet.setName;
								setImportVariableSetName(newVariableSet.setName);
							}
						}
					});
				}
			}
		}

		if (columnToMatchWithMainLevel) {
			formattedVariablesClone.splice(
				columnToMatchWithMainLevel.index,
				0,
				columnToMatchWithMainLevel
			);
		}

		if (projectId) {
			const elements: ColumnFile[] = [];
			formattedVariablesClone.forEach((element, index) => {
				elements.push({
					column: index,
					variable: {
						variableName: element.name,
						variableLabel: element.label,
						variableType: element.type,
						...(element.dateFormat && {
							dateFormat:
								element.dateFormat === 'Custom'
									? reverseMatchDateTimeFormat(element.customDateFormat)
									: element.dateFormat
						}),
						...(element.timeZone &&
							!/(%z)/i.test(element.dateFormat) && {
								timeZone: element.timeZone.value
							})
					}
				});
			});

			if (fileId) {
				uploadProjectDataset(
					{
						projectId,
						fileId,
						autoGenerateNames: true,
						elements,
						...(projectOrganizationId && {
							organizationId: Number(projectOrganizationId)
						}),
						...(isImportVariableSet &&
							columnToMatchWithMainLevel &&
							variableToMatchOnMainLevel && {
								set: {
									setName: targetSetName
								},
								matchingSeriesVariable: {
									variableName: variableToMatchOnMainLevel.name
								},
								matchingSeriesColumnIndex: columnToMatchWithMainLevel.index
							})
					},
					ImportType.ReplaceAll,
					callbacks && { onAPIError: callbacks.handleApiImportErrors }
				);
			}
			if (handleFinishImport) handleFinishImport();
		}
	}

	function setFormattedVariables(formattedVariables: PreviewVariable[]) {
		dispatch(setFormattedVariablesAction({ formattedVariables }));
	}

	function setFormattedVariable(formattedVariable: PreviewVariable) {
		dispatch(setFormattedVariableAction({ formattedVariable }));
	}

	function setColumnToMatch(columnToMatch: ExtendedPreviewVariable) {
		dispatch(setDataToEntriesAction({ dataToEntries: { columnToMatch } }));
	}

	function setVariableToMatch(variableToMatch: VariableToMatch) {
		dispatch(setDataToEntriesAction({ dataToEntries: { variableToMatch } }));
	}

	function setOldFormattedVariables(oldFormattedVariables: PreviewVariable[]) {
		dispatch(setDataToEntriesAction({ dataToEntries: { oldFormattedVariables } }));
	}

	function setPreviousMapping(importType: ImportType | null) {
		dispatch(setPreviousMappingAction({ importType }));
	}

	function onVariableLabelChange({
		varId,
		value,
		isPreviewVariable,
		isNew,
		importType,
		duplicationList,
		clearLabelErrors = false
	}: PreviewLabelChangeFunction) {
		const allOtherLabelsTrimValues = formattedVariables
			.filter(variable => variable.id !== varId)
			.map(variable => variable.label.trim());

		const allOldVariableLabels = variables.map(variable => variable.label.trim());

		const isNewVariableSet = !(importVariableSetName in variableSetsMap);
		const currentSetVariables = getScopeVariables({
			variables: variables,
			variablesData,
			destinationSetName: isNewVariableSet ? null : importVariableSetName
		});
		const variablesOutsideSet = isNewVariableSet
			? variables
			: variables.filter(
					variable =>
						!currentSetVariables.some(
							currentVariable => currentVariable.label === variable.label
						)
			  );

		const variablesLabelsOutsideSet = variablesOutsideSet.map(variable =>
			variable.label.trim()
		);
		const label = isPreviewVariable ? 'previewVariableLabel' : 'label';
		const labelError = isPreviewVariable ? 'previewVariableLabelError' : 'labelError';

		setFormattedVariables(
			produce(formattedVariables, draft => {
				draft.forEach(formattedVariable => {
					if (duplicationList && duplicationList.length === 2) {
						if (
							duplicationList.includes(formattedVariable.id) &&
							formattedVariable.id !== varId
						) {
							formattedVariable.labelError = '';
							formattedVariable.previewVariableLabelError = '';
						}
					}

					if (formattedVariable.id === varId) {
						if (isNew) {
							formattedVariable.isNew = true;
							formattedVariable.previewVariableLabel = formattedVariable.label;
							formattedVariable.previewVariableName = '';
						}

						formattedVariable[label] = value;
						if (clearLabelErrors) {
							formattedVariable.labelError = '';
							formattedVariable.previewVariableLabelError = '';
						} else {
							if (!value) {
								return (formattedVariable[labelError] = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.labelRequired
								));
							}
							if (!value.match(VAR_LABEL_PATTERN)) {
								return (formattedVariable[labelError] = translate(
									dict => dict.validation.formVariables.specialCharacters
								));
							}

							if (allOtherLabelsTrimValues.includes(value.trim())) {
								return (formattedVariable[labelError] = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.noDuplicates
								));
							}

							if (
								allOldVariableLabels.includes(value.trim()) &&
								importType !== ImportType.ReplaceAll
							) {
								return (formattedVariable[labelError] = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.noDuplicates
								));
							}

							if (
								variablesLabelsOutsideSet.includes(value.trim()) &&
								importType === ImportType.ReplaceAll &&
								isImportVariableSet
							) {
								return (formattedVariable[labelError] = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.noDuplicates
								));
							}

							return (formattedVariable[labelError] = '');
						}
					} else if (!formattedVariable[labelError]) {
						formattedVariable[labelError] = !formattedVariable.label
							? translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.labelRequired
							  )
							: '';
					}
				});
			})
		);
	}

	function getClientTimezone() {
		const timeZoneNames = Object.values(timeZones).map(timezone => timezone.value);
		const detectedClientTimezone = timeZoneNames.find(timezone => timezone === userTimezome);
		return detectedClientTimezone
			? {
					label: `${getTimeZoneOffset(detectedClientTimezone)}${detectedClientTimezone}`,
					value: detectedClientTimezone
			  }
			: defaultTimeZone;
	}

	const formattedVariablesWithTimeZone = formattedVariables.map(variable =>
		variable.type === VariableType.DateTime && !variable.timeZone
			? {
					...variable,
					timeZone: getClientTimezone()
			  }
			: variable
	);

	return [
		{
			data: {
				variableToMatch,
				columnToMatch,
				oldFormattedVariables,
				formattedVariables: formattedVariablesWithTimeZone,
				initialSuggestions,
				previousMapping,
				isExcelOrigin,
				timeZones
			},
			loading: false,
			error: false
		},
		{
			uploadReplaceAll,
			uploadDataToEntries,
			uploadEntriesToDataset,
			setColumnToMatch,
			setOldFormattedVariables,
			setVariableToMatch,
			setFormattedVariables,
			setFormattedVariable,
			setPreviousMapping,
			onVariableLabelChange
		}
	];
}
