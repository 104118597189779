import { CrosstabAnalysis } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { CrosstabChiSquare } from './CrosstabChiSquare';
import { CrosstabFisher } from './CrosstabFisher';
import { CrosstabMcNemar } from './CrosstabMcNemar';

interface Props {
	analysis: CrosstabAnalysis;
}

export function CrosstabStatistics({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		fisher: {
			visible: inputStatistics.fisher,
			results: outputStatistics.fisher
		},
		mcNemar: {
			visible: inputStatistics.mcNemar,
			results: outputStatistics.mcNemar
		},
		chiSquare: {
			visible: inputStatistics.chiSquare,
			results: outputStatistics.chiSquare
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.fisher.visible && <CrosstabFisher results={statistics.fisher.results} />}

			{statistics.mcNemar.visible && <CrosstabMcNemar results={statistics.mcNemar.results} />}

			{statistics.chiSquare.visible && (
				<CrosstabChiSquare results={statistics.chiSquare.results} />
			)}
		</Gap>
	);
}
