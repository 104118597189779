import produce from 'immer';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Columns, CrosstabAnalysis, CrosstabRow } from 'api/data/analyses';
import { EntryFilter } from 'api/data/filters';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { Svgs } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';

import { ConfigContainer } from '../UI';
import { DropdownToggle } from 'components/UI/Dropdown/DropdownToggle';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalysisActiveTab,
	useAnalysisConfigPanel,
	useActiveFiltersByVariableName,
	useFilters
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { SelectItem } from 'types/index';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

interface Props {
	analysis: CrosstabAnalysis;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CrosstabConfig({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const {
		id,
		input: { variables, statistics },
		output: {
			dataset: {
				rows: { rows: plotRows }
			}
		}
	} = analysis;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);
	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);

	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [{ areFiltersOpen }] = useFilters();

	const { variablesMap, variableSetsMap } = variablesData;

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { selectItemsMap, categorySelectItems } = variablesDataSelectItems;

	useEffect(() => {
		if (!isEqual(variables, values)) setValues(variables);
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) setStats(statistics);
	}, [statistics]);

	useDebounce(
		() => {
			if (isEqual(variables, values)) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input.variables = values;
				draft.input.statistics = {
					fisher: false,
					chiSquare: false,
					mcNemar: false
				};
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[values, analysis],
		ANALYSIS_DEBOUNCE_TIME
	);

	useDebounce(
		() => {
			if (isEqual(statistics, stats)) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input.statistics = stats;
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[stats, analysis],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			prevShouldRefetchAnalyses !== undefined &&
			prevShouldRefetchAnalyses !== shouldRefetchAnalyses
		) {
			setStats({ fisher: false, chiSquare: false, mcNemar: false });
		}
	}, [shouldRefetchAnalyses]);

	useEffect(() => {
		if (statistics.fisher && !isFishersEnabled(plotRows)) {
			setStats(state => ({ ...state, fisher: false }));
		}
	}, [plotRows]);

	const yVariableFilters = useActiveFiltersByVariableName(values.yVariable);
	const groupingVariableFilters = useActiveFiltersByVariableName(values.groupingVariable);

	const yVariableTwoCategories = useMemo(() => {
		const categs = getSelectedItemCategories(values.yVariable);

		if (categs && categs.length === 2) {
			if (!yVariableFilters.length) return true;

			return areTwoFiltersActive(yVariableFilters);
		}

		if (categs && categs.length > 2) {
			return areTwoFiltersActive(yVariableFilters);
		}

		return false;
	}, [values, variablesMap, yVariableFilters]);

	const groupingVariableTwoCategories = useMemo(() => {
		const categs = getSelectedItemCategories(values.groupingVariable);

		if (categs && categs.length === 2) {
			if (!groupingVariableFilters.length) return true;

			return areTwoFiltersActive(groupingVariableFilters);
		}

		if (categs && categs.length > 2) {
			return areTwoFiltersActive(groupingVariableFilters);
		}

		return false;
	}, [values, variablesMap, groupingVariableFilters]);

	const isMcNemarEnabled = yVariableTwoCategories && groupingVariableTwoCategories;

	function getSelectedItemCategories(name: string): string[] {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			if (aggregatorVariableName in variablesMap) {
				variable = variablesMap[aggregatorVariableName];
			}
		}

		if (variable) return variable.categories.map(c => c.label || c.value);

		return [];
	}

	function areTwoFiltersActive(filters: EntryFilter[]) {
		let valid = false;

		filters.forEach(filter => {
			if (filter.values && filter.values.length === 2) {
				valid = true;
			}
		});

		return valid;
	}

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as CrosstabAnalysis} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6, top: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Dropdown
						toggleComponent={({ ref, open, toggle }) => (
							<DropdownToggle
								ref={ref}
								open={open}
								titleComponent={
									activeTab ? (
										<Flex>
											{activeTab === 0 ? (
												<>
													<Icon
														svg={Svgs.ViewTable}
														key={0}
														active={activeTab === 0}
														marginOffset={{ right: 1 }}
														variant={v => v.buttonActive}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.table
														)}
													</Typography.Paragraph>
												</>
											) : activeTab === 1 ? (
												<>
													<Icon
														svg={Svgs.GroupedView}
														key={1}
														active={activeTab === 1}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.grouped
														)}
													</Typography.Paragraph>
												</>
											) : activeTab === 2 ? (
												<>
													<Icon
														svg={Svgs.StackedView}
														key={2}
														active={activeTab === 2}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.stacked
														)}
													</Typography.Paragraph>
												</>
											) : (
												<>
													<Icon
														svg={Svgs.SunburstView}
														key={3}
														active={activeTab === 3}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.sunburst
														)}
													</Typography.Paragraph>
												</>
											)}
										</Flex>
									) : (
										<Flex>
											<Icon
												svg={Svgs.ViewTable}
												key={0}
												active={activeTab === 0}
												marginOffset={{ right: 1 }}
											/>
											<Typography.Paragraph>
												{translate(
													({ analysis }) =>
														analysis.analyses.crosstab.view.table
												)}
											</Typography.Paragraph>
										</Flex>
									)
								}
								toggle={toggle}
							/>
						)}
						button
					>
						<Dropdown.Item
							key={0}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 0 })}
							active={activeTab === 0}
						>
							<Flex>
								<Icon
									svg={Svgs.ViewTable}
									key={0}
									active={activeTab === 0}
									marginOffset={{ right: 1 }}
								/>
								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.table
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 1}
							key={1}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 1 })}
						>
							<Flex>
								<Icon
									svg={Svgs.GroupedView}
									key={1}
									active={activeTab === 1}
									marginOffset={{ right: 1 }}
								/>
								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.grouped
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 2}
							key={2}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 2 })}
						>
							<Flex>
								<Icon
									svg={Svgs.StackedView}
									key={2}
									active={activeTab === 2}
									marginOffset={{ right: 1 }}
								/>

								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.stacked
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 3}
							key={3}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 3 })}
						>
							<Flex>
								<Icon
									svg={Svgs.SunburstView}
									key={3}
									active={activeTab === 3}
									marginOffset={{ right: 1 }}
								/>

								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.sunburst
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>
					</Dropdown>
				</Gap>
			</CollapsibleCard>

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(({ analysis }) => analysis.analyses.crosstab.config.rows)}
						items={categorySelectItems.filter(
							item => (item as SelectItem).value !== variables.groupingVariable
						)}
						value={selectItemsMap[values.yVariable]}
						onValueSelected={yVariable =>
							yVariable && setValues(values => ({ ...values, yVariable }))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.crosstab.config.columns
						)}
						items={categorySelectItems.filter(
							item => (item as SelectItem).value !== variables.yVariable
						)}
						value={selectItemsMap[values.groupingVariable]}
						onValueSelected={groupingVariable =>
							groupingVariable &&
							setValues(values => ({ ...values, groupingVariable }))
						}
						canClear={false}
					/>

					{/* STATISTICS */}
					<Switch
						label={translate(({ analysis }) => analysis.statistics.fisher.name)}
						on={stats.fisher}
						disabled={!isFishersEnabled(plotRows)}
						onChange={() => setStats(state => ({ ...state, fisher: !state.fisher }))}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.mcNemar.name)}
						on={stats.mcNemar}
						disabled={!isMcNemarEnabled}
						onChange={() => setStats(state => ({ ...state, mcNemar: !state.mcNemar }))}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.chiSquare.name)}
						on={stats.chiSquare}
						onChange={() =>
							setStats(state => ({ ...state, chiSquare: !state.chiSquare }))
						}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting
				analysis={analysis}
				isEnabled={!(activeTab === 0 || activeTab === 3)}
			/>
		</ConfigContainer>
	);
}

function isFishersEnabled(rows: CrosstabRow[]) {
	if (rows && rows.length === 2) {
		for (let i = 0; i < rows.length; i++) {
			if (rows[i].valueDetails.length !== 2) {
				return false;
			}
		}

		return true;
	}

	return false;
}
