import { Columns, FrequenciesAnalysis } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { ActionTypes, VariablesDataSelectItems } from 'store/data/analyses';

import { FrequenciesView } from './FrequenciesView';
import { MediaQueries } from 'environment';
import { FrequenciesConfig } from './FrequenciesConfig';

import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import { useFetchAnalysis, useFullscreenAnalysis, useAnalysesActiveColum } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

interface Props {
	analysis: FrequenciesAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const { loading } = useFetchAnalysis({
		id: analysis.id,
		actionType: ActionTypes.GET_FREQUENCIES,
		initialFetchDependency: 'output.dataset.results'
	});

	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const analysisValidInput = isAnalysisInputValid(analysis);
	const [activeColumn] = useAnalysesActiveColum();
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullscreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullscreen()} activeColumn={activeColumn}>
				{!loading ? (
					<FrequenciesView
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						variablesData={variablesData}
						loading={loading}
						exporting={exporting}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}

				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<FrequenciesConfig
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis category />
		</AnalysisContainer>
	);
}

export const Frequencies = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
