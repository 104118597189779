import { useEffect } from 'react';
import { LogisticRegressionAnalysis } from 'api/data/analyses';
import {
	AnalysisErrorWrapper,
	AnalysisViewContainer,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { useProject, useAnalysesActiveColum, useFullscreenAnalysis } from 'hooks/store';

import { LogisticRegressionChart } from './LogisticRegressionChart';
import { AnalysisChartContainer, AnalysisStatisticsWrapper } from '../../UI';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { LogisticRegressionTable } from './LogisticRegressionTable/LogisticRegressionTable';
import { VariablesData } from 'store/data/variables';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: LogisticRegressionAnalysis;
	exporting: boolean;
	loading: boolean;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
}

export function LogisticRegressionView({
	analysis,
	loading,
	exporting,
	variablesDataSelectItems,
	variablesData
}: Props) {
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const [{ data: project }] = useProject();
	const { selectItemsLabelMap } = variablesDataSelectItems;

	const {
		id,
		options: { configPanel, chartLegend = false },
		output: { dataset },
		input
	} = analysis;

	const logisticRegressionId = ZingChartExportTypes.LogisticRegression + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				logisticRegressionId,
				getExportFileName(
					ExportFileNames.LogisticRegressionChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const legendHeader = input.variables.groupVariable
		? selectItemsLabelMap[input.variables.groupVariable]
		: undefined;

	const scalesLabels = {
		labelX: selectItemsLabelMap[input.variables.independentVariable],
		labelY: selectItemsLabelMap[input.variables.dependentVariable]
	};

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper
				analysis={analysis}
				analysisType={AnalysisType.LogisticRegression}
			>
				{dataset && (
					<>
						<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
							<LogisticRegressionChart
								data={dataset}
								isLegendEnabled={chartLegend}
								legendHeader={legendHeader}
								scalesLabels={scalesLabels}
							/>
							<AnalysisStatisticsWrapper>
								<LogisticRegressionTable
									results={dataset}
									variablesData={variablesData}
								/>
							</AnalysisStatisticsWrapper>
						</AnalysisChartContainer>
						<AnalysisExportWrapper>
							<>
								<LogisticRegressionChart
									data={dataset}
									isLegendEnabled={chartLegend}
									legendHeader={legendHeader}
									scalesLabels={scalesLabels}
									isForExport
									id={logisticRegressionId}
								/>
							</>
						</AnalysisExportWrapper>
					</>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
