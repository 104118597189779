import type { ImportPageProps } from 'types/data/projects/import/types';

import { useMemo, useState } from 'react';
import { VariableType } from 'types/data/variables/constants';
import {
	NarrowContainer,
	Title,
	ColumnTitle,
	PreviousMappingButton,
	MatchColumnToMainVariableContainer
} from 'components/Projects/CreateAndImport';
import { StickyFooter } from 'components/UI/StickyFooter';

import { Colors, Svgs } from 'environment';
import { InputType, ImportType } from 'types/index';
import { PreviousMappingModal } from '../../PreviousMappingModal';
import {
	FlexCellIcon,
	FlexCellLabel,
	FlexCellNewData,
	FlexCellNewLabel,
	FlexCellType,
	FlexRow,
	List,
	TooltipContainer,
	ErrorContainer
} from './EntriesToDataset.style';
import { cloneDeep } from 'lodash';
import { ActionTypes } from 'store/data/projects';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Switch } from 'components/UI/Interactables/Switch';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { useActivities, useTranslation } from 'hooks/store';
import { AssignProjectOrganizationModal } from 'components/Dataset/Modals';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeZoneOptions } from '../TimeZoneOptions/TimeZoneOptions';
import { Spacer } from 'components/UI/Spacer';
import {
	handleCustomDateFormatChange,
	handleVariableDateTypeChange,
	handleVariableTimeZoneChange,
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import {
	applyTimezoneToAllDatetimeVariables,
	importRequiresGlobalTimezoneSettings,
	variableHasSuggestedDateFormat
} from 'helpers/projects/imports/importTimezone';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { useImportManager } from 'hooks/store/data/projects/import/useImportManager';
import { useEffectOnce } from 'hooks/utils';

export function EntriesToDataset({
	handleBack,
	handleFinishImport,
	handleApiImportErrors
}: ImportPageProps) {
	const { addMoreRowsImportError, timezoneDuringImport } = useFlags();

	const [{ loading: loadingGenerateVariableNames }] = useActivities([
		ActionTypes.GENERATE_VARIABLE_NAMES
	]);

	const { translate } = useTranslation();

	const [showUnmatchedFields, setShowUnmatchedFields] = useState(false);
	const [showError, setShowError] = useState(true);

	const {
		data: {
			main: {
				// scopeVariables,
				dataTypeSelectItems,
				createNewVariable,
				initialNewVariables,
				dateFormatsSelectItems,
				dateTimeFormatsSelectItems,
				formattedVariables,
				globalTimezone,
				initialSuggestions,
				loadingVariables,
				mappedInitialVariables,
				mappedMainLevelVariables,
				previousMapping,
				timeZones,
				variablesWithDateFormat,
				formattedDropdownValues,
				duplicationLists,
				isBinary,
				isExcelOrigin
			},
			series: {
				importVariableSetName,
				assignedOrganization,
				columnToMatchWithMainLevel,
				isImportVariableSet,
				showAssignOrganizationModal,
				userOrganizations,
				variableToMatchOnMainLevel
			}
		},
		handlers: {
			main: {
				canUpload,
				computeTitleAndSubtitle,
				handleChangeGlobalTimezone,
				handleClearVariable,
				onVariablePreviewNameChanged,
				onVariableTypeChanged,
				onVariableLabelChange,
				requiresTimezoneSettings,
				setFormattedVariables,
				upload
			},
			series: {
				setDestinationSetName,
				handleSelectColumnToMatchWithMainLevel,
				setColumnToMatchWithMainLevel,
				setAssignedOrganizationId,
				setShowAssignOrganizationModal,
				setVariableToMatchOnMainLevel
			}
		}
	} = useImportManager({
		selectedOption: ImportType.MoreEntriesToDataset,
		handleFinishImport,
		handleApiImportErrors
	});

	const [showPreviousMappingModal, setShowPreviousMappingModal] = useState(false);

	const {
		importType: previousImportType,
		formattedVariables: previousFormattedVariables,
		columnToMatchWithMainLevel: previousColumnToMatchWithMainLevel,
		variableToMatchOnMainLevel: previousVariableToMatchOnMainLevel
	} = previousMapping;

	const isPreviousImportType = previousImportType === ImportType.MoreEntriesToDataset;

	const availablePreviousFormattedVariables = previousFormattedVariables.filter(
		previousFormattedVariable =>
			formattedVariables.some(variable => variable.label === previousFormattedVariable.label)
	);
	const availablePreviousFormattedVariablesLabels = availablePreviousFormattedVariables.map(
		variable => variable.label
	);

	const canUsePreviousVariableToMatchOnMainLevel = mappedMainLevelVariables.some(
		variable =>
			variable.label === previousVariableToMatchOnMainLevel?.label &&
			variable.value === previousVariableToMatchOnMainLevel?.name
	);
	const canUsepreviousColumnToMatchWithMainLevel = initialNewVariables.some(
		variable => variable.previewVariableLabel === previousColumnToMatchWithMainLevel?.label
	);

	const getMainLevelMatching = isImportVariableSet
		? canUsePreviousVariableToMatchOnMainLevel && canUsepreviousColumnToMatchWithMainLevel
		: true;

	const canUsePreviousMapping =
		getMainLevelMatching &&
		isPreviousImportType &&
		availablePreviousFormattedVariables.length > 0;

	const filteredDataTypeSelectItems = dataTypeSelectItems.filter(dataType => {
		// https://ledidi.atlassian.net/browse/PRJCTS-8688
		// time duration disabled altogether for this release (2023-12-15)
		return (
			dataType.value !== VariableType.Unique && dataType.value !== VariableType.TimeDuration
		);
	});

	useEffectOnce(() => {
		if (isImportVariableSet && importVariableSetName) {
			setDestinationSetName(importVariableSetName);
		}
	});

	const isVariableUsed = (variableName: string) => {
		const isUsed = formattedVariables.find(
			element => element.previewVariableName === variableName
		);

		return isUsed ? true : false;
	};

	function loadPreviousMapping() {
		previousColumnToMatchWithMainLevel &&
			setColumnToMatchWithMainLevel(previousColumnToMatchWithMainLevel);
		previousVariableToMatchOnMainLevel &&
			setVariableToMatchOnMainLevel(previousVariableToMatchOnMainLevel);

		const newFormattedVariables = cloneDeep(formattedVariables);

		newFormattedVariables.forEach(item => {
			if (item.label === previousColumnToMatchWithMainLevel?.label) {
				const index = newFormattedVariables.indexOf(item);
				newFormattedVariables.splice(index, 1);
			}
		});
		const newVariables = newFormattedVariables.map(
			formattedVariable =>
				availablePreviousFormattedVariables.find(
					availablePreviousFormattedVariable =>
						availablePreviousFormattedVariable.label === formattedVariable.label
				) || formattedVariable
		);
		setFormattedVariables(newVariables);
	}

	const { title } = computeTitleAndSubtitle();

	const numberOfUnmatchedField = useMemo(() => {
		let number = 0;
		formattedVariables.forEach(formattedVariable => {
			if (
				formattedVariable.isNew &&
				((!formattedVariable.previewVariableLabel && formattedVariable.labelError) ||
					formattedVariable.previewVariableLabelError ===
						translate(
							({ projects }) =>
								projects.createAndImport.generics.previewVariables.noDuplicates
						))
			) {
				number = number + 1;
			}
		});
		return number;
	}, [formattedVariables]);

	const newFormattedVariables = useMemo(() => {
		if (!numberOfUnmatchedField && showUnmatchedFields) {
			return formattedVariables;
		}
		if (showUnmatchedFields) {
			return formattedVariables.filter(
				formattedVariable =>
					formattedVariable.isNew &&
					(formattedVariable.labelError || formattedVariable.previewVariableLabelError)
			);
		}
		return formattedVariables;
	}, [formattedVariables, showUnmatchedFields, numberOfUnmatchedField]);

	return (
		<>
			<NarrowContainer>
				{!addMoreRowsImportError && <Title>{title}</Title>}

				{importRequiresGlobalTimezoneSettings(formattedVariables, timezoneDuringImport) && (
					<TimeZoneOptions
						value={globalTimezone}
						onChange={handleChangeGlobalTimezone}
						applyToAll={() =>
							applyTimezoneToAllDatetimeVariables(
								formattedVariables,
								globalTimezone,
								setFormattedVariables
							)
						}
					/>
				)}

				{numberOfUnmatchedField && showError ? (
					<ErrorContainer>
						<Flex>
							<Icon svg={Svgs.InformationRed} marginOffset={{ right: 0.8 }} />
							<Typography.Paragraph
								fontweight={f => f.bold}
								color={Colors.text.error}
							>
								{numberOfUnmatchedField}{' '}
								{translate(dict => dict.projects.createAndImport.unmatched)}
							</Typography.Paragraph>
						</Flex>
						<Icon
							svg={Svgs.Close}
							colors={{ color: Colors.text.error }}
							onClick={() => setShowError(false)}
							size={s => s.m}
						/>
					</ErrorContainer>
				) : null}

				{numberOfUnmatchedField ? (
					<Switch
						label={translate(dict => dict.projects.createAndImport.showUnmatched)}
						on={showUnmatchedFields}
						onChange={() => setShowUnmatchedFields(s => !s)}
						marginOffset={{ bottom: 1.6 }}
					/>
				) : null}

				{canUsePreviousMapping && (
					<Flex>
						<PreviousMappingButton
							variant={v => v.link}
							title={translate(
								({ projects }) =>
									projects.createAndImport.generics.previousMappingModal.title
							)}
							marginOffset={{ right: 2.4 }}
							onClick={() => setShowPreviousMappingModal(true)}
						/>
					</Flex>
				)}

				{isImportVariableSet && (
					<MatchColumnToMainVariableContainer>
						<FlexRow noMargin>
							<FlexCellLabel>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainFileColumn
									)}
								</Typography.Caption>
							</FlexCellLabel>
							<FlexCellIcon />
							<FlexCellType>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainVariableToMatch
									)}
								</Typography.Caption>
							</FlexCellType>
						</FlexRow>

						<FlexRow noMargin>
							<FlexCellLabel>
								<CreatableSelect
									canClear={false}
									className="select"
									items={mappedInitialVariables}
									scrollIntoView
									value={
										columnToMatchWithMainLevel
											? {
													label: columnToMatchWithMainLevel?.label,
													value: columnToMatchWithMainLevel?.label
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													)
											  }
									}
									onValueSelected={(_, index) =>
										handleSelectColumnToMatchWithMainLevel(index)
									}
								/>
							</FlexCellLabel>
							<FlexCellIcon>
								<Icon svg={Svgs.ArrowLongRight} />
							</FlexCellIcon>
							<FlexCellType>
								<CreatableSelect
									canClear={false}
									className="select"
									scrollIntoView
									value={
										variableToMatchOnMainLevel
											? {
													label: variableToMatchOnMainLevel.label,
													value: variableToMatchOnMainLevel.name
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.variableName
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.variableName
													)
											  }
									}
									items={mappedMainLevelVariables}
									onValueSelected={(_, __, item) =>
										item &&
										item.value &&
										setVariableToMatchOnMainLevel({
											name: item.value,
											label: item.label
										})
									}
								/>
							</FlexCellType>
						</FlexRow>
					</MatchColumnToMainVariableContainer>
				)}

				{newFormattedVariables.length ? (
					<FlexRow alignCenter>
						<FlexCellLabel>
							<ColumnTitle>
								{translate(
									({ projects }) =>
										projects.createAndImport.generics.entriesToDataset
											.columnTitle
								)}
							</ColumnTitle>
						</FlexCellLabel>
						<FlexCellIcon />
						<FlexCellType>
							<WithTooltip
								message={translate(
									({ projects }) =>
										projects.createAndImport.generics.entriesToDataset
											.dropdownTooltip
								)}
							>
								<ColumnTitle>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.entriesToDataset
												.dropdownsTitle
									)}
								</ColumnTitle>
							</WithTooltip>
						</FlexCellType>
					</FlexRow>
				) : null}
				<List>
					{newFormattedVariables.map(formattedVariable => (
						<FlexRow key={formattedVariable.id}>
							<FlexCellLabel
								id={
									formattedVariable.type === VariableType.Date
										? `preview-variable-cell-date-format-${formattedVariable.id}`
										: `preview-variable-cell-date-time-format-${formattedVariable.id}`
								}
							>
								<Input
									type={InputType.Text}
									id={`preview-variable-label-${formattedVariable.id}`}
									value={formattedVariable.label}
									placeholder={translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.variableNameHere
									)}
									disabled
								/>
								{[VariableType.Date, VariableType.DateTime].includes(
									formattedVariable.type as VariableType
								) && (
									<>
										{variableHasSuggestedDateFormat(
											formattedVariable,
											newFormattedVariables,
											isExcelOrigin
										) ? (
											<>
												<Spacer size={s => s.s} />
												<CreatableSelect
													scrollIntoView
													canClear={false}
													id={
														formattedVariable.type === VariableType.Date
															? `preview-variable-date-format-${formattedVariable.id}`
															: `preview-variable-date-time-format-${formattedVariable.id}`
													}
													disabled={
														variablesWithDateFormat.includes(
															formattedVariable.label
														) &&
														initialSuggestions?.find(
															v => v.name === formattedVariable.label
														)?.suggestedVariableType ===
															formattedVariable.type
													}
													value={{
														label: matchDateTimeFormat(
															formattedVariable.dateFormat
														),
														value: matchDateTimeFormat(
															formattedVariable.dateFormat
														)
													}}
													items={
														formattedVariable.type === VariableType.Date
															? [
																	...dateFormatsSelectItems,
																	{
																		label: 'Custom',
																		value: 'Custom'
																	}
															  ]
															: [
																	...dateTimeFormatsSelectItems,
																	{
																		label: 'Custom',
																		value: 'Custom'
																	}
															  ]
													}
													onValueSelected={value =>
														value &&
														handleVariableDateTypeChange(
															formattedVariable.id,
															value,
															formattedVariables,
															setFormattedVariables
														)
													}
												/>
											</>
										) : (
											<>
												{!isExcelOrigin &&
													showDateFormatPicker(
														formattedVariable.type as VariableType,
														VariableType.Date,
														!!isExcelOrigin,
														isBinary
													) && (
														<>
															<Spacer size={s => s.s} />
															<FlexCellNewData
																stretch
																id={`preview-variable-cell-date-format-${formattedVariable.id}`}
															>
																<CreatableSelect
																	id={`preview-variable-date-format-${formattedVariable.id}`}
																	canClear={false}
																	scrollIntoView
																	disabled={
																		variablesWithDateFormat.includes(
																			formattedVariable.label
																		) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																				formattedVariable.label
																		)?.suggestedVariableType ===
																			formattedVariable.type
																	}
																	value={
																		formattedVariable.dateFormat
																			? {
																					value: matchDateTimeFormat(
																						formattedVariable.dateFormat
																					),
																					label: matchDateTimeFormat(
																						formattedVariable.dateFormat
																					)
																			  }
																			: {
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					),
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateFormatsSelectItems
																	]}
																	onValueSelected={value =>
																		value &&
																		handleVariableDateTypeChange(
																			formattedVariable.id,
																			value,
																			formattedVariables,
																			setFormattedVariables
																		)
																	}
																/>
															</FlexCellNewData>
														</>
													)}

												{showDateFormatPicker(
													formattedVariable.type as VariableType,
													VariableType.DateTime,
													!!isExcelOrigin,
													isBinary
												) && (
													<>
														<>
															<Spacer size={s => s.s} />
															<FlexCellNewData
																stretch
																id={`preview-variable-cell-date-time-format-${formattedVariable.id}`}
															>
																<CreatableSelect
																	id={`preview-variable-date-time-format-${formattedVariable.id}`}
																	canClear={true}
																	scrollIntoView
																	disabled={
																		variablesWithDateFormat.includes(
																			formattedVariable.label
																		) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																				formattedVariable.label
																		)?.suggestedVariableType ===
																			formattedVariable.type
																	}
																	value={
																		formattedVariable.dateFormat
																			? {
																					value: matchDateTimeFormat(
																						formattedVariable.dateFormat
																					),
																					label: matchDateTimeFormat(
																						formattedVariable.dateFormat
																					)
																			  }
																			: {
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateAndTime
																					),
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateAndTime
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateTimeFormatsSelectItems
																	]}
																	onValueSelected={value =>
																		value &&
																		handleVariableDateTypeChange(
																			formattedVariable.id,
																			value,
																			formattedVariables,
																			setFormattedVariables
																		)
																	}
																	onClear={() => {
																		handleVariableDateTypeChange(
																			formattedVariable.id,
																			'',
																			formattedVariables,
																			setFormattedVariables
																		);
																	}}
																/>
															</FlexCellNewData>
														</>
													</>
												)}
											</>
										)}
										{formattedVariable.dateFormat === DATE_FORMATS.Custom && (
											<>
												<Spacer size={s => s.s} />
												<Input
													type={InputType.Text}
													id={`preview-variable-custom-date-time-format-${formattedVariable.id}`}
													value={formattedVariable.customDateFormat}
													error={formattedVariable.dateFormatError}
													placeholder={translate(
														dict =>
															dict.projectsPage.dataToEntries
																.customDateFormat
													)}
													onChange={e =>
														handleCustomDateFormatChange(
															formattedVariable.id,
															e.target.value.toUpperCase(),
															formattedVariables,
															setFormattedVariables,
															formattedVariable.type ===
																VariableType.DateTime
														)
													}
												/>
											</>
										)}
									</>
								)}
							</FlexCellLabel>
							<FlexCellIcon>
								<Icon svg={Svgs.ArrowLongRight} />
							</FlexCellIcon>
							<FlexCellType>
								<FlexRow>
									{/** CREATE OR CHOOSE VARIABLE TO MATCH */}
									<CreatableSelect
										canClear={!!formattedVariable.previewVariableName}
										id={`preview-variable-match-or-create-dropdown-${formattedVariable.id}`}
										scrollIntoView
										disabled={loadingVariables}
										items={formattedDropdownValues.map(item =>
											isVariableUsed(item.value)
												? { ...item, disabled: true }
												: item
										)}
										value={
											formattedVariable.isNew
												? {
														value: createNewVariable,
														label: createNewVariable
												  }
												: {
														value: formattedVariable.previewVariableName,
														label: formattedVariable.previewVariableLabel
												  }
										}
										onValueSelected={(value, index) =>
											value &&
											onVariablePreviewNameChanged(
												formattedVariable.id,
												formattedVariable.previewVariableLabel,
												value,
												index
											)
										}
										onClear={() => handleClearVariable(formattedVariable.id)}
										error={
											!formattedVariable.isNew
												? formattedVariable.previewVariableLabelError
												: undefined
										}
									/>
								</FlexRow>
								{formattedVariable.isNew ? (
									<>
										<Spacer size={s => s.s} />
										<FlexRow noMargin>
											<FlexCellNewLabel>
												<Input
													type={InputType.Text}
													id={`preview-variable-label-${formattedVariable.id}`}
													value={formattedVariable.previewVariableLabel}
													error={
														formattedVariable.previewVariableLabelError
													}
													placeholder={translate(
														({ projects }) =>
															projects.createAndImport.generics
																.previewVariables.variableNameHere
													)}
													onChange={e =>
														onVariableLabelChange({
															varId: formattedVariable.id,
															value: e.target.value,
															isPreviewVariable: true,
															duplicationList: duplicationLists.find(
																duplications =>
																	duplications.includes(
																		formattedVariable.id
																	)
															)
															// clearLabelErrors: true
														})
													}
												/>
											</FlexCellNewLabel>
											<FlexCellNewData>
												<CreatableSelect
													id={`preview-variable-type-${formattedVariable.id}`}
													canClear={true}
													scrollIntoView
													allowCreate={false}
													required={true}
													value={
														formattedVariable.type !== '' &&
														formattedVariable.type !== null
															? {
																	label: translate(
																		dict =>
																			dict.variableLabels[
																				formattedVariable.type as VariableType
																			]
																	),
																	value: formattedVariable.type as VariableType
															  }
															: {
																	label: translate(
																		dict =>
																			dict.radioGroups
																				.valueRequired
																	),
																	value: translate(
																		dict =>
																			dict.radioGroups
																				.valueRequired
																	)
															  }
													}
													items={filteredDataTypeSelectItems}
													onValueSelected={value => {
														onVariableTypeChanged(
															formattedVariable.id,
															value as VariableType
														);
													}}
												/>
											</FlexCellNewData>
										</FlexRow>
									</>
								) : null}

								{requiresTimezoneSettings(formattedVariable) && (
									<>
										<Spacer size={s => s.xs} />
										<CreatableSelect
											canClear={true}
											id={`global_timezone_select`}
											items={timeZones}
											onClear={() =>
												handleVariableTimeZoneChange(
													formattedVariable.id,
													'',
													formattedVariables,
													setFormattedVariables
												)
											}
											onValueSelected={value =>
												value &&
												handleVariableTimeZoneChange(
													formattedVariable.id,
													value,
													formattedVariables,
													setFormattedVariables
												)
											}
											value={formattedVariable.timeZone}
										/>
									</>
								)}
							</FlexCellType>
						</FlexRow>
					))}
				</List>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: loadingGenerateVariableNames,
					disabled: canUpload(),
					onClick: () => upload()
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: handleBack
				}}
				maxWidth={65.2}
				zIndex={1006}
			/>

			{showPreviousMappingModal && (
				<PreviousMappingModal
					previousVariablesLabels={availablePreviousFormattedVariablesLabels}
					onConfirm={() => {
						loadPreviousMapping();
						setShowPreviousMappingModal(false);
					}}
					onClose={() => setShowPreviousMappingModal(false)}
				/>
			)}
			{/* ASSIGN GROUP MODAL */}
			<AssignProjectOrganizationModal
				visible={showAssignOrganizationModal}
				onContinue={() => {
					setShowAssignOrganizationModal(false);
				}}
				onClose={handleBack}
				organization={assignedOrganization}
				onSelect={id => setAssignedOrganizationId(id)}
				userOrganizations={userOrganizations}
			/>
		</>
	);
}

interface WithToolTipProps {
	message: string;
	children: React.ReactNode;
}

function WithTooltip({ children, message }: WithToolTipProps) {
	const [tooltipVisible, setTooltipVisible] = useState(false);

	return (
		<TooltipContainer
			onMouseEnter={() => setTooltipVisible(true)}
			// onMouseLeave={() => setTooltipVisible(false)}
		>
			<div>{children}</div>
			<InfoTooltip marginOffset={{ left: 1.4 }} iconVisible={tooltipVisible} text={message} />
		</TooltipContainer>
	);
}
