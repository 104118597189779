import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import produce from 'immer';

import { Columns, CompareNumericAnalysisV1, CompareNumericStatisticsV1 } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useActiveFiltersByVariableName,
	useFilters
} from 'hooks/store';
import { useMutableState, useDebounce, usePrevious } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

interface Props {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CompareNumericConfigV1({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();
	const { input: analysisInput } = analysis;

	const [draftAnalysisInput, setDraftAnalysisInput] = useMutableState(analysisInput);
	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [activeColumn] = useAnalysesActiveColum();
	const { variables: inputVariables, statistics: inputStatistics } = draftAnalysisInput;

	const { variablesMap, variableSetsMap } = variablesData;

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { selectItemsMap, numericSelectItems, categorySelectItems } = variablesDataSelectItems;

	const filters = useActiveFiltersByVariableName(inputVariables.categoryVariable);

	const twoCategories = useMemo(() => {
		const categs = getSelectedItemCategories(inputVariables.categoryVariable);

		if (categs && categs.length === 2) {
			if (!filters.length) return true;

			return areTwoFiltersActive();
		}

		if (categs && categs.length > 2) {
			return areTwoFiltersActive();
		}

		return false;
	}, [inputVariables, variablesMap, filters]);

	const twoOrMoreCategories = useMemo(() => {
		const categs = getSelectedItemCategories(inputVariables.categoryVariable);

		if (categs && categs.length < 2) return false;

		let valid = true;

		filters.forEach(filter => {
			if (filter.values && filter.values.length < 2) {
				valid = false;
			}
		});

		return valid;
	}, [inputVariables, variablesMap, filters]);

	const moreThanTwoCategories = useMemo(() => {
		const categs = getSelectedItemCategories(inputVariables.categoryVariable);

		if (categs && categs.length < 3) return false;

		let valid = true;

		filters.forEach(filter => {
			if (filter.values && filter.values.length < 3) {
				valid = false;
			}
		});

		return valid;
	}, [inputVariables, variablesMap, filters]);

	const isTwoWayAnovaEnabled = !!inputVariables.categoryVariableTwo;
	const isTwoWayManovaEnabled =
		!!inputVariables.exploreVariableTwo && !!inputVariables.categoryVariableTwo;
	const isOneWayManovaEnabled = !!inputVariables.exploreVariableTwo;

	useDebounce(
		() => {
			const hasChanges = !isEqual(draftAnalysisInput, analysisInput);

			if (!hasChanges) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input = draftAnalysisInput;
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[draftAnalysisInput],
		ANALYSIS_DEBOUNCE_TIME
	);

	// SYNC `draftAnalysisInput` STATE
	useEffect(() => {
		if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	}, [analysisInput]);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (prevShouldRefetchAnalyses === undefined) return;

		if (prevShouldRefetchAnalyses !== shouldRefetchAnalyses) {
			turnOffStatistics();
		}
	}, [shouldRefetchAnalyses]);

	useEffect(() => {
		if (!isTwoWayAnovaEnabled && inputStatistics.twoWayAnovaV1) {
			setDraftAnalysisInput(state => {
				state.statistics.twoWayAnovaV1 = false;
			});
		}
		if (!isTwoWayManovaEnabled && inputStatistics.twoWayManovaV1) {
			setDraftAnalysisInput(state => {
				state.statistics.twoWayManovaV1 = false;
			});
		}
	}, [isTwoWayAnovaEnabled, isTwoWayManovaEnabled]);

	function areTwoFiltersActive() {
		let valid = false;

		filters.forEach(filter => {
			if (filter.values && filter.values.length === 2) {
				valid = true;
			}
		});

		return valid;
	}

	function getResetStatistics(): CompareNumericStatisticsV1 {
		return {
			shapiroV1: false,
			mannWhitneyV1: false,
			independentV1: false,
			oneWayAnovaV1: false,
			tukeyV1: false,
			kruskalV1: false,
			twoWayAnovaV1: false,
			twoWayManovaV1: false,
			oneWayManovaV1: false
		};
	}

	function turnOffStatistics() {
		setDraftAnalysisInput(state => {
			state.statistics = getResetStatistics();
		});
	}

	function getSelectedItemCategories(name: string): string[] {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			if (aggregatorVariableName in variablesMap) {
				variable = variablesMap[aggregatorVariableName];
			}
		}

		if (variable) return variable.categories.map(c => c.value);

		return [];
	}

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as CompareNumericAnalysisV1} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.explore_V1
						)}
						items={numericSelectItems}
						value={selectItemsMap[inputVariables.exploreVariable]}
						onValueSelected={exploreVariable =>
							exploreVariable &&
							setDraftAnalysisInput(state => {
								state.variables.exploreVariable = exploreVariable;
								state.statistics = getResetStatistics();
							})
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.category_V1
						)}
						items={categorySelectItems}
						value={selectItemsMap[inputVariables.categoryVariable]}
						onValueSelected={categoryVariable =>
							categoryVariable &&
							setDraftAnalysisInput(state => {
								state.variables.categoryVariable = categoryVariable;
								state.statistics = getResetStatistics();
							})
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.categoryTwo_V1
						)}
						placeholder={`-`}
						items={categorySelectItems}
						value={selectItemsMap[inputVariables.categoryVariableTwo ?? '']}
						onValueSelected={categoryVariableTwo =>
							setDraftAnalysisInput(state => {
								if (categoryVariableTwo) {
									state.variables.categoryVariableTwo = categoryVariableTwo;
								} else {
									delete state.variables.categoryVariableTwo;
								}

								state.statistics = getResetStatistics();
							})
						}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.exploreTwo_V1
						)}
						placeholder={`-`}
						items={numericSelectItems}
						value={selectItemsMap[inputVariables.exploreVariableTwo ?? '']}
						onValueSelected={exploreVariableTwo =>
							setDraftAnalysisInput(state => {
								if (exploreVariableTwo) {
									state.variables.exploreVariableTwo = exploreVariableTwo;
								} else {
									delete state.variables.exploreVariableTwo;
								}

								state.statistics = getResetStatistics();
							})
						}
					/>

					{/* STATISTICS */}
					<Switch
						label={translate(({ analysis }) => analysis.statistics.shapiro.name)}
						on={inputStatistics.shapiroV1}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.shapiroV1 = !state.statistics.shapiroV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.independent.name)}
						on={inputStatistics.independentV1}
						disabled={!twoCategories}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.independentV1 = !state.statistics.independentV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.mannWhitney.name)}
						on={inputStatistics.mannWhitneyV1}
						disabled={!twoCategories}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.mannWhitneyV1 = !state.statistics.mannWhitneyV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.oneWayAnova.name)}
						on={inputStatistics.oneWayAnovaV1}
						disabled={!twoOrMoreCategories}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.oneWayAnovaV1 = !state.statistics.oneWayAnovaV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.tukey.name)}
						on={inputStatistics.tukeyV1}
						disabled={!moreThanTwoCategories}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.tukeyV1 = !state.statistics.tukeyV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.kruskal.name)}
						on={inputStatistics.kruskalV1}
						disabled={!moreThanTwoCategories}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.kruskalV1 = !state.statistics.kruskalV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.twoWayAnova.name)}
						on={inputStatistics.twoWayAnovaV1}
						disabled={!isTwoWayAnovaEnabled}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.twoWayAnovaV1 = !state.statistics.twoWayAnovaV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.oneWayManova.name)}
						on={inputStatistics.oneWayManovaV1}
						disabled={!isOneWayManovaEnabled}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.oneWayManovaV1 = !state.statistics.oneWayManovaV1;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.twoWayManova.name)}
						on={inputStatistics.twoWayManovaV1}
						disabled={!isTwoWayManovaEnabled}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.twoWayManovaV1 = !state.statistics.twoWayManovaV1;
							})
						}
					/>
				</Gap>
			</CollapsibleCard>

			<AnalysisFormatting hasChartOptions={false} isEnabled analysis={analysis} />
		</ConfigContainer>
	);
}
