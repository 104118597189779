import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SegmentComponent } from './SegmentAnalytics';
import { useUsername } from 'hooks/store';

export function SegmentPageTracker() {
	const { pathname } = useLocation();

	const userId = useUsername();

	useEffect(() => {
		SegmentComponent?.page(
			{
				userId,
				title: pathname
			},
			{
				context: {
					ip: '0.0.0.0'
				},
				path: pathname
			}
		);
	}, [pathname]);

	return null;
}
