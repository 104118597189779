import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { PlotNumericAnalysis, PlotNumericScatterResults } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { PlotNumericColumnsChart } from './PlotNumericColumnsChart';
import { PlotNumericBoxPlotChart } from './PlotNumericBoxPlotChart';
import { PlotNumericScatterChart } from './PlotNumericScatterChart';

import { ScatterOptions } from '../PlotNumeric';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import {
	useProject,
	useAnalysisActiveTab,
	useAnalysesActiveColum,
	useFullscreenAnalysis
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

function shuffleData(data: PlotNumericScatterResults) {
	return data.map((element, index) => {
		return element.yValues.map(() => {
			const min = index - 0.18;
			const max = index + 0.18;

			return Number((Math.random() * (max - min) + min).toFixed(2));
		});
	});
}

interface Props extends ScatterOptions {
	analysis: PlotNumericAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function PlotNumericView({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading,
	line,
	align
}: Props) {
	const [{ data: project }] = useProject();

	const {
		id,
		options: { configPanel, chartLegend = true },
		input: { variables },
		output: { dataset, grouping }
	} = analysis;

	const [activeTab] = useAnalysisActiveTab(id);
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const { selectItemsLabelMap } = variablesDataSelectItems;
	const { variablesMap } = variablesData;

	const plotNumericColumnsId = ZingChartExportTypes.PlotNumericColumns + id;
	const plotNumericBoxPlotId = ZingChartExportTypes.PlotNumericBoxPlot + id;
	const plotNumericScatterId = ZingChartExportTypes.PlotNumericScatter + id;

	useEffect(() => {
		if (exporting && project) {
			if (activeTab === 0) {
				exportSvg(
					plotNumericColumnsId,
					getExportFileName(
						ExportFileNames.PlotNumericColumnsChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 1) {
				exportSvg(
					plotNumericBoxPlotId,
					getExportFileName(
						ExportFileNames.PlotNumericBoxPlotChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					plotNumericScatterId,
					getExportFileName(
						ExportFileNames.PlotNumericScatterChart,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const shuffledData = useMemo(() => shuffleData(dataset.scatter), [dataset.scatter]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[variables.categoryVariable],
		labelY: selectItemsLabelMap[variables.numericVariable]
	};

	const legendHeader = variables.groupingVariable
		? selectItemsLabelMap[variables.groupingVariable]
		: undefined;

	const { parsedDataset } = useMemo(() => {
		const parsedDataset = cloneDeep(dataset);

		if (analysis.options.showCategoryLabels) {
			Object.keys(parsedDataset).forEach(group => {
				parsedDataset[group as keyof typeof parsedDataset].forEach(g => {
					if ('categorizationValue' in g) {
						g.categorizationValue =
							(variables.categoryVariable &&
								mapVariableCategoryValueToLabel(
									g.categorizationValue,
									variablesMap[variables.categoryVariable]
								)) ??
							g.categorizationValue;
					}

					if (grouping && 'groupedCalculations' in g) {
						g.groupedCalculations.forEach(variable => {
							variable.groupValue =
								(variables.groupingVariable &&
									mapVariableCategoryValueToLabel(
										variable.groupValue,
										variablesMap[variables.groupingVariable]
									)) ??
								variable.groupValue;
						});
					}
				});
			});
		}
		return { parsedDataset };
	}, [dataset, variablesMap, analysis.options.showCategoryLabels]);

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			{activeTab === 0 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumeric}
					plotNumericType={0}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericColumnsChart
							grouping={grouping}
							data={parsedDataset.columns}
							isLegendEnabled={chartLegend}
							errorBar={variables.errorBar}
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericColumnsChart
							id={plotNumericColumnsId}
							grouping={grouping}
							data={parsedDataset.columns}
							errorBar={variables.errorBar}
							isForExport
							isLegendEnabled
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 1 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumeric}
					plotNumericType={1}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericBoxPlotChart
							key={plotNumericBoxPlotId}
							activeColumn={activeColumn}
							fullscreen={fullscreen}
							grouping={grouping}
							data={parsedDataset.boxplot}
							isLegendEnabled={chartLegend}
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericBoxPlotChart
							key={plotNumericBoxPlotId}
							id={plotNumericBoxPlotId}
							activeColumn={activeColumn}
							fullscreen={fullscreen}
							grouping={grouping}
							data={parsedDataset.boxplot}
							isForExport
							isLegendEnabled
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 2 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumeric}
					plotNumericType={2}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericScatterChart
							line={line}
							align={align}
							shuffledData={shuffledData}
							data={parsedDataset.scatter}
							scalesLabels={scalesLabels}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericScatterChart
							id={plotNumericScatterId}
							line={line}
							align={align}
							shuffledData={shuffledData}
							data={parsedDataset.scatter}
							isForExport
							scalesLabels={scalesLabels}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}
		</AnalysisViewContainer>
	);
}
