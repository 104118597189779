import { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { ProjectDPASignData } from 'api/data/projects';
import { useTranslation } from 'hooks/store';
import { InputType, SetState } from 'types/index';

import { Row } from '../styles';
import { handleFieldAutoFocus } from '../helpers';

import { Steps } from '../../ProjectDPAForm';
import { InfoMessage } from 'components/UI/InfoMessage';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { Input } from 'components/UI/Inputs/Input';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { Modal } from 'components/UI/Modal';
import { Link } from 'components/UI/Link';

enum UseDPA {
	Yes = 'yes',
	No = 'no'
}

interface Props {
	useDPAState: {
		useDPA: boolean;
		setUseDPA: SetState<boolean>;
	};
}

export function ProjectDPAIntroduction({ useDPAState: { useDPA, setUseDPA } }: Props) {
	const { translate } = useTranslation();

	const { setValue, getValues } = useFormContext<ProjectDPASignData>();

	const [personalDataTextModal, setPersonalDataTextModal] = useState(false);
	const [DPATextModal, setDPATextModal] = useState(false);

	const { shouldAutoFocus } = handleFieldAutoFocus({
		formValues: getValues(),
		step: Steps.Introduction
	});

	return (
		<>
			<InfoMessage
				message={
					<>
						{translate(dict => dict.projects.createAndImport.DPA.hasDPA.DPAcondition1)}
						<Link onClick={() => setDPATextModal(true)}>
							{translate(
								dict =>
									dict.projects.createAndImport.DPA.hasDPA.dataProcessingAgreement
							)}
						</Link>
						{` ${translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.DPAcondition2
						)} `}
						<Link onClick={() => setPersonalDataTextModal(true)}>
							{translate(
								dict => dict.projects.createAndImport.DPA.hasDPA.personalData
							)}
						</Link>
						{`.`}
					</>
				}
			/>

			<Spacer size={s => s.m} />

			<Typography.Paragraph fontweight={w => w.medium} marginOffset={{ bottom: 0.8 }}>
				{translate(dict => dict.projects.createAndImport.DPA.hasDPA.label)}
			</Typography.Paragraph>

			<RadioGroup
				items={[
					translate(dict => dict.terms[UseDPA.Yes]),
					translate(dict => dict.terms[UseDPA.No])
				]}
				selected={
					useDPA
						? translate(dict => dict.terms[UseDPA.Yes])
						: translate(dict => dict.terms[UseDPA.No])
				}
				onSelect={item => setUseDPA(item === translate(dict => dict.terms[UseDPA.Yes]))}
			/>

			<Spacer size={s => s.m} />

			{/* USE DPA */}
			{useDPA && (
				<>
					<Typography.Paragraph fontweight={w => w.bold}>
						{translate(
							dict => dict.projects.createAndImport.DPA.hasDPA.provideRequiredInfo
						)}
					</Typography.Paragraph>

					<Spacer size={s => s.s} />

					<Controller
						name="legalEntityName"
						render={({ field: { ref, name, value, onChange, onBlur } }) => (
							<Input
								ref={ref}
								type={InputType.Text}
								label={translate(
									dict =>
										dict.projects.createAndImport.DPA.hasDPA.legatEntityLabel
								)}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								autoFocus={shouldAutoFocus(name)}
								required
							/>
						)}
					/>

					<Spacer size={s => s.s} />

					<Row>
						<Controller
							name="enterpriseNumber"
							render={({ field: { ref, name, value, onChange, onBlur } }) => (
								<Input
									ref={ref}
									type={InputType.Text}
									label={translate(
										dict =>
											dict.projects.createAndImport.DPA.hasDPA
												.enterpriseNumberLabel
									)}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									autoFocus={shouldAutoFocus(name)}
									required
								/>
							)}
						/>

						<Controller
							name="country"
							render={({ field: { value, onBlur } }) => (
								<CountrySelector
									value={value}
									label={translate(
										dict =>
											dict.projects.createAndImport.DPA.hasDPA.countryLabel
									)}
									placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
									onSelect={country =>
										setValue('country', country?.ISO_2 ?? '', {
											shouldDirty: true,
											shouldValidate: true
										})
									}
									onBlur={onBlur}
									required
								/>
							)}
						/>
					</Row>
				</>
			)}

			{/* MODALS */}

			{/* PERSONAL DATA */}
			<Modal
				size={s => s.s}
				visible={personalDataTextModal}
				title={translate(
					dict => dict.projects.createAndImport.DPA.hasDPA.whatIsPersonalData
				)}
				primary={{
					label: translate(({ buttons }) => buttons.done),
					onClick: () => setPersonalDataTextModal(false)
				}}
				onClose={() => setPersonalDataTextModal(false)}
				close
			>
				<Typography.Paragraph multiline>
					{translate(dict => dict.projects.createAndImport.DPA.hasDPA.personalDataText)}
				</Typography.Paragraph>
			</Modal>

			{/* Data Processing Agreement */}
			<Modal
				size={s => s.s}
				visible={DPATextModal}
				title={translate(dict => dict.projects.createAndImport.DPA.hasDPA.whatIsDPA)}
				primary={{
					label: translate(({ buttons }) => buttons.done),
					onClick: () => setDPATextModal(false)
				}}
				onClose={() => setDPATextModal(false)}
				close
			>
				<Typography.Paragraph multiline>
					{translate(dict => dict.projects.createAndImport.DPA.hasDPA.DPAtext)}
				</Typography.Paragraph>
			</Modal>
		</>
	);
}
