import { useState } from 'react';
import { isEqual } from 'lodash';

import { Columns, DensityPlotAnalysis } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

interface Props {
	analysis: DensityPlotAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function DensityPlotConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const {
		input: { variables }
	} = analysis;

	const [values, setValues] = useState(variables);

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [fullscreen] = useFullscreenAnalysis();

	const [activeColumn] = useAnalysesActiveColum();

	const { selectItemsMap, numericSelectItems, categorySelectItems } = variablesDataSelectItems;

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: DensityPlotAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as DensityPlotAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({
						analysisId: analysis.id,
						parameters: !isParamsOpen
					})
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.numeric
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.numericVariable]}
						onValueSelected={numericVariable =>
							numericVariable && setValues(state => ({ ...state, numericVariable }))
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.grouping
						)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.noGrouping
						)}
						items={categorySelectItems}
						disabled={!categorySelectItems.length}
						value={selectItemsMap[values.groupingVariable ?? ''] ?? null}
						onValueSelected={groupingVariable =>
							setValues(state => ({
								...state,
								groupingVariable: groupingVariable ?? undefined
							}))
						}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting
				analysis={analysis}
				isEnabled={variables.groupingVariable}
				hasHistogram
			/>
		</ConfigContainer>
	);
}
