import { SegmentComponent } from 'app/SegmentAnalytics';

// List of implemented events for CORE functiolities
enum AppEventTrackingTypes {
	signup = 'app-signup',
	login = 'app-login',
	logout = 'app-logout',
	autoLogoutTabClosed = 'app-auto-logout-tab-closed',
	autoLogout = 'app-auto-logout',
	createProject = 'app-create-project',
	createUserByEmailInvite = 'app-create-user-by-email-invite',
	trackProjectCollaborators = 'app-track-project-collaborators'
}

// List of implemented events for MODULES (payment) functionalities
enum PaymentEventTrackingTypes {
	login = 'payment-login',
	upgradeSubscription = 'payment-upgrade-subscription',
	upgradeSubscriptionFromFreeToPaid = 'payment-upgrade-subscription-from-free-to-paid',
	downgradeSubscription = 'payment-downgrade-subscription',
	downgradeSubscriptionFromPaidToFree = 'payment-downgrade-subscription-from-paid-to-free',
	payMonthly = 'payment-pay-monthly',
	subscriptionOwnerUserCreate = 'payment-subscription-owner-user-create'
}

// Here shall be declared all custom/optional attributes that need to be sent to segment through payload
type TrackPayload = {
	eventName: AppEventTrackingTypes | PaymentEventTrackingTypes;
	eventSpecifics?: {
		payMonthly?: boolean;
		sameOrganizationInvitation?: boolean;
		projectTracking?: {
			projectWithDPA?: boolean;
			collaborators?: {
				projectCollaboratorsSameOrg?: number;
				projectCollaboratorsDifferentOrg?: number;
			};
		};
	};
};

export function useEventTracker() {
	function trackEvent(payload: TrackPayload) {
		const { eventName, eventSpecifics } = payload;

		const trackingPayload = {
			...(eventName === PaymentEventTrackingTypes.payMonthly && {
				payMonthly: eventSpecifics?.payMonthly
			}),
			...(eventName === PaymentEventTrackingTypes.subscriptionOwnerUserCreate && {
				sameOrganizationInvitation: eventSpecifics?.sameOrganizationInvitation
			}),
			...(eventName === AppEventTrackingTypes.createProject && {
				projectTracking: eventSpecifics?.projectTracking,
				projectWithDPA: eventSpecifics?.projectTracking?.projectWithDPA
			}),
			...(eventName === AppEventTrackingTypes.createUserByEmailInvite && {
				sameOrganizationInvitation: eventSpecifics?.sameOrganizationInvitation
			}),
			...(eventName === AppEventTrackingTypes.trackProjectCollaborators && {
				projectCollaboratorsSameOrg:
					eventSpecifics?.projectTracking?.collaborators?.projectCollaboratorsSameOrg,
				projectCollaboratorsDifferentOrg:
					eventSpecifics?.projectTracking?.collaborators?.projectCollaboratorsDifferentOrg
			})
		};

		// actual call for event tracking

		SegmentComponent?.track(eventName, trackingPayload, {
			context: { ip: '0.0.0.0' }
		});
	}

	// Export tracking event enums aboce through trackingEventTypes object
	const trackingEventTypes = {
		AppEventTrackingTypes,
		PaymentEventTrackingTypes
	};

	return { trackEvent, trackingEventTypes };
}
