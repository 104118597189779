import { Colors } from 'environment';
import type { BooleanMap, GenericMap, NumberMap, ScalesLabels, StringMap } from 'types/index';
import { CHART_COLORS, DEFAULT_MAX_LABELS } from 'consts';

import {
	CorrelationsResults,
	CrosstabRow,
	CrosstabRowDetails,
	DensityPlotResults,
	GetLogisticRegressionOutput,
	KaplanMeierResults,
	LinearRegressionResultsData,
	NumberPlotXYResultsData,
	PlotNumericBoxplotGrouped,
	PlotNumericBoxplotGroupedValue,
	PlotNumericBoxplotResults,
	PlotNumericBoxplotSingle,
	PlotNumericColumnsGrouped,
	PlotNumericColumnsGroupedValue,
	PlotNumericColumnsResults,
	PlotNumericColumnsSingle,
	PlotNumericScatterValue,
	AnalysisStatisticAggregationType,
	TimeCourseGroupedValuesV1,
	TimeCourseGroupedValuesV2,
	TimeCourseSingleV2,
	TimeCourseResultsV1,
	TimeCourseSingleV1,
	CorrelationsStatisticsValueResults,
	PlotNumericColumnsV2,
	PlotNumericColumnV2,
	PlotNumericColumnValueV2,
	PlotNumericBoxPlotsV2,
	PlotNumericScatterV2,
	CrosstabResultsDataV2,
	KaplanMeierResultsDataV2,
	LogisticRegressionResultsDataV2,
	TimeCourseResultsDataV2,
	FrequenciesDecodedResultsV2
} from 'api/data/analyses';
import { AlignOptions, AnalysisErrorBarType, LineOptions } from 'api/data/analyses';
import { ZingChartTypes } from 'types/charts';
import { isEmpty } from 'lodash';
import { pSBC } from 'helpers/pSBC';
import { useTranslation } from 'hooks/store';
import { stringToMaxChars } from 'api/utils/helpers';
import type { VariablesMap } from 'store/data/variables';
import {
	computeLabels,
	computeLegend,
	computeScalesLabels,
	getChartOptions,
	getMaxAndMinInArray,
	getScaleRange,
	toCeil
} from './computeData';
import { VariableType } from 'types/data/variables/constants';

interface PlotNumericColumnsChartSeries {
	values: [string, number][];
	errors: ((number | null)[] | null)[];
	backgroundColor: string;
	'data-error-absolute-plus'?: (number | string)[];
	'data-error-absolute-minus'?: (number | string)[];
	hoverState: { backgroundColor: string | null };
	highlightState?: { backgroundColor: string | null };
	tooltip: any;
	visible?: boolean;
}
interface PlotNumericBoxPlotSeries {
	dataBox: number[][];
	backgroundColor: string;
	borderColor: string;
	tooltip: any;
}

interface CrosstabBarChartSerie {
	values: number[];
	xAxisValues: string[];
	backgroundColor: string;
	text: string;
	hoverState: GenericMap<string | null>;
	highlightState: GenericMap<string | null>;
	visible: boolean;
}

interface CrosstabSunburstChartSerie {
	id: string;
	text: string;
	parent: string;
	value?: number;
}

interface RowMap {
	[index: number]: {
		[category: string]: CrosstabRowDetails;
	};
}
interface FrequenciesBarChartSerie {
	values: number[];
}

type ScaleValues = GenericMap<{ visible: boolean; offset: number }>;
interface NumberPlotXYSeries {
	// values can be numbers (for number variables) or strings (for date variables)
	// and XY chart needs the values as a 2d Array
	values: [string | number | Date, string | number][] | undefined;
	type?: ZingChartTypes;
	text?: string;
	itemsOerlap?: boolean;
	lineColor?: string;
	decimals?: number;
	highlightState?: { lineColor: string | null };
	marker: { backgroundColor: string | null };
	visible: boolean;
}

interface VariableAxesValues {
	variableType: VariableType;
	label: string;
	values: (string | number)[];
}

interface TimeCourseChartSeries {
	values: (number | null)[] | (number | null)[][];
	errors: ((number | null)[] | null)[];
	lineColor: string;
	marker: { backgroundColor: string | null };
	tooltip: any;
	visible?: boolean;
	'data-error-absolute-plus'?: number[];
	'data-error-absolute-minus'?: number[];
}

enum TimeWindow {
	Year = 'year',
	Month = 'month',
	Week = 'week',
	Day = 'day',
	Hour = 'Hour',
	Minute = 'minute',
	second = 'second'
}
export const computeCorrelationsScatterChartV1Data = (
	dataset: CorrelationsResults,
	linearRegression: CorrelationsStatisticsValueResults,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	legendHeader?: string
) => {
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	const [labelX, labelY] = computeScalesLabels(scalesLabels);
	const [legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

	const scatterSeries = dataset.map(({ group, xValues, yValues }, index) => {
		const values = xValues.map((x, i) => [Number(x), Number(yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...xValues);
		yValuesSeries.push(...yValues);

		return {
			type: ZingChartTypes.Scatter,
			tooltip: {
				text:
					dataset.length > 1
						? '%t: %scale-key-value, %node-value'
						: '%scale-key-value, %node-value'
			},
			values,
			text: group,
			size: 20,
			marker: {
				backgroundColor: CHART_COLORS[index],
				borderColor: Colors.white,
				borderWidth: 2
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const linearSeries: {}[] = [];

	if (dataset.length > 0 && linearRegression.length > 0) {
		linearRegression.forEach(({ slope, intercept }, index) => {
			if (slope && intercept) {
				const values = dataset[index]
					? dataset[index].xValues.map(val => {
							return [Number(val), Number(val) * Number(slope) + Number(intercept)];
					  })
					: [];
				const tooltipText =
					dataset.length === 1
						? 'Linear Regression'
						: `Linear Regression ${dataset[index] ? dataset[index].group : ''}`;

				const linearResult = {
					id: `linear-${index}`,
					type: ZingChartTypes.Line,
					marker: {
						visible: false
					},
					legendItem: {
						visible: false
					},
					lineColor: CHART_COLORS[index],
					lineWidth: 6,
					shadow: true,
					shadowColor: Colors.gray.dark,
					shadowDistance: 1,
					zIndex: 2,
					tooltip: {
						text: tooltipText
					},
					animation: null,
					values
				};
				linearSeries.push(linearResult);
			}
		});
	}

	const series = isEmpty(linearSeries) ? scatterSeries : [...scatterSeries, ...linearSeries];

	const { max: xMaxValue, min: xMinValue } = getScaleRange(xValuesSeries);
	const { max: yMaxValue, min: yMinValue } = getScaleRange(yValuesSeries);

	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const options = {
		plot: {
			marker: {
				size: 8,
				borderAlpha: 0.5
			},
			maxTrackers
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && dataset.length > 1,
			marker: {
				type: 'circle'
			}
		},
		scaleX: {
			label: labelX,
			minValue: xMinValue,
			maxValue: xMaxValue,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined,
			exponent:
				Math.abs(xMaxValue) > MAX_NUMBER_FORMATTING || Math.abs(xMinValue) < 0.00001
					? true
					: false
		},
		scaleY: {
			label: labelY,
			minValue: yMinValue,
			maxValue: yMaxValue,
			offset: 0,
			exponent: yMaxValue >= MAX_NUMBER_FORMATTING ? true : false
		},
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		}
	};

	return { series, options, disableAnimations };
};

//  computeData  for CorrelationsScattter chart
export const computeCorrelationsScatterChartData = (
	dataset: CorrelationsResults,
	linearRegression: LinearRegressionResultsData,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	legendHeader?: string
) => {
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	const [labelX, labelY] = computeScalesLabels(scalesLabels);
	const [legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

	const scatterSeries = dataset.map(({ group, xValues, yValues }, index) => {
		const values = xValues.map((x, i) => [Number(x), Number(yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...xValues);
		yValuesSeries.push(...yValues);

		return {
			type: ZingChartTypes.Scatter,
			tooltip: {
				text:
					dataset.length > 1
						? '%t: %scale-key-value, %node-value'
						: '%scale-key-value, %node-value'
			},
			values,
			text: group,
			size: 20,
			marker: {
				backgroundColor: CHART_COLORS[index],
				borderColor: Colors.white,
				borderWidth: 2
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const linearSeries: {}[] = [];

	if (dataset.length > 0 && linearRegression.length > 0) {
		linearRegression.forEach(({ coefficients }, index) => {
			if (!coefficients?.length) return;
			const [{ estimate: intercept }, { estimate: slope }] = coefficients;

			if (slope && intercept) {
				const values = dataset[index]
					? dataset[index].xValues.map(val => {
							return [Number(val), Number(val) * Number(slope) + Number(intercept)];
					  })
					: [];
				const tooltipText =
					dataset.length === 1
						? 'Linear Regression'
						: `Linear Regression ${dataset[index] ? dataset[index].group : ''}`;

				const linearResult = {
					id: `linear-${index}`,
					type: ZingChartTypes.Line,
					marker: {
						visible: false
					},
					legendItem: {
						visible: false
					},
					lineColor: CHART_COLORS[index],
					lineWidth: 6,
					shadow: true,
					shadowColor: Colors.gray.dark,
					shadowDistance: 1,
					zIndex: 2,
					tooltip: {
						text: tooltipText
					},
					animation: null,
					values
				};
				linearSeries.push(linearResult);
			}
		});
	}

	const series = isEmpty(linearSeries) ? scatterSeries : [...scatterSeries, ...linearSeries];

	const { max: xMaxValue, min: xMinValue } = getScaleRange(xValuesSeries);
	const { max: yMaxValue, min: yMinValue } = getScaleRange(yValuesSeries);

	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const COMMON_OPTS = getChartOptions({
		activeColumn: activeColumns,
		fullscreen,
		scalesLabels,
		scaleXValues: xValuesSeries.map(label => label.toString()),
		yMaxValue,
		yMinValue
	});

	const options = {
		plot: {
			marker: {
				size: 8,
				borderAlpha: 0.5
			},
			maxTrackers
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && dataset.length > 1,
			marker: {
				type: 'circle'
			}
		},
		scaleX: {
			exponent: COMMON_OPTS.scaleX.exponent,
			label: labelX,
			minValue: xMinValue,
			maxValue: xMaxValue,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined
		},
		scaleY: { ...COMMON_OPTS.scaleY, label: labelY },
		offset: 0,
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		}
	};

	return { series, options, disableAnimations };
};

//  computeData  for CrosstabBarChart
export function computeCrosstabBarChartData(
	rows: CrosstabRow[],
	categories: string[],
	stacked: boolean,
	isLegendEnabled: boolean,
	activeColumn: number,
	fullscreen: boolean,
	scalesLabels: ScalesLabels,
	plots: BooleanMap,
	legendHeader?: string
) {
	const [legendItem, legendTooltip] = computeLegend(rows.map(row => row.label));

	const numberOfNodesToDraw: number[] = [];
	const yValues: number[] = [];
	const series = rows.map((row, index) => {
		const serie: CrosstabBarChartSerie = {
			values: [],
			xAxisValues: [],
			backgroundColor: CHART_COLORS[index],
			text: row.label,
			hoverState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			highlightState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};

		row.valueDetails.forEach(valueDetail => {
			const { groupVariableValue, N } = valueDetail;

			yValues.push(Number(N));
			serie.xAxisValues.push(groupVariableValue);
			serie.values.push(Number(N));
		});

		numberOfNodesToDraw.push(serie.values.length);

		return serie;
	});

	const disableAnimations = numberOfNodesToDraw.reduce((a, b) => a + b, 0) > 80;

	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValues);

	const nrOfValuesPerSeries = series[0] ? series[0].values.length : 0;

	let yMaxSumValue = 0;

	for (let index = 0; index < nrOfValuesPerSeries; index++) {
		const maxSerieYValue = series.reduce((acc, serie) => acc + serie.values[index], 0);

		if (maxSerieYValue > yMaxSumValue) yMaxSumValue = maxSerieYValue;
	}

	const computedYMaxValue = stacked ? yMaxSumValue : yMaxValue;

	if (activeColumn !== 1 && !fullscreen) {
		isLegendEnabled = false;
	}
	const options = {
		...getChartOptions({
			scaleXValues: categories,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMinValue,
			yMaxValue: computedYMaxValue
		}),

		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled
		},
		plot: {
			stacked
		},

		tooltip: {
			text: '%t, %v'
		}
	};

	return { series, options, disableAnimations };
}
export function computeCrosstabBarChartDataV2(
	dataset: CrosstabResultsDataV2,
	stacked: boolean,
	isLegendEnabled: boolean,
	activeColumn: number,
	fullscreen: boolean,
	scalesLabels: ScalesLabels,
	plots: BooleanMap,
	legendHeader?: string
) {
	// remove "Total" metadata
	const rowLabels = dataset.rowLabels.slice(0, -1);
	const columnLabels = dataset.columnLabels.slice(0, -1);
	const counts = dataset.counts.slice(0, -1).reduce<number[][]>((acc, curr) => {
		acc.push(curr.slice(0, -1));
		return acc;
	}, []);

	const [legendItem, legendTooltip] = computeLegend(rowLabels);

	const numberOfNodesToDraw: number[] = [];
	const yValues = counts.flat();
	const series = rowLabels.map((label, index) => {
		const serie: CrosstabBarChartSerie = {
			values: counts[index],
			xAxisValues: columnLabels,
			backgroundColor: CHART_COLORS[index],
			text: label,
			hoverState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			highlightState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};

		numberOfNodesToDraw.push(serie.values.length);

		return serie;
	});

	const disableAnimations = numberOfNodesToDraw.reduce((a, b) => a + b, 0) > 80;
	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValues);
	const nrOfValuesPerSeries = series[0] ? series[0].values.length : 0;
	let yMaxSumValue = 0;

	for (let index = 0; index < nrOfValuesPerSeries; index++) {
		const maxSerieYValue = series.reduce((acc, serie) => acc + serie.values[index], 0);
		if (maxSerieYValue > yMaxSumValue) yMaxSumValue = maxSerieYValue;
	}

	const computedYMaxValue = stacked ? yMaxSumValue : yMaxValue;
	if (activeColumn !== 1 && !fullscreen) {
		isLegendEnabled = false;
	}
	const options = {
		...getChartOptions({
			scaleXValues: columnLabels,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMinValue,
			yMaxValue: computedYMaxValue
		}),

		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled
		},
		plot: {
			stacked
		},

		tooltip: {
			text: '%t, %v'
		}
	};

	return { series, options, disableAnimations };
}

// computeData for CrosstabSunburstChart
function buildMap(rows: CrosstabRow[]) {
	const map: RowMap = {};
	rows.forEach((row, index) => {
		map[index] = {};
		row.valueDetails.forEach(detail => (map[index][detail.groupVariableValue] = detail));
	});
	return map;
}

export function computeCrosstabSunburstChartData(rows: CrosstabRow[], categories: string[]) {
	const map = buildMap(rows);

	const series: CrosstabSunburstChartSerie[] = [];

	/*
	 * GROUPED BY ROW
	 */
	rows.forEach(row => {
		const serie: CrosstabSunburstChartSerie = {
			id: series.length.toString(),
			text: row.label,
			parent: ''
		};
		series.push(serie);
	});
	rows.forEach((_, index) => {
		categories.forEach(category => {
			const { groupVariableValue, N } = map[index][category];

			if (Number(N) !== 0) {
				const serie: CrosstabSunburstChartSerie = {
					id: series.length.toString(),
					text: groupVariableValue,
					parent: index.toString(),
					value: Number(N)
				};

				series.push(serie);
			}
		});
	});

	/*
	 * GROUPED BY COLUMN
	 */
	// categories.forEach(category => {
	// 	const sunburstChartItem: Serie = {
	// 		id: series.length.toString(),
	// 		text: category,
	// 		parent: ''
	// 	};

	// 	series.push(sunburstChartItem);
	// });
	// rows.forEach((row, index) => {
	// 	categories.forEach((category, i) => {
	// 		const { N } = map[index][category];

	// 		if (Number(N) !== 0) {
	// 			const sunburstChartItem: Serie = {
	// 				id: series.length.toString(),
	// 				text: row.label,
	// 				parent: i.toString(),
	// 				value: Number(N)
	// 			};

	// 			series.push(sunburstChartItem);
	// 		}
	// 	});
	// });

	const disableAnimations = series.length > 80;

	const options = {
		legend: {
			visible: false
		},
		options: {
			palette: CHART_COLORS,
			slice: 0,
			space: 0
		},
		plot: {
			valueBox: {
				text: '%t',
				visible: true
			},
			tooltip: {
				text: '%t, %v'
			}
		},
		plotarea: {
			width: '100%',
			height: '100%'
		}
	};

	return { series, options, disableAnimations };
}

export function computeCrosstabSunburstChartDataV2(dataset: CrosstabResultsDataV2) {
	// const { columnLabels: columns, rowLabels: rows, counts } = dataset;

	const columns = dataset.columnLabels.slice(0, -1);
	const rows = dataset.rowLabels.slice(0, -1);
	const counts = dataset.counts;

	const series: CrosstabSunburstChartSerie[] = [];

	/*
	 * GROUPED BY ROW
	 */
	rows.forEach(row => {
		const serie: CrosstabSunburstChartSerie = {
			id: series.length.toString(),
			text: row,
			parent: ''
		};
		series.push(serie);
	});

	rows.forEach((_, index1) => {
		columns.forEach((column, index2) => {
			const count = counts[index1][index2];

			if (count !== 0) {
				const serie: CrosstabSunburstChartSerie = {
					id: series.length.toString(),
					text: column,
					parent: index1.toString(),
					value: count
				};

				series.push(serie);
			}
		});
	});

	/*
	 * GROUPED BY COLUMN
	 */
	// categories.forEach(category => {
	// 	const sunburstChartItem: Serie = {
	// 		id: series.length.toString(),
	// 		text: category,
	// 		parent: ''
	// 	};

	// 	series.push(sunburstChartItem);
	// });
	// rows.forEach((row, index) => {
	// 	categories.forEach((category, i) => {
	// 		const { N } = map[index][category];

	// 		if (Number(N) !== 0) {
	// 			const sunburstChartItem: Serie = {
	// 				id: series.length.toString(),
	// 				text: row.label,
	// 				parent: i.toString(),
	// 				value: Number(N)
	// 			};

	// 			series.push(sunburstChartItem);
	// 		}
	// 	});
	// });

	const disableAnimations = series.length > 80;

	const options = {
		legend: {
			visible: false
		},
		options: {
			palette: CHART_COLORS,
			slice: 0,
			space: 0
		},
		plot: {
			valueBox: {
				text: '%t',
				visible: true
			},
			tooltip: {
				text: '%t, %v'
			}
		},
		plotarea: {
			width: '100%',
			height: '100%'
		}
	};

	return { series, options, disableAnimations };
}

// computeData for DensityPlotChart
export function computeDensityPlotChartData({
	data,
	isLegendEnabled,
	isHistogramEnabled,
	legendHeader,
	plots,
	scalesLabels
}: {
	data: DensityPlotResults;
	isLegendEnabled: boolean;
	isHistogramEnabled: boolean;
	scalesLabels: ScalesLabels;
	plots: BooleanMap;
	legendHeader?: string;
}) {
	const grouped = data.length !== 1 || data[0].groupingyValue !== 'default';

	const histogramSeries: {}[] = [];
	const densitySeries: {}[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];

	if (grouped) {
		[legendItem, legendTooltip] = computeLegend(data.map(dataset => dataset.groupingyValue));
	}

	data.forEach((object, index) => {
		const area = {
			type: ZingChartTypes.Area,
			text: object.groupingyValue,
			aspect: 'stepped',
			lineWidth: 0,
			lineColor: CHART_COLORS[index],
			marker: {
				visible: false
			},
			tooltip: {
				visible: false
			},
			guideLabel: {
				text: grouped ? undefined : '%v'
			},
			alphaArea: 0.5,

			backgroundColor: CHART_COLORS[index],

			values:
				object.histogramBins?.flatMap((value, i) => {
					xValuesSeries.push(value);
					if (i == 0) {
						yValuesSeries.push(object.histogramColumns[i]);
						return [[value, object.histogramColumns[i]]];
					} else if (i == object.histogramBins.length - 1) {
						yValuesSeries.push(object.histogramColumns[i - 1]);
						return [[value, object.histogramColumns[i - 1]]];
					} else {
						yValuesSeries.push(object.histogramColumns[i]);
						yValuesSeries.push(object.histogramColumns[i - 1]);
						return [
							[value, object.histogramColumns[i - 1]],
							[value, object.histogramColumns[i]]
						];
					}
				}) ?? [],

			visible: plots && plots[index] !== undefined ? plots[index] : true
		};

		const line = {
			id: !grouped ? `area-${index}` : `line-${index}`,
			type: grouped ? ZingChartTypes.Line : ZingChartTypes.Area,
			...(!grouped ? { backgroundColor: CHART_COLORS[index] } : {}),
			lineColor: CHART_COLORS[index],
			aspect: 'spline',
			lineWidth: 3,
			marker: {
				visible: false
			},
			tooltip: {
				visible: false
			},
			text: object.groupingyValue,
			legendItem: {
				visible: grouped && !isHistogramEnabled ? true : false
			},
			values: object.densityPlot.map((y, idx) => {
				xValuesSeries.push(object.values[idx]);
				yValuesSeries.push(y);
				return [object.values[idx], y];
			}),
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};

		histogramSeries.push(area);
		densitySeries.push(line);
	});

	const { max: xMaxValue, min: xMinValue } = getScaleRange(xValuesSeries);
	const { max: yMaxValue, min: yMinValue } = getScaleRange(yValuesSeries);

	const isxMinValueScientificNumber = xMinValue.toString().includes('e');
	const isYMinValueScientificNumber = yMinValue.toString().includes('e');

	const shouldFormatXAxis =
		(isxMinValueScientificNumber && xMinValue < MIN_NUMBER_FORMATTING) ||
		xMaxValue > MAX_NUMBER_FORMATTING;
	const shouldFormatYAxis =
		(isYMinValueScientificNumber && yMinValue < MIN_NUMBER_FORMATTING) ||
		yMaxValue > MAX_NUMBER_FORMATTING;

	const series = isHistogramEnabled ? [...histogramSeries, ...densitySeries] : densitySeries;

	const options = {
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: grouped && isLegendEnabled
		},
		crosshairX: {
			lineStyle: 'dashed',
			lineColor: Colors.gray.lighter,
			plotLabel: {
				borderRadius: 5,
				borderWidth: 1,
				borderColor: Colors.gray.lighter,
				padding: 10,
				fontWeight: 'bold'
			},
			scaleLabel: {
				visible: false
			}
		},
		scaleY: {
			label: labelY,
			item: {
				visible: true
			},
			tick: {
				visible: false
			},
			...(shouldFormatYAxis && { exponent: true })
		},
		scaleX: {
			label: labelX,
			guide: {
				lineColor: Colors.white,
				lineWidth: 2,
				lineStyle: 'solid'
			},
			offset: 50,
			...(shouldFormatXAxis && { exponent: true })
		}
	};

	return { series, options };
}

// computeData for FrequenciesBarChart
export function useComputeFrequenciesBarChartData(
	data: NumberMap,
	scalesLabels: ScalesLabels,
	activeColumn: number,
	fullscreen: boolean
) {
	const { translate } = useTranslation();

	const missingKey = translate(({ analysis }) => analysis.analyses.frequencies.bar.missing);

	if (data['null-category-value'] !== undefined) {
		data[missingKey] = data['null-category-value'];
		delete data['null-category-value'];
	}
	const categories = Object.keys(data);
	const yValues: number[] = [];

	Object.values(data).forEach(number => {
		yValues.push(number);
	});

	const series: FrequenciesBarChartSerie[] = [
		{ values: categories.map(category => data[category]) }
	];

	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValues);

	const options = {
		...getChartOptions({
			scaleXValues: categories,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMaxValue,
			yMinValue
		}),
		legend: {
			visible: false
		},
		plot: {
			backgroundColor: CHART_COLORS[0]
			// TOOLTIP: zingchart inline functions do not work, need to find a workaround
			// https://stackoverflow.com/questions/37023113/zingchart-passing-a-function-to-the-tooltip
			// tooltip: {
			// 	text: (val: number) => {
			// 		const percent = ((val / sum) * 100).toFixed(1);

			// 		return `${val} - ${percent}%`;
			// 	}
			// }
		},
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		},
		tooltip: {
			text: '%kt, %v',
			backgroundColor: CHART_COLORS[0]
		}
	};

	return { series, options };
}

export function useComputeFrequenciesBarChartDataV2(
	data: FrequenciesDecodedResultsV2,
	scalesLabels: ScalesLabels,
	activeColumn: number,
	fullscreen: boolean
) {
	const { translate } = useTranslation();

	const { dict, keys } = data;

	const missingKey = translate(
		({ analysis }) => analysis.analyses.frequencies.bar.missing
	) as string;

	if (dict['null-category-value'] !== undefined) {
		dict[missingKey] = dict['null-category-value'];
		delete dict['null-category-value'];
	}

	const categories = keys.reduce<string[]>(
		(acc, curr) => [...acc, curr === 'null-category-value' ? missingKey : curr],
		[] as string[]
	);

	const yValues: number[] = [];

	Object.values(dict).forEach(number => {
		yValues.push(number);
	});

	const series: FrequenciesBarChartSerie[] = [
		{ values: categories.map(category => dict[category]) }
	];

	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValues);

	const options = {
		...getChartOptions({
			scaleXValues: categories,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMaxValue,
			yMinValue
		}),
		legend: {
			visible: false
		},
		plot: {
			backgroundColor: CHART_COLORS[0]
			// TOOLTIP: zingchart inline functions do not work, need to find a workaround
			// https://stackoverflow.com/questions/37023113/zingchart-passing-a-function-to-the-tooltip
			// tooltip: {
			// 	text: (val: number) => {
			// 		const percent = ((val / sum) * 100).toFixed(1);

			// 		return `${val} - ${percent}%`;
			// 	}
			// }
		},
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		},
		tooltip: {
			text: '%kt, %v',
			backgroundColor: CHART_COLORS[0]
		}
	};

	return { series, options };
}

// computeData for FrequenciesPieChart

export function useComputeFrequenciesPieChartData(
	data: NumberMap,
	isLegendEnabled: boolean,
	legendHeader: string,
	activeColumn: number,
	plots: BooleanMap,
	isForExport?: boolean,
	showLabels?: boolean,
	fullscreen?: string
) {
	const { translate } = useTranslation();

	const keys = Object.keys(data);
	keys[keys.indexOf('null-category-value')] = translate(
		({ analysis }) => analysis.analyses.frequencies.pie.missing
	);
	let enableTooltip = false;

	const series = Object.values(data).map((val, index) => {
		if (keys[index].length > 15) enableTooltip = true;
		return {
			text: keys[index],
			description: isForExport ? keys[index] : stringToMaxChars(keys[index]),
			values: [val],
			backgroundColor: CHART_COLORS[index],
			hoverState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			highlightState: {
				backgroundColor: pSBC(-0.2, CHART_COLORS[index])
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	if (activeColumn !== 1 && !fullscreen) {
		isLegendEnabled = false;
	}

	const options = {
		plot: {
			valueBox: {
				placement: 'out',
				text: '%plot-description: %npv%',
				fontFamily: 'Roboto Flex',
				visible: showLabels
			},
			tooltip: {
				text: '%t: %node-value'
			}
		},
		legend: {
			toggleAction: 'none',
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled,
			item: {
				text: '%plot-description'
			},
			tooltip: {
				visible: enableTooltip,
				text: '%plot-text'
			},
			marker: {
				type: 'circle'
			}
		}
	};

	return { series, options };
}

export function computeKaplanChartData(
	name: string,
	dataset: KaplanMeierResults,
	confidenceIntervals: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	legendHeader?: string
) {
	const keys = Object.keys(dataset);
	const xMaxValue: number[] = [];
	const xMinValue: number[] = [];
	const numberOfNodesToDraw: number[] = [];

	const lineSeries: {}[] = [];
	const rangeSeries: {}[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	if (dataset.single) {
		const { estimate, timeline, lowerCI, upperCI } = dataset.single;
		xMaxValue.push(Math.max(...timeline));

		xMinValue.push(Math.min(...timeline));
		numberOfNodesToDraw.push(estimate.length);

		const rangeValues: (number | number[])[][] = [];
		const lineValues = estimate
			.filter(value => value !== -1)
			.flatMap((value, i) => {
				rangeValues[i] = [timeline[i], [lowerCI[i], upperCI[i]]];
				if (value >= 0) {
					if (i == 0) {
						return [[timeline[i], value]];
					} else {
						return [
							[timeline[i], estimate[i - 1]],
							[timeline[i], value]
						];
					}
				}

				return null;
			});

		lineSeries.push({
			type: ZingChartTypes.Line,
			text: name,
			values: lineValues,
			guideLabel: {
				text: '%v'
			},
			visible: plots && plots[0] !== undefined ? plots[0] : true
		});

		rangeSeries.push({
			type: ZingChartTypes.Range,
			lineWidth: 0,
			aspect: 'segmented',
			backgroundColor: CHART_COLORS[0],
			alphaArea: 0.2,
			legendItem: {
				visible: false
			},
			tooltip: {
				visible: false
			},
			guideLabel: {
				visible: false
			},
			values: rangeValues
		});
	} else {
		[legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

		keys.forEach((key, index) => {
			const { estimate, timeline, lowerCI, upperCI } = dataset[key];
			xMaxValue.push(Math.max(...timeline));
			xMinValue.push(Math.min(...timeline));
			numberOfNodesToDraw.push(estimate.length);

			const rangeValues: (number | number[])[][] = [];
			const lineValues = estimate
				.filter(value => value !== -1)
				.flatMap((value, i) => {
					rangeValues[i] = [timeline[i], [lowerCI[i], upperCI[i]]];
					if (value >= 0) {
						return [[timeline[i], value]];
					}

					return null;
				});

			lineSeries.push({
				type: ZingChartTypes.Line,
				text: key,
				lineColor: CHART_COLORS[index],
				highlightState: {
					lineColor: pSBC(-0.2, CHART_COLORS[index])
				},
				values: lineValues,
				visible: plots && plots[index] !== undefined ? plots[index] : true
			});

			rangeSeries.push({
				id: `range-${index}`,
				type: ZingChartTypes.Range,
				values: rangeValues,
				lineWidth: 0,
				alphaArea: 0.2,
				backgroundColor: CHART_COLORS[index],
				legendItem: {
					visible: false
				},
				tooltip: {
					visible: false
				},
				guideLabel: {
					visible: false
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			});
		});
	}

	const disableAnimations = numberOfNodesToDraw.reduce((a, b) => a + b, 0) > 100;

	const series = confidenceIntervals ? [...lineSeries, ...rangeSeries] : lineSeries;

	const maxValue = toCeil(Math.max(...xMaxValue));

	const options = {
		plot: {
			aspect: 'stepped',
			marker: {
				visible: false
			},
			decimals: 3
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: dataset.single ? false : isLegendEnabled,
			marker: {
				type: 'circle'
			}
		},
		tooltip: {
			visible: false
		},
		scaleX: {
			label: labelX,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined,
			itemsOverlap: false,
			exponent:
				maxValue > MAX_NUMBER_FORMATTING || (xMinValue[0] < 0.00001 && xMinValue[0] !== 0)
					? true
					: false
		},
		scaleY: {
			label: labelY,
			values: '0:1:0.1'
		},
		crosshairX: {
			lineStyle: 'dashed',
			lineColor: Colors.gray.lighter,
			plotLabel: {
				borderRadius: 5,
				borderWidth: 1,
				borderColor: Colors.gray.lighter,
				padding: 10,
				fontWeight: 'bold'
			},
			scaleLabel: {
				visible: false
			}
		}
	};

	return { series, options, disableAnimations };
}

export function computeKaplanChartDataV2(
	name: string,
	dataset: KaplanMeierResultsDataV2,
	confidenceIntervals: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	showCensorTicks?: boolean,
	legendHeader?: string
) {
	const keys = Object.keys(dataset);
	const xMaxValue: number[] = [];
	const xMinValue: number[] = [];
	const numberOfNodesToDraw: number[] = [];

	const lineSeries: {}[] = [];
	const rangeSeries: {}[] = [];
	const censoredSeries: {}[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	if (dataset.single) {
		const { estimate, timeline, lowerCI, upperCI, numberCensored } = dataset.single;
		xMaxValue.push(Math.max(...timeline));

		xMinValue.push(Math.min(...timeline));
		numberOfNodesToDraw.push(estimate.length);

		const rangeValues: (number | number[])[][] = [];
		const lineValues = estimate
			.filter(value => value !== -1)
			.flatMap((value, i) => {
				if (i == 0) {
					rangeValues.push([timeline[i], [lowerCI[i], upperCI[i]]]);
					return [[timeline[i], value]];
				} else {
					rangeValues.push([timeline[i], [lowerCI[i - 1], upperCI[i - 1]]]);
					rangeValues.push([timeline[i], [lowerCI[i], upperCI[i]]]);
					return [
						[timeline[i], estimate[i - 1]],
						[timeline[i], value]
					];
				}
			});

		lineSeries.push({
			type: ZingChartTypes.Line,
			text: name,
			values: lineValues,
			visible: plots && plots[0] !== undefined ? plots[0] : true,
			'data-formatted-x-values': lineValues
				.map(([x]) => x)
				.map(value => Number(value).toFixed(2)),
			'data-formatted-y-values': lineValues
				.map(([_, y]) => y)
				.map(value => Number(value).toFixed(2))
		});

		rangeSeries.push({
			type: ZingChartTypes.Range,
			lineWidth: 0,
			aspect: 'segmented',
			backgroundColor: CHART_COLORS[0],
			alphaArea: 0.2,
			legendItem: {
				visible: false
			},
			tooltip: {
				visible: false
			},
			guideLabel: {
				visible: false
			},
			values: rangeValues
		});

		const offsetValues: number[] = [];

		censoredSeries.push({
			type: ZingChartTypes.BarVertical,
			values: numberCensored.map((censored, idx) => {
				const x = dataset.single.timeline[idx];
				const y = !censored ? null : 0.0375;
				const offsetY = dataset.single.estimate[idx] - 0.01725;
				offsetValues.push(offsetY);

				return [x, y];
			}),
			barWidth: 2,
			alpha: 0.75,
			backgroundColor: CHART_COLORS[0],
			offsetValues,
			legendItem: {
				visible: false
			},
			tooltip: {
				visible: false
			},
			guideLabel: {
				visible: false
			},
			visible: plots && plots[0] !== undefined ? plots[0] : true
		});
	} else {
		[legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

		keys.forEach((key, index) => {
			const { estimate, timeline, lowerCI, upperCI, numberCensored } = dataset[key];
			xMaxValue.push(Math.max(...timeline));
			xMinValue.push(Math.min(...timeline));
			numberOfNodesToDraw.push(estimate.length);

			const rangeValues: (number | number[])[][] = [];
			const lineValues = estimate
				.filter(value => value !== -1)
				.flatMap((value, i) => {
					if (i == 0) {
						rangeValues.push([timeline[i], [lowerCI[i], upperCI[i]]]);
						return [[timeline[i], value]];
					} else {
						rangeValues.push([timeline[i], [lowerCI[i - 1], upperCI[i - 1]]]);
						rangeValues.push([timeline[i], [lowerCI[i], upperCI[i]]]);
						return [
							[timeline[i], estimate[i - 1]],
							[timeline[i], value]
						];
					}
				});

			lineSeries.push({
				type: ZingChartTypes.Line,
				text: key,
				lineColor: CHART_COLORS[index],
				highlightState: {
					lineColor: pSBC(-0.2, CHART_COLORS[index])
				},
				values: lineValues,
				'data-formatted-x-values': lineValues
					.map(([x]) => x)
					.map(value => Number(value).toFixed(2)),
				'data-formatted-y-values': lineValues
					.map(([_, y]) => y)
					.map(value => Number(value).toFixed(2)),
				visible: plots && plots[index] !== undefined ? plots[index] : true,
				dataXValue: lineValues.map(([x, _]) => x)
			});

			rangeSeries.push({
				id: `range-${index}`,
				type: ZingChartTypes.Range,
				values: rangeValues,
				lineWidth: 0,
				alphaArea: 0.2,
				backgroundColor: CHART_COLORS[index],
				legendItem: {
					visible: false
				},
				tooltip: {
					visible: false
				},
				guideLabel: {
					visible: false
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			});

			const offsetValues: number[] = [];

			censoredSeries.push({
				id: `censored-${index}`,
				type: ZingChartTypes.Bar,
				values: numberCensored.map((censored, idx) => {
					const x = timeline[idx];
					const y = !censored ? null : 0.0375;
					const offsetY = estimate[idx] - 0.01725;
					offsetValues.push(offsetY);

					return [x, y];
				}),
				barWidth: 2,
				backgroundColor: CHART_COLORS[index],
				alpha: 0.75,
				offsetValues,
				legendItem: {
					visible: false
				},
				tooltip: {
					visible: false
				},
				guideLabel: {
					visible: false
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			});
		});
	}

	const disableAnimations = numberOfNodesToDraw.reduce((a, b) => a + b, 0) > 100;

	const series = [...lineSeries];
	if (confidenceIntervals) {
		series.push(...rangeSeries);
	}
	if (showCensorTicks) {
		series.push(...censoredSeries);
	}

	const maxValue = toCeil(Math.max(...xMaxValue));

	const options = {
		plot: {
			aspect: 'stepped',
			marker: {
				visible: false
			},
			decimals: 3,
			tooltip: {
				text: '%data-formatted-y-values'
			}
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: dataset.single ? false : isLegendEnabled,
			marker: {
				type: 'circle'
			}
		},
		scaleX: {
			label: labelX,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined,
			itemsOverlap: false,
			exponent:
				maxValue > MAX_NUMBER_FORMATTING || (xMinValue[0] < 0.00001 && xMinValue[0] !== 0)
					? true
					: false
		},
		scaleY: {
			label: labelY,
			minValue: 0,
			maxValue: 1.1,
			showLabels: ['1.0', '0.8', '0.6', '0.4', '0.2', '0.0']
		}
	};

	return { series, options, disableAnimations };
}

// computeData for LogisticRegressionChart
export function computeLogisticRegressionData({
	data,
	isLegendEnabled,
	legendHeader,
	plots,
	scalesLabels
}: {
	data: GetLogisticRegressionOutput;
	isLegendEnabled: boolean;
	scalesLabels: ScalesLabels;
	plots: BooleanMap;
	legendHeader?: string;
}) {
	const lineSeries: {}[] = [];
	const scatterSeries: {}[] = [];
	const scales: ScaleValues = {};
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	if (Array.isArray(data)) {
		[legendItem, legendTooltip] = computeLegend(data.map(value => value.group.groupValue));
	}

	if (!Array.isArray(data)) {
		const values = data.xValues.map((x, i) => [Number(x), Number(data.yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...data.xValues);
		yValuesSeries.push(...data.yValues);

		if (!data.error) {
			const lineValues = [];
			// get min and max values from xValues
			const min = Math.min(...data.xValues);
			const max = Math.max(...data.xValues);
			// calculate step size
			const range = max - min;
			const stepSize = range / 1000; // we need at most 1000 iterations to produce valid curve

			for (let x = min; x <= max; x += stepSize) {
				if (data.coefficients && data.coefficients.length) {
					lineValues.push([
						x,
						1 /
							(1 +
								Math.exp(
									-(
										data.coefficients[0].estimate +
										data.coefficients[1].estimate * x
									)
								))
					]);
				}
			}

			lineSeries.push({
				type: ZingChartTypes.Line,
				lineColor: CHART_COLORS[0],
				aspect: 'spline',
				lineWidth: 3,
				marker: {
					visible: false
				},
				legendItem: {
					visible: false
				},
				tooltip: {
					text: 'Logistic Regression'
				},
				guideLabel: {
					visible: false
				},
				values: lineValues,
				visible: true
			});
		}
		scatterSeries.push({
			type: ZingChartTypes.Scatter,
			aspect: 'stepped',
			lineWidth: 0,
			marker: {
				backgroundColor: CHART_COLORS[0],
				borderColor: Colors.white,
				borderWidth: 2,
				type: 'circle',
				shadow: false
			},
			tooltip: {
				text: '%t: %scale-key-value, %node-value'
			},
			guideLabel: {
				text: '%v'
			},
			backgroundColor: CHART_COLORS[0],
			values,
			visible: true
		});
	}

	if (Array.isArray(data)) {
		const { min, max } = getMaxAndMinInArray(
			data.flatMap(gr => {
				if (gr.logisticRegression?.xValues) return gr.logisticRegression?.xValues;
				return [];
			})
		);
		data.forEach(({ group, logisticRegression }, index) => {
			if (logisticRegression) {
				const values = logisticRegression.xValues.map((x, i) => [
					Number(x),
					Number(logisticRegression.yValues[i])
				]);
				trackers.push(values.length);
				xValuesSeries.push(...logisticRegression.xValues);
				yValuesSeries.push(...logisticRegression.yValues);

				scatterSeries.push({
					type: ZingChartTypes.Scatter,
					aspect: 'stepped',
					lineWidth: 0,
					tooltip: {
						text:
							data.length > 1
								? '%t: %scale-key-value, %node-value'
								: '%scale-key-value, %node-value'
					},
					values,
					text: group.groupValue,
					marker: {
						backgroundColor: CHART_COLORS[index],
						borderColor: Colors.white,
						borderWidth: 2,
						type: 'circle',
						shadow: false
					},
					visible: plots && plots[index] !== undefined ? plots[index] : true
				});

				if (!logisticRegression.error) {
					const lineValues = [];
					for (let x = min; x <= max; x += 1) {
						if (logisticRegression.coefficients)
							lineValues.push([
								x,
								1 /
									(1 +
										Math.exp(
											-(
												logisticRegression.coefficients[0].estimate +
												logisticRegression.coefficients[1].estimate * x
											)
										))
							]);
					}

					const tooltipText =
						data.length === 1
							? 'Logistic Regression'
							: `Logistic Regression ${group ? group.groupValue : ''}`;

					lineSeries.push({
						type: ZingChartTypes.Line,
						lineColor: CHART_COLORS[index],
						aspect: 'spline',
						lineWidth: 3,
						marker: {
							visible: false
						},
						legendItem: {
							visible: false
						},
						tooltip: {
							text: tooltipText
						},
						guideLabel: {
							visible: false
						},
						values: lineValues,
						visible: plots && plots[index] !== undefined ? plots[index] : true
					});
				}
			}
		});
	}

	scales[`scaleX}`] = {
		visible: false,
		offset: 50
	};

	const series = [...lineSeries, ...scatterSeries];
	const maxTrackers = Math.max(...trackers);
	const { max: yMaxValue } = getScaleRange(yValuesSeries);
	const options = {
		plot: {
			marker: {
				size: 6,
				borderAlpha: 0.5
			},
			maxTrackers
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: Array.isArray(data) && isLegendEnabled,
			marker: {
				type: 'circle'
			}
		},
		scaleY: {
			label: labelY,
			item: {
				visible: true
			},
			tick: {
				visible: false
			}
		},
		scaleX: {
			label: labelX,
			item: {
				visible: true
			},
			tick: {
				visible: true
			},
			guide: {
				lineColor: Colors.white,
				lineWidth: 2,
				lineStyle: 'solid'
			},
			offset: 50
		},
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		},
		...scales
	};

	return { series, options };
}

// computeData for LogisticRegressionChart
export function computeLogisticRegressionDataV2({
	data,
	isLegendEnabled,
	legendHeader,
	plots,
	fullscreen,
	activeColumn,
	scalesLabels
}: {
	data: LogisticRegressionResultsDataV2;
	isLegendEnabled: boolean;
	scalesLabels: ScalesLabels;
	fullscreen: boolean;
	activeColumn: number;
	plots: BooleanMap;
	legendHeader?: string;
}) {
	const lineSeries: {}[] = [];
	const scatterSeries: {}[] = [];
	const scales: ScaleValues = {};
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	if (Array.isArray(data)) {
		[legendItem, legendTooltip] = computeLegend(data.map(value => value.group.groupValue));
	}

	if (!Array.isArray(data)) {
		const values = data.xValues.map((x, i) => [Number(x), Number(data.yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...data.xValues);
		yValuesSeries.push(...data.yValues);

		if (!data.error) {
			const lineValues = [];
			// get min and max values from xValues
			const min = Math.min(...data.xValues);
			const max = Math.max(...data.xValues);
			// calculate step size
			const range = max - min;
			const stepSize = range / 1000; // we need at most 1000 iterations to produce valid curve

			for (let x = min; x <= max; x += stepSize) {
				if (data.coefficients && data.coefficients.length) {
					lineValues.push([
						x,
						1 /
							(1 +
								Math.exp(
									-(
										data.coefficients[0].estimate +
										data.coefficients[1].estimate * x
									)
								))
					]);
				}
			}

			lineSeries.push({
				type: ZingChartTypes.Line,
				lineColor: CHART_COLORS[0],
				aspect: 'spline',
				lineWidth: 3,
				marker: {
					visible: false
				},
				legendItem: {
					visible: false
				},
				tooltip: {
					text: 'Logistic Regression'
				},
				guideLabel: {
					visible: false
				},
				values: lineValues,
				visible: true
			});
		}
		scatterSeries.push({
			type: ZingChartTypes.Scatter,
			aspect: 'stepped',
			lineWidth: 0,
			marker: {
				backgroundColor: CHART_COLORS[0],
				borderColor: Colors.white,
				borderWidth: 2,
				type: 'circle',
				shadow: false
			},
			tooltip: {
				text: '%t: %scale-key-value, %node-value'
			},
			guideLabel: {
				text: '%v'
			},
			backgroundColor: CHART_COLORS[0],
			values,
			visible: true
		});
	}

	if (Array.isArray(data)) {
		const { min, max } = getMaxAndMinInArray(
			data.flatMap(gr => {
				if (gr.logisticRegression?.xValues) return gr.logisticRegression?.xValues;
				return [];
			})
		);
		data.forEach(({ group, logisticRegression }, index) => {
			if (logisticRegression) {
				const values = logisticRegression.xValues.map((x, i) => [
					Number(x),
					Number(logisticRegression.yValues[i])
				]);
				trackers.push(values.length);
				xValuesSeries.push(...logisticRegression.xValues);
				yValuesSeries.push(...logisticRegression.yValues);

				scatterSeries.push({
					type: ZingChartTypes.Scatter,
					aspect: 'stepped',
					lineWidth: 0,
					tooltip: {
						text:
							data.length > 1
								? '%t: %scale-key-value, %node-value'
								: '%scale-key-value, %node-value'
					},
					values,
					text: group.groupValue,
					marker: {
						backgroundColor: CHART_COLORS[index],
						borderColor: Colors.white,
						borderWidth: 2,
						type: 'circle',
						shadow: false
					},
					visible: plots && plots[index] !== undefined ? plots[index] : true
				});

				if (!logisticRegression.error) {
					const lineValues = [];
					for (let x = min; x <= max; x += 1) {
						if (logisticRegression.coefficients)
							lineValues.push([
								x,
								1 /
									(1 +
										Math.exp(
											-(
												logisticRegression.coefficients[0].estimate +
												logisticRegression.coefficients[1].estimate * x
											)
										))
							]);
					}

					const tooltipText =
						data.length === 1
							? 'Logistic Regression'
							: `Logistic Regression ${group ? group.groupValue : ''}`;

					lineSeries.push({
						type: ZingChartTypes.Line,
						lineColor: CHART_COLORS[index],
						aspect: 'spline',
						lineWidth: 3,
						marker: {
							visible: false
						},
						legendItem: {
							visible: false
						},
						tooltip: {
							text: tooltipText
						},
						guideLabel: {
							visible: false
						},
						values: lineValues,
						visible: plots && plots[index] !== undefined ? plots[index] : true
					});
				}
			}
		});
	}

	scales[`scaleX}`] = {
		visible: false,
		offset: 50
	};

	const series = [...lineSeries, ...scatterSeries];
	const maxTrackers = Math.max(...trackers);
	const { max: yMaxValue, min: yMinValue } = getScaleRange(yValuesSeries);

	const COMMON_OPTS = getChartOptions({
		activeColumn,
		fullscreen,
		scalesLabels,
		scaleXValues: [],
		yMaxValue,
		yMinValue
	});

	const options = {
		plot: {
			marker: {
				size: 6,
				borderAlpha: 0.5
			},
			maxTrackers
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: Array.isArray(data) && isLegendEnabled,
			marker: {
				type: 'circle'
			}
		},
		scaleY: {
			label: labelY,
			item: {
				visible: true
			},
			tick: {
				visible: false
			},
			exponent: COMMON_OPTS.scaleY.exponent
		},
		scaleX: {
			label: labelX,
			item: {
				visible: true
			},
			tick: {
				visible: true
			},
			guide: {
				lineColor: Colors.white,
				lineWidth: 2,
				lineStyle: 'solid'
			},
			offset: 50
		},
		plotarea: {
			marginLeft: yMaxValue >= MAX_NUMBER_FORMATTING ? 75 : undefined,
			marginRight: yMaxValue >= MAX_NUMBER_FORMATTING ? 40 : undefined
		},
		...scales
	};

	return { series, options };
}

// computeData for  NumberPlotXYChart
export function useComputeNumberPlotXYChartData(
	dataset: NumberPlotXYResultsData | null,
	isLegendEnabled: boolean,
	variablesMap: VariablesMap,
	aggregatorVariableNameByAggregationRuleName: StringMap,

	plots: BooleanMap,
	fullscreen: boolean,
	activeColumns?: number,
	legendHeader?: string
) {
	let series: NumberPlotXYSeries[] = [];
	let options = {};

	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];

	if (!dataset) {
		return { series, options };
	}

	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	let firstVariableValues: VariableAxesValues = {
		variableType: VariableType.String,
		label: '',
		values: []
	};

	let secondVariableValues: VariableAxesValues = {
		variableType: VariableType.String,
		label: '',
		values: []
	};

	const xVariableKey = Object.keys(dataset.PlotResult.x_value)[0];
	const yVariableKey = Object.keys(dataset.PlotResult.y_value)[0];

	const isXAxisAggregationRule = Object.keys(
		aggregatorVariableNameByAggregationRuleName
	).includes(xVariableKey);

	const isYAxisAggregationRule = Object.keys(
		aggregatorVariableNameByAggregationRuleName
	).includes(yVariableKey);

	// TODO: this does NOT cater for Z values or Grouping

	if (dataset.PlotResult.x_value && dataset.PlotResult.y_value) {
		if (isXAxisAggregationRule) {
			const xVariableKeyAggregationRule =
				aggregatorVariableNameByAggregationRuleName[xVariableKey];
			const xVarKey = xVariableKeyAggregationRule;

			firstVariableValues = {
				variableType: variablesMap[xVarKey].type,
				label: variablesMap[xVarKey].label,
				values: dataset.PlotResult.x_value[xVariableKey]
			};
		} else {
			firstVariableValues = {
				variableType: variablesMap[xVariableKey].type,
				label: variablesMap[xVariableKey].label,
				values: dataset.PlotResult.x_value[xVariableKey]
			};
		}

		if (isYAxisAggregationRule) {
			const yVariableKeyAggregationRule =
				aggregatorVariableNameByAggregationRuleName[yVariableKey];
			const yVarKey = yVariableKeyAggregationRule;
			secondVariableValues = {
				variableType: variablesMap[yVarKey].type,
				label: variablesMap[yVarKey].label,
				values: dataset.PlotResult.y_value[yVariableKey]
			};
		} else {
			secondVariableValues = {
				variableType: variablesMap[yVariableKey].type,
				label: variablesMap[yVariableKey].label,
				values: dataset.PlotResult.y_value[yVariableKey]
			};
		}

		if (
			firstVariableValues.variableType == VariableType.Float ||
			firstVariableValues.variableType === VariableType.Integer
		) {
			firstVariableValues.values.forEach(value => {
				xValuesSeries.push(Number(value));
			});
			// xValuesSeries.push(...firstVariableValues.values);
		}
		if (
			secondVariableValues.variableType === VariableType.Float ||
			secondVariableValues.variableType === VariableType.Integer
		) {
			secondVariableValues.values.forEach(value => {
				yValuesSeries.push(Number(value));
			});
		}
	}

	function sortChartValues(a: any, b: any) {
		if (a[0] === b[0]) {
			return 0;
		} else {
			return a[0] < b[0] ? -1 : 1;
		}
	}

	const mergedValues: [string | number | Date, string | number][] =
		firstVariableValues.values.reduce((acc, xValue, i) => {
			let xValueDate = 0;
			if (
				[VariableType.Date, VariableType.DateTime].includes(
					firstVariableValues.variableType
				)
			) {
				if (typeof xValue === 'number') {
					xValueDate = (xValue as number) * 1000;
				} else if (typeof xValue === 'string') {
					let date: Date;
					if (!isNaN(Number(xValue))) {
						date = new Date(Number(xValue));
					} else {
						date = new Date(xValue);
					}
					xValueDate = Math.floor(date.getTime());
				}
			} else {
				xValueDate = parseFloat(xValue as string);
			}

			const yValue = parseFloat(secondVariableValues.values[i] as string);

			acc.push([xValueDate, yValue]);
			return acc;
		}, [] as [string | number | Date, string | number][]);

	const { max: xMaxValue, min: xMinValue } = getScaleRange(xValuesSeries);
	const { max: yMaxValue, min: yMinValue } = getScaleRange(yValuesSeries);

	const isxMinValueScientificNumber = xMinValue.toString().includes('e');
	const isYMinValueScientificNumber = yMinValue.toString().includes('e');

	const shouldFormatXAxis =
		(isxMinValueScientificNumber && xMinValue < MIN_NUMBER_FORMATTING) ||
		xMaxValue > MAX_NUMBER_FORMATTING;
	const shouldFormatYAxis =
		(isYMinValueScientificNumber && yMinValue < MIN_NUMBER_FORMATTING) ||
		yMaxValue > MAX_NUMBER_FORMATTING;

	series = [
		{
			type: ZingChartTypes.Line,
			text: secondVariableValues.label,
			lineColor: CHART_COLORS[0],
			itemsOerlap: true,
			highlightState: {
				lineColor: pSBC(-0.2, CHART_COLORS[0])
			},
			values: mergedValues.sort(sortChartValues),
			marker: {
				backgroundColor: CHART_COLORS[0]
			},
			visible: plots && plots[0] !== undefined ? plots[0] : true
		}
	];

	[legendItem, legendTooltip] = computeLegend([
		firstVariableValues.label,
		secondVariableValues.label
	]);

	options = {
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled
		},
		plot: {
			tooltip: {
				text: `${secondVariableValues.label}: %vv`,
				align: 'left'
			}
		},
		scaleX: {
			label: {
				text: `<br/> ${firstVariableValues.label}`,
				maxChars: 60
			},
			itemsOverlap: false,

			exponent: shouldFormatXAxis,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined,
			...([VariableType.Date, VariableType.DateTime].includes(
				firstVariableValues.variableType
			) && {
				utc: true,
				transform: {
					type: 'date',
					all: '%d/%m/%Y <br/> %H:%i',
					item: {
						visible: false
					}
				}
			})
		},
		scaleY: {
			label: {
				text: secondVariableValues.label,
				maxChars: 60
			},
			exponent: shouldFormatYAxis
		},
		plotarea: {
			marginLeft: shouldFormatYAxis ? 75 : undefined,
			marginRight: shouldFormatYAxis ? 40 : undefined
		}
	};

	return { series, options };
}

// computeData for PlotNumericScatterChart
export function computePlotNumericScatterChartData(
	data: PlotNumericScatterValue[],
	shuffledData: number[][],
	align: AlignOptions,
	line: LineOptions,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap
) {
	const categories = data.map(element => element.categorizationValue);
	const [maxItems, maxChars, fontAngle, tooltipScaleX] = computeLabels(
		categories,
		activeColumns === 1 || fullscreen
	);
	const yValues: number[] = [];
	const bulletValues: number[] = [];
	const mean: number[] = [];
	const median: number[] = [];
	const trackers: number[] = [];

	const maxNumberFormatting = 100000;
	const minNumberFormatting = 0.00001;
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	data.forEach(value => {
		bulletValues.push(0);
		mean.push(Number(value.mean));
		median.push(Number(value.median));
		yValues.push(Math.max(...value.yValues));
		trackers.push(value.yValues.length);
	});

	const scatterSeries = data.map((object, index) => {
		return {
			type: ZingChartTypes.Scatter,
			values: object.yValues.map((value, i) => {
				if (align === AlignOptions.Aligned) {
					return [index, value];
				} else {
					return [shuffledData[index][i], value];
				}
			}),
			text: object.categorizationValue,
			tooltip: {
				text: '%t, %v'
			},
			marker: {
				borderColor: Colors.white
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const bullet = {
		type: ZingChartTypes.Bullet,
		values: bulletValues,
		goals: line === LineOptions.Mean ? mean : median,
		tooltip: {
			text: '%g',
			backgroundColor: Colors.black,
			decimals: 3
		},
		goal: {
			backgroundColor: Colors.black
		}
	};

	const series = [...scatterSeries, bullet];

	const { min: yMinValue, max: yMaxValue } = getMaxAndMinInArray(yValues);
	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const options = {
		...getChartOptions({
			scaleXValues: categories,
			scalesLabels,
			activeColumn: activeColumns,
			fullscreen,
			yMaxValue,
			yMinValue,
			maxY: yMaxValue
		}),

		plot: {
			marker: {
				size: 8,
				backgroundColor: CHART_COLORS[0]
			},
			tooltip: {
				backgroundColor: CHART_COLORS[0]
			},
			barWidth: 35,
			goal: {
				border: 'none',
				height: line === LineOptions.None ? 0 : 2
			},
			maxTrackers
		},
		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		},
		scaleX: {
			label: labelX,
			values: categories,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip: tooltipScaleX
		},
		scaleY: {
			label: labelY,
			minValue: yMinValue < 0 ? yMinValue : 0,
			maxValue: yMaxValue,
			exponent:
				yMaxValue >= maxNumberFormatting || yMinValue <= minNumberFormatting ? true : false
		},
		legend: {
			visible: false
		}
	};

	return { series, options, disableAnimations };
}

export function computePlotNumericScatterChartDataV2(
	data: PlotNumericScatterV2,
	shuffledData: number[][],
	align: AlignOptions,
	line: LineOptions,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap
) {
	const categories = data.map(element => element.group1.value);
	const [maxItems, maxChars, fontAngle, tooltipScaleX] = computeLabels(
		categories,
		activeColumns === 1 || fullscreen
	);
	const yValues: number[] = [];
	const bulletValues: number[] = [];
	const mean: number[] = [];
	const median: number[] = [];
	const trackers: number[] = [];

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	data.forEach(value => {
		bulletValues.push(0);
		mean.push(Number(value.mean));
		median.push(Number(value.median));
		yValues.push(...value.numericValues, Number(value.mean), Number(value.median));
		trackers.push(value.numericValues.length);
	});

	const scatterSeries = data.map((object, index) => {
		return {
			type: ZingChartTypes.Scatter,
			values: object.numericValues.map((value, i) => {
				if (align === AlignOptions.Aligned) {
					return [index, value];
				} else {
					return [shuffledData[index][i], value];
				}
			}),
			text: object.group1.value,
			tooltip: {
				text: '%t, %v'
			},
			marker: {
				borderColor: Colors.white
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const bullet = {
		type: ZingChartTypes.Bullet,
		values: bulletValues,
		goals: line === LineOptions.Mean ? mean : median,
		tooltip: {
			text: '%g',
			backgroundColor: Colors.black,
			decimals: 3
		},
		goal: {
			backgroundColor: Colors.black
		}
	};

	const series = [...scatterSeries, bullet];

	const { min: yMinValue, max: yMaxValue } = getMaxAndMinInArray(yValues);
	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const COMMON_OPTS = getChartOptions({
		scaleXValues: categories,
		scalesLabels,
		activeColumn: activeColumns,
		fullscreen,
		yMaxValue,
		yMinValue
	});

	const options = {
		...COMMON_OPTS,
		plot: {
			marker: {
				size: 8,
				backgroundColor: CHART_COLORS[0]
			},
			tooltip: {
				backgroundColor: CHART_COLORS[0]
			},
			barWidth: 35,
			goal: {
				border: 'none',
				height: line === LineOptions.None ? 0 : 2
			},
			maxTrackers
		},
		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		},
		scaleX: {
			label: labelX,
			values: categories,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip: tooltipScaleX,
			exponent: COMMON_OPTS.scaleX.exponent
		},
		scaleY: {
			// minValue, maxValue props on "goals" behave very unpredictable
			// 99.99% change this is a zingchart bug.
			// minValue: COMMON_OPTS.scaleY.minValue,
			// maxValue: COMMON_OPTS.scaleY.maxValue,
			exponent: COMMON_OPTS.scaleY.exponent,
			label: labelY
		},
		legend: {
			visible: false
		}
	};

	return { series, options, disableAnimations };
}

// computeData for TimeCourseChart
// NOTE: ERRORS NEED TO BE ABSOLUTELY CONSIDERED FOR Y AXIS (ie: lowerLimit 59 for value=69 falls on y=59, not as zc currently computes it at 10 (69-59))

// PERCENTAGE TO ALLOW ROOM ON Y-SCALE FOR EXCEPTIONAL CASES
// eg: errors +- yValue end up on min/max range (y = 50, errorMin/errorMax = +- 50)
// zingchart does not properly compute yScale when minValue is 0 and there's possibly other such cases (negative values?)
const SCALE_MARGIN_MULTIPLIER = 0.03;
const DEFAULT_MAX_ITEMS = 10;
const MAX_NUMBER_FORMATTING = 100000;
const MIN_NUMBER_FORMATTING = 0.00001;

export function format(value: number) {
	if (value > 0) {
		if (value >= MAX_NUMBER_FORMATTING || value <= MIN_NUMBER_FORMATTING) {
			return value;
		}
		return Number(value.toFixed(2));
	} else if (value < 0) {
		if (value <= -MAX_NUMBER_FORMATTING || value >= -MIN_NUMBER_FORMATTING) {
			return value;
		}
		return Number(value.toFixed(2));
	}
	return value;
}

export function useComputeTimeCourseChartData(
	data: TimeCourseResultsDataV2,
	grouping: boolean,
	activeColumns: number,
	fullscreen: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	plots: BooleanMap,
	timeWindowSize: string | null,
	errorBar?: AnalysisStatisticAggregationType,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	function meanOrMedian() {
		let value = '';
		if (errorBar) {
			if (errorBar.toLowerCase().includes('mean')) {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.mean
				);
			} else {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.median
				);
			}
		}
		return value;
	}

	//errorValueMax - val, val - errorValueMin
	function computePlotTooltip(type: number) {
		if (type === 0) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr></table>`;
		} else if (type === 1) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.error
			)}</td><td><b>%node-error-plus</b></td></tr></table>`;
		} else if (type === 2) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorUpper
			)}</td><td><b>%data-error-absolute-plus</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorLower
			)}</td><td><b>%data-error-absolute-minus</b></td></tr></table>`;
		}
	}

	let scaleXValues: number[] = [];
	let scaleYValues: number[] = [];
	let series: TimeCourseChartSeries[] = [];

	let fontAngle = null;
	let tooltipScaleX = null;
	let seriesTooltipText: string | undefined = '';

	/**
	 * GROUPED TIME COURSE
	 */
	if (grouping) {
		data = data as TimeCourseGroupedValuesV2;

		data.groupedTimeCourse.forEach(value => {
			// COMPUTE TOOLTIP TYPE
			const hasErrorBars = value.timeCourse.lowerLimit && value.timeCourse.upperLimit;
			seriesTooltipText = hasErrorBars ? computePlotTooltip(2) : computePlotTooltip(0);
		});
		scaleXValues = scaleXValues.sort((a, b) => a - b);

		// COMPUTE LABELS AND LEGEND
		[legendItem, legendTooltip] = computeLegend(
			data.groupedTimeCourse.map(value => value.group.groupValue)
		);
		const [, _, computedFontAngle, computedTooltip] = computeLabels(
			scaleXValues.map(v => new Date(v).toLocaleDateString('en-US')),
			activeColumns === 1 || fullscreen
		);

		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		series = data.groupedTimeCourse.map((value, index) => {
			return {
				text: value.group.groupValue,
				values: value.timeCourse.yValues
					.map((yValue, index) => {
						scaleYValues.push(Number(yValue));
						if (
							Number(yValue) >= MAX_NUMBER_FORMATTING ||
							Number(yValue) <= MIN_NUMBER_FORMATTING
						) {
							return [value.timeCourse.timeline[index], Number(Number(yValue))];
						} else {
							return [value.timeCourse.timeline[index], format(yValue)];
						}
					})
					.map(v => (!isNaN(v[1]) ? v : [v[0], null])),
				// CUSTOM TOKEN FOR PASSING UNCHANGED API ERROR VALUES (ABSOLUTE VALUE AS OPPOSED TO RELATIVE VALUE THAT IS SENT TO THE CHART)
				// more details here: https://stackoverflow.com/questions/36753138/zing-chart-how-to-add-more-information-to-the-tool-tip
				'data-error-absolute-plus': value.timeCourse.upperLimit ?? undefined,
				'data-error-absolute-minus': value.timeCourse.lowerLimit ?? undefined,
				errors: value.timeCourse.yValues.map((val, index) => {
					if (
						value.timeCourse.lowerLimit &&
						value.timeCourse.lowerLimit[index] &&
						val &&
						value.timeCourse.upperLimit &&
						value.timeCourse.upperLimit[index]
					) {
						const lowerError = format(value.timeCourse.lowerLimit[index]);
						const upperError = format(value.timeCourse.upperLimit[index]);

						scaleYValues.push(...[upperError, lowerError]);

						return [upperError - val, val - lowerError];
					} else {
						return null;
					}
				}),
				lineColor: CHART_COLORS[index],
				marker: {
					backgroundColor: CHART_COLORS[index]
				},
				tooltip: {
					text: seriesTooltipText
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});

		/**
		 * SINGLE TIME COURSE
		 */
	} else {
		data = data as TimeCourseSingleV2;

		if (data.timeCourse) {
			const timeCourseData = data.timeCourse;

			// COMPUTE TOOLTIP TYPE
			const hasErrorBars = timeCourseData.lowerLimit && timeCourseData.upperLimit;
			seriesTooltipText = hasErrorBars ? computePlotTooltip(2) : computePlotTooltip(0);

			const [, _, computedFontAngle, computedTooltip] = computeLabels(
				scaleXValues.map(v => new Date(v).toLocaleDateString('en-US')),
				activeColumns === 1 || fullscreen
			);
			fontAngle = computedFontAngle;
			tooltipScaleX = computedTooltip;

			series = [
				{
					values: data.timeCourse.yValues
						.map((value, index) => {
							scaleYValues.push(value);
							data = data as TimeCourseSingleV2;
							return [data.timeCourse.timeline[index], format(value) ?? null];
						})
						.map(v => (isNaN(v[1]) ? [v[0], null] : v)),
					// CUSTOM TOKEN FOR PASSING UNCHANGED API ERROR VALUES (ABSOLUTE VALUE AS OPPOSED TO RELATIVE VALUE THAT IS SENT TO THE CHART)
					// more details here: https://stackoverflow.com/questions/36753138/zing-chart-how-to-add-more-information-to-the-tool-tip
					'data-error-absolute-plus': data.timeCourse.upperLimit ?? undefined,
					'data-error-absolute-minus': data.timeCourse.lowerLimit ?? undefined,
					errors: data.timeCourse.yValues.map((val, index) => {
						data = data as TimeCourseSingleV2;
						if (
							data.timeCourse.lowerLimit &&
							data.timeCourse.lowerLimit[index] &&
							val &&
							data.timeCourse.upperLimit &&
							data.timeCourse.upperLimit[index]
						) {
							const lowerError = format(data.timeCourse.lowerLimit[index]);
							const upperError = format(data.timeCourse.upperLimit[index]);

							scaleYValues.push(...[upperError, lowerError]);

							return [upperError - val, val - lowerError];
							//eg: [5, 5]
						} else {
							return null;
						}
					}),
					lineColor: CHART_COLORS[0],
					marker: {
						backgroundColor: CHART_COLORS[0]
					},
					tooltip: {
						text: seriesTooltipText
					}
				}
			];
		}
	}

	scaleYValues = scaleYValues.filter(v => !isNaN(v));
	const { min: minValue, max: maxValue } = getMaxAndMinInArray(scaleYValues);

	const timeWindowStep = timeWindowSize?.slice(0, -1);
	const COMMON_OPTS = getChartOptions({
		activeColumn: activeColumns,
		fullscreen,
		scalesLabels,
		scaleXValues: [],
		yMaxValue: maxValue,
		yMinValue: minValue
	});

	const options = {
		preview: {
			preserveZoom: true,
			active: {
				alpha: 0.1,
				alphaArea: 0.1
			},
			live: true,
			adjustLayout: true
		},
		plot: {
			error: {
				size: 14,
				lineWidth: 1
			}
			// marker: {
			// 	borderColor: Colors.gray.darkest
			// }
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			marker: {
				type: 'circle'
			},
			...(activeColumns !== 1 && !fullscreen
				? { visible: false }
				: isLegendEnabled && grouping
				? { visible: true }
				: { visible: false })
		},
		plotarea: {
			marginRight: '65'
		},
		scaleY: {
			...COMMON_OPTS.scaleY,
			label: labelY
		},
		scaleX: {
			zooming: true,
			utc: true,
			...(timeWindowSize && {
				transform: {
					type: 'date',
					all:
						timeWindowStep === TimeWindow.Year
							? '%Y'
							: timeWindowStep === TimeWindow.Month
							? '%Y %m'
							: timeWindowStep === TimeWindow.Day
							? '%Y-%mm-%d'
							: timeWindowStep === TimeWindow.Week
							? '%Y %m'
							: timeWindowStep === TimeWindow.Hour
							? '%Y-%mm-%d %h'
							: timeWindowStep === TimeWindow.Minute
							? '%Y-%mm-%d-%i'
							: '%Y-%mm-%d %i: %s'
				}
			}),
			...(timeWindowSize && { step: timeWindowStep }),
			label: labelX,
			values: scaleXValues,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_ITEMS : undefined,
			item: {
				fontAngle,
				visible: true
			},
			tooltip: tooltipScaleX
		}
	};
	return { series, options };
}

export function useComputeTimeCourseChartDataV1(
	data: TimeCourseResultsV1,
	grouping: boolean,
	activeColumns: number,
	fullscreen: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	plots: BooleanMap,
	timeWindowSize: string,
	errorBar?: AnalysisErrorBarType,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	function meanOrMedian() {
		let value = '';
		if (errorBar) {
			if (errorBar.toLowerCase().includes('mean')) {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.mean
				);
			} else {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.median
				);
			}
		}
		return value;
	}

	//errorValueMax - val, val - errorValueMin
	function computePlotTooltip(type: number) {
		if (type === 0) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr></table>`;
		} else if (type === 1) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.error
			)}</td><td><b>%node-error-plus</b></td></tr></table>`;
		} else if (type === 2) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorUpper
			)}</td><td><b>%data-error-absolute-plus</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorLower
			)}</td><td><b>%data-error-absolute-minus</b></td></tr></table>`;
		}
	}

	let scaleXValues: number[] = [];
	let scaleYValues: number[] = [];
	let series: TimeCourseChartSeries[] = [];

	let fontAngle = null;
	let tooltipScaleX = null;
	let seriesTooltipText: string | undefined = '';

	/**
	 * GROUPED TIME COURSE
	 */
	if (grouping) {
		data = data as TimeCourseGroupedValuesV1;

		data.groupedTimeCourse.forEach(value => {
			// COMPUTE TOOLTIP TYPE
			const hasErrorBars = value.timeCourse.lowerLimit && value.timeCourse.upperLimit;
			seriesTooltipText = hasErrorBars ? computePlotTooltip(2) : computePlotTooltip(0);
		});
		scaleXValues = scaleXValues.sort((a, b) => a - b);

		// COMPUTE LABELS AND LEGEND
		[legendItem, legendTooltip] = computeLegend(
			data.groupedTimeCourse.map(value => value.group.groupValue)
		);
		const [, _, computedFontAngle, computedTooltip] = computeLabels(
			scaleXValues.map(v => new Date(v).toLocaleDateString('en-US')),
			activeColumns === 1 || fullscreen
		);

		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		series = data.groupedTimeCourse.map((value, index) => {
			return {
				text: value.group.groupValue,
				values: value.timeCourse.yValues
					.map((yValue, index) => {
						scaleYValues.push(Number(yValue));
						if (
							Number(yValue) >= MAX_NUMBER_FORMATTING ||
							Number(yValue) <= MIN_NUMBER_FORMATTING
						) {
							return [value.timeCourse.timeline[index], Number(Number(yValue))];
						} else {
							return [value.timeCourse.timeline[index], format(yValue)];
						}
					})
					.map(v => (!isNaN(v[1]) ? v : [v[0], null])),
				// CUSTOM TOKEN FOR PASSING UNCHANGED API ERROR VALUES (ABSOLUTE VALUE AS OPPOSED TO RELATIVE VALUE THAT IS SENT TO THE CHART)
				// more details here: https://stackoverflow.com/questions/36753138/zing-chart-how-to-add-more-information-to-the-tool-tip
				'data-error-absolute-plus': value.timeCourse.upperLimit,
				'data-error-absolute-minus': value.timeCourse.lowerLimit,
				errors: value.timeCourse.yValues.map((val, index) => {
					if (
						value.timeCourse.lowerLimit &&
						value.timeCourse.lowerLimit[index] &&
						val &&
						value.timeCourse.upperLimit &&
						value.timeCourse.upperLimit[index]
					) {
						const lowerError = format(value.timeCourse.lowerLimit[index]);
						const upperError = format(value.timeCourse.upperLimit[index]);

						scaleYValues.push(...[upperError, lowerError]);

						return [upperError - val, val - lowerError];
					} else {
						return null;
					}
				}),
				lineColor: CHART_COLORS[index],
				marker: {
					backgroundColor: CHART_COLORS[index]
				},
				tooltip: {
					text: seriesTooltipText
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});

		/**
		 * SINGLE TIME COURSE
		 */
	} else {
		data = data as TimeCourseSingleV1;

		if (data.timeCourse) {
			const timeCourseData = data.timeCourse;

			// COMPUTE TOOLTIP TYPE
			const hasErrorBars = timeCourseData.lowerLimit && timeCourseData.upperLimit;
			seriesTooltipText = hasErrorBars ? computePlotTooltip(2) : computePlotTooltip(0);

			const [, _, computedFontAngle, computedTooltip] = computeLabels(
				scaleXValues.map(v => new Date(v).toLocaleDateString('en-US')),
				activeColumns === 1 || fullscreen
			);
			fontAngle = computedFontAngle;
			tooltipScaleX = computedTooltip;

			series = [
				{
					values: data.timeCourse.yValues
						.map((value, index) => {
							scaleYValues.push(value);
							data = data as TimeCourseSingleV1;
							return [data.timeCourse.timeline[index], format(value) ?? null];
						})
						.map(v => (isNaN(v[1]) ? [v[0], null] : v)),
					// CUSTOM TOKEN FOR PASSING UNCHANGED API ERROR VALUES (ABSOLUTE VALUE AS OPPOSED TO RELATIVE VALUE THAT IS SENT TO THE CHART)
					// more details here: https://stackoverflow.com/questions/36753138/zing-chart-how-to-add-more-information-to-the-tool-tip
					'data-error-absolute-plus': data.timeCourse.upperLimit,
					'data-error-absolute-minus': data.timeCourse.lowerLimit,
					errors: data.timeCourse.yValues.map((val, index) => {
						data = data as TimeCourseSingleV1;
						if (
							data.timeCourse.lowerLimit &&
							data.timeCourse.lowerLimit[index] &&
							val &&
							data.timeCourse.upperLimit &&
							data.timeCourse.upperLimit[index]
						) {
							const lowerError = format(data.timeCourse.lowerLimit[index]);
							const upperError = format(data.timeCourse.upperLimit[index]);

							scaleYValues.push(...[upperError, lowerError]);

							return [upperError - val, val - lowerError];
							//eg: [5, 5]
						} else {
							return null;
						}
					}),
					lineColor: CHART_COLORS[0],
					marker: {
						backgroundColor: CHART_COLORS[0]
					},
					tooltip: {
						text: seriesTooltipText
					}
				}
			];
		}
	}

	scaleYValues = scaleYValues.filter(v => !isNaN(v));
	const { min: minValue, max: maxValue } = getMaxAndMinInArray(scaleYValues);
	const isOutOfFormattingBounds =
		maxValue !== null || minValue !== null
			? Number(maxValue) >= MAX_NUMBER_FORMATTING || Number(minValue) < MIN_NUMBER_FORMATTING
				? true
				: false
			: false;

	const timeWindowStep = timeWindowSize?.slice(0, -1);

	const options = {
		preview: {
			preserveZoom: true,
			active: {
				alpha: 0.1,
				alphaArea: 0.1
			},
			live: true,
			adjustLayout: true
		},
		plot: {
			error: {
				size: 14,
				lineWidth: 1
			},
			lineWidth: 1,
			marker: {
				size: 6,
				borderColor: Colors.gray.darkest
			}
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			marker: {
				type: 'circle'
			},
			...(activeColumns !== 1 && !fullscreen
				? { visible: false }
				: isLegendEnabled && grouping
				? { visible: true }
				: { visible: false })
		},
		plotarea: {
			marginRight: '65'
		},
		scaleY: {
			label: labelY,
			minValue: minValue - SCALE_MARGIN_MULTIPLIER * (maxValue - minValue),
			maxValue: maxValue + SCALE_MARGIN_MULTIPLIER * (maxValue - minValue),
			exponent: isOutOfFormattingBounds
		},
		scaleX: {
			zooming: true,
			utc: true,
			...(timeWindowSize && {
				transform: {
					type: 'date',
					all:
						timeWindowStep === TimeWindow.Year
							? '%Y'
							: timeWindowStep === TimeWindow.Month
							? '%Y %m'
							: timeWindowStep === TimeWindow.Day
							? '%Y-%mm-%d'
							: timeWindowStep === TimeWindow.Week
							? '%Y %m'
							: timeWindowStep === TimeWindow.Hour
							? '%Y-%mm-%d %h'
							: timeWindowStep === TimeWindow.Minute
							? '%Y-%mm-%d-%i'
							: '%Y-%mm-%d %i: %s'
				}
			}),
			...(timeWindowSize && { step: timeWindowStep }),

			label: labelX,
			values: scaleXValues,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_ITEMS : undefined,
			item: {
				fontAngle,
				visible: true
			},
			tooltip: tooltipScaleX
		}
	};
	return { series, options };
}

// computeData for PlotNumericBoxPlotChart
export function useComputePlotNumericBoxPlotChartData(
	data: PlotNumericBoxplotResults,
	grouping: boolean,
	isLegendEnabled: boolean,
	activeColumn: number,
	fullscreen: boolean,
	plots: BooleanMap,
	scalesLabels: ScalesLabels,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	let scaleXValues: string[] = [];
	let series: PlotNumericBoxPlotSeries[] = [];

	let errorMaxValues: number[] = [];
	let errorMinValues: number[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	const maxNumberFormatting = 100000;
	const minNumberFormatting = 0.00001;

	const seriesTooltipText = `<table><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMax
	)}</td><td><b>%data-max</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRUpper
	)}</td><td><b>%data-upper-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.median
	)}</td><td><b>%data-median</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRLower
	)}</td><td><b>%data-lower-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMin
	)}</td><td><b>%data-min</b></td></tr></table>`;

	if (grouping) {
		const dataset = data as PlotNumericBoxplotGrouped;

		const computedSeries: GenericMap<PlotNumericBoxplotGroupedValue[]> = {};

		dataset.forEach(({ groupedCalculations }) => {
			groupedCalculations &&
				groupedCalculations.forEach(groupedValue => {
					errorMaxValues.push(Number(groupedValue.errorValueMax));
					errorMinValues.push(Number(groupedValue.errorValueMin));

					if (!computedSeries[groupedValue.groupValue]) {
						computedSeries[groupedValue.groupValue] = [];
					}
					computedSeries[groupedValue.groupValue].push(groupedValue);
				});
		});

		scaleXValues = dataset.map(value => value.categorizationValue);

		[legendItem, legendTooltip] = computeLegend(Object.keys(computedSeries));

		series = Object.entries(computedSeries).map(([key, value], index) => {
			return {
				text: key,
				dataBox: value.map(plot => {
					const errorValueMin =
						Number(plot.errorValueMin) >= maxNumberFormatting ||
						Number(plot.errorValueMin) <= minNumberFormatting
							? Number(plot.errorValueMin)
							: Number(Number(plot.errorValueMin).toFixed(3));

					const IQRLower =
						Number(plot.IQRLower) >= maxNumberFormatting ||
						Number(plot.IQRLower) <= minNumberFormatting
							? Number(plot.IQRLower)
							: Number(Number(plot.IQRLower).toFixed(3));
					const median =
						Number(plot.median) >= maxNumberFormatting ||
						Number(plot.median) <= minNumberFormatting
							? Number(plot.median)
							: Number(Number(plot.median).toFixed(3));
					const IQRUpper =
						Number(plot.IQRUpper) >= maxNumberFormatting ||
						Number(plot.IQRUpper) <= minNumberFormatting
							? Number(plot.IQRUpper)
							: Number(Number(plot.IQRUpper).toFixed(3));
					const errorValueMax =
						Number(plot.errorValueMax) >= maxNumberFormatting ||
						Number(plot.errorValueMax) <= minNumberFormatting
							? Number(plot.errorValueMax)
							: Number(Number(plot.errorValueMax).toFixed(3));

					return [errorValueMin, IQRLower, median, IQRUpper, errorValueMax];
				}),
				backgroundColor: CHART_COLORS[index],
				borderColor: CHART_COLORS[index],
				highlightState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				tooltip: {
					text: seriesTooltipText,
					backgroundColor: CHART_COLORS[index]
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});
	} else {
		const dataset = data as PlotNumericBoxplotSingle;
		scaleXValues = dataset.map(serie => serie.categorizationValue);
		dataset.forEach(el => errorMaxValues.push(Number(el.errorValueMax)));
		dataset.forEach(el => errorMinValues.push(Number(el.errorValueMin)));

		series = [
			{
				dataBox: dataset.map(plot => {
					const errorValueMin =
						Number(plot.errorValueMin) >= maxNumberFormatting ||
						Number(plot.errorValueMin) <= minNumberFormatting
							? Number(plot.errorValueMin)
							: Number(Number(plot.errorValueMin).toFixed(3));

					const IQRLower =
						Number(plot.IQRLower) >= maxNumberFormatting ||
						Number(plot.IQRLower) <= minNumberFormatting
							? Number(plot.IQRLower)
							: Number(Number(plot.IQRLower).toFixed(3));
					const median =
						Number(plot.median) >= maxNumberFormatting ||
						Number(plot.median) <= minNumberFormatting
							? Number(plot.median)
							: Number(Number(plot.median).toFixed(3));
					const IQRUpper =
						Number(plot.IQRUpper) >= maxNumberFormatting ||
						Number(plot.IQRUpper) <= minNumberFormatting
							? Number(plot.IQRUpper)
							: Number(Number(plot.IQRUpper).toFixed(3));
					const errorValueMax =
						Number(plot.errorValueMax) >= maxNumberFormatting ||
						Number(plot.errorValueMax) <= minNumberFormatting
							? Number(plot.errorValueMax)
							: Number(Number(plot.errorValueMax).toFixed(3));

					return [errorValueMin, IQRLower, median, IQRUpper, errorValueMax];
				}),
				backgroundColor: CHART_COLORS[0],
				borderColor: CHART_COLORS[0],
				tooltip: {
					text: seriesTooltipText,
					backgroundColor: CHART_COLORS[0]
				}
			}
		];
	}

	errorMaxValues = errorMaxValues.filter(value => !isNaN(value));
	errorMinValues = errorMinValues.filter(value => !isNaN(value));

	const yMaxValue = Math.max(...errorMaxValues);
	const yMinValue = Math.min(...errorMinValues);

	if (activeColumn !== 1 && !fullscreen) {
		isLegendEnabled = false;
	}

	const options = {
		...getChartOptions({
			scaleXValues,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMaxValue,
			yMinValue,
			maxY: yMaxValue,
			minY: yMinValue,
			itemsOverlap: true
		}),

		plot: {
			hoverState: {
				visible: false
			}
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && grouping
		},
		options: {
			lineMedianLevel: {
				lineColor: Colors.gray.darkest,
				lineWidth: 2
			}
		}
	};

	return { series, options };
}

export function useComputePlotNumericBoxPlotChartDataV2(
	data: PlotNumericBoxPlotsV2,
	grouping: boolean,
	isLegendEnabled: boolean,
	activeColumn: number,
	fullscreen: boolean,
	plots: BooleanMap,
	scalesLabels: ScalesLabels,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	let scaleXValues: string[] = [];
	let series: PlotNumericBoxPlotSeries[] = [];

	let errorMaxValues: number[] = [];
	let errorMinValues: number[] = [];
	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	// const maxNumberFormatting = 100000;
	// const minNumberFormatting = 0.00001;

	const seriesTooltipText = `<table><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMax
	)}</td><td><b>%data-max</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRUpper
	)}</td><td><b>%data-upper-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.median
	)}</td><td><b>%data-median</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRLower
	)}</td><td><b>%data-lower-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMin
	)}</td><td><b>%data-min</b></td></tr></table>`;

	if (grouping) {
		const computedSeries: GenericMap<PlotNumericBoxPlotsV2> = {};

		data.forEach(column => {
			// canot be undefined in grouping scenario;
			errorMinValues.push(column.min);
			errorMaxValues.push(column.max);
			const group = column.group2!.value;
			if (!computedSeries[group]) computedSeries[group] = [];
			if (
				column.firstQuartile === null &&
				column.max === null &&
				column.min === null &&
				column.median === null &&
				column.min === null
			) {
				return;
			}
			computedSeries[group].push(column);
		});

		errorMaxValues = errorMaxValues.filter(value => !isNaN(value));
		errorMinValues = errorMinValues.filter(value => !isNaN(value));

		if (activeColumn !== 1 && !fullscreen) {
			isLegendEnabled = false;
		}

		[legendItem, legendTooltip] = computeLegend(Object.keys(computedSeries));

		series = Object.entries(computedSeries).map(([key, value], index) => {
			const dataBox = value.map(col => {
				const { firstQuartile, max, min, median, thirdQuartile } = col;

				return [
					Number(min),
					Number(firstQuartile),
					Number(median),
					Number(thirdQuartile),
					Number(max)
				];
			});

			return {
				text: key,
				dataBox,
				backgroundColor: CHART_COLORS[index],
				borderColor: CHART_COLORS[index],
				highlightState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				tooltip: {
					text: seriesTooltipText,
					backgroundColor: CHART_COLORS[index]
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});
	} else {
		series = [
			{
				dataBox: data.map(col => {
					const { firstQuartile, max, min, median, thirdQuartile } = col;

					return [
						Number(min),
						Number(firstQuartile),
						Number(median),
						Number(thirdQuartile),
						Number(max)
					];
				}),
				backgroundColor: CHART_COLORS[0],
				borderColor: CHART_COLORS[0],
				tooltip: {
					text: seriesTooltipText,
					backgroundColor: CHART_COLORS[0]
				}
			}
		];
	}

	scaleXValues = [...new Set(data.map(d => d.group1.value))];
	data.forEach(el => errorMaxValues.push(Number(el.max)));
	data.forEach(el => errorMinValues.push(Number(el.min)));

	const yMaxValue = Math.max(...errorMaxValues);
	const yMinValue = Math.min(...errorMinValues);

	const options = {
		...getChartOptions({
			scaleXValues,
			scalesLabels,
			activeColumn,
			fullscreen,
			yMaxValue,
			yMinValue,
			itemsOverlap: true
		}),

		plot: {
			hoverState: {
				visible: false
			}
		},
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && grouping
		},
		options: {
			lineMedianLevel: {
				lineColor: Colors.gray.darkest,
				lineWidth: 2
			}
		}
	};

	return { series, options };
}

export function useComputePlotNumericColumnsChartData(
	data: PlotNumericColumnsResults,
	grouping: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	errorBar?: AnalysisErrorBarType,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	function meanOrMedian() {
		let value = '';
		if (errorBar) {
			if (errorBar.toLowerCase().includes('mean')) {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.mean
				);
			} else {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.median
				);
			}
		}
		return value;
	}

	function computePlotTooltip(type: number) {
		if (type === 0) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr></table>`;
		} else if (type === 1) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.error
			)}</td><td><b>%node-error-plus</b></td></tr></table>`;
		} else if (type === 2) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorUpper
			)}</td><td><b>%node-error-plus</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorLower
			)}</td><td><b>%node-error-minus</b></td></tr></table>`;
		}
	}

	let series: PlotNumericColumnsChartSeries[] = [];

	let maxItems = null;
	let fontAngle = null;
	let maxChars = null;
	let tooltipScaleX = null;
	let scaleXValues: string[] = [];
	let seriesTooltipText: string | undefined = '';

	let allYValues: number[] = [];
	const maxNumberFormatting = 100000;
	const minNumberFormatting = 0.00001;

	if (grouping) {
		const dataset = data as PlotNumericColumnsGrouped;

		const maxPlotValues: number[] = [];

		const computedSeries: GenericMap<PlotNumericColumnsGroupedValue[]> = {};

		dataset.forEach(({ groupedCalculations, categorizationValue }) => {
			groupedCalculations &&
				groupedCalculations.forEach(groupedValue => {
					let meanOrMedian = 0;
					let errorValue = 0;

					if (Number(groupedValue.meanOrMedian) > meanOrMedian) {
						// mean or median
						meanOrMedian = Number(groupedValue.meanOrMedian);
						seriesTooltipText = computePlotTooltip(0);
					}
					if (groupedValue.errorValueMax && groupedValue.errorValueMin) {
						// range
						errorValue = Math.abs(
							Number(groupedValue.meanOrMedian) - Number(groupedValue.errorValueMax)
						);
						seriesTooltipText = computePlotTooltip(2);
					} else if (groupedValue.errorValue) {
						// mean/median with SD/CI
						errorValue = Number(groupedValue.errorValue);
						seriesTooltipText = computePlotTooltip(1);
					}
					if (!isNaN(meanOrMedian)) {
						if (!isNaN(errorValue)) {
							maxPlotValues.push(meanOrMedian + errorValue);
						}
						maxPlotValues.push(meanOrMedian);
					}

					if (!computedSeries[groupedValue.groupValue]) {
						computedSeries[groupedValue.groupValue] = [];
					}
					computedSeries[groupedValue.groupValue].push({
						...groupedValue,
						categorizationValue
					});
				});
		});

		[legendItem, legendTooltip] = computeLegend(Object.keys(computedSeries));

		scaleXValues = dataset.map(value => value.categorizationValue);
		const [computedMaxItems, computedMaxChars, computedFontAngle, computedTooltip] =
			computeLabels(scaleXValues, activeColumns === 1 || fullscreen);

		maxItems = computedMaxItems;
		maxChars = computedMaxChars;
		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		series = Object.entries(computedSeries).map(([key, value], index) => {
			return {
				text: key,
				values: value.map(value => {
					allYValues.push(Number(Number(value.meanOrMedian)));
					if (
						Number(value.meanOrMedian) >= maxNumberFormatting ||
						(Number(value.meanOrMedian) <= minNumberFormatting &&
							Number(value.meanOrMedian) > 0)
					) {
						return [value.categorizationValue, Number(value.meanOrMedian)];
					} else {
						return [
							value.categorizationValue,
							Number(Number(value.meanOrMedian).toFixed(3))
						];
					}
				}),
				errors: value.map(value => {
					if (value.errorValueMax && value.errorValueMin) {
						const meanOrMedian = Number(value.meanOrMedian);
						const minErrorValue = Number(value.errorValueMin);
						const maxErrorValue = Number(value.errorValueMax);

						const errorValueMax = Number(
							Math.abs(meanOrMedian - maxErrorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									(Number(value.meanOrMedian) <= minNumberFormatting &&
										Number(value.meanOrMedian) > 0)
									? 20
									: 3
							)
						);
						const errorValueMin = Number(
							Math.abs(meanOrMedian - minErrorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									Number(value.meanOrMedian) <= minNumberFormatting
									? 20
									: 3
							)
						);
						return [errorValueMax, errorValueMin];
					} else if (value.errorValue) {
						const errorValue = Number(
							Number(value.errorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									Number(value.meanOrMedian) <= minNumberFormatting
									? 20
									: 3
							)
						);
						return [errorValue, null];
					} else {
						return null;
					}
				}),
				backgroundColor: CHART_COLORS[index],
				hoverState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				highlightState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				tooltip: {
					text: seriesTooltipText
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});
	} else {
		const dataset = data as PlotNumericColumnsSingle;
		const maxPlotValues: number[] = [];

		dataset.forEach(value => {
			let meanOrMedian = 0;
			let errorValue = 0;

			if (Number(value.meanOrMedian) > meanOrMedian) {
				// mean or median
				meanOrMedian = Number(value.meanOrMedian);
				seriesTooltipText = computePlotTooltip(0);
			}
			if (value.errorValueMax && value.errorValueMin) {
				// range
				errorValue = Math.abs(Number(value.meanOrMedian) - Number(value.errorValueMax));
				seriesTooltipText = computePlotTooltip(2);
			} else if (value.errorValue) {
				// mean/median with SD/CI
				errorValue = Number(value.errorValue);
				seriesTooltipText = computePlotTooltip(1);
			}
			if (!isNaN(meanOrMedian)) {
				if (!isNaN(errorValue)) {
					maxPlotValues.push(meanOrMedian + errorValue);
				}
				maxPlotValues.push(meanOrMedian);
			}
		});

		const scaleXValues = dataset.map(obj => obj.categorizationValue);
		const [computedMaxItems, computedMaxChars, computedFontAngle, computedTooltip] =
			computeLabels(scaleXValues, activeColumns === 1 || fullscreen);

		maxItems = computedMaxItems;
		maxChars = computedMaxChars;
		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		series = [
			{
				tooltip: {
					text: seriesTooltipText
				},
				values: dataset
					.map(value => {
						allYValues.push(Number(Number(value.meanOrMedian)));
						if (
							Number(value.meanOrMedian) < minNumberFormatting ||
							Number(value.meanOrMedian) > maxNumberFormatting
						) {
							return [value.categorizationValue, Number(value.meanOrMedian)] as [
								string,
								number
							];
						} else {
							return [
								value.categorizationValue,
								Number(Number(value.meanOrMedian).toFixed(3))
							] as [string, number];
						}
					})
					.map(([x, y]) => (isNaN(y) ? [x, 0] : [x, y])),
				errors: dataset.map(value => {
					if (value.errorValueMax && value.errorValueMin) {
						const meanOrMedian = Number(value.meanOrMedian);
						const minErrorValue = Number(value.errorValueMin);
						const maxErrorValue = Number(value.errorValueMax);

						const errorValueMax = Number(
							Math.abs(meanOrMedian - maxErrorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									Number(value.meanOrMedian) <= minNumberFormatting
									? 20
									: 3
							)
						);
						const errorValueMin = Number(
							Math.abs(meanOrMedian - minErrorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									Number(value.meanOrMedian) <= minNumberFormatting
									? 20
									: 3
							)
						);
						return [errorValueMax, errorValueMin];
					} else if (value.errorValue) {
						const errorValue = Number(
							Number(value.errorValue).toFixed(
								Number(value.meanOrMedian) >= maxNumberFormatting ||
									Number(value.meanOrMedian) <= minNumberFormatting
									? 20
									: 3
							)
						);
						return [errorValue, null];
					} else {
						return null;
					}
				}),
				backgroundColor: CHART_COLORS[0],
				hoverState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[0])
				}
			}
		];
	}

	function computeYScaleRange(arr: number[]): [number, number] {
		const { min, max } = getMaxAndMinInArray(arr);
		// if just one value in the array;
		if (arr.length === 1 && min === max) {
			return [min, 0];
		}

		// if both are positive/negative;
		if (min > 0 && max > 0) {
			return [0, max];
		} else if (min < 0 && max < 0) {
			return [min, 0];
		}

		return [min, max];
	}
	allYValues = allYValues.filter(value => !isNaN(value));

	const [yMinValue, yMaxValue] = computeYScaleRange(allYValues);

	const options = {
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && grouping,
			highlightPlot: false
		},
		plotarea: {
			margin: 'dynamic'
		},
		scaleY: {
			...getChartOptions({
				activeColumn: activeColumns,
				yMaxValue,
				yMinValue,
				fullscreen,
				scaleXValues,
				scalesLabels
			}).scaleY,
			label: labelY
		},
		scaleX: {
			label: labelX,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip: tooltipScaleX
		},
		plot: {
			hoverState: {
				alpha: 0.5
			}
		}
	};

	return { series, options };
}
export function useComputePlotNumericColumnsChartDataV2(
	data: PlotNumericColumnsV2,
	grouping: boolean,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	errorBar: AnalysisStatisticAggregationType,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	const hasErrors = ![
		AnalysisStatisticAggregationType.Mean,
		AnalysisStatisticAggregationType.Median
	].includes(errorBar);

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	let legendItem: {} | null = {};
	let legendTooltip: {} | null = null;

	function meanOrMedian() {
		let value = '';
		if (errorBar) {
			if (errorBar.toLowerCase().includes('mean')) {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.mean
				);
			} else {
				value = translate(
					({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.median
				);
			}
		}
		return value;
	}

	/**
	 *
	 * @param type type 0 = error, type 1 = error upper, type 2 = error lower
	 * @returns
	 */
	function computePlotTooltip(type: number) {
		if (type === 0) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr></table>`;
		} else if (type === 1) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.error
			)}</td><td><b>%data-error-absolute-plus</b></td></tr></table>`;
		} else if (type === 2) {
			return `<table><tr><td style="padding-right:1rem">${meanOrMedian()}</td><td><b>%v</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorUpper
			)}</td><td><b>%data-error-absolute-plus</b></td></tr><tr><td style="padding-right:1rem">${translate(
				({ analysis }) => analysis.analyses.plotNumeric.tooltip.columns.errorLower
			)}</td><td><b>%data-error-absolute-minus</b></td></tr></table>`;
		}
	}

	let maxItems = null;
	let fontAngle = null;
	let maxChars = null;
	let tooltipScaleX = null;
	let scaleXValues: string[] = [];
	const seriesTooltipText = hasErrors ? computePlotTooltip(2) : computePlotTooltip(0);

	let allYValues: (number | null)[] = [];

	let series: PlotNumericColumnsChartSeries[] = [];

	if (!grouping) {
		[legendItem, legendTooltip] = computeLegend(Object.keys(data.map(d => d.group1.value)));

		// only values with error have object type shape
		scaleXValues = [...new Set(data.map(d => d.group1.value ?? ''))];
		const [computedMaxItems, computedMaxChars, computedFontAngle, computedTooltip] =
			computeLabels(scaleXValues, activeColumns === 1 || fullscreen);

		maxItems = computedMaxItems;
		maxChars = computedMaxChars;
		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		const values: [string, number][] = [];
		const errors: (number | null)[][] = [];
		const absoluteErrors: (number | string)[][] = [];

		data.forEach(value => {
			const key = value.group1.value;

			// TODO NULL HANDLING 8816
			if (hasErrors) {
				const point = value[errorBar] as PlotNumericColumnValueV2;
				const center = toDecimals(Number(point.center));
				const lower = point.lower ? toDecimals(Number(point.lower)) : null;
				const upper = point.upper ? toDecimals(Number(point.upper)) : null;

				const upperDelta =
					upper !== null && center !== null ? toDecimals(upper - center) : null;
				const lowerDelta =
					lower !== null && center !== null ? toDecimals(center - lower) : null;

				values.push([key, toDecimals(center)]);
				errors.push([upperDelta, lowerDelta]);
				absoluteErrors.push([lower ?? 'N/A', upper ?? 'N/A']);
				allYValues.push(...[lower, center, upper]);
			} else {
				const point = value[errorBar] as number;
				const val = toDecimals(Number(point));
				allYValues.push(val);
				values.push([key, val]);
			}
		});
		series = [
			{
				values,
				errors,
				'data-error-absolute-plus': absoluteErrors.map(error => error[1]),
				'data-error-absolute-minus': absoluteErrors.map(error => error[0]),
				backgroundColor: CHART_COLORS[0],
				hoverState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[0])
				},
				highlightState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[0])
				},
				tooltip: {
					text: seriesTooltipText
				},
				visible: plots && plots[0] !== undefined ? plots[0] : true
			}
		];
	} else {
		// GROUPING
		// multiple series identified by key inside computedSeries object;
		// group2 is y
		const computedSeries: GenericMap<PlotNumericColumnV2[]> = {};

		data.forEach(column => {
			// must have group2 otherwise grouping would be FALSE
			const group = column.group2!.value;

			if (!computedSeries[group]) computedSeries[group] = [];
			computedSeries[group].push(column);
		});

		[legendItem, legendTooltip] = computeLegend(Object.keys(computedSeries));

		// only values with error have object type shape
		const scaleXValues = data.map(d => d.group1?.value ?? '');
		const [computedMaxItems, computedMaxChars, computedFontAngle, computedTooltip] =
			computeLabels(scaleXValues, activeColumns === 1 || fullscreen);

		maxItems = computedMaxItems;
		maxChars = computedMaxChars;
		fontAngle = computedFontAngle;
		tooltipScaleX = computedTooltip;

		series = Object.entries(computedSeries).map(([key, value], index) => {
			const values: [string, number][] = [];
			const errors: (number | null)[][] = [];
			const absoluteErrors: (number | string)[][] = [];

			if (hasErrors) {
				value.forEach(val => {
					const x = val.group1.value;
					const point = val[errorBar] as PlotNumericColumnValueV2;

					const center = Number(point.center);
					const lower = point.lower ? Number(point.lower) : null;
					const upper = point.upper ? Number(point.upper) : null;

					const upperDelta = upper !== null && center !== null ? upper - center : null;
					const lowerDelta = lower !== null && center !== null ? center - lower : null;

					values.push([x, center]);
					errors.push([upperDelta, lowerDelta]);
					absoluteErrors.push([lower ?? 'N/A', upper ?? 'N/A']);
					allYValues.push(...[lower, center, upper]);
				});
			} else {
				value.forEach(point => {
					const x = point.group1.value;
					const y = point[errorBar] !== null ? Number(point[errorBar] as number) : 0;
					allYValues.push(y);
					values.push([x, y]);
				});
			}

			return {
				text: key,
				values,
				errors,
				backgroundColor: CHART_COLORS[index],
				'data-error-absolute-plus': absoluteErrors.map(error => error[1]),
				'data-error-absolute-minus': absoluteErrors.map(error => error[0]),
				hoverState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				highlightState: {
					backgroundColor: pSBC(-0.2, CHART_COLORS[index])
				},
				tooltip: {
					text: seriesTooltipText
				},
				visible: plots && plots[index] !== undefined ? plots[index] : true
			};
		});
	}

	allYValues = allYValues
		.filter(value => value !== null)
		.filter(value => !isNaN(value as number));

	const { min: minValue, max: maxValue } = getMaxAndMinInArray(allYValues as number[]);

	const options = {
		legend: {
			toggleAction: 'none',
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && grouping,
			highlightPlot: false
		},
		plotarea: {
			margin: 'dynamic'
		},
		scaleY: {
			...getChartOptions({
				yMaxValue: maxValue,
				yMinValue: minValue,
				scalesLabels,
				scaleXValues,
				activeColumn: activeColumns,
				fullscreen
			}).scaleY,
			label: labelY
		},
		scaleX: {
			label: labelX,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip: tooltipScaleX
		},
		plot: {
			hoverState: {
				alpha: 0.5
			}
		}
	};

	return { series, options };
}

/**
 * formats and trims number to set amount of decimal points (default 2)
 * @param number number
 * @param decimals number, default: 2
 * @returns number
 */
function toDecimals(number: number, decimals = 2) {
	let sign = '';
	let parsedNumber = number;
	if (number < 0) {
		sign = '-';
		parsedNumber = -number;
	}
	const digits = Math.ceil(Math.log10(parsedNumber));
	const normalized = parsedNumber / Math.pow(10, digits);

	return Number(
		(Number(`${Number(sign + normalized)}`) * Math.pow(10, digits)).toFixed(decimals)
	);
}
