import styled from 'styled-components';

import { Colors } from 'environment';

export const Container = styled.div`
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* TODO: replace with colors from styleguide */
	background-color: #7c7c89;

	/* PDF CONTROLS */
	> :last-child {
		bottom: 4rem;
		box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
	}
`;

export const ScrollableContainer = styled.div`
	overflow: auto;
	width: 100%;
`;

export const PDFContainer = styled.div`
	padding: 2rem;
	margin: auto;
	width: fit-content;
	height: fit-content;

	/* ADD GAP BETWEEN PDF PAGES */
	.react-pdf__Document > :not(:last-child) {
		margin-bottom: 1.6rem;
	}
	.react-pdf__Page {
		box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
	}
`;

export const Header = styled.div`
	z-index: 1;
	padding: 0 4%;
	height: 6rem;
	min-height: 6rem;
	display: flex;
	width: 100%;
	box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
	background-color: ${Colors.background.disabled};
`;

export const HeaderLeft = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	/* in order for ellipsis to work */
	min-width: 0;
`;

export const HeaderRight = styled.div`
	display: flex;
	align-items: center;
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
	/* in order for ellipsis to work */
	min-width: 0;
`;
