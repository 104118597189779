import { decodeURIComponentSafe } from 'helpers/generic';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { NumberMap } from 'types/index';

export function exportFrequencies(
	dataset: NumberMap,
	{ translate }: { translate: TranslateFunction }
) {
	let sum = 0;

	const rows = Object.keys(dataset)
		.filter(key => key !== 'missing')
		.map(key => {
			sum += dataset[key];

			return { key, value: dataset[key] };
		});

	if (dataset['missing'] !== undefined) {
		sum += dataset['missing'];
	}

	const columns = [
		translate(({ analysis }) => analysis.analyses.frequencies.table.category),
		translate(({ analysis }) => analysis.analyses.frequencies.table.n),
		'%'
	];

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	// ADD COLUMNS
	csv += columns.join(comma) + newLine;

	// ADD ROWS
	rows.forEach(row => {
		let label = decodeURIComponentSafe(row.key);
		// null-category-value is already generated, so missing is not required, but rename is
		if (row.key === 'null-category-value') {
			label = translate(({ analysis }) => analysis.analyses.frequencies.table.missing);
		}
		const percent = ((row.value / sum) * 100).toFixed(1);
		const percentValue = !isNaN(Number(percent)) ? percent : '';

		const finalRow = [label, row.value, `${percentValue}%`];

		csv += finalRow.join(comma) + newLine;
	});

	return csv;
}
