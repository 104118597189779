import { useEffect, useMemo } from 'react';

import { ExploreAnalysis } from 'api/data/analyses';
import { AnalysisViewContainer, AnalysisErrorWrapper } from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames, FileType } from 'types/index';

import { ExploreTable } from './ExploreTable';
import { exportExplore } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import { useProject, useTimeDurationEntries, useTranslation } from 'hooks/store';
import { cloneDeep, keys } from 'lodash';
import {
	getTimeDurationFormatForAnalysis,
	getTimeDurationStringFromMicroseconds
} from 'helpers/entries';
import { VariableType } from 'types/data/variables/constants';
import { buildAggregationRuleNameToAggregatorVariableMap } from 'helpers/variables';
import { useSelector } from 'hooks/utils';
import { selectTranslations } from 'store/ui/i18n';
import { VariablesData } from 'store/data/variables';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: ExploreAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function ExploreView({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const { translate } = useTranslation();
	const translations = useSelector(state => selectTranslations(state.ui.i18n));

	const [{ data: project }] = useProject();

	const { selectItemsLabelMap } = variablesDataSelectItems;
	const { parseTimeDurationEntryCell } = useTimeDurationEntries({ withTranslation: true });
	const { variablesMap, variableSetsMap } = variablesData;
	const {
		input,
		options: { configPanel },
		output: { dataset }
	} = analysis;

	// PARSE TIME DURAION VARIABLES;
	// 'skewness' and 'kurtosis' have no unit of measurement - should be parsed as normal numbers
	const KEYS_TO_EXCLUDE: (keyof (typeof dataset)[0])[] = [
		'missing',
		'nr of rows',
		'skewness',
		'kurtosis'
	];

	// NOTE: aggregation rules as variable are sent and mapped by rule.name, which is not indexed in `variablesMap`
	const aggregationRuleToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const parsedDataset = useMemo(() => {
		const parsedDataset = cloneDeep(dataset);
		parsedDataset.forEach((data, index) => {
			const name = input.variables[index]?.variableName;

			const variable =
				variablesMap[name] ??
				variablesMap[aggregationRuleToVariableNameMap[name]?.aggregator.variableName];
			if (variable?.type === VariableType.TimeDuration) {
				// parse values
				const shownFormat = getTimeDurationFormatForAnalysis(variable.durationFormat ?? []);
				keys(data).forEach(k => {
					const key = k as keyof typeof data;
					if (KEYS_TO_EXCLUDE.includes(key)) return;

					if (!isNaN(Number(data[key]))) {
						const duration = getTimeDurationStringFromMicroseconds(
							Number(data[key]),
							shownFormat,
							// "variance" has squared time units and needs to be formatted properly;
							...(key === 'variance' ? [true] : [])
						);
						// add ^2 to each unit for "variance" field
						if (key === 'variance') {
							data[key] = parseTimeDurationEntryCell(
								duration ?? undefined,
								shownFormat
							)
								.split(':')
								.map(val => (val += '^2'))
								.join(':');
						} else {
							data[key] = parseTimeDurationEntryCell(
								duration ?? undefined,
								shownFormat
							);
						}
					}
				});
			}
		});
		return parsedDataset;
	}, [translations, dataset, variablesMap, input.variables]);

	useEffect(() => {
		if (exporting) {
			const data = exportExplore(
				dataset,
				{ variablesMap, variableSetsMap },
				{
					translate,
					columnLabelsMap: selectItemsLabelMap
				},
				{ parseTimeDurationEntryCell }
			);

			if (data && project) {
				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.ExploreTable,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			}
		}
	}, [exporting]);

	return (
		<AnalysisViewContainer
			isConfigPanelOpen={configPanel.open}
			loading={loading}
			error={!input.variables.length}
			message={translate(({ analysis }) => analysis.analyses.explore.view.chooseAVariable)}
		>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.Explore}>
				<ExploreTable
					variablesDataSelectItems={variablesDataSelectItems}
					dataset={parsedDataset}
				/>
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
