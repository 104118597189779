import { useRef, useMemo } from 'react';
import { isEmpty } from 'lodash';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import { nanoid as generate } from 'nanoid';

import { GetLogisticRegressionOutput } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';

import { ScalesLabels } from 'types/index';
import { ZingChartTypes } from 'types/charts';
import {
	useAnalyses,
	useAnalysesActiveColum,
	useAnalysisConfigPanel,
	useAnalysisContext,
	useFilters,
	useFullscreenAnalysis,
	useToggleAnalysisChartPlot,
	useTranslation
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import { computeLogisticRegressionData as computeData } from 'helpers/analysis';

interface Props {
	data: GetLogisticRegressionOutput | null;
	id?: string;
	isLegendEnabled: boolean;
	scalesLabels: ScalesLabels;
	isForExport?: boolean;
	legendHeader?: string;
}

export function LogisticRegressionChart({
	id,
	data,
	scalesLabels,
	isLegendEnabled,
	isForExport,
	legendHeader
}: Props) {
	const ref = useRef<ZingChart>(null);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumns] = useAnalysesActiveColum();
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const { translate } = useTranslation();
	const [{ filters }] = useFilters();
	const [{ isConfigPanelOpen }] = useAnalysisConfigPanel(analysisId);

	const renderId = useMemo(generate, [
		fullscreen,
		filters,
		activeColumns,
		windowSize,
		analysisIds,
		isConfigPanelOpen
	]);

	if (isEmpty(data)) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	const { series, options } = computeData({
		data,
		isLegendEnabled,
		legendHeader,
		plots,
		scalesLabels
	});

	function handleLegendItemClick(e: ZingChartClickEvent) {
		// Fix for legend because this has line + scatter and we only care for lines
		const lineSeriesCount = series.filter(
			({ type }: any) => type === ZingChartTypes.Line
		).length;
		const index = e.plotindex - lineSeriesCount;

		!isForExport && togglePlot({ id: analysisId, plotIndex: index });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `line-${index}` });
		}
	}

	return (
		<Chart
			key={renderId}
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
