import { Analysis } from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

export function validateAnalysis(analysis: Analysis): Analysis {
	if (
		analysis.type === AnalysisType.Frequencies ||
		analysis.type === AnalysisType.FrequenciesV2 ||
		analysis.type === AnalysisType.Explore ||
		analysis.type === AnalysisType.ExploreV2 ||
		analysis.type === AnalysisType.CompareNumericV1 ||
		analysis.type === AnalysisType.CompareNumericV2 ||
		analysis.type === AnalysisType.Crosstab ||
		analysis.type === AnalysisType.CrosstabV2 ||
		analysis.type === AnalysisType.Kaplan ||
		analysis.type === AnalysisType.KaplanV2 ||
		analysis.type === AnalysisType.CorrelationsV1 ||
		analysis.type === AnalysisType.CorrelationsV2 ||
		analysis.type === AnalysisType.PlotNumeric ||
		analysis.type === AnalysisType.PlotNumericV2 ||
		analysis.type === AnalysisType.DensityPlot ||
		analysis.type === AnalysisType.DensityPlotV2 ||
		analysis.type === AnalysisType.TimeCourseV1 ||
		analysis.type === AnalysisType.TimeCourseV2 ||
		analysis.type === AnalysisType.ComparePaired ||
		analysis.type === AnalysisType.ComparePairedV2 ||
		analysis.type === AnalysisType.JADBio ||
		analysis.type === AnalysisType.LogisticRegression ||
		analysis.type === AnalysisType.LogisticRegressionV2 ||
		analysis.type === AnalysisType.NumberPlotXY
	) {
		return analysis;
	}

	const invalidAnalysis = {
		...analysis,
		invalid: true
	};

	return invalidAnalysis;
}
