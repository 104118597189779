import { ProjectType, ProjectStatus } from 'types/data/projects/constants';
import { Title, NarrowContainer } from 'components/Projects/CreateAndImport';
import { Typography } from 'components/UI/Typography';
import { InputType, SetState, SubscriptionAddonCode } from 'types/index';
import {
	createProject,
	CreateProject,
	ProjectFormValues,
	ProjectMetadata,
	ProjectMetadataType
} from 'store/data/projects';
import { Column, Row } from './CreateProjectForm.style';
import { AddonsToggles } from 'components/Addons';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Flex } from 'components/UI/Flex';
import { RequiredFields } from 'components/UI/RequiredFields';
import { StickyFooter } from 'components/UI/StickyFooter';
import { arrayUtils } from 'helpers/arrays';
import { useNavigation } from 'hooks/navigation';
import { useProjectMetadataDefinition, useShowAddons, useTranslation } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, useEffectOnce } from 'hooks/utils';
import {
	getInitialMetadataFormValues,
	getProjectValidationSchema,
	parseFormValuesToProject,
	transforMetadataParametersToFormRows
} from 'helpers/projects/projectMetadata';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { parseFromApiMetadata } from 'store/data/projects/parsers';

interface Props {
	isDPAEnabled: boolean;
	draftProject: CreateProject | null;
	userAddons: SubscriptionAddonCode[];
	activeUserAddons: SubscriptionAddonCode[];
	setDraftProject: SetState<CreateProject | null>;
	createProjectController: {
		loading: boolean;
		error: boolean;
		create: (...input: Parameters<typeof createProject>) => void;
	};
	onFinish: () => void;
}

export function CreateProjectForm({
	isDPAEnabled,
	draftProject,
	userAddons,
	activeUserAddons,
	setDraftProject,
	createProjectController,
	onFinish
}: Props) {
	const { routes, navigate } = useNavigation();
	const { translate } = useTranslation();

	const { data: showAddons } = useShowAddons();

	const [{ data: projectMetadataDefinition }] = useProjectMetadataDefinition();

	const {
		loading: creatingProject,
		error: errorCreatingProject,
		create: createProject
	} = createProjectController;

	useCompletedAction(creatingProject, errorCreatingProject, onFinish);

	// APPLY DRAFT PROJECT (IF EXISTS)
	useEffectOnce(() => {
		if (draftProject) {
			const { metadata, ...draftProjectValues } = draftProject;
			Object.keys(draftProjectValues).forEach(key => {
				setValue(
					key as keyof Omit<CreateProject, 'metadata'>,
					draftProjectValues[key as keyof Omit<CreateProject, 'metadata'>] ?? '',
					{
						shouldDirty: true,
						shouldValidate: true
					}
				);
			});

			const parsedMetadata = parseFromApiMetadata(metadata);

			if (parsedMetadata)
				Object.keys(parsedMetadata).forEach(key =>
					setValue(
						key as keyof ProjectMetadata,
						parsedMetadata[key as keyof ProjectMetadata],
						{
							shouldDirty: true,
							shouldValidate: true
						}
					)
				);
		}
	});

	const { validationSchema } = getProjectValidationSchema({
		projectMetadataDefinition,
		translate
	});

	const initialMetadataValues = getInitialMetadataFormValues(projectMetadataDefinition);

	const initialValues: ProjectFormValues = {
		projectName: '',
		givenProjectNumber: '',
		status: ProjectStatus.Ongoing,
		projectType: ProjectType.CORE,
		projectEndDate: '',
		description: '',
		slideFolderURL: '',
		activeAddonCodes: [],
		...initialMetadataValues
	};

	function toggleAddon(currentValues: SubscriptionAddonCode[], addon: SubscriptionAddonCode) {
		if (currentValues) {
			const newValues = arrayUtils.addOrSubstract(currentValues, addon);

			setValue('activeAddonCodes', newValues, {
				shouldDirty: true
			});
		}
	}

	const {
		Form,
		register,
		setValue,
		getValues,
		handleSubmit,
		handleTrimOnBlur,
		errors,
		isDirty,
		touchedFields,
		control,
		Controller,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema
	});

	const handleFormSubmit = handleSubmit(() => {
		if (isDirty && !creatingProject) {
			trimFields();

			const values = { ...getValues() };

			// DPA ENABLED FROM ENV FILES -> DRAFT THE NEW PROJECT DATA
			// IN ORDER CREATE AND SIGN DPA LATER IN ONE SINGLE ACTION
			if (isDPAEnabled) {
				setDraftProject(parseFormValuesToProject(values, projectMetadataDefinition));
				onFinish();
			}
			// DPA DISABLED FROM ENV FILES -> CARRY ON WITH PROJECT CREATION
			else {
				createProject(parseFormValuesToProject(values, projectMetadataDefinition));
			}
		}
	});

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.toString().trim());
		setValue('description', values.description.toString().trim());
		setValue('givenProjectNumber', values.givenProjectNumber.toString().trim());

		projectMetadataDefinition.forEach(metadataParameter => {
			if (values[metadataParameter.name])
				setValue(
					`${metadataParameter.name}`,
					values[metadataParameter.name].toString().trim()
				);
		});
	}

	const formMetadataParameters = transforMetadataParametersToFormRows(projectMetadataDefinition);

	return (
		<>
			<NarrowContainer>
				<Form onSubmit={handleFormSubmit}>
					<Title>
						{translate(({ projects }) => projects.createAndImport.title.create)}
					</Title>

					<Input
						{...register('projectName', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.createProject.projectTitle)}
						error={touchedFields.projectName ? errors.projectName?.message : ''}
						dataTestId="project-name-input"
						required
						autoFocus
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('description', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Textarea}
						label={translate(dict => dict.createProject.projectDescription)}
						dataTestId="project-description-input"
						rows={4}
					/>

					<Spacer size={s => s.s} />
					<Row>
						<Column>
							<Input
								{...register('givenProjectNumber', {
									onBlur: handleTrimOnBlur
								})}
								type={InputType.Text}
								label={translate(dict => dict.createProject.projectNumber)}
							/>
						</Column>
						<Column>
							<Controller
								control={control}
								name="projectEndDate"
								render={({ field: { value } }) => (
									<Input
										type={InputType.Date}
										value={value as number}
										label={translate(dict => dict.createProject.projectEndDate)}
										onDateChange={({ formattedDate }) =>
											setValue('projectEndDate', formattedDate, {
												shouldDirty: true,
												shouldValidate: true
											})
										}
									/>
								)}
							/>
						</Column>
					</Row>

					{formMetadataParameters.length > 0 && (
						<>
							<Spacer size={s => s.s} />
							{formMetadataParameters.map((metadataParametersRow, i) => {
								return (
									<div key={i}>
										<Spacer size={s => s.s} />
										<Row>
											{metadataParametersRow.map(metadataParameter => (
												<Column key={metadataParameter.name}>
													{metadataParameter.type ===
														ProjectMetadataType.String && (
														<Input
															{...register(
																`${metadataParameter.name}`,
																{
																	onBlur: handleTrimOnBlur
																}
															)}
															type={InputType.Text}
															label={translate(
																() => metadataParameter.label
															)}
															error={
																touchedFields[
																	metadataParameter.name
																]
																	? errors[metadataParameter.name]
																			?.message
																	: ''
															}
															tooltipComponent={
																<InfoTooltip
																	marginOffset={{
																		left: 0.4,
																		bottom: 0.4
																	}}
																	iconVisible
																	text={translate(
																		() =>
																			metadataParameter.description
																	)}
																/>
															}
															required={metadataParameter.mandatory}
														/>
													)}

													{metadataParameter.type ===
														ProjectMetadataType.Dropdown && (
														<Controller
															name={`${metadataParameter.name}`}
															control={control}
															render={({
																field: { value, onBlur }
															}) => (
																<CreatableSelect
																	label={translate(
																		() =>
																			metadataParameter.label
																	)}
																	tooltipPlace="top"
																	tooltipComponent={
																		<InfoTooltip
																			marginOffset={{
																				left: 0.4,
																				bottom: 0.4
																			}}
																			iconVisible
																			text={translate(
																				() =>
																					metadataParameter.description
																			)}
																		/>
																	}
																	placeholder={translate(
																		() =>
																			metadataParameter.placeholder
																	)}
																	value={
																		value
																			? {
																					value: value.toString(),
																					label: translate(
																						() =>
																							metadataParameter.categories.find(
																								p =>
																									p.id ===
																									value
																							)
																								?.name ??
																							''
																					)
																			  }
																			: undefined
																	}
																	items={metadataParameter.categories.map(
																		option => ({
																			label: translate(
																				() => option.name
																			),
																			value: `${option.id}`
																		})
																	)}
																	error={
																		touchedFields[
																			metadataParameter.name
																		]
																			? errors[
																					metadataParameter
																						.name
																			  ]?.message
																			: ''
																	}
																	onClear={() => {
																		setValue(
																			`${metadataParameter.name}`,
																			'',
																			{
																				shouldDirty: true,
																				shouldValidate: true
																			}
																		);
																	}}
																	borderError={
																		touchedFields[
																			metadataParameter.name
																		] &&
																		!!errors[
																			metadataParameter.name
																		]?.message
																	}
																	required={
																		metadataParameter.mandatory
																	}
																	onValueSelected={selectedValue =>
																		selectedValue &&
																		setValue(
																			`${metadataParameter.name}`,
																			selectedValue,
																			{
																				shouldDirty: true
																			}
																		)
																	}
																	onBlur={onBlur}
																	hint={translate(
																		() =>
																			metadataParameter.categories.find(
																				p => p.id === value
																			)?.description ?? ''
																	)}
																	scrollIntoView
																/>
															)}
														/>
													)}
												</Column>
											))}
										</Row>
									</div>
								);
							})}
						</>
					)}

					{showAddons && (
						<>
							<Spacer size={s => s.l} />
							<Row>
								<Column>
									<Flex align={a => a.center}>
										<Typography.Paragraph>
											{translate(dict => dict.accountUM.tabs.addons)}
										</Typography.Paragraph>
									</Flex>
									<Spacer size={s => s.s} />
									<Controller
										control={control}
										name="activeAddonCodes"
										render={({
											field: { value: currentEnabledAddons = [] }
										}) => (
											<AddonsToggles
												addons={userAddons}
												enabledAddons={
													currentEnabledAddons as SubscriptionAddonCode[]
												}
												activeUserAddons={activeUserAddons}
												onAddonClicked={(
													addonCode: SubscriptionAddonCode
												) =>
													toggleAddon(
														currentEnabledAddons as SubscriptionAddonCode[],
														addonCode
													)
												}
											/>
										)}
									/>
								</Column>
							</Row>
						</>
					)}

					<Spacer size={s => s.m} />
					<RequiredFields />
				</Form>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: creatingProject,
					disabled: !canSubmitForm,
					onClick: handleFormSubmit
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: creatingProject ? undefined : () => navigate(routes.projects.list)
				}}
				maxWidth={65.2}
			/>
		</>
	);
}
