import { useMemo } from 'react';

import { OneWayAnovaResultsV1, OneWayAnovaResultsDataV1 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';

interface Props {
	results: OneWayAnovaResultsV1;
}

export function CompareNumericOneWayAnovaV1({ results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.oneWayAnova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translate(({ errors }) => errors.api.analyses.statistics.oneWayAnova)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}

function Result({ data }: { data: OneWayAnovaResultsDataV1 }) {
	const { fStatistic, pStatistic } = useMemo(() => {
		const fStatistic = getFStatistic({
			df: data.df,
			errorDf: data.errorDf,
			fValue: data.fValue
		});

		const pStatistic = getPStatistic({
			pValue: data.pValue
		});

		return { fStatistic, pStatistic };
	}, [data]);

	return (
		<Table.Responsive horizontalScroll fullWidth={false}>
			<Table>
				<Table.Body>
					<Table.Row>
						<Table.Cell title={fStatistic.text} width={14} noWrap>
							{fStatistic.JSX}
						</Table.Cell>
						<Table.Cell title={pStatistic.text} width={14} noWrap>
							{pStatistic.JSX}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

function getFStatistic(input: { df: number; errorDf: number; fValue: number }) {
	const { df, errorDf, fValue } = input;

	const { value: parsedFValue } = parseAnalysisNumber(fValue);

	const output = {
		JSX: (
			<>
				<i>{`F`}</i>
				{`(${df}, ${errorDf}) = ${parsedFValue}`}
			</>
		),
		text: `F(${df}, ${errorDf}) = ${parsedFValue}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}
