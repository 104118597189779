import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	ClearSnapshotAction,
	CreateSnapshotAction,
	DeleteSnapshotAction,
	GetSnapshotAction,
	GetSnapshotsAction,
	SetActiveSnapshotAction,
	UpdateSnapshotAction
} from './types';
import { deleteAnalysesAction } from '../analyses';
import { deleteJADBioAnalyses, extractJADBioAnalysesIds } from 'store/addons/jadbio';
import {
	ComparePairedAnalysis,
	ComparePairedDataModels,
	CorrelationsAnalysis,
	CrosstabAnalysis,
	CrosstabAnalysisV2,
	LogisticRegressionAnalysis,
	NumberPlotXYAnalysis,
	PlotNumericAnalysis,
	PlotNumericBoxplotResults,
	PlotNumericColumnsResults,
	PlotNumericScatterResults,
	Analysis,
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	KaplanMeierAnalysis,
	DensityPlotAnalysis,
	ExploreAnalysis,
	FrequenciesAnalysis,
	KaplanMeierDataModels,
	ExploreAnalysisV2,
	AnalysisVariable,
	CorrelationsV1Analysis,
	DensityPlotAnalysisV2,
	TimeCourseAnalysisV2,
	TimeCourseAnalysisV1,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysisV2,
	GetKaplanMeierDurationRequestInputV2,
	GetLogRankDurationStatisticsV2,
	GetLogRankTimeRangeStatisticsV2,
	ComparePairedAnalysisV2,
	GetKaplanMeierTimeRangeRequestInputV2
} from 'api/data/analyses/types';
import { Dictionary } from 'environment';
import { AnalysisType } from 'api/data/analyses/constants';
import { parseApiEntryFilters } from 'helpers/analysis';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildVariablesDataFromStoreData
} from 'helpers/variables';

export const getSnapshotAction = (
	payload: ActionPayload<GetSnapshotAction>
): GetSnapshotAction => ({
	type: ActionTypes.GET_SNAPSHOT,
	payload
});

export const getSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const data = await context.api.data.snapshots().getSnapshot(snapshotId, projectId);

				// TODO: refactor when BE resolves this issue
				try {
					const variablesStoreData =
						getState().data.variables.byProjectId[projectId].current;
					const { variablesMap, variableSetsMap } =
						buildVariablesDataFromStoreData(variablesStoreData);

					const aggRuleNameToAggRuleMap =
						buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

					const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
						(acc, [ruleName, rule]) => {
							return {
								...acc,
								[ruleName]: variablesMap[rule.aggregator.variableName]
							};
						},
						{}
					);

					const filters = parseApiEntryFilters(data.filters, {
						...variablesMap,
						...aggRuleToVariableMap
					});

					const analysesPromises = await Promise.allSettled(
						data.analysisList.map(async (a: Analysis) => {
							if (a.type === AnalysisType.CompareNumericV2) {
								const oldCompareNumericAnalysis = a as CompareNumericAnalysisV2;
								const {
									categoryVariable,
									categoryVariableTwo,
									exploreVariable,
									exploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								const compareNumericAnalysisDataset = await context.api.data
									.analyses()
									.getCompareNumericV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										groupVariables:
											categoryVariableTwo && categoryVariable
												? [categoryVariable, categoryVariableTwo]
												: categoryVariable
												? [categoryVariable]
												: [],
										numericVariables:
											exploreVariableTwo && exploreVariable
												? [exploreVariable, exploreVariableTwo]
												: exploreVariable
												? [exploreVariable]
												: [],
										filters
									});

								const {
									independentV2,
									independent,
									kruskalV2,
									kruskal,
									mannWhitneyV2,
									mannWhitney,
									oneWayAnovaV2,
									oneWayAnova,
									oneWayManovaV2,
									oneWayManova,
									shapiroV2,
									shapiro,
									tukeyV2,
									tukey,
									twoWayAnovaV2,
									twoWayAnova,
									twoWayManovaV2,
									twoWayManova
								} = oldCompareNumericAnalysis.input.statistics;

								oldCompareNumericAnalysis.output.dataset =
									compareNumericAnalysisDataset;

								if (
									(independentV2 || independent) &&
									categoryVariable &&
									exploreVariable
								) {
									const independentStatistic = await context.api.data
										.analyses()
										.getIndependentStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.independent =
										independentStatistic;
								}

								if ((kruskalV2 || kruskal) && exploreVariable && categoryVariable) {
									const kruskalStatistic = await context.api.data
										.analyses()
										.getKruskalStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.kruskal =
										kruskalStatistic;
								}

								if (
									(mannWhitneyV2 || mannWhitney) &&
									exploreVariable &&
									categoryVariable
								) {
									const mannWhitneyStatistic = await context.api.data
										.analyses()
										.getMannWhitneyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.mannWhitney =
										mannWhitneyStatistic;
								}

								if (
									(oneWayAnovaV2 || oneWayAnova) &&
									exploreVariable &&
									categoryVariable
								) {
									const oneWayAnovaStatistic = await context.api.data
										.analyses()
										.getOneWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariables: [categoryVariable],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayAnova =
										oneWayAnovaStatistic;
								}

								if (
									(oneWayManovaV2 || oneWayManova) &&
									exploreVariableTwo &&
									exploreVariable &&
									categoryVariable
								) {
									const oneWayManovaStatistic = await context.api.data
										.analyses()
										.getOneWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable],
											numericVariables: [
												exploreVariable,
												exploreVariableTwo
											] as AnalysisVariable[],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayManova =
										oneWayManovaStatistic;
								}

								if (
									(shapiroV2 || shapiro) &&
									categoryVariableTwo &&
									exploreVariableTwo &&
									categoryVariable &&
									exploreVariable
								) {
									const shapiroStatistic = await context.api.data
										.analyses()
										.getShapiroStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariables: [exploreVariable, exploreVariableTwo],
											groupVariables: [categoryVariable, categoryVariableTwo],

											filters
										});

									oldCompareNumericAnalysis.output.statistics.shapiro =
										shapiroStatistic;
								}

								if ((tukeyV2 || tukey) && exploreVariable && categoryVariable) {
									const tukeyStatistic = await context.api.data
										.analyses()
										.getTukeyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.tukey =
										tukeyStatistic;
								}

								if (
									(twoWayAnovaV2 || twoWayAnova) &&
									categoryVariable &&
									categoryVariableTwo &&
									exploreVariable
								) {
									const twoWayAnovaStatistic = await context.api.data
										.analyses()
										.getTwoWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariable: exploreVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaStatistic;
								}

								if (
									(twoWayManovaV2 || twoWayManova) &&
									categoryVariableTwo &&
									exploreVariableTwo &&
									exploreVariable &&
									categoryVariable
								) {
									const twoWayManovaStatistic = await context.api.data
										.analyses()
										.getTwoWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayManova =
										twoWayManovaStatistic;
								}

								return oldCompareNumericAnalysis;
							}

							if (
								a.type === AnalysisType.CompareNumericV1
								// a.type === AnalysisType.CompareNumericInit
							) {
								const oldCompareNumericAnalysis = {
									...a,
									type: AnalysisType.CompareNumericV1
								} as CompareNumericAnalysisV1;

								const compareNumericAnalysisDataset = await context.api.data
									.analyses()
									.getCompareNumericV1({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										categoryVariable: oldCompareNumericAnalysis.input.variables
											.categoryVariableTwo
											? [
													oldCompareNumericAnalysis.input.variables
														.categoryVariable,
													oldCompareNumericAnalysis.input.variables
														.categoryVariableTwo
											  ]
											: oldCompareNumericAnalysis.input.variables
													.categoryVariable,
										exploreVariable: oldCompareNumericAnalysis.input.variables
											.exploreVariableTwo
											? [
													oldCompareNumericAnalysis.input.variables
														.exploreVariable,
													oldCompareNumericAnalysis.input.variables
														.exploreVariableTwo
											  ]
											: oldCompareNumericAnalysis.input.variables
													.exploreVariable,
										filters
									});

								const {
									independentV1,
									kruskalV1,
									mannWhitneyV1,
									oneWayAnovaV1,
									oneWayManovaV1,
									shapiroV1,
									tukeyV1,
									twoWayAnovaV1,
									twoWayManovaV1,
									independent,
									kruskal,
									mannWhitney,
									oneWayAnova,
									oneWayManova,
									shapiro,
									tukey,
									twoWayAnova,
									twoWayManova
								} = oldCompareNumericAnalysis.input.statistics;

								oldCompareNumericAnalysis.output.dataset =
									compareNumericAnalysisDataset;

								if (independentV1 || independent) {
									const independentStatistic = await context.api.data
										.analyses()
										.getIndependentStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.independent =
										independentStatistic;
								}

								if (kruskalV1 || kruskal) {
									const kruskalStatistic = await context.api.data
										.analyses()
										.getKruskalStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.kruskal =
										kruskalStatistic;
								}

								if (mannWhitneyV1 || mannWhitney) {
									const mannWhitneyStatistic = await context.api.data
										.analyses()
										.getMannWhitneyStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.mannWhitney =
										mannWhitneyStatistic;
								}

								if (oneWayAnovaV1 || oneWayAnova) {
									const oneWayAnovaStatistic = await context.api.data
										.analyses()
										.getOneWayAnovaStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayAnova =
										oneWayAnovaStatistic;
								}

								if (
									(oneWayManovaV1 || oneWayManova) &&
									oldCompareNumericAnalysis.input.variables.exploreVariableTwo
								) {
									const oneWayManovaStatistic = await context.api.data
										.analyses()
										.getOneWayManovaStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable:
												oldCompareNumericAnalysis.input.variables
													.categoryVariable,
											exploreVariable: [
												oldCompareNumericAnalysis.input.variables
													.exploreVariable,
												oldCompareNumericAnalysis.input.variables
													.exploreVariableTwo
											],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayManova =
										oneWayManovaStatistic;
								}

								if (shapiroV1 || shapiro) {
									const shapiroStatistic = await context.api.data
										.analyses()
										.getShapiroStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.shapiro =
										shapiroStatistic;
								}

								if (tukeyV1 || tukey) {
									const tukeyStatistic = await context.api.data
										.analyses()
										.getTukeyStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCompareNumericAnalysis.input.variables,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.tukey =
										tukeyStatistic;
								}

								if (
									(twoWayAnovaV1 || twoWayAnova) &&
									oldCompareNumericAnalysis.input.variables.categoryVariableTwo
								) {
									const twoWayAnovaStatistic = await context.api.data
										.analyses()
										.getTwoWayAnovaStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable: [
												oldCompareNumericAnalysis.input.variables
													.categoryVariable,
												oldCompareNumericAnalysis.input.variables
													.categoryVariableTwo
											],
											exploreVariable:
												oldCompareNumericAnalysis.input.variables
													.exploreVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaStatistic;
								}

								if (
									(twoWayManovaV1 || twoWayManova) &&
									oldCompareNumericAnalysis.input.variables.categoryVariableTwo &&
									oldCompareNumericAnalysis.input.variables.exploreVariableTwo
								) {
									const twoWayManovaStatistic = await context.api.data
										.analyses()
										.getTwoWayManovaStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable: [
												oldCompareNumericAnalysis.input.variables
													.categoryVariable,
												oldCompareNumericAnalysis.input.variables
													.categoryVariableTwo
											],
											exploreVariable: [
												oldCompareNumericAnalysis.input.variables
													.exploreVariable,
												oldCompareNumericAnalysis.input.variables
													.exploreVariableTwo
											],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayManova =
										twoWayManovaStatistic;
								}

								return oldCompareNumericAnalysis;
							}

							if (a.type === AnalysisType.ComparePaired) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysis;

								const isSingleEntryPerSubject =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT;
								const usingSeries =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.USING_SERIES;

								if (usingSeries) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePaired(
											{
												projectId: Number(projectId),
												filters,
												set: {
													setName:
														oldComparePairedAnalysis.input.variables
															.setName
												},
												testVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.testVariable
												},
												groupVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.groupVariable
												},
												groupOne:
													oldComparePairedAnalysis.input.variables
														.groupOne,
												groupTwo:
													oldComparePairedAnalysis.input.variables
														.groupTwo
											},
											oldComparePairedAnalysis.input.variables.setName
										);

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else if (isSingleEntryPerSubject) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePaired(
											{
												projectId: Number(projectId),
												filters,
												numericVariableOne: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.numericVariableOne
												},
												numericVariableTwo: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.numericVariableTwo
												}
											},
											undefined,
											true
										);

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePaired({
											projectId: Number(projectId),
											filters,
											testVariable: {
												variableName:
													oldComparePairedAnalysis.input.variables
														.numericVariableOne
											},
											groupVariable: {
												variableName:
													oldComparePairedAnalysis.input.variables
														.catVarnameDiffSamples
											},
											groupOne:
												oldComparePairedAnalysis.input.variables
													.firstCategoryValue,
											groupTwo:
												oldComparePairedAnalysis.input.variables
													.secondCategoryValue,
											pairIdentifier: {
												variableName:
													oldComparePairedAnalysis.input.variables
														.patientIdentifierVarname
											}
										});

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								}

								const { pairedTTest, pairedWilcoxon } =
									oldComparePairedAnalysis.input.statistics;

								if (pairedTTest) {
									if (usingSeries) {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatistics(
												{
													projectId: Number(projectId),
													filters,
													testVariable: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.testVariable
													},
													groupVariable: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.groupVariable
													},
													groupOne:
														oldComparePairedAnalysis.input.variables
															.groupOne,
													groupTwo:
														oldComparePairedAnalysis.input.variables
															.groupTwo,
													set: {
														setName:
															oldComparePairedAnalysis.input.variables
																.setName
													}
												},
												oldComparePairedAnalysis.input.variables.setName
											);

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									} else if (isSingleEntryPerSubject) {
										const pairedTTextStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatistics(
												{
													projectId: Number(projectId),
													filters,
													numericVariableOne: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.numericVariableOne
													},
													numericVariableTwo: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.numericVariableTwo
													}
												},
												undefined,
												true
											);

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTextStatistic;
									} else {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatistics({
												projectId: Number(projectId),
												filters,
												testVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.numericVariableOne
												},
												groupVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.catVarnameDiffSamples
												},
												groupOne:
													oldComparePairedAnalysis.input.variables
														.firstCategoryValue,
												groupTwo:
													oldComparePairedAnalysis.input.variables
														.secondCategoryValue,
												pairIdentifier: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.patientIdentifierVarname
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									}

									return oldComparePairedAnalysis;
								}

								if (pairedWilcoxon) {
									if (usingSeries) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatistics(
												{
													projectId: Number(projectId),
													filters,
													testVariable: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.testVariable
													},
													groupVariable: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.groupVariable
													},
													groupOne:
														oldComparePairedAnalysis.input.variables
															.groupOne,
													groupTwo:
														oldComparePairedAnalysis.input.variables
															.groupTwo,
													set: {
														setName:
															oldComparePairedAnalysis.input.variables
																.setName
													}
												},
												oldComparePairedAnalysis.input.variables.setName
											);

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else if (isSingleEntryPerSubject) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatistics(
												{
													projectId: Number(projectId),
													filters,
													numericVariableOne: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.numericVariableOne
													},
													numericVariableTwo: {
														variableName:
															oldComparePairedAnalysis.input.variables
																.numericVariableTwo
													}
												},
												undefined,
												true
											);

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatistics({
												projectId: Number(projectId),
												filters,
												testVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.numericVariableOne
												},
												groupVariable: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.catVarnameDiffSamples
												},
												groupOne:
													oldComparePairedAnalysis.input.variables
														.firstCategoryValue,
												groupTwo:
													oldComparePairedAnalysis.input.variables
														.secondCategoryValue,
												pairIdentifier: {
													variableName:
														oldComparePairedAnalysis.input.variables
															.patientIdentifierVarname
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									}
								}
							}

							if (a.type === AnalysisType.ComparePairedV2) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysisV2;
								const {
									input: {
										dataModel,
										variables: {
											afterVariable,
											beforeVariable,
											groupVariable,
											numericVariable,
											pairIdentifier
										}
									}
								} = oldComparePairedAnalysis;

								if (
									dataModel ===
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
									numericVariable &&
									groupVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											numericVariable,
											groupVariable
										});

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else if (
									dataModel ===
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
									beforeVariable &&
									afterVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2(
											{
												projectId: Number(projectId),
												filters,
												beforeVariable,
												afterVariable
											},
											true
										);

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else if (
									dataModel ===
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
									pairIdentifier &&
									numericVariable &&
									groupVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											pairingVariable: pairIdentifier,
											numericVariable,
											groupVariable
										});

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								}

								const { pairedTTestV2, pairedWilcoxonV2 } =
									oldComparePairedAnalysis.input.statistics;

								if (pairedTTestV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										numericVariable &&
										groupVariable
									) {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										beforeVariable &&
										afterVariable
									) {
										const pairedTTextStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable,
													afterVariable
												},
												dataModel
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTextStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										numericVariable &&
										groupVariable &&
										pairIdentifier
									) {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									}

									return oldComparePairedAnalysis;
								}

								if (pairedWilcoxonV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										numericVariable &&
										groupVariable
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										beforeVariable &&
										afterVariable
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable,
													afterVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										numericVariable &&
										groupVariable &&
										pairIdentifier
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									}
								}
							}

							if (a.type === AnalysisType.CorrelationsV2) {
								const oldCorrelationsAnalysis = a as CorrelationsAnalysis;

								const correlationsAnalysis = await context.api.data
									.analyses()
									.getCorrelations({
										projectId: Number(projectId),
										filters,
										...oldCorrelationsAnalysis.input.variables
									});

								oldCorrelationsAnalysis.output.dataset = correlationsAnalysis;

								const { linearRegressionV2, pearsonV2, spearmanV2 } =
									oldCorrelationsAnalysis.input.statistics;

								if (linearRegressionV2) {
									const linearRegressionStatistic = await context.api.data
										.analyses()
										.getLinearRegressionStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.linearRegressionV2 =
										linearRegressionStatistic;
								}

								if (pearsonV2) {
									const pearsonStatistic = await context.api.data
										.analyses()
										.getPearsonStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.pearsonV2 =
										pearsonStatistic;
								}

								if (spearmanV2) {
									const spearmanStatistic = await context.api.data
										.analyses()
										.getSpearmanStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.spearmanV2 =
										spearmanStatistic;
								}

								return oldCorrelationsAnalysis;
							}

							if (a.type === AnalysisType.CorrelationsV1) {
								const old = a as CorrelationsV1Analysis;
								// backwards compatibility
								const oldCorrelationsAnalysis = {
									...old,
									output: {
										...old.output,
										statistics: {
											...old.output.statistics,
											linearRegression:
												old.output.statistics.linearRegression,
											pearson: old.output.statistics.pearson,
											spearman: old.output.statistics.spearman
										} as CorrelationsV1Analysis['output']['statistics']
									}
								} as CorrelationsV1Analysis;

								const correlationsAnalysis = await context.api.data
									.analyses()
									.getCorrelationsV1({
										projectId: Number(projectId),
										filters,
										...oldCorrelationsAnalysis.input.variables
									});

								oldCorrelationsAnalysis.output.dataset = correlationsAnalysis;

								const {
									linearRegressionV1,
									pearsonV1,
									spearmanV1,
									linearRegression,
									pearson,
									spearman
								} = oldCorrelationsAnalysis.input.statistics;

								if (linearRegressionV1 || linearRegression) {
									const linearRegressionStatistic = await context.api.data
										.analyses()
										.getLinearRegressionStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.linearRegression =
										linearRegressionStatistic;
								}

								if (pearsonV1 || pearson) {
									const pearsonStatistic = await context.api.data
										.analyses()
										.getPearsonStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.pearson =
										pearsonStatistic;
								}

								if (spearmanV1 || spearman) {
									const spearmanStatistic = await context.api.data
										.analyses()
										.getSpearmanStatisticsV1({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.spearman =
										spearmanStatistic;
								}

								return oldCorrelationsAnalysis;
							}

							if (a.type === AnalysisType.Crosstab) {
								const oldCrosstabAnalysis = a as CrosstabAnalysis;

								const crosstabAnalysis = await context.api.data
									.analyses()
									.getCrosstab({
										projectId: Number(projectId),
										filters,
										row: {
											variableName:
												oldCrosstabAnalysis.input.variables.yVariable
										},
										column: {
											variableName:
												oldCrosstabAnalysis.input.variables.groupingVariable
										}
									});

								oldCrosstabAnalysis.output.dataset = {
									columns: crosstabAnalysis.groupingVariable,
									rows: crosstabAnalysis.yVariable
								};

								const { chiSquare, fisher, mcNemar } =
									oldCrosstabAnalysis.input.statistics;

								if (chiSquare) {
									const chiSquareStatistic = await context.api.data
										.analyses()
										.getChiStatistics({
											projectId: Number(projectId),
											rowVariable:
												oldCrosstabAnalysis.input.variables.yVariable,
											columnVariable:
												oldCrosstabAnalysis.input.variables
													.groupingVariable,
											filters
										});

									oldCrosstabAnalysis.output.statistics.chiSquare =
										chiSquareStatistic;
								}

								if (fisher) {
									const fisherStatistic = await context.api.data
										.analyses()
										.getFisherStatistics({
											projectId: Number(projectId),
											rowVariable:
												oldCrosstabAnalysis.input.variables.yVariable,
											columnVariable:
												oldCrosstabAnalysis.input.variables
													.groupingVariable,
											filters
										});

									oldCrosstabAnalysis.output.statistics.fisher = fisherStatistic;
								}

								if (mcNemar) {
									const mcNemarStatistic = await context.api.data
										.analyses()
										.getMcNemarStatistics({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable:
												oldCrosstabAnalysis.input.variables.yVariable,
											exploreVariable:
												oldCrosstabAnalysis.input.variables
													.groupingVariable,
											filters
										});

									oldCrosstabAnalysis.output.statistics.mcNemar =
										mcNemarStatistic;
								}

								return oldCrosstabAnalysis;
							}

							if (a.type === AnalysisType.CrosstabV2) {
								const oldCrosstabAnalysis = a as CrosstabAnalysisV2;

								const {
									input: {
										variables: { columnVariable, rowVariable },
										statistics: { chiSquareV2, fisherV2, mcNemarV2 }
									}
								} = oldCrosstabAnalysis;

								if (rowVariable && columnVariable) {
									const dataset = await context.api.data
										.analyses()
										.getCrosstabV2({
											projectId: Number(projectId),
											filters,
											rowVariable,
											columnVariable
										});

									oldCrosstabAnalysis.output.dataset = dataset;

									if (chiSquareV2) {
										const chiSquareStatistic = await context.api.data
											.analyses()
											.getChiStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.chiSquare =
											chiSquareStatistic;
									}

									if (fisherV2) {
										const fisherStatistic = await context.api.data
											.analyses()
											.getFisherStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.fisher =
											fisherStatistic;
									}

									if (mcNemarV2) {
										const mcNemarStatistic = await context.api.data
											.analyses()
											.getMcNemarStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.mcNemar =
											mcNemarStatistic;
									}
								}

								return oldCrosstabAnalysis;
							}

							if (a.type === AnalysisType.DensityPlot) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysis;

								const densityPlotAnalysis = await context.api.data
									.analyses()
									.getDensityPlot({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldDensityPlotAnalysis.input.variables,
										filters
									});

								oldDensityPlotAnalysis.output.dataset = densityPlotAnalysis;

								return oldDensityPlotAnalysis;
							}

							if (a.type === AnalysisType.DensityPlotV2) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysisV2;

								const densityPlotAnalysis = await context.api.data
									.analyses()
									.getDensityPlotV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldDensityPlotAnalysis.input.variables,
										filters
									});

								oldDensityPlotAnalysis.output.dataset = densityPlotAnalysis;

								return oldDensityPlotAnalysis;
							}

							if (a.type === AnalysisType.Explore) {
								const oldExploreAnalysis = a as ExploreAnalysis;

								const exploreAnalysis = await context.api.data
									.analyses()
									.getExplore({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										requestedVariables: oldExploreAnalysis.input.variables,
										filters
									});

								oldExploreAnalysis.output.dataset = exploreAnalysis;

								return oldExploreAnalysis;
							}

							if (a.type === AnalysisType.ExploreV2) {
								const oldExploreAnalysis = a as ExploreAnalysisV2;

								const exploreAnalysis = await context.api.data
									.analyses()
									.getExploreV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										numericVariables: oldExploreAnalysis.input.variables,
										filters
									});

								oldExploreAnalysis.output.dataset = exploreAnalysis;

								return oldExploreAnalysis;
							}

							if (a.type === AnalysisType.Frequencies) {
								const oldFrequencyAnalysis = a as FrequenciesAnalysis;

								const frequencyAnalysis = await context.api.data
									.analyses()
									.getFrequencies({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldFrequencyAnalysis.input.variables,
										filters
									});

								oldFrequencyAnalysis.output.dataset = frequencyAnalysis;

								return oldFrequencyAnalysis;
							}

							if (a.type === AnalysisType.FrequenciesV2) {
								const oldFrequencyAnalysis = a as FrequenciesAnalysisV2;

								const {
									input: {
										variables: { categoryVariable }
									}
								} = oldFrequencyAnalysis;

								if (categoryVariable) {
									const frequencyAnalysis = await context.api.data
										.analyses()
										.getFrequenciesV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable,
											filters
										});

									oldFrequencyAnalysis.output.dataset = frequencyAnalysis;

									return oldFrequencyAnalysis;
								}
							}

							if (a.type === AnalysisType.Kaplan) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysis;

								const model = oldKaplanMeierAnalysis.input.selectedDataModel;
								const kaplanMeierAnalysis = await context.api.data
									.analyses()
									.getKaplanMeier(
										{
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...(model ===
												KaplanMeierDataModels.timeRangeWithEvent && {
												startDate: {
													variableName:
														oldKaplanMeierAnalysis.input.variables
															.startDate
												},
												endDate: {
													variableName:
														oldKaplanMeierAnalysis.input.variables
															.endDate
												},
												timeUnit:
													oldKaplanMeierAnalysis.input.variables.timeUnit,
												...(oldKaplanMeierAnalysis.input.variables
													.autofillDate && {
													autofillDate:
														oldKaplanMeierAnalysis.input.variables
															.autofillDate
												})
											}),
											...(model === KaplanMeierDataModels.duration && {
												durationVariable: {
													variableName:
														oldKaplanMeierAnalysis.input.variables
															.durationVariable
												}
											}),
											eventVariable: {
												variableName:
													oldKaplanMeierAnalysis.input.variables
														.eventVariable
											},
											positiveEvent:
												oldKaplanMeierAnalysis.input.variables
													.positiveEvent,
											...(oldKaplanMeierAnalysis.input.variables
												.groupVariable && {
												groupVariable: {
													variableName:
														oldKaplanMeierAnalysis.input.variables
															.groupVariable
												}
											}),

											filters
										},
										oldKaplanMeierAnalysis.input.selectedDataModel
									);

								oldKaplanMeierAnalysis.output.dataset = kaplanMeierAnalysis;

								const { logRank } = oldKaplanMeierAnalysis.input.statistics;

								if (logRank) {
									const logRankStatistic = await context.api.data
										.analyses()
										.getLogRankStatistics(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												durationVariable:
													oldKaplanMeierAnalysis.input.variables
														.durationVariable,
												eventVariable:
													oldKaplanMeierAnalysis.input.variables
														.eventVariable,
												positiveEvent:
													oldKaplanMeierAnalysis.input.variables
														.positiveEvent,
												groupVariable: oldKaplanMeierAnalysis.input
													.variables.groupVariable as string,
												filters
											},
											oldKaplanMeierAnalysis.input.selectedDataModel
										);

									oldKaplanMeierAnalysis.output.statistics.logRank =
										logRankStatistic;
								}

								return oldKaplanMeierAnalysis;
							}

							if (a.type === AnalysisType.KaplanV2) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysisV2;

								const {
									input: {
										selectedDataModel: model,
										variables: {
											durationVariable,
											endDate,
											eventVariable,
											positiveEvent,
											startDate,
											timeUnit,
											autofillDate,
											groupVariable,
											endTimeCensorVariable
										}
									}
								} = oldKaplanMeierAnalysis;

								if (model === KaplanMeierDataModels.duration) {
									const input: GetKaplanMeierDurationRequestInputV2 = {
										projectId: Number(projectId),
										durationVariable,
										events: positiveEvent,
										eventVariable,
										filters,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									try {
										const kaplanMeierAnalysis = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);

										oldKaplanMeierAnalysis.output.dataset = kaplanMeierAnalysis;
									} catch (e) {
										console.error(e);
									}
								}

								if (model === KaplanMeierDataModels.timeRangeWithEvent) {
									const input: GetKaplanMeierTimeRangeRequestInputV2 = {
										projectId: Number(projectId),
										eventVariable,
										events: positiveEvent,
										startTimeVariable: startDate,
										endTimeVariable: endDate,
										endTimeFillValue: autofillDate,
										timeUnit,
										filters,
										endTimeCensorVariable,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									const kaplanMeierAnalysis = await context.api.data
										.analyses()
										.getKaplanMeierV2(input, model);

									oldKaplanMeierAnalysis.output.dataset = kaplanMeierAnalysis;
								}

								const { logRankV2 } = oldKaplanMeierAnalysis.input.statistics;

								if (logRankV2) {
									const {
										input: {
											selectedDataModel: dataModel,
											variables: {
												durationVariable,
												eventVariable,
												positiveEvent: events,
												endDate: endTimeVariable,
												startDate: startTimeVariable,
												groupVariable,
												endTimeCensorVariable,
												timeUnit,
												autofillDate
											}
										}
									} = oldKaplanMeierAnalysis;

									if (groupVariable && dataModel) {
										if (
											model === KaplanMeierDataModels.duration &&
											durationVariable &&
											eventVariable
										) {
											const input: GetLogRankDurationStatisticsV2 = {
												events,
												durationVariable,
												eventVariable,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											oldKaplanMeierAnalysis.output.statistics.logRank =
												logRankStatistic;
										} else if (
											model === KaplanMeierDataModels.timeRangeWithEvent &&
											startTimeVariable &&
											endTimeVariable &&
											eventVariable
										) {
											const input: GetLogRankTimeRangeStatisticsV2 = {
												events,
												eventVariable,
												endTimeVariable,
												startTimeVariable,
												timeUnit,
												endTimeCensorVariable,
												endTimeFillValue: autofillDate,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											oldKaplanMeierAnalysis.output.statistics.logRank =
												logRankStatistic;
										}
									}
								}
								return oldKaplanMeierAnalysis;
							}

							if (a.type === AnalysisType.LogisticRegression) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysis;

								const logisticRegressionAnalysis = await context.api.data
									.analyses()
									.getLogisticReggresion({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldLogisticRegressionAnalysis.input.variables,
										filters
									});

								oldLogisticRegressionAnalysis.output.dataset =
									logisticRegressionAnalysis;

								return oldLogisticRegressionAnalysis;
							}

							if (a.type === AnalysisType.LogisticRegressionV2) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysisV2;

								const { yVariable, xVariable, outcomes, groupVariables } =
									oldLogisticRegressionAnalysis.input.variables;

								if (yVariable && xVariable && outcomes.length) {
									const logisticRegressionAnalysis = await context.api.data
										.analyses()
										.getLogisticReggresionV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											outcomes,
											groupVariables,
											filters
										});

									oldLogisticRegressionAnalysis.output.dataset =
										logisticRegressionAnalysis;

									return oldLogisticRegressionAnalysis;
								}
							}

							if (a.type === AnalysisType.NumberPlotXY) {
								const oldNumberPlotXYAnalysis = a as NumberPlotXYAnalysis;

								const numberPlotXYAnalysis = await context.api.data
									.analyses()
									.getNumberPlotXY({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldNumberPlotXYAnalysis.input.variables,
										filters
									});

								oldNumberPlotXYAnalysis.output.dataset = numberPlotXYAnalysis;

								return oldNumberPlotXYAnalysis;
							}

							if (a.type === AnalysisType.PlotNumeric) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysis;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0) {
									const plotNumericColumnAnalysis = (await context.api.data
										.analyses()
										.getPlotNumeric(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												...oldPlotNumericAnalysis.input.variables,
												filters
											},
											0
										)) as PlotNumericColumnsResults;

									oldPlotNumericAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis;
								}

								if (prevActiveTab === 1) {
									const plotNumericBoxplotAnalysis = (await context.api.data
										.analyses()
										.getPlotNumeric(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												...oldPlotNumericAnalysis.input.variables,
												filters
											},
											1
										)) as PlotNumericBoxplotResults;

									oldPlotNumericAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis;
								}

								if (prevActiveTab === 2) {
									const plotNumericScatterAnalysis = (await context.api.data
										.analyses()
										.getPlotNumeric(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												...oldPlotNumericAnalysis.input.variables,
												filters
											},
											2
										)) as PlotNumericScatterResults;

									oldPlotNumericAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis;
								}

								return oldPlotNumericAnalysis;
							}

							if (a.type === AnalysisType.PlotNumericV2) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysisV2;

								const {
									input: {
										variables: {
											numericVariable,
											groupingVariable,
											categoryVariable
										}
									}
								} = oldPlotNumericAnalysis;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0 && numericVariable && categoryVariable) {
									const plotNumericColumnAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											0
										);

									oldPlotNumericAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis.columns;
								}

								if (prevActiveTab === 1 && numericVariable && categoryVariable) {
									const plotNumericBoxplotAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											1
										);

									oldPlotNumericAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis.boxplot;
								}

								if (prevActiveTab === 2 && numericVariable && categoryVariable) {
									const plotNumericScatterAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [categoryVariable],
												filters
											},
											2
										);

									oldPlotNumericAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis.scatter;
								}

								return oldPlotNumericAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV2) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV2;

								const timeCourseAnalysis = await context.api.data
									.analyses()
									.getTimeCourseV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										format: 'unix',
										...oldTimeCourseAnalysis.input.variables,
										filters
									});

								oldTimeCourseAnalysis.output.dataset = timeCourseAnalysis;

								return oldTimeCourseAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV1) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV1;

								const timeCourseAnalysis = await context.api.data
									.analyses()
									.getTimeCourseV1({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										format: 'unix',
										...oldTimeCourseAnalysis.input.variables,
										filters
									});

								oldTimeCourseAnalysis.output.dataset = timeCourseAnalysis;

								return oldTimeCourseAnalysis;
							}

							return a;
						})
					);

					// filter the analyses that were not fetched
					const newAnalysisList = await getFulfilledValues(analysesPromises);
					data.analysisList = newAnalysisList;
					dispatch(getSnapshotAction({ projectId, snapshotId, data }));
				} catch (e: any) {
					throw new Error(Dictionary.errors.api.snapshots.couldNotLoadSnapshot);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

async function getFulfilledValues(results: PromiseSettledResult<any>[]): Promise<Analysis[]> {
	// Filter out only the fulfilled promises and extract their values
	const fulfilledValues = results
		.filter(
			(result): result is PromiseFulfilledResult<Analysis> => result.status === 'fulfilled'
		)
		.map((result: { value: Analysis }) => result.value);

	return fulfilledValues;
}

const getSnapshotsAction = (payload: ActionPayload<GetSnapshotsAction>): GetSnapshotsAction => ({
	type: ActionTypes.GET_SNAPSHOTS,
	payload
});

export const getSnapshots = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SNAPSHOTS,
		dispatch
	});

	const { projectId } = getState().data.projects;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const snapshots = await context.api.data.snapshots().getSnapshots(projectId);

			dispatch(getSnapshotsAction({ projectId, snapshots }));
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

const createSnapshotAction = (
	payload: ActionPayload<CreateSnapshotAction>
): CreateSnapshotAction => ({
	type: ActionTypes.CREATE_SNAPSHOT,
	payload
});

export const createSnapshot =
	(name: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				analyses: { byProjectId: analysesByProjectId, byId: analysesById }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const snapshot = {
					projectId,
					snapName: name,
					snapshotJson: JSON.stringify({
						filters,
						analysisList
					})
				};

				const snapshotId = await context.api.data.snapshots().createSnapshot(snapshot);

				dispatch(createSnapshotAction({ projectId, snapshotId, name }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateSnapshotAction = (
	payload: ActionPayload<UpdateSnapshotAction>
): UpdateSnapshotAction => ({
	type: ActionTypes.UPDATE_SNAPSHOT,
	payload
});

export const updateSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				analyses: { byProjectId: analysesByProjectId, byId: analysesById },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				snapshots: { byId: bySnapshotId }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const snapshot = {
					snapshotId: Number(snapshotId),
					projectId,
					snapName: bySnapshotId[snapshotId].snapName,
					snapshotJson: JSON.stringify({
						filters,
						analysisList
					})
				};

				await context.api.data.snapshots().updateSnapshot(snapshot);

				dispatch(updateSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteSnapshotAction = (
	payload: ActionPayload<DeleteSnapshotAction>
): DeleteSnapshotAction => ({
	type: ActionTypes.DELETE_SNAPSHOT,
	payload
});

export const deleteSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				await context.api.data.snapshots().deleteSnapshot(snapshotId, projectId);

				// remove analyses from the snapshot from store
				const snapshotAnalysesIds = getState().data.analyses.bySnapshotId[snapshotId];
				const jadBioAnalysesIds = extractJADBioAnalysesIds(snapshotAnalysesIds, getState);

				if (jadBioAnalysesIds.length)
					dispatch(deleteJADBioAnalyses(jadBioAnalysesIds, projectId));

				// Check if there are other snapshots that use the same analyses and delete the unused ones
				const analisesToRemove = [...snapshotAnalysesIds];

				for (const [snapId, analysesList] of Object.entries(
					getState().data.analyses.bySnapshotId
				)) {
					if (snapId !== snapshotId) {
						for (const analysisId of analysesList) {
							if (analisesToRemove.includes(analysisId)) {
								analisesToRemove.splice(analisesToRemove.indexOf(analysisId), 1);
							}
						}
					}
				}

				dispatch(deleteAnalysesAction({ projectId, analysisIds: analisesToRemove }));

				dispatch(deleteSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setActiveSnapshot = (
	payload: ActionPayload<SetActiveSnapshotAction>
): SetActiveSnapshotAction => ({
	type: ActionTypes.SET_ACTIVE_SNAPSHOT,
	payload
});

export const clearSnapshot = (): ClearSnapshotAction => ({
	type: ActionTypes.CLEAR_SNAPSHOT
});
