import { useState, useEffect, useMemo } from 'react';

import { CrosstabRow } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { withMemo } from 'helpers/HOCs';
import { nanoid as generate } from 'nanoid';
import { useWindowSize } from 'hooks/ui';
import { useAnalyses, useAnalysesActiveColum, useFilters } from 'hooks/store';
import { computeCrosstabSunburstChartData as computeData } from 'helpers/analysis';

interface Props {
	rows: CrosstabRow[];
	categories: string[];
	id?: string;
	isForExport?: boolean;
	fullscreen?: string;
}

function Component({ rows, categories, id, isForExport, fullscreen }: Props) {
	const { series, options, disableAnimations } = computeData(rows, categories);
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const [activeColumn] = useAnalysesActiveColum();

	const renderId = useMemo(generate, [
		activeColumn,
		fullscreen,
		windowSize,
		analysisIds,
		filters
	]);
	/**
	 * Re-render `Chart` component when props change due to `Sunburst` being fucked up and not rendering data correctly
	 * TODO: REMOVE WHEN ZINGCHART FIXED THIS
	 * - START -
	 */
	const [render, setRender] = useState(true);

	useEffect(() => {
		if (render) setRender(false);
	}, [rows, categories]);

	useEffect(() => {
		if (!render) setRender(true);
	}, [render]);

	if (!render) return null;
	/**
	 * - END -
	 */

	return (
		<Chart
			key={renderId}
			id={id}
			type={t => t.Sunburst}
			series={series}
			options={options}
			disableAnimations={disableAnimations}
			effect={e => e.ExpandVertical}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}

export const CrosstabSunburstChart = withMemo(Component, ['rows', 'categories']);
