import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { DensityPlotAnalysis } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';
import { DensityPlotChart } from './DensityPlotChart';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import {
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useProject,
	useTranslation
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: DensityPlotAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function DensityPlotView({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const [{ data: project }] = useProject();
	const { translate } = useTranslation();

	const { selectItemsLabelMap } = variablesDataSelectItems;

	const {
		id,
		input,
		options: { configPanel, chartLegend = false, histogram = false },
		output: { dataset }
	} = analysis;
	const { variablesMap } = variablesData;
	const [activeColumn] = useAnalysesActiveColum();

	const [fullscreen] = useFullscreenAnalysis();
	const densityPlotChartId = ZingChartExportTypes.DensityPlot + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				densityPlotChartId,
				getExportFileName(
					ExportFileNames.DensityPlotChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting, project]);

	const { parsedDataset } = useMemo(() => {
		let parsedDataset = cloneDeep(dataset);

		if (analysis.options.showCategoryLabels) {
			parsedDataset = parsedDataset.map(result => {
				result.groupingyValue =
					(input.variables.groupingVariable &&
						mapVariableCategoryValueToLabel(
							result.groupingyValue,
							variablesMap[input.variables.groupingVariable]
						)) ??
					result.groupingyValue;
				return result;
			});
		}
		return { parsedDataset };
	}, [
		dataset,
		variablesMap,
		input.variables.groupingVariable,
		analysis.options.showCategoryLabels
	]);

	const legendHeader = input.variables.groupingVariable
		? selectItemsLabelMap[input.variables.groupingVariable]
		: undefined;

	const scalesLabels = {
		labelX: selectItemsLabelMap[input.variables.numericVariable],
		labelY: translate(dict => dict.analysis.analyses.densityPlot.yAxis)
	};
	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper
				ignoreAnalysisData={false}
				analysis={analysis}
				analysisType={AnalysisType.DensityPlot}
			>
				<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
					<DensityPlotChart
						data={parsedDataset}
						isLegendEnabled={chartLegend}
						isHistogramEnabled={histogram}
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
				</AnalysisChartContainer>
				<AnalysisExportWrapper>
					<DensityPlotChart
						id={densityPlotChartId}
						data={parsedDataset}
						isLegendEnabled={chartLegend}
						isHistogramEnabled={histogram}
						legendHeader={legendHeader}
						isForExport
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
				</AnalysisExportWrapper>
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
