import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useWatch } from 'react-hook-form';
import { DraftProjectDPA } from './ProjectDPAForm';
import { usePreviewProjectDPA } from 'hooks/store';
import { usePrevious } from 'hooks/utils';

interface Props {
	onReset: () => void;
}

export function PreviewDPAFileResetter({ onReset }: Props) {
	const [{ data: previewDPAFile }, { reset: resetDPAFile }] = usePreviewProjectDPA();

	const values = useWatch({}) as DraftProjectDPA;

	const prevValues = usePrevious(values);
	useEffect(() => {
		if (prevValues === undefined) return;

		if (isEqual(prevValues, values)) return;

		if (previewDPAFile) {
			onReset();
			resetDPAFile();
		}
	}, [values]);

	return null;
}
