import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import produce from 'immer';
import { VariableSet, AggregationRule, AggregationRuleType, Variable } from 'api/data/variables';
import { InputType, SelectItem, SelectGroup, SelectItemOrGroup, GenericMap } from 'types/index';
import { AggregationRuleCard } from './AggregationRuleCard';
import { Container, AggregationRulesScrollContainer } from './VariableSetDrawerSettings.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';
import {
	buildVariablesRichData,
	initVariablesData,
	buildVariableSetVariablesData,
	variablesDataArrayIterator
} from 'helpers/variables';
import { useTranslation, useUpdateVariableSet, useVariablesData } from 'hooks/store';
import { useMutableState, useCompletedAction, useKeyPress } from 'hooks/utils';

interface Props {
	variableSet: VariableSet;
	readOnly: boolean;
}

export function VariableSetDrawerSettings({ variableSet, readOnly }: Props) {
	const { setName, setLabel, aggregationRules } = variableSet;
	const { translate } = useTranslation();

	const [{ loading: updatingVariableSet, error: errorUpdatingVariableSet }, updateVariableSet] =
		useUpdateVariableSet();

	const variablesData = useVariablesData({ initial: true });

	const { variablesMap, variablesDataArray } = useMemo(() => {
		// TODO: QUICK FIX FOR NOW - STATE IS 1 RENDER BEHIND - STORE GETS UPDATED FIRST AND THE SELECTOR RETURNS NOTHING
		if (!(setName in variablesData.variableSetsMap)) {
			return buildVariablesRichData(initVariablesData());
		}

		const variableSetVariablesData = buildVariableSetVariablesData({
			setName,
			variablesData
		});

		return buildVariablesRichData(variableSetVariablesData);
	}, [setName, variablesData]);

	const variableSetDataSelectItems = useMemo(() => {
		const items: SelectItemOrGroup[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE
			variable => {
				const selectItem = getVariableSelectItem(variable);

				items.push(selectItem);
				itemsMap[selectItem.value] = selectItem;
			},
			// GROUP
			groupData => {
				const { groupLabel, groupVariables } = groupData;

				const selectGroup = getSelectGroup(groupLabel);

				groupVariables.forEach(variable => {
					const selectItem = getVariableSelectItem(variable);

					selectGroup.options.push(selectItem);
					itemsMap[selectItem.value] = selectItem;
				});

				if (selectGroup.options.length) items.push(selectGroup);
			},
			// VARIABLE SET - OMIT
			() => null
		);

		function getVariableSelectItem(variable: Variable): SelectItem {
			return {
				label: variable.label,
				value: variable.name
			};
		}

		function getSelectGroup(label: string): SelectGroup<SelectItem> {
			return {
				label: label,
				options: []
			};
		}

		return { items, itemsMap };
	}, [variablesDataArray]);

	const [newAggregationRule, setNewAggregationRule] = useMutableState<AggregationRule | null>(
		null
	);
	const [draftVariableSet, setDraftVariableSet] = useMutableState(variableSet);

	const hasChanges = useMemo(
		() => !isEqual(variableSet, draftVariableSet),
		[variableSet, draftVariableSet]
	);

	// SYNC `draftVariableSet` STATE
	useEffect(() => {
		const variableSetChanged = !isEqual(variableSet, draftVariableSet);

		if (variableSetChanged) setDraftVariableSet(variableSet);
	}, [variableSet]);

	// Update `identifier` field listener
	useEffect(() => {
		if (!(!readOnly && hasChanges)) return;

		updateVariableSet({
			setName,
			identifier: draftVariableSet.identifier
		});
	}, [draftVariableSet.identifier.variableName]);

	useCompletedAction(
		updatingVariableSet,
		errorUpdatingVariableSet,
		// SUCCESS CALLBACK
		() => undefined,
		// ERROR CALLBACK
		resetDraft
	);

	useKeyPress(
		{ onEnterKeyPress: handleSubmit },
		{ noModalsOpened: true, listen: !updatingVariableSet }
	);

	function handleSubmit() {
		if (!(!readOnly && hasChanges)) return;

		updateVariableSet(trimFields(draftVariableSet));
	}

	function resetDraft() {
		setDraftVariableSet(variableSet);
	}

	function resetLabel() {
		setDraftVariableSet(state => {
			state.setLabel = setLabel;
		});
	}

	function handleCreateNewRule() {
		if (newAggregationRule) return;

		setNewAggregationRule({
			name: 'new_aggregation_rule',
			aggregator: {
				variableName: ''
			},
			rule: {
				type: AggregationRuleType.Latest,
				metadata: null
			}
		});
	}

	function handleCancelNewRule() {
		setNewAggregationRule(null);
	}

	function trimFields(state: VariableSet) {
		return produce(state, draft => {
			draft.setLabel = draft.setLabel.trim();
		});
	}

	function handleTrimFieds() {
		setDraftVariableSet(trimFields);
	}

	return (
		<Container>
			<Input
				type={InputType.Text}
				label={translate(dict => dict.variablesPage.nameOfSeries)}
				value={draftVariableSet.setLabel}
				// TODO: FIX
				// error={labelError}
				readOnly={readOnly}
				onChange={e =>
					setDraftVariableSet(state => {
						state.setLabel = e.target.value;
					})
				}
				onBlur={() => {
					handleTrimFieds();
					handleSubmit();
				}}
				onSubmit={handleSubmit}
				onCancel={resetLabel}
			/>
			<Spacer size={s => s.s} />
			<Flex
				/**
				 * Make dropdown to be on top of the aggregation rules
				 */
				css={`
					z-index: ${aggregationRules.length + 1};
				`}
			>
				<CreatableSelect
					label={translate(dict => dict.terms.identifier)}
					placeholder={`-`}
					value={
						variableSetDataSelectItems.itemsMap[
							draftVariableSet.identifier.variableName ?? ''
						] ?? null
					}
					items={variableSetDataSelectItems.items}
					readOnly={readOnly}
					onValueSelected={value =>
						setDraftVariableSet(state => {
							const identifyingVariable = value ?? null;

							state.identifier.variableName = identifyingVariable;
						})
					}
				/>
			</Flex>

			<Spacer size={s => s.s} />

			{!readOnly && (
				<>
					<Button
						title={translate(dict => dict.variablesPage.addAggregationRule)}
						variant={v => v.outline}
						onClick={handleCreateNewRule}
					/>
					<Spacer size={s => s.s} />
				</>
			)}

			<AggregationRulesScrollContainer>
				<Gap marginGap={{ bottom: 0.8 }}>
					{aggregationRules.map((aggregationRule, index) => (
						<AggregationRuleCard
							key={`aggregation_rule_${aggregationRule.name}`}
							index={index}
							zIndex={aggregationRules.length - index} // Reversed index [first <-> last]
							setName={setName}
							aggregationRule={aggregationRule}
							variablesMap={variablesMap}
							variablesSelectItems={variableSetDataSelectItems}
							readOnly={readOnly}
						/>
					))}

					{newAggregationRule && (
						<AggregationRuleCard
							index={-1}
							zIndex={0} // Reversed index [first <-> last]
							setName={setName}
							aggregationRule={newAggregationRule}
							variablesMap={variablesMap}
							variablesSelectItems={variableSetDataSelectItems}
							newRule={{
								onCreated: handleCancelNewRule,
								onCancel: handleCancelNewRule
							}}
							readOnly={readOnly}
						/>
					)}
				</Gap>
			</AggregationRulesScrollContainer>
		</Container>
	);
}
