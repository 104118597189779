import { intersection } from 'lodash';
import {
	DATE_FORMATS,
	DATE_TOKENS,
	DATE_YEAR_TOKENS,
	PYTHON_TO_JS_CONVERSION_MAP,
	SEPARATORS,
	TIME_TOKENS,
	TIME_ZONE_TOKENS
} from 'types/data/projects/import/constants';
import { PreviewVariable } from 'types/data/projects/import/types';
import { getTimeZoneOffset } from './importTimezone';
import { VariableType } from 'types/data/variables/constants';

// check that date has 1 of each DD / MM / YY / YYYY (order irrelevant)
export function isDateValid(date: string) {
	const tokens = date.toUpperCase().split(SEPARATORS);
	// check if separators are doubled
	if (date.match(/[.|/\\,_\-:;]{2,}/g)) return false;

	if (tokens.length !== 3) return false;

	// check if 3 elements match between our date and the date tokens array
	const dateElements = intersection(tokens, DATE_TOKENS);
	const checkDoubleYear = intersection(tokens, DATE_YEAR_TOKENS);
	if (checkDoubleYear.length !== 1) return false;

	// valid dates are always 3 tokens
	return dateElements.length === 3;
}

// check that date has tokens in order, starting from HH
export function isTimeValid(time: string) {
	const tokens: string[] = [];
	const orderedTokens = ['HH', 'MM', 'SS', 'SSS'];
	const parts = time.toUpperCase().split(/(\+|-|z)/gi);

	if (!time.toUpperCase().startsWith('HH')) return false;

	if (parts.length !== 3) return false;

	const [timeWithoutTimezone, separator, timeZone] = parts;

	if (separator === 'Z' && timeZone !== '') return false;

	if (separator !== 'Z' && !TIME_ZONE_TOKENS.includes(timeZone)) return false;

	const timeTokens = timeWithoutTimezone.split(/[:.,]/g);
	if (time.match(/[.|/\\,_\-:;]{2,}/g)) return false;

	timeTokens.forEach(token => {
		if (TIME_TOKENS.includes(token) && !tokens.includes(token)) {
			tokens.push(token);
		}
	});
	for (let i = 0; i < tokens.length; i++) {
		if (tokens[i] !== orderedTokens[i]) {
			return false;
		}
	}

	return true;
}

export function isDateTimeFormatValid(dateFormat: string, isDateTimeVariable?: boolean) {
	const datePart = dateFormat.toUpperCase().split(/(T| )/i)[0];
	const timePart = dateFormat.toUpperCase().split(/(T| )/i)[2];
	const tokens: string[] = [];
	const orderedTokens = ['HH', 'MM', 'SS', 'SSS'];

	if (isDateTimeVariable && !timePart) return false;

	// check there is a value after the T or space between date and time
	if (/(T| )/i.test(dateFormat) && timePart.length === 0) {
		return false;
	}

	// check if date part is valid. This is done regardless if value is date or datetime
	if (!isDateValid(datePart)) {
		return false;
	}

	// if time, check if time part is valid
	if (timePart && /(\+|-|z)/i.test(timePart)) {
		return isDateValid(datePart) && isTimeValid(timePart);
	}

	// invalidate when user double presses a separator
	if (timePart && timePart.match(/[.|/\\,_\-:;]{2,}/g)) {
		return false;
	}

	// if value is datetime, check that time elements are in correct order
	if (timePart) {
		const timeTokens = timePart.split(/[:.,]/g);
		let invalidToken = false;
		timeTokens.forEach(token => {
			// if token is valud and not already in list, we push it to our tokens
			if (TIME_TOKENS.includes(token) && !tokens.includes(token)) {
				tokens.push(token);
			} else {
				// invalid token can mean even just pressing M instead of MM
				invalidToken = true;
			}
		});
		// if invalid token flag has been triggered we return false
		if (invalidToken) {
			return false;
		}
		// make sure that all tokens are in correct order
		for (let i = 0; i < tokens.length; i++) {
			if (tokens[i].toUpperCase() !== orderedTokens[i].toUpperCase()) {
				return false;
			}
		}
	}

	// at this point, all early returns have been triggered and we can return true
	return true;
}

export function convertJSDateFormatToPython(dateFormat: string) {
	return dateFormat
		.replace(/(dd)/gi, '%d')
		.replace(/(mm)/gi, '%m')
		.replace(/(yyyy)/gi, '%Y')
		.replace(/(yy)/gi, '%y');
}

export function convertJSTimeFormatToPython(dateFormat: string) {
	return dateFormat
		.replace(/(\+HH:MM|\+HHMM|\+HH|-HH:MM|-HHMM|-HH|Z)/gi, '%z')
		.replace(/(hh)/gi, '%H')
		.replace(/(mm)/gi, '%M')
		.replace(/(\.[s]{1,6})/gi, '.%f')
		.replace(/[s]{3,6}/gi, '%f')
		.replace(/(ss)/gi, '%S');
}

export const handleVariableDateTypeChange = (
	varId: string,
	value: string,
	oldFormattedVariables: PreviewVariable[],
	setVariables: (formattedVariables: PreviewVariable[]) => void
) => {
	const newFormattedVariables = oldFormattedVariables.map(variable =>
		variable.id === varId
			? {
					...variable,
					dateFormat: value,
					customDateFormat:
						value !== DATE_FORMATS.Custom ? '' : variable.customDateFormat,
					dateFormatError: ''
			  }
			: variable
	);

	setVariables(newFormattedVariables);
};

export const handleVariableTimeZoneChange = (
	varId: string,
	value: string,
	oldFormattedVariables: PreviewVariable[],
	setVariables: (formattedVariables: PreviewVariable[]) => void
) => {
	const newFormattedVariables = oldFormattedVariables.map(variable =>
		variable.id === varId
			? {
					...variable,
					timeZone: { label: `${getTimeZoneOffset(value)}${value}`, value }
			  }
			: variable
	);

	setVariables(newFormattedVariables);
};

export const handleCustomDateFormatChange = (
	varId: string,
	value: string,
	oldFormattedVariables: PreviewVariable[],
	setVariables: (formattedVariables: PreviewVariable[]) => void,
	isDateTimeVariable?: boolean
) => {
	const isDateFormatBad = !isDateTimeFormatValid(value);
	const isDateTimeVariableFormatValid = isDateTimeVariable
		? isDateTimeFormatValid(value, isDateTimeVariable)
		: isDateValid(value);

	const newFormattedVariables = oldFormattedVariables.map(variable =>
		variable.id === varId
			? {
					...variable,
					customDateFormat: value,
					dateFormatError:
						isDateFormatBad || !isDateTimeVariableFormatValid
							? 'Invalid date format'
							: ''
			  }
			: variable
	);

	setVariables(newFormattedVariables);
};

export function showDateFormatPicker(
	variableType: VariableType,
	expectedVariableType: VariableType,
	isExcelDateFormat: boolean,
	isBinary?: boolean
) {
	if (variableType === expectedVariableType) {
		// @BAD_DATE_TYPE = in Excel you could have a date string
		// inside a cell that has no date formatting
		// Excel will transform that into YYYY-MM-DD string

		// IF THE FILE IS A CSV, SHOULD ALWAYS SHOW
		if (!isBinary) return true;

		// If the suggestion API matched a column with a @BAD_DATE_TYPE we shouldn't display the format picker
		if (isExcelDateFormat) {
			if (variableType === VariableType.Date) {
				return false;
			} else {
				return true;
			}
		}
	}
}

export const handleVariableDateTimeTypeChange = (
	varId: string,
	value: string,
	oldFormattedVariables: PreviewVariable[],
	setVariables: (formattedVariables: PreviewVariable[]) => void
) => {
	const newFormattedVariables = oldFormattedVariables.map(variable =>
		variable.id === varId
			? {
					...variable,
					dateFormat: value
			  }
			: variable
	);
	setVariables(newFormattedVariables);
};

/**
 *
 * @param dateFormat python formatted date
 * @returns js formatted date(string)
 */
export function matchDateTimeFormat(dateFormat: string): string {
	let jsFormat = dateFormat;
	for (const pythonToken in PYTHON_TO_JS_CONVERSION_MAP) {
		const jsToken = PYTHON_TO_JS_CONVERSION_MAP[pythonToken];
		jsFormat = jsFormat.replace(new RegExp(pythonToken, 'g'), jsToken);
	}

	return jsFormat;
}

export function reverseMatchDateTimeFormat(dateFormat: string) {
	const [datePart, dateTimeSeparator, timePart] = dateFormat.split(/(T| )/gi);

	if (!isDateTimeFormatValid(dateFormat)) {
		return;
	}

	return `${convertJSDateFormatToPython(datePart)}${dateTimeSeparator || ''}${
		timePart ? convertJSTimeFormatToPython(timePart) : ''
	}`.trim();
}
