import { CorrelationsV1Analysis } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { CorrelationsV1Pearson } from './CorrelationsV1Pearson';
import { CorrelationsV1Spearman } from './CorrelationsV1Spearman';
import { CorrelationsV1LinearRegression } from './CorrelationsV1LinearRegression';

interface Props {
	analysis: CorrelationsV1Analysis;
}

export function CorrelationsV1Statistics({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		pearson: {
			visible: inputStatistics.pearsonV1,
			results: outputStatistics.pearson
		},
		spearman: {
			visible: inputStatistics.spearmanV1,
			results: outputStatistics.spearman
		},
		linearRegression: {
			visible: inputStatistics.linearRegressionV1,
			results: outputStatistics.linearRegression
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.pearson.visible && (
				<CorrelationsV1Pearson results={statistics.pearson.results} />
			)}

			{statistics.spearman.visible && (
				<CorrelationsV1Spearman results={statistics.spearman.results} />
			)}

			{statistics.linearRegression.visible && (
				<CorrelationsV1LinearRegression results={statistics.linearRegression.results} />
			)}
		</Gap>
	);
}
