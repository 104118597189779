import { AxiosResponse } from 'axios';

import { ApiEntryFilters } from 'api/data/filters';
import { RequestError, GenericApiResponse, NetworkRequestBase } from 'api/types';
import { NumberMap, GenericMap, StringMap } from 'types/index';
import { AnalysisType } from './constants';

export enum DataModel {
	main = 'Main level',
	series = 'Series'
}

export interface BaseAnalysis {
	id: string;
	type: AnalysisType;
	fetched: boolean;
	options: {
		open: boolean;
		configPanel: {
			open: boolean;
			formatting: boolean;
			parameters: boolean;
			jadBioPerformance?: boolean;
			chartType?: boolean;
		};
		chartLegend?: boolean;
		activeTab?: number;
		showCategoryLabels?: boolean;
		showCensorTicks?: boolean;
		histogram?: boolean;
	};
	// -- keep chartLegend & activeTab for mapping old snapshot structure to the new one --
	chartLegend?: boolean;
	histogram?: boolean;
	activeTab?: number;
	invalid?: boolean;
}

export interface VariableStatistics {
	average: string;
	categorizationValue: string;
	confidentLower: string;
	confidentUpper: string;
	kurtosis: string;
	max: string;
	median: string;
	min: string;
	missing: string;
	'nr of rows': string;
	sem: string;
	skewness: string;
	std: string;
	variableName: string;
	variance: string;
	IQRLower: string;
	IQRUpper: string;
}

export enum Columns {
	OneColumn = 1,
	TwoColumns = 2,
	ThreeColumns = 3
}

export interface CompareNumericVariablesV1 {
	categoryVariable: string;
	exploreVariable: string;
	categoryVariableTwo?: string;
	exploreVariableTwo?: string;
}
export interface CompareNumericVariablesV2 {
	categoryVariable?: AnalysisVariable | null;
	exploreVariable?: AnalysisVariable | null;
	categoryVariableTwo?: AnalysisVariable | null;
	exploreVariableTwo?: AnalysisVariable | null;
}

export interface CompareNumericStatisticsV1 {
	shapiroV1: boolean;
	mannWhitneyV1: boolean;
	independentV1: boolean;
	oneWayAnovaV1: boolean;
	tukeyV1: boolean;
	kruskalV1: boolean;
	twoWayAnovaV1: boolean;
	twoWayManovaV1: boolean;
	oneWayManovaV1: boolean;
	// properties that come from older snapshots
	shapiro?: boolean;
	mannWhitney?: boolean;
	independent?: boolean;
	oneWayAnova?: boolean;
	tukey?: boolean;
	kruskal?: boolean;
	twoWayAnova?: boolean;
	twoWayManova?: boolean;
	oneWayManova?: boolean;
}

export interface CompareNumericStatisticsV2 {
	shapiroV2: boolean;
	mannWhitneyV2: boolean;
	independentV2: boolean;
	oneWayAnovaV2: boolean;
	tukeyV2: boolean;
	kruskalV2: boolean;
	twoWayAnovaV2: boolean;
	twoWayManovaV2: boolean;
	oneWayManovaV2: boolean;
	// properties that come from older snapshots
	shapiro?: boolean;
	mannWhitney?: boolean;
	independent?: boolean;
	oneWayAnova?: boolean;
	tukey?: boolean;
	kruskal?: boolean;
	twoWayAnova?: boolean;
	twoWayManova?: boolean;
	oneWayManova?: boolean;
}

export interface DescriptiveStatistics {
	n: number;
	mean: number | null;
	sd: number | null;
	lowerCI: number | null;
	upperCI: number | null;
	sem: number | null;
	variance: number | null;
	skewness: number | null;
	kurtosis: number | null;
	median: number | null;
	min: number | null;
	max: number | null;
	range: number | null;
	lowerIQR: number | null;
	upperIQR: number | null;
	missing: number;
}
export interface GetGroupedDescriptiveStatistics {
	groupIdentifiers: GroupIdentifier[];
	values: DescriptiveStatistics & { variable: AnalysisVariable };
}

export type GetUngroupedDescriptiveStatistics = DescriptiveStatistics & {
	variable: AnalysisVariable;
};

export type CompareNumericResultsData = {
	result: GetGroupedDescriptiveStatistics[];
};

export type CompareNumericGroupsMetadata = {
	group1: GroupIdentifier;
	group2: GroupIdentifier;
};

export type CompareNumericResultsV1 = GenericMap<
	CompareNumericResultsOneCategory | CompareNumericResultsTwoCategories
>;

export type CompareNumericResultsDataV2 = GenericMap<
	(DescriptiveStatistics & Partial<CompareNumericGroupsMetadata>)[]
>;

export type CompareNumericResultsV2 = AnalysisOutputData<CompareNumericResultsDataV2>;

export type CompareNumericResultsOneCategory = VariableStatistics[];
export type CompareNumericResultsTwoCategories = CompareNumericResultsTwoCategoriesValue[];
export interface CompareNumericResultsTwoCategoriesValue {
	categorizationValue: string;
	groupedCalculations: (VariableStatistics & {
		groupValue: string;
	})[];
}

export interface CompareNumericAnalysisV1 extends BaseAnalysis {
	input: {
		variables: CompareNumericVariablesV1;
		statistics: CompareNumericStatisticsV1;
	};
	output: {
		dataset: CompareNumericResultsV1;
		statistics: {
			shapiro: ShapiroResultsV1;
			mannWhitney: MannWhitneyResultsV1;
			independent: IndependentResultsV1;
			oneWayAnova: OneWayAnovaResultsV1;
			tukey: TukeyResultsV1;
			kruskal: KruskalResultsV1;
			twoWayAnova: TwoWayAnovaResultsV1;
			twoWayManova: TwoWayManovaResultsV1;
			oneWayManova: OneWayManovaResultsV1;
		};
	};
}
export interface CompareNumericAnalysisV2 extends BaseAnalysis {
	input: {
		variables: CompareNumericVariablesV2;
		statistics: CompareNumericStatisticsV2;
		dataModel?: DataModel | null;
		series?: string;
	};
	output: {
		dataset: CompareNumericResultsV2;
		statistics: {
			shapiro: ShapiroResultsV2;
			mannWhitney: MannWhitneyResultsV2;
			independent: IndependentResultsV2;
			oneWayAnova: OneWayAnovaResultsV2;
			tukey: TukeyResultsV2;
			kruskal: KruskalResultsV2;
			twoWayAnova: TwoWayAnovaResultsV2;
			twoWayManova: TwoWayManovaResultsV2;
			oneWayManova: OneWayManovaResultsV2;
		};
	};
}

export type ApiShapiroResponseV1 = {
	shapiroWilk: {
		wStat: number;
		pValue: number;
		exploreVariable: string;
	}[];
};
export interface ApiShapiroResponseV2 {
	groupIdentifiers: GroupIdentifier[];
	values: {
		variable: AnalysisVariable;
		pStatistic: number | null;
		pValue: number | null;
		error?: AnalysisError;
	};
}

export type ShapiroResultsV1 = {
	data: ShapiroResultsDataV1 | null;
	error?: boolean;
	errorMessage?: string;
};

export type ShapiroResultsV2 = {
	data: ShapiroResultsDataV2 | null;
	error?: AnalysisError;
};

export type ShapiroResultsDataV1 = {
	wStat: number;
	pValue: number;
	exploreVariable: string;
}[];

export type ShapiroResultsDataV2 = {
	// wStat: number;
	pValue: number | null;
	// exploreVariable: string;
}[];

export type MannWhitneyResultsV1 = {
	data: MannWhitneyResultsDataV1 | null;
	error?: boolean;
};

export type MannWhitneyResultsV2 = {
	data: MannWhitneyResultsDataV2 | null;
	error?: AnalysisError;
};

export type MannWhitneyResultsDataV1 = {
	pValue: number;
	wStat: number;
};

export type MannWhitneyResultsDataV2 = {
	pValue: number;
	statistic: number;
};

export type IndependentResultsV1 = {
	data: IndependentResultsDataV1 | null;
	error?: boolean;
};
export type IndependentResultsV2 = {
	data: IndependentResultsDataV2 | null;
	error?: AnalysisError;
};

export type IndependentResultsDataV1 = {
	degrees_of_freedom: number;
	pValue: number;
	tStat: number;
};
export type IndependentResultsDataV2 = {
	degrees_of_freedom: number;
	pValue: number;
	tStat: number;
};

export type OneWayAnovaResultsV1 = {
	data: OneWayAnovaResultsDataV1 | null;
	error?: boolean;
};

export type OneWayAnovaResultsV2 = AnalysisOutputData<GenericMap<AnovaData>>;
export type OneWayAnovaResultsDataV1 = {
	fValue: number;
	pValue: number;
	df: number;
	errorDf: number;
};

export type TwoWayAnovaResultsV1 = {
	data: TwoWayAnovaDataResultV1 | null;
	error?: boolean;
};

export type TwoWayAnovaResultsV2 = AnalysisOutputData<TwoWayAnovaDataResultV2[]>;

export type TwoWayAnovaDataResultV1 = {
	dynamic: GenericMap<TwoWayAnovaValue>;
	intercept: TwoWayAnovaValue;
	testType: string;
	condition_number: number;
	error: {
		df: number;
		sumSq: number;
		meanSq: number;
	};
	total: {
		df: number;
		sumSq: number;
	};
};

export interface TwoWayAnovaDataResultV2 extends AnovaData {
	variables: AnalysisVariable[];
}

export type AnovaData = {
	sumSq: number;
	df: number;
	meanSq: number;
	fValue: number | null;
	pValue: number | null;
};

export type TukeyResultsV1 = {
	data: TukeyResultsDataV1[] | null;
	error?: boolean;
	errorMessage?: string;
};

export type TukeyResultsV2 = {
	data: TukeyResultsDataV2[] | null;
	error?: AnalysisError;
};

export type TukeyResultsDataV1 = {
	comparison: string;
	statistic: number;
	pValue: number;
	lowerCI: number;
	upperCI: number;
};
export type TukeyResultsDataV2 = {
	groupOne: string;
	groupTwo: string;
	statistic: number;
	pValue: number | null;
	lowerCI: number | null;
	upperCI: number | null;
};

export type TukeyTwoResults = {
	data: TukeyTwoResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type TukeyTwoResultsData = {
	pValue: number[];
	groupLabels: string[][];
};

export type KruskalResultsV1 = {
	data: KruskalResultsDataV1 | null;
	error?: boolean;
};
export type KruskalResultsV2 = {
	data: KruskalResultsDataV2 | null;
	error?: AnalysisError;
	warnings?: AnalysisError;
};

export type KruskalResultsDataV1 = {
	df: number;
	stat: number;
	validTest: boolean;
	pValue: number;
};

export type KruskalResultsDataV2 = {
	df: number;
	statistic: number;
	pValue: number;
};

export interface TwoWayManovaFieldValue {
	f: number;
	value: number;
	hypothesisDf: number;
	// /**
	//  * wrong naming convention is on purpose due to BE not being able to send `errorDF`
	//  */
	erroDF: number;
	etaSquard: number;
	statistic: number;
	fValue: number;
	df1: number;
	df2: number;
	pValue: number;
}

export interface ManovaValue {
	pillaisTrace: TwoWayManovaFieldValue;
	lawleyHotellingTrace: TwoWayManovaFieldValue;
	roysLargestRoot: TwoWayManovaFieldValue;
	wilksLambda: TwoWayManovaFieldValue;
}

export type ApiManovaResultV1 = ManovaValue & { variables: AnalysisVariable[] };
export type ApiManovaResultV2 = ManovaValue & { variables: AnalysisVariable[] };

export type ApiTwoWayManovaResultsV1 = GenericMap<TwoWayManovaValueV1> & {
	testType: string;
	Intercept: TwoWayManovaValueV1;
};
export type ApiTwoWayManovaResultV2 = ApiManovaResultV2;

export interface TwoWayManovaValueV1 {
	pillaisTrace: TwoWayManovaFieldValue;
	hotellingTrace: TwoWayManovaFieldValue;
	roysRoot: TwoWayManovaFieldValue;
	wilksLamda: TwoWayManovaFieldValue;
}
export type TwoWayManovaResultsV1 = {
	data: TwoWayManovaResultsDataV1 | null;
	error?: boolean;
};

export type TwoWayManovaResultsDataV1 = {
	dynamic: GenericMap<TwoWayManovaValueV1>;
	intercept: TwoWayManovaValueV1;
	testType: string;
	expanded: {
		pillaisTrace: boolean;
		hotellingTrace: boolean;
		roysRoot: boolean;
		wilksLamda: boolean;
	};
};

export type TwoWayManovaResultsV2 = {
	data: GenericMap<ManovaValue> | null;
	error?: AnalysisError;
};

export type ManovaResultsData = ApiManovaResultV2;

export type OneWayManovaResultsV1 = {
	data: OneWayManovaResultsDataV1 | null;
	error?: boolean;
};

export type OneWayManovaResultsV2 = {
	data: GenericMap<ManovaValue> | null;
	error?: AnalysisError;
};

export type OneWayManovaResultsDataV1 = {
	dynamic: GenericMap<TwoWayManovaValueV1>;
	intercept: TwoWayManovaValueV1;
	testType: string;
	expanded: {
		pillaisTrace: boolean;
		hotellingTrace: boolean;
		roysRoot: boolean;
		wilksLamda: boolean;
	};
};
export type OneWayManovaResultsDataV2 = GenericMap<ManovaValue>;

export interface ApiOneWayAnovaResults extends AnovaData {
	variables: AnalysisVariable[];
}

export interface TwoWayAnovaValue {
	f: number;
	df: number;
	pValue: number;
	sumSq: number;
	meanSq: number;
	etaSquared: number;
}

export type ApiTwoWayAnovaResultsV1 = GenericMap<TwoWayAnovaValue> & {
	intercept: TwoWayAnovaValue;
	testType: string;
	condition_number: number;
	error: {
		df: number;
		sumSq: number;
		meanSq: number;
	};
	total: {
		df: number;
		sumSq: number;
	};
};

export interface ApiTwoWayAnovaResultsV2 extends AnovaData {
	variables: AnalysisVariable[];
}

export type TwoWayAnovaResultsDataV1 = {
	dynamic: GenericMap<TwoWayAnovaValue>;
	intercept: TwoWayAnovaValue;
	testType: string;
	condition_number: number;
	error: {
		df: number;
		sumSq: number;
		meanSq: number;
	};
	total: {
		df: number;
		sumSq: number;
	};
};
export type TwoWayAnovaResultsDataV2 = ApiTwoWayAnovaResultsV2;

/**
 * CORRELATIONS
 */

/**
 * CORRELATIONS
 */

// V1

export interface GetCorrelationsV1Response extends AxiosResponse {
	data: ScatterResults | RequestError;
}
export interface PearsonStatisticsGroup extends CorrelationsGroup {
	pearson: string;
	pearsonPValue: string;
}

export interface PearsonStatisticsGroupV1 extends CorrelationsGroupV1 {
	pearson: string;
	pearsonPValue: string;
}
export interface SpearmanStatisticsGroup extends CorrelationsGroup {
	spearman: string;
	spearmanPValue: string;
}
export interface SpearmanStatisticsGroupV1 extends CorrelationsGroupV1 {
	spearman: string;
	spearmanPValue: string;
}

export interface LinearRegressionStatisticsGroup extends CorrelationsGroup {
	linearRValue: string;
	linearPValue: string;
	slope?: string;
	intercept?: string;
}

export interface LinearRegressionStatisticsGroupV1 extends CorrelationsGroupV1 {
	linearRValue: string;
	linearPValue: string;
	slope?: string;
	intercept?: string;
}

export interface LinearRegressionStatisticsGroup extends CorrelationsGroup {
	linearRValue: string;
	linearPValue: string;
	slope?: string;
	intercept?: string;
}
export type PearsonResults = {
	data: PearsonResultsData | null;
	error?: boolean;
	errorMessage?: boolean;
};

export type PearsonResultsData = {
	group: string;
	rValue?: string;
	pValue?: string;
	error?: string;
}[];
export type SpearmanResults = {
	data: SpearmanResultsData | null;
	error?: boolean;
	errorMessage?: boolean;
};

export type SpearmanResultsData = {
	group: string;
	rValue?: string;
	pValue?: string;
	error?: string;
}[];

export interface CorrelationsGroup {
	group: string;
	xValues: number[];
	yValues: number[];
	errors?: AnalysisError[];
}

export interface CorrelationsGroupV1 {
	group: string;
	xValues: number[];
	yValues: number[];
	error?: string;
}

export type LinearRegressionResultsV1 = {
	data: LinearRegressionResultsDataV1 | null;
	error?: boolean;
	errorMessage?: string;
};

export type LinearRegressionResultsDataV1 = {
	group: string;
	rValue?: string;
	pValue?: string;
	slope?: string;
	intercept?: string;
	error?: string;
}[];
export interface CorrelationsVariables {
	xVariable: AnalysisVariable | null;
	yVariable: AnalysisVariable | null;
	groupVariables: AnalysisVariable[];
}

export interface CorrelationsV1Variables {
	xNumericVariable: string;
	yNumericVariable: string;
	groupingVariable?: string;
}

export interface CorrelationsStatisticsV2 {
	pearsonV2: boolean;
	spearmanV2: boolean;
	linearRegressionV2: boolean;
}
export interface CorrelationsStatisticsV1 {
	pearsonV1: boolean;
	spearmanV1: boolean;
	linearRegressionV1: boolean;
	// backwards compatibility
	pearson?: boolean;
	spearman?: boolean;
	linearRegression?: boolean;
}

export type CorrelationsResults = CorrelationsGroup[];
export type CorrelationsResultsV2 = AnalysisOutputData<CorrelationsGroup[]>;
export type CorrelationsStatisticsValueResults = CorrelationsStatisticsValue[];

export interface CorrelationsAnalysis extends BaseAnalysis {
	input: {
		variables: CorrelationsVariables;
		statistics: CorrelationsStatisticsV2;
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: CorrelationsResultsV2;
		statistics: {
			pearsonV2: CorrelationsStatisticsResults;
			spearmanV2: CorrelationsStatisticsResults;
			linearRegressionV2: LinearRegressionResults;
		};
	};
}

export interface CorrelationsV1Analysis extends BaseAnalysis {
	input: {
		variables: CorrelationsV1Variables;
		statistics: CorrelationsStatisticsV1;
	};
	output: {
		dataset: CorrelationsResults;
		statistics: {
			pearson: PearsonResults;
			spearman: SpearmanResults;
			linearRegression: LinearRegressionResultsV1;
		};
	};
}

export type CorrelationsStatisticsResults = AnalysisOutputData<CorrelationsStatisticsData>;

export type CorrelationsStatisticsData = {
	group: string;
	rValue?: string;
	pValue?: string;
	error?: string;
}[];

export type LinearRegressionResults = AnalysisOutputData<LinearRegressionResultsData>;

export type LinearRegressionResultsData = {
	coefficients: StatisticCoefficient[] | null;
	groupValue?: string;
	error?: string;
}[];

/**
 * CROSSTAB
 */
export interface CrosstabRowDetails {
	N: string;
	groupVariable: string;
	groupVariableValue: string;
	percent: string;
	variable: string;
	variableValue: string;
}

export interface CrosstabRow {
	label: string;
	valueDetails: CrosstabRowDetails[];
	values: string[];
}

export interface CrosstabRowsData {
	label: string;
	name: string;
	rows: CrosstabRow[];
}

export interface CrosstabColumnsData {
	label: string;
	name: string;
	categoryLabels: string[];
	categoryTotals: number[];
}

export interface CrosstabVariables {
	yVariable: string;
	groupingVariable: string;
}

export interface CrosstabVariablesV2 {
	rowVariable: AnalysisVariable | null;
	columnVariable: AnalysisVariable | null;
}

export interface CrosstabStatistics {
	fisher: boolean;
	chiSquare: boolean;
	mcNemar: boolean;
}

export interface CrosstabStatisticsV2 {
	fisherV2: boolean;
	chiSquareV2: boolean;
	mcNemarV2: boolean;
}

export interface CrosstabResults {
	rows: CrosstabRowsData;
	columns: CrosstabColumnsData;
}

export interface CrosstabResultsDataV2 {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
	rowLabels: string[];
	columnLabels: string[];
	counts: number[][];
	percentages: number[][];
}

export type CrosstabResultsV2 = AnalysisOutputData<CrosstabResultsDataV2>;

export interface CrosstabAnalysis extends BaseAnalysis {
	input: {
		variables: CrosstabVariables;
		statistics: CrosstabStatistics;
	};
	output: {
		dataset: CrosstabResults;
		statistics: {
			fisher: FisherResults;
			chiSquare: ChiSquareResults;
			mcNemar: McNemarResults;
		};
	};
}
export interface CrosstabAnalysisV2 extends BaseAnalysis {
	input: {
		variables: CrosstabVariablesV2;
		statistics: CrosstabStatisticsV2;
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: CrosstabResultsV2;
		statistics: {
			fisher: FisherResultsV2;
			chiSquare: ChiSquareResultsV2;
			mcNemar: McNemarResultsV2;
		};
	};
}

export type FisherResults = {
	data: FisherResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type FisherResultsV2 = {
	data: FisherResultsDataV2 | null;
	error?: AnalysisError;
};

export type FisherResultsData = {
	oddsRatio: number;
	pValue: number;
};

export type FisherResultsDataV2 = {
	statistic: number | null;
	pValue: number | null;
};

export type ChiSquareResults = {
	data: ChiSquareResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type ChiSquareDataResultsV2 = Omit<
	GetChiSquareStatisticsResponseV2['data']['result'],
	'errors'
>;
export type ChiSquareResultsV2 = {
	data: ChiSquareDataResultsV2 | null;
	error?: AnalysisError;
};

export type ChiSquareResultsData = {
	chiSquare_test_statistic: number;
	degrees_of_freedom: number;
	pValue: number;
	valid_test: string;
};

export type McNemarResults = {
	data: McNemarResultsData | null;
	error?: boolean;
};
export type McNemarResultsV2 = {
	data: McNemarResultsDataV2 | null;
	error?: AnalysisError;
};

export type McNemarResultsData = {
	stat: number;
	pValue: number;
	exact: boolean;
};

export type McNemarResultsDataV2 = {
	statistic: number | null;
	pValue: number | null;
	exact: boolean;
};

/**
 * EXPLORE
 */
export interface ExploreVariable {
	variableName: string;
}

export type ExploreVariables = ExploreVariable[];
export type ExploreVariablesV2 = AnalysisVariable[];
export interface DescriptiveStatistics {
	n: number;
	mean: number | null;
	sd: number | null;
	lowerCI: number | null;
	upperCI: number | null;
	sem: number | null;
	variance: number | null;
	skewness: number | null;
	kurtosis: number | null;
	median: number | null;
	min: number | null;
	max: number | null;
	range: number | null;
	lowerIQR: number | null;
	upperIQR: number | null;
	missing: number;
}
export type ExploreResults = VariableStatistics[];

export type ExploreApiDataItem = DescriptiveStatistics & { variable: AnalysisVariable };
export type ExploreApiResultsV2 = ExploreApiDataItem[];

export type ExploreResultsV2 = AnalysisOutputData<VariableStatistics[]>;

export interface ExploreAnalysis extends BaseAnalysis {
	input: {
		variables: ExploreVariables;
	};
	output: {
		dataset: ExploreResults;
	};
}

export interface ExploreAnalysisV2 extends BaseAnalysis {
	input: {
		variables: ExploreVariablesV2;
		series?: string;
		dataModel: DataModel | null;
	};
	output: {
		dataset: ExploreResultsV2;
	};
}

/**
 * FREQUENCIES
 */
export interface FrequenciesDecodedResults {
	results: NumberMap;
}
export interface FrequenciesDecodedResultsV2 {
	dict: NumberMap;
	keys: (keyof FrequenciesDecodedResultsV2['dict'])[];
}

export type FrequenciesResultsV2 = AnalysisOutputData<NumberMap>;

export interface FrequenciesAnalysis extends BaseAnalysis {
	input: {
		variables: {
			categoryVariable: string;
		};
	};
	output: {
		dataset: FrequenciesDecodedResults;
	};
}

export interface FrequenciesAnalysisV2 extends BaseAnalysis {
	input: {
		variables: {
			categoryVariable: AnalysisVariable | null;
		};
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: AnalysisOutputData<FrequenciesDecodedResultsV2>;
	};
}

/**
 * KAPLAN MEIER
 */
export interface KaplanMeierStatistics {
	patients: boolean;
	confidenceIntervals: boolean;
	logRank: boolean;
}
export interface KaplanMeierStatisticsV2 {
	patientsV2: boolean;
	confidenceIntervalsV2: boolean;
	logRankV2: boolean;
}

export interface KaplanMeierVariables {
	durationVariable: string;
	eventVariable: string;
	positiveEvent: string[];
	startDate: string;
	endDate: string;
	timeUnit: TimeWindowSizeType;
	groupVariable?: string;
	autofillDate?: string;
}

export interface KaplanMeierVariablesV2 {
	durationVariable: AnalysisVariable | null;
	eventVariable: AnalysisVariable | null;
	positiveEvent: string[];
	startDate: AnalysisVariable | null;
	endDate: AnalysisVariable | null;
	timeUnit: TimeWindowSizeType;
	groupVariable?: AnalysisVariable | null;
	autofillDate?: string;
	endTimeCensorVariable?: AnalysisVariable | null;
}

export enum KaplanMeierDataModels {
	duration = 'duration',
	timeRangeWithEvent = 'timeRangeWithEvent'
}
export interface KaplanMeierAnalysis extends BaseAnalysis {
	input: {
		selectedDataModel: KaplanMeierDataModels;
		variables: KaplanMeierVariables;
		statistics: KaplanMeierStatistics;
	};
	output: {
		dataset: KaplanMeierResults;
		statistics: {
			logRank: LogRankResults;
		};
	};
}

export interface KaplanMeierAnalysisV2 extends BaseAnalysis {
	input: {
		selectedDataModel: KaplanMeierDataModels | null;
		variables: KaplanMeierVariablesV2;
		statistics: KaplanMeierStatisticsV2;
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: KaplanMeierResultsV2;
		statistics: {
			logRank: LogRankResultsV2;
		};
	};
}

export type LogRankResults = {
	data: LogRankResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type LogRankResultsV2 = AnalysisOutputData<LogRankResultsData>;

export type LogRankResultsData = {
	pValue: number;
	statistic: number;
};

export enum AnalysisStatisticAggregationType {
	Mean = 'mean',
	MeanSD = 'meanSD',
	MeanCI = 'meanCI',
	MeanRange = 'meanRange',
	Median = 'median',
	MedianCI = 'medianCI',
	MedianRange = 'medianRange',
	MedianIQR = 'medianIQR'
}

export enum AnalysisErrorBarType {
	Mean = 'MEAN',
	MeanSD = 'MEANSD',
	MeanCI = 'MEANCI',
	MeanRange = 'MEANRANGE',
	Median = 'MEDIAN',
	MedianCI = 'MEDIANCI',
	MedianRange = 'MEDIANRANGE',
	MedianIQR = 'MEDIANIQR'
}

export enum LineOptions {
	None,
	Mean,
	Median
}

export enum AlignOptions {
	Aligned = 'Aligned',
	Scattered = 'Scattered'
}

export interface PlotNumericVariables {
	categoryVariable: string;
	numericVariable: string;
	groupingVariable?: string;
	errorBar: AnalysisErrorBarType;
}

export interface PlotNumericVariablesV2 {
	categoryVariable: AnalysisVariable | null;
	numericVariable: AnalysisVariable | null;
	groupingVariable?: AnalysisVariable | null;
	errorBar: AnalysisStatisticAggregationType;
}

export interface PlotNumericColumnsUngroupedData {
	categorizationValue: string;
	meanOrMedian: string;
	errorValue?: number;
	errorValueMin?: number;
	errorValueMax?: number;
}

export interface PlotNumericColumnValueV2 {
	center: number;
	lower: number | null;
	upper: number | null;
}
export interface PlotNumericColumnsUngroupedDataV2 {
	mean: number | null;
	median: number | null;
	meanSD: PlotNumericColumnValueV2;
	meanCI: PlotNumericColumnValueV2;
	meanRange: PlotNumericColumnValueV2;
	medianCI: PlotNumericColumnValueV2;
	medianRange: PlotNumericColumnValueV2;
	medianIQR: PlotNumericColumnValueV2;
	errors?: AnalysisError[];
}

export interface PlotNumericColumnsGroupedValue {
	categorizationValue: string;
	groupValue: string;
	meanOrMedian: string;
	errorValue?: number;
	errorValueMin?: number;
	errorValueMax?: number;
}

export interface PlotNumericColumnsGroupedData {
	categorizationValue: string;
	groupedCalculations: PlotNumericColumnsGroupedValue[];
}
export interface PlotNumericColumnsGroupedDataV2 {
	values: PlotNumericColumnsUngroupedDataV2;
	groupIdentifiers: GroupIdentifier[];
}

export interface PlotNumericBoxplotUngroupedData {
	categorizationValue: string;
	median: number | string;
	errorValueMin: string;
	errorValueMax: string;
	IQRLower: string;
	IQRUpper: string;
	errors?: AnalysisError[];
}

export interface PlotNumericBoxplotUngroupedDataV2 {
	median: number;
	min: number;
	max: number;
	firstQuartile: number;
	thirdQuartile: number;
	errors?: AnalysisError[];
}

export interface PlotNumericBoxplotGroupedValue {
	groupValue: string;
	median: number | string;
	errorValueMin: string;
	errorValueMax: string;
	IQRLower: string;
	IQRUpper: string;
}

export interface PlotNumericBoxplotGroupedData {
	categorizationValue: string;
	groupedCalculations: PlotNumericBoxplotGroupedValue[];
}

export interface PlotNumericBoxplotGroupedDataV2 {
	values: PlotNumericBoxplotUngroupedDataV2;
	groupIdentifiers: GroupIdentifier[];
}

export interface PlotNumericScatterValue {
	categorizationValue: string;
	mean: number | string;
	median: number | string;
	yValues: number[];
}
// PARSED TYPE
export type PlotNumericScatterValueResultV2 = {
	mean: number;
	median: number;
	numericValues: number[];
	group1: GroupIdentifier;
};
// API TYPE
export type PlotNumericScatterGroupedResultV2 = {
	values: {
		mean: number;
		median: number;
		numericValues: number[];
	};
	groupIdentifiers: GroupIdentifier[];
};

export type PlotNumericColumnsSingle = PlotNumericColumnsUngroupedData[];
export type PlotNumericColumnsGrouped = PlotNumericColumnsGroupedData[];
export type PlotNumericColumnsResults = PlotNumericColumnsSingle | PlotNumericColumnsGrouped;

// V2
export type PlotNumericColumnsSingleV2 = PlotNumericColumnsUngroupedDataV2;
export type PlotNumericColumnsGroupedV2 = PlotNumericColumnsGroupedDataV2[];

export type PlotNumericColumnsResultsV2 = {
	result: PlotNumericColumnsSingleV2 | PlotNumericColumnsGroupedV2;
};

// PARSED TYPE
export type PlotNumericColumnV2 = PlotNumericColumnsUngroupedDataV2 & {
	group1: GroupIdentifier;
	group2?: GroupIdentifier;
};
export type PlotNumericColumnsV2 = PlotNumericColumnV2[];

export type PlotNumericBoxplotSingle = PlotNumericBoxplotUngroupedData[];
export type PlotNumericBoxplotGrouped = PlotNumericBoxplotGroupedData[];
export type PlotNumericBoxplotResults = PlotNumericBoxplotSingle | PlotNumericBoxplotGrouped;

//V2
export type PlotNumericBoxplotSingleV2 = PlotNumericBoxplotUngroupedDataV2;
export type PlotNumericBoxplotGroupedV2 = PlotNumericBoxplotGroupedDataV2[];
export type PlotNumericBoxplotResultsV2 = {
	result: PlotNumericBoxplotSingleV2 | PlotNumericBoxplotGroupedV2;
};

// PARSED TYPE
type PlotNumericBoxPlotV2 = PlotNumericBoxplotSingleV2 & {
	group1: GroupIdentifier;
	group2?: GroupIdentifier;
};
export type PlotNumericBoxPlotsV2 = PlotNumericBoxPlotV2[];

export type PlotNumericScatterResults = PlotNumericScatterValue[];
export type PlotNumericScatterResultsV2 = { result: PlotNumericScatterGroupedResultV2[] };

// PARSED TYPE
export type PlotNumericScatterV2 = PlotNumericScatterValueResultV2[];
export interface PlotNumericResults {
	columns: PlotNumericColumnsResults;
	boxplot: PlotNumericBoxplotResults;
	scatter: PlotNumericScatterResults;
}

export interface PlotNumericResultsV2 {
	columns: AnalysisOutputData<PlotNumericColumnsV2>;
	boxplot: AnalysisOutputData<PlotNumericBoxPlotsV2>;
	scatter: AnalysisOutputData<PlotNumericScatterV2>;
}

export interface PlotNumericAnalysis extends BaseAnalysis {
	input: {
		variables: PlotNumericVariables;
	};
	output: {
		dataset: PlotNumericResults;
		grouping: boolean;
	};
}

export interface PlotNumericAnalysisV2 extends BaseAnalysis {
	input: {
		variables: PlotNumericVariablesV2;
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: PlotNumericResultsV2;
		grouping: boolean;
	};
}

interface DensityPlotValues {
	groupingyValue: string;
	histogramColumns: number[];
	histogramBins: number[];
	densityPlot: number[];
	values: number[];
}

export interface DensityPlotVariables {
	numericVariable: string;
	groupingVariable?: string;
}

export interface DensityPlotVariablesV2 {
	numericVariable: AnalysisVariable | null;
	groupVariables?: AnalysisVariable[];
}

export type DensityPlotResults = DensityPlotValues[];
export type DensityPlotResultsV2 = AnalysisOutputData<DensityPlotValues[]>;

export type GetUngroupedDensityPlotResultV2 = {
	xValues: number[] | null;
	yValues: number[] | null;
	histogram: {
		bins: number[];
		values: number[];
	} | null;
	errors?: AnalysisError[];
};

export type GetGroupedDensityPlotResultV2 = {
	groupIdentifiers: GroupIdentifier[];
	values: GetUngroupedDensityPlotResultV2;
};

export interface DensityPlotAnalysis extends BaseAnalysis {
	input: {
		variables: DensityPlotVariables;
	};
	output: {
		dataset: DensityPlotResults;
	};
}

export interface DensityPlotAnalysisV2 extends BaseAnalysis {
	input: {
		variables: DensityPlotVariablesV2;
		series?: string;
		dataModel: DataModel | null;
	};
	output: {
		dataset: DensityPlotResultsV2;
	};
}
export interface DensityPlotAnalysis extends BaseAnalysis {
	input: {
		variables: DensityPlotVariables;
	};
	output: {
		dataset: DensityPlotResults;
	};
}

/**
 * TIME COURSE
 */

export enum TimeWindowSizeType {
	Years = 'years',
	Months = 'months',
	Weeks = 'weeks',
	Days = 'days',
	Hours = 'hours',
	Minutes = 'minutes',
	Seconds = 'seconds'
}

interface TimeCourseSingleValueV2 {
	timeCourse: {
		timeline: number[];
		yValues: number[];
		lowerLimit?: number[] | null;
		upperLimit?: number[] | null;
	};
}
interface TimeCourseSingleValueV1 {
	timeCourse: {
		timeline: number[];
		yValues: number[];
		lowerLimit?: number[];
		upperLimit?: number[];
	};
}

export interface TimeCourseGroupedValueV2 extends TimeCourseSingleValueV2 {
	group: {
		groupVariable: string;
		groupValue: string;
	};
}

export interface TimeCourseGroupedValueV1 extends TimeCourseSingleValueV1 {
	group: {
		groupVariable: string;
		groupValue: string;
	};
}

export interface TimeCourseVariablesV2 {
	numericVariable: AnalysisVariable | null;
	timeVariable: AnalysisVariable | null;
	timeUnit?: TimeWindowSizeType | null;
	statistic: AnalysisStatisticAggregationType;
	groupVariables?: AnalysisVariable[];
}

export interface TimeCourseVariablesV1 {
	numericVariable: string;
	dateVariable: string;
	timeWindowSize: TimeWindowSizeType;
	errorBar: AnalysisErrorBarType;
	groupingVariable?: string;
}

export interface TimeCourseGroupedValuesV1 {
	groupedTimeCourse: TimeCourseGroupedValueV1[];
}

export interface TimeCourseGroupedValuesV2 {
	groupedTimeCourse: TimeCourseGroupedValueV2[];
}

export type TimeCourseSingleV1 = TimeCourseSingleValueV1;
export type TimeCourseGroupedV1 = TimeCourseGroupedValuesV1;
export type TimeCourseResultsV1 = TimeCourseSingleV1 | TimeCourseGroupedV1;

export type TimeCourseSingleV2 = TimeCourseSingleValueV2;
export type TimeCourseGroupedV2 = TimeCourseGroupedValuesV2;
export type TimeCourseResultsDataV2 = TimeCourseSingleV2 | TimeCourseGroupedV2;
export type TimeCourseResultsV2 = AnalysisOutputData<TimeCourseResultsDataV2>;

export interface TimeCourseAnalysisV2 extends BaseAnalysis {
	input: {
		variables: TimeCourseVariablesV2;
		series?: string;
		dataModel: DataModel | null;
	};
	output: {
		dataset: TimeCourseResultsV2 | null;
		grouping: boolean;
	};
}

export interface TimeCourseAnalysisV1 extends BaseAnalysis {
	input: {
		variables: TimeCourseVariablesV1;
	};
	output: {
		dataset: TimeCourseResultsV1 | null;
		grouping: boolean;
	};
}

export interface GetUngroupedTimeCourseData {
	timeline: number[];
	yValues: number[];
	lowerLimit: number[] | null;
	upperLimit: number[] | null;
}

export interface GetGroupedTimeCourseData {
	groupIdentifiers: GroupIdentifier[];
	values: GetUngroupedTimeCourseData;
}
[];

export interface GetTimeCourseResponseV1 extends AxiosResponse {
	data: TimeCourseDataV1;
}

export type GetTimeCourseResponseV2 = GenericApiResponse<{
	result: GetUngroupedTimeCourseData | GetGroupedTimeCourseData[];
}>;

export type TimeCourseDataV1 = TimeCourseResultsV1 & RequestError;
export type TimeCourseInputV1 = TimeCourseVariablesV1 & AnalysisBaseInput;
export type TimeCourseInputV2 = TimeCourseVariablesV2 & AnalysisBaseInput;

export interface ComparePairedAnalysis extends BaseAnalysis {
	input: {
		dataModel: ComparePairedDataModels;
		variables: ComparePairedVariables;
		statistics: ComparePairedStatistics;
	};
	output: {
		dataset: ComparePairedResults;
		statistics: {
			pairedTTest: PairedTTestResults;
			pairedWilcoxon: PairedWilcoxonResults;
		};
	};
}

export interface ComparePairedAnalysisV2 extends BaseAnalysis {
	input: {
		variables: ComparePairedVariablesV2;
		statistics: ComparePairedStatisticsV2;
		series?: string;
		dataModel: ComparePairedDataModels | null;
	};
	output: {
		dataset: ComparePairedResultsV2;
		statistics: {
			pairedTTest: PairedTTestResultsV2;
			pairedWilcoxon: PairedWilcoxonResultsV2;
		};
	};
}

export enum ComparePairedDataModels {
	SINGLE_ENTRY_PER_SUBJECT = 'SINGLE_ENTRY_PER_SUBJECT',
	MULTIPLE_ENTRIES_PER_SUBJECT = 'MULTIPLE_ENTRIES_PER_SUBJECT',
	USING_SERIES = 'Series'
}

export interface ComparePairedVariables {
	numericVariableOne: string;
	numericVariableTwo: string;
	catVarnameDiffSamples: string;
	firstCategoryValue: string;
	secondCategoryValue: string;
	patientIdentifierVarname: string;
	setName: string;
	testVariable: string;
	groupVariable: string;
	groupOne: string;
	groupTwo: string;
}

export interface ComparePairedVariablesV2 {
	beforeVariable: AnalysisVariable | null;
	afterVariable: AnalysisVariable | null;
	pairIdentifier: AnalysisVariable | null;
	numericVariable: AnalysisVariable | null;
	groupVariable: AnalysisVariable | null;
}

export interface ComparePairedStatistics {
	pairedTTest: boolean;
	pairedWilcoxon: boolean;
}

export interface ComparePairedStatisticsV2 {
	pairedTTestV2: boolean;
	pairedWilcoxonV2: boolean;
}

export type ComparePairedResults = {
	data: ComparePairedResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type ComparePairedResultsData = {
	delta_stats: ComparePairedStatisticsData;
	sample_values: {
		variableName: string;
		categoryValue?: string;
		stats: ComparePairedStatisticsData;
	}[];
};

export type ComparePairedResultsV2 = AnalysisOutputData<ComparePairedResultsDataV2>;

export type ComparePairedResultsDataV2 = ComparePairedValueV2[];

export interface ComparePairedStatisticsData {
	average: string;
	median: string;
	std: string;
	confidentLower: string;
	confidentUpper: string;
	confidentLowerMedian: string;
	confidentUpperMedian: string;
	iqr: string;
	IQRLower: string;
	IQRUpper: string;
	variance: string;
	kurtosis: string;
	skewness: string;
	sem: string;
	min: string;
	max: string;
	'nr of rows': number;
	missing: number;
}

export interface LogisticRegressionAnalysis extends BaseAnalysis {
	input: {
		variables: LogisticRegressionVariables;
	};
	output: {
		dataset: GetLogisticRegressionOutput | null;
	};
}

export interface LogisticRegressionAnalysisV2 extends BaseAnalysis {
	input: {
		variables: LogisticRegressionVariablesV2;
		dataModel: DataModel | null;
		series?: string;
	};
	output: {
		dataset: GetLogisticRegressionOutputV2;
	};
}

export interface LogisticRegressionVariables {
	dependentVariable: string;
	positiveEvent: string[];
	independentVariable: string;
	groupVariable?: string;
}

export interface LogisticRegressionVariablesV2 {
	xVariable: AnalysisVariable | null;
	outcomes: string[];
	yVariable: AnalysisVariable | null;
	groupVariables?: AnalysisVariable[];
}

export interface LogisticRegressionResults
	extends LogisticRegressionData,
		GroupedLogisticRegressionData {
	error?: boolean;
	errorMessage?: boolean;
}

export interface UngroupedLogisticRegressionDataV2 {
	coefficients:
		| {
				type: string;
				variable: AnalysisVariable;
				categoryValue: string | null;
				estimate: number;
				standardError: number | null;
				statistic: number | null;
				pValue: number | null;
				lowerCI: number | null;
				upperCI: number | null;
		  }[]
		| null;
	xValues: number[];
	yValues: number[];
	errors?: AnalysisError[];
}
export interface GroupedLogisticRegressionDataV2 {
	groupIdentifiers: GroupIdentifier[];
	values: UngroupedLogisticRegressionDataV2;
}

export interface LogisticRegressionResultsV2 {
	result: UngroupedLogisticRegressionDataV2 | GroupedLogisticRegressionDataV2[];
}

export interface GroupedLogisticRegressionData {
	groupedLogisticRegression: GroupedLogisticRegressionResult[];
}

export interface GroupedLogisticRegressionResult extends LogisticRegressionData {
	group: {
		groupVariable: string;
		groupValue: string;
	};
}

export interface GroupedLogisticRegressionResultV2 extends LogisticRegressionDataV2 {
	group: {
		groupVariable: string;
		groupValue: string;
	};
}

export interface LogisticRegressionData {
	logisticRegression: LogisticRegressionDataResults | null;
}

export interface LogisticRegressionDataV2 {
	logisticRegression: LogisticRegressionDataResultsV2 | null;
}

export interface LogisticRegressionDataResults {
	coefficients: LogisticRegressionCoefficients | null;
	xValues: number[];
	yValues: number[];
	error?: {
		type: string;
		message: string;
	};
}

export interface LogisticRegressionDataResultsV2 {
	coefficients: LogisticRegressionCoefficients | null;
	xValues: number[];
	yValues: number[];
	error?: AnalysisError;
}

export type LogisticRegressionCoefficients = {
	type: string;
	estimate: number;
	standardError: string;
	statistic: string;
	pValue: string;
	variableName?: string;
	lowerCI: string;
	upperCI: string;
}[];
export interface NumberPlotXYAnalysis extends BaseAnalysis {
	input: {
		variables: {
			x_value: string;
			y_value: string[];
			grouping_variable?: string;
		};
	};
	output: {
		dataset: NumberPlotXYResults;
	};
}

export type NumberPlotXYResults = {
	data: NumberPlotXYResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type NumberPlotXYResultsData = {
	PlotResult: {
		x_value: Record<string, (string | number)[]>;
		y_value: Record<string, (string | number)[]>;
	};
};

export type PairedTTestResults = {
	data: PairedTTestResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type PairedTTestResultsV2 = {
	data: PairedTTestResultsDataV2 | null;
	error?: AnalysisError;
};

export type PairedTTestResultsData = {
	tStat: number;
	pValue: number;
	df?: number;
};
export type PairedTTestResultsDataV2 = {
	statistic: number;
	pValue: number;
	df?: number;
};

export type PairedWilcoxonResults = {
	data: PairedWilcoxonResultsData | null;
	error?: boolean;
	errorMessage?: string;
};

export type PairedWilcoxonResultsV2 = {
	data: PairedWilcoxonResultsDataV2 | null;
	error?: AnalysisError;
};

export type PairedWilcoxonResultsData = {
	tStat: number;
	pValue: number;
};

export type PairedWilcoxonResultsDataV2 = {
	statistic: number;
	pValue: number;
};

export interface ComparePairedInputOneSubjectPerEntry {
	numericVariableOne: { variableName: string };
	numericVariableTwo: { variableName: string };
}

export interface ComparePairedInputOneSubjectPerEntryV2 {
	beforeVariable: AnalysisVariable;
	afterVariable: AnalysisVariable;
}

export interface ComparePairedInputMultipleEntriesPerSubject {
	testVariable: { variableName: string };
	groupVariable: { variableName: string };
	groupOne: string;
	groupTwo: string;
	pairIdentifier: { variableName: string };
}
export interface ComparePairedInputMultipleEntriesPerSubjectV2 {
	numericVariable: AnalysisVariable;
	groupVariable: AnalysisVariable;
	pairingVariable: AnalysisVariable;
}

export interface ComparePairedInputUsingSeries {
	set: { setName: string };
	testVariable: { variableName: string };
	groupVariable: { variableName: string };
	groupOne?: string;
	groupTwo?: string;
}
export interface ComparePairedInputUsingSeriesV2 {
	numericVariable: AnalysisVariable;
	groupVariable: AnalysisVariable;
}

export type ComparePairedValueV2 = {
	variable: AnalysisVariable;
	n: number;
	mean: number;
	sd: number;
	lowerCI: number;
	upperCI: number;
	sem: number;
	variance: number;
	skewness: number;
	kurtosis: number;
	median: number;
	min: number;
	max: number;
	range: number;
	lowerIQR: number;
	upperIQR: number;
	missing: number;
};
export type ComparePairedGroupedResponseV2 = ComparePairedValueV2[];

export enum JADBioAnalysisModels {
	Classification = 'classification',
	SurvivalAnalysis = 'survival',
	Regression = 'regression'
}

export const JADBioAnalysisModelsLabels = {
	[JADBioAnalysisModels.Classification]: 'analysis.analyses.JADBio.config.model.classification',
	[JADBioAnalysisModels.SurvivalAnalysis]:
		'analysis.analyses.JADBio.config.model.survivalAnalysis',
	[JADBioAnalysisModels.Regression]: 'analysis.analyses.JADBio.config.model.regression'
};

export interface JADBioModelElement {
	type: string;
	featureSelection: string;
	model: string;
	preprocessing: string;
	performance: StringMap;
	signatures: Array<string>;
}

export interface JADBioAnalysis extends BaseAnalysis {
	input: JADBioAnalysisInput;
	output: { dataset: JADBioOutputs };
}

export type JADBioAnalysisInput = {
	model: JADBioAnalysisModels | null;
	variables: {
		// CLASSIFICATION MODEL
		classificationVariable: string; // `category` VARIABLE
		// SURVIVAL ANALYSIS MODEL
		eventVariableName: string; // `category` VARIABLE
		timeToEventVariableName: string; // `integer` | `float` VARIABLE
		// REGRESSION MODEL
		regressionVariable: string; // `integer` | `float` VARIABLE
	};
	performanceReductionType: JADBioReductionType;
	featureImportanceType: JADBioFeatureImportancePlots;
	probabilitiesDisplayType: JADBioProbabilitiesDisplayType;

	datasetInitialized: boolean | null;
	jadBioDatasetId: string | null;
};

export enum JADBioReductionType {
	PCA = 'PCA',
	UMAP = 'UMAP'
}

export enum JADBioFeatureImportancePlots {
	FeatureImportance = 'FeatureImportance',
	ProgressiveFeatureInclusion = 'ProgressiveFeatureInclusion'
}

export const JADBioFeatureImportanceLabels = {
	[JADBioFeatureImportancePlots.FeatureImportance]:
		'analysis.analyses.JADBio.config.featureImportance',
	[JADBioFeatureImportancePlots.ProgressiveFeatureInclusion]:
		'analysis.analyses.JADBio.config.progressiveFeatureInclusion'
};

export enum JADBioProbabilitiesDisplayType {
	DensityPlot = 'DensityPlot',
	BoxPlot = 'BoxPlot'
}

export const JADBioProbabilitiesDisplayLabels = {
	[JADBioProbabilitiesDisplayType.DensityPlot]: 'analysis.analyses.JADBio.config.densityPlot',
	[JADBioProbabilitiesDisplayType.BoxPlot]: 'analysis.analyses.JADBio.config.boxPlot'
};

export type JADBioOutputs = {
	[JADBioAnalysisModels.Classification]: JADBioClassificationOutput;
	[JADBioAnalysisModels.SurvivalAnalysis]: JADBioSurvivalOutput;
	[JADBioAnalysisModels.Regression]: JADBioRegressionOutput;
};

export type JADBioOutput = {
	summary: JADBioModelElement[];
	progress: number | null;
	pauseProgress: boolean;
	stopProgress: boolean;
	jadBioAnalysisId: string;
};

export type JADBioOutputMap =
	| { [JADBioAnalysisModels.Classification]: JADBioClassificationOutput }
	| { [JADBioAnalysisModels.SurvivalAnalysis]: JADBioSurvivalOutput }
	| { [JADBioAnalysisModels.Regression]: JADBioRegressionOutput };

export interface JADBioClassificationOutput extends JADBioOutput {
	plots: {
		performance: {
			UMAP: JADBioScatterPlot | null;
			PCA: JADBioScatterPlot | null;
			probabilitiesDensityPlot: JADBioScatterPlot | null;
			probabilitiesBoxPlot: PlotNumericBoxplotUngroupedData[];
			featureImportance: JADBioFeatureImportanceElement[];
			progressiveFeatureImportance: JADBioProgressiveFeatureImportanceElement[];
		};
	} | null;
}

export interface JADBioFeatureImportanceElement {
	name: string;
	cis: number[];
	value: number;
}

export interface JADBioProgressiveFeatureImportanceElement {
	name: string[];
	cis: number[];
	value: number;
}

export interface ScatterGroup {
	group: string;
	xValues: number[];
	yValues: number[];
	error?: string;
}
export type ScatterResults = ScatterGroup[];

export interface JADBioScatterPlot {
	title: string;
	xaxis: string;
	yaxis: string;
	groups: JADBioScatterGroup[];
}

export interface JADBioScatterGroup {
	group: string;
	xValues: number[];
	yValues: number[];
}

export interface JADBioSurvivalOutput extends JADBioOutput {
	plots: null; // TODO: parse data
}

export interface JADBioRegressionOutput extends JADBioOutput {
	plots: null; // TODO: parse data
}

export interface AnalysisChartState {
	[analysisId: string]: {
		[plotIndex: string]: boolean;
	};
}

export type Analysis =
	| CompareNumericAnalysisV1
	| CompareNumericAnalysisV2
	| CorrelationsAnalysis
	| CorrelationsV1Analysis
	| CrosstabAnalysis
	| CrosstabAnalysisV2
	| ExploreAnalysis
	| ExploreAnalysisV2
	| FrequenciesAnalysis
	| FrequenciesAnalysisV2
	| KaplanMeierAnalysis
	| KaplanMeierAnalysisV2
	| PlotNumericAnalysis
	| PlotNumericAnalysisV2
	| DensityPlotAnalysis
	| DensityPlotAnalysisV2
	| TimeCourseAnalysisV1
	| TimeCourseAnalysisV2
	| ComparePairedAnalysis
	| ComparePairedAnalysisV2
	| LogisticRegressionAnalysis
	| LogisticRegressionAnalysisV2
	| NumberPlotXYAnalysis
	| JADBioAnalysis;

interface AnalysisBaseInput {
	projectId: number;
	datasetId?: number;
	format?: string;
	timeFormat?: string;
	filters?: ApiEntryFilters;
}

export interface GetCorrelationsInputV1 extends AnalysisBaseInput {
	xNumericVariable: string;
	yNumericVariable: string;
	groupingVariable?: string;
}
export interface GetCorrelationsInput extends AnalysisBaseInput {
	xVariable: AnalysisVariable | null;
	yVariable: AnalysisVariable | null;
	groupVariables: AnalysisVariable[];
	type?: 'spearman' | 'pearson';
}

export interface AnalysisVariable {
	name: string;
	series?: string | null;
}

export interface GetScatterPlotVariables {
	xVariable: AnalysisVariable;
	yVariable: AnalysisVariable;
	groupVariables: AnalysisVariable[];
}

export type GetScatterPlotInput = AnalysisBaseInput & CorrelationsVariables;
export interface GetScatterPlotResponse extends AxiosResponse {
	data: ScatterResults | RequestError;
}

export interface GroupIdentifier {
	value: string;
	variable: AnalysisVariable;
}

export interface GetUngroupedCorrelationsData {
	xValues: number[];
	yValues: number[];
}

export interface GetUngroupedCorrelationsData {
	xValues: number[];
	yValues: number[];
	errors?: AnalysisError[];
}

export interface GetGroupedCorrelationsData {
	groupIdentifiers: GroupIdentifier[];
	values: GetUngroupedCorrelationsData;
}
export type GetCorrelationsResponse = GenericApiResponse<{
	result: GetUngroupedCorrelationsData | GetGroupedCorrelationsData[];
}>;

export interface CrosstabDataResponse {
	crosstab: {
		[columnNameValue: string]: [
			{
				rowName: string;
				percentage: string;
				count: string;
			}
		];
	};
}

export interface CrosstabDataResponseV2 {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
	rowLabels: string[];
	columnLabels: string[];
	counts: number[][];
	percentages: number[][];
}
export interface CrosstabData {
	yVariable: CrosstabRowsData;
	groupingVariable: CrosstabColumnsData;
}

export type CrosstabDataV2 = CrosstabDataResponseV2;

/*
	GET CROSSTAB
*/
export type GetCrosstabInput = AnalysisBaseInput & {
	row: { variableName: string };
	column: { variableName: string };
};
export type GetCrosstabInputV2 = AnalysisBaseInput & {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
};

export type GetCrosstabRequest = NetworkRequestBase & GetCrosstabInput;
export type GetCrosstabResponse = GenericApiResponse<CrosstabDataResponse>;
export type GetCrosstabOutput = CrosstabData;

export type GetCrosstabRequestV2 = NetworkRequestBase & GetCrosstabInputV2;
export type GetCrosstabResponseV2 = GenericApiResponse<{ result: CrosstabDataResponseV2 }>;
export type GetCrosstabOutputV2 = AnalysisOutputData<CrosstabDataResponseV2>;

export interface GetExploreInput extends AnalysisBaseInput {
	requestedVariables: ExploreVariables;
}

export interface GetExploreInputV2 extends AnalysisBaseInput {
	numericVariables: ExploreVariablesV2;
}

export interface GetExploreResponse extends AxiosResponse {
	data: ExploreResults | RequestError;
}

export type GetExploreResponseV2 = GenericApiResponse<{ result: ExploreApiResultsV2 }>;

export interface GetFrequenciesInput extends AnalysisBaseInput {
	categoryVariable: string;
}
export interface GetFrequenciesInputV2 extends AnalysisBaseInput {
	categoryVariable: AnalysisVariable;
}

export interface FrequenciesResults {
	frequencies: FrequenciesResult[];
}

export interface FrequenciesApiResultsV2 {
	result: FrequenciesApiResultV2[];
}

export interface FrequenciesResult {
	categoryValue: string | null;
	count: number;
}

export interface FrequenciesApiResultV2 {
	categoryValue: string | null;
	count: number;
}

export type FrequenciesData = FrequenciesResults & RequestError;

export interface GetFrequenciesResponse extends AxiosResponse {
	data: FrequenciesData;
}

export type FrequenciesDataV2 = FrequenciesApiResultsV2 & RequestError;

export interface GetFrequenciesResponseV2 extends AxiosResponse {
	data: FrequenciesDataV2;
}

export interface GetKaplanMeierRequestInput extends AnalysisBaseInput {
	durationVariable?: { variableName: string };
	eventVariable?: { variableName: string };
	positiveEvent?: string[];
	startDate?: { variableName: string };
	timeUnit?: TimeWindowSizeType;
	endDate?: { variableName: string };
	autofillDate?: string;
	groupVariable?: { variableName: string };
}

export type GetKaplanMeierRequestInputV2 =
	| GetKaplanMeierDurationRequestInputV2
	| GetKaplanMeierTimeRangeRequestInputV2;

export interface GetKaplanMeierDurationRequestInputV2 extends AnalysisBaseInput {
	durationVariable: AnalysisVariable | null;
	eventVariable: AnalysisVariable | null;
	events: string[];
	groupVariables?: AnalysisVariable[];
}

export interface GetKaplanMeierTimeRangeRequestInputV2 extends AnalysisBaseInput {
	startTimeVariable: AnalysisVariable | null;
	endTimeVariable: AnalysisVariable | null;
	eventVariable: AnalysisVariable | null;
	events: string[];
	groupVariables?: AnalysisVariable[];
	timeUnit: TimeWindowSizeType;
	endTimeFillValue?: string;
	endTimeCensorVariable?: AnalysisVariable | null;
}

export interface GetLogRankStatistics {
	durationVariable?: string;
	eventVariable?: string;
	positiveEvent?: string[];
	startDate?: string;
	timeUnit?: TimeWindowSizeType;
	endDate?: string;
	autofillDate?: string;
	groupVariable: string;
}
export interface GetLogRankDurationStatisticsV2 {
	durationVariable?: AnalysisVariable;
	eventVariable?: AnalysisVariable;
	events: string[];
	groupVariable?: AnalysisVariable;
}

export interface GetLogRankTimeRangeStatisticsV2 {
	startTimeVariable: AnalysisVariable;
	endTimeVariable: AnalysisVariable;
	eventVariable: AnalysisVariable;
	timeUnit: TimeWindowSizeType;
	events: string[];
	groupVariable?: AnalysisVariable;
	endTimeFillValue?: string;
	endTimeCensorVariable?: AnalysisVariable | null;
}

export type GetLogRankStatisticsV2 =
	| GetLogRankTimeRangeStatisticsV2
	| GetLogRankDurationStatisticsV2;
export interface ApiKaplanMeier {
	estimate: number[];
	timeline: number[];
	lowerCI: number[];
	upperCI: number[];
	numberAtRisk: number[];
	numberAtRiskMatrix: NumberAtRiskTableMatrix;
	timeUnit: string;
}

export interface GroupedKaplanMeier {
	group: {
		groupVariable: string;
		groupValue: string;
	};
	kaplanMeier: ApiKaplanMeier;
}
export interface ApiGroupedKaplanMeier {
	groupedKaplanMeier: GroupedKaplanMeier[];
}

export interface NumberAtRiskTableMatrix {
	timeline: number[];
	numberAtRisk: number[];
}

export interface GetKaplanMeierSingleResponse {
	kaplanMeier: ApiKaplanMeier;
}

interface GroupedResponse extends AxiosResponse {
	data: (GetKaplanMeierSingleResponse | ApiGroupedKaplanMeier) & RequestError;
}
export type GetKaplanMeierResponse = GroupedResponse;

export type GetKaplanMeierSingleDataV2 = KaplanMeierDataResultsV2;
export type GetKaplanMeierGroupedDataV2 = {
	groupIdentifiers: GroupIdentifier[];
	values: KaplanMeierDataResultsV2;
};
export type GetKaplanMeierResponseV2 = GenericApiResponse<{
	result: GetKaplanMeierSingleDataV2 | GetKaplanMeierGroupedDataV2[];
}>;

export interface KaplanMeierDataResults {
	estimate: number[];
	timeline: number[];
	lowerCI: number[];
	upperCI: number[];
	numberAtRisk: number[];
	numberAtRiskMatrix: NumberAtRiskTableMatrix;
	timeUnit?: string;
}

export interface KaplanMeierDataResultsV2 {
	estimate: number[];
	timeline: number[];
	lowerCI: number[];
	upperCI: number[];
	numberAtRisk: number[];
	numberCensored: number[];
	mediansurvivalTime?: number;
	numberAtRiskMatrix: NumberAtRiskTableMatrix;
	timeUnit?: string;
}

export type KaplanMeierResults = GenericMap<KaplanMeierDataResults>;
export type KaplanMeierResultsDataV2 = GenericMap<KaplanMeierDataResultsV2>;
export type KaplanMeierResultsV2 = AnalysisOutputData<KaplanMeierResultsDataV2>;

export interface GetPlotNumericInput extends AnalysisBaseInput {
	categoryVariable: string;
	numericVariable: string;
	groupingVariable?: string;
	errorBar?: AnalysisErrorBarType;
}
export interface GetPlotNumericInputV2 extends AnalysisBaseInput {
	numericVariable: AnalysisVariable;
	groupVariables: AnalysisVariable[];
}

export type PlotNumericData = (
	| PlotNumericBoxplotResults
	| PlotNumericColumnsResults
	| PlotNumericScatterResults
) &
	RequestError;

export type PlotNumericDataV2 = (
	| PlotNumericBoxplotResultsV2
	| PlotNumericColumnsResultsV2
	| PlotNumericScatterResultsV2
) &
	RequestError;

export interface GetPlotNumericResponse extends AxiosResponse {
	data: PlotNumericData;
}

export interface GetPlotNumericResponseV2 extends AxiosResponse {
	data: PlotNumericDataV2;
}

export interface GetDensityPlotInput extends AnalysisBaseInput {
	numericVariable: string;
	groupingVariable?: string;
}
export interface GetDensityPlotInputV2 extends AnalysisBaseInput, DensityPlotVariablesV2 {}
('');

export type GetDensityPlotResponse = GenericApiResponse<DensityPlotResults>;
export type GetDensityPlotResponseV2 = GenericApiResponse<{
	result: GetGroupedDensityPlotResultV2[] | GetUngroupedDensityPlotResultV2;
}>;

export interface AnalysisError {
	code: string;
	message: string;
	values?: StringMap;
}

export interface CorrelationsStatisticsValue {
	p?: string;
	r?: string;
	slope?: string;
	intercept?: string;
}

interface CorrelationValues {
	correlation: string;
	pValue: string;
}

export type UngroupedCorrelationStatistics = CorrelationValues & { errors?: AnalysisError[] };

export interface GroupedCorrelationStatistics {
	groupIdentifiers: [
		{
			variable: AnalysisVariable;
			value: string;
		}
	];
	values: CorrelationStatisticsValues;
}
export interface CorrelationStatisticsValues {
	correlation: string;
	pValue: string;
	errors?: AnalysisError[];
}

export interface CorrelationStatisticsResponse {
	result: GroupedCorrelationStatistics[] | UngroupedCorrelationStatistics;
}

export type SpearmanStatisticsResponse = CorrelationStatisticsResponse;
export type PearsonStatisticsResponse = CorrelationStatisticsResponse;

interface StatisticCoefficient {
	estimate: number | null;
	type: string;
	statistic: number | null;
	standardError?: number | null;
	pValue?: number | null;
	lowerCI?: number | null;
	upperCI?: number | null;
	variable?: AnalysisVariable;
}

export interface GroupedLinearRegressionResponse {
	groupIdentifiers: GroupIdentifier[] | null;
	values: { coefficients: StatisticCoefficient[]; errors?: AnalysisError[] };
}
export interface UngroupedLinearRegressionResponse {
	coefficients: StatisticCoefficient[] | null;
	errors: AnalysisError[];
}
export type LinearRegressionResponse = {
	result: UngroupedLinearRegressionResponse | GroupedLinearRegressionResponse[];
};

/*
	GET COMPARE NUMERIC
*/
export type GetCompareNumericInputV1 = AnalysisBaseInput & {
	categoryVariable: string | string[];
	exploreVariable: string | string[];
};
export type GetCompareNumericRequestV1 = NetworkRequestBase & GetCompareNumericInputV1;
export type GetCompareNumericResponseV1 = GenericApiResponse<CompareNumericResultsV1>;
export type GetCompareNumericOutputV1 = CompareNumericResultsV1;

export type GetCompareNumericInputV2 = AnalysisBaseInput & {
	groupVariables: AnalysisVariable[];
	numericVariables: AnalysisVariable[];
};
export type GetCompareNumericRequestV2 = NetworkRequestBase & GetCompareNumericInputV2;
export type GetCompareNumericResponseV2 = GenericApiResponse<CompareNumericResultsData>;
export type GetCompareNumericOutputV2 = CompareNumericResultsV2;

/*
	GET COMPARE PAIRED
*/
export type GetComparePairedInput = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntry
		| ComparePairedInputMultipleEntriesPerSubject
		| ComparePairedInputUsingSeries
	);
export type GetComparePairedRequest = NetworkRequestBase & GetComparePairedInput;
export type GetComparePairedResponse = GenericApiResponse<{
	delta_stats: ComparePairedStatisticsData;
	sample_stats: {
		variableName: string;
		categoryValue?: string;
		stats: ComparePairedStatisticsData;
	}[];
}>;
export type GetComparePairedOutput = ComparePairedResults;

export type GetComparePairedInputV2 = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntryV2
		| ComparePairedInputMultipleEntriesPerSubjectV2
		| ComparePairedInputUsingSeriesV2
	);
export type GetComparePairedRequestV2 = NetworkRequestBase & GetComparePairedInputV2;
export type GetComparePairedResponseV2 = GenericApiResponse<{
	result: ComparePairedGroupedResponseV2;
}>;
export type GetComparePairedOutputV2 = ComparePairedResultsV2;

/*
	GET LOGISTIC REGRESSION
*/
export type GetLogisticRegressionInput = AnalysisBaseInput & {
	dependentVariable: string;
	positiveEvent: string[];
	independentVariable: string;
	groupVariable?: string;
};
export type GetLogisticRegressionRequest = NetworkRequestBase & GetLogisticRegressionInput;
export type GetLogisticRegressionResponse = GenericApiResponse<LogisticRegressionResults>;
export type GetLogisticRegressionOutput =
	| LogisticRegressionDataResults
	| GroupedLogisticRegressionResult[];

export type GetLogisticRegressionInputV2 = AnalysisBaseInput & {
	yVariable: AnalysisVariable;
	outcomes: string[];
	xVariable: AnalysisVariable;
	groupVariables?: AnalysisVariable[];
};
export type GetLogisticRegressionRequestV2 = NetworkRequestBase & GetLogisticRegressionInputV2;
export type GetLogisticRegressionResponseV2 = GenericApiResponse<LogisticRegressionResultsV2>;
export type GetLogisticRegressionOutputV2 = AnalysisOutputData<LogisticRegressionResultsDataV2>;

export type LogisticRegressionResultsDataV2 =
	| LogisticRegressionDataResultsV2
	| GroupedLogisticRegressionResultV2[];

/*	
	GET NUMBER PLOT XY
*/
export type GetNumberPlotXYInput = AnalysisBaseInput & {
	x_value: string;
	y_value: string[];
	grouping_variable?: string;
};
export type GetNumberPlotXYRequest = NetworkRequestBase & GetNumberPlotXYInput;
export type GetNumberPlotXYResponse = GenericApiResponse<{
	PlotResult: {
		x_value: { [key: string]: string[] | number[] };
		y_value: [{ [key: string]: string[] | number[] }];
	};
}>;
export type GetNumberPlotXYOutput = NumberPlotXYResults;

/*
	GET PAIRED T-TEST STATISTICS
*/
export type GetPairedTTestStatisticsInput = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntry
		| ComparePairedInputMultipleEntriesPerSubject
		| ComparePairedInputUsingSeries
	);
export type GetPairedTTestStatisticsRequest = NetworkRequestBase & GetPairedTTestStatisticsInput;
export type GetPairedTTestStatisticsResponse = GenericApiResponse<{
	paired_t_test: {
		pairedTTest: {
			pValue: number;
			tStat: number;
			df: number;
		};
	};
}>;
export type GetPairedTTestStatisticsOutput = PairedTTestResults;

export type GetPairedTTestStatisticsInputV2 = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntryV2
		| ComparePairedInputMultipleEntriesPerSubjectV2
		| ComparePairedInputUsingSeriesV2
	);
export type GetPairedTTestStatisticsRequestV2 = NetworkRequestBase &
	GetPairedTTestStatisticsInputV2;
export type GetPairedTTestStatisticsResponseV2 = GenericApiResponse<{
	result: {
		pValue: number;
		statistic: number;
		df: number;
	};
}>;

export type GetPairedTTestStatisticsOutputV2 = PairedTTestResultsV2;

/*
	GET PAIRED WILCOXON STATISTICS
*/
export type GetPairedWilcoxonStatisticsInput = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntry
		| ComparePairedInputMultipleEntriesPerSubject
		| ComparePairedInputUsingSeries
	);
export type GetPairedWilcoxonStatisticsRequest = NetworkRequestBase &
	GetPairedWilcoxonStatisticsInput;
export type GetPairedWilcoxonStatisticsResponse = GenericApiResponse<{
	paired_wilcoxon_test: {
		pairedWilcoxon: {
			pValue: number;
			tStat: number;
		};
	};
}>;
export type GetPairedWilcoxonStatisticsOutput = PairedWilcoxonResults;

export type GetPairedWilcoxonStatisticsInputV2 = AnalysisBaseInput &
	(
		| ComparePairedInputOneSubjectPerEntryV2
		| ComparePairedInputMultipleEntriesPerSubjectV2
		| ComparePairedInputUsingSeriesV2
	);
export type GetPairedWilcoxonStatisticsRequestV2 = NetworkRequestBase &
	GetPairedWilcoxonStatisticsInputV2;
export type GetPairedWilcoxonStatisticsResponseV2 = GenericApiResponse<{
	result: {
		pValue: number;
		statistic: number;
	};
}>;
export type GetPairedWilcoxonStatisticsOutputV2 = PairedWilcoxonResultsV2;

/*
	GET KRUSKAL STATISTICS
*/
export type GetKruskalStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetKruskalStatisticsRequestV1 = NetworkRequestBase & GetKruskalStatisticsInputV1;
export type GetKruskalStatisticsResponseV1 = GenericApiResponse<{
	kruskalResults: {
		df: number;
		stat: number;
		validTest: string;
		pValue: number;
	};
}>;
export type GetKruskalStatisticsOutputV1 = KruskalResultsV1;

export type GetKruskalStatisticsInputV2 = AnalysisBaseInput & {
	groupVariable: AnalysisVariable;
	numericVariable: AnalysisVariable;
};
export type GetKruskalStatisticsRequestV2 = NetworkRequestBase & GetKruskalStatisticsInputV2;
export type GetKruskalStatisticsResponseV2 = GenericApiResponse<{
	result: {
		df: number;
		statistic: number;
		pValue: number;
		warnings?: AnalysisError[];
	};
}>;
export type GetKruskalStatisticsOutputV2 = KruskalResultsV2;

/*
	GET MC NEMAR STATISTICS
*/
export type GetMcNemarStatisticsInput = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetMcNemarStatisticsInputV2 = AnalysisBaseInput & {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
};
export type GetMcNemarStatisticsRequestV2 = NetworkRequestBase & GetMcNemarStatisticsInputV2;
export type GetMcNemarStatisticsRequest = NetworkRequestBase & GetMcNemarStatisticsInput;
export type GetMcNemarStatisticsResponse = GenericApiResponse<{
	mcnemarResults: {
		stat: number;
		pValue: number;
		exact: string;
	};
}>;
export type GetMcNemarStatisticsResponseV2 = GenericApiResponse<{
	result: {
		statistic: number | null;
		pValue: number | null;
		exact: boolean;
		errors?: AnalysisError[];
	};
}>;
export type GetMcNemarStatisticsOutputV2 = McNemarResultsV2;
export type GetMcNemarStatisticsOutput = McNemarResults;

/*
    GET ONE WAY ANOVA STATISTICS
*/
export type GetOneWayAnovaStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetOneWayAnovaStatisticsRequestV1 = NetworkRequestBase &
	GetOneWayAnovaStatisticsInputV1;
export type GetOneWayAnovaStatisticsResponseV1 = GenericApiResponse<{
	anovaResults: GenericMap<TwoWayAnovaValue> & {
		intercept: TwoWayAnovaValue;
		testType: string;
		condition_number: number;
		error: {
			df: number;
			sumSq: number;
			meanSq: number;
		};
		total: {
			df: number;
			sumSq: number;
		};
	};
}>;
export type GetOneWayAnovaStatisticsOutputV1 = OneWayAnovaResultsV1;

export type GetOneWayAnovaStatisticsInputV2 = AnalysisBaseInput & {
	groupVariables: AnalysisVariable[];
	numericVariable: AnalysisVariable;
};
export type GetOneWayAnovaStatisticsRequestV2 = NetworkRequestBase &
	GetOneWayAnovaStatisticsInputV2;
export type GetOneWayAnovaStatisticsResponseV2 = GenericApiResponse<{
	result: [ApiOneWayAnovaResults, ApiOneWayAnovaResults];
}>;
export type GetOneWayAnovaStatisticsOutputV2 = OneWayAnovaResultsV2;

/*
		GET ONE WAY MANOVA STATISTICS
*/
export type GetOneWayManovaStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string[];
};
export type GetOneWayManovaStatisticsRequestV1 = NetworkRequestBase &
	GetOneWayManovaStatisticsInputV1;
export type GetOneWayManovaStatisticsResponseV1 = GenericApiResponse<{
	manovaResults: ApiTwoWayManovaResultsV1;
}>;
export type GetOneWayManovaStatisticsOutputV1 = OneWayManovaResultsV1;

export type GetOneWayManovaStatisticsInputV2 = AnalysisBaseInput & {
	groupVariables: AnalysisVariable[];
	numericVariables: AnalysisVariable[];
};
export type GetOneWayManovaStatisticsRequestV2 = NetworkRequestBase &
	GetOneWayManovaStatisticsInputV2;
export type GetOneWayManovaStatisticsResponseV2 = GenericApiResponse<{
	result: ApiTwoWayManovaResultV2[];
}>;
export type GetOneWayManovaStatisticsOutputV2 = OneWayManovaResultsV2;

/*
    GET TWO WAY MANOVA STATISTICS
*/
export type GetTwoWayManovaStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string[];
	exploreVariable: string[];
};
export type GetTwoWayManovaStatisticsRequestV1 = NetworkRequestBase &
	GetTwoWayManovaStatisticsInputV1;
export type GetTwoWayManovaStatisticsResponseV1 = GenericApiResponse<{
	manovaResults: ApiTwoWayManovaResultsV1;
}>;
export type GetTwoWayManovaStatisticsOutputV1 = TwoWayManovaResultsV1;

export type GetTwoWayManovaStatisticsInputV2 = AnalysisBaseInput & {
	groupVariables: AnalysisVariable[];
	numericVariables: AnalysisVariable[];
};
export type GetTwoWayManovaStatisticsRequestV2 = NetworkRequestBase &
	GetTwoWayManovaStatisticsInputV2;
export type GetTwoWayManovaStatisticsResponseV2 = GenericApiResponse<{
	result: ApiTwoWayManovaResultV2[];
}>;
export type GetTwoWayManovaStatisticsOutputV2 = TwoWayManovaResultsV2;

/*
    GET TWO WAY ANOVA STATISTICS
*/
export type GetTwoWayAnovaStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string[];
	exploreVariable: string;
};
export type GetTwoWayAnovaStatisticsRequestV1 = NetworkRequestBase &
	GetTwoWayAnovaStatisticsInputV1;
export type GetTwoWayAnovaStatisticsResponseV1 = GenericApiResponse<{
	anovaResults: ApiTwoWayAnovaResultsV1;
}>;
export type GetTwoWayAnovaStatisticsOutputV1 = TwoWayAnovaResultsV1;

export type GetTwoWayAnovaStatisticsInputV2 = AnalysisBaseInput & {
	groupVariables: AnalysisVariable[];
	numericVariable: AnalysisVariable;
};
export type GetTwoWayAnovaStatisticsRequestV2 = NetworkRequestBase &
	GetTwoWayAnovaStatisticsInputV2;
export type GetTwoWayAnovaStatisticsResponseV2 = GenericApiResponse<{
	result: ApiTwoWayAnovaResultsV2[];
}>;
export type GetTwoWayAnovaStatisticsOutputV2 = TwoWayAnovaResultsV2;

/*
    GET FISHER STATISTICS
*/
export type GetFisherStatisticsInput = AnalysisBaseInput & {
	rowVariable: string;
	columnVariable: string;
};
export type GetFisherStatisticsInputV2 = AnalysisBaseInput & {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
};
export type GetFisherStatisticsRequestV2 = NetworkRequestBase & GetFisherStatisticsInputV2;
export type GetFisherStatisticsRequest = NetworkRequestBase & GetFisherStatisticsInput;
export type GetFisherStatisticsResponse = GenericApiResponse<{
	fisherResults: {
		oddsRatio: number;
		pValue: number;
	};
}>;

export type GetFisherStatisticsResponseV2 = GenericApiResponse<{
	result: {
		statistic: number;
		pValue: number;
		errors?: AnalysisError[];
	};
}>;
export type GetFisherStatisticsOutput = FisherResults;
export type GetFisherStatisticsOutputV2 = FisherResultsV2;

/*
    GET CHI STATISTICS
*/
export type GetChiSquareStatisticsInput = AnalysisBaseInput & {
	rowVariable: string;
	columnVariable: string;
};

export type GetChiSquareStatisticsInputV2 = AnalysisBaseInput & {
	rowVariable: AnalysisVariable;
	columnVariable: AnalysisVariable;
};
export type GetChiSquareStatisticsRequest = NetworkRequestBase & GetChiSquareStatisticsInput;
export type GetChiSquareStatisticsRequestV2 = NetworkRequestBase & GetChiSquareStatisticsInputV2;
export type GetChiSquareStatisticsResponse = GenericApiResponse<{
	chiSquareResults: ChiSquareResultsData;
	errorMessage?: string;
}>;

export type GetChiSquareStatisticsResponseV2 = GenericApiResponse<{
	result: {
		statistic: number | null;
		pValue: number | null;
		df?: number;
		errors?: AnalysisError[];
	};
}>;
export type GetChiSquareStatisticsOutput = ChiSquareResults;
export type GetChiSquareStatisticsOutputV2 = ChiSquareResultsV2;

/*
    GET MANN WHITNEY STATISTICS
*/
export type GetMannWhitneyStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetMannWhitneyStatisticsRequestV1 = NetworkRequestBase &
	GetMannWhitneyStatisticsInputV1;
export type GetMannWhitneyStatisticsResponseV1 = GenericApiResponse<{
	uTestResults: {
		pValue: number;
		wStat: number;
		exact: string;
	};
}>;
export type GetMannWhitneyStatisticsOutputV1 = MannWhitneyResultsV1;

export type GetMannWhitneyStatisticsInputV2 = AnalysisBaseInput & {
	groupVariable: AnalysisVariable;
	numericVariable: AnalysisVariable;
};
export type GetMannWhitneyStatisticsRequestV2 = NetworkRequestBase &
	GetMannWhitneyStatisticsInputV2;
export type GetMannWhitneyStatisticsResponseV2 = GenericApiResponse<{
	result: {
		pValue: number;
		statistic: number;
		error?: AnalysisError;
	};
}>;
export type GetMannWhitneyStatisticsOutputV2 = MannWhitneyResultsV2;

/*
    GET INDEPENDENT STATISTICS
*/
export type GetIndependentStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetIndependentStatisticsRequestV1 = NetworkRequestBase &
	GetIndependentStatisticsInputV1;
export type GetIndependentStatisticsResponseV1 = GenericApiResponse<{
	independetTTest: {
		degrees_of_freedom: number;
		pValue: number;
		tStat: number;
	};
}>;
export type GetIndependentStatisticsOutputV1 = IndependentResultsV1;

export type GetIndependentStatisticsInputV2 = AnalysisBaseInput & {
	groupVariable: AnalysisVariable;
	numericVariable: AnalysisVariable;
};
export type GetIndependentStatisticsRequestV2 = NetworkRequestBase &
	GetIndependentStatisticsInputV2;
export type GetIndependentStatisticsResponseV2 = GenericApiResponse<{
	result: {
		// degrees_of_freedom: number;
		// pValue: number;
		// tStat: number;
		statistic: number;
		pValue: number;
		df: number;
		error?: AnalysisError;
	};
}>;
export type GetIndependentStatisticsOutputV2 = IndependentResultsV2;

/*
    GET PEARSON STATISTICS
*/
export type GetPearsonStatisticsInput = AnalysisBaseInput & GetCorrelationsInput;
export type GetPearsonStatisticsRequest = NetworkRequestBase & GetPearsonStatisticsInput;
export type GetPearsonStatisticsResponse = GenericApiResponse<PearsonStatisticsResponse>;
export type GetPearsonStatisticsOutput = CorrelationsStatisticsResults;

export type GetPearsonStatisticsV1Input = AnalysisBaseInput & GetCorrelationsInputV1;
export type GetPearsonStatisticsV1Request = NetworkRequestBase & GetPearsonStatisticsV1Input;
export type GetPearsonStatisticsV1Response = GenericApiResponse<PearsonStatisticsGroupV1[]>;
export type GetPearsonStatisticsV1Output = PearsonResults;

/*
    GET SPEARMAN STATISTICS
*/
export type GetSpearmanStatisticsInput = AnalysisBaseInput & GetCorrelationsInput;
export type GetSpearmanStatisticsRequest = NetworkRequestBase & GetSpearmanStatisticsInput;
export type GetSpearmanStatisticsResponse = GenericApiResponse<SpearmanStatisticsResponse>;
export type GetSpearmanStatisticsOutput = CorrelationsStatisticsResults;

export type GetSpearmanStatisticsV1Input = AnalysisBaseInput & GetCorrelationsInputV1;
export type GetSpearmanStatisticsV1Request = NetworkRequestBase & GetSpearmanStatisticsV1Input;
export type GetSpearmanStatisticsV1Response = GenericApiResponse<SpearmanStatisticsGroupV1[]>;
export type GetSpearmanStatisticsV1Output = SpearmanResults;

/*
    GET LINEAR REGRESSION STATISTICS
*/
export type GetLinearRegressionStatisticsInput = AnalysisBaseInput & GetCorrelationsInput;
export type GetLinearRegressionStatisticsRequest = NetworkRequestBase &
	GetLinearRegressionStatisticsInput;
export type GetLinearRegressionStatisticsResponse = GenericApiResponse<LinearRegressionResponse>;
export type GetLinearRegressionStatisticsOutput = LinearRegressionResults;

export type GetLinearRegressionStatisticsV1Input = AnalysisBaseInput & GetCorrelationsInputV1;
export type GetLinearRegressionStatisticsV1Request = NetworkRequestBase &
	GetLinearRegressionStatisticsV1Input;
export type GetLinearRegressionStatisticsV1Response = GenericApiResponse<
	LinearRegressionStatisticsGroupV1[]
>;
export type GetLinearRegressionStatisticsV1Output = LinearRegressionResultsV1;

/*
    GET SHAPIRO STATISTICS
*/
export type GetShapiroStatisticsInputV1 = AnalysisBaseInput & GetCompareNumericInputV1;
export type GetShapiroStatisticsRequestV1 = NetworkRequestBase & GetShapiroStatisticsInputV1;
export type GetShapiroStatisticsResponseV1 = GenericApiResponse<ApiShapiroResponseV1>;
export type GetShapiroStatisticsOutputV1 = ShapiroResultsV1;

export type GetShapiroStatisticsInputV2 = AnalysisBaseInput & GetCompareNumericInputV2;
export type GetShapiroStatisticsRequestV2 = NetworkRequestBase & GetShapiroStatisticsInputV2;
export type GetShapiroStatisticsResponseV2 = GenericApiResponse<{
	result: ApiShapiroResponseV2[];
}>;
export type GetShapiroStatisticsOutputV2 = ShapiroResultsV2;

export type GetLogRankStatisticsInput = AnalysisBaseInput & GetLogRankStatistics;
export type GetLogRankStatisticsRequest = NetworkRequestBase & GetLogRankStatisticsInput;
export type GetLogRankStatisticsResponse = GenericApiResponse<{
	logRank: {
		pValue: number;
		statistic: number;
	};
}>;
export type GetLogRankStatisticsOutput = LogRankResults;

export type GetLogRankStatisticsInputV2 = AnalysisBaseInput & GetLogRankStatisticsV2;
export type GetLogRankStatisticsRequestV2 = NetworkRequestBase & GetLogRankStatisticsInputV2;
export type GetLogRankStatisticsResponseV2 = GenericApiResponse<{
	result: {
		pValue: number;
		statistic: number;
	};
}>;
export type GetLogRankStatisticsOutputV2 = LogRankResultsV2;

/*
    GET TUKEY STATISTICS
*/
export type GetTukeyStatisticsInputV1 = AnalysisBaseInput & {
	categoryVariable: string;
	exploreVariable: string;
};
export type GetTukeyStatisticsRequestV1 = NetworkRequestBase & GetTukeyStatisticsInputV1;
export type GetTukeyStatisticsResponseV1 = GenericApiResponse<{
	result: TukeyResultsDataV1[];
}>;
export type GetTukeyStatisticsOutputV1 = TukeyResultsV1;

export type GetTukeyStatisticsInputV2 = AnalysisBaseInput & {
	groupVariable: AnalysisVariable;
	numericVariable: AnalysisVariable;
};
export type GetTukeyStatisticsRequestV2 = NetworkRequestBase & GetTukeyStatisticsInputV2;
export type GetTukeyStatisticsResponseV2 = GenericApiResponse<{
	result: TukeyResultsDataV2[];
}>;
export type GetTukeyStatisticsOutputV2 = TukeyResultsV2;

export enum AnalysisErrorCodes {
	NoData = 'error.noData',
	GroupLimitExceeded = 'error.groupLimitExceeded',
	ComputationError = 'error.computationError',
	IncompatibleNumberOfGroups = 'error.incompatibleNumberOfGroups'
}

export interface AnalysisOutputData<T> {
	data: T | null;
	error?: AnalysisError;
}
export interface DisplayError {
	header?: string | null;
	body: string | null;
}
