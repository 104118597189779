import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {
	sendRequest,
	DATA_URL,
	EXCEL_TO_CSV_URL,
	PROJECTS_URL,
	STATISTICS_URL,
	UPLOAD_FILE,
	contentfulClient,
	contentfulOptions
} from 'api/utils';
import { buildErrorMessage } from 'api/utils/helpers';
import { Dictionary } from 'environment';
import {
	LicenceLimitationErrorTypes,
	toggleFreeLicenceLimitationModalEvent
} from 'helpers/licences';
import {
	CreateProject,
	Project,
	DPAFiles,
	PreviewDPAFile,
	ProjectMetadataDefinition
} from 'store/data/projects';
import { parseApiProject, parseApiProjects } from 'store/data/projects/parsers';
import { LanguageType, LedidiStatusCode, StringMap } from 'types/index';
import { RequestTypes } from 'api/utils';

import {
	ConvertXlsToCsvInput,
	CreateProjectResponse,
	GetProjectResponse,
	GetProjectsResponse,
	GetStatsResponse,
	ProjectCount,
	UploadProjectDatasetInput,
	GetPromsResponse,
	ImportDataTextInterface,
	ImportDataTextEntry,
	UpdateExistingDatasetInput,
	GetLeaveProjectResponse,
	GenerateVariableNamesInput,
	GenerateVariableNamesResponse,
	CheckTransactionProgressResponse,
	ConvertXlsToCsvAsyncResponse,
	ConvertXlsToCsvAsyncData,
	SuggestVariableTypeInput,
	ImportVariableTypesAsyncResponse,
	DraggedFile,
	GetDPAInput,
	GetDPARequest,
	GetDPAResponse,
	SignDPAInput,
	SignDPARequest,
	PreviewDPAInput,
	PreviewDPARequest,
	PreviewDPAResponse,
	//
	GetProjectsCollaboratorsAvatarDataRequest,
	GetProjectsCollaboratorsAvatarDataResponse,
	GetProjectsCollaboratorsAvatarDataOutput,
	//
	UpdateProjectInput,
	UpdateProjectRequest,
	UpdateProjectResponse,
	//
	DeleteProjectInput,
	DeleteProjectRequest,
	DeleteProjectResponse,
	//
	ConvertProjectInput,
	ConvertProjectRequest,
	ConvertProjectResponse,
	ProjectsCollaboratorsAvatarData,
	CopyProjectOutput,
	CopyProjectInput,
	CopyProjectRequest,
	CopyProjectResponse,
	//
	UploadUrlInput,
	UploadUrlOutput,
	SuggestedVariableTypesResponse,
	ImportAddMoreEntriesAsyncResponse,
	TransferProjectsOwnershipRequest,
	GetProjectMetadataDefinitionResponse
} from './types';
import { ProjectType } from 'types/data/projects/constants';

export const methods = {
	getProjects: 'getListOfAllProjects',
	getProms: 'getListOfAllPromProjects',
	getProject: 'getProject',
	getCounts: 'getObservationCountsForAllProjects',
	createProject: 'addProject',
	updateProject: 'updateProject',
	deleteProject: 'deleteProject',
	leaveProject: 'leaveProject',
	copyProject: 'copyProject',
	// PROJECT DPA
	getDPA: 'getDPA',
	signDPA: 'signDPA',
	previewDPA: 'previewDPA',
	/////////////////////////////
	convertXlsToCsv: 'xlsxtocsv',
	convertXlsToCsvAsync: 'xlsxToCSVHeader',
	createDataset: 'createNewDatasetTypeFromCSVString',
	createDatasetAsync: 'start_import/full/',
	importToExistingDataset: 'start_import/add_more_entries/',
	updateExistingDataset: 'start_import/add_more_columns/',
	generateVariableNames: 'generateVariableNames',
	checkImportProgress: 'trackTransactionProgress',
	suggestVariableTypes: 'suggest_variable_types/',
	getProjectsCollaboratorsAvatarData: 'getCollaboratorsForProjectList',
	convertProject: 'convertProject',
	getPromsCollaboratorsAvatarData: 'getCollaboratorsForProjectListProm',
	uploadUrl: 'upload_url/',

	// transfer project
	transferProjectsOwnership: 'transferProjectsOwnership', // only for Enterprise users

	// project metadata
	getProjectMetadataDefinition: 'getProjectMetadataDefinition'
};

export default () => ({
	async getProjects(): Promise<Project[]> {
		const { data }: GetProjectsResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProjects,
			retrieveProjectOwnerDetails: true
		});

		if (data.statusCode) {
			throw new Error(Dictionary.errors.api.projects.couldNotLoadProjects);
		}

		// TODO: remove when BE returns `projectType`
		data.projects.forEach(project => {
			project.projectType = ProjectType.CORE;
		});

		return parseApiProjects(data.projects);
	},

	async getProms(): Promise<Project[]> {
		const { data }: GetPromsResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProms,
			retrieveProjectOwnerDetails: true
		});

		if (data.statusCode) {
			throw new Error(Dictionary.errors.api.projects.couldNotLoadProjects);
		}

		// TODO: remove when BE returns `projectType`
		data.projects.forEach(project => {
			project.projectType = ProjectType.PROM;
		});

		return parseApiProjects(data.projects);
	},

	async getProject(projectId: string, projectType: ProjectType): Promise<Project> {
		try {
			const { data }: GetProjectResponse = await sendRequest(PROJECTS_URL, {
				method: methods.getProject,
				retrieveProjectOwnerDetails: true,
				project: {
					projectId
				}
			});

			return parseApiProject(data.project);
		} catch (e) {
			const isProm = projectType === ProjectType.PROM;

			throw new Error(
				isProm
					? Dictionary.errors.api.proms.couldNotLoadProm
					: Dictionary.errors.api.projects.couldNotLoadProject
			);
		}
	},

	async getCounts(): Promise<ProjectCount[]> {
		try {
			const { data }: GetStatsResponse = await sendRequest(STATISTICS_URL, {
				method: methods.getCounts
			});

			return data;
		} catch (e) {
			throw new Error(Dictionary.errors.api.projects.couldNotLoadEntryCounts);
		}
	},

	async createProject(project: CreateProject): Promise<Project> {
		const { data }: CreateProjectResponse = await sendRequest(PROJECTS_URL, {
			method: methods.createProject,
			project
		});

		if (!data.project) {
			if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
				toggleFreeLicenceLimitationModalEvent().dispatch(
					LicenceLimitationErrorTypes.createProject
				);
				throw new Error();
			}

			throw new Error(Dictionary.errors.api.projects.couldNotCreateProject);
		}

		return parseApiProject(data.project);
	},

	async updateProject(input: UpdateProjectInput): Promise<void> {
		const { data } = await sendRequest<UpdateProjectRequest, UpdateProjectResponse>(
			PROJECTS_URL,
			{
				method: methods.updateProject,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	async deleteProject(input: DeleteProjectInput): Promise<void> {
		const { data } = await sendRequest<DeleteProjectRequest, DeleteProjectResponse>(
			PROJECTS_URL,
			{
				method: methods.deleteProject,
				...input
			}
		);

		if (data.httpStatusCode !== 200) throw new Error();
	},

	async leaveProject(projectId: string): Promise<any> {
		const { data }: GetLeaveProjectResponse = await sendRequest(PROJECTS_URL, {
			method: methods.leaveProject,
			project: { projectId }
		});

		if (!data) {
			throw new Error(Dictionary.errors.api.projects.couldNotLeaveProject);
		}

		return data;
	},

	async copyProject(input: CopyProjectInput): Promise<CopyProjectOutput> {
		const { data } = await sendRequest<CopyProjectRequest, CopyProjectResponse>(DATA_URL, {
			method: methods.copyProject,
			...input
		});

		if (data.httpStatusCode !== 200) {
			if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
				toggleFreeLicenceLimitationModalEvent().dispatch(
					input.newOwnerId
						? LicenceLimitationErrorTypes.transferProject
						: LicenceLimitationErrorTypes.copyProject
				);
				throw new Error();
			}

			throw new Error(Dictionary.errors.api.projects.couldNotCopyProject);
		}

		return data;
	},

	/**
	 * PROJECT DPA
	 */

	async getDPA(input: GetDPAInput): Promise<DPAFiles> {
		const { data }: GetDPAResponse = await sendRequest<GetDPARequest>(PROJECTS_URL, {
			method: methods.getDPA,
			...input
		});

		return data.dpaFiles;
	},

	async signDPA(input: SignDPAInput): Promise<void> {
		await sendRequest<SignDPARequest>(PROJECTS_URL, {
			method: methods.signDPA,
			...input
		});
	},

	async previewDPA(input: PreviewDPAInput): Promise<PreviewDPAFile> {
		const { data }: PreviewDPAResponse = await sendRequest<PreviewDPARequest>(PROJECTS_URL, {
			method: methods.previewDPA,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();

		const { signedS3URL } = data.dpaFiles[0].fileMetadata;

		return { signedS3URL };
	},

	//////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////

	async convertXlsToCsvAsync(
		input: ConvertXlsToCsvInput
	): Promise<ConvertXlsToCsvAsyncData | undefined> {
		try {
			const { data }: ConvertXlsToCsvAsyncResponse = await sendRequest(
				EXCEL_TO_CSV_URL,
				{
					method: methods.convertXlsToCsvAsync,
					...input.body
				},
				{ ...input.config, timeout: 29000 }
			);

			if (data && data.csvHeaderContent) {
				return data;
			}

			throw new Error(Dictionary.errors.api.projects.couldNotConvert);
		} catch (e: any) {
			if (axios.isCancel(e)) {
				console.log(Dictionary.errors.api.projects.requestCanceled, e.message);
			} else {
				throw new Error(e.message);
			}
		}
	},

	async convertXlsToCsv(input: ConvertXlsToCsvInput): Promise<string | undefined> {
		try {
			const { data } = await sendRequest(
				EXCEL_TO_CSV_URL,
				{
					method: methods.convertXlsToCsv,
					...input.body
				},
				input.config
			);

			if (data && data.csvContent) {
				return data.csvContent;
			}
		} catch (e: any) {
			if (axios.isCancel(e)) {
				console.log(Dictionary.errors.api.projects.requestCanceled, e.message);
			} else {
				throw new Error(e.message);
			}
		}
	},

	async uploadUrl(input: UploadUrlInput): Promise<{
		fileId: string;
		url: string;
	}> {
		try {
			const { data }: UploadUrlOutput = await sendRequest(UPLOAD_FILE + methods.uploadUrl, {
				...input
			});

			return data;
		} catch (e) {
			throw new Error(Dictionary.errors.api.projects.couldNotUploadFile);
		}
	},

	async uploadFile(url: string, file: DraggedFile) {
		try {
			await sendRequest(
				url,
				file,
				{
					withCredentials: false,
					headers: {
						'Content-Type': 'application/json'
					}
				},
				RequestTypes.PUT
			);

			return true;
		} catch (e) {
			return false;
		}
	},

	//  [x] [ ] [ ] - DataToEntries
	async updateExistingDataset(input: UpdateExistingDatasetInput) {
		const { data }: ImportVariableTypesAsyncResponse = await sendRequest(
			UPLOAD_FILE + methods.updateExistingDataset,
			{
				...input
			},
			{ timeout: 900000 } // This is a very heavy request that might take very long. More details on PRJCTS-2647
		);

		if (data && data.httpStatusCode !== 200) {
			buildErrorMessage(data);
		}

		return data.transactionId;
	},

	// [ ] [x] [] - EntriesToDataset
	async uploadToExistingDataset(input: UploadProjectDatasetInput) {
		const { data }: ImportAddMoreEntriesAsyncResponse = await sendRequest(
			UPLOAD_FILE + methods.importToExistingDataset,
			{
				...input
			},
			{ timeout: 900000 } // This is a very heavy request that might take very long. More details on PRJCTS-2647
		);

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.createEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorCreateEntry
			);
			throw new Error();
		}

		if (data && data.httpStatusCode !== 200) {
			buildErrorMessage(data);
		}

		return data.transactionIds;
	},

	// [ ] [ ] [x] - ReplaceAll
	async uploadProjectDataset(input: UploadProjectDatasetInput) {
		const { data }: ImportVariableTypesAsyncResponse = await sendRequest(
			UPLOAD_FILE + methods.createDatasetAsync,
			{
				...input
			},
			{ timeout: 29000 } // This is a very heavy request that might take very long. More details on PRJCTS-2647
		);

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicence) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.createEntry
			);
			throw new Error();
		}

		if (data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther) {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorCreateEntry
			);
			throw new Error();
		}

		if (data && data.httpStatusCode !== 200) {
			buildErrorMessage(data);
		}

		return data.transactionId;
	},

	async getImportDataText(locale: LanguageType): Promise<ImportDataTextInterface> {
		const entries = await contentfulClient.getEntries<ImportDataTextEntry>({
			content_type: 'import',
			locale: locale.toLowerCase()
		});
		const importEntries = entries.items ?? [];
		const firstEntry = importEntries[0]?.fields ?? undefined;

		if (!firstEntry) return { name: '', content: null };

		return {
			name: firstEntry.name,
			content: documentToReactComponents(firstEntry.content, contentfulOptions)
		};
	},

	async checkTransactionProgress(
		projectId: number,
		transactionId: number,
		propagateErrors?: boolean
	) {
		const { data }: CheckTransactionProgressResponse = await sendRequest(DATA_URL, {
			method: methods.checkImportProgress,
			projectId,
			transactionId
		});

		const { response } = data;

		if (response && response.errors && response.errors.length && !propagateErrors) {
			throw new Error(Dictionary.errors.api.projects.couldNotCompleteImport);
		}

		return data;
	},

	async generateVariableNames({
		variableLabels,
		variableNames,
		projectId
	}: GenerateVariableNamesInput): Promise<StringMap | undefined> {
		const { data }: GenerateVariableNamesResponse = await sendRequest(DATA_URL, {
			method: methods.generateVariableNames,
			variableLabels,
			existingNames: variableNames,
			projectId
		});

		if (data && data.httpStatusCode === 200) {
			return data.generated;
		}
	},

	async suggestVariableTypesUrl({
		fileId,
		projectId,
		timeZoneLess
	}: SuggestVariableTypeInput): Promise<number | undefined> {
		const { data }: ImportVariableTypesAsyncResponse = await sendRequest(
			UPLOAD_FILE + methods.suggestVariableTypes,
			{
				projectId,
				fileId,
				timeZoneLess
			}
		);
		console.log('suggesteVariableTypesUrl call: response: ', { data });

		if (data.httpStatusCode === 200) {
			return data.transactionId;
		}
	},

	async getSuggestedVariableTypes(
		url: string
	): Promise<SuggestedVariableTypesResponse | undefined> {
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			const { json } = response;
			console.log('getSuggestedVariableTypes call: response: ', { json });
			return json.bind(response)();
		} catch (e) {
			throw new Error(Dictionary.errors.api.projects.couldNotUploadFile);
		}
	},

	async getProjectsCollaboratorsAvatarData({
		fetchForProms
	}: ProjectsCollaboratorsAvatarData): Promise<GetProjectsCollaboratorsAvatarDataOutput> {
		const { data } = await sendRequest<
			GetProjectsCollaboratorsAvatarDataRequest,
			GetProjectsCollaboratorsAvatarDataResponse
		>(PROJECTS_URL, {
			method: !fetchForProms
				? methods.getProjectsCollaboratorsAvatarData
				: methods.getPromsCollaboratorsAvatarData
		});

		// TODO: UNCOMMENT WHEN RESPONSE CHANGES
		// if (data.httpStatusCode !== 200) throw new Error();

		// TODO: UNCOMMENT WHEN RESPONSE CHANGES
		// if (data.httpStatusCode !== 200) throw new Error();

		if (!data.projectUserTree) throw new Error();

		const output: GetProjectsCollaboratorsAvatarDataOutput = {
			data: {
				byProjectId: {}
			}
		};

		/**
		 * Parse result to a more meaningful structure
		 */
		Object.keys(data.projectUserTree).forEach(projectId => {
			output.data.byProjectId[projectId] = {
				byUserId: data.projectUserTree[projectId]
			};
		});

		return output;
	},

	convertProject: async (input: ConvertProjectInput): Promise<void> => {
		const { data } = await sendRequest<ConvertProjectRequest, ConvertProjectResponse>(
			PROJECTS_URL,
			{
				method: methods.convertProject,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	// Only for enterprise users
	transferProjectsOwnership: async (userId: string, projectIds: string[]): Promise<void> => {
		try {
			const { data } = await sendRequest<TransferProjectsOwnershipRequest>(PROJECTS_URL, {
				method: methods.transferProjectsOwnership,
				userId,
				projectIds
			});
			if (data.statusCode !== '200') throw new Error();
		} catch (e) {
			throw new Error(Dictionary.admin.enterprise.errors.api.transferProjectOwnership);
		}
	},

	async getProjectMetadataDefinition(): Promise<ProjectMetadataDefinition> {
		const { data }: GetProjectMetadataDefinitionResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProjectMetadataDefinition
		});

		if (!data.metadataDefinition) return [];

		return data.metadataDefinition;
	}
});
