import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { Columns, FrequenciesAnalysis } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { Svgs } from 'environment';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { TabsWithIcon } from 'components/UI/Tabs';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

import {
	useAnalysesActiveColum,
	useAnalysisActiveTab,
	useAnalysisConfigPanel,
	useFilters,
	useFullscreenAnalysis,
	useTranslation,
	useUpdateAnalysis
} from 'hooks/store';
import { useDebounce, useDeepCompareMemo } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';

interface Props {
	analysis: FrequenciesAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function FrequenciesConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();
	const [fullscreen] = useFullscreenAnalysis();

	const updateAnalysis = useUpdateAnalysis();
	const {
		id,
		input: { variables }
	} = analysis;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);

	const [values, setValues] = useState(variables);

	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [{ areFiltersOpen }] = useFilters();

	const [activeColumn] = useAnalysesActiveColum();
	const { selectItemsMap, categorySelectItems, categoryMultipleSelectItems } =
		variablesDataSelectItems;

	const allCategorySelectItems = useDeepCompareMemo(
		() => [...categorySelectItems, ...categoryMultipleSelectItems],
		[variablesDataSelectItems]
	);

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useDebounce(
		() => {
			if (!isEqual(variables, values)) {
				const updatedAnalysis: FrequenciesAnalysis = {
					...analysis,
					input: { variables: values }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as FrequenciesAnalysis} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6, top: 1.6 }} style={{ width: '100%' }} notLastChild>
					<TabsWithIcon
						labels={[
							translate(({ analysis }) => analysis.analyses.frequencies.view.table),
							translate(({ analysis }) => analysis.analyses.frequencies.view.columns),
							translate(({ analysis }) => analysis.analyses.frequencies.view.pie)
						]}
						icons={[Svgs.ViewTable, Svgs.GroupedView, Svgs.ChartPie]}
						startIndex={activeTab}
						onChangeTabs={active => setActiveTab({ analysisId: id, activeTab: active })}
						maxWidth={36}
					/>
				</Gap>
			</CollapsibleCard>
			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.frequencies.config.categories
						)}
						items={allCategorySelectItems}
						value={selectItemsMap[values.categoryVariable]}
						onValueSelected={categoryVariable =>
							categoryVariable && setValues(state => ({ ...state, categoryVariable }))
						}
						canClear={false}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<AnalysisFormatting hasChartOptions={activeTab !== 0} analysis={analysis} isEnabled />
		</ConfigContainer>
	);
}
