import { TukeyResultsV1, TukeyResultsDataV1 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';

import { OverflowYContainer } from './CompareNumericTukeyV1.style';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { computeCellValue } from 'helpers/analysis';
import { PStatistic } from 'components/Analysis/Analyses/AnalysisStatistics';

interface Props {
	results: TukeyResultsV1;
}

export function CompareNumericTukeyV1({ results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.tukey.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translate(({ errors }) => errors.api.analyses.statistics.tukey)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}

function Result({ data }: { data: TukeyResultsDataV1[] }) {
	const { translate } = useTranslation();

	return (
		<OverflowYContainer scroll={data.length > 15}>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							<Table.Column>
								{translate(
									({ analysis }) =>
										analysis.statistics.tukey.tableColumns.comparison
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) =>
										analysis.statistics.tukey.tableColumns.statistic
								)}
							</Table.Column>
							<Table.Column minWidth={10}>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.pValue
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.ciLower
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.ciUpper
								)}
							</Table.Column>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{data.map((value, index) => (
							<Table.Row key={`table-row-${index}`}>
								<Table.Cell>{value.comparison}</Table.Cell>
								<Table.Cell>{computeCellValue(value.statistic)}</Table.Cell>
								<Table.Cell>
									<PStatistic value={value.pValue} />
								</Table.Cell>
								<Table.Cell>{computeCellValue(value.lowerCI)}</Table.Cell>
								<Table.Cell>{computeCellValue(value.upperCI)}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</OverflowYContainer>
	);
}
