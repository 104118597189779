import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Colors } from 'environment';
import { DPAFile } from 'store/data/projects';
import { Svgs } from 'environment';
import { PdfControls } from 'pages/Documents/Modals/PreviewDocument/PdfControls/PdfControls';
import { NumberedPages } from 'pages/Documents/Modals/PreviewDocument/PdfControls/NumberedPages';
import {
	Container,
	Header,
	ScrollableContainer,
	PDFContainer,
	Title,
	HeaderLeft,
	HeaderRight
} from './ViewProjectDPA.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

// THIS NEEDS TO BE SET IN ORDER FOR PDF WORKER TO FUNCTION
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const escapeKeyMaps = ['Escape', 'Esc', '27', 27];

interface Props {
	DPAFile: DPAFile;
	onClose: () => void;
}

export function ViewProjectDPA({ DPAFile, onClose }: Props) {
	// number of PDF pages as mappable array - array item is the page number
	const [pages, setPages] = useState<number[]>([]);
	const [pageNumberInView, setPageNumberInView] = useState(1);
	const { translate } = useTranslation();

	const defaultScale = 1.3;
	const scaleStep = 0.3;
	const [scale, setScale] = useState(defaultScale);

	// ESC / LEFT / RIGHT KEYS LISTENER
	useEffectOnce(() => {
		document.addEventListener('keyup', handleKeyPress);

		return () => document.removeEventListener('keyup', handleKeyPress);
	});

	function handleKeyPress(e: KeyboardEvent) {
		// ESC KEY
		if (escapeKeyMaps.includes(e.key)) onClose();
	}

	function onDocumentLoad(numPages: number) {
		const state = [...new Array(numPages)].fill(null).map((_, i) => i + 1);

		setPages(state);
	}

	function zoomIn() {
		setScale(state => state + scaleStep);
	}

	function zoomOut() {
		if (scale > defaultScale) setScale(state => state - scaleStep);
	}

	return ReactDOM.createPortal(
		<Container>
			<Header>
				<HeaderLeft>
					{/* MAIN LOGO */}
					<Icon
						svg={Svgs.LedidiLogoGrey}
						customSize={3}
						marginOffset={{ left: -2, right: 4.2 }}
						onClick={onClose}
					/>

					<Title>
						<Icon
							svg={Svgs.File}
							colors={{ color: Colors.text.disabled }}
							size={s => s.m}
							marginOffset={{ right: 0.8 }}
						/>
						<Typography.H6
							title={translate(dict => dict.documentsPage.viewProjectDPA.title)}
							ellipsis
						>
							{translate(dict => dict.documentsPage.viewProjectDPA.title)}
						</Typography.H6>
					</Title>
				</HeaderLeft>

				<HeaderRight>
					<Icon
						svg={Svgs.Print}
						variant={v => v.buttonActive}
						onClick={() => window.open(DPAFile.fileMetadata.signedS3URL)}
						marginOffset={{ right: 1.6 }}
					/>
					<Icon svg={Svgs.Close} variant={v => v.buttonActive} onClick={onClose} />
				</HeaderRight>
			</Header>

			<ScrollableContainer className="scrollContainer">
				<PDFContainer>
					<Document
						file={DPAFile.fileMetadata.signedS3URL}
						loading={<></>} // hide loading message
						onLoadSuccess={info => onDocumentLoad(info.numPages)}
					>
						<NumberedPages
							pages={pages}
							targetClass="scrollContainer"
							setPageNumberInView={setPageNumberInView}
							pageComponent={pageNumber => (
								<Page
									pageNumber={pageNumber}
									scale={scale}
									renderAnnotationLayer={false}
									loading={<></>} // hide loading message
								/>
							)}
						/>
					</Document>
				</PDFContainer>
			</ScrollableContainer>

			<PdfControls
				// Removed until we get to calculate page index
				pageIndex={pageNumberInView}
				totalPages={pages.length}
				defaultScale={defaultScale}
				scale={scale}
				zoomIn={zoomIn}
				zoomOut={zoomOut}
			/>
		</Container>,
		document.body
	);
}
