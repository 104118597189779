import { forwardRef, useRef } from 'react';
import { nanoid as generate } from 'nanoid';
import { Colors } from 'environment';
import { useMemoOnce } from 'hooks/utils';
import { InputType } from 'types/index';

import {
	Wrapper,
	HiddenInput,
	Container,
	Text,
	RadioButton,
	RadioButtonCheck
} from '../../RadioButton/RadioButton.style';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { mergeRefs } from 'helpers/generic';
import { withMemo } from 'helpers/HOCs';

interface Props {
	name: string;
	label: string;
	value: string;
	error?: string;
	tooltip?: string;
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	allowUnselect?: boolean;
	dataTestId?: string;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (value: string) => void;
}

const Component = forwardRef<HTMLInputElement, Props>(
	(
		{
			name,
			label,
			value,
			error,
			tooltip,
			checked,
			className,
			disabled,
			allowUnselect,
			dataTestId,
			onBlur,
			onChange
		},
		ref
	) => {
		const id = useMemoOnce(() => `${name}_${value}_${generate()}`);

		const innerRef = useRef<HTMLInputElement>(null);
		const mergedRef = mergeRefs([innerRef, ref]);

		// toggle radio select
		function handleToggle() {
			if (!(innerRef && innerRef.current && allowUnselect && !disabled)) return;

			const element = innerRef.current;

			// `mouseUp` EVENT FIRES BEFORE `onChange` / `onClick` SO WE CAN GET THE PREVIOUS VALUE
			const prevChecked = element.checked;

			// USE TIMEOUT TO DELAY THE `mouseUp` EVENT TO GET THE FINAL CHECKED VALUE
			setTimeout(() => {
				if (prevChecked === element.checked) {
					element.checked = false;
					// trigger onChange event
					element.dispatchEvent(new Event('change'));
					if (onChange) onChange('');
				}
			});
		}

		function dataTestIdValue() {
			if (dataTestId) {
				return (
					dataTestId.replace(/\s/g, '').toLowerCase() +
					'_' +
					label.replace(/\s/g, '').toLowerCase()
				);
			}

			return label.replace(/\s/g, '').toLowerCase();
		}

		return (
			<Wrapper disabled={disabled}>
				<HiddenInput
					ref={mergedRef}
					id={id}
					type={InputType.Radio}
					name={name}
					value={value}
					checked={checked}
					disabled={disabled}
					onBlur={onBlur}
					onChange={e => onChange && onChange(e.target.value)}
				/>

				<label
					data-testid={`html_toggle_${dataTestIdValue()}`}
					htmlFor={id}
					onMouseUp={handleToggle}
				>
					<Container className={className}>
						<RadioButton>
							<RadioButtonCheck />
						</RadioButton>

						{label && (
							<Text
								data-testid={dataTestIdValue()}
								color={error !== undefined ? Colors.text.error : undefined}
								data-tip={tooltip}
								data-for={id}
							>
								{label}
							</Text>
						)}
					</Container>
				</label>

				{tooltip !== undefined && <Tooltip id={id} />}
			</Wrapper>
		);
	}
);

export const RadioButtonUncontrolled = withMemo(Component);
