import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENABLE_SENTRY === 'true' && window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: 'https://c3c61376ba3baecc7df6bfffc4226e48@o4507882312105984.ingest.de.sentry.io/4507882315645008',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.captureConsoleIntegration({
				levels: ['error']
			})
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			'localhost',
			/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/?\n]+)\.ledidi\.com/i
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
