import { ProjectOwnerDetails } from 'api/data/projects';
import { DropdownUsers } from 'components/UI/DropdownUsers';
import { Flex } from 'components/UI/Flex';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Translate } from 'components/UI/Translate';
import { Typography } from 'components/UI/Typography';
import { UserCard } from 'components/UI/UserCard';
import { Svgs } from 'environment';
import { useSubscription, useTranslation, useUsername } from 'hooks/store';
import { useAPITransferEnterpriseProjectsOwnership } from 'hooks/store/account/enterprise/useAPITransferEnterpriseProjectsOwnership';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';
import { useMemo, useState } from 'react';
import { SubscriptionStatus, SubscriptionUser, UserStatus } from 'store/account/subscription';
import { InputType, NeutralButtonProps, PrimaryButtonProps } from 'types/index';

interface TransferProjectOwnershipModalProps {
	projectId: string;
	currentOwnerDetails: ProjectOwnerDetails;
	onTransferred: () => void;
	onClose: () => void;
}

enum TransferStep {
	ChooseNewOwner,
	ConfirmNewOwner,
	TransferSuccessful
}

export function TransferProjectOwnershipModal({
	projectId,
	currentOwnerDetails,
	onTransferred,
	onClose
}: TransferProjectOwnershipModalProps) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [
		{
			data: { users }
		}
	] = useSubscription();

	const loggedInUser = useUsername();

	const [confirmInputValue, setConfirmInputValue] = useState('');
	const CONFIRM_TEXT = 'TRANSFER_OWNERSHIP';

	const usersElligibleForTransfer = useMemo(
		() =>
			users.filter(
				u =>
					u.status === UserStatus.Active &&
					u.subscriptionStatus === SubscriptionStatus.Active &&
					u.userid !== currentOwnerDetails.userId &&
					u.userid !== loggedInUser
			),
		[users]
	);

	const usersMap = useMemo(
		() =>
			usersElligibleForTransfer.map(u => {
				return {
					userId: u.userid,
					userFirstName: u.userFirstName,
					userSirName: u.userSirName,
					emailAddress: u.emailAddress
				};
			}),
		[usersElligibleForTransfer]
	);

	const [step, setStep] = useState(TransferStep.ChooseNewOwner);

	const [selectedNewOwner, setSelectedNewOwner] = useState<SubscriptionUser>();

	const [{ loading: transfering, error: errorTransfering }, transferProjects] =
		useAPITransferEnterpriseProjectsOwnership();

	function closeModal(transfered?: boolean) {
		setStep(TransferStep.ChooseNewOwner);
		onClose();
		if (transfered) onTransferred();
	}

	function onContinue() {
		setStep(TransferStep.ConfirmNewOwner);
	}

	function onBack() {
		setStep(TransferStep.ChooseNewOwner);
	}

	function onTransferClicked() {
		if (selectedNewOwner) transferProjects(selectedNewOwner.userid, [projectId]);
	}

	useCompletedAction(
		transfering,
		errorTransfering,
		// SUCCESS CALLBACK
		() => {
			setNotification({
				message: translate(
					({ dataset }) => dataset.entries.transferOwnership.successMessage
				)
			});
			setStep(TransferStep.TransferSuccessful);
		}
	);

	const primaryButtonProps: PrimaryButtonProps = useMemo(() => {
		switch (step) {
			case TransferStep.ChooseNewOwner:
				return {
					label: translate(({ buttons }) => buttons.continue),
					disabled: !selectedNewOwner,
					onClick: onContinue
				};
			case TransferStep.ConfirmNewOwner:
				return {
					label: translate(({ buttons }) => buttons.transfer),
					disabled: confirmInputValue !== CONFIRM_TEXT,
					loading: transfering,
					onClick: onTransferClicked
				};
			case TransferStep.TransferSuccessful:
				return {
					label: translate(({ buttons }) => buttons.done),
					onClick: () => closeModal(true)
				};
			default:
				return false;
		}
	}, [step, selectedNewOwner, confirmInputValue, transfering]);

	const neutralButtonProps: NeutralButtonProps = useMemo(() => {
		switch (step) {
			case TransferStep.ChooseNewOwner:
				return {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: closeModal
				};
			case TransferStep.ConfirmNewOwner:
				return {
					label: translate(({ buttons }) => buttons.back),
					onClick: onBack
				};
			default:
				return false;
		}
	}, [step, selectedNewOwner]);

	const transferSuccessfulOwners = {
		previousOwnerName:
			currentOwnerDetails.userFirstName + ' ' + currentOwnerDetails.userSirName,
		newOwnerName: selectedNewOwner?.userFirstName + ' ' + selectedNewOwner?.userSirName
	};

	return (
		<Modal
			size={s => s.s}
			minHeight={56}
			title={translate(({ enterpriseAdmin }) => enterpriseAdmin.projects.transferModal.title)}
			primary={primaryButtonProps}
			neutral={neutralButtonProps}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			{step === TransferStep.ChooseNewOwner && (
				<>
					<DropdownUsers
						users={[{ ...currentOwnerDetails }]}
						selectedUserId={currentOwnerDetails.userId}
						label={translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.projects.transferModal.currentOwner
						)}
						inactive
					/>
					<Spacer size={s => s.s} />
					<DropdownUsers
						users={usersMap}
						selectedUserId={selectedNewOwner?.userid ?? ''}
						onSelect={userId => {
							const user = users.find(u => u.userid === userId);
							if (user) setSelectedNewOwner(user);
						}}
						label={translate(
							({ enterpriseAdmin }) => enterpriseAdmin.projects.transferModal.newOwner
						)}
					/>
				</>
			)}

			{step === TransferStep.ConfirmNewOwner && selectedNewOwner && (
				<>
					<UserCard.Basic
						userId={selectedNewOwner.userid}
						userData={{ ...selectedNewOwner }}
					/>
					<Spacer size={s => s.s} />
					<UserCard.Details
						userId={selectedNewOwner.userid}
						userData={selectedNewOwner}
						showHeader={false}
						cardLayout={l => l.TransferUser}
					/>
					<Spacer size={s => s.m} />
					<Typography.Paragraph
						css={`
							user-select: none;
						`}
						fontweight={w => w.bold}
						onSelect={e => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						{translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.projects.transferModal.confirmationText
						)}
					</Typography.Paragraph>
					<Spacer size={s => s.xs} />

					<Input
						type={InputType.Text}
						value={confirmInputValue}
						onChange={e => setConfirmInputValue(e.target.value)}
						dataTestId="transfer-ownership-confirm-input"
					/>
				</>
			)}
			{step === TransferStep.TransferSuccessful && (
				<Flex align={a => a.center} column>
					<Svgs.ProjectComplete style={{ minHeight: 240 }} />
					<Spacer size={s => s.s} />
					<Typography.H5 alignCenter>
						{translate(
							({ enterpriseAdmin }) =>
								enterpriseAdmin.projects.transferModal.titleTransferSuccesful
						)}
					</Typography.H5>
					<Spacer size={s => s.xs} />
					<Typography.Paragraph alignCenter>
						<Translate
							transKey={({ enterpriseAdmin }) =>
								enterpriseAdmin.projects.transferModal.projectUnderNewOwnership
							}
							options={transferSuccessfulOwners}
						>
							{Object.values(transferSuccessfulOwners).map((value, i) => {
								return i === 0 ? (
									<span key={value}>{value}</span>
								) : (
									<b key={value}>{value}</b>
								);
							})}
						</Translate>
					</Typography.Paragraph>
				</Flex>
			)}
		</Modal>
	);
}
