import produce from 'immer';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';

import {
	Columns,
	ComparePairedAnalysis,
	ComparePairedDataModels,
	ComparePairedStatistics
} from 'api/data/analyses';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';

import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { SelectItem } from 'types/index';
import { ComparePairedTestVariableConfig } from './ComparePairedTestVariableConfig';
import { ConfigContainer } from '../../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	buildVariablesRichData,
	getAggregatorVariableNameByAggregationRuleName,
	variablesDataArrayIterator
} from 'helpers/variables';
import { getDefaultVariableName } from 'helpers/analysis';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useActiveFiltersByVariableName,
	useFilters
} from 'hooks/store';
import { useMutableState, useDebounce, usePrevious } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';

interface Props {
	analysis: ComparePairedAnalysis;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function ComparePairedConfig({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();
	const [{ areFiltersOpen }] = useFilters();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [fullscreen] = useFullscreenAnalysis();

	const {
		input: analysisInput,
		options: { configPanel }
	} = analysis;

	const [draftAnalysisInput, setDraftAnalysisInput] = useMutableState(analysisInput);

	const [{ isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(analysis.id);

	const [activeColumn] = useAnalysesActiveColum();

	const {
		dataModel: inputDataModel,
		variables: inputVariables,
		statistics: inputStatistics
	} = draftAnalysisInput;

	const { variablesMap, variableSetsMap } = variablesData;

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const {
		selectItemsMap,
		categoryWithoutAggregationsSelectItems,
		numericSelectItems,
		stringSelectItems,
		integerSelectItems
	} = variablesDataSelectItems;

	const filters = useActiveFiltersByVariableName(inputVariables.catVarnameDiffSamples);

	const dataModel = {
		is: {
			singleEntry: inputDataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT,
			multipleEntry: inputDataModel === ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT,
			usingSeries: inputDataModel === ComparePairedDataModels.USING_SERIES
		},
		label: {
			[ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.singleEntryDataModel
			),
			[ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.twoEntriesDataModel
			),
			[ComparePairedDataModels.USING_SERIES]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.seriesDataModel
			)
		}
	};

	const dataModelSelectItems: SelectItem[] = [
		{
			label: dataModel.label[ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT],
			value: ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
		},
		{
			label: dataModel.label[ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT],
			value: ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
		},
		{
			label: dataModel.label[ComparePairedDataModels.USING_SERIES],
			value: ComparePairedDataModels.USING_SERIES
		}
	];

	const twoCategories = useMemo(() => {
		const categs = getSelectedItemCategories(inputVariables.catVarnameDiffSamples);

		if (categs && categs.length === 2) {
			if (!filters.length) return true;

			return areTwoFiltersActive();
		}

		if (categs && categs.length > 2) {
			return areTwoFiltersActive();
		}

		return false;
	}, [inputVariables.catVarnameDiffSamples, variablesMap, filters]);

	useDebounce(
		() => {
			const hasChanges = !isEqual(draftAnalysisInput, analysisInput);

			if (!hasChanges) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input = draftAnalysisInput;
			});

			const shouldUpdateAnalysis =
				(dataModel.is.usingSeries &&
					!!updatedAnalysis.input.variables.groupOne &&
					!!updatedAnalysis.input.variables.groupTwo &&
					!!updatedAnalysis.input.variables.firstCategoryValue &&
					!!updatedAnalysis.input.variables.secondCategoryValue) ||
				(dataModel.is.multipleEntry &&
					!!updatedAnalysis.input.variables.firstCategoryValue &&
					!!updatedAnalysis.input.variables.secondCategoryValue) ||
				dataModel.is.singleEntry;

			if (shouldUpdateAnalysis) {
				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[draftAnalysisInput],
		ANALYSIS_DEBOUNCE_TIME
	);

	// SYNC `draftAnalysisInput` STATE
	useEffect(() => {
		if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	}, [analysisInput]);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			prevShouldRefetchAnalyses !== undefined &&
			prevShouldRefetchAnalyses !== shouldRefetchAnalyses
		) {
			turnOffStatistics();
		}
	}, [shouldRefetchAnalyses]);

	function areTwoFiltersActive() {
		let valid = false;

		filters.forEach(filter => {
			if (filter.values && filter.values.length === 2) {
				valid = true;
			}
		});

		return valid;
	}
	const { variablesDataArray } = buildVariablesRichData(variablesData);
	const [numericVariableNames, categoryVariableNames, stringVariableNames] = useMemo(() => {
		const numericVariableNames: string[] = [];
		const categoryVariableNames: string[] = [];
		const stringVariableNames: string[] = [];

		// BUILD VARIABLES BY TYPE ARRAYS
		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE
			variable => addVariableToNamesArray(variable),
			// GROUP
			groupData => {
				const { groupVariables } = groupData;

				groupVariables.forEach(variable => addVariableToNamesArray(variable));
			},
			// VARIABLE SET
			variableSetData => {
				const { aggregationRules } = variableSetData;

				aggregationRules.forEach(aggregationRule => {
					const variable = variablesMap[aggregationRule.aggregator.variableName];

					addVariableToNamesArray(variable, aggregationRule.name);
				});
			}
		);

		function addVariableToNamesArray(variable: Variable, customName?: string) {
			const isNumericVariable = [VariableType.Integer, VariableType.Float].includes(
				variable.type
			);
			const isCategoryVariable = VariableType.Category === variable.type;
			const isStringVariable = VariableType.String === variable.type;

			if (isNumericVariable) numericVariableNames.push(customName ?? variable.name);
			if (isCategoryVariable) categoryVariableNames.push(customName ?? variable.name);
			if (isStringVariable) stringVariableNames.push(customName ?? variable.name);
		}

		return [numericVariableNames, categoryVariableNames, stringVariableNames];
	}, [variablesDataArray]);

	function getSelectedItemCategories(name: string): string[] {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			if (aggregatorVariableName in variablesMap) {
				variable = variablesMap[aggregatorVariableName];
			}
		}

		if (variable) return variable.categories.map(c => c.value);

		return [];
	}

	function getResetStatistics(): ComparePairedStatistics {
		return {
			pairedTTest: false,
			pairedWilcoxon: false
		};
	}

	function turnOffStatistics() {
		setDraftAnalysisInput(state => {
			state.statistics = getResetStatistics();
		});
	}

	const variableSetSelectItems: SelectItem[] = useMemo(() => {
		const { variableSets } = buildVariablesRichData(variablesData);

		return variableSets.map(variableSet => ({
			label: variableSet.setLabel,
			value: variableSet.setName
		}));
	}, [variablesData]);

	const areSeriesInputsFilled = !!(
		inputVariables.setName &&
		inputVariables.testVariable &&
		inputVariables.groupVariable
	);

	function getVariableCategories(name: string) {
		let variable = variablesMap[name];
		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			variable = variablesMap[aggregatorVariableName];
		}

		if (variable) return variable.categories;

		return [];
	}

	const { categories } = useMemo(() => {
		const categories = getVariableCategories(
			inputDataModel === ComparePairedDataModels.USING_SERIES
				? inputVariables.groupVariable
				: inputVariables.catVarnameDiffSamples
		).map(c => ({
			label: c.label || c.value,
			value: c.value
		}));

		return { categories };
	}, [
		inputVariables.catVarnameDiffSamples,
		variablesMap,
		variableSetsMap,
		inputVariables.groupVariable,
		inputDataModel
	]);
	const areTestsDisabled = useMemo(() => {
		if (dataModel.is.singleEntry) return false;

		if (
			(!twoCategories && !dataModel.is.usingSeries) ||
			(dataModel.is.usingSeries && !areSeriesInputsFilled)
		) {
			return true;
		}
	}, [areSeriesInputsFilled, dataModel, twoCategories]);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && configPanel.open && (
				<AnalysisOptionsHeader analysis={analysis as ComparePairedAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.comparePaired.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === inputDataModel)}
						onValueSelected={dataModel =>
							dataModel &&
							setDraftAnalysisInput(state => {
								state.dataModel = dataModel as ComparePairedDataModels;
								state.statistics = getResetStatistics();
							})
						}
						canClear={false}
						isItemDisabled={option => {
							let value = false;
							if (option.value === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT) {
								const singleEntryCase = [
									getDefaultVariableName(numericVariableNames),
									getDefaultVariableName(numericVariableNames, {
										second: true
									})
								];
								value = !singleEntryCase.every(variableRef => variableRef !== '');
							}
							if (
								option.value ===
								ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
							) {
								const multipleEntriesCase = [
									getDefaultVariableName(numericVariableNames),
									getDefaultVariableName(categoryVariableNames),
									getDefaultVariableName(stringVariableNames) ||
										getDefaultVariableName(numericVariableNames, {
											second: true
										})
								];
								value = !multipleEntriesCase.every(
									variableRef => variableRef !== ''
								);
							}
							if (option.value === ComparePairedDataModels.USING_SERIES) {
								value = Object.keys(variableSetsMap).length === 0;
							}

							return value;
						}}
					/>

					{(dataModel.is.singleEntry || dataModel.is.multipleEntry) && (
						<CreatableSelect
							label={translate(
								({ analysis }) =>
									analysis.analyses.comparePaired.config.numericVariableOne
							)}
							items={
								dataModel.is.multipleEntry
									? numericSelectItems
									: numericSelectItems.filter(
											value =>
												value.label !== inputVariables.numericVariableTwo
									  )
							}
							value={selectItemsMap[inputVariables.numericVariableOne]}
							onValueSelected={numericVariableOne =>
								numericVariableOne &&
								setDraftAnalysisInput(state => {
									state.variables.numericVariableOne = numericVariableOne;
									state.statistics = getResetStatistics();
								})
							}
							canClear={false}
						/>
					)}

					{dataModel.is.singleEntry && (
						<CreatableSelect
							label={translate(
								({ analysis }) =>
									analysis.analyses.comparePaired.config.numericVariableTwo
							)}
							items={
								dataModel.is.multipleEntry
									? numericSelectItems
									: numericSelectItems.filter(
											value =>
												value.label !== inputVariables.numericVariableOne
									  )
							}
							value={selectItemsMap[inputVariables.numericVariableTwo]}
							onValueSelected={numericVariableTwo =>
								numericVariableTwo &&
								setDraftAnalysisInput(state => {
									state.variables.numericVariableTwo = numericVariableTwo;
									state.statistics = getResetStatistics();
								})
							}
							canClear={false}
						/>
					)}

					{dataModel.is.multipleEntry && (
						<>
							<CreatableSelect
								label={translate(
									({ analysis }) =>
										analysis.analyses.comparePaired.config.catVarnameDiffSamples
								)}
								items={categoryWithoutAggregationsSelectItems}
								value={selectItemsMap[inputVariables.catVarnameDiffSamples]}
								onValueSelected={catVarnameDiffSamples =>
									catVarnameDiffSamples &&
									setDraftAnalysisInput(state => {
										if (state.variables.catVarnameDiffSamples) {
											state.variables.firstCategoryValue = '';
											state.variables.secondCategoryValue = '';
										}
										state.variables.catVarnameDiffSamples =
											catVarnameDiffSamples;

										state.statistics = getResetStatistics();
									})
								}
								canClear={false}
							/>
							{inputVariables.catVarnameDiffSamples && (
								<>
									<CreatableSelect
										label={translate(
											({ analysis }) =>
												analysis.analyses.comparePaired.config
													.firstCategoryValue
										)}
										items={categories.filter(
											value =>
												value.label !== inputVariables.secondCategoryValue
										)}
										value={
											categories.filter(
												value =>
													value.value ===
													inputVariables.firstCategoryValue
											)[0]
										}
										onValueSelected={firstCategoryValue =>
											firstCategoryValue &&
											setDraftAnalysisInput(state => {
												state.variables.firstCategoryValue =
													firstCategoryValue;
												state.statistics = getResetStatistics();
											})
										}
										canClear={false}
									/>

									<CreatableSelect
										label={translate(
											({ analysis }) =>
												analysis.analyses.comparePaired.config
													.secondCategoryValue
										)}
										items={categories.filter(
											value =>
												value.label !== inputVariables.firstCategoryValue
										)}
										value={
											categories.filter(
												value =>
													value.value ===
													inputVariables.secondCategoryValue
											)[0]
										}
										onValueSelected={secondCategoryValue =>
											secondCategoryValue &&
											setDraftAnalysisInput(state => {
												state.variables.secondCategoryValue =
													secondCategoryValue;
												state.statistics = getResetStatistics();
											})
										}
										canClear={false}
									/>
								</>
							)}

							<CreatableSelect
								label={translate(
									({ analysis }) =>
										analysis.analyses.comparePaired.config
											.patientIdentifierVarname
								)}
								items={[...stringSelectItems, ...integerSelectItems]}
								value={selectItemsMap[inputVariables.patientIdentifierVarname]}
								onValueSelected={patientIdentifierVarname =>
									patientIdentifierVarname &&
									setDraftAnalysisInput(state => {
										state.variables.patientIdentifierVarname =
											patientIdentifierVarname;
										state.statistics = getResetStatistics();
									})
								}
								canClear={false}
							/>
						</>
					)}

					{dataModel.is.usingSeries && (
						<>
							<CreatableSelect
								label={translate(
									({ analysis }) => analysis.analyses.comparePaired.config.setName
								)}
								items={variableSetSelectItems}
								value={variableSetSelectItems.find(
									item => item.value === inputVariables.setName
								)}
								onValueSelected={setName =>
									setName &&
									setDraftAnalysisInput(state => {
										state.variables.setName = setName;
										state.variables.groupOne = '';
										state.variables.groupTwo = '';
										state.statistics = getResetStatistics();
									})
								}
								canClear={false}
							/>

							{inputVariables.setName && (
								<>
									<ComparePairedTestVariableConfig
										variablesData={variablesData}
										setName={inputVariables.setName ?? undefined}
										omitVariables={[inputVariables.groupVariable]}
										render={({ selectItemsMap, numericSelectItems }) => (
											<CreatableSelect
												label={translate(
													({ analysis }) =>
														analysis.analyses.comparePaired.config
															.testVariable
												)}
												items={numericSelectItems}
												value={selectItemsMap[inputVariables.testVariable]}
												onValueSelected={testVariable =>
													testVariable &&
													setDraftAnalysisInput(state => {
														state.variables.testVariable = testVariable;
														state.statistics = getResetStatistics();
													})
												}
												canClear={false}
											/>
										)}
									/>
									<ComparePairedTestVariableConfig
										variablesData={variablesData}
										setName={inputVariables.setName ?? undefined}
										omitVariables={[inputVariables.testVariable]}
										render={({ selectItemsMap, categorySelectItems }) => (
											<CreatableSelect
												label={translate(
													({ analysis }) =>
														analysis.analyses.comparePaired.config
															.catVarnameDiffSamples
												)}
												items={categorySelectItems}
												value={selectItemsMap[inputVariables.groupVariable]}
												onValueSelected={groupVariable =>
													groupVariable &&
													setDraftAnalysisInput(state => {
														if (state.variables.groupVariable) {
															state.variables.groupOne = '';
															state.variables.groupTwo = '';
														}
														state.variables.groupVariable =
															groupVariable;
														state.statistics = getResetStatistics();
													})
												}
												canClear={false}
											/>
										)}
									/>
									{inputVariables.groupVariable && (
										<>
											<CreatableSelect
												label={translate(
													({ analysis }) =>
														analysis.analyses.comparePaired.config
															.firstCategoryValue
												)}
												items={categories.filter(
													value => value.label !== inputVariables.groupTwo
												)}
												value={
													categories.filter(
														value =>
															value.value === inputVariables.groupOne
													)[0]
												}
												onValueSelected={groupOne =>
													groupOne &&
													setDraftAnalysisInput(state => {
														state.variables.groupOne = groupOne;
														state.statistics = getResetStatistics();
													})
												}
												canClear={false}
											/>
											<CreatableSelect
												label={translate(
													({ analysis }) =>
														analysis.analyses.comparePaired.config
															.secondCategoryValue
												)}
												items={categories.filter(
													value => value.label !== inputVariables.groupOne
												)}
												value={
													categories.filter(
														value =>
															value.value === inputVariables.groupTwo
													)[0]
												}
												onValueSelected={groupTwo =>
													groupTwo &&
													setDraftAnalysisInput(state => {
														state.variables.groupTwo = groupTwo;
														state.statistics = getResetStatistics();
													})
												}
												canClear={false}
											/>
										</>
									)}
								</>
							)}
						</>
					)}

					{/* STATISTICS */}
					<Switch
						label={translate(({ analysis }) => analysis.statistics.pairedTTest.name)}
						on={inputStatistics.pairedTTest}
						disabled={areTestsDisabled}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.pairedTTest = !state.statistics.pairedTTest;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.pairedWilcoxon.name)}
						on={inputStatistics.pairedWilcoxon}
						disabled={areTestsDisabled}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.pairedWilcoxon = !state.statistics.pairedWilcoxon;
							})
						}
					/>
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}
