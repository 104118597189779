import { useRef, useMemo } from 'react';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import { nanoid as generate } from 'nanoid';
import { CorrelationsResults, CorrelationsStatisticsValueResults } from 'api/data/analyses';
import { ScalesLabels } from 'types';
import { Chart } from 'components/UI/Chart';
import { computeCorrelationsScatterChartV1Data as computeData } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import {
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	data: CorrelationsResults;
	isForExport?: boolean;
	isLegendEnabled: boolean;
	legendHeader?: string;
	linearRegression: CorrelationsStatisticsValueResults;
	scalesLabels: ScalesLabels;
	isConfigPanelOpen: boolean;
}

function Component({
	id,
	data,
	linearRegression,
	isForExport,
	isLegendEnabled,
	legendHeader,
	scalesLabels,
	isConfigPanelOpen
}: Props) {
	const ref = useRef<ZingChart>(null);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const [activeColumns] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const { series, options, disableAnimations } = useMemo(
		() =>
			computeData(
				data,
				linearRegression,
				isLegendEnabled,
				scalesLabels,
				activeColumns,
				!!fullscreen,
				plots,
				legendHeader
			),
		[data, linearRegression, isLegendEnabled, scalesLabels, activeColumns, plots, legendHeader]
	);

	const renderId = useMemo(generate, [
		series,
		activeColumns,
		fullscreen,
		windowSize,
		analysisIds,
		filters,
		isConfigPanelOpen
	]);

	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `linear-${e.plotindex}` });
		}
	}

	return (
		<Chart
			key={renderId}
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			disableAnimations={disableAnimations}
			effect={e => e.SlideBottom}
			sequence={s => s.ByPlot}
			method={m => m.RegularEaseOut}
			speed={s => s.Faster}
			isForExport={isForExport}
			height={fullscreen || activeColumns === 1 ? '100%' : '330'}
		/>
	);
}

export const CorrelationsScatterChartV1 = withMemo(Component);
