import { useState } from 'react';

import { AlignOptions, Columns, LineOptions, PlotNumericAnalysis } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { MediaQueries } from 'environment';
import { PlotNumericConfig } from './PlotNumericConfig';
import { PlotNumericView } from './PlotNumericView';
import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import { usePlotNumericAnalysis, useAnalysesActiveColum, useFullscreenAnalysis } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

export interface ScatterOptions {
	line: LineOptions;
	align: AlignOptions;
}

interface Props {
	analysis: PlotNumericAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const [line, setLine] = useState(LineOptions.Mean);
	const [align, setAlign] = useState(AlignOptions.Scattered);

	const { loading } = usePlotNumericAnalysis(analysis);

	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const analysisValidInput = isAnalysisInputValid(analysis);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	function handleLineSelect(option: LineOptions) {
		setLine(option);
	}

	function handleAlignSelect(value: AlignOptions) {
		setAlign(value);
	}
	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};
	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
				{analysis.fetched && !loading ? (
					<PlotNumericView
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						variablesData={variablesData}
						exporting={exporting}
						loading={loading}
						line={line}
						align={align}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}

				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<PlotNumericConfig
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
						line={line}
						align={align}
						onAlignSelect={handleAlignSelect}
						onLineSelect={handleLineSelect}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis category numeric />
		</AnalysisContainer>
	);
}

export const PlotNumeric = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
