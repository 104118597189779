import type {
	ContentMap,
	JsonLogic,
	TokenMap,
	Variable,
	SerializedContentWithPositions,
	SerializedDocWithPositions
} from 'api/data/variables/types';
import { nanoid as generate } from 'nanoid';
import type { ParenthesisToken, GeneralTokenizerState } from 'types/UI/calculatedEditor/types';
import {
	generatePlaceholderTextNode,
	generateTextNode,
	generateVariableTextNode
} from 'helpers/calculatedEditor/parsers/serializer/serializer';
import { getOpenParenthesisOfIdFromMap } from 'helpers/calculatedEditor/editorHelpers';
import { ArithmeticOperator, ComparisonOperator } from 'types/data/variables/constants';
import { generateMergeContent, tokenGenerator } from '../tokenGenerator';

export function parseJsonLogicToEditorContentAndState(
	cases: JsonLogic[],
	variables: Variable[],
	logicIds: string[]
): { content: SerializedDocWithPositions; tokenMap: TokenMap; contentMap: ContentMap } {
	const topLevelDocument: SerializedDocWithPositions = {
		type: 'doc',
		pos: {
			start: 0,
			end: 0
		},
		content: []
	};

	const tokenMap: TokenMap = {};

	const contentMap: ContentMap = {};

	let position = 0;

	let logicIdx = 0;

	for (const logic of cases) {
		position++;
		const paragraphStartPos = position;

		const currentLogicId = logicIds[logicIdx];

		tokenMap[currentLogicId] = [];

		const isLastLogic = currentLogicId === logicIds[logicIds.length - 1];

		const { content, nextPosition } = processJsonLogicRecursively(
			logic,
			variables,
			tokenMap,
			currentLogicId,
			position,
			isLastLogic
		);

		const mergedContent = generateMergeContent(content);

		topLevelDocument.content.push({
			type: 'paragraph',
			attrs: {
				nodeIndent: null,
				style: '',
				spellcheck: 'false'
			},
			content: mergedContent,
			pos: {
				start: paragraphStartPos,
				end: nextPosition + 1
			}
		});

		contentMap[currentLogicId] = {
			startPosition: paragraphStartPos,
			endPosition: nextPosition
		};

		position = nextPosition + 1;

		logicIdx++;
	}

	topLevelDocument.pos.end = position;

	if (topLevelDocument.content.length === 0) {
		topLevelDocument.content.push({
			type: 'paragraph',
			attrs: {
				nodeIndent: null,
				style: '',
				spellcheck: 'false'
			},
			pos: {
				start: 0,
				end: 1
			}
		});
	}

	// set end of top level document to the end of the last paragraph
	topLevelDocument.pos.end =
		topLevelDocument.content[topLevelDocument.content.length - 1].pos.end;

	return { content: topLevelDocument, tokenMap: tokenMap, contentMap };
}

const processJsonLogicRecursively = (
	logic: any,
	variables: Variable[],
	tokenMap: TokenMap,
	logicId: string,
	position: number,
	isLastLogic = false
): { content: SerializedContentWithPositions[]; nextPosition: number } => {
	if (!logic) return { content: [], nextPosition: position };

	const content: SerializedContentWithPositions[] = [];

	if (typeof logic === 'object') {
		const keys = Object.keys(logic);
		if (keys.length === 0) return { content: [], nextPosition: position };

		const key = keys[0];

		switch (key) {
			case 'if': {
				position = generateStartingIfContentAndState(content, tokenMap, logicId, position);

				const { content: logicalContent, nextPosition: positionAfterLogical } =
					processJsonLogicRecursively(
						logic[key][0],
						variables,
						tokenMap,
						logicId,
						position
					);

				content.push(...logicalContent);

				position = generateEndingIfContentAndState(
					content,
					tokenMap,
					logicId,
					positionAfterLogical
				);

				const { content: actionContent, nextPosition: positionAfterAction } =
					processJsonLogicRecursively(
						logic[key][1],
						variables,
						tokenMap,
						logicId,
						position
					);

				content.push(...actionContent);

				position = positionAfterAction;

				if (!isLastLogic) {
					// Increase position for whitespace character
					content.push(generateTextNode(' ,', position));

					position = position + 1;

					// Add Separator Token to editor state
					const { token: separatorToken, nextPos: posAfterSeparator } =
						tokenGenerator.generateSeparatorToken(',', position);

					tokenMap[logicId].push(separatorToken);

					position = posAfterSeparator;
				}
				break;
			}

			case 'and': {
				content.push(generateTextNode('( ', position));

				let openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

				// Add Open Parenthesis Token to editor state
				const { token: openParenthesisToken, nextPos: posAfterParen } =
					tokenGenerator.generateParenthesisToken(
						'(',
						position,
						calculateParenDepth(openParenthesisTokens)
					);

				tokenMap[logicId].push(openParenthesisToken);

				// Increase position for whitespace character
				position = posAfterParen + 1;

				for (let i = 0; i < logic[key].length; i++) {
					const {
						content: logicalContentOfAnd,
						nextPosition: positionAfterLogicalContent
					} = processJsonLogicRecursively(
						logic[key][i],
						variables,
						tokenMap,
						logicId,
						position
					);

					// Increase position for whitespace character
					position = positionAfterLogicalContent;

					content.push(...logicalContentOfAnd);

					if (i < logic[key].length - 1) {
						content.push(generateTextNode(' ) AND ( ', position));

						// Increase position for whitespace character
						position = position + 1;

						openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

						// Get last open parenthesis token
						const lastOpenParenthesisToken =
							getLastOpenParenthesisWithNoMatchingPairId(openParenthesisTokens);

						// Add Close Parenthesis Token to editor state
						const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
							tokenGenerator.generateParenthesisToken(
								')',
								position,
								lastOpenParenthesisToken!.depth,
								lastOpenParenthesisToken
							);

						tokenMap[logicId].push(closeParenthesisToken);

						lastOpenParenthesisToken!.matchingPairId = closeParenthesisToken.id;

						// Increase position for whitespace character
						position = posAfterCloseParenthesis + 1;

						// Generate AND Keyword Token
						const { token: andKeywordToken, nextPos: posAfterAndKeyword } =
							tokenGenerator.generateKeywordToken('AND', position);

						tokenMap[logicId].push(andKeywordToken);

						// Increase position for whitespace character
						position = posAfterAndKeyword + 1;

						// Add Open Parenthesis Token to editor state
						const { token: openParenthesisToken, nextPos: posAfterParen } =
							tokenGenerator.generateParenthesisToken(
								'(',
								position,
								calculateParenDepth(openParenthesisTokens)
							);

						tokenMap[logicId].push(openParenthesisToken);

						// Increase position for whitespace character
						position = posAfterParen + 1;
					}
				}
				content.push(generateTextNode(' )', position));

				// Increase position for whitespace character
				position = position + 1;

				openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

				// Get last open parenthesis token
				const lastOpenParenthesisToken =
					getLastOpenParenthesisWithNoMatchingPairId(openParenthesisTokens);

				// Add Close Parenthesis Token to editor state
				const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
					tokenGenerator.generateParenthesisToken(
						')',
						position,
						lastOpenParenthesisToken!.depth,
						lastOpenParenthesisToken
					);

				tokenMap[logicId].push(closeParenthesisToken);

				lastOpenParenthesisToken!.matchingPairId = closeParenthesisToken.id;

				position = posAfterCloseParenthesis;
				break;
			}

			case 'var': {
				const variable = variables.find(v => v.name === logic[key][0]);

				if (!variable) {
					break;
				}

				const uniqueID = generate();
				content.push(generateVariableTextNode(variable, uniqueID, position));

				// Add Variable Token to editor state
				const { token: variableToken, nextPos: posAfterVariable } =
					tokenGenerator.generateVariableToken(variable, position, uniqueID);

				tokenMap[logicId].push(variableToken);

				position = posAfterVariable;

				break;
			}

			case 'catVal': {
				position = generateCategoryContentAndState(
					content,
					tokenMap,
					logicId,
					position,
					logic[key]
				);
				break;
			}

			default: {
				if (
					Object.values(ComparisonOperator).includes(key as any) ||
					Object.values(ArithmeticOperator).includes(key as any)
				) {
					const hasNestedExpressionInFirstOperand =
						typeof logic[key][0] !== 'string' &&
						typeof logic[key][0] !== 'number' &&
						typeof logic[key][0] === 'object' &&
						!('var' in logic[key][0]);

					const hasNestedExpressionInSecondOperand =
						typeof logic[key][1] !== 'string' &&
						typeof logic[key][1] !== 'number' &&
						typeof logic[key][1] === 'object' &&
						!('var' in logic[key][1]);

					if (hasNestedExpressionInFirstOperand) {
						content.push(generateTextNode('( ', position));

						const openParenthesisTokens = getOpenParenthesisOfIdFromMap(
							logicId,
							tokenMap
						);

						// Add Open Parenthesis Token to editor state
						const { token: openParenthesisToken, nextPos: posAfterParen } =
							tokenGenerator.generateParenthesisToken(
								'(',
								position,
								calculateParenDepth(openParenthesisTokens)
							);

						tokenMap[logicId].push(openParenthesisToken);

						// Increase position for whitespace character
						position = posAfterParen + 1;
					}

					for (let i = 0; i < logic[key].length; i++) {
						const { content: logicalContent, nextPosition } =
							processJsonLogicRecursively(
								logic[key][i],
								variables,
								tokenMap,
								logicId,
								position
							);

						content.push(...logicalContent);

						position = nextPosition;

						if (i < logic[key].length - 1) {
							position = generateLogicalOperatorContentAndState(
								content,
								tokenMap,
								logicId,
								position,
								key,
								hasNestedExpressionInFirstOperand,
								hasNestedExpressionInSecondOperand
							);
						}
					}

					if (hasNestedExpressionInSecondOperand) {
						content.push(generateTextNode(' )', position));

						// Increase position for whitespace character
						position = position + 1;

						const openParens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

						// Get last open parenthesis token
						const lastOpenParenthesisToken =
							getLastOpenParenthesisWithNoMatchingPairId(openParens);

						// Add Close Parenthesis Token to editor state
						const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
							tokenGenerator.generateParenthesisToken(
								')',
								position,
								lastOpenParenthesisToken!.depth,
								lastOpenParenthesisToken
							);

						lastOpenParenthesisToken!.matchingPairId = closeParenthesisToken.id;

						tokenMap[logicId].push(closeParenthesisToken);

						position = posAfterCloseParenthesis;
					}
				}
				break;
			}
		}
	} else if (typeof logic === 'number') {
		content.push(generateTextNode(logic.toString(), position));

		// Add Number Token to editor state
		const { token: numberToken, nextPos: posAfterNumber } = tokenGenerator.generateNumberToken(
			logic,
			position
		);

		tokenMap[logicId].push(numberToken);

		position = posAfterNumber;
	}

	return { content, nextPosition: position };
};

// =====================================================================================================
// ======================================== Variable Editor helpers =====================================
// =====================================================================================================

function calculateParenDepth(openParens: ParenthesisToken[]) {
	let depth = 0;

	for (const paren of openParens) {
		if (!paren.matchingPairId) {
			depth++;
		}
	}

	return depth;
}

function getLastOpenParenthesisWithNoMatchingPairId(openParens: ParenthesisToken[]) {
	return [...openParens].reverse().find(p => p.matchingPairId === null);
}

function generateStartingIfContentAndState(
	content: SerializedContentWithPositions[],
	tokenMap: TokenMap,
	logicId: string,
	position: number,
	hasPlaceholders = false
) {
	content.push(
		hasPlaceholders ? generateTextNode('IF(', position) : generateTextNode('IF( ', position)
	);

	// Add IF Keyword Token to editor state
	const { token: keywordToken, nextPos: posAfterKeyword } = tokenGenerator.generateKeywordToken(
		'IF',
		position
	);

	// Add Open Parenthesis Token to editor state
	const { token: openParenthesisToken, nextPos: posAfterParen } =
		tokenGenerator.generateParenthesisToken('(', posAfterKeyword, 0);

	tokenMap[logicId].push(keywordToken);
	tokenMap[logicId].push(openParenthesisToken);

	if (hasPlaceholders) {
		position = posAfterParen;
	} else {
		position = posAfterParen + 1;
	}

	return position;
}

function generateEndingIfContentAndState(
	content: SerializedContentWithPositions[],
	editorState: TokenMap,
	logicId: string,
	position: number,
	hasPlaceholders = false
) {
	content.push(
		hasPlaceholders
			? generateTextNode(') THEN VALUE IS ', position)
			: generateTextNode(' ) THEN VALUE IS ', position)
	);

	if (!hasPlaceholders) {
		// Increase position for whitespace character
		position = position + 1;
	}

	const openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, editorState);

	// Get the open parenthesis token that doesen't have a matching pair id of depth 0
	const openParenthesisTokenWithoutMatchingPairId =
		getLastOpenParenthesisWithNoMatchingPairId(openParenthesisTokens);

	// Add Close Parenthesis Token to editor state

	const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
		tokenGenerator.generateParenthesisToken(
			')',
			position,
			0,
			openParenthesisTokenWithoutMatchingPairId
		);

	openParenthesisTokenWithoutMatchingPairId!.matchingPairId = closeParenthesisToken.id;

	editorState[logicId].push(closeParenthesisToken);

	// Increase position for whitespace character
	position = posAfterCloseParenthesis + 1;

	// Add THEN VALUE IS Keyword Token to editor state
	const { token: thenKeywordToken, nextPos: posAfterThenKeyword } =
		tokenGenerator.generateKeywordToken('THEN VALUE IS', position);

	editorState[logicId].push(thenKeywordToken);

	return posAfterThenKeyword + 1;
}

function generateLogicalOperatorContentAndState(
	content: SerializedContentWithPositions[],
	tokenMap: TokenMap,
	logicId: string,
	position: number,
	key: string,
	hasNestedExpressionInFirstOperand = false,
	hasNestedExpressionInSecondOperand = false,
	hasPlaceholders = false
) {
	if (hasNestedExpressionInFirstOperand && hasNestedExpressionInSecondOperand) {
		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, position));
			tokenMap[logicId].push(tokenGenerator.generatePlaceholderToken(id, position).token);

			// Increase position for placeholder
			position = position + 1;
		}

		content.push(
			hasPlaceholders
				? generateTextNode(`) ${key} (`, position)
				: generateTextNode(` ) ${key} ( `, position)
		);

		if (!hasPlaceholders) {
			// Increase position for whitespace character
			position = position + 1;
		}

		const openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

		//Get last open parenthesis token
		const lastOpenParenthesisToken =
			getLastOpenParenthesisWithNoMatchingPairId(openParenthesisTokens);

		// Add Close Parenthesis Token to editor state
		const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
			tokenGenerator.generateParenthesisToken(
				')',
				position,
				lastOpenParenthesisToken!.depth,
				lastOpenParenthesisToken
			);

		tokenMap[logicId].push(closeParenthesisToken);

		lastOpenParenthesisToken!.matchingPairId = closeParenthesisToken.id;

		// Increase position for whitespace character
		position = posAfterCloseParenthesis + 1;

		// const openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

		// Generate Operator Token
		const { token: operatorToken, nextPos: posAfterOperator } =
			tokenGenerator.generateOperatorToken(
				key as ArithmeticOperator | ComparisonOperator,
				position,
				calculateParenDepth(openParenthesisTokens)
			);

		tokenMap[logicId].push(operatorToken);

		// Increase position for whitespace character
		position = posAfterOperator + 1;

		// Add Open Parenthesis Token to editor state
		const { token: openParenthesisToken, nextPos: posAfterParen } =
			tokenGenerator.generateParenthesisToken(
				'(',
				position,
				calculateParenDepth(openParenthesisTokens)
			);

		tokenMap[logicId].push(openParenthesisToken);

		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, posAfterParen));
			tokenMap[logicId].push(
				tokenGenerator.generatePlaceholderToken(id, posAfterParen).token
			);
		}

		// Increase position for whitespace character or placeholder
		position = posAfterParen + 1;
	} else if (hasNestedExpressionInFirstOperand && !hasNestedExpressionInSecondOperand) {
		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, position));
			tokenMap[logicId].push(tokenGenerator.generatePlaceholderToken(id, position).token);

			// Increase position for placeholder
			position = position + 1;
		}

		content.push(
			hasPlaceholders
				? generateTextNode(`) ${key}`, position)
				: generateTextNode(` ) ${key} `, position)
		);

		if (!hasPlaceholders) {
			// Increase position for whitespace character
			position = position + 1;
		}

		const openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

		//Get last open parenthesis token
		const lastOpenParenthesisToken =
			getLastOpenParenthesisWithNoMatchingPairId(openParenthesisTokens);

		// Add Close Parenthesis Token to editor state
		const { token: closeParenthesisToken, nextPos: posAfterCloseParenthesis } =
			tokenGenerator.generateParenthesisToken(
				')',
				position,
				lastOpenParenthesisToken!.depth,
				lastOpenParenthesisToken
			);

		tokenMap[logicId].push(closeParenthesisToken);

		lastOpenParenthesisToken!.matchingPairId = closeParenthesisToken.id;

		// Increase position for whitespace character
		position = posAfterCloseParenthesis + 1;

		// Generate Operator Token
		const { token: operatorToken, nextPos: posAfterOperator } =
			tokenGenerator.generateOperatorToken(
				key as ArithmeticOperator | ComparisonOperator,
				position,
				calculateParenDepth(openParenthesisTokens)
			);

		tokenMap[logicId].push(operatorToken);

		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, posAfterOperator));
			tokenMap[logicId].push(
				tokenGenerator.generatePlaceholderToken(id, posAfterOperator).token
			);
		}

		// Increase position for whitespace character or placeholder
		position = posAfterOperator + 1;
	} else if (!hasNestedExpressionInFirstOperand && hasNestedExpressionInSecondOperand) {
		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, position));
			tokenMap[logicId].push(tokenGenerator.generatePlaceholderToken(id, position).token);

			// Increase position for placeholder
			position = position + 1;
		}

		content.push(
			hasPlaceholders
				? generateTextNode(`${key} (`, position)
				: generateTextNode(` ${key} ( `, position)
		);

		if (!hasPlaceholders) {
			// Increase position for whitespace character
			position = position + 1;
		}

		const openParentesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

		// Generate Operator Token
		const { token: operatorToken, nextPos: posAfterOperator } =
			tokenGenerator.generateOperatorToken(
				key as ArithmeticOperator | ComparisonOperator,
				position,
				calculateParenDepth(openParentesisTokens)
			);

		tokenMap[logicId].push(operatorToken);

		// Increase position for whitespace character
		position = posAfterOperator + 1;

		// Add Open Parenthesis Token to editor state
		const { token: openParenthesisToken, nextPos: posAfterParen } =
			tokenGenerator.generateParenthesisToken(
				'(',
				position,
				calculateParenDepth(openParentesisTokens)
			);

		tokenMap[logicId].push(openParenthesisToken);

		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, posAfterParen));
			tokenMap[logicId].push(
				tokenGenerator.generatePlaceholderToken(id, posAfterParen).token
			);
		}

		// Increase position for whitespace character or placeholder
		position = posAfterParen + 1;
	} else {
		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, position));
			tokenMap[logicId].push(tokenGenerator.generatePlaceholderToken(id, position).token);

			// Increase position for placeholder
			position = position + 1;
		}

		content.push(
			hasPlaceholders
				? generateTextNode(`${key}`, position)
				: generateTextNode(` ${key} `, position)
		);

		if (!hasPlaceholders) {
			// Increase position for whitespace character
			position = position + 1;
		}

		const openParenthesisTokens = getOpenParenthesisOfIdFromMap(logicId, tokenMap);

		// Generate Operator Token
		const { token: operatorToken, nextPos: posAfterOperator } =
			tokenGenerator.generateOperatorToken(
				key as ArithmeticOperator | ComparisonOperator,
				position,
				calculateParenDepth(openParenthesisTokens)
			);

		tokenMap[logicId].push(operatorToken);

		if (hasPlaceholders) {
			const id = 'placeholder_' + generate();

			content.push(generatePlaceholderTextNode(id, posAfterOperator));
			tokenMap[logicId].push(
				tokenGenerator.generatePlaceholderToken(id, posAfterOperator).token
			);
		}

		// Increase position for whitespace character or placeholder
		position = posAfterOperator + 1;
	}

	return position;
}

function generateCategoryContentAndState(
	content: SerializedContentWithPositions[],
	tokenMap: TokenMap,
	logicId: string,
	position: number,
	categoryValue: string
) {
	content.push(generateTextNode(categoryValue, position));

	// Add Category Token to editor state
	const { token: categoryToken, nextPos: posAfterCategory } =
		tokenGenerator.generateCategoryToken(categoryValue, position);

	tokenMap[logicId].push(categoryToken);

	return posAfterCategory;
}

function generateCategoryPlaceholderContentAndState(
	content: SerializedContentWithPositions[],
	tokenMap: TokenMap,
	logicId: string,
	position: number
) {
	const id = 'placeholder_' + generate();

	content.push(generatePlaceholderTextNode(id, position));
	tokenMap[logicId].push(tokenGenerator.generatePlaceholderToken(id, position).token);

	// Increase position for placeholder
	position = position + 1;

	return position;
}

// =====================================================================================================
// ======================================== Variable modal helpers =====================================
// =====================================================================================================

export function generateEmptyIFContentAndState(
	categoryValue: string | null = null,
	previousDoc: SerializedDocWithPositions | null = null,
	tokenMap: TokenMap,
	currentLogicId: string,
	contentMap: ContentMap,
	lastLogicId?: string
) {
	const {
		doc: newDoc,
		tokenMap: updatedTokenMap,
		contentMap: updatedContentMap
	} = getInitialContent(previousDoc, tokenMap, currentLogicId, contentMap, lastLogicId);

	const content: SerializedContentWithPositions[] = [];

	let position = lastLogicId ? getLastPositionOfLogic(lastLogicId, contentMap) + 1 : 0;
	let startPosition = 0;
	let endPosition = 0;

	if (newDoc.content !== undefined) {
		startPosition = position;

		// Increase position for opening
		position++;

		position = generateStartingIfContentAndState(
			content,
			updatedTokenMap,
			currentLogicId,
			position,
			true
		);

		position = generateLogicalOperatorContentAndState(
			content,
			updatedTokenMap,
			currentLogicId,
			position,
			ComparisonOperator.GreaterThan,
			false,
			false,
			true
		);

		position = generateEndingIfContentAndState(
			content,
			updatedTokenMap,
			currentLogicId,
			position,
			true
		);

		if (categoryValue) {
			endPosition = generateCategoryContentAndState(
				content,
				updatedTokenMap,
				currentLogicId,
				position,
				categoryValue
			);
		} else {
			endPosition = generateCategoryPlaceholderContentAndState(
				content,
				updatedTokenMap,
				currentLogicId,
				position
			);
		}

		const mergedContent = generateMergeContent(content);

		// Increase position for closing paragraph tag
		endPosition++;

		newDoc.content.push({
			type: 'paragraph',
			attrs: {
				nodeIndent: null,
				style: '',
				spellcheck: 'false'
			},
			content: mergedContent,
			pos: {
				start: startPosition,

				end: endPosition
			}
		});

		newDoc.pos.end = endPosition;
	}

	updatedContentMap[currentLogicId] = {
		startPosition: startPosition + 1,
		endPosition: endPosition - 1
	};

	return {
		content: newDoc,
		tokenMap: updatedTokenMap,
		contentMap: updatedContentMap
	};
}

export function computeEditorPositionByPath(path: string, state?: GeneralTokenizerState) {
	const pathParts = path.split('.');

	let position = 0;

	const initialIndex = Number(pathParts.shift());

	if (state === undefined) {
		return position;
	}

	if (!isNaN(initialIndex)) {
		position = state.keywordState.activeKeywords[initialIndex].pos.end + 1;
	}

	for (const part of pathParts) {
		const maybeNumber = Number(part);

		if (!isNaN(maybeNumber) && pathParts.length > 1) {
			// we have the index to the logical Node
			// we continue to the next part
			continue;
		}

		if (isNaN(maybeNumber)) {
			// Check if we have a logical operator
			const operator = Object.values(ComparisonOperator).find(
				op => op.toLowerCase() === part.toLowerCase()
			);

			if (operator && pathParts.length === 1) {
				// we have a logical node with no nested expression
				// Find the the last part is pointing to the first or second operand
				const operandIndex = pathParts[pathParts.length - 1] === '0' ? 0 : 1;

				// find the operator token
				const operatorToken = state.operatorState.activeOperators.find(
					op => op.value.toLowerCase() === operator.toLowerCase()
				);

				if (!operatorToken) {
					return position;
				}

				if (operandIndex == 0) {
					position = operatorToken.pos.start - 1;
				} else {
					position = operatorToken.pos.end + 1;
				}
			}
		}
	}

	return position;
}

function getInitialContent(
	previousContent: SerializedDocWithPositions | null,
	tokenMap: TokenMap,
	currentLogicId: string,
	contentMap: ContentMap,
	lastLogicId?: string
): {
	doc: SerializedDocWithPositions;
	tokenMap: TokenMap;
	contentMap: ContentMap;
} {
	if (previousContent && lastLogicId) {
		const { updatedDoc, updatedTokenMap, updatedContentMap } =
			getInitialContentFromPreviousContent(
				previousContent,
				tokenMap,
				contentMap,
				lastLogicId
			);

		updatedTokenMap[currentLogicId] = [];
		return {
			doc: updatedDoc,
			tokenMap: updatedTokenMap,
			contentMap: updatedContentMap
		};
	}

	const newDoc: SerializedDocWithPositions = {
		type: 'doc',
		content: [],
		pos: {
			start: 0,
			end: 0
		}
	};

	tokenMap[currentLogicId] = [];

	return {
		doc: newDoc,
		tokenMap,
		contentMap: contentMap
	};
}

function getLastPositionOfLogic(logicId: string, contentMap: ContentMap) {
	const logicPositions = contentMap[logicId];

	if (!logicPositions) {
		return 0;
	}

	return logicPositions.endPosition;
}

function getInitialContentFromPreviousContent(
	doc: SerializedDocWithPositions,
	tokenMap: TokenMap,
	contentMap: ContentMap,
	lastLogicId: string
) {
	const {
		doc: updatedDoc,
		tokenMap: updatedTokenMap,
		contentMap: updatedContentMap
	} = addSeparatorToLastTextNode(doc, tokenMap, contentMap, lastLogicId);

	return {
		updatedDoc,
		updatedTokenMap,
		updatedContentMap
	};
}

function addSeparatorToLastTextNode(
	doc: SerializedDocWithPositions,
	tokenMap: TokenMap,
	contentMap: ContentMap,
	lastLogicId: string
) {
	if (doc.content && doc.content.length > 0) {
		const lastParagraphNode = doc.content[doc.content.length - 1];

		if (lastParagraphNode.content) {
			const lastTextNode = lastParagraphNode.content[lastParagraphNode.content.length - 1];

			if (lastTextNode.type === 'text' && lastTextNode.text) {
				const hasMark = lastTextNode.marks && lastTextNode.marks.length > 0;
				if (!hasMark) {
					lastTextNode.text = lastTextNode.text.trimEnd() + ' ,';

					// Increase end position of last text node to account for whitespace and char insertion
					lastTextNode.pos.end = lastTextNode.pos.end + 2;
				} else {
					const separatorTextNode = generateTextNode(' ,', lastTextNode.pos.end);

					lastParagraphNode.content.push(separatorTextNode);
				}

				// Add Separator Token to editor state
				const { token: separatorToken } = tokenGenerator.generateSeparatorToken(
					',',
					!hasMark ? lastTextNode.pos.end - 1 : lastTextNode.pos.end + 1
				);

				tokenMap[lastLogicId].push(separatorToken);
				contentMap[lastLogicId].endPosition = separatorToken.pos.end;

				// set end position of paragraph node to account for whitespace and char insertion
				lastParagraphNode.pos.end = lastParagraphNode.pos.end + 2;

				return {
					doc,
					tokenMap,
					contentMap
				};
			}
		}
	}

	return {
		doc,
		tokenMap,
		contentMap
	};
}
