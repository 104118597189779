import { useSelector } from 'hooks/utils';
import {
	selectFiltersById,
	selectFilterIdsByVariableName,
	selectActiveFilterIds
} from 'store/data/filters';

export function useActiveFiltersByVariableName(name: string) {
	const byId = useSelector(state => selectFiltersById(state.data.filters));
	const ids = useSelector(state => selectFilterIdsByVariableName(state.data.filters, name));
	const activeIds = useSelector(state => selectActiveFilterIds(state.data.filters));

	return activeIds.filter(id => ids.includes(id)).map(id => byId[id]);
}
