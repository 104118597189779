import { useMemo } from 'react';
import { isEqual } from 'lodash';
import produce from 'immer';

import {
	TwoWayManovaResultsV1,
	TwoWayManovaResultsDataV1,
	CompareNumericAnalysisV1
} from 'api/data/analyses';
import { UPDATE_DEBOUNCE_TIME } from 'consts';
import { Svgs } from 'environment';
import { VariablesMap, VariablesData } from 'store/data/variables';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUpdateAnalysis } from 'hooks/store';
import { useMutableState, useDebounce } from 'hooks/utils';

type StatisticName = 'pillaisTrace' | 'hotellingTrace' | 'roysRoot' | 'wilksLamda';
type StatisticNames = StatisticName[];

interface Props {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
	results: TwoWayManovaResultsV1;
}

export function CompareNumericTwoWayManovaV1({ analysis, variablesData, results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title marginOffset={{ bottom: 1.6 }}>
				{translate(({ analysis }) => analysis.statistics.twoWayManova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translate(({ errors }) => errors.api.analyses.statistics.twoWayManova)}
				</AnalysisStatistic.Error>
			)}

			{data && (
				<Result
					analysis={analysis}
					variablesData={variablesData}
					data={data}
					error={error}
				/>
			)}
		</AnalysisStatistic>
	);
}

function Result({
	analysis,
	variablesData,
	data,
	error
}: {
	analysis: CompareNumericAnalysisV1;
	variablesData: VariablesData;
	data: TwoWayManovaResultsDataV1;
	error?: boolean;
}) {
	const updateAnalysis = useUpdateAnalysis();

	const [draftExpanded, setDraftExpanded] = useMutableState(data.expanded);

	useDebounce(
		() => {
			const hasChanges = !isEqual(draftExpanded, data.expanded);

			if (!hasChanges) return;

			const updatedAnalysis = produce(analysis, draft => {
				if (draft.output.statistics.twoWayManova.data) {
					draft.output.statistics.twoWayManova.data.expanded = draftExpanded;
				}
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[draftExpanded],
		UPDATE_DEBOUNCE_TIME
	);

	const { variablesMap } = variablesData;

	const statistics = useMemo(() => {
		if (error) {
			return {
				byName: {
					pillaisTrace: [],
					hotellingTrace: [],
					roysRoot: [],
					wilksLamda: []
				},
				names: []
			};
		}

		const { pillaisTrace, hotellingTrace, roysRoot, wilksLamda } = getStatistics(data, {
			variablesMap
		});

		return {
			byName: {
				pillaisTrace: [pillaisTrace.first, pillaisTrace.second, pillaisTrace.mixed],
				hotellingTrace: [hotellingTrace.first, hotellingTrace.second, hotellingTrace.mixed],
				roysRoot: [roysRoot.first, roysRoot.second, roysRoot.mixed],
				wilksLamda: [wilksLamda.first, wilksLamda.second, wilksLamda.mixed]
			},
			names: ['pillaisTrace', 'hotellingTrace', 'roysRoot', 'wilksLamda'] as StatisticNames
		};
	}, [data, variablesMap, error]);

	const statisticLabelsMap = {
		pillaisTrace: `Pillai's trace`,
		hotellingTrace: `Hotelling's trace`,
		roysRoot: `Roy's largest root`,
		wilksLamda: `Wilks' Lambda`
	};

	return (
		<Gap marginGap={{ bottom: 1.6 }} notLastChild>
			{statistics.names.map(name => {
				const isVisible = draftExpanded[name];
				const label = statisticLabelsMap[name];
				const statistic = statistics.byName[name];

				return (
					<Flex key={name} column>
						{/* EXPANDABLE TOGGLE */}
						<Flex marginOffset={{ bottom: isVisible ? 0.8 : undefined }}>
							<Icon
								svg={isVisible ? Svgs.ArrowDown : Svgs.ArrowRight}
								size={s => s.l}
								marginOffset={{ right: 0.8 }}
								onClick={() =>
									setDraftExpanded(state => {
										state[name] = !state[name];
									})
								}
							/>
							<Typography.Paragraph
								fontweight={w => w.bold}
								onClick={() =>
									setDraftExpanded(state => {
										state[name] = !state[name];
									})
								}
								clickable
							>
								{label}
							</Typography.Paragraph>
						</Flex>

						{isVisible && (
							<Table.Responsive horizontalScroll fullWidth={false}>
								<Table>
									<Table.Body>
										{statistic.map(
											({
												label,
												fStatistic,
												pStatistic,
												lambdaStatistic
											}) => (
												<Table.Row key={`${name}_${label}`}>
													<Table.Cell title={label} width={28} noWrap>
														{label}
													</Table.Cell>
													<Table.Cell
														title={getFStatistic(fStatistic).text}
														width={14}
														noWrap
													>
														{getFStatistic(fStatistic).JSX}
													</Table.Cell>
													<Table.Cell
														title={getPStatistic(pStatistic).text}
														width={14}
														noWrap
													>
														{getPStatistic(pStatistic).JSX}
													</Table.Cell>
													<Table.Cell
														title={getLambdaStatistic(lambdaStatistic)}
														width={14}
														noWrap
													>
														{getLambdaStatistic(lambdaStatistic)}
													</Table.Cell>
												</Table.Row>
											)
										)}
									</Table.Body>
								</Table>
							</Table.Responsive>
						)}
					</Flex>
				);
			})}
		</Gap>
	);
}

function getStatistics(
	data: TwoWayManovaResultsDataV1,
	{ variablesMap }: { variablesMap: VariablesMap }
) {
	const keys = extractKeys(data);

	const firstStatisticValues = data.dynamic[keys.first];
	const secondStatisticValues = data.dynamic[keys.second];
	const mixedStatisticValues = data.dynamic[keys.mixed];

	const firstStatisticLabel = variablesMap[keys.first].label;
	const secondStatisticLabel = variablesMap[keys.second].label;
	const mixedStatisticLabel = `${firstStatisticLabel} * ${secondStatisticLabel}`;

	// TODO: FIND A CLEANER WAY TO BUILD THIS
	const statistics = {
		pillaisTrace: {
			first: {
				label: firstStatisticLabel,
				fStatistic: {
					hypothesisDf: firstStatisticValues.pillaisTrace.hypothesisDf,
					erroDF: firstStatisticValues.pillaisTrace.erroDF,
					f: firstStatisticValues.pillaisTrace.f
				},
				pStatistic: {
					pValue: firstStatisticValues.pillaisTrace.pValue
				},
				lambdaStatistic: {
					value: firstStatisticValues.pillaisTrace.value
				}
			},
			second: {
				label: secondStatisticLabel,
				fStatistic: {
					hypothesisDf: secondStatisticValues.pillaisTrace.hypothesisDf,
					erroDF: secondStatisticValues.pillaisTrace.erroDF,
					f: secondStatisticValues.pillaisTrace.f
				},
				pStatistic: {
					pValue: secondStatisticValues.pillaisTrace.pValue
				},
				lambdaStatistic: {
					value: secondStatisticValues.pillaisTrace.value
				}
			},
			mixed: {
				label: mixedStatisticLabel,
				fStatistic: {
					hypothesisDf: mixedStatisticValues.pillaisTrace.hypothesisDf,
					erroDF: mixedStatisticValues.pillaisTrace.erroDF,
					f: mixedStatisticValues.pillaisTrace.f
				},
				pStatistic: {
					pValue: mixedStatisticValues.pillaisTrace.pValue
				},
				lambdaStatistic: {
					value: mixedStatisticValues.pillaisTrace.value
				}
			}
		},
		hotellingTrace: {
			first: {
				label: firstStatisticLabel,
				fStatistic: {
					hypothesisDf: firstStatisticValues.hotellingTrace.hypothesisDf,
					erroDF: firstStatisticValues.hotellingTrace.erroDF,
					f: firstStatisticValues.hotellingTrace.f
				},
				pStatistic: {
					pValue: firstStatisticValues.hotellingTrace.pValue
				},
				lambdaStatistic: {
					value: firstStatisticValues.hotellingTrace.value
				}
			},
			second: {
				label: secondStatisticLabel,
				fStatistic: {
					hypothesisDf: secondStatisticValues.hotellingTrace.hypothesisDf,
					erroDF: secondStatisticValues.hotellingTrace.erroDF,
					f: secondStatisticValues.hotellingTrace.f
				},
				pStatistic: {
					pValue: secondStatisticValues.hotellingTrace.pValue
				},
				lambdaStatistic: {
					value: secondStatisticValues.hotellingTrace.value
				}
			},
			mixed: {
				label: mixedStatisticLabel,
				fStatistic: {
					hypothesisDf: mixedStatisticValues.hotellingTrace.hypothesisDf,
					erroDF: mixedStatisticValues.hotellingTrace.erroDF,
					f: mixedStatisticValues.hotellingTrace.f
				},
				pStatistic: {
					pValue: mixedStatisticValues.hotellingTrace.pValue
				},
				lambdaStatistic: {
					value: mixedStatisticValues.hotellingTrace.value
				}
			}
		},
		roysRoot: {
			first: {
				label: firstStatisticLabel,
				fStatistic: {
					hypothesisDf: firstStatisticValues.roysRoot.hypothesisDf,
					erroDF: firstStatisticValues.roysRoot.erroDF,
					f: firstStatisticValues.roysRoot.f
				},
				pStatistic: {
					pValue: firstStatisticValues.roysRoot.pValue
				},
				lambdaStatistic: {
					value: firstStatisticValues.roysRoot.value
				}
			},
			second: {
				label: secondStatisticLabel,
				fStatistic: {
					hypothesisDf: secondStatisticValues.roysRoot.hypothesisDf,
					erroDF: secondStatisticValues.roysRoot.erroDF,
					f: secondStatisticValues.roysRoot.f
				},
				pStatistic: {
					pValue: secondStatisticValues.roysRoot.pValue
				},
				lambdaStatistic: {
					value: secondStatisticValues.roysRoot.value
				}
			},
			mixed: {
				label: mixedStatisticLabel,
				fStatistic: {
					hypothesisDf: mixedStatisticValues.roysRoot.hypothesisDf,
					erroDF: mixedStatisticValues.roysRoot.erroDF,
					f: mixedStatisticValues.roysRoot.f
				},
				pStatistic: {
					pValue: mixedStatisticValues.roysRoot.pValue
				},
				lambdaStatistic: {
					value: mixedStatisticValues.roysRoot.value
				}
			}
		},
		wilksLamda: {
			first: {
				label: firstStatisticLabel,
				fStatistic: {
					hypothesisDf: firstStatisticValues.wilksLamda.hypothesisDf,
					erroDF: firstStatisticValues.wilksLamda.erroDF,
					f: firstStatisticValues.wilksLamda.f
				},
				pStatistic: {
					pValue: firstStatisticValues.wilksLamda.pValue
				},
				lambdaStatistic: {
					value: firstStatisticValues.wilksLamda.value
				}
			},
			second: {
				label: secondStatisticLabel,
				fStatistic: {
					hypothesisDf: secondStatisticValues.wilksLamda.hypothesisDf,
					erroDF: secondStatisticValues.wilksLamda.erroDF,
					f: secondStatisticValues.wilksLamda.f
				},
				pStatistic: {
					pValue: secondStatisticValues.wilksLamda.pValue
				},
				lambdaStatistic: {
					value: secondStatisticValues.wilksLamda.value
				}
			},
			mixed: {
				label: mixedStatisticLabel,
				fStatistic: {
					hypothesisDf: mixedStatisticValues.wilksLamda.hypothesisDf,
					erroDF: mixedStatisticValues.wilksLamda.erroDF,
					f: mixedStatisticValues.wilksLamda.f
				},
				pStatistic: {
					pValue: mixedStatisticValues.wilksLamda.pValue
				},
				lambdaStatistic: {
					value: mixedStatisticValues.wilksLamda.value
				}
			}
		}
	};

	return statistics;
}

function extractKeys(data: TwoWayManovaResultsDataV1) {
	const rawKeys = Object.keys(data.dynamic);

	const parsedKeys = {
		first: '',
		second: '',
		mixed: ''
	};

	rawKeys.forEach(rawKey => {
		const isMixed = rawKey.includes(':');

		if (isMixed) {
			const [firstKey, secondKey] = rawKey.split(':');

			if (firstKey && secondKey) {
				parsedKeys.first = firstKey;
				parsedKeys.second = secondKey;
				parsedKeys.mixed = rawKey;
			}
		}
	});

	return parsedKeys;
}

function getFStatistic(input: { hypothesisDf: number; erroDF: number; f: number }) {
	const { hypothesisDf, erroDF, f } = input;

	const { value: parsedF } = parseAnalysisNumber(f);

	const output = {
		JSX: (
			<>
				<i>{`F`}</i>
				{`(${hypothesisDf}, ${erroDF}) = ${parsedF}`}
			</>
		),
		text: `F(${hypothesisDf}, ${erroDF}) = ${parsedF}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}

function getLambdaStatistic(input: { value: number }) {
	const { value } = input;

	const { operator, value: parsedValue } = parseAnalysisNumber(value);

	return `λ ${operator} ${parsedValue}`;
}
