import { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import { nanoid as generate } from 'nanoid';
import { KaplanMeierResults } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { NoPlot } from '../../NoPlot';
import {
	useAnalyses,
	useAnalysesActiveColum,
	useAnalysisContext,
	useFilters,
	useFullscreenAnalysis,
	useToggleAnalysisChartPlot,
	useTranslation
} from 'hooks/store';
import { AnalysisContainer } from '../../UI';
import { computeKaplanChartData as computeData } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	name: string;
	dataset: KaplanMeierResults;
	confidenceIntervals: boolean;
	isLegendEnabled: boolean;
	legendHeader?: string;
	scalesLabels: ScalesLabels;
	isForExport?: boolean;
	loading: boolean;
}

function Component({
	id,
	name,
	dataset,
	confidenceIntervals,
	isLegendEnabled,
	legendHeader,
	scalesLabels,
	isForExport,
	loading
}: Props) {
	const ref = useRef<ZingChart>(null);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumns] = useAnalysesActiveColum();
	const windowSize = useWindowSize();
	const { translate } = useTranslation();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();

	const { series, options, disableAnimations } = useMemo(
		() =>
			computeData(
				name,
				dataset,
				confidenceIntervals,
				isLegendEnabled,
				scalesLabels,
				activeColumns,
				!!fullscreen,
				plots,
				legendHeader
			),
		[
			name,
			dataset,
			confidenceIntervals,
			isLegendEnabled,
			scalesLabels,
			legendHeader,
			activeColumns,
			plots
		]
	);

	const renderId = useMemo(generate, [
		series,
		fullscreen,
		activeColumns,
		windowSize,
		analysisIds,
		filters
	]);

	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `range-${e.plotindex}` });
		}
	}

	if (isEmpty(dataset) && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	return (
		<Chart
			key={renderId}
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			disableAnimations={disableAnimations}
			effect={e => e.ExpandVertical}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}

export const KaplanChart = withMemo(Component);
