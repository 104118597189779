import { UserDetails } from 'api/data/accounts';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { UserCard } from 'components/UI/UserCard';
import { InvitedUser } from 'store/data/accounts';
import { EligibleInvitedByEmail, EligibleUserForInvite } from '..';
import { Title, WithStripes } from '../styles';
import { useTranslation, useInviteUser } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';
import { Error } from 'components/UI/Error';

interface Props {
	newUsersData: UserDetails[];
	newInvitedUsers: InvitedUser[];
	confirmedUsers: string[];
	eligibleForInviteEmails: string[];
	termsOfUse: boolean;
	handleTermsOfUse: () => void;
	handleConfirmUser: (email: string) => void;
	notAllowedDueToDomainConstraintViolation: string[];
}

export function ConfirmCollaborators({
	newUsersData,
	newInvitedUsers,
	confirmedUsers,
	eligibleForInviteEmails,
	termsOfUse,
	handleTermsOfUse,
	handleConfirmUser,
	notAllowedDueToDomainConstraintViolation
}: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [{ loading: invitingUser, error: errorInvitingUser }, inviteUser] = useInviteUser();

	useCompletedAction(invitingUser, errorInvitingUser, () =>
		setNotification({
			message: translate(
				({ sharingConfirmNewMembers }) => sharingConfirmNewMembers.userInvitedNotification
			)
		})
	);

	const areUsersToInvite = eligibleForInviteEmails && eligibleForInviteEmails.length > 0;

	const thereAreUsersToInvite = newUsersData.length > 0 || newInvitedUsers.length > 0;

	return (
		<>
			<Title>{translate(({ collaborators }) => collaborators.add.title.confirmStep)}</Title>

			{/* INVITE USERS TO LEDIDI */}
			{areUsersToInvite && (
				<>
					<Typography.Paragraph fontweight={w => w.bold}>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteUserToLedidPlatform
						)}
					</Typography.Paragraph>

					<Spacer size={s => s.xs} />

					{eligibleForInviteEmails.map(emailAddress => (
						<EligibleUserForInvite
							key={emailAddress}
							emailAddress={emailAddress}
							disableInvite={invitingUser}
							onInvite={inviteUser}
						/>
					))}

					<Spacer size={s => s.m} />
				</>
			)}

			{/* CONFIRM LEDIDI USERS */}
			{(newUsersData.length > 0 || newInvitedUsers.length > 0) && (
				<>
					<Typography.Paragraph fontweight={w => w.bold}>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.confirmToProject
						)}
					</Typography.Paragraph>

					<Spacer size={s => s.xs} />

					<WithStripes>
						{newUsersData.map(userData => (
							<UserCard.DropdownItem
								key={userData.userid}
								userData={userData}
								checked={confirmedUsers.includes(userData.emailAddress)}
								onCheck={() => handleConfirmUser(userData.emailAddress)}
							/>
						))}
						{newInvitedUsers.map(invitedUser => (
							<EligibleInvitedByEmail
								key={invitedUser.email}
								checked={confirmedUsers.includes(invitedUser.email)}
								emailAddress={invitedUser.email}
								handleCheck={handleConfirmUser}
							/>
						))}
					</WithStripes>
				</>
			)}

			{notAllowedDueToDomainConstraintViolation.length > 0 && (
				<>
					<Spacer size={s => s.l} />

					<Error
						dataTestid="notAllowedDueToDomainConstraintViolationError"
						message={translate(
							dict => {
								return dict.sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolationError;
							},
							false,
							{
								emails: notAllowedDueToDomainConstraintViolation.join(', ')
							}
						)}
					/>
				</>
			)}

			{areUsersToInvite && (
				<>
					<Spacer size={s => s.l} />

					<Typography.Caption multiline>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteNewUserParagraph1
						)}
						<Spacer size={s => s.xs} />
						&#x2022; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteNewUserDot1
						)}
						<Spacer size={s => s.xs} />
						&#x2022; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteNewUserDot2
						)}
						<Spacer size={s => s.xs} />
						&#x2022; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteNewUserDot3
						)}
						<Spacer size={s => s.xs} />
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers.inviteNewUserParagraph2
						)}
					</Typography.Caption>
				</>
			)}

			<Spacer size={s => s.l} />

			{thereAreUsersToInvite && (
				<Checkbox
					label={translate(({ collaborators }) => collaborators.add.termsOfUse)}
					checked={termsOfUse}
					onClick={handleTermsOfUse}
					dataTestId="termsOfUseCheckbox"
				/>
			)}
		</>
	);
}
