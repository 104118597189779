import { CrosstabRow } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { useMemo } from 'react';
import { nanoid as generate } from 'nanoid';
import { ZingChartClickEvent } from 'zingchart-react';
import { computeCrosstabBarChartData as computeData } from 'helpers/analysis';

import {
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	rows: CrosstabRow[];
	categories: string[];
	isLegendEnabled: boolean;
	legendHeader?: string;
	scalesLabels: ScalesLabels;
	id?: string;
	stacked?: boolean;
	isForExport?: boolean;
	fullscreen: string;
	activeColumn: number;
}

export function CrosstabBarChart({
	rows,
	categories,
	isLegendEnabled,
	legendHeader,
	scalesLabels,
	id,
	stacked = false,
	isForExport,
	activeColumn,
	fullscreen
}: Props) {
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();

	const { series, options, disableAnimations } = computeData(
		rows,
		categories,
		stacked,
		isLegendEnabled,
		activeColumn,
		!!fullscreen,
		scalesLabels,
		plots,
		legendHeader
	);

	const renderId = useMemo(generate, [
		fullscreen,
		filters,
		activeColumn,
		windowSize,
		analysisIds
	]);

	function onTogglePlot(e: ZingChartClickEvent) {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}

	return (
		<Chart
			key={renderId}
			id={id}
			type={t => t.Bar}
			series={series}
			options={options}
			onLegendItemClick={onTogglePlot}
			disableAnimations={disableAnimations}
			effect={e => e.ExpandBottom}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
