import { LogisticRegressionAnalysis, Columns } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { useAnalysesActiveColum, useFullscreenAnalysis, useFetchAnalysis } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { VariablesData } from 'store/data/variables';
import { ActionTypes, VariablesDataSelectItems } from 'store/data/analyses';
import { MediaQueries } from 'environment';

import { LogisticRegressionConfig } from './LogisticRegressionConfig';
import { LogisticRegressionView } from './LogisticRegressionView';
import { AnalysisViewContainer } from '../UI';

import { InvalidAnalysis } from '..';
import { AnalysisContainer } from '../UI';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

interface Props {
	analysis: LogisticRegressionAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesData, variablesDataSelectItems, exporting }: Props) {
	const { loading } = useFetchAnalysis({
		actionType: ActionTypes.GET_LOGISTIC_REGRESSION,
		id: analysis.id
	});

	const analysisValidInput = isAnalysisInputValid(analysis);
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();
	const noPositiveOutcome = analysis.input.variables.positiveEvent.length === 0;

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
				{noPositiveOutcome ? (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<InvalidAnalysis noPositiveOutcome />
					</AnalysisViewContainer>
				) : analysis.fetched && !loading ? (
					<LogisticRegressionView
						analysis={analysis}
						exporting={exporting}
						loading={loading}
						variablesDataSelectItems={variablesDataSelectItems}
						variablesData={variablesData}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}
				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<LogisticRegressionConfig
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
						variablesData={variablesData}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis category numeric />
		</AnalysisContainer>
	);
}

export const LogisticRegression = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
