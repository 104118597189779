import { CrosstabResults } from 'api/data/analyses';
import { decodeURIComponentSafe } from 'helpers/generic';

import { computeCrosstabTotalValues } from './computeCrosstabTotalValues';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';

export function exportCrosstab(
	dataset: CrosstabResults,
	{ translate }: { translate: TranslateFunction }
) {
	const totalColumnName = translate(({ analysis }) => analysis.analyses.crosstab.table.total);
	const totalRowsName = translate(({ analysis }) => analysis.analyses.crosstab.table.total);

	const {
		rows: { rows: yRows, label: rowsLabel },
		columns: {
			categoryLabels: columns,
			categoryTotals: totals
			// label: columnsLabel // TODO: enable as well
		}
	} = dataset;

	const rows: string[][] = [[decodeURIComponentSafe(rowsLabel)]];

	// Append the columns to the first row
	columns.forEach(column => rows[0].push(column));
	// Append the total column
	rows[0].push(totalColumnName);
	// Create the other rows
	yRows.forEach(row => rows.push([row.label]));
	// Create the total row
	rows.push([totalRowsName]);

	// Add the data cells
	const totalValues = computeCrosstabTotalValues(yRows, totals);
	yRows.forEach((row, index) => {
		row.valueDetails.forEach(value => rows[index + 1].push(`${value.N} (${value.percent})`));
		rows[index + 1].push(`${totalValues[index].toString()} (100%)`);
	});

	// Add the data cells for the total row
	totals.forEach(total =>
		rows[rows.length - 1].push(
			`${total} (${((total / totalValues[totalValues.length - 1]) * 100).toFixed(1)}%)`
		)
	);
	rows[rows.length - 1].push(`${totalValues[totalValues.length - 1].toString()} (100%)`);

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	rows.forEach(row => (csv += row.join(comma) + newLine));

	return csv;
}
