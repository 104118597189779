import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTransition } from 'react-spring';
import { ColumnInstance } from 'react-table';
import { Operator } from 'api/data/filters';
import { FilterIcon, Icon } from 'components/UI/Icons';
import { UPDATE_DEBOUNCE_TIME } from 'consts';
import { Colors, Svgs } from 'environment';
import { ProjectData, ProjectTableView } from 'store/data/projects';
import { Offset } from 'types/index';
import { SelectOperator } from 'components/Analysis/Filters/FilterList/Filter';
import { mapColumnNameToType } from '../ProjectsTable/helpers';
import { FilterStatuses } from './FilterStatuses';
import {
	CollapsibleCard,
	FadingWrapper,
	Inputs,
	Wrapper,
	Container,
	Floating
} from './Filter.style';
import { Input } from 'components/UI/Inputs/Input';
import { getProjectDefaultOperator } from 'helpers/filters';
import { getPositionWithinBounds } from 'helpers/generic';
import { useTranslation, useProjectsTableFilters } from 'hooks/store';
import { useUiStates, useProjectsFiltersHelpers } from 'hooks/ui';
import { useDebounce, useOutsideClick } from 'hooks/utils';

const PADDING = 40;
const DEFAULT_OFFSET = 20;

function computeLeftOffset(position: Offset | null) {
	let left = -DEFAULT_OFFSET;

	if (position && position.left) {
		left = -(position.left + DEFAULT_OFFSET + PADDING);
	}

	return left;
}

interface Props {
	tableRef: React.RefObject<HTMLTableElement>;
	column: ColumnInstance<ProjectData>;
	statuses: string[];
}

export function FilterColumn({ column, statuses, tableRef }: Props) {
	const { translate } = useTranslation();
	const [open, setOpen] = useState(true);
	const [mouseover, setMouseover] = useState(false);
	const [filtersData] = useProjectsTableFilters();
	const filterRef = useRef<HTMLDivElement>(null);
	const iconRef = useRef<HTMLImageElement>(null);
	const [visible, setVisible] = useState(false);
	const [{ focusState: focus }] = useUiStates();

	const [position, setPosition] = useState<Offset | null>(null);
	const [inView, setInview] = useState(true);
	const [computingPosition, setComputingPosition] = useState(true);

	useEffect(() => {
		if (computingPosition) {
			const bounds = getPositionWithinBounds(filterRef, tableRef);

			if (bounds) setPosition(bounds);

			setComputingPosition(false);
		}
	}, [computingPosition]);

	const isInputFocused = useRef(focus);

	const { setFilter } = column;

	const { inputType, shouldInvalidateFilter, validate, validationSchema } =
		useProjectsFiltersHelpers(mapColumnNameToType(column.id));

	const { values, touched, handleChange, setFieldValue, setFieldTouched } = useFormik({
		initialValues: Array.isArray(column.filterValue?.value)
			? {
					from: filtersData.filters.filter(filter => filter.id === column.id)[0]
						? filtersData.filters.filter(filter => filter.id === column.id)[0].value
								.value[0]
						: '',
					to: filtersData.filters.filter(filter => filter.id === column.id)[0]
						? filtersData.filters.filter(filter => filter.id === column.id)[0].value
								.value[1]
						: ''
			  }
			: {
					from: filtersData.filters.filter(filter => filter.id === column.id)[0]
						? filtersData.filters.filter(filter => filter.id === column.id)[0].value
								.value
						: '',
					to: ''
			  },
		validationSchema,
		validate,
		enableReinitialize: true,
		onSubmit: () => undefined
	});

	const [operator, setOperator] = useState(
		filtersData.filters.filter(filter => filter.id === column.id)[0]
			? filtersData.filters.filter(filter => filter.id === column.id)[0].value.operator
			: getProjectDefaultOperator(mapColumnNameToType(column.id))
	);

	const [initialOperator, setInitialOperator] = useState(
		filtersData.filters.filter(filter => filter.id === column.id)[0]
			? filtersData.filters.filter(filter => filter.id === column.id)[0].value.operator
			: getProjectDefaultOperator(mapColumnNameToType(column.id))
	);

	const transitions = useTransition(mouseover, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: 0 }
	});

	useDebounce(
		() => {
			const initialData = {
				values: Array.isArray(column.filterValue?.value)
					? {
							from: filtersData.filters.filter(filter => filter.id === column.id)[0]
								? filtersData.filters.filter(filter => filter.id === column.id)[0]
										.value.value[0]
								: '',
							to: filtersData.filters.filter(filter => filter.id === column.id)[0]
								? filtersData.filters.filter(filter => filter.id === column.id)[0]
										.value.value[1]
								: ''
					  }
					: {
							from: filtersData.filters.filter(filter => filter.id === column.id)[0]
								? filtersData.filters.filter(filter => filter.id === column.id)[0]
										.value.value
								: '',
							to: ''
					  },
				operator: initialOperator
			};

			const data = {
				values,
				operator
			};

			if (isEqual(initialData, data)) return;
			if (values.from === undefined || values.to === undefined) return;

			update(operator as Operator, values.from as string, values.to);
		},
		[values, operator],
		UPDATE_DEBOUNCE_TIME
	);

	function update(op: Operator, from: string, to: string) {
		if (shouldInvalidateFilter(op, from, to)) {
			setFilter({
				value: '',
				operator: op
			});
		} else {
			setFilter({
				value: operator === Operator.Between ? [from, to] : from,
				operator: op
			});
			if (initialOperator && !isEqual(initialOperator, operator)) {
				setInitialOperator(operator);
			}
		}
	}

	const isBetween = operator === Operator.Between;

	function handleToggle() {
		setOpen(state => !state);
	}

	function handleMouseEnter() {
		if (!mouseover) setMouseover(true);
	}

	function handleMouseLeave() {
		if (mouseover) setMouseover(false);
	}

	const isStatusFilter = column.id === ProjectTableView.status;
	const filterActive = column.filterValue && column.filterValue.value;

	const hideFilter = useCallback(() => {
		if (!isInputFocused.current) {
			setVisible(false);
		}
	}, [isInputFocused, setVisible]);

	useOutsideClick(hideFilter, [iconRef, filterRef]);

	function showFilter() {
		setVisible(true);
	}

	function onIconClick(e: React.MouseEvent) {
		setInview(e.clientX < window.innerWidth - 390);

		if (!visible) {
			showFilter();
		} else {
			hideFilter();
		}
	}
	const left = useMemo(() => computeLeftOffset(position), [position]);

	return (
		<Container>
			<FilterIcon
				className="column-filter-icon"
				ref={iconRef}
				active={filterActive}
				onClick={onIconClick}
				style={
					filterActive
						? {
								visibility: 'visible'
						  }
						: undefined
				}
			/>

			{visible && (
				<Floating
					ref={filterRef}
					style={{
						left: inView ? left : -307,
						opacity: 1,
						marginTop: '0.8rem'
					}}
				>
					<CollapsibleCard
						open={open}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						onToggle={handleToggle}
						title={column.id}
						actionComponent={transitions((props, item) => {
							if (item) {
								return (
									<FadingWrapper style={props}>
										<Icon
											svg={Svgs.Delete}
											colors={{
												color: Colors.text.disabled,
												hover: Colors.primary.normal
											}}
											onClick={() => {
												setFilter({
													value: '',
													operator: operator
												});
											}}
										/>
									</FadingWrapper>
								);
							}

							return null;
						})}
					>
						{isStatusFilter ? (
							<FilterStatuses
								column={column}
								statuses={statuses}
								operator={operator}
							/>
						) : (
							<>
								<SelectOperator
									selected={operator as Operator}
									type={mapColumnNameToType(column.id)}
									onSelect={setOperator}
								/>
								<Inputs>
									<Wrapper>
										<Input
											name={translate(
												({ filterInputs }) => filterInputs.fromLowerCase
											)}
											type={inputType}
											value={values.from as string}
											// error={errors.from}
											label={translate(({ filterInputs }) =>
												isBetween
													? filterInputs.fromUpperCase
													: filterInputs.value
											)}
											onChange={handleChange}
											onDateChange={({ formattedDate: from }) => {
												if (!touched.from) setFieldTouched('from');

												setFieldValue('from', from, true);
											}}
											onBlur={() => {
												if (!touched.from) setFieldTouched('from');
											}}
										/>
									</Wrapper>
									{isBetween && (
										<Wrapper>
											<Input
												name={translate(
													dict => dict.filterInputs.toLowerCase
												)}
												type={inputType}
												value={values.to}
												// error={errors.to}
												label={translate(
													dict => dict.filterInputs.toUpperCase
												)}
												onChange={handleChange}
												onDateChange={({ formattedDate: to }) => {
													if (!touched.to) setFieldTouched('to');

													setFieldValue('to', to, true);
												}}
												onBlur={() => {
													if (!touched.to) setFieldTouched('to');
												}}
											/>
										</Wrapper>
									)}
								</Inputs>
							</>
						)}
					</CollapsibleCard>
				</Floating>
			)}
		</Container>
	);
}
