import { KaplanMeierResults } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { CHART_COLORS } from 'consts';
import { useTranslation } from 'hooks/store';

import { AnalysisGroupedTables, AnalysisStatistic } from 'components/Analysis/Analyses/UI';

import { Circle } from './KaplanPatientsTable.style';
import { computeCellValue } from 'helpers/analysis';

interface Props {
	dataset: KaplanMeierResults;
}

export function KaplanPatientsTable({ dataset }: Props) {
	const { translate } = useTranslation();

	const grouping = Object.keys(dataset).length > 1;
	const tableColumnNames: string[] = [];
	const patientsTables = Object.entries(dataset).map(([key, value]) => {
		tableColumnNames.push(key);
		return value.numberAtRiskMatrix;
	});

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.analyses.kaplan.config.patientsAtRisk)}
			</AnalysisStatistic.Title>

			{patientsTables.map(({ timeline, numberAtRisk }, tableIndex) => (
				<AnalysisGroupedTables
					key={`table-${tableIndex}`}
					marginTop={tableIndex !== 0 ? 1 : undefined}
				>
					{grouping && (
						<Table maxWidth={15} fullWidth>
							<Table.Head>
								<Table.Row>
									<Table.Column
										title={tableColumnNames[tableIndex]}
										minWidth={10}
										maxWidth={10}
										noWrap
									>
										{tableColumnNames[tableIndex]}
									</Table.Column>
								</Table.Row>
							</Table.Head>
							<Table.Body>
								<Table.Row>
									<Table.Cell width={1.6} height={3.3}>
										<Circle color={CHART_COLORS[tableIndex]} />
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					)}
					<Table.Responsive horizontalScroll>
						<Table fullWidth>
							<Table.Head>
								<Table.Row>
									{timeline.map((item, index) => (
										<Table.Column
											key={`table-head-column-${index}`}
											minWidth={8}
										>
											{(item < 1 && item > 0) || item > 100000
												? computeCellValue(item)
												: item === 0
												? item.toFixed(0)
												: item.toFixed(2)}
										</Table.Column>
									))}
								</Table.Row>
							</Table.Head>
							<Table.Body>
								<Table.Row>
									{numberAtRisk.map((item, index) => (
										<Table.Cell key={`table-body-cell-${index}`} minWidth={8}>
											{item}
										</Table.Cell>
									))}
								</Table.Row>
							</Table.Body>
						</Table>
					</Table.Responsive>
				</AnalysisGroupedTables>
			))}
		</AnalysisStatistic>
	);
}
