import Papa from 'papaparse';
import { batch } from 'react-redux';
import format from 'date-fns/format';
import { getVariables, hydrateData } from 'store/data/variables';

import {
	type UploadProjectDatasetInput,
	type UpdateExistingDatasetInput,
	type StoreCsvInput,
	type Transaction,
	type SignDPAInput,
	type PreviewDPAInput,
	type GenerateVariableNamesInput,
	type ProjectsCollaboratorsAvatarData
} from 'api/data/projects';
import { createInitTransaction } from 'api/utils/helpers';
import type { DraggedFile, SuggestedVariableTypes } from 'api/data/projects';
import {
	DATE_TIME_TIMEZONE_FORMAT,
	FETCH_DEBOUNCE_TIME,
	SPECIAL_CHARACTERS,
	VAR_LABEL_PATTERN
} from 'consts';
import { Dictionary } from 'environment';
import { getSnapshots } from 'store/data/snapshots';
import { getEntries } from 'store/data/entries';
import { createActivity } from 'store/ui/activities';
import type { Thunk, ActionPayload } from 'store/types';
import { initiateJADBioProject } from 'store/addons/jadbio';
import { PromDistributionTypes, ImportType, SubscriptionAddonCode } from 'types/index';

import type {
	CreateProjectAction,
	GetCountsAction,
	GetProjectAction,
	GetProjectsAction,
	GetPromsAction,
	ResetCsvDataAction,
	SetActiveOwnershipAction,
	SetActiveStatusAction,
	SetProjectIdAction,
	SetProjectsSearchTermAction,
	SetProjectSortAction,
	UpdateProjectAction,
	CopyProjectAction,
	UploadProjectDatasetAction,
	UpdateExistingDatasetAction,
	UploadToExistingDatasetAction,
	GetImportDataTextAction,
	ResetProjectsFiltersAction,
	LeaveProjectAction,
	UpdateImportPercentageAction,
	StoreCsvContentAction,
	UpdateProjectOwnerPermissionsAction,
	CreateProject,
	UpdateProject,
	SuggestVariableTypesAction,
	GetDPAAction,
	PreviewDPAAction,
	ClearPreviewDPAFileAction,
	SetFormattedVariablesAction,
	SetPreviousMappingAction,
	SetDataToEntriesAction,
	SignDPAAction,
	GetProjectsCollaboratorsAvatarDataAction,
	DeleteProjectAction,
	SetImportVariableSetAction,
	SetProjectsViewOptionAction,
	SetProjectsTableVisibleColumnsAction,
	SetTableFiltersAction,
	SetFormattedVariableAction,
	SetRefetchProjectsAction,
	SetCopyProjectAction,
	UploadUrlAction,
	UploadFileAction,
	SetAssignedOrganizationIdAction,
	ResetCsvAction,
	GetProjectMetadataDefinitionAction
} from './types';

import { ActionTypes } from './types';

import { getForms } from '../forms';
import { getDependencies } from '../dependencies';
import { getStatuses } from '../statuses';
import { clearActionTypeError, doAfterTransaction } from 'store/ui/activities';
import { roundMinutes } from 'helpers/dateFormat';
import { executePromisesInSequence, throttleActivity } from 'store/utils';
import { Collaborator } from '../collaborators';
import { ApiImportErrorType } from './constants';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount,
	ApiFileImportError,
	FileImportErrors,
	FileImportErrorValue
} from 'types/data/projects/types';
import type { TransactionResponseBody } from 'api/types';
import { parseApiMetadataDefinition } from './parsers';
import {
	getPreviewVariablesAndTypes,
	hasDuplicateLabels
} from 'helpers/projects/imports/suggestedVariables';
import { clone } from 'lodash';
import {
	buildVariablesDataFromStoreData,
	buildVariablesRichData,
	getScopeVariables
} from 'helpers/variables';
import { PreviewVariable } from '../../../types/data/projects/import/types';
import { TranslateFunction } from '../../../hooks/store/ui/useTranslation';
import { decodeString } from 'helpers/strings';
import { EntryVariableType } from 'types/data/variables/constants';
import { ProjectType } from 'types/data/projects/constants';

const throttleTimeouts = {
	general: 1000
};
const throttledActivity = throttleActivity({ timeout: throttleTimeouts.general });

export const getProjectsAction = (
	payload: ActionPayload<GetProjectsAction>
): GetProjectsAction => ({
	type: ActionTypes.GET_PROJECTS,
	payload
});

export const getProjects =
	(options?: { omitMetadata?: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_PROJECTS, dispatch });

		try {
			activity.begin();

			const { username } = getState().auth;

			if (!options?.omitMetadata) {
				dispatch(getCounts());
				dispatch(getProjectsCollaboratorsAvatarData());
			}

			const projects = await context.api.data.projects().getProjects();

			if (username) dispatch(getProjectsAction({ projects, username }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getPromsAction = (payload: ActionPayload<GetPromsAction>): GetPromsAction => ({
	type: ActionTypes.GET_PROMS,
	payload
});

export const getProms =
	(options?: { omitMetadata?: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_PROMS, dispatch });

		try {
			activity.begin();

			const { username } = getState().auth;

			if (!options?.omitMetadata) {
				dispatch(getCounts());
				dispatch(getProjectsCollaboratorsAvatarData({ fetchForProms: true }));
			}

			const projects = await context.api.data.projects().getProms();

			if (username) dispatch(getPromsAction({ projects, username }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const getProjectAction = (payload: ActionPayload<GetProjectAction>): GetProjectAction => ({
	type: ActionTypes.GET_PROJECT,
	payload
});

export const getProject =
	(projectType: ProjectType): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_PROJECT, dispatch });

		try {
			activity.begin();

			const { username } = getState().auth;
			const { projectId } = getState().data.projects;

			if (projectId) {
				const project = await context.api.data
					.projects()
					.getProject(projectId, projectType);

				if (username) {
					dispatch(getProjectAction({ project, username, projectType }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getCountsAction = (payload: ActionPayload<GetCountsAction>): GetCountsAction => ({
	type: ActionTypes.GET_COUNTS,
	payload
});

export const getCounts = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({ type: ActionTypes.GET_COUNTS, dispatch });

	try {
		activity.begin();

		const counts = await context.api.data.projects().getCounts();

		dispatch(getCountsAction({ counts }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const createProjectAction = (
	payload: ActionPayload<CreateProjectAction>
): CreateProjectAction => ({
	type: ActionTypes.CREATE_PROJECT,
	payload
});

export const createProject =
	(project: CreateProject, promDistributionType?: PromDistributionTypes): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_PROJECT, dispatch });

		try {
			activity.begin();

			const {
				account: {
					subscription: { accountDetails: projectOwnerDetails }
				},
				auth: { username }
			} = getState();

			const createdProject = await context.api.data.projects().createProject(project);

			dispatch(
				getProjectsCollaboratorsAvatarData({
					fetchForProms: project.projectType === ProjectType.PROM
				})
			);

			// IF IS PROM - INITIATE PROM DISTRIBUTION SETUP
			if (project.projectType === ProjectType.PROM) {
				const isManualDistribution = promDistributionType === PromDistributionTypes.Manual;

				if (isManualDistribution) {
					const dateNowRoundedTime = roundMinutes(new Date(), true).toISOString();
					const formattedDateNow = format(
						new Date(dateNowRoundedTime),
						DATE_TIME_TIMEZONE_FORMAT
					);

					await context.api.data
						.patients()
						.updateManualDistributions(
							Number(createdProject.projectId),
							undefined,
							formattedDateNow
						);

					// QUICK FIX UNTIL WE MOVE THE ABOVE LOGIC RIGHT INTO THE `addProject` INPUT
					// THIS WILL BE DONE IN A LATER REFACTOR
					createdProject.promType = PromDistributionTypes.Manual;
				} else {
					await context.api.data
						.patients()
						.updateDistributions(Number(createdProject.projectId));
				}
			}

			if (username && projectOwnerDetails) {
				// to be removed when projectOwnerDetails is returned from the API
				createdProject.projectOwnerDetails = {
					userId: username,
					status: projectOwnerDetails.status,
					emailAddress: projectOwnerDetails.emailAddress,
					userFirstName: projectOwnerDetails.userFirstName,
					userSirName: projectOwnerDetails.userSirName,
					phoneNumber: projectOwnerDetails.phoneNumber,
					organization: projectOwnerDetails.workplace,
					position: projectOwnerDetails.position,
					department: projectOwnerDetails.department,
					city: projectOwnerDetails.city,
					country: projectOwnerDetails.country
				};

				// IF JADBIO IS ENABLED, INITIALIZE PROJECT
				if (
					createdProject.activeAddonCodes &&
					createdProject.activeAddonCodes.includes(SubscriptionAddonCode.JADBio)
				) {
					dispatch(initiateJADBioProject(createdProject.projectId));
				}

				batch(() => {
					dispatch(
						createProjectAction({
							project: createdProject,
							user: {
								...createdProject.projectOwnerDetails,
								organizationsCount: 1
							} as Collaborator
						})
					);
					dispatch(setProjectId({ projectId: createdProject.projectId }));
				});
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateProjectAction = (payload: ActionPayload<UpdateProjectAction>): UpdateProjectAction => ({
	type: ActionTypes.UPDATE_PROJECT,
	payload
});

export const updateProject =
	(project: UpdateProject): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_PROJECT, dispatch });

		try {
			activity.begin();

			await context.api.data.projects().updateProject({
				project
			});

			dispatch(updateProjectAction({ project }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteProjectAction = (payload: ActionPayload<DeleteProjectAction>): DeleteProjectAction => ({
	type: ActionTypes.DELETE_PROJECT,
	payload
});

export const deleteProject =
	(payload: ActionPayload<DeleteProjectAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_PROJECT, dispatch });

		const { projectId } = payload;

		try {
			activity.begin({ payload: projectId });

			const {
				projects: { byId }
			} = getState().data;

			if (projectId in byId) {
				const project = byId[projectId];

				// TODO: DELETE WHEN `deleteProject` API METHOD IS READY
				const {
					projectName,
					givenProjectNumber = '',
					description,
					slideFolderURL = '',
					projectType = ProjectType.CORE,
					projectEndDate = ''
				} = project;

				// TODO: replace with `deleteProject` API method
				// await context.api.data.projects().deleteProject();
				await context.api.data.projects().updateProject({
					project: {
						projectId,
						projectName,
						givenProjectNumber,
						// TODO: DELETE WHEN `deleteProject` API METHOD IS READY
						// @ts-ignore
						status: 'deleted',
						description,
						slideFolderURL,
						projectType,
						projectEndDate
					}
				});

				dispatch(deleteProjectAction(payload));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: projectId });
		} finally {
			activity.end();
		}
	};

const copyProjectAction = (payload: ActionPayload<CopyProjectAction>): CopyProjectAction => ({
	type: ActionTypes.COPY_PROJECT,
	payload
});

export const setCopyProjectId = (
	payload: ActionPayload<SetCopyProjectAction>
): SetCopyProjectAction => ({
	type: ActionTypes.SET_COPY_PROJECT_ID,
	payload
});
export const copyProject =
	(payload: ActionPayload<CopyProjectAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.COPY_PROJECT, dispatch });

		try {
			activity.begin();

			throttledActivity.register({ activityId: activity.id });

			const {
				projects: { byId }
			} = getState().data;
			if (payload.projectId in byId) {
				const { transactionIds, projectId: copiedProjectId } = await context.api.data
					.projects()
					.copyProject(payload);
				dispatch(setCopyProjectId({ copiedProjectId }));
				dispatch(setRefetchProjects());

				if (transactionIds) {
					transactionIds?.forEach(async (transactionId: number) => {
						await dispatch(
							doAfterTransaction({
								globalProjectId: payload.projectId,
								transactionId,
								actionType: ActionTypes.COPY_PROJECT,
								callback: () => {
									dispatch(copyProjectAction(payload));
								}
							})
						);
					});
				} else {
					dispatch(copyProjectAction(payload));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			// THROTTLE ACTIVITY
			throttledActivity.throttle({
				activityId: activity.id,
				callback: () => dispatch(hydrateData())
			});
		}
	};

export const setRefetchProjects = (): SetRefetchProjectsAction => ({
	type: ActionTypes.SET_REFETCH_PROJECT
});

const uploadUrlAction = (payload: ActionPayload<UploadUrlAction>): UploadUrlAction => ({
	type: ActionTypes.UPLOAD_URL,
	payload
});
/*
		NON_ASYNC Used for cases DataToEntries and EntriesToDataset
		Receives XLS File in base64 as input and converts it to CSV format using the API method
	*/
export const uploadUrl =
	(
		projectId: number,
		file: DraggedFile,
		isBinaryFile: boolean,
		dispatchSuggestedVariables: boolean,
		translate: TranslateFunction,
		timeZoneLess?: boolean,
		importType?: Omit<ImportType, ImportType.Manual>,
		callbacks?: {
			onAPIError: (errors: FileImportErrors) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPLOAD_URL, dispatch });

		console.log('uploadUrl api action: input: ', {
			projectId,
			file,
			isBinaryFile,
			dispatchSuggestedVariables,
			translate,
			timeZoneLess,
			importType,
			callbacks
		});
		try {
			activity.begin();

			const response = await context.api.data
				.projects()
				.uploadUrl({ projectId: Number(projectId), fileName: file.name });

			console.log('uploadUrl api action: response: ', response);
			if (response && response.url) {
				await dispatch(uploadFile(response.url, file));

				if (dispatchSuggestedVariables) {
					console.log(
						'uploadUrl api action: dispatching getSuggestedVariableTypesApiAction'
					);
					await dispatch(
						getSuggestedVariableTypesApiAction(
							Number(projectId),
							response.fileId,
							translate,
							timeZoneLess,
							importType,
							callbacks
						)
					);
				}

				dispatch(uploadUrlAction({ ...response, isBinaryFile }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const uploadFileAction = (): UploadFileAction => ({
	type: ActionTypes.UPLOAD_FILE
});

export const uploadFile =
	(url: string, file: DraggedFile): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPLOAD_FILE, dispatch });

		try {
			activity.begin();

			await context.api.data.projects().uploadFile(url, file);

			dispatch(uploadFileAction());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const suggestVariableTypesStoreAction = (
	payload: ActionPayload<SuggestVariableTypesAction>
): SuggestVariableTypesAction => ({
	type: ActionTypes.SUGGEST_VARIABLE_TYPES,
	payload
});

export const getSuggestedVariableTypesApiAction =
	(
		projectId: number,
		fileId: string,
		translate: TranslateFunction,
		timeZoneLess?: boolean,
		importType?: Omit<ImportType, ImportType.Manual>,
		callbacks?: {
			onAPIError: (errors: FileImportErrors) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.SUGGEST_VARIABLE_TYPES, dispatch });
		console.log('getSuggestedVariableTypesApiAction: start: ');
		console.log('getSuggestedVariableTypesApiAction: input: ', {
			projectId,
			fileId,
			translate,
			timeZoneLess,
			importType,
			callbacks
		});
		try {
			const transactionId = await context.api.data.projects().suggestVariableTypesUrl({
				projectId: Number(projectId),
				fileId,
				timeZoneLess
			});
			console.log('getSuggestedVariableTypesApiAction: transactionId: ', transactionId);
			if (transactionId) {
				await dispatch(
					doAfterTransaction<{ url: string }>({
						propagateError: true,
						transactionId,
						actionType: ActionTypes.SUGGEST_VARIABLE_TYPES,
						callback: response => {
							console.log(
								'getSuggestedVariableTypesApiAction: success response: ',
								response
							);
							response &&
								dispatch(
									suggestVariableTypesApiAction(
										response.url,
										translate,
										importType,
										timeZoneLess
									)
								);
						},
						errorCallback: (e: any) => {
							console.log('getSuggestedVariableTypesApiAction: error: ', e);
							if (callbacks) {
								const response = e as TransactionResponseBody<Record<string, any>>;
								if (
									response.statusCode == 400 &&
									response.errors &&
									response.errors.length > 0 &&
									response.errors.some((err: any) =>
										[
											ApiImportErrorType.MismatchingColumnNumber,
											ApiImportErrorType.UnknownCodec,
											ApiImportErrorType.NotSupportedExcelFile
										].includes(err.code)
									)
								) {
									const parsedErrors: FileImportErrors = [];
									const mismatchErrors: FileImportErrorValue[] = [];

									for (const err of response.errors) {
										const { code, values } =
											err as unknown as ApiFileImportError;
										if (code === ApiImportErrorType.MismatchingColumnNumber) {
											mismatchErrors.push({
												rowIdx: values.row_number,
												expectedColumnLength: values.headers_number,
												actualColumnLength: values.row_length
											});
										} else if (code === ApiImportErrorType.UnknownCodec) {
											parsedErrors.push({
												type: ApiImportErrorType.UnknownCodec
											});
										} else if (
											code === ApiImportErrorType.NotSupportedExcelFile
										) {
											parsedErrors.push({
												type: ApiImportErrorType.NotSupportedExcelFile
											});
										}
									}

									if (mismatchErrors.length) {
										parsedErrors.push({
											type: ApiImportErrorType.MismatchingColumnNumber,
											errors: mismatchErrors
										});
									}
									callbacks.onAPIError(parsedErrors);

									return;
								}
							} else {
								throw e;
							}
						}
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const suggestVariableTypesApiAction =
	(
		url: string,
		translate: TranslateFunction,
		importType?: Omit<ImportType, ImportType.Manual>,
		timezoneDuringImport?: boolean
	): Thunk =>
	async (dispatch, getState, context) => {
		const {
			data: {
				variables: { projectId, byProjectId: variablesByProjectId },
				projects: {
					import: {
						importVariableSet: { importVariableSetName, isImportVariableSet }
					}
				}
			}
		} = getState();
		const activity = createActivity({ type: ActionTypes.SUGGEST_VARIABLE_TYPES, dispatch });

		try {
			console.log('suggestVariableTypesApiAction: start: ', { url });
			const data = await context.api.data.projects().getSuggestedVariableTypes(url);
			console.log('suggestVariableTypesApiAction: data: ', { data });

			if (data) {
				const { dateFormats, dateTimeFormats, suggestions, timeZones, isExcelOrigin } =
					data;

				let formattedVariables: PreviewVariable[] = [];
				if (projectId) {
					const { variables } = getPreviewVariablesAndTypes(
						suggestions,
						timezoneDuringImport
					);

					const newSuggestedTypes: (SuggestedVariableTypes | null)[] = [];
					const copySuggestedTypes = clone(suggestions);

					if (copySuggestedTypes && suggestions.length !== variables.length) {
						variables.forEach((variable, index) => {
							newSuggestedTypes[index] = null;

							if (variable.type === '') {
								newSuggestedTypes[index] = copySuggestedTypes[0];
								copySuggestedTypes.shift();
							}
						});
					}

					const variablesData = buildVariablesDataFromStoreData(
						variablesByProjectId[projectId].initial
					);
					const oldVariables = buildVariablesRichData(variablesData);

					const formattedSuggestedVariableTypes = newSuggestedTypes.length
						? newSuggestedTypes
						: suggestions;

					let unnamedVariableCount = 0;

					const isNewVariableSet = !(
						importVariableSetName in oldVariables.variableSetsMap
					);

					const scopeVariables = getScopeVariables({
						variables: oldVariables.variables,
						variablesData,
						destinationSetName: isNewVariableSet
							? null
							: importVariableSetName !== ''
							? importVariableSetName
							: null
					});

					if (formattedSuggestedVariableTypes && formattedSuggestedVariableTypes.length) {
						variables.forEach((variable, index, vars) => {
							let suggestedType = null;
							let isExcelDateFormat = false;

							const dropdownVariables = scopeVariables.filter(
								variable => variable.entryType !== EntryVariableType.Calculated
							);

							// --- ADD MORE DATA ---
							if (importType === ImportType.MoreDataToExistingEntries) {
								if (hasDuplicateLabels(variable, oldVariables.variables)) {
									variable.labelError = translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.noDuplicates
									);
								}
							}

							// --- ADD MORE ENTRIES ---
							if (importType === ImportType.MoreEntriesToDataset) {
								// check if potential duplicates are actually matches

								if (hasDuplicateLabels(variable, oldVariables.variables)) {
									// IF TYPE IS THE SAME AUTO-MATCH;
									const matchingVariable = scopeVariables.find(
										oldVariable =>
											variable.label
												.replaceAll(SPECIAL_CHARACTERS, '')
												.trim() === oldVariable.label.trim()
									);
									if (matchingVariable) {
										const isAlreadyMatched = vars.find(
											(v, idx) =>
												(v.label === matchingVariable.label ||
													v.previewVariableLabel ===
														matchingVariable.label) &&
												idx < index
										);

										if (isAlreadyMatched) {
											variable.isNew = true;
											variable.labelError = translate(
												({ projects }) =>
													projects.createAndImport.generics
														.previewVariables.noDuplicates
											);
											variable.previewVariableLabelError = translate(
												({ projects }) =>
													projects.createAndImport.generics
														.previewVariables.noDuplicates
											);
										} else {
											variable.isNew = false;
											variable.previewVariableLabelError = '';
											variable.labelError = '';
										}

										if (variable.type !== matchingVariable.type) {
											variable.type = matchingVariable.type;
										}

										variable.previewVariableName = matchingVariable.name;
										variable.name = matchingVariable.name;
									} else {
										variable.previewVariableLabelError = translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.noDuplicates
										);
									}
								} else {
									if (!variable.label.match(VAR_LABEL_PATTERN)) {
										variable.previewVariableLabelError = translate(
											dict => dict.validation.formVariables.specialCharacters
										);
										variable.labelError = translate(
											dict => dict.validation.formVariables.specialCharacters
										);
									}
								}
							}

							// --- SERIES REPLACE ALL ---
							if (importType === ImportType.ReplaceAll && isImportVariableSet) {
								const isNewVariableSet = !(
									importVariableSetName in oldVariables.variableSetsMap
								);
								const currentSetVariables = getScopeVariables({
									variables: oldVariables.variables,
									variablesData,
									destinationSetName: isNewVariableSet
										? null
										: importVariableSetName
								});
								const variablesOutsideSet = isNewVariableSet
									? oldVariables.variables
									: oldVariables.variables.filter(
											variable =>
												!currentSetVariables.some(
													currentVariable =>
														currentVariable.label === variable.label
												)
									  );
								if (hasDuplicateLabels(variable, variablesOutsideSet)) {
									variable.labelError = translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.noDuplicates
									);
									variable.previewVariableLabelError = translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.noDuplicates
									);
								}
							}

							if (!variable.label.match(VAR_LABEL_PATTERN)) {
								variable.labelError = translate(
									dict => dict.validation.formVariables.specialCharacters
								);
							}

							if (!variable.label && !variable.previewVariableLabel) {
								variable.label = `Unnamed: ${unnamedVariableCount}`;
								unnamedVariableCount++;
							}

							if (
								formattedSuggestedVariableTypes[index] &&
								!formattedSuggestedVariableTypes[index]?.isEmpty
							) {
								suggestedType =
									formattedSuggestedVariableTypes[index]?.suggestedVariableType;
								isExcelDateFormat =
									!!formattedSuggestedVariableTypes[index]?.isExcelDateFormat;
							}

							if (suggestedType) {
								if (variable.type === '') variable.type = suggestedType;
								variable.isExcelDateFormat = isExcelDateFormat;
							}

							if (variable.isNew) {
								const hasDuplicateMappingError =
									translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.noDuplicates
									) === variable.previewVariableLabelError;

								dropdownVariables.forEach(dropdownVar => {
									if (
										dropdownVar.label === decodeString(variable.label) &&
										dropdownVar.type === variable.type
									) {
										if (!hasDuplicateMappingError) {
											dispatch(
												setFormattedVariableAction({
													formattedVariable: {
														...variable,
														isNew: false,
														omit: false,
														previewVariableLabel: dropdownVar.label,
														label: dropdownVar.label,
														previewVariableName: dropdownVar.name,
														name: dropdownVar.name,
														previewVariableLabelError: '',
														...(dropdownVar.dateFormat
															? { dateFormat: dropdownVar.dateFormat }
															: {})
													}
												})
											);
										}
									}
								});
							}
						});
					}
					formattedVariables = variables;
				}

				dispatch(
					suggestVariableTypesStoreAction({
						suggestedVariableTypes: suggestions,
						initialSuggestions: suggestions,
						dateFormats: dateFormats,
						dateTimeFormats: dateTimeFormats,
						isExcelOrigin: isExcelOrigin,
						timeZones: timeZones,
						formattedVariables
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const generateVariableNames =
	(input: GenerateVariableNamesInput): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.GENERATE_VARIABLE_NAMES, dispatch });

		try {
			activity.begin();

			const { variableLabels, variableNames, projectId, callbacks = {} } = input;

			const response = await context.api.data
				.projects()
				.generateVariableNames({ variableLabels, variableNames, projectId });
			if (response) {
				callbacks.successCallback?.(response);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const storeCsvContentAction = (
	payload: ActionPayload<StoreCsvContentAction>
): StoreCsvContentAction => ({
	type: ActionTypes.STORE_CSV_CONTENT,
	payload
});
/*
	NON_ASYNC / works with all 3 methods (ReplaceAll, DataToEntries, EntriesToDatase)
	Simply parses the locally uploaded CSV / no need for the API
*/
export const storeCsvContent =
	(input: StoreCsvInput): Thunk =>
	async dispatch => {
		const activity = createActivity({ type: ActionTypes.STORE_CSV_CONTENT, dispatch });

		try {
			activity.begin();

			dispatch(resetCsvDataAction());

			const { content } = input;
			if (content) {
				const result = Papa.parse(content, { skipEmptyLines: true });

				let data = content.split('\n');

				if (data.length < 2 && data[0].replace(result.data[0] as string, '').length > 0) {
					data = content.split('\r');
				}

				const formattedData = [data[0], content.replace(data[0], '')];

				if (formattedData[1].substring(0, 2).includes('\r\n'))
					formattedData[1] = formattedData[1].replace('\r\n', '');
				if (formattedData[1].substring(0, 2).includes('\r'))
					formattedData[1] = formattedData[1].replace('\r', '');
				if (formattedData[1].substring(0, 2).includes('\n'))
					formattedData[1] = formattedData[1].replace('\n', '');

				// await dispatch(suggestVariableTypes(result.data as string[][]));

				const headerDelimiter =
					result.meta.delimiter === ',' || result.meta.delimiter === ';'
						? result.meta.delimiter
						: ',';

				dispatch(
					storeCsvContentAction({
						content: formattedData,
						originalContent: content,
						headerDelimiter
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

function timeout(duration: number) {
	return new Promise(resolve => setTimeout(resolve, duration));
}

const uploadReplaceAllStoreAction = (
	payload: ActionPayload<UploadProjectDatasetAction>
): UploadProjectDatasetAction => ({
	type: ActionTypes.UPLOAD_PROJECT_DATASET,
	payload
});
/*
	Method that uploads the XLS or CSV data to the API and also does polling
	[ ] [ ] [x] - ReplaceAll
*/

export const uploadReplaceAllApiAction =
	(
		input: UploadProjectDatasetInput,
		callbacks?: {
			onAPIError: (
				apiErrorMap: ApiImportErrorsByVariableName,
				errorCountMap: ApiVariableErrorCount
			) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPLOAD_PROJECT_DATASET, dispatch });

		try {
			activity.begin();

			const transactionId = await context.api.data.projects().uploadProjectDataset(input);

			if (!transactionId) throw new Error(Dictionary.errors.api.importFail);

			let transactionData = createInitTransaction();

			let timeoutCount = 0;
			let progressPercentage = 0;

			while (!transactionData.isFinished) {
				[transactionData] = (await Promise.all([
					context.api.data
						.projects()
						.checkTransactionProgress(
							input.projectId,
							transactionId,
							callbacks ? true : false
						),
					timeout(1000)
				])) as [Transaction, any];

				if (transactionData.isTimedOut) throw new Error(Dictionary.errors.api.importFail);

				timeoutCount++;
				dispatch(
					updateImportPercentage({ importPercentage: transactionData.progressPercentage })
				);

				if (
					transactionData.progressPercentage !== progressPercentage &&
					transactionData.progressPercentage > progressPercentage
				) {
					timeoutCount = 0;
					progressPercentage = transactionData.progressPercentage;
				}

				if (timeoutCount >= 20) {
					throw new Error(Dictionary.errors.api.importFail);
				}
			}

			if (!transactionData.isSuccessful) {
				const { response } = transactionData;
				if (response) {
					if (response.httpStatusCode !== 200) {
						throw new Error();
					}

					if (callbacks) {
						if (response.statusCode == 400 && response.groupedErrors) {
							const groupedErrors =
								response.groupedErrors as ApiImportErrorsByVariableName;
							const errorCountMap = response.numberOfErrors as ApiVariableErrorCount;

							const hasValues = Object.values(groupedErrors).length > 0;

							if (hasValues) {
								let shouldTriggerCallback = false;
								for (const errorsMap of Object.values(groupedErrors)) {
									for (const errorType of Object.keys(errorsMap)) {
										if (
											[
												ApiImportErrorType.ImportCouldNotParseValue,
												ApiImportErrorType.NonExistentDatetime,
												ApiImportErrorType.DatetimeExistsTwice
											].includes(errorType as ApiImportErrorType)
										) {
											shouldTriggerCallback = true;
											break;
										}
									}
								}

								if (shouldTriggerCallback) {
									return callbacks.onAPIError(groupedErrors, errorCountMap);
								}
							} else {
								if (response.errors && response.errors.length) {
									throw new Error(response.errors.join('\n'));
								}
							}
						}
					} else {
						if (response.errors && response.errors.length) {
							throw new Error(response.errors.join('\n'));
						}
					}
				}
			}

			// RESETS ENTRIES
			dispatch(
				uploadReplaceAllStoreAction({
					projectId: input.projectId.toString()
				})
			);

			dispatch(getForms());
			dispatch(getCounts());
			dispatch(getStatuses());
			dispatch(getSnapshots());
			dispatch(getDependencies());

			await dispatch(getEntries({}));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const uploadMoreDataToEntriesStoreAction = (): UpdateExistingDatasetAction => ({
	type: ActionTypes.UPDATE_EXISTING_DATASET
});
//  [x] [ ] [ ] - DataToEntries
export const uploadMoreDataToEntriesApiAction =
	(
		input: UpdateExistingDatasetInput,
		callbacks?: {
			onAPIError: (
				apiErrorMap: ApiImportErrorsByVariableName,
				errorCountMap: ApiVariableErrorCount
			) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_EXISTING_DATASET, dispatch });
		// clear previous errors
		dispatch(clearActionTypeError(ActionTypes.UPDATE_EXISTING_DATASET));
		try {
			activity.begin();

			dispatch(
				setPreviousMappingAction({
					importType: ImportType.MoreDataToExistingEntries
				})
			);

			const transactionId = await context.api.data.projects().updateExistingDataset(input);

			if (transactionId) {
				await dispatch(
					doAfterTransaction({
						transactionId,
						actionType: ActionTypes.UPDATE_EXISTING_DATASET,
						errorCallback: (e: TransactionResponseBody<Record<string, any>> | any) => {
							if (callbacks) {
								const response = e as TransactionResponseBody<Record<string, any>>;

								if (response.statusCode == 400 && response.groupedErrors) {
									const groupedErrors =
										response.groupedErrors as ApiImportErrorsByVariableName;
									const errorCountMap =
										response.numberOfErrors as ApiVariableErrorCount;

									const hasValues = Object.values(groupedErrors).length > 0;

									if (hasValues) {
										let shouldTriggerCallback = false;
										for (const errorsMap of Object.values(groupedErrors)) {
											for (const errorType of Object.keys(errorsMap)) {
												if (
													[
														ApiImportErrorType.ImportCouldNotParseValue,
														ApiImportErrorType.NonExistentDatetime,
														ApiImportErrorType.DatetimeExistsTwice
													].includes(errorType as ApiImportErrorType)
												) {
													shouldTriggerCallback = true;
													break;
												}
											}
										}

										if (shouldTriggerCallback) {
											return callbacks.onAPIError(
												groupedErrors,
												errorCountMap
											);
										}
									} else {
										if (response.errors && response.errors.length) {
											throw new Error(response.errors.join('\n'));
										}
									}
								}
							} else {
								throw e;
							}
						},
						propagateError: !!callbacks
					})
				);
			}
			// RESETS ENTRIES
			dispatch(uploadMoreDataToEntriesStoreAction());

			dispatch(getCounts());
			dispatch(getSnapshots());

			dispatch(setPreviousMappingAction({ importType: null }));

			/**
			 * Give some time to backend (ES) because newly added data
			 * is not immediately visible when searched for
			 */
			await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));

			await dispatch(getEntries({}));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const uploadMoreEntriesToDatasetStoreAction = (): UploadToExistingDatasetAction => ({
	type: ActionTypes.UPLOAD_TO_EXISTING_DATASET
});
// [ ] [x] [] - EntriesToDataset
export const uploadMoreEntriesToDatasetApiAction =
	(
		input: UploadProjectDatasetInput,
		callbacks?: {
			onAPIError: (
				apiErrorMap: ApiImportErrorsByVariableName,
				errorCountMap: ApiVariableErrorCount
			) => void;
		}
	): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPLOAD_TO_EXISTING_DATASET, dispatch });

		try {
			activity.begin();

			dispatch(
				setPreviousMappingAction({
					importType: ImportType.MoreEntriesToDataset
				})
			);

			const transactionIds = await context.api.data.projects().uploadToExistingDataset(input);

			if (transactionIds) {
				const transactions = transactionIds.map(
					id => async () =>
						dispatch(
							doAfterTransaction({
								transactionId: id,
								actionType: ActionTypes.UPLOAD_TO_EXISTING_DATASET,
								errorCallback: (
									e: TransactionResponseBody<Record<string, any>> | any
								) => {
									if (callbacks) {
										const response = e as TransactionResponseBody<
											Record<string, any>
										>;

										if (response.statusCode == 400 && response.groupedErrors) {
											const groupedErrors =
												response.groupedErrors as ApiImportErrorsByVariableName;
											const errorCountMap =
												response.numberOfErrors as ApiVariableErrorCount;

											const hasValues =
												Object.values(groupedErrors).length > 0;

											let shouldTriggerCallback = false;
											if (hasValues) {
												for (const errorsMap of Object.values(
													groupedErrors
												)) {
													for (const errorType of Object.keys(
														errorsMap
													)) {
														if (
															[
																ApiImportErrorType.ImportCouldNotParseValue,
																ApiImportErrorType.NotAllowedFixedCategoryValue,
																ApiImportErrorType.ImportNoDateFormat,
																ApiImportErrorType.MissingValueForUniqueVariable,
																ApiImportErrorType.DuplicateValueForUniqueVariable,
																ApiImportErrorType.NotAllowedToSetValueForUniqueVariable,
																ApiImportErrorType.NonExistentDatetime,
																ApiImportErrorType.DatetimeExistsTwice
															].includes(
																errorType as ApiImportErrorType
															)
														) {
															shouldTriggerCallback = true;
															break;
														}
													}
												}

												if (shouldTriggerCallback) {
													return callbacks.onAPIError(
														groupedErrors,
														errorCountMap
													);
												}
											} else {
												if (response.errors && response.errors.length) {
													const errorMessage = response.errors
														.map((e: any) => e.message)
														.join('\n');

													throw new Error(errorMessage);
												}
											}
										}
									} else {
										throw e;
									}
								},
								propagateError: !!callbacks
							})
						)
				);

				await executePromisesInSequence<void>(transactions);
			}

			// RESETS ENTRIES
			dispatch(uploadMoreEntriesToDatasetStoreAction());

			dispatch(getCounts());
			dispatch(getSnapshots());

			dispatch(setPreviousMappingAction({ importType: null }));

			/**
			 * Give some time to backend (ES) because newly added data
			 * is not immediately visible when searched for
			 */
			await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));

			await dispatch(getEntries({}));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

// VIEW OPTION
export const setProjectsViewOption = (
	payload: ActionPayload<SetProjectsViewOptionAction>
): SetProjectsViewOptionAction => ({
	type: ActionTypes.SET_PROJECTS_VIEW_OPTION,
	payload
});

export const setProjectsTableVisibleColumns = (
	payload: ActionPayload<SetProjectsTableVisibleColumnsAction>
): SetProjectsTableVisibleColumnsAction => ({
	type: ActionTypes.SET_PROJECTS_TABLE_VISIBLE_COLUMNS,
	payload
});

export const setTableFilters = (
	payload: ActionPayload<SetTableFiltersAction>
): SetTableFiltersAction => ({
	type: ActionTypes.SET_TABLE_FILTERS,
	payload
});

export const setProjectsSearchTerm = (
	payload: ActionPayload<SetProjectsSearchTermAction>
): SetProjectsSearchTermAction => ({
	type: ActionTypes.SET_PROJECTS_SEARCH_TERM,
	payload
});

export const setActiveStatus = (
	payload: ActionPayload<SetActiveStatusAction>
): SetActiveStatusAction => ({
	type: ActionTypes.SET_ACTIVE_STATUS,
	payload
});

export const setProjectSort = (
	payload: ActionPayload<SetProjectSortAction>
): SetProjectSortAction => ({
	type: ActionTypes.SET_PROJECT_SORT,
	payload
});

export const setActiveOwnership = (payload: ActionPayload<SetActiveOwnershipAction>) => ({
	type: ActionTypes.SET_ACTIVE_OWNERSHIP,
	payload
});

export const setProjectId = (payload: ActionPayload<SetProjectIdAction>): SetProjectIdAction => ({
	type: ActionTypes.SET_PROJECT_ID,
	payload
});

export const resetCsvDataAction = (): ResetCsvDataAction => ({
	type: ActionTypes.RESET_CSV_DATA
});

export const resetCsvAction = (): ResetCsvAction => ({
	type: ActionTypes.RESET_CSV
});

const getImportDataTextAction = (
	payload: ActionPayload<GetImportDataTextAction>
): GetImportDataTextAction => ({
	type: ActionTypes.GET_IMPORT_DATA_TEXT,
	payload
});

export const getImportDataText = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_IMPORT_DATA_TEXT, dispatch });

	try {
		activity.begin();

		const language = getState().ui.i18n.language;

		if (language) {
			const importDataText = await context.api.data.projects().getImportDataText(language);
			dispatch(getImportDataTextAction({ importDataText }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const resetProjectsFilters = (
	payload: ActionPayload<ResetProjectsFiltersAction>
): ResetProjectsFiltersAction => ({
	type: ActionTypes.RESET_PROJECTS_FILTERS,
	payload
});

const leaveProjectAction = (payload: ActionPayload<LeaveProjectAction>): LeaveProjectAction => ({
	type: ActionTypes.LEAVE_PROJECT,
	payload
});

export const leaveProject =
	(projectId: string, matchProms: boolean): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.LEAVE_PROJECT, dispatch });

		try {
			activity.begin();

			const data = await context.api.data.projects().leaveProject(projectId);

			if (data) {
				dispatch(leaveProjectAction({ projectId, matchProms }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const updateImportPercentage = (
	payload: ActionPayload<UpdateImportPercentageAction>
): UpdateImportPercentageAction => ({
	type: ActionTypes.UPDATE_IMPORT_PERCENTAGE,
	payload
});

export const updateProjectOwnerPermissions = (
	payload: ActionPayload<UpdateProjectOwnerPermissionsAction>
): UpdateProjectOwnerPermissionsAction => ({
	type: ActionTypes.UPDATE_PROJECT_OWNER_PERMISSIONS,
	payload
});

/*
 * DPA
 */

const getDPAAction = (payload: ActionPayload<GetDPAAction>): GetDPAAction => ({
	type: ActionTypes.GET_DPA,
	payload
});

export const getDPA = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_DPA, dispatch });

	try {
		activity.begin();

		const { projectId } = getState().data.projects;

		if (projectId) {
			const DPAFiles = await context.api.data.projects().getDPA({ project: { projectId } });

			dispatch(getDPAAction({ DPAFiles }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const signDPAAction = (): SignDPAAction => ({
	type: ActionTypes.SIGN_DPA
});

export const signDPA =
	(input: SignDPAInput): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.SIGN_DPA, dispatch });

		try {
			activity.begin();

			await context.api.data.projects().signDPA(input);

			dispatch(signDPAAction());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const previewDPAAction = (payload: ActionPayload<PreviewDPAAction>): PreviewDPAAction => ({
	type: ActionTypes.PREVIEW_DPA,
	payload
});

export const previewDPA =
	(input: PreviewDPAInput): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.PREVIEW_DPA, dispatch });

		try {
			activity.begin();

			const previewDPAFile = await context.api.data.projects().previewDPA(input);

			dispatch(previewDPAAction({ previewDPAFile }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const clearPreviewDPAFile = (): ClearPreviewDPAFileAction => ({
	type: ActionTypes.CLEAR_PREVIEW_DPA_FILE
});

export const setFormattedVariablesAction = (
	payload: ActionPayload<SetFormattedVariablesAction>
): SetFormattedVariablesAction => ({
	type: ActionTypes.SET_FORMATTED_VARIABLES,
	payload
});

export const setFormattedVariableAction = (
	payload: ActionPayload<SetFormattedVariableAction>
): SetFormattedVariableAction => ({
	type: ActionTypes.SET_FORMATTED_VARIABLE,
	payload
});

export const setPreviousMappingAction = (
	payload: ActionPayload<SetPreviousMappingAction>
): SetPreviousMappingAction => ({
	type: ActionTypes.SET_PREVIOUS_MAPPING,
	payload
});

export const setDataToEntriesAction = (
	payload: ActionPayload<SetDataToEntriesAction>
): SetDataToEntriesAction => ({
	type: ActionTypes.SET_DATA_TO_ENTRIES,
	payload
});

const getProjectsCollaboratorsAvatarDataAction = (
	payload: ActionPayload<GetProjectsCollaboratorsAvatarDataAction>
): GetProjectsCollaboratorsAvatarDataAction => ({
	type: ActionTypes.GET_PROJECTS_COLLABORATORS_AVATAR_DATA,
	payload
});

export const getProjectsCollaboratorsAvatarData =
	(payload?: ProjectsCollaboratorsAvatarData): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PROJECTS_COLLABORATORS_AVATAR_DATA,
			dispatch
		});

		try {
			activity.begin();

			const { data } = await context.api.data
				.projects()
				.getProjectsCollaboratorsAvatarData({ fetchForProms: payload?.fetchForProms });

			dispatch(getProjectsCollaboratorsAvatarDataAction({ data }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

/*
	IMPORT OF SERIES
*/
export const setImportVariableSetAction = (
	payload: ActionPayload<SetImportVariableSetAction>
): SetImportVariableSetAction => ({
	type: ActionTypes.SET_IMPORT_VARIABLE_SET,
	payload
});

export const convertProject =
	(input: {
		projectId: string;
		promType: PromDistributionTypes;
		projectType: ProjectType;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CONVERT_PROJECT, dispatch });

		const { projectId, promType, projectType } = input;

		try {
			activity.begin({ payload: { projectId } });

			await context.api.data
				.projects()
				.convertProject({ project: { projectId, promType, projectType } });

			// IF IS PROM - INITIATE PROM DISTRIBUTION SETUP
			if (projectType === ProjectType.PROM) {
				const isManualDistribution = promType === PromDistributionTypes.Manual;

				if (isManualDistribution) {
					const dateNowRoundedTime = roundMinutes(new Date(), true).toISOString();
					const formattedDateNow = format(
						new Date(dateNowRoundedTime),
						DATE_TIME_TIMEZONE_FORMAT
					);

					await context.api.data
						.patients()
						.createManualDistributions(Number(projectId), undefined, formattedDateNow);
				} else {
					await context.api.data.patients().createDistributions(Number(projectId));
				}
			}

			const {
				projects: { projects, proms }
			} = getState().data;

			//refetch revelant data
			dispatch(getCounts());
			dispatch(getProjectsCollaboratorsAvatarData());
			dispatch(getVariables());
			if (proms.fetched) dispatch(getProms({ omitMetadata: true }));
			if (projects.fetched) dispatch(getProjects({ omitMetadata: true }));
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

export const setAssignedOrganizationId = (
	payload: ActionPayload<SetAssignedOrganizationIdAction>
): SetAssignedOrganizationIdAction => ({
	type: ActionTypes.SET_ASSIGNED_ORGANIZATION_ID,
	payload
});
// PROJECT METADATA DEFINITION

const getProjectMetadataDefinitionAction = (
	payload: ActionPayload<GetProjectMetadataDefinitionAction>
): GetProjectMetadataDefinitionAction => ({
	type: ActionTypes.GET_PROJECT_METADATA_DEFINITION,
	payload
});

export const getProjectMetadataDefinition = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_PROJECT_METADATA_DEFINITION,
		dispatch
	});

	try {
		activity.begin();

		const metadata = await context.api.data.projects().getProjectMetadataDefinition();

		dispatch(
			getProjectMetadataDefinitionAction({ metadata: parseApiMetadataDefinition(metadata) })
		);
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};
