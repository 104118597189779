import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { nanoid as generate } from 'nanoid';
import { PlotNumericBoxplotResults } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { ZingChartClickEvent } from 'zingchart-react';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import { useComputePlotNumericBoxPlotChartData as useComputeData } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import {
	useTranslation,
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	data: PlotNumericBoxplotResults;
	grouping: boolean;
	isLegendEnabled: boolean;
	legendHeader?: string;
	isForExport?: boolean;
	scalesLabels: ScalesLabels;
	activeColumn: number;
	fullscreen: string;
	loading: boolean;
	isConfigPanelOpen: boolean;
}

function Component({
	id,
	data,
	grouping,
	isLegendEnabled,
	isForExport,
	scalesLabels,
	legendHeader,
	activeColumn,
	fullscreen,
	loading,
	isConfigPanelOpen
}: Props) {
	const { translate } = useTranslation();
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const { series, options } = useComputeData(
		data,
		grouping,
		isLegendEnabled,
		activeColumn,
		!!fullscreen,
		plots,
		scalesLabels,
		legendHeader
	);

	const renderId = useMemo(generate, [
		activeColumn,
		fullscreen,
		windowSize,
		analysisIds,
		isConfigPanelOpen,
		filters
	]);

	if (isEmpty(data) && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	function onTogglePlot(e: ZingChartClickEvent) {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}

	return (
		<Chart
			onLegendItemClick={onTogglePlot}
			key={renderId}
			id={id}
			type={t => t.BoxPlot}
			series={series}
			options={options}
			// !!! disabled animations until Zingchart fixes no-tooltip-over-median BUG !!!
			// effect={e => e.UnfoldVertical}
			// method={m => m.StrongEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}

export const PlotNumericBoxPlotChart = withMemo(Component, [
	'data',
	'grouping',
	'isLegendEnabled',
	'activeColumn',
	'fullscreen',
	'scalesLabels',
	'legendHeader',
	'isConfigPanelOpen'
]);
