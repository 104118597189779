import React, { RefObject, useCallback, RefCallback } from 'react';
import { isEqual } from 'lodash';
import Select, {
	ClearIndicatorProps,
	components,
	createFilter,
	DropdownIndicatorProps,
	MenuListProps,
	MenuPlacement,
	OptionProps,
	ValueContainerProps
} from 'react-select';
import SelectWithCreate from 'react-select/creatable';
import { InputError } from 'components/UI/Inputs/InputError';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { Colors, Svgs } from 'environment';
import { withMemo } from 'helpers/HOCs';
import { Nullable, SelectItem, SelectItemOrGroup } from 'types/index';
import { Container, SearchValueContainer, SelectContainer } from './CreatableSelect.style';
import { Place } from 'react-tooltip';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Tooltip } from '../Tooltip';
import { getScrollParent } from 'hooks/utils';
import { useTranslation } from 'hooks/store';

enum CreatableSelectType {
	Dropdown,
	Search
}

interface CustomSelectProps {
	maxItems?: number;
	tooltipPlace?: Place;
	creatableSelectType: CreatableSelectType;
	menuTestId?: string;
	optionTestId?: string;
	dropdownIconTestId?: string;
	onClear?: () => void;
	menuRef: RefCallback<HTMLDivElement>;
}

interface Props {
	_ref?: RefObject<HTMLInputElement>;
	label?: string;
	value?: SelectItem | null;
	defaultCustomValue?: string;
	values?: SelectItem[];
	items: SelectItemOrGroup[];
	className?: string;
	placeholder?: string;
	name?: string;
	hint?: string;
	error?: string;
	borderError?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	required?: boolean;
	allowCreate?: boolean;
	allowCreateOnlyOne?: boolean;
	scrollIntoView?: boolean;
	canClear?: boolean;
	maxMenuHeight?: number;
	minMenuHeight?: number;
	hasMultipleValues?: boolean;
	tooltipComponent?: React.ReactNode;
	noOptionsMessage?: React.ReactNode;
	autoFocus?: boolean;
	initiallyOpen?: boolean;
	menuPlacement?: MenuPlacement;
	maxItems?: number;
	menuTestId?: string;
	optionTestId?: string;
	dropdownIconTestId?: string;
	width?: number;
	dropdownWidth?: number;
	id?: string;
	type?: (type: typeof CreatableSelectType) => CreatableSelectType;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
	formatCreateLabel?: (inputValue: string) => string;
	onValueSelected?: (
		selectedValue: Nullable<string>,
		index: number,
		item: SelectItem | null
	) => void;
	onValuesSelected?: (selectedValues: string[]) => void;
	onInputChange?: (newValue: string) => void;
	isItemDisabled?: (item: SelectItem) => boolean;
	onClear?: () => void;
	tooltipPlace?: Place;
	dataTestId?: string;
}

function Component({
	_ref,
	label,
	value = null,
	defaultCustomValue,
	values = [],
	items,
	className,
	placeholder,
	name,
	hint,
	error,
	borderError,
	disabled,
	readOnly,
	required,
	formatCreateLabel,
	allowCreate,
	allowCreateOnlyOne = false,
	scrollIntoView = true,
	canClear = true,
	maxMenuHeight = 220,
	minMenuHeight = 220,
	hasMultipleValues = false,
	tooltipComponent,
	noOptionsMessage,
	autoFocus,
	initiallyOpen,
	menuPlacement,
	maxItems = 100,
	menuTestId = 'select-menu',
	optionTestId = 'select-option',
	dropdownIconTestId = 'dropdown-icon',
	width,
	dropdownWidth,
	id,
	type,
	onBlur,
	onFocus,
	onValueSelected = () => undefined,
	onValuesSelected = () => undefined,
	onInputChange = () => undefined,
	isItemDisabled,
	onClear,
	tooltipPlace = 'right',
	dataTestId
}: Props) {
	const { translate } = useTranslation();
	// const selectedItemIndex = value ? items.findIndex(item => item?.label === value.label) : null;
	// const selectedValue = selectedItemIndex !== null ? items[selectedItemIndex] : null;

	function onChangeSingle(selected: SelectItem | null) {
		const parsedValue = selected ? (selected as SelectItem).value : null;

		const selectedItemIndex = items.findIndex(
			item => (item as SelectItem).value === parsedValue
		) as number;

		onValueSelected(parsedValue, selectedItemIndex, selected);
	}

	function onChangeMultiple(values: SelectItem[]) {
		const parsedValues = values ? values.map(({ value }) => value) : [];

		onValuesSelected(parsedValues);
	}

	const creatableSelectType = type ? type(CreatableSelectType) : CreatableSelectType.Dropdown;

	const valueToShow = hasMultipleValues ? values : value;

	const hasCustomValue = values.reduce(
		(acc, value) => acc || !items.find(item => isEqual(item, value)),
		false
	);

	const menuRef = useCallback(
		(node: HTMLElement | null) => {
			if (!node || !scrollIntoView) return;
			const nodePos = node.getBoundingClientRect();

			const { top } = nodePos;

			const parent = getScrollParent(node.parentElement) as HTMLElement;

			const middle = window.innerHeight / 2; // -> CENTER;

			// added this check for integration tests
			parent.scrollBy && parent.scrollBy({ top: top - middle, behavior: 'smooth' });

			return;
		},
		[scrollIntoView]
	);

	// ALLOW ONLY ONE CUSTOM VALUE
	allowCreate =
		allowCreate && allowCreateOnlyOne && hasMultipleValues ? !hasCustomValue : allowCreate;

	const components = {
		ClearIndicator,
		DropdownIndicator,
		...(creatableSelectType === CreatableSelectType.Search && {
			ValueContainer
		}),
		Option,
		MenuList
	};

	const selectProps = {
		ref: _ref,
		isMulti: hasMultipleValues,
		isClearable: canClear,
		onBlur,
		onFocus,
		value: valueToShow,
		defaultValue: valueToShow,
		defaultInputValue: defaultCustomValue,
		name,
		formatCreateLabel,
		className: 'select-container',
		classNamePrefix: 'select',
		// default translated string for placeholder
		placeholder: placeholder ?? translate(dict => dict.terms.select),
		options: items,
		isSearchable: true,
		onChange: hasMultipleValues ? onChangeMultiple : onChangeSingle,
		maxMenuHeight,
		minMenuHeight: items ? (items.length >= 5 ? minMenuHeight : 220) : minMenuHeight,
		blurInputOnSelect: true,
		menuShouldshouldScrollIntoView: false,
		onInputChange,
		noOptionsMessage: noOptionsMessage ? () => noOptionsMessage : undefined,
		autoFocus,
		defaultMenuIsOpen: initiallyOpen,
		isOptionDisabled: isItemDisabled,
		menuPlacement,
		creatableSelectType,
		maxItems,
		menuRef,
		onClear,
		tooltipPlace,
		components,
		menuTestId,
		optionTestId,
		dropdownIconTestId,
		// improve performance
		filterOption: createFilter({
			ignoreAccents: false
		})
	};

	return (
		<Container className={className} data-testid={dataTestId}>
			<Flex align={a => a.center}>
				<InputLabel disabled={disabled} required={required} label={label} />
				{tooltipComponent && tooltipComponent}
			</Flex>

			<SelectContainer
				data-testid="select-container"
				error={!!error || !!borderError}
				disabled={disabled}
				readOnly={readOnly}
				hasValue={!!valueToShow}
				allowCreate={allowCreate}
				width={width}
				dropdownWidth={dropdownWidth}
			>
				{allowCreate ? (
					<SelectWithCreate
						{...selectProps}
						data-testid="creatable-select"
						//@ts-ignore
						components={components}
						id={id}
					/>
				) : (
					<Select
						{...selectProps}
						//@ts-ignore
						components={components}
						id={id}
					/>
				)}
			</SelectContainer>

			{hint !== undefined && (
				<Typography.Hint marginOffset={{ top: 0.2 }}>{hint}</Typography.Hint>
			)}

			<InputError error={error} />
		</Container>
	);
}

function Option({
	selectProps: { tooltipPlace, optionTestId, ...originalSelectProps },
	...props
}: OptionProps<SelectItem> & {
	selectProps: {
		tooltipPlace?: CustomSelectProps['tooltipPlace'];
		optionTestId?: CustomSelectProps['optionTestId'];
	};
}) {
	// improve performance
	delete props.innerProps.onMouseMove;
	delete props.innerProps.onMouseOver;

	return (
		<components.Option {...props} selectProps={originalSelectProps}>
			<Flex
				dataTestId={optionTestId}
				data-tip={props.data.tooltip || ''}
				data-for={props.innerProps.id}
			>
				{props.children}
				{props.data.tooltip && (
					<Tooltip
						id={props.innerProps.id}
						delayShow={250}
						offset={{
							left: tooltipPlace === 'left' ? 6 : 0,
							right: tooltipPlace === 'right' ? 6 : 0
						}}
						place={tooltipPlace}
						html
					/>
				)}
			</Flex>
		</components.Option>
	);
}

function ClearIndicator({
	selectProps: { onClear, ...originalSelectProps },
	...props
}: ClearIndicatorProps<SelectItem> & { selectProps: { onClear?: CustomSelectProps['onClear'] } }) {
	return (
		<components.ClearIndicator {...props} selectProps={originalSelectProps}>
			<Icon
				svg={Svgs.Clear}
				size={s => s.m}
				colors={{
					color: Colors.text.disabled,
					hover: Colors.text.disabled
				}}
				onClick={() => (onClear ? onClear() : null)}
			/>
		</components.ClearIndicator>
	);
}

function ValueContainer({ children, ...props }: ValueContainerProps<SelectItem>) {
	return (
		<SearchValueContainer>
			<Icon
				svg={Svgs.Search}
				size={s => s.m}
				colors={{ color: Colors.text.disabled }}
				marginOffset={{ left: 0.8 }}
			/>

			<components.ValueContainer {...props}>{children}</components.ValueContainer>
		</SearchValueContainer>
	);
}

function DropdownIndicator({
	selectProps: { creatableSelectType, dropdownIconTestId, ...originalSelectProps },
	...props
}: DropdownIndicatorProps<SelectItem> & {
	selectProps: {
		creatableSelectType: CustomSelectProps['creatableSelectType'];
		dropdownIconTestId: CustomSelectProps['dropdownIconTestId'];
	};
}) {
	return creatableSelectType === CreatableSelectType.Dropdown ? (
		<components.DropdownIndicator {...props} selectProps={{ ...originalSelectProps }}>
			<Icon dataTestId={dropdownIconTestId} svg={Svgs.ChevronDown} onClick={() => null} />
		</components.DropdownIndicator>
	) : (
		<></>
	);
}

/**
 * Limit `MenuList` to 'maxItems' items
 */
function MenuList({
	children,
	selectProps: { maxItems, menuRef, menuTestId, ...originalSelectProps },
	...props
}: MenuListProps<SelectItem> & {
	selectProps: {
		menuTestId: CustomSelectProps['menuTestId'];
		maxItems: CustomSelectProps['maxItems'];
		menuRef: CustomSelectProps['menuRef'];
	};
}) {
	const { translate } = useTranslation();
	const maxItemsCount = maxItems ?? 0;
	const childrenCount = React.Children.count(children);
	const truncatedChildren = React.Children.toArray(children).slice(0, maxItemsCount);
	const hiddenChildren = childrenCount - maxItemsCount;

	return (
		<components.MenuList
			{...props}
			selectProps={originalSelectProps}
			data-testid={menuTestId}
			innerRef={menuRef}
		>
			{truncatedChildren}
			{childrenCount > maxItemsCount && (
				<Typography.Caption marginOffset={{ top: 0.8, left: 0.8 }}>
					{`${translate(dict => dict.selectMultiple.moreResults)} (${hiddenChildren})`}
				</Typography.Caption>
			)}
		</components.MenuList>
	);
}

export const CreatableSelect = withMemo(Component, [
	'items',
	'value',
	'formatCreateLabel',
	'values',
	'label',
	'error',
	'disabled',
	'required',
	'onBlur',
	'onFocus',
	'onValueSelected',
	'onValuesSelected',
	'maxItems',
	'tooltipPlace'
]);
