import { useState } from 'react';
import { SelectItem } from 'types';
import { PreviewVariable } from 'types/data/projects/import/types';
import { useImport } from './useImport';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { VariableType } from 'types/data/variables/constants';
import { userDetectedTimezone } from 'helpers/projects/imports/importTimezone';

export function useImportTimezoneSettings() {
	const [
		{
			data: { timeZones }
		}
	] = useImport();
	const { timezoneDuringImport } = useFlags();

	const [globalTimezone, setGlobalTimezone] = useState<SelectItem>(
		userDetectedTimezone || timeZones[0]
	);

	function requiresTimezoneSettings(variable: PreviewVariable) {
		if (variable.customDateFormat !== '') {
			return (
				variable.type === VariableType.DateTime &&
				timezoneDuringImport &&
				!/Z|([+-]HH:MM)|([+-]HHMM)|(%z)/i.test(variable.customDateFormat)
			);
		}
		return (
			variable.type === VariableType.DateTime &&
			timezoneDuringImport &&
			!/Z|([+-]HH:MM)|([+-]HHMM)|(%z)/i.test(variable.dateFormat)
		);
	}

	function handleChangeGlobalTimezone(value: string) {
		if (value === '') setGlobalTimezone({ label: '', value: '' });
		const tz = timeZones.find(timezone => timezone.value === value);
		if (!tz) return;
		setGlobalTimezone(tz);
	}

	return {
		globalTimezone,
		handleChangeGlobalTimezone,
		requiresTimezoneSettings
	};
}
