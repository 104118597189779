import { Chart } from 'components/UI/Chart';
import { NumberMap } from 'types/index';
import { useCallback, useMemo } from 'react';
import { nanoid as generate } from 'nanoid';
import { ZingChartClickEvent } from 'zingchart-react';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import {
	useTranslation,
	useAnalysisContext,
	useAnalyses,
	useToggleAnalysisChartPlot,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';
import { useComputeFrequenciesPieChartData as useComputeData } from 'helpers/analysis';

interface Props {
	id?: string;
	isForExport?: boolean;
	isLegendEnabled: boolean;
	legendHeader: string;
	activeColumn: number;
	data: NumberMap;
	fullscreen: string;
	showLabels?: boolean;
	loading: boolean;
}

export function FrequenciesPieChart({
	id,
	isForExport,
	data,
	isLegendEnabled,
	legendHeader,
	fullscreen,
	activeColumn,
	showLabels,
	loading
}: Props) {
	const { translate } = useTranslation();
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const analysisIds = useAnalyses();
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const windowSize = useWindowSize();
	const [{ filters }] = useFilters();
	const { series, options } = useComputeData(
		data,
		isLegendEnabled,
		legendHeader,
		activeColumn,
		plots,
		isForExport,
		showLabels,
		fullscreen
	);
	const renderId = useMemo(generate, [
		activeColumn,
		fullscreen,
		windowSize,
		analysisIds,
		filters
	]);

	const onTogglePlot = useCallback((e: ZingChartClickEvent) => {
		if (isForExport) return;
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}, []);

	const isEmpty = () => Object.values(data).filter(value => value !== 0).length === 0;

	if (isEmpty() && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	return (
		<Chart
			onLegendItemClick={onTogglePlot}
			key={renderId}
			id={id}
			type={t => t.Pie}
			series={series}
			options={options}
			effect={e => e.ExpandVertical}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}
