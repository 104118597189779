import produce from 'immer';

import {
	Analysis,
	CompareNumericAnalysisV2,
	CrosstabAnalysis,
	KaplanMeierAnalysis,
	CorrelationsAnalysis,
	ComparePairedAnalysis,
	CompareNumericAnalysisV1,
	CorrelationsV1Analysis
} from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

// RESET STATISTICS IF WENT FROM `ENABED` TO `DISABLED`
export function withStatisticsResetter(analysis: Analysis): Analysis {
	const parsedAnalysis = produce(analysis, analysisDraft => {
		// COMPARE NUMERIC
		if (analysis.type === AnalysisType.CompareNumericV1) {
			const { input, output } = analysisDraft as CompareNumericAnalysisV1;

			if (!input.statistics.shapiroV1) {
				output.statistics.shapiro = {
					data: null
				};
			}
			if (!input.statistics.mannWhitneyV1) {
				output.statistics.mannWhitney = {
					data: null
				};
			}
			if (!input.statistics.independentV1) {
				output.statistics.independent = {
					data: null
				};
			}
			if (!input.statistics.oneWayAnovaV1) {
				output.statistics.oneWayAnova = {
					data: null
				};
			}
			if (!input.statistics.tukeyV1) {
				output.statistics.tukey = {
					data: null
				};
			}
			if (!input.statistics.kruskalV1) {
				output.statistics.kruskal = {
					data: null
				};
			}
			if (!input.statistics.twoWayAnovaV1) {
				output.statistics.twoWayAnova = {
					data: null
				};
			}
			if (!input.statistics.oneWayManovaV1) {
				output.statistics.oneWayManova = {
					data: null
				};
			}
			if (!input.statistics.twoWayManovaV1) {
				output.statistics.twoWayManova = {
					data: null
				};
			}
		}
		if (analysis.type === AnalysisType.CompareNumericV2) {
			const { input, output } = analysisDraft as CompareNumericAnalysisV2;

			if (!input.statistics.shapiroV2) {
				output.statistics.shapiro = {
					data: null
				};
			}
			if (!input.statistics.mannWhitneyV2) {
				output.statistics.mannWhitney = {
					data: null
				};
			}
			if (!input.statistics.independentV2) {
				output.statistics.independent = {
					data: null
				};
			}
			if (!input.statistics.oneWayAnovaV2) {
				output.statistics.oneWayAnova = {
					data: null
				};
			}
			if (!input.statistics.tukeyV2) {
				output.statistics.tukey = {
					data: null
				};
			}
			if (!input.statistics.kruskalV2) {
				output.statistics.kruskal = {
					data: null
				};
			}
			if (!input.statistics.twoWayAnovaV2) {
				output.statistics.twoWayAnova = {
					data: null
				};
			}
			if (!input.statistics.oneWayManovaV2) {
				output.statistics.oneWayManova = {
					data: null
				};
			}
			if (!input.statistics.twoWayManovaV2) {
				output.statistics.twoWayManova = {
					data: null
				};
			}
		}
		// CROSSTAB
		if (analysis.type === AnalysisType.Crosstab) {
			const { input, output } = analysisDraft as CrosstabAnalysis;

			if (!input.statistics.fisher) {
				output.statistics.fisher = {
					data: null
				};
			}
			if (!input.statistics.chiSquare) {
				output.statistics.chiSquare = {
					data: null
				};
			}
			if (!input.statistics.mcNemar) {
				output.statistics.mcNemar = {
					data: null
				};
			}
		}
		// KAPLAN MEIER
		if (analysis.type === AnalysisType.Kaplan) {
			const { input, output } = analysisDraft as KaplanMeierAnalysis;

			if (!input.statistics.logRank) {
				output.statistics.logRank = {
					data: null
				};
			}
		}
		// CORRELATIONS
		if (analysis.type === AnalysisType.CorrelationsV1) {
			const { input, output } = analysisDraft as CorrelationsV1Analysis;

			if (!input.statistics.pearsonV1) {
				output.statistics.pearson = {
					data: null
				};
			}
			if (!input.statistics.spearmanV1) {
				output.statistics.spearman = {
					data: null
				};
			}
			if (!input.statistics.linearRegressionV1) {
				output.statistics.linearRegression = {
					data: null
				};
			}
		}
		// CORRELATIONS
		if (analysis.type === AnalysisType.CorrelationsV2) {
			const { input, output } = analysisDraft as CorrelationsAnalysis;

			if (!input.statistics.pearsonV2) {
				output.statistics.pearsonV2 = {
					data: null
				};
			}
			if (!input.statistics.spearmanV2) {
				output.statistics.spearmanV2 = {
					data: null
				};
			}
			if (!input.statistics.linearRegressionV2) {
				output.statistics.linearRegressionV2 = {
					data: null
				};
			}
		}
		// COMPARE PAIRED
		if (analysis.type === AnalysisType.ComparePaired) {
			const { input, output } = analysisDraft as ComparePairedAnalysis;

			if (!input.statistics.pairedTTest) {
				output.statistics.pairedTTest = {
					data: null
				};
			}
			if (!input.statistics.pairedWilcoxon) {
				output.statistics.pairedWilcoxon = {
					data: null
				};
			}
		}
	});

	return parsedAnalysis;
}
