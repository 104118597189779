import { AddonsToggles } from 'components/Addons';
import { EditProject, ProjectMetadataType, ProjectFormValues } from 'store/data/projects';
import { InputType, SubscriptionAddonCode } from 'types/index';
import { Column, Row } from './EditProjectModal.style';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button';
import { arrayUtils } from 'helpers/arrays';
import { useMatchProms } from 'hooks/navigation';
import {
	useTranslation,
	useProjectById,
	useUserAddons,
	usePermissions,
	useIsProjectOwner,
	useUpdateProject,
	useShowAddons,
	useProjectMetadataDefinition
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, useKeyPress } from 'hooks/utils';
import {
	getInitialMetadataFormValues,
	getProjectValidationSchema,
	parseFormValuesToEditedProject,
	transforMetadataParametersToFormRows
} from 'helpers/projects/projectMetadata';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';

interface Props {
	projectId: string;
	deleteModalVisible: boolean;
	onDelete: () => void;
	onClose: (success?: boolean) => void;
}

export function EditProjectModal({ projectId, deleteModalVisible, onDelete, onClose }: Props) {
	const matchProms = useMatchProms();
	const { translate } = useTranslation();

	const [{ data: projectMetadataDefinition }] = useProjectMetadataDefinition();

	const project = useProjectById(projectId);

	const [
		{
			data: { userAddons, activeUserAddons }
		}
	] = useUserAddons();

	const { data: showAddons } = useShowAddons();

	const { hasEditProjectWriteAccess } = usePermissions({
		projectId: project?.projectId
	});
	const isProjectOwner = useIsProjectOwner(project?.projectId);

	const [{ loading: updatingProject, error: errorUpdatingProject }, updateProject] =
		useUpdateProject();

	const { validationSchema } = getProjectValidationSchema({
		projectMetadataDefinition,
		translate
	});

	const initialMetadataValues = getInitialMetadataFormValues(projectMetadataDefinition, project);

	const initialValues: ProjectFormValues = {
		projectId: project?.projectId ?? '',
		projectName: project?.projectName ?? '',
		givenProjectNumber: project?.givenProjectNumber ?? '',
		status: project?.status ?? ProjectStatus.Ongoing,
		description: project?.description ?? '',
		slideFolderURL: project?.slideFolderURL ?? '',
		projectType: matchProms ? ProjectType.PROM : ProjectType.CORE,
		projectEndDate: project?.projectEndDate ?? '',
		activeAddonCodes: project?.activeAddonCodes ?? [],
		...initialMetadataValues
	};

	const {
		Form,
		register,
		handleSubmit,
		setValue,
		getValues,
		Controller,
		control,
		isDirty: formHasChanges,
		errors,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema,
		enableReinitialize: true
	});

	const handleOnSubmit = handleSubmit(() => {
		if (formHasChanges && !updatingProject && hasEditProjectWriteAccess) {
			trimFields();

			const values = { ...getValues() };

			updateProject(parseFormValuesToEditedProject(values, projectMetadataDefinition));
		}
	});

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.toString().trim());
		setValue('description', values.description.toString().trim());
		setValue('givenProjectNumber', values.givenProjectNumber.toString().trim());

		projectMetadataDefinition.forEach(metadataParameter => {
			if (values[metadataParameter.name])
				setValue(
					`${metadataParameter.name}`,
					values[metadataParameter.name].toString().trim()
				);
		});
	}

	// close modal on project update
	useCompletedAction(updatingProject, errorUpdatingProject, () => onClose(true));

	useKeyPress(
		{
			onEnterKeyPress: deleteModalVisible ? undefined : handleOnSubmit,
			onDeleteKeyPress: () => (isProjectOwner ? onDelete() : undefined)
		},
		{ listen: !updatingProject }
	);

	const baseDictKey = matchProms ? EditProject.prom : EditProject.project;

	function toggleAddon(currentValues: SubscriptionAddonCode[], addon: SubscriptionAddonCode) {
		if (currentValues) {
			const newValues = arrayUtils.addOrSubstract(currentValues, addon);

			setValue('activeAddonCodes', newValues, {
				shouldDirty: true
			});
		}
	}

	const statusOptions = [
		{
			label: translate(dict => dict[baseDictKey].status.values.ongoing),
			value: ProjectStatus.Ongoing
		},
		{
			label: translate(dict => dict[baseDictKey].status.values.onHold),
			value: ProjectStatus.OnHold
		},
		{
			label: translate(dict => dict[baseDictKey].status.values.ended),
			value: ProjectStatus.Ended
		}
	];

	const formMetadataParameters = transforMetadataParametersToFormRows(projectMetadataDefinition);

	return (
		<Modal
			title={translate(dict => dict[baseDictKey].modalTitle)}
			primary={
				hasEditProjectWriteAccess && {
					label: translate(({ buttons }) =>
						formHasChanges ? buttons.update : buttons.done
					),
					loading: updatingProject,
					disabled: formHasChanges ? !canSubmitForm : false,
					onClick: formHasChanges ? handleOnSubmit : onClose
				}
			}
			neutral={
				formHasChanges && {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onClose
				}
			}
			onClose={onClose}
			visible
			close
		>
			<Form onSubmit={handleOnSubmit}>
				<Input
					{...register('projectName')}
					type={InputType.Text}
					label={translate(dict => dict[baseDictKey].title)}
					error={errors.projectName?.message}
					readOnly={!hasEditProjectWriteAccess}
					onBlur={trimFields}
					required
				/>
				<Spacer size={s => s.s} />
				<Input
					{...register('description')}
					type={InputType.Textarea}
					label={translate(dict => dict[baseDictKey].description)}
					rows={4}
					readOnly={!hasEditProjectWriteAccess}
					onBlur={trimFields}
				/>
				<Spacer size={s => s.s} />
				<Row>
					<Column>
						<Input
							{...register('givenProjectNumber')}
							type={InputType.Text}
							label={translate(dict => dict[baseDictKey].number)}
							readOnly={!hasEditProjectWriteAccess}
							onBlur={trimFields}
						/>
					</Column>
					<Column>
						<Controller
							control={control}
							name="projectEndDate"
							render={({ field: { value } }) => (
								<Input
									openDateDownwards
									type={InputType.Date}
									value={value as number}
									onBlur={trimFields}
									label={translate(dict => dict[baseDictKey].endDate)}
									onDateChange={({ formattedDate }) =>
										setValue('projectEndDate', formattedDate, {
											shouldDirty: true
										})
									}
									readOnly={!hasEditProjectWriteAccess}
								/>
							)}
						/>
					</Column>
				</Row>
				<Spacer size={s => s.s} />
				<Controller
					name="status"
					control={control}
					render={({ field: { name, value, onBlur } }) => (
						<RadioGroupUncontrolled
							name="status"
							label={translate(dict => dict[baseDictKey].status.label)}
							options={statusOptions}
							disabled={!hasEditProjectWriteAccess}
							// CONTROLLED PROPS
							value={value as string}
							onBlur={onBlur}
							onChange={newValue =>
								setValue(name, newValue as ProjectStatus, {
									shouldDirty: true
								})
							}
						/>
					)}
				/>

				{formMetadataParameters.length > 0 && (
					<>
						<Spacer size={s => s.s} />
						{formMetadataParameters.map((metadataParametersRow, i) => {
							return (
								<div key={i}>
									<Spacer size={s => s.s} />
									<Row>
										{metadataParametersRow.map(metadataParameter => (
											<Column key={metadataParameter.name}>
												{metadataParameter.type ===
													ProjectMetadataType.String && (
													<Input
														{...register(`${metadataParameter.name}`)}
														type={InputType.Text}
														label={translate(
															() => metadataParameter.label
														)}
														error={
															errors[metadataParameter.name]?.message
														}
														tooltipComponent={
															<InfoTooltip
																marginOffset={{
																	left: 0.4,
																	bottom: 0.4
																}}
																iconVisible
																text={translate(
																	() =>
																		metadataParameter.description
																)}
															/>
														}
														onBlur={trimFields}
														readOnly={!hasEditProjectWriteAccess}
														required={metadataParameter.mandatory}
													/>
												)}

												{metadataParameter.type ===
													ProjectMetadataType.Dropdown && (
													<Controller
														name={`${metadataParameter.name}`}
														control={control}
														render={({ field: { value, onBlur } }) => (
															<CreatableSelect
																label={translate(
																	() => metadataParameter.label
																)}
																tooltipPlace="top"
																tooltipComponent={
																	<InfoTooltip
																		marginOffset={{
																			left: 0.4,
																			bottom: 0.4
																		}}
																		iconVisible
																		text={translate(
																			() =>
																				metadataParameter.description
																		)}
																	/>
																}
																placeholder={translate(
																	() =>
																		metadataParameter.placeholder
																)}
																value={
																	value
																		? {
																				value: value.toString(),
																				label: translate(
																					() =>
																						metadataParameter.categories.find(
																							p =>
																								p.id ===
																								value
																						)?.name ??
																						''
																				)
																		  }
																		: undefined
																}
																items={metadataParameter.categories.map(
																	option => ({
																		label: translate(
																			() => option.name
																		),
																		value: `${option.id}`
																	})
																)}
																error={
																	errors[metadataParameter.name]
																		?.message
																}
																onClear={() => {
																	setValue(
																		`${metadataParameter.name}`,
																		'',
																		{
																			shouldDirty: true
																		}
																	);
																}}
																borderError={
																	!!errors[metadataParameter.name]
																		?.message
																}
																required={
																	metadataParameter.mandatory
																}
																onValueSelected={selectedValue =>
																	selectedValue &&
																	setValue(
																		`${metadataParameter.name}`,
																		selectedValue,
																		{
																			shouldDirty: true
																		}
																	)
																}
																readOnly={
																	!hasEditProjectWriteAccess
																}
																hint={translate(
																	() =>
																		metadataParameter.categories.find(
																			p => p.id === value
																		)?.description ?? ''
																)}
																onBlur={onBlur}
																scrollIntoView
															/>
														)}
													/>
												)}
											</Column>
										))}
									</Row>
								</div>
							);
						})}
					</>
				)}

				{showAddons && userAddons && activeUserAddons && (
					<>
						<Spacer size={s => s.l} />
						<Flex align={a => a.center}>
							<Typography.Paragraph>
								{translate(dict => dict.accountUM.tabs.addons)}
							</Typography.Paragraph>
						</Flex>
						<Spacer size={s => s.s} />
						<Controller
							control={control}
							name="activeAddonCodes"
							render={({ field: { value: currentEnabledAddons = [] } }) => (
								<AddonsToggles
									addons={userAddons}
									enabledAddons={currentEnabledAddons as SubscriptionAddonCode[]}
									activeUserAddons={activeUserAddons}
									onAddonClicked={(addonCode: SubscriptionAddonCode) =>
										toggleAddon(
											currentEnabledAddons as SubscriptionAddonCode[],
											addonCode
										)
									}
								/>
							)}
						/>
					</>
				)}
			</Form>

			{isProjectOwner && (
				<Flex flex={1} align={a => a.end} marginOffset={{ top: 2.4 }}>
					<Button
						title={translate(dict => dict[baseDictKey].delete)}
						variant={v => v.link}
						paddingOffset={{ all: 0 }}
						onClick={onDelete}
					/>
				</Flex>
			)}
		</Modal>
	);
}
