import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useInView } from 'react-intersection-observer';
import { PreviewDPAInput, ProjectDPASignData } from 'api/data/projects';
import { CreateProject, Project } from 'store/data/projects';
import { SetState } from 'types/index';
import { DPAContainer } from './ProjectDPAPreview.style';
import { InfoMessage } from 'components/UI/InfoMessage';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { useTranslation, usePreviewProjectDPA } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

interface Props {
	draftProject: CreateProject | Project | null;
	termsState: {
		terms: boolean;
		setTerms: SetState<boolean>;
	};
	readDPAState: {
		readDPA: boolean;
		setReadDPA: SetState<boolean>;
	};
}

export function ProjectDPAPreview({
	draftProject,
	termsState: { terms, setTerms },
	readDPAState: { readDPA, setReadDPA }
}: Props) {
	const { translate } = useTranslation();

	const { getValues } = useFormContext<ProjectDPASignData>();

	const [{ data: previewDPAFile }, { handler: previewDPA }] = usePreviewProjectDPA();

	const [ref, inView] = useInView({ threshold: 0 });

	// number of PDF pages as mappable array - array item is the page number
	const [pages, setPages] = useState<number[]>([]);

	const [renderInViewRef, setRenderInViewRef] = useState(false);

	useEffectOnce(() => {
		if (!previewDPAFile) handlePreviewDPA();
	});

	useEffect(() => {
		if (inView && !readDPA) setReadDPA(true);
	}, [readDPA, inView]);

	function handlePreviewDPA() {
		if (!draftProject) return;

		const formValues = { ...getValues() };

		const previewInput: PreviewDPAInput = {
			dpa: formValues,
			project: {
				projectName: draftProject.projectName
			}
		};

		previewDPA(previewInput);
	}

	function onDocumentLoad(numPages: number) {
		const state = [...new Array(numPages)].fill(null).map((_, i) => i + 1);

		setPages(state);

		// DEBOUNCE DUE TO PRINTING TO SCREEN TIMING
		setTimeout(() => setRenderInViewRef(true), 100);
	}

	return (
		<>
			<InfoMessage
				message={translate(
					({ projects }) => projects.createAndImport.DPA.preview.readThrough
				)}
			/>

			<Spacer size={s => s.m} />

			{/* PREVIEW DPA */}
			<DPAContainer>
				{previewDPAFile ? (
					<Document
						file={previewDPAFile.signedS3URL}
						loading={
							<Typography.Paragraph>
								{translate(
									({ projects }) => projects.createAndImport.DPA.preview.loading
								)}
							</Typography.Paragraph>
						}
						error={
							<Typography.Paragraph>
								{translate(
									({ projects }) => projects.createAndImport.DPA.preview.error
								)}
							</Typography.Paragraph>
						}
						onLoadSuccess={info => onDocumentLoad(info.numPages)}
					>
						{pages.map(pageNumber => (
							<Page
								key={`page-${pageNumber}`}
								pageNumber={pageNumber}
								renderAnnotationLayer={false}
								width={605} // NARROW IT SO WE DONT HAVE HORIZONTAL SCROLL
							/>
						))}

						{renderInViewRef && <div ref={ref} />}
					</Document>
				) : (
					<Typography.Paragraph paddingOffset={{ y: 0.8, x: 1.6 }}>
						{translate(({ projects }) => projects.createAndImport.DPA.preview.loading)}
					</Typography.Paragraph>
				)}
			</DPAContainer>

			<Spacer size={s => s.m} />

			{/* TERMS */}
			<Checkbox
				label={translate(({ projects }) => projects.createAndImport.DPA.terms)}
				onClick={() => setTerms(state => !state)}
				checked={terms}
				disabled={!readDPA}
			/>
		</>
	);
}
