import { getPermissions } from 'helpers/projects';
import { useSelector } from 'hooks/utils';
import { selectProjectPermissions, ActionTypes } from 'store/data/projects';
import { useActivities } from '../utils/useActivities';
import { useIsProjectOwner } from '../data/projects/useIsProjectOwner';

interface Props {
	projectId?: string;
}

export function usePermissions(options?: Props) {
	const projectId = options?.projectId;

	const basePermissions = useSelector(state =>
		selectProjectPermissions(state.data.projects, projectId)
	);
	const isOwner = useIsProjectOwner(projectId ?? undefined);

	const [{ loading }] = useActivities([
		ActionTypes.GET_PROJECT,
		ActionTypes.GET_PROJECTS,
		ActionTypes.GET_PROMS
	]);
	const permissions = getPermissions(basePermissions);

	return {
		...{
			...permissions,
			hasOwnershipAllAccess: !!permissions.hasOwnershipAllAccess && isOwner
		},
		loading,
		fetched: !!permissions
	};
}
