import {
	isSerializedContent,
	generateVariableTextNode,
	generateTextNode
} from './parsers/serializer/serializer';
import type {
	ArithmeticNode,
	CategoryNode,
	ContentMap,
	JsonLogic,
	JsonLogicMap,
	LogicalNode,
	ComparisonNode,
	TokenMap,
	VariableNode,
	Variable,
	SerializedContent,
	SerializedContentWithPositions,
	SerializedDoc,
	SerializedDocWithPositions
} from 'api/data/variables/types';
import { generateCalculationId } from 'helpers/variables/variableHelpers';
import { nanoid as generate } from 'nanoid';
import { generatePlaceholderTextNode } from './parsers/serializer/serializer';
import { cloneDeep } from 'lodash';
import { Colors } from 'environment';
import { ArithmeticOperator, ComparisonOperator } from 'types/data/variables/constants';
import { ExtensionType, SubType, TokenType } from 'types/UI/calculatedEditor/constants';
import type {
	GeneralTokenizerState,
	NumberToken,
	OperatorToken,
	ParenthesisToken,
	PlaceholderToken,
	Token,
	VariableToken,
	CategoryToken
} from 'types/UI/calculatedEditor/types';
import { tokenGenerator, generateMergeContent } from './tokenGenerator';

// ====================================================================================================
// ======================================== Editor HELPERS ============================================
// ====================================================================================================

export function isArithmeticOperatorToken(token: Token): token is OperatorToken {
	return [SubType.Plus, SubType.Minus, SubType.Multiply, SubType.Divide].includes(token.subType);
}

export function isComparisonOperatorToken(token: Token): token is OperatorToken {
	return [
		SubType.GreaterThan,
		SubType.GreaterThanOrEqual,
		SubType.Equals,
		SubType.LessThan,
		SubType.LessThanOrEqual
	].includes(token.subType);
}

export function diffContent(
	currentContent?: SerializedDoc | SerializedContent | null,
	previousContent?: SerializedDoc | SerializedContent | null
): boolean {
	if (!currentContent || !previousContent) return true;

	// Check if types are the same
	if (currentContent.type !== previousContent.type) return true;

	// Check if texts are the same (if they are of type 'text')
	if (
		isSerializedContent(currentContent) &&
		isSerializedContent(previousContent) &&
		currentContent.type === 'text' &&
		currentContent.text !== previousContent.text
	)
		return true;

	if (currentContent.content && !previousContent.content) return true;

	if (!currentContent.content && previousContent.content) return true;

	if (
		currentContent.content &&
		previousContent.content &&
		currentContent.content.length !== previousContent.content.length
	)
		return true;

	// Check content lengths
	const contentA = currentContent.content || [];
	const contentB = previousContent.content || [];

	// Recursively diff each child node
	for (let i = 0; i < contentA.length; i++) {
		if (diffContent(contentA[i], contentB[i])) return true;
	}

	return false;
}

export function buildHelperTokenMap(tokens: Token[]): TokenMap {
	const tokenMap: TokenMap = {
		err: []
	};

	for (const token of tokens) {
		tokenMap.err.push(token);
	}

	return tokenMap;
}

// ====================================================================================================
// ======================================== VARIABLE MODAL HELPERS ====================================
// ====================================================================================================

export function initializeJsonLogicMapping(cases: JsonLogic[]): JsonLogicMap {
	const jsonLogicMapping: JsonLogicMap = {
		order: [],
		logics: {}
	};

	for (const logic of cases) {
		const id = generateCalculationId();

		jsonLogicMapping.order.push(id);

		jsonLogicMapping.logics[id] = logic;
	}

	return jsonLogicMapping;
}

// ====================================================================================================
// ======================================== HOOK HELPERS ==============================================
// ====================================================================================================

export function getEditorStateFromMap(tokenMap: TokenMap) {
	const editorState: GeneralTokenizerState = {
		categoryState: {
			activeCategoryTokens: []
		},
		keywordState: {
			activeKeywords: []
		},
		numberState: {
			activeNumbers: []
		},
		operatorState: {
			activeOperators: []
		},
		parenthesisState: {
			activeCloseParentheses: [],
			activeOpenParentheses: []
		},
		separatorState: {
			activeSeparatorTokens: []
		},
		variableState: {
			activeVariableTokens: []
		},
		placeholderState: {
			activePlaceholderTokens: []
		}
	};

	const allTokens = getAllTokensFromMap(tokenMap);

	for (let i = 0; i < allTokens.length; i++) {
		const token = allTokens[i];
		switch (token.type) {
			case TokenType.Identifier: {
				if (token.subType === SubType.Category) {
					editorState.categoryState.activeCategoryTokens.push(token);
				} else {
					editorState.variableState.activeVariableTokens.push(token);
				}
				break;
			}

			case TokenType.Keyword: {
				editorState.keywordState.activeKeywords.push(token);
				break;
			}

			case TokenType.Number: {
				editorState.numberState.activeNumbers.push(token);
				break;
			}

			case TokenType.Operator: {
				editorState.operatorState.activeOperators.push(token);
				break;
			}

			case TokenType.Parenthesis: {
				if (token.subType === SubType.OpenParenthesis) {
					editorState.parenthesisState.activeOpenParentheses.push(token);
				} else {
					editorState.parenthesisState.activeCloseParentheses.push(token);
				}
				break;
			}

			case TokenType.Separator: {
				editorState.separatorState.activeSeparatorTokens.push(token);
				break;
			}

			case TokenType.Placeholder: {
				editorState.placeholderState.activePlaceholderTokens.push(token);
				break;
			}
		}
	}

	return editorState;
}

export function getEmptyEditorState(): GeneralTokenizerState {
	return {
		categoryState: {
			activeCategoryTokens: []
		},
		keywordState: {
			activeKeywords: []
		},
		numberState: {
			activeNumbers: []
		},
		operatorState: {
			activeOperators: []
		},
		parenthesisState: {
			activeCloseParentheses: [],
			activeOpenParentheses: []
		},
		separatorState: {
			activeSeparatorTokens: []
		},
		variableState: {
			activeVariableTokens: []
		},
		placeholderState: {
			activePlaceholderTokens: []
		}
	};
}

export function recomputeContentAndStateAfterJsonLogicDeletion(
	editorContent: SerializedDocWithPositions | null,
	contentMap: ContentMap,
	tokenMap: TokenMap,
	logicIdToRemove: string
): {
	content: SerializedDocWithPositions | null;
	contentMap: ContentMap;
	tokenMap: TokenMap;
} {
	const hasCommaAtEnd =
		tokenMap[logicIdToRemove].length > 0
			? tokenMap[logicIdToRemove][tokenMap[logicIdToRemove].length - 1].type ===
			  TokenType.Separator
			: false;

	const { newContentMap, newDoc, newTokenMap } = updateContentAndMapsAfterJsonLogicDeletion(
		editorContent,
		hasCommaAtEnd,
		contentMap,
		tokenMap,
		logicIdToRemove
	);

	return {
		content: newDoc,
		contentMap: newContentMap,
		tokenMap: newTokenMap
	};
}

export function recomputeContentAndState(
	content: SerializedDocWithPositions | null,
	contentMap: ContentMap,
	tokenMap: TokenMap,
	path: string,
	variables: Variable[],
	value?:
		| string
		| number
		| VariableNode
		| CategoryNode
		| ArithmeticNode
		| LogicalNode
		| ComparisonNode
) {
	if (!content) return { content: null, contentMap: contentMap, tokenMap: tokenMap };

	const pathKeys = path.split('.');

	const logicId = pathKeys.shift() as string;

	const newId = generate();

	const isNewValueOperator =
		!!value &&
		typeof value === 'string' &&
		(checkIfIsComparisonOperator(value) || checkIfIsArithmeticOperator(value));

	const tokenToReplace = getTokenForReplacing(tokenMap, logicId, pathKeys, isNewValueOperator);

	if (tokenToReplace) {
		const shouldAddArithmeticTokens =
			typeof value === 'object' &&
			[
				ArithmeticOperator.Addition,
				ArithmeticOperator.Subtraction,
				ArithmeticOperator.Multiplication,
				ArithmeticOperator.Division
			].includes(Object.keys(value)[0] as ArithmeticOperator);
		const newTokens = shouldAddArithmeticTokens
			? getArithmeticTokensFromValue(
					tokenToReplace,
					value as ArithmeticNode,
					variables,
					tokenMap,
					logicId
			  )
			: getNewTokenFromValue(newId, tokenToReplace, null, value, variables);

		const variable =
			value && typeof value === 'object' && 'var' in value
				? variables.find(v => v.name === value.var[0])
				: undefined;

		if (newTokens !== null) {
			let updatedContent = copyDoc(content);

			if (!Array.isArray(newTokens)) {
				const { doc: updatedDoc } = updateContent(
					content,
					contentMap,
					logicId,
					tokenToReplace,
					newTokens as Token,
					variable
				);

				updatedContent = updatedDoc;

				const wasPlaceholderToken = tokenToReplace.type === TokenType.Placeholder;
				const isNewTokenPlaceholder = newTokens.type === TokenType.Placeholder;

				const startAtSamePosition = newTokens.pos.start === tokenToReplace.pos.start;

				// offset by one if the token to replace was a placeholder for the whitespace
				const shouldOffsetByOne = wasPlaceholderToken && !startAtSamePosition;

				const shouldAddPlaceholder = isNewTokenPlaceholder && !wasPlaceholderToken;

				const offset = shouldOffsetByOne
					? newTokens.pos.end - tokenToReplace.pos.end + (!shouldAddPlaceholder ? 1 : 0)
					: newTokens.pos.end - tokenToReplace.pos.end - (!shouldAddPlaceholder ? 0 : 1);

				regenerateTokenMap(
					tokenMap,
					offset,
					tokenToReplace.pos.start,
					newTokens,
					tokenToReplace
				);
			} else {
				const { doc: updatedDoc, offsets } = updateContent(
					content,
					contentMap,
					logicId,
					tokenToReplace,
					newTokens,
					variable
				);

				updatedContent = updatedDoc;

				const offsetValue = getOffsetValue(offsets);

				regenerateTokenMap(
					tokenMap,
					offsetValue,
					tokenToReplace.pos.start,
					newTokens,
					tokenToReplace
				);
			}

			return {
				content: updatedContent,
				contentMap: contentMap,
				tokenMap: tokenMap
			};
		}
	}

	return {
		content: null,
		contentMap: contentMap,
		tokenMap: tokenMap
	};
}

export function recomputeContentAndStateAfterLogicalOperationChange(
	doc: SerializedDocWithPositions | null,
	contentMap: ContentMap,
	tokenMap: TokenMap,
	path: string,
	value: string
) {
	if (!doc) return { content: null, contentMap, tokenMap };

	const logicId = path.split('.')[0];

	const tokens = tokenMap[logicId];

	const isAnd = value === 'and';

	const currentLogicTokens = getLogicalExpressionTokens(tokens);

	const wasSingleExpression = !currentLogicTokens.some(t => t.subType === SubType.And);

	if (isAnd) {
		const { offsets, newTokens } = addLogicalExpressionToContent(
			doc,
			currentLogicTokens,
			contentMap,
			wasSingleExpression
		);

		doc.pos.end = doc.content[doc.content.length - 1].pos.end;

		offsetExistingTokens(tokenMap, offsets, wasSingleExpression, false, logicId);

		addNewTokensToTokenMap(tokenMap, newTokens, logicId);
	} else {
		const tokensToRemove = getTokensForRemoval(path, currentLogicTokens);

		const shouldBeSingleExpression = checkRemainingTokensIfSingleExpression(
			currentLogicTokens,
			tokensToRemove
		);

		let tokenBeforeAnd: Token | null = null;
		if (!shouldBeSingleExpression) {
			const andToken = tokensToRemove[0];

			const andIndex = currentLogicTokens.findIndex(t => t.id === andToken.id);

			tokenBeforeAnd = currentLogicTokens[andIndex - 1];
		}

		const lastValidToken = [...currentLogicTokens]
			.reverse()
			.find(t =>
				shouldBeSingleExpression
					? t.pos.end <= tokensToRemove[1].pos.start
					: tokenBeforeAnd
					? t.pos.end < tokenBeforeAnd.pos.start
					: t.pos.end < tokensToRemove[0].pos.start
			);

		const { offsets } = removeTokensFromContent(
			doc,
			tokensToRemove,
			contentMap,
			shouldBeSingleExpression,
			lastValidToken
		);

		removeTokensFromTokenMap(tokenMap, tokensToRemove, logicId);

		offsetExistingTokens(
			tokenMap,
			offsets,
			wasSingleExpression,
			shouldBeSingleExpression,
			logicId
		);
	}

	return { content: doc, contentMap, tokenMap };
}

export function recomputeContentAndStateAfterOperandDeletion(
	editorContent: SerializedDocWithPositions | null,
	contentMap: ContentMap,
	tokenMap: TokenMap,
	path: string
) {
	if (!editorContent) return { content: null, contentMap: contentMap, tokenMap: tokenMap };

	const pathKeys = path.split('.');

	const logicId = pathKeys.shift() as string;

	const logicTokens = tokenMap[logicId];

	const tokensToRemove = getTokensFromPath(pathKeys, logicTokens);

	if (tokensToRemove) {
		const { offsets, token } = removeTokensAndReplaceWithPlaceholder(
			editorContent,
			tokensToRemove,
			contentMap
		);

		removeTokensFromTokenMap(tokenMap, tokensToRemove, logicId);

		offsetExistingTokens(tokenMap, offsets, false, false, logicId);

		if (token) {
			addNewTokensToTokenMap(tokenMap, [token], logicId);
		}
	}

	return { content: editorContent, tokenMap: tokenMap, contentMap: contentMap };
}

// ====================================================================================================
// ======================================== HOOK UTILITIES ============================================
// ====================================================================================================

function getAllTokensFromMap(tokenMap: TokenMap) {
	const tokens: Token[] = [];

	for (const tokensOfLogic of Object.values(tokenMap)) {
		tokens.push(...tokensOfLogic);
	}

	return tokens;
}

function updateContentAndMapsAfterJsonLogicDeletion(
	doc: SerializedDocWithPositions | null,
	hasCommaAtEnd: boolean,
	contentMap: ContentMap,
	tokenMap: TokenMap,
	logicIdToRemove: string
) {
	if (!doc)
		return {
			newDoc: null,
			newContentMap: contentMap,
			newTokenMap: tokenMap
		};

	const newDoc: SerializedDocWithPositions = {
		type: 'doc',
		content: [],
		pos: {
			start: 0,
			end: 0
		}
	};

	const { startPosition: startRemovePosition, endPosition: endRemovePosition } =
		contentMap[logicIdToRemove];

	let offset = endRemovePosition - startRemovePosition;

	// remove the content between the start and end positions and offset the positions of the content after the end position
	for (let i = 0; i < doc.content.length; i++) {
		const paragraph = doc.content[i];

		if (
			paragraph.type === 'paragraph' &&
			paragraph.content &&
			paragraph.pos.start >= endRemovePosition
		) {
			// offset the position of the content and the paragraph tag
			paragraph.pos.start = paragraph.pos.start - offset;
			paragraph.pos.end = paragraph.pos.end - offset;

			// offset the positions of the content inside the paragraph
			for (let j = 0; j < paragraph.content.length; j++) {
				const textContent = paragraph.content[j];

				textContent.pos.start = textContent.pos.start - offset;
				textContent.pos.end = textContent.pos.end - offset;
			}

			newDoc.content.push(paragraph);

			continue;
		}

		if (paragraph.pos.start <= startRemovePosition && paragraph.pos.end >= endRemovePosition) {
			const { updatedParagraph } = removeContentFromParagraphV2(
				paragraph,
				startRemovePosition,
				endRemovePosition
			);

			if (updatedParagraph) {
				newDoc.content.push(updatedParagraph);
			} else {
				// Add 2 positions to offset if the paragraph is empty so that we remove the opening and closing paragraph tags
				offset += 2;
			}

			continue;
		}

		if (
			paragraph &&
			paragraph.type === 'paragraph' &&
			paragraph.content &&
			paragraph.pos.end === startRemovePosition &&
			!hasCommaAtEnd
		) {
			const updatedContent = removeSeparatorFromContent(paragraph, contentMap);

			newDoc.content.push(updatedContent);
			newDoc.pos.end = updatedContent.pos.end;
		} else {
			newDoc.content.push(paragraph);
			newDoc.pos.end = paragraph.pos.end;
		}
	}

	if (newDoc.content.length === 0) {
		newDoc.content.push({
			type: 'paragraph',
			attrs: {
				indent: null
			},
			pos: {
				start: 0,
				end: 1
			}
		});
	}

	// if the new doc has 1 paragraph, check if the last token is a separator and remove it and the content at the same position
	if (newDoc.content.length === 1) {
		const lastContent = newDoc.content[0];

		let lastValidToken: Token | null = null;

		if (lastContent.type === 'paragraph') {
			if (lastContent.content) {
				const lastTextContent = lastContent.content[lastContent.content.length - 1];

				const tokens = tokenMap[logicIdToRemove];

				for (let i = tokens.length - 1; i >= 0; i--) {
					if (tokens[i].pos.start < startRemovePosition) {
						lastValidToken = tokens[i];
						break;
					}
				}

				if (
					lastTextContent.type === 'text' &&
					lastTextContent.text &&
					isLastCharacterSeparator(
						lastValidToken,
						lastTextContent.pos.start,
						lastTextContent.text
					)
				) {
					lastTextContent.text = lastTextContent.text.slice(0, -2);
					lastTextContent.pos.end = lastTextContent.pos.end - 2;

					lastContent.pos.start = 0;

					// increase position for ending paragraph tag
					lastContent.pos.end = lastTextContent.pos.end + 1;
				}
			}
		}
	}

	newDoc.pos.end =
		newDoc.content.length > 0 ? newDoc.content[newDoc.content.length - 1].pos.end : 0;

	const newContentMap = removePositionsAndUpdateContentMap(
		contentMap,
		{
			start: startRemovePosition,
			end: endRemovePosition
		},
		offset
	);

	const newTokenMap = removePositionsAndUpdateTokenMap(
		tokenMap,
		{
			start: startRemovePosition,
			end: endRemovePosition
		},
		offset
	);

	return { newDoc, newContentMap, newTokenMap };
}

function getTokenForReplacing(
	tokenMap: TokenMap,
	logicId: string,
	pathKeys: string[],
	isNewValueOperator: boolean
) {
	const tokens = tokenMap[logicId];

	return getTokenFromPath(pathKeys, tokens, isNewValueOperator);
}

function getTokenFromPath(
	pathKeys: string[],
	tokens: Token[],
	shouldReturnOperator: boolean
): Token | null {
	if (pathKeys.length === 0) return null;

	if (pathKeys[0] === 'if' && Number(pathKeys[1]) === 1) {
		return tokens.find(t => t.subType === SubType.Category) ||
			tokens[tokens.length - 1].type === TokenType.Placeholder
			? tokens[tokens.length - 1]
			: tokens[tokens.length - 2].type === TokenType.Placeholder
			? tokens[tokens.length - 2]
			: null;
	}

	const isAndExpression = pathKeys.includes('and');

	let tokenSearchStartIndex = tokens.findIndex(
		t => t.subType === SubType.OpenParenthesis && t.depth === 0
	);
	let tokenSearchEndIndex = tokens.findIndex(
		t => t.subType === SubType.CloseParenthesis && t.depth === 0
	);

	if (isAndExpression) {
		const andKeyIndex = pathKeys.indexOf('and');
		const keyAfterAnd = Number(pathKeys[andKeyIndex + 1]);

		const { startIndex: andStartIndex, endIndex: andEndIndex } = computeIndicesByAndTokens(
			tokens,
			keyAfterAnd
		);

		tokenSearchStartIndex = andStartIndex;
		tokenSearchEndIndex = andEndIndex;
	}

	const logicalKeys = processKeys(pathKeys);

	const operatorKey = logicalKeys.shift() as string;
	const keyAfterOperator = logicalKeys.shift() as string;

	const remainingTokens = tokens.slice(tokenSearchStartIndex, tokenSearchEndIndex + 1);

	if (
		remainingTokens[0].subType === SubType.OpenParenthesis &&
		remainingTokens[remainingTokens.length - 1].subType === SubType.CloseParenthesis &&
		remainingTokens[0].matchingPairId === remainingTokens[remainingTokens.length - 1].id
	) {
		remainingTokens.shift();
		remainingTokens.pop();
	}

	return findTokenToReplace(
		remainingTokens,
		operatorKey,
		keyAfterOperator,
		logicalKeys,
		isAndExpression ? 1 : 0,
		shouldReturnOperator
	);
}

function findTokenToReplace(
	tokens: Token[],
	operatorKey: string | undefined,
	keyAfterOperator: string | undefined,
	remainingKeys: string[],
	contextLevel: number,
	shouldReturnOperator: boolean
): Token | null {
	if (tokens.length === 1) {
		return tokens[0];
	}

	if (shouldReturnOperator) {
		if (tokens.length === 3) {
			return tokens[1];
		} else {
			if (!operatorKey && !keyAfterOperator && remainingKeys.length === 0) {
				const operatorToken = tokens.find(
					t => t.type === TokenType.Operator && t.depth === contextLevel + 1
				) as OperatorToken;

				return operatorToken;
			}
		}
	}

	const operatorIndex = tokens.findIndex(
		t =>
			t.value === operatorKey && t.type === TokenType.Operator && t.depth === contextLevel + 1
	);

	if (operatorIndex === -1) return null;

	const direction = Number(keyAfterOperator);

	const { startIndex, endIndex } = computeIndicesByOperatorIndex(
		tokens,
		operatorIndex,
		direction
	);

	const remainingTokens = tokens.slice(startIndex, endIndex + 1);

	if (
		remainingTokens[0].subType === SubType.OpenParenthesis &&
		remainingTokens[remainingTokens.length - 1].subType === SubType.CloseParenthesis
	) {
		remainingTokens.shift();
		remainingTokens.pop();
	}

	return findTokenToReplace(
		remainingTokens,
		remainingKeys[0],
		remainingKeys[1],
		remainingKeys.slice(2),
		contextLevel + 1,
		shouldReturnOperator
	);
}

function computeIndicesByOperatorIndex(
	tokens: Token[],
	operatorIndex: number,
	direction: number
): { startIndex: number; endIndex: number } {
	let startIndex = 0;
	let endIndex = tokens.length - 1;

	if (direction === 0) {
		startIndex = 0;
		endIndex = operatorIndex - 1;
	} else {
		startIndex = operatorIndex + 1;
		endIndex = tokens.length - 1;
	}

	return { startIndex, endIndex };
}

function computeIndicesByAndTokens(
	tokens: Token[],
	searchSection: number
): { startIndex: number; endIndex: number } {
	const andIndices: number[] = [];

	for (let i = 0; i < tokens.length; i++) {
		if (tokens[i].subType === SubType.And) {
			andIndices.push(i);
		}
	}

	if (searchSection === 0) {
		if (tokens[0].subType === SubType.If && tokens[1].subType === SubType.OpenParenthesis) {
			return { startIndex: 2, endIndex: andIndices[0] - 1 };
		} else {
			return { startIndex: 0, endIndex: andIndices[0] - 1 };
		}
	}

	if (searchSection === andIndices.length || (andIndices.length <= 1 && searchSection === 1)) {
		// If searchSection points to the last AND, then endIndex is before the closing parenthesis with depth 0
		let endIndex = tokens.length - 1;
		for (let i = andIndices[andIndices.length - 1] + 1; i < tokens.length; i++) {
			const token = tokens[i];
			if (token.subType === SubType.CloseParenthesis && token.depth === 0) {
				endIndex = i - 1;
				break;
			}
		}
		return { startIndex: andIndices[andIndices.length - 1] + 1, endIndex };
	}

	return {
		startIndex: andIndices[searchSection - 1] + 1,
		endIndex: andIndices[searchSection] - 1
	};
}

function checkRemainingTokensIfSingleExpression(currentTokens: Token[], tokensToRemove: Token[]) {
	const remainingTokens = currentTokens.filter(
		t => !tokensToRemove.some(tokenToRemove => tokenToRemove.id === t.id)
	);

	return !remainingTokens.some(token => token.subType === SubType.And);
}

function processKeys(keys: string[]): string[] {
	const andIndex = keys.indexOf('and');
	if (andIndex !== -1) {
		return keys.slice(andIndex + 2);
	} else {
		return keys.slice(2);
	}
}

function checkIfIsComparisonOperator(operator: string) {
	return [
		ComparisonOperator.Equals,
		ComparisonOperator.GreaterThan,
		ComparisonOperator.GreaterThanOrEqual,
		ComparisonOperator.LessThan,
		ComparisonOperator.LessThanOrEqual
	].includes(operator as ComparisonOperator);
}

function checkIfIsArithmeticOperator(operator: string) {
	return [
		ArithmeticOperator.Addition,
		ArithmeticOperator.Subtraction,
		ArithmeticOperator.Multiplication,
		ArithmeticOperator.Division
	].includes(operator as ArithmeticOperator);
}

function getNewTokenFromValue(
	id: string,
	currentToken: Token | null,
	startPos: number | null,
	value:
		| string
		| number
		| VariableNode
		| CategoryNode
		| ArithmeticNode
		| LogicalNode
		| ComparisonNode
		| undefined
		| null,
	variables: Variable[]
) {
	if (value === undefined || (currentToken === null && startPos === null)) return null;

	let token: Token | null = null;

	if (typeof value === 'number') {
		const isInteger = Number.isInteger(value);

		let numberTokenStartPosition = 0;

		if (currentToken) {
			numberTokenStartPosition =
				currentToken.type === TokenType.Placeholder
					? currentToken.pos.start + 1
					: currentToken.pos.start;
		} else if (startPos) {
			numberTokenStartPosition = startPos;
		}

		token = {
			id,
			value: value.toString(),
			type: TokenType.Number,
			subType: isInteger ? SubType.Integer : SubType.Float,
			pos: {
				// add a space before and after the number
				start: numberTokenStartPosition,
				end: numberTokenStartPosition + value.toString().length
			}
		} as NumberToken;
	}

	if (typeof value === 'string' || value === null) {
		if (value === '' || value === null) {
			let placeholderTokenStartPosition = 0;

			if (currentToken) {
				const isPlaceholderToken = currentToken.type === TokenType.Placeholder;
				placeholderTokenStartPosition = isPlaceholderToken
					? currentToken.pos.start
					: currentToken.pos.start - 1;
			} else if (startPos) {
				placeholderTokenStartPosition = startPos;
			}

			token = {
				id,
				value: ' ',
				type: TokenType.Placeholder,
				subType: SubType.None,
				pos: {
					start: placeholderTokenStartPosition,
					end: placeholderTokenStartPosition + 1
				}
			} as PlaceholderToken;
		}

		if (value && (checkIfIsComparisonOperator(value) || checkIfIsArithmeticOperator(value))) {
			let operatorTokenStartPosition = 0;

			if (currentToken) {
				operatorTokenStartPosition = currentToken.pos.start;
			} else if (startPos) {
				operatorTokenStartPosition = startPos;
			}
			token = {
				id,
				value,
				type: TokenType.Operator,
				subType: getOperatorSubtype(value),
				depth: (currentToken as OperatorToken).depth,
				color: (currentToken as OperatorToken).color,
				pos: {
					start: operatorTokenStartPosition,
					end: operatorTokenStartPosition + value.length
				}
			} as OperatorToken;
		}
	}

	if (value && typeof value === 'object') {
		if ('var' in value) {
			const variable = variables.find(v => v.name === value.var[0]);

			if (!variable) return null;

			let variableTokenStartPosition = 0;

			if (currentToken) {
				variableTokenStartPosition =
					currentToken.type === TokenType.Placeholder
						? currentToken.pos.start + 1
						: currentToken.pos.start;
			} else if (startPos) {
				variableTokenStartPosition = startPos;
			}

			token = {
				id: `${variable.name}__${id}`,
				value: variable.label,
				type: TokenType.Identifier,
				subType: SubType.Variable,
				pos: {
					start: variableTokenStartPosition,
					end: variableTokenStartPosition + variable.label.length
				}
			} as VariableToken;
		}

		if ('catVal' in value) {
			let categoryTokenStartPosition = 0;

			if (currentToken) {
				categoryTokenStartPosition = currentToken.pos.start;
			} else if (startPos) {
				categoryTokenStartPosition = startPos;
			}
			token = {
				id,
				value: value.catVal,
				type: TokenType.Identifier,
				subType: SubType.Category,
				pos: {
					start: categoryTokenStartPosition,
					end: categoryTokenStartPosition + value.catVal.length
				}
			} as CategoryToken;
		}
	}

	return token;
}

function updateContent(
	doc: SerializedDocWithPositions,
	contentMap: ContentMap,
	logicId: string,
	currentToken: Token,
	newTokens: Token | Token[],
	variable?: Variable
) {
	const offsets: [number, number][] = [];
	if (doc.content.length > 0) {
		const isCurrTokenPlaceholder = currentToken.type === TokenType.Placeholder;

		if (!Array.isArray(newTokens)) {
			const isNewTokenPlaceholder = newTokens.type === TokenType.Placeholder;
			const offset = isCurrTokenPlaceholder
				? newTokens.pos.end - currentToken.pos.end + (!isNewTokenPlaceholder ? 1 : 0)
				: newTokens.pos.end - currentToken.pos.end - (!isNewTokenPlaceholder ? 0 : 1);

			for (let i = 0; i < doc.content.length; i++) {
				const paragraph = doc.content[i];
				if (
					paragraph.content &&
					paragraph.pos.start < currentToken.pos.start &&
					paragraph.pos.end > currentToken.pos.start
				) {
					replaceTokenInContent(
						doc,
						contentMap,
						currentToken,
						newTokens,
						offset,
						logicId,
						isNewTokenPlaceholder,
						variable
					);

					break;
				}
			}
		} else {
			let modificationDone = false;
			let increaseStartPosition = false;

			for (const paragraph of doc.content) {
				if (
					paragraph.pos.start < currentToken.pos.start &&
					paragraph.pos.end > currentToken.pos.end
				) {
					modificationDone = addNewTokensToContent(
						paragraph,
						newTokens,
						currentToken,
						contentMap,
						logicId,
						offsets,
						variable
					);
				}

				const offset = getOffsetValue(offsets);

				if (increaseStartPosition) {
					paragraph.pos.start += offset;

					regenerateTextContentPositions(paragraph, offset, currentToken.pos.start);
				}

				if (modificationDone) {
					paragraph.pos.end += offset;

					if (!increaseStartPosition) {
						increaseStartPosition = true;
					}
				}
			}
		}
	}

	doc.pos.end = doc.content[doc.content.length - 1].pos.end;

	const updatedDoc: SerializedDocWithPositions = {
		type: 'doc',
		content: doc.content,
		pos: doc.pos
	};

	return {
		doc: updatedDoc,
		offsets: offsets
	};
}

function regenerateTokenMap(
	tokenMap: TokenMap,
	offset: number,
	startPosition: number,
	newTokens: Token | Token[],
	oldToken: Token
) {
	for (const tokens of Object.values(tokenMap)) {
		for (let i = 0; i < tokens.length; i++) {
			const token = tokens[i];

			if (oldToken.id === token.id) {
				if (!Array.isArray(newTokens)) {
					tokens[i] = newTokens;
				} else {
					tokens.splice(i, 1, ...newTokens);

					i += newTokens.length - 1;
				}

				continue;
			}

			if (token.pos.start > startPosition) {
				token.pos.start += offset;
				token.pos.end += offset;
			}
		}
	}
}

function removeSeparatorFromContent(
	content: SerializedContentWithPositions,
	contentMap: ContentMap
) {
	if (!content.content) return content;

	const { start, end } = content.pos;

	for (const logicId in contentMap) {
		const { startPosition, endPosition } = contentMap[logicId];

		if (startPosition === start && endPosition === end) {
			const lastTextContent = content.content[content.content.length - 1];

			if (lastTextContent.type === 'text' && lastTextContent.text) {
				const text = lastTextContent.text;

				if (text[text.length - 1] === ',') {
					lastTextContent.text = text.slice(0, -2);
					lastTextContent.pos.end = lastTextContent.pos.end - 2;

					// increase position for ending paragraph tag
					content.pos.end = lastTextContent.pos.end + 1;
				}
			}
		}
	}

	return content;
}

function isLastCharacterSeparator(token: Token | null, nodeOffset: number, text?: string) {
	if (!text || !token) return false;

	if (token.type !== TokenType.Separator) return false;

	// check position of the separator token and delete the positions from the text content
	const separatorPosition = token.pos.start;

	const separatorIndex = separatorPosition - nodeOffset;

	const lastCharacter = text[separatorIndex];

	if (lastCharacter === ',') return true;
}

function regenerateTextContentPositions(
	paragraph: SerializedContentWithPositions,
	offset: number,
	startPosition: number
) {
	if (!paragraph.content) return;

	for (let i = 0; i < paragraph.content.length; i++) {
		const content = paragraph.content[i];

		if (content.pos.start > startPosition) {
			content.pos.start = content.pos.start + offset;
			content.pos.end = content.pos.end + offset;
		}
	}
}

function regenerateContentMapPositions(
	contentMap: ContentMap,
	logicId: string,
	offset: number,
	startPosition: number
) {
	for (const [id, values] of Object.entries(contentMap)) {
		if (id === logicId) {
			values.endPosition += offset;
		} else if (values.startPosition > startPosition) {
			values.startPosition += offset;
			values.endPosition += offset;
		}
	}
}

function replaceTokenInContent(
	doc: SerializedDocWithPositions,
	contentMap: ContentMap,
	currentToken: Token,
	newToken: Token,
	offset: number,
	logicId: string,
	isNewTokenPlaceholder: boolean,
	variable?: Variable
) {
	let modificationDone = false;
	let increaseStartPosition = false;

	for (const paragraph of doc.content) {
		if (paragraph.type === 'paragraph' && paragraph.content) {
			const newContent: SerializedContentWithPositions[] = [];
			for (let i = 0; i < paragraph.content.length; i++) {
				const textNode = paragraph.content[i];

				if (
					textNode.pos.start <= currentToken.pos.start &&
					textNode.pos.end >= currentToken.pos.end &&
					textNode.type === 'text' &&
					textNode.text
				) {
					const hasMark = !!textNode.marks && textNode.marks.length > 0;

					const hasPlaceholderMark =
						hasMark &&
						!!textNode.marks?.find(m => m.type === ExtensionType.PlaceholderTokenizer);

					const hasVariableMark =
						hasMark &&
						!!textNode.marks?.find(m => m.type === ExtensionType.VariableTokenizer);

					if (!hasPlaceholderMark && !hasVariableMark) {
						const beforeToken = textNode.text.substring(
							0,
							newToken.pos.start - textNode.pos.start
						);

						const afterToken = textNode.text?.substring(
							currentToken.pos.end -
								textNode.pos.start +
								(newToken.type !== TokenType.Placeholder ? 0 : 1)
						);

						if (isNewTokenPlaceholder) {
							newContent.push(...paragraph.content.slice(0, i));

							textNode.text = beforeToken;

							textNode.pos.end = newToken.pos.start;

							newContent.push(textNode);

							const placeholderTextNode = generatePlaceholderTextNode(
								newToken.id,
								newToken.pos.start
							);

							newContent.push(placeholderTextNode);

							newContent.push(generateTextNode(afterToken, newToken.pos.end));

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							newContent.push(...paragraph.content.slice(i + 1));

							paragraph.content = generateMergeContent(newContent);
						} else {
							textNode.text = beforeToken + newToken.value + afterToken;

							textNode.pos.end += offset;

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);
						}

						regenerateContentMapPositions(
							contentMap,
							logicId,
							offset,
							currentToken.pos.start
						);

						modificationDone = true;

						break;
					} else if (hasPlaceholderMark) {
						if (newToken.subType === SubType.Variable && variable) {
							const newContent = paragraph.content.slice(0, i);

							if (currentToken.type === TokenType.Placeholder) {
								const whitespaceBeforeVariable = generateTextNode(
									' ',
									currentToken.pos.start
								);

								newContent.push(whitespaceBeforeVariable);
							}

							const variableTextNode = generateVariableTextNode(
								variable,
								newToken.id.split('__')[1],
								currentToken.type === TokenType.Placeholder
									? currentToken.pos.start + 1
									: currentToken.pos.start
							);

							newContent.push(variableTextNode);

							if (currentToken.type === TokenType.Placeholder) {
								newContent.push(generateTextNode(' ', newToken.pos.end));
							}

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							newContent.push(...paragraph.content.slice(i + 1));

							paragraph.content = generateMergeContent(newContent);

							regenerateContentMapPositions(
								contentMap,
								logicId,
								offset,
								currentToken.pos.start
							);

							modificationDone = true;

							break;
						}

						if (newToken.type === TokenType.Number) {
							const numberTextNode = generateTextNode(
								` ${newToken.value} `,
								currentToken.pos.start
							);

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							paragraph.content[i] = numberTextNode;

							paragraph.content = generateMergeContent(paragraph.content);

							regenerateContentMapPositions(
								contentMap,
								logicId,
								offset,
								currentToken.pos.start
							);

							modificationDone = true;

							break;
						}

						if (
							newToken.type === TokenType.Identifier &&
							newToken.subType === SubType.Category
						) {
							const categoryTextNode = generateTextNode(
								`${newToken.value}`,
								currentToken.pos.start
							);

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							paragraph.content[i] = categoryTextNode;

							paragraph.content = generateMergeContent(paragraph.content);

							regenerateContentMapPositions(
								contentMap,
								logicId,
								offset,
								currentToken.pos.start
							);

							modificationDone = true;

							break;
						}

						if (newToken.type === TokenType.Placeholder) {
							break;
						}
					} else if (hasVariableMark) {
						if (newToken.subType === SubType.Variable && variable) {
							const newContent = paragraph.content.slice(0, i);

							if (currentToken.type === TokenType.Placeholder) {
								const whitespaceBeforeVariable = generateTextNode(
									' ',
									currentToken.pos.start
								);

								newContent.push(whitespaceBeforeVariable);
							}

							const variableTextNode = generateVariableTextNode(
								variable,
								newToken.id.split('__')[1],
								currentToken.type === TokenType.Placeholder
									? currentToken.pos.start + 1
									: currentToken.pos.start
							);

							newContent.push(variableTextNode);

							if (currentToken.type === TokenType.Placeholder) {
								newContent.push(generateTextNode(' ', newToken.pos.end));
							}

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							newContent.push(...paragraph.content.slice(i + 1));

							paragraph.content = generateMergeContent(newContent);

							regenerateContentMapPositions(
								contentMap,
								logicId,
								offset,
								currentToken.pos.start
							);

							modificationDone = true;

							break;
						}

						if (newToken.type === TokenType.Number) {
							const numberTextNode = generateTextNode(
								` ${newToken.value} `,
								newToken.pos.start
							);

							regenerateTextContentPositions(
								paragraph,
								offset,
								currentToken.pos.start
							);

							paragraph.content[i] = numberTextNode;

							paragraph.content = generateMergeContent(paragraph.content);

							regenerateContentMapPositions(
								contentMap,
								logicId,
								offset,
								currentToken.pos.start
							);

							modificationDone = true;

							break;
						}

						if (newToken.type === TokenType.Placeholder) {
							break;
						}
					}
				}
			}

			if (increaseStartPosition) {
				paragraph.pos.start += offset;

				regenerateTextContentPositions(paragraph, offset, currentToken.pos.start);
			}

			if (modificationDone) {
				paragraph.pos.end += offset;

				if (!increaseStartPosition) {
					increaseStartPosition = true;
				}
			}
		}
	}
}

function getOperatorSubtype(value: string) {
	switch (value) {
		case '+':
			return SubType.Plus;
		case '-':
			return SubType.Minus;
		case '*':
			return SubType.Multiply;
		case '/':
			return SubType.Divide;
		case '>':
			return SubType.GreaterThan;
		case '<':
			return SubType.LessThan;
		case '=':
			return SubType.Assign;
		case '<=':
			return SubType.LessThanOrEqual;
		case '>=':
			return SubType.GreaterThanOrEqual;
		case '==':
			return SubType.Equals;

		default:
			return SubType.None;
	}
}

function getLogicalExpressionTokens(tokens: Token[]): Token[] {
	// Find the first open parenthesis with depth 0
	const openParenthesis = tokens.find(
		token =>
			token.type === TokenType.Parenthesis &&
			token.subType === SubType.OpenParenthesis &&
			token.depth === 0
	);

	if (!openParenthesis) return [];

	const openIndex = tokens.indexOf(openParenthesis);

	// Find the closing parenthesis token that matches the first open parenthesis
	const closeToken = tokens.find(
		token => (token as ParenthesisToken).matchingPairId === openParenthesis.id
	);

	if (!closeToken) return [];

	const closeIndex = tokens.indexOf(closeToken);

	// Return tokens starting from the open parenthesis with depth 0 to its matching closing parenthesis
	return tokens.slice(openIndex, closeIndex + 1);
}

function addLogicalExpressionToContent(
	doc: SerializedDocWithPositions | null,
	logicalExpressionTokens: Token[],
	contentMap: ContentMap,
	wasSingleExpression: boolean
) {
	if (!doc) return { offsets: [], newTokens: [] };

	const firstParenthesis = logicalExpressionTokens[0] as ParenthesisToken;

	const start = firstParenthesis.pos.start;

	const lastParenthesis = logicalExpressionTokens[
		logicalExpressionTokens.length - 1
	] as ParenthesisToken;

	const end = lastParenthesis.pos.end;

	const offsets: [number, number][] = [];
	const newTokens: Token[] = [];

	let modificationDone = false;

	let increaseStartPosition = false;

	for (const paragraph of doc.content) {
		if (
			paragraph.type === 'paragraph' &&
			paragraph.content &&
			paragraph.pos.start < start &&
			paragraph.pos.end > end
		) {
			const newContent: SerializedContentWithPositions[] = [];

			for (let i = 0; i < paragraph.content.length; i++) {
				const textNode = paragraph.content[i];

				if (textNode.pos.start <= end) {
					if (textNode.type === 'text' && textNode.text) {
						if (
							textNode.text.toLowerCase().includes('if') &&
							textNode.text.toLowerCase().includes('then value is')
						) {
							const textEndingWithOpenParen = textNode.text.substring(
								0,
								firstParenthesis.pos.end - textNode.pos.start
							);

							const textStartingFromCloseParen = textNode.text.substring(
								lastParenthesis.pos.start - textNode.pos.start
							);

							const textBetweenParens = textNode.text.substring(
								firstParenthesis.pos.end - textNode.pos.start,
								lastParenthesis.pos.start - textNode.pos.start
							);

							const startingExpressionContent = generateTextNode(
								textEndingWithOpenParen,
								textNode.pos.start
							);

							newContent.push(startingExpressionContent);

							let maybeOpenParenthesisToken: ParenthesisToken | null = null;

							if (wasSingleExpression) {
								const whitespaceAfterOpenParen = generateTextNode(
									' ',
									startingExpressionContent.pos.end
								);

								const openParethesisTextNode = generateTextNode(
									'(',
									whitespaceAfterOpenParen.pos.end
								);

								maybeOpenParenthesisToken = tokenGenerator.generateParenthesisToken(
									'(',
									whitespaceAfterOpenParen.pos.end,
									1
								).token;

								newContent.push(whitespaceAfterOpenParen);
								newContent.push(openParethesisTextNode);
								newTokens.push(maybeOpenParenthesisToken);

								offsets.push([firstParenthesis.pos.end, 2]);
							}

							const existingExpressionContent = generateTextNode(
								textBetweenParens,
								maybeOpenParenthesisToken
									? maybeOpenParenthesisToken.pos.end
									: startingExpressionContent.pos.end
							);

							newContent.push(existingExpressionContent);

							let secondOffsetValue = 0;
							let maybeCloseParenthesisToken: ParenthesisToken | null = null;

							if (maybeOpenParenthesisToken) {
								const closeParenthesisTextNode = generateTextNode(
									')',
									existingExpressionContent.pos.end
								);

								maybeCloseParenthesisToken =
									tokenGenerator.generateParenthesisToken(
										')',
										existingExpressionContent.pos.end,
										maybeOpenParenthesisToken.depth,
										maybeOpenParenthesisToken
									).token;

								maybeOpenParenthesisToken.matchingPairId =
									maybeCloseParenthesisToken.id;

								newContent.push(closeParenthesisTextNode);
								newTokens.push(maybeCloseParenthesisToken);
								secondOffsetValue++;
							}

							const hasWhitespaceAtEndOfExistingExpressionContent =
								existingExpressionContent.text
									? existingExpressionContent.text[
											existingExpressionContent.text.length - 1
									  ] === ' '
									: false;

							const shouldAddWhitespaceBeforeAnd =
								wasSingleExpression ||
								(!wasSingleExpression &&
									!hasWhitespaceAtEndOfExistingExpressionContent);

							const {
								position: closingExpressionParenthesisEnd,
								offset: andContentOffset
							} = chainEmptyLogicalExpression(
								newContent,
								newTokens,
								maybeCloseParenthesisToken
									? maybeCloseParenthesisToken.pos.end
									: existingExpressionContent.pos.end,
								shouldAddWhitespaceBeforeAnd
							);

							secondOffsetValue += andContentOffset;

							const whitespaceBeforeCloseParen = generateTextNode(
								' ',
								closingExpressionParenthesisEnd
							);

							newContent.push(whitespaceBeforeCloseParen);
							secondOffsetValue++;

							newContent.push(
								generateTextNode(
									textStartingFromCloseParen,
									whitespaceBeforeCloseParen.pos.end
								)
							);

							offsets.push([lastParenthesis.pos.start, secondOffsetValue]);

							break;
						}

						if (textNode.marks && textNode.marks.length > 0) {
							if (offsets.length > 0) {
								textNode.pos.start += offsets[0][1];
								textNode.pos.end += offsets[0][1];
							}

							newContent.push(textNode);

							continue;
						}

						if (
							textNode.pos.start <= firstParenthesis.pos.start &&
							textNode.pos.end >= firstParenthesis.pos.end
						) {
							recomputeStartingIfExpression(
								textNode,
								firstParenthesis,
								logicalExpressionTokens,
								newContent,
								newTokens,
								offsets,
								wasSingleExpression
							);

							continue;
						}

						if (
							textNode.pos.start <= lastParenthesis.pos.start &&
							textNode.pos.end >= lastParenthesis.pos.end
						) {
							recomputeEndingIfExpression(
								textNode,
								lastParenthesis,
								newContent,
								newTokens,
								offsets,
								wasSingleExpression
							);

							continue;
						}

						if (textNode.pos.end < end) {
							if (offsets.length > 0) {
								textNode.pos.start += offsets[0][1];
								textNode.pos.end += offsets[0][1];
							}

							newContent.push(textNode);

							continue;
						}
					}
				}
			}

			paragraph.content = generateMergeContent(newContent);

			modificationDone = true;
		}

		if (increaseStartPosition) {
			paragraph.pos.start += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

			for (const [start, offset] of offsets) {
				regenerateTextContentPositions(paragraph, offset, start);
			}
		}

		if (modificationDone) {
			paragraph.pos.end += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

			if (!increaseStartPosition) {
				increaseStartPosition = true;
			}
		}
	}

	const offsetValue = offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];
	for (const values of Object.values(contentMap)) {
		if (values.startPosition < start && values.endPosition > end) {
			values.endPosition += offsetValue;
		}

		if (values.startPosition > end) {
			values.startPosition += offsetValue;
			values.endPosition += offsetValue;
		}
	}

	return { offsets, newTokens };
}

function offsetExistingTokens(
	tokenMap: TokenMap,
	offsets: [number, number][],
	wasSingleExpression: boolean,
	shouldBeSingleExpression?: boolean,
	logicId?: string
) {
	const sortedOffsets = offsets.sort(([posA], [posB]) => posB - posA);

	for (const [id, tokens] of Object.entries(tokenMap)) {
		for (const token of tokens) {
			for (const [start, offset] of sortedOffsets) {
				if (token.pos.start >= start) {
					token.pos.start += offset;
					token.pos.end += offset;
				}
			}

			if (token.type === TokenType.Operator && id === logicId) {
				if (wasSingleExpression) {
					token.depth += 1;
				}

				if (shouldBeSingleExpression) {
					token.depth -= 1;
				}

				token.color =
					Colors.lqleditor.parenthesisAndOperatorColors[
						token.depth % Colors.lqleditor.parenthesisAndOperatorColors.length
					];
			}

			if (token.type === TokenType.Parenthesis && id === logicId && token.depth > 0) {
				if (wasSingleExpression) {
					token.depth += 1;
				}

				if (shouldBeSingleExpression) {
					token.depth -= 1;
				}

				token.color =
					Colors.lqleditor.parenthesisAndOperatorColors[
						token.depth % Colors.lqleditor.parenthesisAndOperatorColors.length
					];
			}
		}
	}
}

function addNewTokensToTokenMap(tokenMap: TokenMap, newTokens: Token[], logicId: string) {
	tokenMap[logicId] = [...tokenMap[logicId], ...newTokens].sort(
		(a, b) => a.pos.start - b.pos.start
	);
}

function chainEmptyLogicalExpression(
	newContent: SerializedContentWithPositions[],
	newTokens: Token[],
	startPosition: number,
	shouldAddWhitespaceAtStart = true
) {
	let offsetValue = 0;
	const andTextNode = generateTextNode(
		shouldAddWhitespaceAtStart ? ' AND (' : 'AND (',
		startPosition
	);

	const andToken = tokenGenerator.generateKeywordToken(
		'AND',
		shouldAddWhitespaceAtStart ? andTextNode.pos.start + 1 : andTextNode.pos.start
	).token;

	const parenthesisAfterAndToken = tokenGenerator.generateParenthesisToken(
		'(',
		andToken.pos.end + 1,
		1
	).token;

	newContent.push(andTextNode);

	newTokens.push(andToken);
	newTokens.push(parenthesisAfterAndToken);

	offsetValue += andTextNode.text ? andTextNode.text.length : 0;

	const firstPlaceholderId = `placeholder_${generate()}`;

	const firstPlaceholderOperandTextNode = generatePlaceholderTextNode(
		firstPlaceholderId,
		andTextNode.pos.end
	);

	const firstPlaceholderToken = tokenGenerator.generatePlaceholderToken(
		firstPlaceholderId,
		firstPlaceholderOperandTextNode.pos.start
	).token;

	newContent.push(firstPlaceholderOperandTextNode);
	newTokens.push(firstPlaceholderToken);
	offsetValue++;

	newContent.push(generateTextNode('>', firstPlaceholderOperandTextNode.pos.end));

	const operatorToken = tokenGenerator.generateOperatorToken(
		ComparisonOperator.GreaterThan,
		firstPlaceholderOperandTextNode.pos.end,
		2
	).token;

	newTokens.push(operatorToken);
	offsetValue++;

	const secondPlaceholderId = `placeholder_${generate()}`;

	const secondPlaceholderOperandTextNode = generatePlaceholderTextNode(
		secondPlaceholderId,
		firstPlaceholderOperandTextNode.pos.end + 1
	);

	const secondPlaceholderToken = tokenGenerator.generatePlaceholderToken(
		secondPlaceholderId,
		secondPlaceholderOperandTextNode.pos.start
	).token;

	newContent.push(secondPlaceholderOperandTextNode);
	newTokens.push(secondPlaceholderToken);
	offsetValue++;

	const logicalExpressionClosingParenthesisTextNode = generateTextNode(
		')',
		secondPlaceholderOperandTextNode.pos.end
	);

	const logicalExpressionClosingParenthesisToken = tokenGenerator.generateParenthesisToken(
		')',
		secondPlaceholderOperandTextNode.pos.end,
		1
	).token;

	const previousSecondOpenParenthesisToken = newTokens.find(
		token =>
			token.subType === SubType.OpenParenthesis &&
			token.pos.start < logicalExpressionClosingParenthesisToken.pos.start &&
			token.pos.start > andToken.pos.start &&
			token.depth === 1
	);

	logicalExpressionClosingParenthesisToken.matchingPairId =
		previousSecondOpenParenthesisToken!.id;

	(previousSecondOpenParenthesisToken! as ParenthesisToken).matchingPairId =
		logicalExpressionClosingParenthesisToken.id;

	newContent.push(logicalExpressionClosingParenthesisTextNode);
	newTokens.push(logicalExpressionClosingParenthesisToken);
	offsetValue++;

	return {
		position: logicalExpressionClosingParenthesisToken.pos.end,
		offset: offsetValue
	};
}

function recomputeStartingIfExpression(
	textNode: SerializedContentWithPositions,
	firstParenthesis: ParenthesisToken,
	logicalExpressionTokens: Token[],
	newContent: SerializedContentWithPositions[],
	newTokens: Token[],
	offsets: [number, number][],
	wasSingleExpression: boolean
) {
	if (!textNode.text) return;

	const hasWhitespceAfterFirstParenthesis =
		textNode.text[firstParenthesis.pos.end - textNode.pos.start] === ' ';

	const hasWhitespaceAtEnd = textNode.text[textNode.text.length - 1] === ' ';

	const openParenthesisAfterFirstParenthesis = logicalExpressionTokens.find(
		t =>
			t.type === TokenType.Parenthesis &&
			t.subType === SubType.OpenParenthesis &&
			t.pos.start ===
				(hasWhitespceAfterFirstParenthesis
					? firstParenthesis.pos.end + 1
					: firstParenthesis.pos.end)
	) as ParenthesisToken | undefined;

	const numberToken = logicalExpressionTokens.find(
		token =>
			token.pos.start > textNode.pos.start &&
			token.pos.end < textNode.pos.end &&
			token.type === TokenType.Number
	);

	const isArithmeticExpression = openParenthesisAfterFirstParenthesis && wasSingleExpression;

	if (!numberToken) {
		if (wasSingleExpression) {
			if (!openParenthesisAfterFirstParenthesis) {
				newContent.push(textNode);

				const shouldAddWhitespaceLast =
					(hasWhitespceAfterFirstParenthesis && !openParenthesisAfterFirstParenthesis) ||
					(!!openParenthesisAfterFirstParenthesis && hasWhitespaceAtEnd);

				newContent.push(
					generateTextNode(shouldAddWhitespaceLast ? '( ' : ' (', textNode.pos.end)
				);
				newTokens.push(
					tokenGenerator.generateParenthesisToken(
						'(',
						shouldAddWhitespaceLast ? textNode.pos.end : firstParenthesis.pos.end + 1,
						1
					).token
				);
			} else {
				const textBeforeFirstParenthesis = textNode.text.substring(
					0,
					hasWhitespceAfterFirstParenthesis
						? firstParenthesis.pos.end - textNode.pos.start + 1
						: firstParenthesis.pos.end - textNode.pos.start
				);

				const textAfterFirstParenthesis = textNode.text.substring(
					firstParenthesis.pos.end - textNode.pos.start
				);

				const textBeforeFirstParenthesisTextNode = generateTextNode(
					textBeforeFirstParenthesis,
					textNode.pos.start
				);

				newContent.push(textBeforeFirstParenthesisTextNode);

				const openParenthesisTextNode = generateTextNode(
					hasWhitespceAfterFirstParenthesis ? '(' : ' (',
					textBeforeFirstParenthesisTextNode.pos.end
				);

				newContent.push(openParenthesisTextNode);

				const openParenthesisToken = tokenGenerator.generateParenthesisToken(
					'(',
					hasWhitespceAfterFirstParenthesis
						? textBeforeFirstParenthesisTextNode.pos.end
						: textBeforeFirstParenthesisTextNode.pos.end + 1,
					1
				).token;

				newTokens.push(openParenthesisToken);

				const textAfterFirstParenthesisTextNode = generateTextNode(
					textAfterFirstParenthesis,
					openParenthesisTextNode.pos.end
				);

				newContent.push(textAfterFirstParenthesisTextNode);
			}

			offsets.push([firstParenthesis.pos.end, 2]);
		} else {
			newContent.push(textNode);
		}
	} else {
		if (wasSingleExpression) {
			const textBeforeFirstParenthesis = textNode.text.substring(
				0,
				hasWhitespceAfterFirstParenthesis
					? firstParenthesis.pos.end - textNode.pos.start + 1
					: firstParenthesis.pos.end - textNode.pos.start
			);

			const textAfterFirstParenthesis = textNode.text.substring(
				hasWhitespceAfterFirstParenthesis
					? firstParenthesis.pos.end - textNode.pos.start + 1
					: firstParenthesis.pos.end - textNode.pos.start
			);

			const textBeforeFirstParenthesisTextNode = generateTextNode(
				textBeforeFirstParenthesis,
				textNode.pos.start
			);

			newContent.push(textBeforeFirstParenthesisTextNode);

			const openParenthesisTextNode = generateTextNode(
				hasWhitespceAfterFirstParenthesis ? '( ' : ' (',
				textBeforeFirstParenthesisTextNode.pos.end
			);

			newContent.push(openParenthesisTextNode);

			const openParenthesisToken = tokenGenerator.generateParenthesisToken(
				'(',
				hasWhitespceAfterFirstParenthesis
					? textBeforeFirstParenthesisTextNode.pos.end
					: textBeforeFirstParenthesisTextNode.pos.end + 1,
				1
			).token;

			newTokens.push(openParenthesisToken);

			if (!isArithmeticExpression) {
				const operatorToken = logicalExpressionTokens.find(
					token =>
						token.type === TokenType.Operator &&
						(token.pos.start === numberToken.pos.end ||
							token.pos.start === numberToken.pos.end + 1)
				) as OperatorToken;

				const firstOperandNumberTextNode = generateTextNode(
					numberToken.value,
					numberToken.pos.start + 2
				);

				const hasWhitespaceAfterNumberOperand =
					textNode.text[numberToken.pos.end - textNode.pos.start] === ' ';

				newContent.push(firstOperandNumberTextNode);

				if (hasWhitespaceAfterNumberOperand) {
					const whitespaceAfterNumberOperandTextNode = generateTextNode(
						' ',
						firstOperandNumberTextNode.pos.end
					);

					newContent.push(whitespaceAfterNumberOperandTextNode);
				}

				if (operatorToken) {
					const operatorTextNode = generateTextNode(
						operatorToken.value,
						hasWhitespaceAfterNumberOperand
							? firstOperandNumberTextNode.pos.end + 1
							: firstOperandNumberTextNode.pos.end
					);
					newContent.push(operatorTextNode);

					if (hasWhitespaceAtEnd) {
						newContent.push(generateTextNode(' ', operatorTextNode.pos.end));
					}
				}
			} else {
				const textAfterFirstParenthesisTextNode = generateTextNode(
					textAfterFirstParenthesis,
					openParenthesisTextNode.pos.end
				);

				newContent.push(textAfterFirstParenthesisTextNode);
			}

			offsets.push([firstParenthesis.pos.end, 2]);
		} else {
			newContent.push(textNode);
		}
	}
}

function recomputeEndingIfExpression(
	textNode: SerializedContentWithPositions,
	lastParenthesis: ParenthesisToken,
	newContent: SerializedContentWithPositions[],
	newTokens: Token[],
	offsets: [number, number][],
	wasSingleExpression: boolean
) {
	if (!textNode.text) return;

	const textBeforeLastCloseParen = textNode.text.substring(
		0,
		lastParenthesis.pos.end - textNode.pos.start - 1
	);
	const isWhitespace =
		textBeforeLastCloseParen.length > 0 && textBeforeLastCloseParen.trim() === '';

	const textAfterLastCloseParen = textNode.text.substring(
		lastParenthesis.pos.end - textNode.pos.start - 1
	);

	const hasContentBeforeParenthesis = textBeforeLastCloseParen !== '';

	let offsetValue = 0;
	let logicalContentStartPos = offsets[0]
		? textNode.pos.start + offsets[0][1]
		: textNode.pos.start;

	if (hasContentBeforeParenthesis) {
		let hasWhitespaceAtEnd = false;

		if (!wasSingleExpression && !isWhitespace) {
			hasWhitespaceAtEnd =
				textBeforeLastCloseParen[textBeforeLastCloseParen.length - 1] === ' ';
			const numberOfWhitespacesAtEnd = getNumberOfWhitespaces(
				textBeforeLastCloseParen,
				'end'
			);

			offsetValue -= numberOfWhitespacesAtEnd;
		}

		const beforeLastCloseParenTextNode = generateTextNode(
			hasWhitespaceAtEnd ? textBeforeLastCloseParen.trimEnd() : textBeforeLastCloseParen,
			textNode.pos.start + (offsets.length > 0 ? offsets[0][1] : 0)
		);

		newContent.push(beforeLastCloseParenTextNode);
		logicalContentStartPos = beforeLastCloseParenTextNode.pos.end;
	}

	if (wasSingleExpression) {
		const closeParenTextNode = generateTextNode(')', logicalContentStartPos);

		newContent.push(closeParenTextNode);
		logicalContentStartPos = closeParenTextNode.pos.end;
		offsetValue++;

		const closeParenToken = tokenGenerator.generateParenthesisToken(
			')',
			closeParenTextNode.pos.start,
			1
		).token;

		const previousOpenParenthesisToken = newTokens.find(
			token =>
				token.subType === SubType.OpenParenthesis &&
				token.pos.start < closeParenToken.pos.start
		) as ParenthesisToken;

		closeParenToken.matchingPairId = previousOpenParenthesisToken.id;

		previousOpenParenthesisToken.matchingPairId = closeParenToken.id;

		newTokens.push(closeParenToken);
	}

	const { position: closingExpressionParenthesisEnd, offset: andContentOffset } =
		chainEmptyLogicalExpression(newContent, newTokens, logicalContentStartPos);

	const whitespaceBeforeClosingParenthesis = generateTextNode(
		' ',
		closingExpressionParenthesisEnd
	);

	newContent.push(whitespaceBeforeClosingParenthesis);
	offsetValue++;

	const textAfterLastCloseParenTextNode = generateTextNode(
		textAfterLastCloseParen,
		whitespaceBeforeClosingParenthesis.pos.end
	);
	newContent.push(textAfterLastCloseParenTextNode);
	offsetValue += andContentOffset;

	offsets.push([lastParenthesis.pos.start, offsetValue]);
}

function removeTokensFromContent(
	doc: SerializedDocWithPositions,
	tokensToRemove: Token[],
	contentMap: ContentMap,
	shouldBeSingleExpression: boolean,
	lastValidToken?: Token
) {
	if (!doc) return { offsets: [] };

	const startRemovePosition = shouldBeSingleExpression
		? tokensToRemove[1].pos.start
		: tokensToRemove[0].pos.start;
	const endRemovePosition = tokensToRemove[tokensToRemove.length - 1].pos.end;

	const maybeOpenParenthsesisToRemove = shouldBeSingleExpression ? tokensToRemove[0] : null;

	const offsets: [number, number][] = [];

	let modificationDone = false;

	let decreasePosition = false;

	let shouldAddWhitespaceAtStart = false;

	for (const paragraph of doc.content) {
		if (paragraph.type === 'paragraph' && paragraph.content) {
			const newContent: SerializedContentWithPositions[] = [];

			for (let i = 0; i < paragraph.content.length; i++) {
				const textNode = paragraph.content[i];

				if (textNode.text) {
					if (
						(textNode.pos.start <= startRemovePosition &&
							textNode.pos.end <= endRemovePosition &&
							textNode.pos.end > startRemovePosition) ||
						(textNode.pos.start >= startRemovePosition &&
							textNode.pos.end <= endRemovePosition) ||
						(textNode.pos.start < startRemovePosition &&
							textNode.pos.end > endRemovePosition)
					) {
						const hasAndToken = textNode.text.toLowerCase().includes('and');

						shouldAddWhitespaceAtStart = removeContentFromMiddleIfExpression(
							textNode,
							hasAndToken,
							startRemovePosition,
							endRemovePosition,
							tokensToRemove,
							shouldBeSingleExpression,
							newContent,
							offsets,
							maybeOpenParenthsesisToRemove,
							shouldAddWhitespaceAtStart
						);

						modificationDone = true;
						continue;
					}

					if (
						maybeOpenParenthsesisToRemove &&
						textNode.pos.start < maybeOpenParenthsesisToRemove.pos.start &&
						textNode.pos.end >= maybeOpenParenthsesisToRemove.pos.end
					) {
						removeTokenFromStartingIfExpression(
							textNode,
							maybeOpenParenthsesisToRemove,
							newContent,
							offsets
						);

						modificationDone = true;

						continue;
					}

					if (
						textNode.pos.start <= endRemovePosition &&
						textNode.pos.end >= endRemovePosition
					) {
						removeTokenFromEndingIfExpression(
							textNode,
							tokensToRemove[tokensToRemove.length - 1],
							newContent,
							offsets,
							lastValidToken
						);

						modificationDone = true;

						continue;
					}

					if (textNode.pos.end <= startRemovePosition) {
						if (offsets.length > 0) {
							if (!offsets[1]) {
								textNode.pos.start += offsets[0][1];
								textNode.pos.end += offsets[0][1];
							} else {
								textNode.pos.start += offsets[1]
									? offsets[0][1] + offsets[1][1]
									: offsets[0][1];
								textNode.pos.end += offsets[1]
									? offsets[0][1] + offsets[1][1]
									: offsets[0][1];
							}
						}

						newContent.push(textNode);

						continue;
					}

					if (textNode.pos.start >= endRemovePosition) {
						if (decreasePosition) {
							textNode.pos.start += offsets[1]
								? offsets[0][1] + offsets[1][1]
								: offsets[0][1];
							textNode.pos.end += offsets[1]
								? offsets[0][1] + offsets[1][1]
								: offsets[0][1];
						}

						newContent.push(textNode);
					}
				}
			}

			paragraph.content = generateMergeContent(newContent);

			if (decreasePosition) {
				paragraph.pos.start += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];
			}

			if (offsets.length > 0 && modificationDone) {
				paragraph.pos.end += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

				if (!decreasePosition) {
					decreasePosition = true;
				}
			}
		}
	}

	doc.pos.end = doc.content[doc.content.length - 1].pos.end;

	const offsetValue = offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];
	for (const values of Object.values(contentMap)) {
		if (values.startPosition < startRemovePosition && values.endPosition > endRemovePosition) {
			values.endPosition += offsetValue;
		}

		if (values.startPosition > endRemovePosition) {
			values.startPosition += offsetValue;
			values.endPosition += offsetValue;
		}
	}

	return { offsets };
}

function getNumberOfWhitespaces(text: string, from: 'start' | 'end') {
	let count = 0;

	if (from === 'start') {
		for (let i = 0; i < text.length; i++) {
			if (text[i] === ' ') {
				count++;
			} else {
				break;
			}
		}
	} else {
		for (let i = text.length - 1; i >= 0; i--) {
			if (text[i] === ' ') {
				count++;
			} else {
				break;
			}
		}
	}

	return count;
}

function getTokensForRemoval(path: string, logicalTokens: Token[]) {
	const pathParts = path.split('.');

	// discard Logic ID from front of path parts
	pathParts.shift();

	const tokensToRemove: Token[] = [];

	let lastValidLogicIndex: number | null = null;

	for (let i = 0; i < pathParts.length; i++) {
		const part = pathParts[i];
		if (part === 'and') {
			const nextPart = pathParts[i + 1];

			if (!isNaN(Number(nextPart))) {
				lastValidLogicIndex = Number(nextPart);
			}

			break;
		} else {
			continue;
		}
	}

	let andTokenCount = 0;
	for (let i = 0; i < logicalTokens.length; i++) {
		const token = logicalTokens[i];
		const nextToken = logicalTokens[i + 1] ? logicalTokens[i + 1] : null;
		const previousToken = logicalTokens[i - 1] ? logicalTokens[i - 1] : null;

		if (lastValidLogicIndex === 0) {
			if (
				(token.subType === SubType.OpenParenthesis && token.depth === 0) ||
				(token.subType === SubType.CloseParenthesis && token.depth === 0)
			) {
				continue;
			}

			if (
				previousToken &&
				token.subType === SubType.OpenParenthesis &&
				previousToken.subType === SubType.OpenParenthesis &&
				previousToken.depth === 0
			) {
				tokensToRemove.push(token);
				continue;
			}

			if (
				token.subType === SubType.CloseParenthesis &&
				nextToken &&
				nextToken.subType === SubType.And
			) {
				tokensToRemove.push(token);
				continue;
			}
		}

		if (token.type === TokenType.Keyword && token.subType === SubType.And) {
			andTokenCount++;
		}

		if (lastValidLogicIndex !== null && andTokenCount > lastValidLogicIndex) {
			if (
				token.subType === SubType.CloseParenthesis &&
				token.depth === 0 &&
				andTokenCount > 1
			) {
				break;
			}
			tokensToRemove.push(token);
		}
	}

	return tokensToRemove;
}

function removeTokenFromStartingIfExpression(
	textNode: SerializedContentWithPositions,
	tokenToRemove: Token,
	newContent: SerializedContentWithPositions[],
	offsets: [number, number][]
) {
	if (textNode.type === 'text' && textNode.text) {
		if (
			textNode.pos.start <= tokenToRemove.pos.start &&
			textNode.pos.end >= tokenToRemove.pos.end
		) {
			const hasWhitespaceBeforeToken =
				textNode.text[tokenToRemove.pos.start - 1 - textNode.pos.start] === ' ';

			const hasWhitespaceAfterToken =
				textNode.text[tokenToRemove.pos.end - textNode.pos.start] === ' ';

			const textBefore = textNode.text.substring(
				0,
				hasWhitespaceBeforeToken
					? tokenToRemove.pos.start - textNode.pos.start - 1
					: tokenToRemove.pos.start - textNode.pos.start
			);

			const textAfter = textNode.text.substring(
				hasWhitespaceAfterToken
					? tokenToRemove.pos.end - textNode.pos.start + 1
					: tokenToRemove.pos.end - textNode.pos.start
			);

			const newTextValue =
				hasWhitespaceBeforeToken && hasWhitespaceAfterToken && textBefore !== ''
					? `${textBefore} ${textAfter}`
					: `${textBefore}${textAfter}`;

			const startingTextNode = generateTextNode(newTextValue, textNode.pos.start);

			newContent.push(startingTextNode);

			const offsetValue = textNode.text.length - newTextValue.length;

			offsets.push([tokenToRemove.pos.start, -offsetValue]);
		}
	}
}

function removeContentFromMiddleIfExpression(
	textNode: SerializedContentWithPositions,
	hasAndToken: boolean,
	startRemovePosition: number,
	endRemovePosition: number,
	tokensToRemove: Token[],
	shouldBeSingleExpression: boolean,
	newContent: SerializedContentWithPositions[],
	offsets: [number, number][],
	maybeOpenParenthesisToRemove: Token | null,
	shouldAddWhitespaceAtEnd?: boolean
): boolean {
	if (hasAndToken && textNode.pos.start <= startRemovePosition && textNode.text) {
		const andToken = tokensToRemove.find(
			t =>
				t.pos.start >= textNode.pos.start &&
				t.pos.end <= textNode.pos.end &&
				t.subType === SubType.And
		);
		if (andToken) {
			if (textNode.pos.end < endRemovePosition) {
				const hasWhitespaceBeforeAnd =
					textNode.text[andToken.pos.start - 1 - textNode.pos.start] === ' ';

				const textStartingWithAnd = textNode.text.substring(
					hasWhitespaceBeforeAnd
						? andToken.pos.start - textNode.pos.start - 1
						: andToken.pos.start - textNode.pos.start
				);

				const hasWhitespaceAtStart = textNode.text[0] === ' ';

				const hasCloseParenAtStart = hasWhitespaceAtStart
					? textNode.text[1] === ')'
					: textNode.text[0] === ')';

				let adjustOffset: number | null = null;

				if (hasCloseParenAtStart && !shouldBeSingleExpression) {
					const textBeforeAnd = textNode.text.substring(
						0,
						hasWhitespaceBeforeAnd
							? andToken.pos.start - textNode.pos.start - 1
							: andToken.pos.start - textNode.pos.start
					);

					const textNodeBeforeAnd = generateTextNode(textBeforeAnd, textNode.pos.start);

					newContent.push(textNodeBeforeAnd);

					if (hasWhitespaceBeforeAnd && hasWhitespaceAtStart) {
						shouldAddWhitespaceAtEnd = false;
					} else {
						newContent.push(generateTextNode(' ', textNodeBeforeAnd.pos.end));
					}
				} else {
					let textBeforeAnd = textNode.text.substring(
						0,
						hasWhitespaceBeforeAnd
							? andToken.pos.start - textNode.pos.start - 1
							: andToken.pos.start - textNode.pos.start
					);

					const isLastCharCloseParen = textBeforeAnd[textBeforeAnd.length - 1] === ')';

					if (isLastCharCloseParen && shouldBeSingleExpression) {
						const hasWhitespaceBeforeCloseParen =
							textBeforeAnd[textBeforeAnd.length - 2] === ' ';

						textBeforeAnd = textBeforeAnd.slice(
							0,
							hasWhitespaceBeforeCloseParen ? -2 : -1
						);
						adjustOffset = hasWhitespaceBeforeCloseParen ? -2 : -1;
					}

					if (textBeforeAnd !== '') {
						if (
							maybeOpenParenthesisToRemove &&
							maybeOpenParenthesisToRemove.pos.start > textNode.pos.start
						) {
							const hasWhitespaceBeforeOpenParen =
								textBeforeAnd[
									maybeOpenParenthesisToRemove.pos.start - textNode.pos.start - 1
								] === ' ';

							const textBeforeOParen = textBeforeAnd.substring(
								0,
								hasWhitespaceBeforeOpenParen
									? maybeOpenParenthesisToRemove.pos.start -
											textNode.pos.start -
											1
									: maybeOpenParenthesisToRemove.pos.start - textNode.pos.start
							);

							const textAfterOParen = textBeforeAnd.substring(
								maybeOpenParenthesisToRemove.pos.end - textNode.pos.start
							);

							if (textBeforeOParen !== '') {
								newContent.push(
									generateTextNode(textBeforeOParen, textNode.pos.start)
								);
							}

							if (textAfterOParen !== '') {
								newContent.push(
									generateTextNode(
										textAfterOParen,
										hasWhitespaceBeforeOpenParen
											? maybeOpenParenthesisToRemove.pos.start - 1
											: maybeOpenParenthesisToRemove.pos.start
									)
								);
							}

							offsets.push([
								maybeOpenParenthesisToRemove.pos.start,
								hasWhitespaceBeforeOpenParen ? -2 : -1
							]);
						} else {
							newContent.push(
								generateTextNode(
									textBeforeAnd,
									isLastCharCloseParen && shouldBeSingleExpression
										? textNode.pos.start - 2
										: textNode.pos.start
								)
							);
						}
					} else if (!adjustOffset) {
						adjustOffset = shouldAddWhitespaceAtEnd ? -2 : -1;
					}
				}

				if (
					hasWhitespaceBeforeAnd &&
					newContent[newContent.length - 1].text?.trim() === '' &&
					!newContent[newContent.length - 1].marks
				) {
					adjustOffset = 1;
				}

				offsets.push([
					andToken.pos.start - 1,
					-textStartingWithAnd.length + (adjustOffset || 0)
				]);
			} else {
				let textBeforeStartRemovePosition = textNode.text.substring(
					0,
					startRemovePosition - textNode.pos.start
				);

				let textAfterEndRemovePosition = textNode.text.substring(
					endRemovePosition - textNode.pos.start
				);

				textAfterEndRemovePosition =
					textAfterEndRemovePosition[0] === ' '
						? textAfterEndRemovePosition.substring(1)
						: textAfterEndRemovePosition;

				let hasWhitespaceBeforeOParen = false;

				const isMaybeOpenParenInTextNode =
					maybeOpenParenthesisToRemove &&
					textNode.pos.start < maybeOpenParenthesisToRemove.pos.start;

				if (isMaybeOpenParenInTextNode) {
					let textBeforeOpenParen = textBeforeStartRemovePosition.substring(
						0,
						maybeOpenParenthesisToRemove.pos.start - textNode.pos.start
					);

					hasWhitespaceBeforeOParen =
						textBeforeOpenParen[textBeforeOpenParen.length - 1] === ' ';

					textBeforeOpenParen = hasWhitespaceBeforeOParen
						? textBeforeOpenParen.substring(0, textBeforeOpenParen.length - 1)
						: textBeforeOpenParen;

					offsets.push([
						maybeOpenParenthesisToRemove.pos.start,
						-(hasWhitespaceBeforeOParen ? 2 : 1)
					]);

					const textAfterOpenParen = textBeforeStartRemovePosition.substring(
						maybeOpenParenthesisToRemove.pos.end - textNode.pos.start
					);

					textBeforeStartRemovePosition = textBeforeOpenParen + textAfterOpenParen;
				}

				const textBeforeStartPosition =
					offsets[0] && !isMaybeOpenParenInTextNode
						? textNode.pos.start + offsets[0][1]
						: textNode.pos.start;
				const textBeforeTextNode = generateTextNode(
					textBeforeStartRemovePosition,
					textBeforeStartPosition
				);

				newContent.push(textBeforeTextNode);

				const textAfterTextNode = generateTextNode(
					textAfterEndRemovePosition,
					textBeforeTextNode.pos.end
				);

				newContent.push(textAfterTextNode);

				const offsetValue =
					textNode.text.length -
					(textBeforeStartRemovePosition.length + textAfterEndRemovePosition.length) -
					(isMaybeOpenParenInTextNode ? (hasWhitespaceBeforeOParen ? 2 : 1) : 0);

				offsets.push([startRemovePosition, -offsetValue]);
			}
		}
	} else {
		if (offsets.length === 2) {
			offsets[1][1] -= textNode.text ? textNode.text.length : 0;
		}

		if (!maybeOpenParenthesisToRemove && offsets.length === 1 && textNode.text) {
			offsets[0][1] -= textNode.text.length;
		}
	}

	return !!shouldAddWhitespaceAtEnd;
}

function removeTokenFromEndingIfExpression(
	textNode: SerializedContentWithPositions,
	tokenToRemove: Token,
	newContent: SerializedContentWithPositions[],
	offsets: [number, number][],
	tokenBefore?: Token
) {
	if (textNode.type === 'text' && textNode.text) {
		if (
			textNode.pos.start <= tokenToRemove.pos.start &&
			textNode.pos.end >= tokenToRemove.pos.end
		) {
			const hasWhitespaceBeforeToken =
				textNode.text[tokenToRemove.pos.start - 1 - textNode.pos.start] === ' ';

			const hasWhitespaceAfterToken =
				textNode.text[tokenToRemove.pos.end - textNode.pos.start] === ' ';

			const firstWhitespaceCheck =
				tokenBefore &&
				hasWhitespaceBeforeToken &&
				hasWhitespaceAfterToken &&
				tokenBefore.type !== TokenType.Placeholder;

			const secondWhitespaceCheck =
				tokenBefore &&
				!hasWhitespaceBeforeToken &&
				hasWhitespaceAfterToken &&
				(tokenBefore.type === TokenType.Number || tokenBefore.subType === SubType.Variable);

			const shouldAddWhitespace = firstWhitespaceCheck || secondWhitespaceCheck;

			const textAfter = textNode.text.substring(
				hasWhitespaceAfterToken
					? tokenToRemove.pos.end - textNode.pos.start + 1
					: tokenToRemove.pos.end - textNode.pos.start
			);

			const lastAddedTextNode =
				newContent.length > 0 ? newContent[newContent.length - 1] : null;

			const isWhitespaceTextNode = lastAddedTextNode?.text?.trim() === '';

			const hasMark = lastAddedTextNode ? lastAddedTextNode.marks : false;
			const hasPlaceholderMark = hasMark
				? hasMark.find(m => m.type === 'Placeholder-Extension')
				: false;

			if (shouldAddWhitespace && !isWhitespaceTextNode && !hasPlaceholderMark) {
				const whitespaceStartPos = newContent[newContent.length - 1].pos.end;

				newContent.push(generateTextNode(' ', whitespaceStartPos));

				if (offsets[1]) {
					offsets[1][1] += 1;
				} else if (offsets[0]) {
					offsets[0][1] += 1;
				}
			}

			const newTextNode = generateTextNode(
				textAfter,
				newContent.length > 0
					? newContent[newContent.length - 1].pos.end
					: textNode.pos.start
			);

			newContent.push(newTextNode);

			const offsetValue = textNode.text.length - textAfter.length;

			if (offsets.length > 0) {
				if (offsets[1]) {
					offsets[1][1] -= offsetValue;
				} else {
					offsets.push([tokenToRemove.pos.start, -offsetValue]);
				}
			}
		}
	}
}

function removeTokensFromTokenMap(tokenMap: TokenMap, tokensToRemove: Token[], logicId: string) {
	const currentLogicTokens = tokenMap[logicId];

	const newTokens: Token[] = [];

	for (const token of currentLogicTokens) {
		if (!tokensToRemove.includes(token)) {
			newTokens.push(token);
		}
	}

	tokenMap[logicId] = newTokens;
}

function getArithmeticTokensFromValue(
	tokenToReplace: Token,
	value: ArithmeticNode,
	variables: Variable[],
	tokenMap: TokenMap,
	currentlogicId: string
) {
	const tokens: Token[] = [];

	const currentDepthContext = getDepthContext(tokenToReplace, tokenMap, currentlogicId);

	const operator = Object.keys(value)[0] as ArithmeticOperator;

	const operands = Object.values(value)[0] as (string | number | VariableNode)[];

	const isPlaceholderToken = tokenToReplace.type === TokenType.Placeholder;

	const openParenthesisToken = tokenGenerator.generateParenthesisToken(
		'(',
		isPlaceholderToken ? tokenToReplace.pos.start + 1 : tokenToReplace.pos.start,
		currentDepthContext + 1
	).token;

	tokens.push(openParenthesisToken);

	const firstOperandId = generate();
	const firstOperandToken = getNewTokenFromValue(
		firstOperandId,
		null,
		openParenthesisToken.pos.end,
		operands[0],
		variables
	);

	if (firstOperandToken) {
		tokens.push(firstOperandToken);
	}

	const operatorToken = tokenGenerator.generateOperatorToken(
		operator,
		firstOperandToken ? firstOperandToken.pos.end : openParenthesisToken.pos.end,
		currentDepthContext + 2
	).token;

	tokens.push(operatorToken);

	const secondOperandId = generate();

	const secondOperandToken = getNewTokenFromValue(
		secondOperandId,
		null,
		operatorToken.pos.end,
		operands[1],
		variables
	);

	if (secondOperandToken) {
		tokens.push(secondOperandToken);
	}

	const closeParenthesisToken = tokenGenerator.generateParenthesisToken(
		')',
		secondOperandToken ? secondOperandToken.pos.end : operatorToken.pos.end,
		currentDepthContext + 1
	).token;

	openParenthesisToken.matchingPairId = closeParenthesisToken.id;
	closeParenthesisToken.matchingPairId = openParenthesisToken.id;

	tokens.push(closeParenthesisToken);

	return tokens;
}

function getDepthContext(tokenToReplace: Token, tokenMap: TokenMap, logicId: string) {
	const currentLogicTokens = [...tokenMap[logicId]];

	let depth = -1;

	for (const token of currentLogicTokens) {
		if (token.pos.start > tokenToReplace.pos.start) {
			break;
		}

		if (token.type === TokenType.Parenthesis) {
			if (token.subType === SubType.OpenParenthesis) {
				depth++;
			} else if (token.subType === SubType.CloseParenthesis) {
				depth--;
			}
		}
	}

	return depth;
}

function getOffsetValue(offsets: [number, number][]) {
	let offsetValue = 0;

	for (const offset of offsets) {
		offsetValue += offset[1];
	}

	return offsetValue;
}

function addNewTokensToContent(
	paragraph: SerializedContentWithPositions,
	newTokens: Token[],
	currentToken: Token,
	contentMap: ContentMap,
	logicId: string,
	offsets: [number, number][],
	variable?: Variable
) {
	if (!paragraph.content) {
		return false;
	}

	const newContent: SerializedContentWithPositions[] = [];
	for (let i = 0; i < paragraph.content.length; i++) {
		const textNode = paragraph.content[i];

		if (
			textNode.pos.start <= currentToken.pos.start &&
			textNode.pos.end >= currentToken.pos.end &&
			textNode.type === 'text' &&
			textNode.text
		) {
			replaceTokenWithNewTokens(
				textNode,
				currentToken,
				newTokens,
				newContent,
				offsets,
				variable
			);
		} else {
			if (offsets.length > 0) {
				const offsetValue = getOffsetValue(offsets);
				textNode.pos.start += offsetValue;
				textNode.pos.end += offsetValue;
			}

			newContent.push(textNode);
		}
	}

	for (const positions of Object.values(contentMap)) {
		const offsetValue = getOffsetValue(offsets);
		if (
			positions.startPosition <= currentToken.pos.start &&
			positions.endPosition >= currentToken.pos.end
		) {
			positions.endPosition += offsetValue;
		} else if (positions.startPosition > currentToken.pos.start) {
			positions.startPosition += offsetValue;
			positions.endPosition += offsetValue;
		}
	}

	if (newContent.length > 0) {
		paragraph.content = generateMergeContent(newContent);
		return true;
	}

	return false;
}

function replaceTokenWithNewTokens(
	textNode: SerializedContentWithPositions,
	currentToken: Token,
	newTokens: Token[],
	newContent: SerializedContentWithPositions[],
	offsets: [number, number][],
	variable?: Variable
) {
	const hasMark = !!textNode.marks && textNode.marks.length > 0;

	const hasPlaceholderMark =
		hasMark && !!textNode.marks?.find(m => m.type === ExtensionType.PlaceholderTokenizer);

	const hasVariableMark =
		hasMark && !!textNode.marks?.find(m => m.type === ExtensionType.VariableTokenizer);

	if (hasPlaceholderMark) {
		const whitespaceTextNodeBeforeNewTokens = generateTextNode(' ', textNode.pos.start);

		newContent.push(whitespaceTextNodeBeforeNewTokens);

		const newTokensOffset = getContentFromTokens(newTokens, newContent);

		const whitespaceTextNodeAfterNewTokens = generateTextNode(
			' ',
			whitespaceTextNodeBeforeNewTokens.pos.end + newTokensOffset
		);

		newContent.push(whitespaceTextNodeAfterNewTokens);

		// we remove 1 position because we remove the existing Placeholder mark and add 2 whitespaces resulting in 1 position added to the offset
		offsets.push([textNode.pos.start, newTokensOffset + 1]);
	} else if (hasVariableMark) {
		const newTokensOffset = getContentFromTokens(newTokens, newContent, variable);

		offsets.push([textNode.pos.start, newTokensOffset]);
	} else {
		if (textNode.text) {
			const textBeforeToken = textNode.text.substring(
				0,
				currentToken.pos.start - textNode.pos.start
			);

			const textAfterToken = textNode.text.substring(
				currentToken.pos.end - textNode.pos.start
			);

			const textBeforeTextNode = generateTextNode(textBeforeToken, textNode.pos.start);

			newContent.push(textBeforeTextNode);

			const newTokensOffset = getContentFromTokens(newTokens, newContent);

			const textAfterTextNode = generateTextNode(
				textAfterToken,
				textBeforeTextNode.pos.end + newTokensOffset
			);

			newContent.push(textAfterTextNode);

			offsets.push([textBeforeTextNode.pos.end, newTokensOffset]);
		}
	}
}

function getContentFromTokens(
	tokens: Token[],
	newContent: SerializedContentWithPositions[],
	variable?: Variable
) {
	let offset = 0;
	for (const token of tokens) {
		if (token.subType === SubType.Variable || token.type === TokenType.Number) {
			const whitespaceBeforeToken = generateTextNode(' ', token.pos.start);

			newContent.push(whitespaceBeforeToken);
			offset += 1;

			if (token.type === TokenType.Number) {
				const newTextNode = generateTextNode(token.value, whitespaceBeforeToken.pos.end);

				newContent.push(newTextNode);
				offset += token.value.length;
			} else {
				if (variable) {
					const variableId = token.id.split('__')[1];

					const variableTextNode = generateVariableTextNode(
						variable,
						variableId,
						token.pos.start
					);

					newContent.push(variableTextNode);
					offset += token.value.length;
				}
			}

			const whitespaceAfterToken = generateTextNode(' ', token.pos.end);

			newContent.push(whitespaceAfterToken);
			offset += 1;
		}

		if (token.type === TokenType.Placeholder) {
			const placeholderTextNode = generatePlaceholderTextNode(token.id, token.pos.start);

			newContent.push(placeholderTextNode);
			offset += 1;
		} else {
			const newTextNode = generateTextNode(token.value, token.pos.start);
			newContent.push(newTextNode);
			offset += token.value.length;
		}
	}

	return offset;
}

function getTokensFromPath(pathKeys: string[], tokens: Token[]) {
	if (pathKeys.length === 0) return null;

	const isAndExpression = pathKeys.includes('and');

	let tokenSearchStartIndex = tokens.findIndex(
		t => t.subType === SubType.OpenParenthesis && t.depth === 0
	);
	let tokenSearchEndIndex = tokens.findIndex(
		t => t.subType === SubType.CloseParenthesis && t.depth === 0
	);

	if (isAndExpression) {
		const andKeyIndex = pathKeys.indexOf('and');
		const keyAfterAnd = Number(pathKeys[andKeyIndex + 1]);

		const { startIndex: andStartIndex, endIndex: andEndIndex } = computeIndicesByAndTokens(
			tokens,
			keyAfterAnd
		);

		tokenSearchStartIndex = andStartIndex;
		tokenSearchEndIndex = andEndIndex;
	}

	const logicalKeys = processKeys(pathKeys);

	const operatorKey = logicalKeys.shift() as string;
	const keyAfterOperator = logicalKeys.shift() as string;

	const remainingTokens = tokens.slice(tokenSearchStartIndex, tokenSearchEndIndex + 1);

	if (
		remainingTokens[0].subType === SubType.OpenParenthesis &&
		remainingTokens[remainingTokens.length - 1].subType === SubType.CloseParenthesis
	) {
		remainingTokens.shift();
		remainingTokens.pop();
	}

	return findTokensToReplace(
		operatorKey,
		keyAfterOperator,
		remainingTokens,
		logicalKeys,
		isAndExpression ? 1 : 0
	);
}

function findTokensToReplace(
	operatorKey: string,
	keyAfterOperator: string,
	tokens: Token[],
	keys: string[],
	contextLevel = 0
): Token[] | null {
	const operatorIndex = tokens.findIndex(
		t =>
			t.value === operatorKey && t.type === TokenType.Operator && t.depth === contextLevel + 1
	);

	if (operatorIndex === -1) return null;

	const direction = Number(keyAfterOperator);

	const { startIndex, endIndex } = computeIndicesByOperatorIndex(
		tokens,
		operatorIndex,
		direction
	);

	if (startIndex === -1 || endIndex === -1) return null;

	const remainingTokens = tokens.slice(startIndex, endIndex + 1);

	if (remainingTokens[0].subType === SubType.OpenParenthesis && keys.length > 0) {
		remainingTokens.shift();

		if (remainingTokens[remainingTokens.length - 1].subType === SubType.CloseParenthesis) {
			remainingTokens.pop();
		}
	}

	if (keys.length === 0) {
		return remainingTokens;
	} else {
		return findTokensToReplace(
			keys.shift() as string,
			keys.shift() as string,
			remainingTokens,
			keys,
			contextLevel + 1
		);
	}
}

function removeTokensAndReplaceWithPlaceholder(
	doc: SerializedDocWithPositions,
	tokensToRemove: Token[],
	contentMap: ContentMap
): { offsets: [number, number][]; token: Token | null } {
	if (!doc || doc.content.length === 0) return { offsets: [], token: null };

	const startRemovePosition = tokensToRemove[0].pos.start;
	const endRemovePosition = tokensToRemove[tokensToRemove.length - 1].pos.end;

	const offsets: [number, number][] = [];

	let modificationDone = false;

	let decreasePosition = false;

	let placeholderToken: Token | null = null;

	for (const paragraph of doc.content) {
		const newContent: SerializedContentWithPositions[] = [];
		if (
			paragraph.content &&
			((paragraph.pos.start < startRemovePosition &&
				paragraph.pos.end > startRemovePosition) ||
				(paragraph.pos.start < startRemovePosition &&
					paragraph.pos.end > endRemovePosition) ||
				(paragraph.pos.start < endRemovePosition && paragraph.pos.end > endRemovePosition))
		) {
			const { modified, token } = recomputeParagraphContentAfterReplacingWithPlaceholder(
				paragraph,
				tokensToRemove,
				startRemovePosition,
				endRemovePosition,
				newContent,
				offsets
			);

			if (modified) {
				modificationDone = true;

				if (token) {
					placeholderToken = token;
				}
			}

			if (modificationDone) {
				paragraph.content = generateMergeContent(newContent);
			}
		}

		if (decreasePosition) {
			paragraph.pos.start += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

			if (offsets.length > 0) {
				regenerateTextContentPositions(paragraph, offsets[0][1], offsets[0][0]);
			}
		}

		if (offsets.length > 0 && modificationDone) {
			paragraph.pos.end += offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

			if (!decreasePosition) {
				decreasePosition = true;
			}
		}
	}

	doc.pos.end = doc.content[doc.content.length - 1].pos.end;

	const offsetValue = offsets[1] ? offsets[0][1] + offsets[1][1] : offsets[0][1];

	for (const values of Object.values(contentMap)) {
		if (values.startPosition < startRemovePosition && values.endPosition > endRemovePosition) {
			values.endPosition += offsetValue;
		}

		if (values.startPosition > endRemovePosition) {
			values.startPosition += offsetValue;
			values.endPosition += offsetValue;
		}
	}

	return { offsets, token: placeholderToken };
}

function recomputeParagraphContentAfterReplacingWithPlaceholder(
	paragraph: SerializedContentWithPositions,
	tokensToRemove: Token[],
	startRemovePosition: number,
	endRemovePosition: number,
	newContent: SerializedContentWithPositions[],
	offsets: [number, number][]
) {
	let modified = false;

	if (!paragraph.content) return { modified, token: null };

	let placeholderToken: Token | null = null;

	for (const textNode of paragraph.content) {
		if (textNode.text) {
			if (textNode.pos.start > startRemovePosition && textNode.pos.end < endRemovePosition) {
				if (offsets.length > 0) {
					offsets[0][1] -= textNode.text.length;
				}

				continue;
			}

			if (textNode.pos.end < startRemovePosition) {
				newContent.push(textNode);
			}

			if (textNode.pos.start >= endRemovePosition) {
				if (offsets.length > 0) {
					textNode.pos.start += offsets[0][1];
					textNode.pos.end += offsets[0][1];
				}

				newContent.push(textNode);

				continue;
			}

			if (textNode.pos.start < startRemovePosition && textNode.pos.end > endRemovePosition) {
				const textBefore = textNode.text.substring(
					0,
					startRemovePosition - textNode.pos.start
				);

				const hasWhitespaceAtEndOfTextBefore = textBefore[textBefore.length - 1] === ' ';

				const textAfter = textNode.text.substring(endRemovePosition - textNode.pos.start);

				const hasWhitespaceAtStartOfTextAfter = textAfter[0] === ' ';

				const beforeTextNode = generateTextNode(
					hasWhitespaceAtEndOfTextBefore
						? textBefore.substring(0, textBefore.length - 1)
						: textBefore,
					textNode.pos.start
				);

				newContent.push(beforeTextNode);

				const placeholderId = `placeholder_${generate()}`;

				const placeholderTextNode = generatePlaceholderTextNode(
					placeholderId,
					beforeTextNode.pos.end
				);

				placeholderToken = tokenGenerator.generatePlaceholderToken(
					placeholderId,
					beforeTextNode.pos.end
				).token;

				newContent.push(placeholderTextNode);

				const afterTextNode = generateTextNode(
					hasWhitespaceAtStartOfTextAfter ? textAfter.substring(1) : textAfter,
					placeholderTextNode.pos.end
				);

				newContent.push(afterTextNode);

				const offsetValue = textNode.pos.end - afterTextNode.pos.end;

				offsets.push([startRemovePosition, -offsetValue]);

				modified = true;
			}

			if (
				textNode.pos.start < startRemovePosition &&
				textNode.pos.end >= startRemovePosition &&
				textNode.pos.end < endRemovePosition
			) {
				const firstTokenToRemove = tokensToRemove[0];

				const hasWhitespaceBeforeFirstToken =
					textNode.text[firstTokenToRemove.pos.start - 1 - textNode.pos.start] === ' ';

				const textBeforeFirstToken = textNode.text.substring(
					0,
					hasWhitespaceBeforeFirstToken
						? firstTokenToRemove.pos.start - textNode.pos.start - 1
						: firstTokenToRemove.pos.start - textNode.pos.start
				);

				const textNodeBeforeFirstToken = generateTextNode(
					textBeforeFirstToken,
					textNode.pos.start
				);

				newContent.push(textNodeBeforeFirstToken);

				const placeholderId = `placeholder_${generate()}`;

				const placeholderTextNode = generatePlaceholderTextNode(
					placeholderId,
					textNodeBeforeFirstToken.pos.end
				);

				placeholderToken = tokenGenerator.generatePlaceholderToken(
					placeholderId,
					textNodeBeforeFirstToken.pos.end
				).token;

				newContent.push(placeholderTextNode);

				const offsetValue =
					textNode.pos.end -
					textNode.pos.start -
					textBeforeFirstToken.length -
					placeholderToken.value.length;

				offsets.push([firstTokenToRemove.pos.start, -offsetValue]);

				continue;
			}

			if (
				textNode.pos.start >= startRemovePosition &&
				textNode.pos.end >= endRemovePosition
			) {
				const lastTokenToRemove = tokensToRemove[tokensToRemove.length - 1];

				const hasWhitespaceAfterToken =
					textNode.text[lastTokenToRemove.pos.end - textNode.pos.start] === ' ';

				const textAfter = textNode.text.substring(
					hasWhitespaceAfterToken
						? endRemovePosition - textNode.pos.start + 1
						: endRemovePosition - textNode.pos.start
				);

				const textAfterTextNode = generateTextNode(
					textAfter,
					textNode.pos.start + (offsets.length > 0 ? offsets[0][1] : 0)
				);

				newContent.push(textAfterTextNode);

				const updatedOffsetValue =
					(offsets.length > 0 ? offsets[0][1] : 0) -
					(textNode.text.length - textAfter.length);

				if (offsets.length > 0) {
					offsets[0][1] = updatedOffsetValue;
				}

				modified = true;
			}
		}
	}

	return { modified, token: placeholderToken };
}

function copyDoc(doc: SerializedDocWithPositions) {
	return {
		...doc,
		content: doc.content.length > 0 ? cloneDeep(doc.content) : []
	} as SerializedDocWithPositions;
}

function removeContentFromParagraphV2(
	paragraph: SerializedContentWithPositions,
	startRemovePosition: number,
	endRemovePosition: number
): { updatedParagraph: SerializedContentWithPositions | null } {
	if (!paragraph.content || paragraph.content.length === 0) {
		return { updatedParagraph: paragraph };
	}

	const newContent: SerializedContentWithPositions[] = [];
	let offset = 0;

	for (let i = 0; i < paragraph.content.length; i++) {
		const textNode = paragraph.content[i];

		if (textNode.pos.end <= startRemovePosition) {
			// Text node is completely before the removed range
			newContent.push(textNode);
		} else if (textNode.pos.start >= endRemovePosition) {
			// Text node is completely after the removed range
			textNode.pos.start -= offset;
			textNode.pos.end -= offset;
			newContent.push(textNode);
		} else {
			// Text node is affected by the removal
			const start = Math.max(textNode.pos.start, startRemovePosition);
			const end = Math.min(textNode.pos.end, endRemovePosition);
			offset += end - start;

			if (textNode.text) {
				const textBefore = textNode.text.substring(0, start - textNode.pos.start);
				const textAfter = textNode.text.substring(end - textNode.pos.start);

				if (textBefore || textAfter) {
					const newTextValue = textBefore + textAfter;
					const newStartPos = newContent.length
						? newContent[newContent.length - 1].pos.end
						: 1;
					const newTextNode = generateTextNode(newTextValue, newStartPos);
					newContent.push(newTextNode);
				}
			}
		}
	}

	const updatedParagraph = generateParagraph(newContent, paragraph.pos.start, paragraph.attrs);

	return { updatedParagraph };
}

export function generateParagraph(
	content: SerializedContentWithPositions[],
	startPosition: number,
	attrs: { [key: string]: any } = {}
): SerializedContentWithPositions | null {
	if (content.length === 0) return null;

	return {
		type: 'paragraph',
		attrs,
		content,
		pos: {
			start: startPosition,
			end: content[content.length - 1].pos.end + 1
		}
	};
}

function removePositionsAndUpdateContentMap(
	contentMap: ContentMap,
	removePositions: { start: number; end: number },
	offset: number
): ContentMap {
	const updatedContentMap: ContentMap = {};

	for (const key in contentMap) {
		const block = contentMap[key];

		if (block.endPosition < removePositions.start) {
			// If the block is entirely before the remove positions, do nothing
			updatedContentMap[key] = block;
			continue;
		}

		// If the block is entirely within the remove positions, delete it
		if (
			block.startPosition >= removePositions.start &&
			block.endPosition <= removePositions.end
		) {
			continue;
		}

		if (block.endPosition > removePositions.start) {
			// If the block is partially or entirely after the remove positions, update its positions
			updatedContentMap[key] = {
				startPosition: block.startPosition - offset,
				endPosition: block.endPosition - offset
			};
		}
	}

	return updatedContentMap;
}

function removePositionsAndUpdateTokenMap(
	tokenMap: TokenMap,
	removePositions: { start: number; end: number },
	offset: number
): TokenMap {
	const updatedTokenMap: TokenMap = {};

	for (const key in tokenMap) {
		const updatedTokens = tokenMap[key]
			.filter(
				token =>
					token.pos.end < removePositions.start || token.pos.start > removePositions.end
			)
			.map(token => {
				if (token.pos.start > removePositions.end) {
					return {
						...token,
						pos: {
							start: token.pos.start - offset,
							end: token.pos.end - offset
						}
					};
				}
				return token;
			});

		if (updatedTokens.length > 0) {
			updatedTokenMap[key] = updatedTokens;
		}
	}

	return updatedTokenMap;
}

// ====================================================================================================
// ======================================== PARSERS HELPERS ============================================
// ====================================================================================================

export function getOpenParenthesisOfIdFromMap(id: string, tokenMap: TokenMap) {
	return tokenMap[id].filter(
		t => t.subType === SubType.OpenParenthesis && t.type === TokenType.Parenthesis
	) as ParenthesisToken[];
}
