import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	Columns,
	KaplanMeierAnalysis,
	KaplanMeierDataModels,
	KaplanMeierStatistics,
	KaplanMeierVariables,
	TimeWindowSizeType
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { ConfigContainer } from '../../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useActiveFiltersByVariableName,
	useFilters
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { SelectItem } from 'types/generic';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { GenericMap } from 'types/index';

interface Props {
	analysis: KaplanMeierAnalysis;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

const TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Years,
	TimeWindowSizeType.Months,
	TimeWindowSizeType.Weeks,
	TimeWindowSizeType.Days,
	TimeWindowSizeType.Hours,
	TimeWindowSizeType.Minutes,
	TimeWindowSizeType.Seconds
];

export function KaplanConfig({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();

	const {
		input: { variables, statistics, selectedDataModel }
	} = analysis;
	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);
	const [{ areFiltersOpen }] = useFilters();
	const [inputDataModel, setInputDataModel] = useState(selectedDataModel);

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [fullscreen] = useFullscreenAnalysis();

	const [activeColumn] = useAnalysesActiveColum();

	const { variablesMap, variableSetsMap } = variablesData;

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { selectItemsMap, dateSelectItems, numericSelectItems, categorySelectItems } =
		variablesDataSelectItems;

	const filters = useActiveFiltersByVariableName(values.groupVariable ?? '');

	const twoCategories = useMemo(() => {
		if (!values.groupVariable) return false;

		const categs = getVariableCategories(values.groupVariable);

		if (categs && categs.length === 2) {
			if (!filters.length) return true;

			return areTwoFiltersActive();
		}

		if (categs && categs.length > 2) {
			return areTwoFiltersActive();
		}

		return false;
	}, [values, variablesMap, filters]);

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) {
			setStats(statistics);
		}
	}, [statistics]);

	useEffect(() => {
		if (selectedDataModel !== inputDataModel) {
			setInputDataModel(selectedDataModel);
		}
	}, [selectedDataModel]);
	useDebounce(
		() => {
			if (
				(haveVariablesChanged(variables, values) ||
					haveStatisticsChanged(statistics, stats) ||
					selectedDataModel !== inputDataModel) &&
				values.positiveEvent.length !== 0
			) {
				const updatedAnalysis: KaplanMeierAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						selectedDataModel: inputDataModel,

						variables: {
							...analysis.input.variables,
							...values
						},
						statistics: stats
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values, stats, inputDataModel, values.positiveEvent],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	const prevGrouping = usePrevious(values.groupVariable);
	useEffect(() => {
		if (
			(prevShouldRefetchAnalyses !== undefined &&
				prevShouldRefetchAnalyses !== shouldRefetchAnalyses) ||
			!values.groupVariable ||
			(prevGrouping !== undefined && prevGrouping !== values.groupVariable)
		) {
			setStats(state => ({
				...state,
				logRank: false
			}));
		}
	}, [shouldRefetchAnalyses, values]);

	function areTwoFiltersActive() {
		let valid = false;

		filters.forEach(filter => {
			if (filter.values && filter.values.length === 2) {
				valid = true;
			}
		});

		return valid;
	}

	function getVariableCategories(name: string) {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			if (aggregatorVariableName in variablesMap) {
				variable = variablesMap[aggregatorVariableName];
			}
		}

		if (variable) return variable.categories;

		return [];
	}

	function getVariableCategoriesSelectItems(variableName: string): SelectItem[] {
		return getVariableCategories(variableName).map(c => ({
			label: c.label || c.value,
			value: c.value
		}));
	}

	function getResetStatistics(): KaplanMeierStatistics {
		return {
			confidenceIntervals: false,
			logRank: false,
			patients: false
		};
	}

	const positiveEventSelectItems = getVariableCategoriesSelectItems(values.eventVariable);

	const groupsColumnsNameSelectedItems =
		values.groupVariable && getVariableCategoriesSelectItems(values.groupVariable);

	// BUILD DATAMODEL
	const dataModel = {
		is: {
			duration: selectedDataModel === KaplanMeierDataModels.duration,
			timeRangeWithEvent: selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent
		},
		label: {
			[KaplanMeierDataModels.duration]: translate(
				({ analysis }) => analysis.analyses.kaplan.config.duration
			),
			[KaplanMeierDataModels.timeRangeWithEvent]: translate(
				({ analysis }) => analysis.analyses.kaplan.config.timeRangeWithEvent
			)
		}
	};

	const dataModelSelectItems: SelectItem[] = [
		{
			label: dataModel.label[KaplanMeierDataModels.duration],
			value: KaplanMeierDataModels.duration
		},
		{
			label: dataModel.label[KaplanMeierDataModels.timeRangeWithEvent],
			value: KaplanMeierDataModels.timeRangeWithEvent
		}
	];

	// BUILD TIME UNIT
	const timeWindowValues = useMemo(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		TIME_WINDOW_VALUES.forEach(timeWindow => {
			const item = {
				label: translateTimeWindowValues(timeWindow),
				value: timeWindow
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	}, []);

	function translateTimeWindowValues(type: TimeWindowSizeType) {
		switch (type) {
			case TimeWindowSizeType.Years:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
			case TimeWindowSizeType.Months:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.months
				);
			case TimeWindowSizeType.Weeks:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.weeks
				);
			case TimeWindowSizeType.Days:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.days
				);
			case TimeWindowSizeType.Hours:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.hours
				);
			case TimeWindowSizeType.Minutes:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.minutes
				);
			case TimeWindowSizeType.Seconds:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.seconds
				);

			default:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
		}
	}

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as KaplanMeierAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === selectedDataModel)}
						onValueSelected={dataModel => {
							setInputDataModel(dataModel as KaplanMeierDataModels);

							setStats(getResetStatistics());
						}}
						canClear={false}
					/>
					{/* VARIABLE INPUTS */}
					{selectedDataModel === KaplanMeierDataModels.duration && (
						<>
							<CreatableSelect
								label={translate(
									({ analysis }) => analysis.analyses.kaplan.config.timeVariable
								)}
								items={numericSelectItems}
								disabled={!dataModel.is.duration}
								value={selectItemsMap[values.durationVariable] ?? null}
								onValueSelected={durationVariable => {
									if (durationVariable) {
										setValues(state => ({
											...state,
											durationVariable
										}));
										setStats(getResetStatistics());
									}
								}}
								canClear={false}
							/>
							<Gap marginGap={{ bottom: 1.6 }} notLastChild>
								<CreatableSelect
									label={translate(
										({ analysis }) => analysis.analyses.kaplan.config.endpoint
									)}
									items={categorySelectItems}
									value={selectItemsMap[values.eventVariable]}
									onValueSelected={eventVariable => {
										if (eventVariable) {
											setValues(state => ({
												...state,
												eventVariable,
												positiveEvent: [
													getVariableCategories(eventVariable)[0]
														?.value ?? ''
												]
											}));
											setStats(getResetStatistics());
										}
									}}
									canClear={false}
									isItemDisabled={item => item.value === variables.groupVariable}
								/>

								<CreatableSelect
									items={positiveEventSelectItems}
									value={positiveEventSelectItems.find(
										item => item.value === values.positiveEvent[0]
									)}
									values={values.positiveEvent.map(val => ({
										label: val,
										value: val
									}))}
									onValuesSelected={newValues => {
										newValues &&
											setValues(state => ({
												...state,
												positiveEvent: newValues
											}));
										setStats(getResetStatistics());
									}}
									onClear={() => {
										setValues(state => ({
											...state,
											positiveEvent: []
										}));
									}}
									canClear={false}
									hasMultipleValues
								/>
							</Gap>
						</>
					)}
					{/* TIME RANGE WITH EVENTS */}
					{selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent && (
						<>
							<CreatableSelect
								label={translate(({ patientCard }) => patientCard.startDate)}
								placeholder={`-`}
								items={dateSelectItems}
								disabled={!dataModel.is.timeRangeWithEvent}
								value={selectItemsMap[values.startDate] ?? null}
								onValueSelected={startDate => {
									if (startDate) {
										setValues(state => ({ ...state, startDate }));
										setStats(getResetStatistics());
									}
								}}
								isItemDisabled={item => item.value === values.endDate}
								canClear={false}
							/>
							<CreatableSelect
								label={translate(dict => dict.projects.tableView.endDate)}
								placeholder={`-`}
								items={dateSelectItems}
								disabled={!dataModel.is.timeRangeWithEvent}
								value={selectItemsMap[values.endDate] ?? null}
								onValueSelected={endDate => {
									if (endDate) {
										setValues(state => ({ ...state, endDate }));
										setStats(getResetStatistics());
									}
								}}
								isItemDisabled={item => item.value === values.startDate}
								canClear={false}
							/>
							<CreatableSelect
								label={translate(
									({ analysis }) => analysis.analyses.kaplan.config.timeUnit
								)}
								items={timeWindowValues.items}
								value={
									timeWindowValues.itemsMap[values.timeUnit ?? ''] ??
									timeWindowValues.items[0]
								}
								onValueSelected={timeUnit => {
									if (timeUnit) {
										setValues(state => ({
											...state,
											timeUnit: timeUnit as TimeWindowSizeType
										}));
									}
									setStats(getResetStatistics());
								}}
								canClear={false}
							/>
							<Gap marginGap={{ bottom: 1.6 }} notLastChild>
								<CreatableSelect
									label={translate(
										({ analysis }) => analysis.analyses.kaplan.config.endpoint
									)}
									items={categorySelectItems}
									value={selectItemsMap[values.eventVariable]}
									onValueSelected={eventVariable => {
										if (eventVariable) {
											setValues(state => ({
												...state,
												eventVariable,
												positiveEvent: [
													getVariableCategories(eventVariable)[0]
														?.value ?? ''
												]
											}));
											setStats(getResetStatistics());
										}
									}}
									canClear={false}
									isItemDisabled={item => item.value === variables.groupVariable}
								/>

								<CreatableSelect
									items={positiveEventSelectItems}
									value={positiveEventSelectItems.find(
										item => item.value === values.positiveEvent[0]
									)}
									values={values.positiveEvent.map(val => ({
										label: val,
										value: val
									}))}
									onValuesSelected={newValues => {
										newValues &&
											setValues(state => ({
												...state,
												positiveEvent: newValues
											}));
										setStats(getResetStatistics());
									}}
									onClear={() => {
										setValues(state => ({
											...state,
											positiveEvent: []
										}));
									}}
									canClear={false}
									hasMultipleValues
								/>

								<DateTimeInput
									value={values.autofillDate}
									options={{
										label: translate(
											({ analysis }) =>
												analysis.analyses.kaplan.config.endDateCensoring
										),
										small: true,
										noLeftMargins: true,
										responsive: true,
										smallCalendar: true
									}}
									onChange={autofillDate => {
										if (autofillDate) {
											setValues(state => ({
												...state,
												autofillDate: autofillDate
											}));
										}
										setStats(getResetStatistics());
									}}
								/>
							</Gap>
						</>
					)}

					{/* GROUPING VARIABLE */}
					<CreatableSelect
						label={translate(({ analysis }) => analysis.analyses.kaplan.config.groups)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.kaplan.config.noGrouping
						)}
						items={categorySelectItems}
						isItemDisabled={item => item.value === variables.eventVariable}
						value={selectItemsMap[values.groupVariable ?? ''] ?? null}
						onValueSelected={groupVariable => {
							setValues(state => ({
								...state,
								groupVariable: groupVariable ?? undefined
							}));
							setStats(getResetStatistics());
						}}
					/>
					{/* STATISTICS */}
					<Switch
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.confidenceIntervals
						)}
						on={stats.confidenceIntervals}
						onChange={() =>
							setStats(state => ({
								...state,
								confidenceIntervals: !state.confidenceIntervals
							}))
						}
					/>
					<Switch
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.patientsAtRisk
						)}
						on={stats.patients}
						onChange={() =>
							setStats(state => ({ ...state, patients: !state.patients }))
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.analyses.kaplan.config.logRank)}
						on={stats.logRank}
						disabled={!values.groupVariable || !twoCategories}
						onChange={() => setStats(state => ({ ...state, logRank: !state.logRank }))}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} isEnabled={groupsColumnsNameSelectedItems} />
		</ConfigContainer>
	);
}

function haveStatisticsChanged(own: KaplanMeierStatistics, values: KaplanMeierStatistics) {
	return (
		own.patients !== values.patients ||
		own.confidenceIntervals !== values.confidenceIntervals ||
		own.logRank !== values.logRank
	);
}

function haveVariablesChanged(own: KaplanMeierVariables, values: KaplanMeierVariables) {
	return (
		own.eventVariable !== values.eventVariable ||
		own.positiveEvent !== values.positiveEvent ||
		own.durationVariable !== values.durationVariable ||
		own.groupVariable !== values.groupVariable ||
		own.startDate !== values.startDate ||
		own.endDate !== values.endDate ||
		own.timeUnit !== values.timeUnit ||
		own.autofillDate !== values.autofillDate
	);
}
