import styled from 'styled-components';

import { Colors } from 'environment';

export const DPAContainer = styled.div`
	width: 100%;
	min-height: 4.4rem;
	max-height: 45rem;
	overflow: auto;
	background-color: ${Colors.background.disabled};

	.react-pdf__Document {
		height: fit-content;
		width: fit-content;
		padding: 0.8rem 1.6rem;
	}
	/* ADD GAP BETWEEN PDF PAGES */
	.react-pdf__Document > :not(:last-child) {
		margin-bottom: 1.5rem;
	}

	.react-pdf__Page__canvas {
		border-radius: 0.4rem;
	}
`;
